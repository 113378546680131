import { RadioGroupFieldProps } from 'lib-ui';
import { SourceCodeAuthenticationType } from 'core/api/client-portal/autogenerated/data-contracts';

const authenticationTypeOptions: RadioGroupFieldProps['options'] = [
    { label: 'SSH', value: SourceCodeAuthenticationType.Value0.toString() },
    {
        label: 'HTTPS',
        value: SourceCodeAuthenticationType.Value1.toString()
    }
];

export default authenticationTypeOptions;
