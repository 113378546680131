import { ReactNode } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { CheckboxProps } from '../element/checkbox';
import CheckboxField from './checkbox-field';
import Field, { FieldProps } from './field';
import classNames from 'classnames';

interface MultiCheckboxFieldProps
    extends Omit<FieldProps, 'error'>,
        Omit<CheckboxProps, 'name' | 'label' | 'children' | 'value'> {
    elementClassName?: string;
    maxHeight?: string;
    options: { label: ReactNode; value: string | number }[];
}

function MultiCheckboxField({
    className,
    disabled,
    elementClassName,
    label,
    maxHeight,
    name,
    options,
    orientation,
    readOnly,
    tailwindStyle,
    ...restProps
}: MultiCheckboxFieldProps) {
    const {
        formState: { errors }
    } = useFormContext();
    const formValue = useWatch({ name });

    const cssClasses = classNames(
        'px-3 py-1 border border-general-grey-grey-40 rounded-lg overflow-auto',
        { 'max-h-24': !maxHeight, [`max-h-${maxHeight}`]: maxHeight }
    );
    if (typeof formValue !== 'object')
        throw new Error(
            `<MultiCheckboxField /> value expects value to be of type 'object' but was '${typeof formValue}'`
        );

    return (
        <Field
            as="div"
            name={name}
            label={label}
            orientation={orientation}
            readOnly={readOnly}
            disabled={disabled}
            className={className}
            required={restProps.required}
            tailwindStyle={tailwindStyle}
            error={!!errors[name] ? <>{errors[name]?.message}</> : undefined}
        >
            <div className={cssClasses}>
                {options.map((item) => (
                    <CheckboxField
                        key={item.value}
                        name={name}
                        value={item.value}
                        label={item.label}
                        elementClassName={elementClassName}
                        suppressError
                    />
                ))}
            </div>
        </Field>
    );
}

export default MultiCheckboxField;
export type { MultiCheckboxFieldProps };
