import { Modal } from 'lib-ui';
import { useTranslation } from 'react-i18next';
import React from 'react';

type SelectedAgreement = {
    software_name: string;
    software_number: string;
    value: number;
};

type SelectedAgreementModalProps = {
    selectedAgreements: SelectedAgreement[];
};

const SelectedAgreementModal = ({
    selectedAgreements = []
}: SelectedAgreementModalProps) => {
    const { t } = useTranslation();
    // const { pop } = useModals();
    // const close = useCallback(() => {
    //     pop();
    // }, [pop]);
    // const [, ...rest] = selectedAgreements;

    return (
        <Modal size="m">
            <Modal.Header>
                {t('deposit-now-agreement-modal.title')}
            </Modal.Header>
            <Modal.Body>
                <p className="text-general-grey-grey-100 text-sm">
                    {t('deposit-now-agreement-modal.description')}:
                </p>
                <div className="flex gap-2 flex-wrap my-4">
                    {selectedAgreements?.map((agreement: SelectedAgreement) => (
                        <div
                            key={agreement.value}
                            className="border border-general-grey-grey-50 bg-general-grey-grey-10 w-fit rounded-3xl padding px-4 py-2 max-w-[178px]"
                            title={agreement.software_name}
                        >
                            <p className="text-general-grey-grey-90 text-sm font-inter truncate">
                                {agreement?.software_number} -{' '}
                                {agreement.software_name}
                            </p>
                        </div>
                    ))}
                </div>
            </Modal.Body>
            <Modal.Footer showCloseButtonAtEnd>
                <div className="flex w-full gap-4"></div>
            </Modal.Footer>
        </Modal>
    );
};

export default SelectedAgreementModal;
