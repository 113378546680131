import { DataHeaderButton } from 'lib-ui';
import { Dropdown, useDropdown } from '@ncc-frontend/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';

import ExportWizard from './export-wizard';

function ExportButton() {
    const { t } = useTranslation();
    const { openPanelId } = useDropdown();
    const panelId = 'export';

    return (
        <Dropdown.Trigger panelId={panelId} panel={<ExportWizard />}>
            <DataHeaderButton
                icon={<FontAwesomeIcon icon={faFileExport} />}
                active={openPanelId === panelId}
            >
                {t('table.export')}
            </DataHeaderButton>
        </Dropdown.Trigger>
    );
}

export default ExportButton;
