import { DepositFrequency } from 'core/api/client-portal/model/deposit-frequency';
import { PropsWithChildren, useMemo } from 'react';
import { Tag } from 'lib-ui';
import { TailwindStyle } from '@ncc-frontend/core';

interface ScheduleFrequencyTagProps {
    size?: 's' | 'm';
    value: DepositFrequency;
}

function ScheduleFrequencyTag({
    children,
    size,
    value
}: PropsWithChildren<ScheduleFrequencyTagProps>) {
    const tailwindStyle = useMemo<TailwindStyle | undefined>(() => {
        switch (value) {
            case 'daily':
                return {
                    background:
                        'bg-brand-escode-harlequingreen-harlequingreen-40',
                    textColor:
                        'text-brand-escode-tourmalineblack-tourmalineblack-100'
                };
            case 'weekly':
                return {
                    background: 'bg-general-lightpurple-lightpurple-10',
                    textColor: 'text-general-lightpurple-lightpurple-100'
                };
            case 'monthly':
                return {
                    background: 'bg-general-red-red-10',
                    textColor: 'text-general-red-red-100'
                };
            case 'quarterly':
                return {
                    background: 'bg-general-blue-blue-10',
                    textColor: 'text-general-blue-blue-100'
                };
            case 'annually':
                return {
                    background: 'bg-general-orange-orange-20',
                    textColor: 'text-general-orange-orange-100'
                };
        }
    }, [value]);

    return (
        <Tag size={size} tailwindStyle={tailwindStyle}>
            {children}
        </Tag>
    );
}

export default ScheduleFrequencyTag;
export type { ScheduleFrequencyTagProps };
