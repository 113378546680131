import { Carousel, CarouselProps } from 'lib-ui';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import DashboardWidget, { DashboardWidgetProps } from '../dashboard-widget';
import NewsCarouselOption from './news-carousel-option';

interface NewsWidgetProps extends Omit<DashboardWidgetProps, 'loading'> {}

function NewsWidget({}: NewsWidgetProps) {
    const { t } = useTranslation();

    const { current: options } = useRef<CarouselProps['options']>([
        {
            component: NewsCarouselOption,
            props: {
                externalLink: true,
                img: '/assets/images/news-1.png',
                label: t('dashboard.news-widget.read-article'),
                to: 'https://www.escode.com/escrow-verification/'
            }
        },
        {
            component: NewsCarouselOption,
            props: {
                externalLink: true,
                img: '/assets/images/news-1.png',
                label: t('dashboard.news-widget.read-article'),
                to: 'https://www.escode.com/escrow-verification/'
            }
        }
    ]);

    return (
        <DashboardWidget suppressPadding>
            <Carousel className="w-full h-full" options={options} />
        </DashboardWidget>
    );
}

export default NewsWidget;
export type { NewsWidgetProps };
