import { MutationOptions, useMutation } from 'react-query';

import { ApiController } from '../../autogenerated/Api';
import { AxiosResponse } from 'axios';
import { DepositDataCentre } from '../../autogenerated/data-contracts';
import { useAuth } from 'react-oidc-context';
import interceptors from '../../../interceptors';
import securityWorker from '../../../security-worker';

type AgreementsDataCentreUpdateParams = {
    data: {
        agreementId: string;
        depositDataCentre: DepositDataCentre;
        depositId: number;
    };
};
function useAgreementsDepositsDataCentreUpdate<
    TError = unknown,
    TContext = unknown
>(
    options?: Omit<
        MutationOptions<
            AxiosResponse<void | unknown>,
            ReactQueryError<TError>,
            AgreementsDataCentreUpdateParams,
            TContext
        >,
        'mutationFn'
    >
) {
    const auth = useAuth();

    return useMutation(
        [
            'dataCentre',
            { token: auth.user?.access_token, tokenType: auth.user?.token_type }
        ],
        async ({ data }: AgreementsDataCentreUpdateParams) => {
            if (process.env.REACT_APP_API_MOCK) {
                console.warn(
                    'Mock is enabled useAgreementsDepositsDataCentreUpdate call ignored.',
                    { data }
                );
                return Promise.resolve({} as AxiosResponse<void, unknown>);
            }
            const { agreementId, depositDataCentre, depositId } = data;

            const controller = new ApiController({
                interceptors,
                securityWorker: securityWorker(
                    auth.user?.token_type,
                    auth.user?.access_token
                )
            });
            return controller.v1AgreementsDepositsDatacentreUpdate(
                depositId,
                agreementId,
                {
                    isOnPrem: depositDataCentre.isOnPrem,
                    platform: depositDataCentre.platform,
                    region: depositDataCentre.region
                }
            );
        },
        options
    );
}

export default useAgreementsDepositsDataCentreUpdate;
