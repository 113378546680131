import {
    ChangeEventHandler,
    ForwardedRef,
    forwardRef,
    useCallback,
    useMemo,
    useState
} from 'react';
import {
    Checkbox as CoreCheckbox,
    CheckboxProps as CoreCheckboxProps,
    TailwindStyle
} from '@ncc-frontend/core';
import { merge } from 'lodash';

interface CheckboxProps extends CoreCheckboxProps {
    error?: boolean;
}

function Checkbox(
    {
        checked: propChecked,
        disabled,
        error,
        indeterminate,
        onChange,
        tailwindStyle,
        ...restProps
    }: CheckboxProps,
    ref: ForwardedRef<HTMLInputElement>
) {
    const [checked, setChecked] = useState(propChecked || false);

    const mergedTailwindStyle = useMemo<TailwindStyle>(
        () =>
            merge<TailwindStyle, TailwindStyle | undefined>(
                {
                    background:
                        (checked || indeterminate) &&
                        !disabled &&
                        'bg-brand-escode-neonblue-neonblue-100',
                    borderColor: error
                        ? 'border-general-red-red-100'
                        : disabled
                        ? 'border-ncc-grey-40'
                        : checked || indeterminate
                        ? 'border-brand-escode-neonblue-neonblue-100'
                        : 'border-brand-escode-neonblue-neonblue-80'
                },
                tailwindStyle
            ),
        [checked, disabled, error, indeterminate, tailwindStyle]
    );

    const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
        (event) => {
            setChecked(event.target.checked);

            onChange?.(event);
        },
        [onChange]
    );

    return (
        <CoreCheckbox
            {...restProps}
            ref={ref}
            checked={checked}
            tailwindStyle={mergedTailwindStyle}
            onChange={handleChange}
            indeterminate={indeterminate}
            disabled={disabled}
        />
    );
}

export default forwardRef(Checkbox);
export type { CheckboxProps };
