import { DepositFrequency } from 'core/api/client-portal/model/deposit-frequency';
import { ProgressBar, ProgressBarProps } from 'lib-ui';
import { PropsWithChildren, useRef } from 'react';
import { useTranslation } from 'react-i18next';

interface SchedulesMetricProps {
    value: number;
    variant: DepositFrequency;
}

function SchedulesMetric({
    children,
    value,
    variant
}: PropsWithChildren<SchedulesMetricProps>) {
    const { t } = useTranslation();

    let name = t('schedules.frequency.unknown');
    const { current: progressBarColor } = useRef<
        NonNullable<ProgressBarProps['color']>
    >({
        fill: '',
        track: ''
    });
    switch (variant) {
        case 'daily':
            name = t('schedules.frequency.daily');
            progressBarColor.fill = 'bg-escode-green-100';
            progressBarColor.track = 'bg-escode-green-20';
            break;
        case 'weekly':
            name = t('schedules.frequency.weekly');
            progressBarColor.fill = 'bg-general-red-red-100';
            progressBarColor.track = 'bg-escode-red-20';
            break;
        case 'monthly':
            name = t('schedules.frequency.monthly');
            progressBarColor.fill = 'bg-escode-purple-100';
            progressBarColor.track = 'bg-escode-purple-20';
            break;
        case 'quarterly':
            name = t('schedules.frequency.quarterly');
            progressBarColor.fill = 'bg-escode-blue-100';
            progressBarColor.track = 'bg-escode-blue-20';
            break;
        case 'annually':
            name = t('schedules.frequency.annually');
            progressBarColor.fill = 'bg-general-orange-orange-100-100';
            progressBarColor.track = 'bg-general-orange-orange-100-20';
            break;
    }

    return (
        <div className="flex flex-col gap-1.5 min-w-2xs 2xl:w-72">
            <div className="flex justify-between gap-2.5 text-sm text-general-grey-grey-90">
                <div>{name}</div>
                <div className="font-medium">{children}</div>
            </div>
            <ProgressBar value={value} color={progressBarColor} />
        </div>
    );
}

export default SchedulesMetric;
export type { SchedulesMetricProps };
// w-72 xl:w-78 2xl:w-96
