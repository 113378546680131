import { ReactElement, useCallback } from 'react';
import useContactsList from 'core/api/client-portal/hooks/contacts/use-contacts-list';

import ContactsTable from './contacts-table';

function Contacts(): ReactElement | null {
    const { data, error, isFetching, isLoading, refetch } = useContactsList();

    const reload = useCallback(() => {
        refetch();
    }, [refetch]);

    return (
        <div className="h-full">
            <ContactsTable
                data={data}
                loading={isFetching || isLoading}
                error={error?.response.status}
                onReload={reload}
            />
        </div>
    );
}

export default Contacts;
