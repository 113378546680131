import {
    ModalBackdrop as CoreModalBackdrop,
    TailwindStyle
} from '@ncc-frontend/core';
import { PropsWithChildren, ReactElement, useRef } from 'react';

function ModalBackdrop({ children }: PropsWithChildren): ReactElement | null {
    const { current: tailwindStyle } = useRef<TailwindStyle>({
        background: 'bg-escode-grey-100/20',
        zIndex: 'z-50'
    });

    return (
        <CoreModalBackdrop
            tailwindStyle={tailwindStyle}
            suppressCloseOnClick
            className="animate-blur-in"
        >
            {children}
        </CoreModalBackdrop>
    );
}

export default ModalBackdrop;
