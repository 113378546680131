import {
    DetailedHTMLProps,
    ReactNode,
    SelectHTMLAttributes,
    createRef,
    forwardRef,
    useCallback,
    useImperativeHandle
} from 'react';
import { Dropdown } from '@ncc-frontend/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { isUndefined } from 'lodash';
import { memo } from 'react';
import classNames from 'classnames';

import SelectOption, { SelectOptionProps } from './select-option';

interface SelectProps
    extends DetailedHTMLProps<
        SelectHTMLAttributes<HTMLSelectElement>,
        HTMLSelectElement
    > {
    error?: boolean;
    label?: ReactNode;
    options: { label: ReactNode; value: string }[];
}

const Select = forwardRef<HTMLSelectElement, SelectProps>(
    (
        {
            className,
            disabled,
            error,
            id,
            label, // id captures click and break dropdown so we need to ignore it.
            options,
            placeholder,
            required,
            style,
            ...restProps
        },
        ref
    ) => {
        const selectRef = createRef<HTMLSelectElement>();

        const cssClasses = classNames(
            'h-10 px-3 py-1',
            'flex items-center',
            'border rounded-lg',
            {
                'bg-ncc-grey-10 text-ncc-grey-100': disabled,
                'bg-ncc-white': !disabled,
                'border-general-grey-grey-40': !error && !disabled,
                'border-general-red-red-100 focus:outline-general-red-red-100':
                    error
            },
            className
        );

        const handleOptionSelected = useCallback<SelectOptionProps['onClick']>(
            (selectedValue) => {
                if (selectRef.current) {
                    selectRef.current.value = selectedValue;
                    selectRef.current.dispatchEvent(
                        new Event('change', { bubbles: true, cancelable: true })
                    );
                }
            },
            [selectRef]
        );

        useImperativeHandle(ref, () => selectRef.current as HTMLSelectElement);

        return (
            <>
                <Dropdown
                    trigger="click"
                    matchAnchorSize
                    offset={1}
                    panel={
                        <div className="flex flex-col gap-2.5 p-3 bg-ncc-white rounded-lg border border-text-general-grey-grey-10 text-general-grey-grey-90 max-h-80 overflow-y-auto ">
                            {options.map((item) => (
                                <SelectOption
                                    key={item.value}
                                    value={item.value}
                                    onClick={handleOptionSelected}
                                >
                                    {item.label}
                                </SelectOption>
                            ))}
                        </div>
                    }
                    className="flex-1"
                    disabled={disabled}
                >
                    <div className={cssClasses} style={style}>
                        {options.find((item) => item.value === restProps.value)
                            ?.label || (
                            <span className="truncate text-sm font-normal text-general-grey-grey-100 opacity-50">
                                {placeholder}
                            </span>
                        )}
                        <div className="ml-auto flex gap-2.5 items-center">
                            {required && isUndefined(label) && (
                                <span className="ml-auto text-general-red-red-100">
                                    *
                                </span>
                            )}
                            <FontAwesomeIcon icon={faCaretDown} />
                        </div>
                    </div>
                </Dropdown>
                <select
                    ref={selectRef}
                    hidden
                    {...restProps}
                    disabled={disabled}
                >
                    {options.map((item) => (
                        <option key={item.value} value={item.value}>
                            {item.label}
                        </option>
                    ))}
                </select>
            </>
        );
    }
);

export default memo(Select);
export type { SelectProps };
