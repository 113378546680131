import { AgGridReact } from 'ag-grid-react';
import {
    CalendarInstance,
    DataWidget,
    DataWidgetContextValue,
    DataWidgetProps,
    ResultModal,
    TableWidgetProps,
    Tabs,
    TabsProps,
    useTableData
} from 'lib-ui';
import { CommonProps, dateFormatter, useModals } from '@ncc-frontend/core';
import { DownloadScheduleItem } from 'core/api/client-portal/autogenerated/data-contracts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    MouseEventHandler,
    createRef,
    useCallback,
    useMemo,
    useRef
} from 'react';
import { faArrowsRepeat, faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useBeautify from 'core/beautifiers/use-beautify';

import { SchedulesTableContext } from './schedules-table/schedules-table-context';
import {
    getScheduleFrequencyOptions,
    schedulesFrequencyTypeValueGetter
} from './schedules-table/schedules-frequency-type-cell-renderer';
import SchedulesCalendar from './schedules-calendar/schedules-calendar';
import SchedulesTable from './schedules-table/schedules-table';
import useData, { DataConfig } from 'common/use-data';
import useSchedulesDelete from 'core/api/client-portal/hooks/schedules/use-schedules-delete';

interface SchedulesWidgetProps extends CommonProps {
    currentTab?: string;
    data: DownloadScheduleItem[] | null | undefined;
    error?: number;
    loading?: boolean;
    onReload?: MouseEventHandler<HTMLButtonElement>;
    onTabChange: TabsProps['onChange'];
}

function SchedulesWidget({
    className,
    currentTab,
    data,
    error,
    loading,
    onReload,
    onTabChange,
    style
}: SchedulesWidgetProps) {
    const { t } = useTranslation();
    const { push } = useModals();
    const goTo = useNavigate();
    const { beautifyFrequency } = useBeautify();
    const calendarRef = createRef<CalendarInstance>();
    const agGridRef = useRef<AgGridReact<DownloadScheduleItem> | null>(null);
    const dataWidget = useRef<DataWidgetContextValue | null>(null);

    const id = 'schedules-table';

    const {
        handleApplyFilters: handleTableApplyFilters,
        handleGridReady: handleTableGridReady,
        handleHiddenFieldsChange: handleTableHiddenFieldsChange,
        handlePageChange: handleTablePageChange,
        handlePaginationChanged: handleTablePaginationChanged,
        handleQuickSearchChange: handleTableQuickSearchChange,
        handleReset: handleTableReset,
        handleRowDataUpdated: handleTableRowDataUpdated,
        refresh: tableRefresh
    } = useTableData<DownloadScheduleItem>({
        agGridRef,
        backendPagination: false,
        dataWidget
    });

    const { isLoading: loadingDelete, mutate: requestScheduleDelete } =
        useSchedulesDelete({
            onError: () => {
                push(ResultModal, {
                    description: t('schedules.delete-modal.error-description'),
                    title: t('schedules.delete-modal.error-title')
                });
            }
        });

    const tableContext = useMemo<SchedulesTableContext>(
        () => ({
            beautifyFrequency,
            goTo,
            loading: loadingDelete,
            push,
            requestScheduleDelete,
            t
        }),
        [beautifyFrequency, goTo, loadingDelete, push, requestScheduleDelete, t]
    );

    const fields = useMemo<DataConfig<DownloadScheduleItem>['fields']>(
        () => [
            {
                filterType: 'date',
                mode: 'inRange',
                path: 'nextDownloadAt',
                valueGetter: ({ data }) => dateFormatter(data.nextDownloadAt)
            },
            {
                path: 'agreementNumber'
            },
            {
                filterType: 'text',
                mode: 'inList',
                options: getScheduleFrequencyOptions(tableContext),
                path: 'frequencyType',
                valueGetter: ({ data }) =>
                    schedulesFrequencyTypeValueGetter({
                        context: tableContext,
                        data
                    })
            }
        ],
        [tableContext]
    );

    const filterableFields = useMemo<
        TableWidgetProps<false>['filterableFields']
    >(
        () => [
            {
                colId: 'frequencyType',
                filterType: 'text',
                icon: <FontAwesomeIcon icon={faArrowsRepeat} />,
                mode: 'inList',
                name: t('schedules.header.frequency'),
                options: getScheduleFrequencyOptions(tableContext),
                title: t('schedules.filter.frequency')
            },
            {
                colId: 'nextDownloadAt',
                filterType: 'date',
                icon: <FontAwesomeIcon icon={faCalendar} />,
                mode: 'inRange',
                name: t('schedules.header.upcoming-deposit'),
                options: [],
                title: t('schedules.filter.upcoming-deposit')
            }
        ],
        [t, tableContext]
    );

    const { setFilters, setQuickFilter } = useData({
        data,
        fields
    });

    const setDataWidget = useCallback(
        (ref: DataWidgetContextValue | null) => {
            dataWidget.current = ref;

            if (!ref) return;

            setFilters(ref.filters);
            setQuickFilter(ref.quickSearchValue);
        },
        [setFilters, setQuickFilter]
    );

    const setAgGridRef = useCallback(
        (ref: AgGridReact | null) => {
            agGridRef.current = ref;

            if (!ref) return;

            tableRefresh();
        },
        [tableRefresh]
    );

    const handleApplyFilters = useCallback<
        NonNullable<DataWidgetProps['onApplyFilters']>
    >(
        (...args) => {
            handleTableApplyFilters(...args);
            setFilters(...args);
        },
        [handleTableApplyFilters, setFilters]
    );

    const handleReset = useCallback<NonNullable<DataWidgetProps['onReset']>>(
        (...args) => {
            handleTableReset(...args);

            calendarRef.current?.reset();
        },
        [calendarRef, handleTableReset]
    );

    const handleQuickSearchChange = useCallback<
        NonNullable<DataWidgetProps['onQuickSearchChange']>
    >(
        (...args) => {
            handleTableQuickSearchChange(...args);
            setQuickFilter(args[0]);
        },
        [handleTableQuickSearchChange, setQuickFilter]
    );

    return (
        <DataWidget
            ref={setDataWidget}
            id={id}
            title={t('schedules.title')}
            error={error}
            filterableFields={filterableFields}
            hideTableActions={currentTab === 'calendar'}
            onApplyFilters={handleApplyFilters}
            onHiddenFieldsChange={handleTableHiddenFieldsChange}
            onPageChange={handleTablePageChange}
            onQuickSearchChange={handleQuickSearchChange}
            onReset={handleReset}
            onReload={onReload}
            className={className}
            style={style}
        >
            <Tabs className="h-full" onChange={onTabChange}>
                <Tabs.Tab tabId="table" button={t('schedules.table')} default>
                    <SchedulesTable
                        ref={setAgGridRef}
                        id={id}
                        data={data}
                        context={tableContext}
                        loading={loading}
                        onPaginationChanged={handleTablePaginationChanged}
                        onGridReady={handleTableGridReady}
                        onRowDataUpdated={handleTableRowDataUpdated}
                    />
                </Tabs.Tab>
                <Tabs.Tab
                    tabId="calendar"
                    button={t('schedules.calendar')}
                    className="h-full"
                >
                    <SchedulesCalendar
                        ref={calendarRef}
                        data={data}
                        className="px-5"
                    />
                </Tabs.Tab>
            </Tabs>
        </DataWidget>
    );
}

export default SchedulesWidget;
export type { SchedulesWidgetProps };
