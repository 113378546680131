import { AxiosResponse } from 'axios';
import {
    CreateProviderViewModelResponse,
    CreateSourceCodeProviderRequest
} from '../../autogenerated/data-contracts';
import { MutationOptions, useMutation, useQueryClient } from 'react-query';

import { ApiController } from '../../autogenerated/Api';
import { useAuth } from 'react-oidc-context';
import interceptors from '../../../interceptors';
import securityWorker from '../../../security-worker';

type ProvidersAddParams = { data: CreateSourceCodeProviderRequest };

function useProvidersAdd<TError = unknown, TContext = unknown>(
    options?: Omit<
        MutationOptions<
            AxiosResponse<CreateProviderViewModelResponse>,
            ReactQueryError<TError>,
            ProvidersAddParams,
            TContext
        >,
        'mutationFn'
    >
) {
    const queryClient = useQueryClient();
    const auth = useAuth();

    return useMutation(
        [{ token: auth.user?.access_token, tokenType: auth.user?.token_type }],
        async ({ data }: ProvidersAddParams) => {
            if (process.env.REACT_APP_API_MOCK) {
                console.warn(
                    'Mock is enabled useProvidersAdd call ignored.',
                    data
                );
                return Promise.resolve(
                    {} as AxiosResponse<CreateProviderViewModelResponse>
                );
            }

            const controller = new ApiController({
                interceptors,
                securityWorker: securityWorker(
                    auth.user?.token_type,
                    auth.user?.access_token
                )
            });

            return controller.createSourceCodeProvider(data);
        },
        {
            ...options,
            onSettled: (data, error, variables, context) => {
                options?.onSettled?.(data, error, variables, context);

                queryClient.invalidateQueries({ queryKey: ['providers'] });
            }
        }
    );
}

export default useProvidersAdd;
export type { ProvidersAddParams };
