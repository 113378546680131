import { Placement } from '@ncc-frontend/core';
import { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';
import SectionHeading from './section-heading';

type SectionType = {
    children: React.ReactNode;
    className?: string;
    dataTestId?: string;
    heading?: string;
    headingClasses?: string;
    headingTextClasses?: string;
    optional?: boolean;
    required?: boolean;
    tooltip?: string;
    tooltipDisabled?: boolean;
    tooltipPlacement?: Placement;
    wrapperClasses?: string;
    wrapperDefault?: boolean;
};

function Section({
    children,
    className,
    dataTestId,
    heading,
    headingClasses,
    headingTextClasses,
    optional,
    required,
    tooltip,
    tooltipDisabled = false,
    tooltipPlacement = 'right',
    wrapperClasses,
    wrapperDefault = true
}: PropsWithChildren<SectionType>) {
    return (
        <div
            className={twMerge(
                'flex-col flex-1 border-2 rounded-lg p-5 bg-white',
                className
            )}
            data-testid="section"
        >
            {heading && (
                <div data-testid="section-heading-container">
                    <SectionHeading
                        className={headingClasses}
                        dataTestId={dataTestId}
                        heading={heading}
                        headingTextClasses={headingTextClasses}
                        optional={optional}
                        required={required}
                        tooltip={tooltip}
                        tooltipDisabled={tooltipDisabled}
                        tooltipPlacement={tooltipPlacement}
                    />
                </div>
            )}
            <div
                className={twMerge(
                    wrapperDefault && 'flex flex-col gap-y-6',
                    wrapperClasses
                )}
                data-testid="section-children-container"
            >
                {children}
            </div>
        </div>
    );
}

export default Section;
