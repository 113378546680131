import { isUndefined } from 'lodash';
import { useCallback, useRef } from 'react';

function useDebounce<TArgs extends unknown[]>(ms: number) {
    const timeout = useRef<number>();

    const debounce = useCallback(
        (cb: (...args: TArgs) => void) => {
            if (!isUndefined(timeout.current)) clearTimeout(timeout.current);

            timeout.current = setTimeout(cb, ms);
        },
        [ms]
    );

    return debounce;
}

export default useDebounce;
