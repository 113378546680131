import { DepositState as ApiDepositState } from '../autogenerated/data-contracts';

type DepositState =
    | 'none'
    | 'contact-details'
    | 'agreement-selection'
    | 'software-details'
    | 'deposit-type'
    | 'deposit-specs'
    | 'deposit-content'
    | 'data-centre'
    | 'review-details';

const DEPOSIT_STATE_MAPPER: Record<ApiDepositState | number, DepositState> = {
    [ApiDepositState.Value0]: 'none',
    [ApiDepositState.Value1]: 'contact-details',
    [ApiDepositState.Value2]: 'agreement-selection',
    [ApiDepositState.Value3]: 'software-details',
    [ApiDepositState.Value4]: 'deposit-type',
    [ApiDepositState.Value5]: 'deposit-specs',
    [ApiDepositState.Value6]: 'deposit-content',
    [ApiDepositState.Value7]: 'data-centre',
    [ApiDepositState.Value8]: 'review-details'
};

export default DEPOSIT_STATE_MAPPER;
export type { DepositState };
