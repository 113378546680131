import { Dropdown, useDropdown } from '@ncc-frontend/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement } from 'react';
import { faRotateExclamation } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';

import DataHeaderButton from '../data-header-button/data-header-button';
import ResetLayoutPanel from './reset-panel';
import useDataWidget from '../use-data-widget';

interface ResetButtonProps {
    disabled?: boolean;
}

function ResetButton({ disabled }: ResetButtonProps): ReactElement | null {
    const { t } = useTranslation();
    const { openPanelId } = useDropdown();
    const { loading } = useDataWidget();

    const panelId = 'reset-layout';

    return (
        <Dropdown.Trigger
            panelId={panelId}
            panel={<ResetLayoutPanel />}
            disabled={loading || disabled}
        >
            <DataHeaderButton
                icon={<FontAwesomeIcon icon={faRotateExclamation} />}
                active={openPanelId === panelId}
                disabled={loading || disabled}
            >
                {t('table.reset-layout')}
            </DataHeaderButton>
        </Dropdown.Trigger>
    );
}

export default ResetButton;
export type { ResetButtonProps };
