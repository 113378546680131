import { MouseEventHandler } from 'react';
import { PropsWithChildren } from 'react';
import { TailwindProps, tailwindClasses } from '@ncc-frontend/core';
import { useTranslation } from 'react-i18next';
import WidgetBody, { WidgetBodyProps } from './widget-body';
import WidgetHeader, { WidgetHeaderProps } from './widget-header';
import classNames from 'classnames';

import Button from '../../button/button';
import ErrorPanel from '../../error/error-panel';

interface WidgetProps extends TailwindProps {
    error?: number;
    onClick?: MouseEventHandler<HTMLDivElement>;
    onReload?: MouseEventHandler<HTMLButtonElement>;
}

function Widget({
    children,
    className,
    error,
    onReload,
    tailwindStyle,
    ...restProps
}: PropsWithChildren<WidgetProps>) {
    const { t } = useTranslation();

    const cssClasses = classNames(
        tailwindClasses(
            {
                background: 'bg-white',
                borderRadius: 'rounded-2xl',
                boxShadow: 'shadow-widget',
                display: 'flex',
                flexDirection: 'flex-col',
                overflow: 'overflow-hidden'
            },
            tailwindStyle
        ),
        className
    );

    return (
        <div {...restProps} className={cssClasses}>
            {!error ? (
                children
            ) : (
                <ErrorPanel code={error}>
                    <Button onClick={onReload}>{t('reload')}</Button>
                </ErrorPanel>
            )}
        </div>
    );
}

Widget.Header = WidgetHeader;
Widget.Body = WidgetBody;

export default Widget;
export type { WidgetBodyProps, WidgetHeaderProps, WidgetProps };
