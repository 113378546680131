import { RatingField, TextareaField } from 'lib-ui';
import { useTranslation } from 'react-i18next';

import FeedbackFormStep, { FeedbackFormStepProps } from './feedback-form-step';

type FieldValues = {
    newFeatureAgreementWidgets: string;
    newFeatureDatePicker: string;
    newFeatureDepositScheduler: string;
    newFeatureDynamicDashboard: string;
    // newFeatureFileManager: string;
    // newFeatureQuickActions: string;
    newFeatureSmartFilters: string;
    newFeaturesComment: string;
};

interface NewFeaturesStepProps {
    defaultValues: FieldValues;
    onChange: FeedbackFormStepProps<FieldValues>['onChange'];
}

function NewFeaturesStep({ defaultValues, onChange }: NewFeaturesStepProps) {
    const { t } = useTranslation();

    return (
        <FeedbackFormStep<FieldValues>
            message={t('feedback-form.new-features-question')}
            title={t('feedback-form.new-features')}
            onChange={onChange}
            defaultValues={defaultValues}
        >
            <RatingField
                label={t('feedback-form.new-feature-agreement-widgets')}
                name="newFeatureAgreementWidgets"
                orientation="horizontal"
            />
            <RatingField
                label={t('feedback-form.new-feature-date-picker')}
                name="newFeatureDatePicker"
                orientation="horizontal"
            />
            <RatingField
                label={t('feedback-form.new-feature-deposit-scheduler')}
                name="newFeatureDepositScheduler"
                orientation="horizontal"
            />
            <RatingField
                label={t('feedback-form.new-feature-dynamic-dashboard')}
                name="newFeatureDynamicDashboard"
                orientation="horizontal"
            />
            {/* <RatingField
                label={t('feedback-form.new-feature-file-manager')}
                name="newFeatureFileManager"
                orientation="horizontal"
            /> */}
            {/* <RatingField
                label={t('feedback-form.new-feature-quick-actions')}
                name="newFeatureQuickActions"
                orientation="horizontal"
            /> */}
            <RatingField
                label={t('feedback-form.new-feature-smart-filters')}
                name="newFeatureSmartFilters"
                orientation="horizontal"
            />
            <TextareaField
                name="newFeaturesComment"
                placeholder={t('feedback-form.comment-placeholder')}
            />
        </FeedbackFormStep>
    );
}

export default NewFeaturesStep;
export type { NewFeaturesStepProps };
