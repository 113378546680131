/* eslint-disable sort-keys-fix/sort-keys-fix */

function getDayOfWeek(dateString: string): string {
    const date = new Date(dateString);
    const daysOfWeek = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
    ];
    const dayIndex = date.getDay();
    return daysOfWeek[dayIndex];
}

const DAY_OF_WEEK_MAPPER = {
    Sunday: '0',
    Monday: '1',
    Tuesday: '2',
    Wednesday: '3',
    Thursday: '4',
    Friday: '5',
    Saturday: '6'
};

export { DAY_OF_WEEK_MAPPER };
export default getDayOfWeek;
