import {
    ComponentType,
    ForwardRefExoticComponent,
    ForwardedRef,
    PropsWithChildren,
    RefAttributes,
    forwardRef
} from 'react';
import { TailwindProps } from '@ncc-frontend/core';

import FilterStepBody, { FilterStepBodyProps } from './filter-step-body';
import FilterStepFooter, { FilterStepFooterProps } from './filter-step-footer';
import FilterStepHeader, { FilterStepHeaderProps } from './filter-step-header';
import FilterStepNested, { FilterStepNestedProps } from './filter-step-nested';
import Wizard from '../../../../wizard/wizard';

interface FilterStepProps extends TailwindProps {}

const FilterStep = forwardRef(function FilterStep(
    { children, ...restProps }: PropsWithChildren<FilterStepProps>,
    ref: ForwardedRef<HTMLDivElement>
) {
    return (
        <Wizard.Step ref={ref} {...restProps}>
            {children}
        </Wizard.Step>
    );
}) as ForwardRefExoticComponent<
    PropsWithChildren<FilterStepProps> & RefAttributes<HTMLDivElement>
> & {
    Body: ComponentType<PropsWithChildren<FilterStepBodyProps>>;
    Footer: ComponentType<PropsWithChildren<FilterStepFooterProps>>;
    Header: ComponentType<PropsWithChildren<FilterStepHeaderProps>>;
    Nested: ComponentType<PropsWithChildren<FilterStepNestedProps>>;
};

FilterStep.Body = FilterStepBody;
FilterStep.Footer = FilterStepFooter;
FilterStep.Header = FilterStepHeader;
FilterStep.Nested = FilterStepNested;

export default FilterStep;
export type { FilterStepProps };
