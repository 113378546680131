import { UseQueryOptions, useQuery } from 'react-query';
import { isUndefined } from 'lodash';

import { ApiController } from '../../autogenerated/Api';
import { GetDepositResponse } from 'core/api/client-portal/autogenerated/data-contracts';
import { useAuth } from 'react-oidc-context';
import interceptors from '../../../interceptors';
import securityWorker from '../../../security-worker';
import useGetDepositDetailsMockData from './use-get-deposit-details-mock-data';

function useGetDepositDetails<TError = unknown>(
    depositId?: number,
    options?: Omit<
        UseQueryOptions<
            GetDepositResponse | undefined,
            ReactQueryError<TError>
        >,
        'queryKey' | 'queryFn'
    >
) {
    const auth = useAuth();

    return useQuery<GetDepositResponse | undefined, ReactQueryError<TError>>(
        [
            'agreements',
            'getDepositDetails',
            {
                token: auth.user?.access_token,
                tokenType: auth.user?.token_type
            }
        ],
        async () => {
            if (isUndefined(depositId)) {
                console.warn('Undefined ID in useGetDepositDetails');
                return undefined;
            }

            if (process.env.REACT_APP_API_MOCK) {
                console.warn('Using mock data for useGetDepositDetails');
                return useGetDepositDetailsMockData;
            }

            const controller = new ApiController({
                interceptors,
                securityWorker: securityWorker(
                    auth.user?.token_type,
                    auth.user?.access_token
                )
            });

            const response = await controller.v2DepositDetail(depositId);
            return response.data;
        },
        options
    );
}

export default useGetDepositDetails;
