import { Menu } from 'lib-ui';
import {
    MouseEventHandler,
    PropsWithChildren,
    MouseEvent as ReactMouseEvent,
    memo,
    useCallback
} from 'react';

type ExportFormat = 'csv' | 'xlsx';

type ExportFormatOptionClickHandler = (
    format: ExportFormat,
    event: ReactMouseEvent<HTMLDivElement, MouseEvent>
) => void;

interface ExportFormatOptionProps {
    active?: boolean;
    format: ExportFormat;
    onClick: ExportFormatOptionClickHandler;
}

function ExportFormatOption({
    active,
    children,
    format,
    onClick
}: PropsWithChildren<ExportFormatOptionProps>) {
    const handleClick = useCallback<MouseEventHandler<HTMLDivElement>>(
        (event) => {
            onClick(format, event);
        },
        [format, onClick]
    );

    return (
        <Menu.Item onClick={handleClick} active={active}>
            {children}
        </Menu.Item>
    );
}

export default memo(ExportFormatOption);
export type {
    ExportFormat,
    ExportFormatOptionClickHandler,
    ExportFormatOptionProps
};
