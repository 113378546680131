import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronLeft,
    faChevronRight
} from '@fortawesome/pro-solid-svg-icons';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

interface PagerProps {
    currentPage: number;
    disabled?: boolean;
    onChange: (toPage: number) => void;
    totalPages: number;
}

function Pager({ currentPage, disabled, onChange, totalPages }: PagerProps) {
    const { t } = useTranslation();

    const arrowCssClasses = classNames(
        'h-10 w-8 flex items-center justify-center',
        'disabled:bg-ncc-grey-10 disabled:text-ncc-grey-70',
        'transition-all'
    );

    const padding = totalPages.toString().length;

    const handlePrevious = useCallback(() => {
        onChange(currentPage - 1);
    }, [currentPage, onChange]);

    const handleNext = useCallback(() => {
        onChange(currentPage + 1);
    }, [currentPage, onChange]);

    return (
        <div className="flex items-center h-10 bg-ncc-white rounded-lg text-brand-escode-neonblue-neonblue-100 font-semibold text-sm">
            <div className="px-4">
                {t('pager.current-page', {
                    currentPage: currentPage.toString().padStart(padding, '0'),
                    totalPages: totalPages ?? '?'
                })}
            </div>
            <button
                className={arrowCssClasses}
                onClick={handlePrevious}
                disabled={disabled || currentPage <= 1}
                data-testid="previous"
            >
                <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            <button
                className={classNames(arrowCssClasses, 'rounded-r-lg')}
                onClick={handleNext}
                disabled={disabled || currentPage >= totalPages}
                data-testid="next"
            >
                <FontAwesomeIcon icon={faChevronRight} />
            </button>
        </div>
    );
}
export default Pager;
export type { PagerProps };
