import { saveAsFile } from '@ncc-frontend/core';
import { useMutation } from 'react-query';
import axios from 'axios';

function useAgreementsStatutoryDeclarationFormDownload() {
    return useMutation(async () => {
        const instance = axios.create({});

        return new Promise((resolve, reject) => {
            instance
                .request({
                    responseType: 'blob',
                    url: '/assets/docs/statutory-declaration-form.docx'
                })
                .then((response) => {
                    saveAsFile(
                        `Statutory Declaration Form.docx`,
                        response.data,
                        {
                            type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                        }
                    );
                    resolve(response.data);
                });
        });
    });
}

export default useAgreementsStatutoryDeclarationFormDownload;
