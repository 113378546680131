import { CSSProperties, PropsWithChildren, ReactNode } from 'react';
import { TailwindProps, tailwindClasses } from '@ncc-frontend/core';
import { merge } from 'lodash';
import classNames from 'classnames';

import SkeletonLine from '../../skeleton/skeleton-line';

interface WidgetHeaderProps extends TailwindProps {
    /** Css units: px, rem, ... */
    height?: string;
    loading?: boolean;
    title?: ReactNode;
}
function WidgetHeader({
    children,
    className,
    height,
    loading,
    style,
    tailwindStyle,
    title,
    ...restProps
}: PropsWithChildren<WidgetHeaderProps>) {
    const cssClasses = classNames(
        tailwindClasses(
            {
                background: 'bg-general-grey-grey-10',
                display: 'flex',
                gap: 'gap-1.5',
                items: 'items-center',
                padding: 'px-4',
                position: 'relative',
                textSize: 'text-xl',
                zIndex: 'z-1'
            },
            tailwindStyle
        ),
        className
    );

    const mergedStyle = merge<CSSProperties, CSSProperties | undefined>(
        {
            height: height || `var(--escode-widget-height)`
        },
        style
    );

    return (
        <div {...restProps} className={cssClasses} style={mergedStyle}>
            {!!title && (
                <div className="mr-3 truncate">
                    {!!loading && <SkeletonLine absolute />}
                    {title}
                </div>
            )}
            {children}
        </div>
    );
}

export default WidgetHeader;
export type { WidgetHeaderProps };
