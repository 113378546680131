import {
    AGREEMENT_TESTING_LEVEL_MAPPER,
    AgreementTestingLevel
} from 'core/api/client-portal/model/agreement-testing-level';
import { AgreementViewModel } from 'core/api/client-portal/autogenerated/data-contracts';
import { FilterFieldData } from 'lib-ui/widget/data/data-widget-types';
import { ValueGetterParams } from 'lib-ui';

import { AgreementsTableContext } from './agreements-table-context';

function getAgreementTestingLevel(
    agreement: AgreementViewModel
): AgreementTestingLevel | undefined {
    return AGREEMENT_TESTING_LEVEL_MAPPER[
        agreement.lastDepositLevelOfVerification || ''
    ];
}

function getAgreementTestingLevelString({
    context: { t },
    testingLevel
}: Pick<
    ValueGetterParams<AgreementViewModel, AgreementsTableContext>,
    'context'
> & { testingLevel: AgreementTestingLevel }): string {
    if (!testingLevel) return '';
    return t(`agreement.testing-level.${testingLevel}`);
}

function getAgreementTestingLevelOptions(
    context: AgreementsTableContext
): FilterFieldData['options'] {
    const options: FilterFieldData['options'] = Object.values(
        AGREEMENT_TESTING_LEVEL_MAPPER
    ).map((key) => ({
        key,
        label: getAgreementTestingLevelString({
            context,
            testingLevel: key
        })
    }));

    return options;
}

function agreementTestingLevelValueGetter({
    context,
    data
}: ValueGetterParams<AgreementViewModel, AgreementsTableContext>) {
    const testingLevel = getAgreementTestingLevel(data);
    if (!testingLevel) return data.lastDepositLevelOfVerification;
    return getAgreementTestingLevelString({ context, testingLevel });
}

export { agreementTestingLevelValueGetter, getAgreementTestingLevelOptions };
