import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { isUndefined } from 'lodash';
import { useModals } from '@ncc-frontend/core';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SelectedAgreementModal from '../selected-agreement-modal/selected-agreement-modal';
import useAgreementsList from 'core/api/client-portal/hooks/agreements/use-agreements-list';
import useDepositDetailsV2 from 'core/api/client-portal-v2/hooks/deposits/use-deposit-details-v2';

const SelectedAgreementDisplay = () => {
    const { t } = useTranslation();
    const { depositId } = useParams();
    const { push } = useModals();
    const [selectedAgreements, setSelectedAgreements] = useState<
        { software_name: string; software_number: string; value: number }[]
    >([]);

    const { data: agreements } = useAgreementsList({
        DepositNow: true
    });

    const { data: deposit } = useDepositDetailsV2(
        depositId as unknown as number
    );

    const agreementOptions = useMemo(
        () =>
            agreements?.agreements
                ?.filter(
                    (item) =>
                        !isUndefined(item.agreementNumber) &&
                        !isUndefined(item.agreementId)
                )
                .map((item) => ({
                    software_name: item.softwarePackageName || '',
                    software_number: item.agreementNumber?.toString() || '',
                    value: item.agreementId as number
                })) ?? [],
        [agreements]
    );

    useEffect(
        () =>
            setSelectedAgreements(
                agreementOptions.filter((obj) =>
                    deposit?.relatedAgreements.includes(obj.value)
                )
            ),
        [agreementOptions, deposit]
    );

    const openAgreementsModal = useCallback(() => {
        push(SelectedAgreementModal, { selectedAgreements });
    }, [push, selectedAgreements]);

    if (selectedAgreements.length === 0)
        return (
            <div className="flex justify-center items-center">
                <FontAwesomeIcon
                    className="h-4 w-4 mx-auto"
                    icon={faSpinner}
                    spin
                />
            </div>
        );

    return (
        <div className="flex gap-2 items-center">
            <p className="text-general-dark-dark-100 text-md font-medium">
                {t('deposit-now-agreement-display.tag')}
            </p>
            <div
                className="py-2 px-4 max-w-[192px] bg-general-grey-grey-10 border border-general-grey-grey-50 rounded-3xl flex items-center justify-center text-general-grey-grey-90 text-sm"
                title={selectedAgreements[0]?.software_name}
            >
                <p className="truncate">
                    {selectedAgreements[0]?.software_number} -{' '}
                    {selectedAgreements[0]?.software_name}
                </p>
            </div>

            {selectedAgreements.length > 1 && (
                <div
                    className="cursor-pointer h-8 w-8 p-2"
                    onClick={openAgreementsModal}
                >
                    <p className="text-brand-escode-neonblue-neonblue-100 text-xs border-b border-dashed border-brand-escode-neonblue-neonblue-100">
                        +{selectedAgreements.length - 1}
                    </p>
                </div>
            )}
        </div>
    );
};

export default SelectedAgreementDisplay;
