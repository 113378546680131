import { CommonProps } from '@ncc-frontend/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ForwardedRef, forwardRef } from 'react';
import { NavLink } from 'react-router-dom';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';

interface NewsCarouselOptionProps extends CommonProps {
    externalLink?: boolean;
    img: string;
    label: string;
    to: string;
}

function NewsCarouselOption(
    {
        className,
        externalLink = false,
        img,
        label,
        to,
        ...restProps
    }: NewsCarouselOptionProps,
    ref: ForwardedRef<HTMLDivElement>
) {
    return (
        <div
            {...restProps}
            ref={ref}
            className={classNames(
                'flex items-end justify-end p-4 pb-8  bg-center bg-cover',
                className
            )}
            style={{
                backgroundImage: `url(${img})`
            }}
        >
            {externalLink ? (
                <a
                    className="text-sm font-semibold text-ncc-white"
                    href={to}
                    rel="noreferrer"
                    target="_blank"
                >
                    {label} <FontAwesomeIcon icon={faChevronRight} />
                </a>
            ) : (
                <NavLink
                    to={to}
                    className="text-sm font-semibold text-ncc-white"
                >
                    {label} <FontAwesomeIcon icon={faChevronRight} />
                </NavLink>
            )}
        </div>
    );
}

export default forwardRef(NewsCarouselOption);
export type { NewsCarouselOptionProps };
