import { Button } from 'lib-ui';
import { useCallback } from 'react';
import { useModals } from '@ncc-frontend/core';
import { useTranslation } from 'react-i18next';

export interface ModalFooterProps {
    children: React.ReactNode;
    showCloseButtonAtEnd?: boolean;
    suppressCloseButton?: boolean;
}

function ModalFooter({
    children,
    showCloseButtonAtEnd = false,
    suppressCloseButton = false
}: ModalFooterProps): React.ReactElement | null {
    const { t } = useTranslation();
    const { pop } = useModals();

    const close = useCallback(() => {
        pop();
    }, [pop]);

    return (
        <div
            className={`flex items-center p-escode-l border-t-2 gap-3 ${
                !showCloseButtonAtEnd ? 'justify-end' : 'justify-start'
            }`}
        >
            {!showCloseButtonAtEnd && !suppressCloseButton && (
                <Button
                    variant="danger"
                    onClick={close}
                    className="flex-1 max-w-1/2"
                >
                    {t('modal.close')}
                </Button>
            )}
            {children}
            {showCloseButtonAtEnd && !suppressCloseButton && (
                <Button variant="danger" onClick={close}>
                    {t('modal.close')}
                </Button>
            )}
        </div>
    );
}

export default ModalFooter;
