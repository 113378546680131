/* eslint-disable sort-keys-fix/sort-keys-fix */
import * as yup from 'yup';

const schema = yup.object({
    compressionMethod: yup.string().when('isCompressed', {
        is: 'yes',
        then: yup.string().required('Compression method is required'),
        otherwise: yup.string()
    }),
    containsPersonalData: yup
        .mixed<string | boolean>()
        .required('This field is required')
        .typeError('Please select an option'),
    documentation: yup.string(),
    encryptMethod: yup.string().when('isEncrypted', {
        is: 'yes',
        then: yup.string().required('Encryption method is required'),
        otherwise: yup.string()
    }),
    exportControlsDetails: yup.string().when('isSubjectToAnyExportControls', {
        is: 'yes',
        then: yup.string().required('This field is required'),
        otherwise: yup.string()
    }),
    isCompressed: yup
        .mixed<string | boolean>()
        .required('This field is required')
        .typeError('Please select an option'),
    isEncrypted: yup
        .mixed<string | boolean>()
        .required('This field is required')
        .typeError('Please select an option'),
    isPasswordRequired: yup
        .mixed<string | boolean>()
        .required('This field is required')
        .typeError('Please select an option'),
    isReplacementOfPreviousDeposit: yup.mixed<string | boolean>(),
    isSubjectToAnyExportControls: yup
        .mixed<string | boolean>()
        .required('This field is required')
        .typeError('Please select an option'),
    password: yup
        .string()
        .when('isPasswordRequired', {
            is: 'yes',
            then: yup
                .string()
                .required('This field is required')
                // .min(8, 'Password must exceed 8 characters')
                .max(150, 'Password cannot exceed 150 characters')
        })
        .typeError('Please enter a valid password')
        .nullable(),
    personalDataDetails: yup.string().when('containsPersonalData', {
        is: 'yes',
        then: yup.string().required('This field is required'),
        otherwise: yup.string()
    })
});

export default schema;
