import * as yup from 'yup';
import {
    Button,
    InputField,
    Modal,
    RadioGroupField,
    ResultModal,
    SelectField,
    TextareaField
} from 'lib-ui';
import { FormProvider, Path, SubmitHandler, useForm } from 'react-hook-form';
import {
    MouseEventHandler,
    useCallback,
    useEffect
} from 'react';
import { isUndefined } from 'lodash';
import { useModals } from '@ncc-frontend/core';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import authenticationTypeOptions from '../authentication-type-options';
import providerTypeOptions from '../provider-type-options';

import useBackendValidation from 'common/use-backend-validation';
import useProvidersEdit, {
    ProvidersEditParams
} from 'core/api/client-portal/hooks/providers/use-providers-edit';

type EditSourceControlConnectionFormValues = Omit<
    ProvidersEditParams['data'],
    'authenticationType' | 'providerType'
> & {
    authenticationType?: string;
    providerType?: string;
};

interface EditSourceControlConnectionModalProps
    extends EditSourceControlConnectionFormValues { }

function EditSourceControlConnectionModal({
    authenticationType,
    connectionName,
    password,
    privateKey,
    providerId,
    providerType,
    repositoryAddress,
    userName
}: EditSourceControlConnectionModalProps) {
    const { t } = useTranslation();
    const { pop, push } = useModals();
    const {
        error,
        isLoading,
        mutate: requestProvidersEdit
    } = useProvidersEdit<{
        errors: Record<
            | Path<EditSourceControlConnectionFormValues>
            | 'repositorySettingsNotValid',
            string[]
        >;
    }>({
        onError: (err) => {
            if (err.response.status !== 400) {
                pop();
                push(ResultModal, {
                    description: t('result.error-description'),
                    title: t('source-control-connection.edit.error-title')
                });
            }
        },
        onSuccess: () => {
            pop();
            push(ResultModal, {
                description: t(
                    'source-control-connection.edit.success-description'
                ),
                navigate: '/schedules',
                title: t('source-control-connection.edit.success-title')
            });
        }
    });
    const connectionFailed = !isUndefined(
        error?.response?.data?.errors?.repositorySettingsNotValid
    );

    const formSchema = yup.object({
        authenticationType: yup.string().when(['providerType'], {
            is: (providerType: string) => providerType === '1', // 1 == Git,
            otherwise: (schema) => schema,
            then: (schema) =>
                schema.required(
                    t('form.validation.required', {
                        field: t(
                            'add-source-control-connection.authentication-type'
                        )
                    })
                )
        }),
        connectionName: yup
            .string()
            .required(
                t('form.validation.required', {
                    field: t('add-source-control-connection.connection-name')
                })
            )
            .max(
                150,
                t('form.validation.max-length', {
                    field: t('add-source-control-connection.connection-name'),
                    length: 150
                })
            ),
        password: yup.string().when(['authenticationType'], {
            is: (authenticationType: string) => authenticationType === '1', // 1 == HTTPS
            otherwise: (schema) =>
                schema.required(
                    t('form.validation.required', {
                        field: t('add-source-control-connection.key-passphrase')
                    })
                ),
            then: (schema) =>
                schema.required(
                    t('form.validation.required', {
                        field: t('add-source-control-connection.password')
                    })
                )
        }),
        privateKey: yup.string().when(['providerType', 'authenticationType'], {
            is: (providerType: string, authenticationType: string) =>
                providerType !== '1' || // 1 === Git
                (providerType === '1' && authenticationType === '0'),
            otherwise: (schema) => schema,
            then: (schema) =>
                schema.required(
                    t('form.validation.required', {
                        field: t('add-source-control-connection.private-key')
                    })
                )
        }),
        providerType: yup.string().required(
            t('form.validation.required', {
                field: t('add-source-control-connection.provider-type')
            })
        ),
        repositoryAddress: yup.string().required(
            t('form.validation.required', {
                field: t('add-source-control-connection.repository-address')
            })
        ),
        userName: yup.string().required(
            t('form.validation.required', {
                field: t('add-source-control-connection.username')
            })
        )
    });
    const {
        handleSubmit: rhfHandleSubmit,
        setError,
        ...methods
    } = useForm<EditSourceControlConnectionFormValues>({
        defaultValues: {
            authenticationType: authenticationType || '',
            connectionName: connectionName || '',
            password: password || '',
            privateKey: privateKey || '',
            providerType: providerType || '',
            repositoryAddress: repositoryAddress || '',
            userName: userName || ''
        },
        mode: 'onTouched',
        resolver: yupResolver(formSchema)
    });
    const values = methods.watch();

    const showPrivateKey =
        values.providerType !== '1' ||
        (values.providerType === '1' && values.authenticationType === '0');

    const submit = useCallback<
        SubmitHandler<EditSourceControlConnectionFormValues>
    >(
        (formData) => {
            const data: ProvidersEditParams['data'] = {
                ...formData,
                authenticationType: undefined,
                privateKey: undefined,
                providerId,
                providerType: undefined
            };

            if (!isUndefined(formData.providerType)) {
                data.providerType = Number(formData.providerType);
            }
            if (
                formData.providerType === '1' && // 1 === Git
                !isUndefined(formData.authenticationType)
            ) {
                data.authenticationType = Number(formData.authenticationType);
            }
            if (showPrivateKey) {
                data.privateKey = formData.privateKey;
            }

            requestProvidersEdit({ data });
        },
        [providerId, requestProvidersEdit, showPrivateKey]
    );

    const triggerSubmit = useCallback(() => {
        rhfHandleSubmit(submit)();
    }, [rhfHandleSubmit, submit]);

    const handleDiscard = useCallback<MouseEventHandler<HTMLButtonElement>>(
        (event) => {
            event.preventDefault();
            pop();
        },
        [pop]
    );

    // TODO: remove this once tested
    // const preventFormDefault = useCallback<FormEventHandler<HTMLFormElement>>(
    //     (event) => {
    //         event.stopPropagation()
    //         event.preventDefault();
    //     },
    //     []
    // );

    useBackendValidation(error?.response?.data?.errors, setError, [
        'repositorySettingsNotValid'
    ]);

    useEffect(() => {
        if (
            !isUndefined(
                error?.response?.data?.errors?.repositorySettingsNotValid
            )
        ) {
            setError('password', {
                message: t('add-source-control-connection.key-passphrase')
            });
            setError('privateKey', {
                message: t('add-source-control-connection.private-key')
            });
            setError('userName', {
                message: t('add-source-control-connection.username')
            });
        }
    }, [error, setError, t]);

    return (
        <Modal className="w-96">
            <Modal.Header>
                {t('source-control-connection.edit.title', { id: providerId })}
            </Modal.Header>
            <Modal.Body className="flex flex-col gap-2.5">
                <FormProvider
                    {...methods}
                    setError={setError}
                    handleSubmit={rhfHandleSubmit}
                >
                    {/* <form
                        className="flex flex-col gap-2.5"
                        onSubmit={preventFormDefault}
                    > */}
                    <InputField
                        name="connectionName"
                        data-testid="connectionName"
                        label={t(
                            'add-source-control-connection.connection-name'
                        )}
                        placeholder={t(
                            'add-source-control-connection.connection-name-placeholder'
                        )}
                        required
                    />
                    <SelectField
                        name="providerType"
                        data-testid="providerType"
                        label={t(
                            'add-source-control-connection.provider-type'
                        )}
                        placeholder={t(
                            'add-source-control-connection.provider-type-placeholder'
                        )}
                        options={providerTypeOptions}
                        required
                        disabled
                    />
                    {/* // 1 == Git */}
                    {values.providerType === '1' && (
                        <RadioGroupField
                            name="authenticationType"
                            data-testid="authenticationType"
                            label={t(
                                'add-source-control-connection.authentication-type'
                            )}
                            options={authenticationTypeOptions}
                            required
                            readOnly
                        />
                    )}
                    <InputField
                        name="repositoryAddress"
                        data-testid="repositoryAddress"
                        label={t(
                            'add-source-control-connection.repository-address'
                        )}
                        placeholder="viewtest.git"
                        required
                        disabled
                    />
                    <InputField
                        name="userName"
                        data-testid="userName"
                        label={t('add-source-control-connection.username')}
                        placeholder="john.smith"
                        required
                    />
                    {showPrivateKey && (
                        <TextareaField
                            name="privateKey"
                            data-testid="privateKey"
                            label={t(
                                'add-source-control-connection.private-key'
                            )}
                            placeholder={t(
                                'add-source-control-connection.private-key-placeholder'
                            )}
                            required
                        />
                    )}
                    <InputField
                        name="password"
                        data-testid="password"
                        type="password"
                        label={
                            showPrivateKey
                                ? t(
                                    'add-source-control-connection.key-passphrase'
                                )
                                : t(
                                    'add-source-control-connection.password'
                                )
                        }
                        placeholder={t(
                            'add-source-control-connection.key-passphrase-placeholder'
                        )}
                        required
                    />

                    {connectionFailed && (
                        <span className="text-center text-general-red-red-100">
                            {t(
                                'add-source-control-connection.invalid-repository-settings'
                            )}
                        </span>
                    )}

                    <div className="grid grid-cols-2 gap-2.5">
                        <Button variant="danger" onClick={handleDiscard}>
                            {t('add-source-control-connection.discard')}
                        </Button>
                        <Button
                            variant={
                                connectionFailed ? 'danger' : 'primary'
                            }
                            loading={isLoading}
                            disabled={!methods.formState.isDirty}
                            onClick={triggerSubmit}
                            className="w-full"
                        >
                            {connectionFailed
                                ? t(
                                    'add-source-control-connection.retry'
                                )
                                : t(
                                    'source-control-connection.edit.submit'
                                )}
                        </Button>
                    </div>
                    {/* </form> */}
                </FormProvider>
            </Modal.Body>
        </Modal>
    );
}

export default EditSourceControlConnectionModal;
export type { EditSourceControlConnectionModalProps };
