import * as yup from 'yup';

const requiredMessage = 'This is required';
const countryMessage = 'This is required. Please select a country';
const schema = yup.object({
    addressLine1: yup.string().required(requiredMessage),
    addressLine2: yup.string(),
    addressLine3: yup.string(),
    city: yup.string().required(requiredMessage),
    companyName: yup.string().required(requiredMessage),
    contactName: yup.string().required(requiredMessage),
    country: yup.string().required(countryMessage).typeError(countryMessage),
    county: yup.string(),
    emailAddress: yup.string().required(requiredMessage),
    jobTitle: yup.string().required(requiredMessage),
    postCode: yup.string().required(requiredMessage),
    telephoneNumber: yup.string().required(requiredMessage)
});

export default schema;
