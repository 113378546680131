import { FilterFieldData } from '../../data-widget-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheck,
    faDiamondExclamation,
    faRightLong
} from '@fortawesome/pro-solid-svg-icons';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import ConfirmationTooltip from '../../../../tooltip/confirmation-tooltip';

interface FilterFieldProps {
    active?: boolean;
    columnHidden: boolean;
    data: FilterFieldData;
    onSelect?: (field: FilterFieldData) => void;
    readonly?: boolean;
}

function FilterField({
    active,
    columnHidden,
    data,
    onSelect,
    readonly
}: FilterFieldProps) {
    const { t } = useTranslation();

    const cssClasses = classNames(
        'relative group flex gap-1.5 items-center px-4 py-2.5 rounded-lg',
        'hover:bg-brand-escode-neonblue-neonblue-10',
        'cursor-pointer',
        'transition-all',
        {
            'bg-escode-green/20': active,
            'bg-general-orange-orange-100-20': columnHidden && !active
        }
    );

    const iconCssClasses = classNames(
        'absolute top-1/2 right-4 -translate-y-1/2',
        'transition-all'
    );

    const arrowIconCssClasses = classNames(
        'text-transparent',
        {
            'group-hover:text-brand-escode-neonblue-neonblue-100': !readonly
        },
        iconCssClasses
    );

    const checkIconCssClasses = classNames(
        {
            'text-escode-green group-hover:text-transparent': active,
            'text-transparent': !active
        },
        iconCssClasses
    );

    const readonlyIconWrapper = classNames(
        'absolute top-1/2 w-5 h-5 right-4 -translate-y-1/2',
        'flex items-center justify-center',
        'rounded-full',
        'transition-all',
        {
            'bg-escode-grey-100 group-hover:bg-brand-escode-neonblue-neonblue-100':
                readonly
        }
    );

    const readonlyIcon = classNames({
        'text-transparent': !readonly,
        'text-white': readonly
    });

    const handleClick = useCallback(() => {
        onSelect?.(data);
    }, [data, onSelect]);

    return (
        <ConfirmationTooltip
            icon={<FontAwesomeIcon icon={faDiamondExclamation} />}
            title={t('filters.column-disable-tooltip-title')}
            message={t('filters.column-disable-tooltip-message')}
            confirmMessage={t('tooltip.proceed')}
            variant="warning"
            suppressConfirmation={!columnHidden || readonly}
            onConfirm={handleClick}
            placement="left"
        >
            <div className={cssClasses}>
                {data.icon} {data.name}{' '}
                <FontAwesomeIcon
                    icon={faRightLong}
                    className={arrowIconCssClasses}
                />
                <FontAwesomeIcon
                    icon={faCheck}
                    className={checkIconCssClasses}
                />
                <div className={readonlyIconWrapper}>
                    <FontAwesomeIcon
                        icon={faCircleInfo}
                        className={readonlyIcon}
                    />
                </div>
            </div>
        </ConfirmationTooltip>
    );
}

export default FilterField;
export type { FilterFieldProps };
