import { BarPlot, BarPlotProps, SkeletonLine } from 'lib-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TailwindStyle } from '@ncc-frontend/core';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { merge } from 'lodash';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import DashboardWidget, { DashboardWidgetProps } from '../dashboard-widget';
import useDepositsList from 'core/api/client-portal/hooks/deposits/use-deposits-list';
import useIncompleteDeposits from 'core/api/client-portal/hooks/deposits/use-incomplete-deposits';

interface DepositTrackerWidgetProps extends DashboardWidgetProps {}

function DepositTrackerWidget({
    className,
    tailwindStyle,
    ...restProps
}: DepositTrackerWidgetProps) {
    const { i18n, t } = useTranslation();

    const {
        data: deposits,
        isFetching: isFetchingDeposits,
        isLoading: isLoadingDeposits
    } = useDepositsList();
    const {
        data: incompleteDeposits,
        isFetching: isFetchingIncomplete,
        isLoading: isLoadingIncomplete
    } = useIncompleteDeposits();
    const loading =
        isLoadingDeposits ||
        isFetchingDeposits ||
        isLoadingIncomplete ||
        isFetchingIncomplete;

    const { current: months } = useRef(
        new Array(12).fill(0).map((_item, index) =>
            new Date(2023, index).toLocaleString(i18n.language, {
                month: 'short'
            })
        )
    );

    const data = useMemo<BarPlotProps['data']>(() => {
        const today = new Date();

        const completedDeposits = deposits?.deposits?.filter(
            (deposit) =>
                !incompleteDeposits?.deposits?.some(
                    (incompleteDeposit) =>
                        incompleteDeposit.agreementId === deposit.agreementId &&
                        incompleteDeposit.depositId === deposit.depositId
                )
        );

        return [
            {
                color: '#000FFF', // brand-escode-neonblue-neonblue-100
                x: months,
                y: new Array(12).fill(0).map(
                    (_, month) =>
                        completedDeposits?.filter((deposit) => {
                            if (!deposit.depositDate) return false;

                            const depositDate = new Date(deposit.depositDate);
                            return (
                                depositDate >=
                                    new Date(today.getFullYear(), month) &&
                                depositDate <
                                    new Date(today.getFullYear(), month + 1)
                            );
                        }).length ?? 0
                )
            },
            {
                color: '#0B1932',
                // brand-escode-neonblue-neonblue-80
                x: months,
                y: new Array(12).fill(0).map(
                    (_, month) =>
                        incompleteDeposits?.deposits?.filter((deposit) => {
                            if (!deposit.created) return false;

                            const createdDate = new Date(deposit.created);
                            return (
                                createdDate >=
                                    new Date(today.getFullYear(), month) &&
                                createdDate <
                                    new Date(today.getFullYear(), month + 1)
                            );
                        }).length ?? 0
                )
            }
        ];
    }, [deposits?.deposits, incompleteDeposits?.deposits, months]);

    return (
        <DashboardWidget
            {...restProps}
            className={className}
            tailwindStyle={merge<TailwindStyle, TailwindStyle | undefined>(
                {
                    flexDirection: 'flex-row',
                    gap: 'gap-1.5'
                },
                tailwindStyle
            )}
        >
            <div className="flex flex-col gap-5">
                <DashboardWidget.Header
                    title={t('dashboard.deposit-tracker-widget.title')}
                    loading={loading}
                />
                <div className="flex flex-col gap-3.5">
                    <div className="relative flex items-center gap-2">
                        {loading && <SkeletonLine absolute />}
                        <FontAwesomeIcon
                            className="text-brand-escode-tourmalineblack-tourmalineblack-100 text-escode-xxxs"
                            icon={faCircle}
                        />
                        {t('dashboard.deposit-tracker-widget.completed')}
                    </div>
                    <div className="relative flex items-center gap-2">
                        {loading && <SkeletonLine absolute />}
                        <FontAwesomeIcon
                            className="text-brand-escode-neonblue-neonblue-100 text-escode-xxxs"
                            icon={faCircle}
                        />
                        {t('dashboard.deposit-tracker-widget.incomplete')}
                    </div>
                </div>
            </div>
            <BarPlot
                className="flex-1 px-4 py-2.5"
                data={data}
                loading={loading}
            />
        </DashboardWidget>
    );
}

export default DepositTrackerWidget;
export type { DepositTrackerWidgetProps };
