import { Modal } from 'lib-ui';
import { useTranslation } from 'react-i18next';
import DEPOSIT_STATE_MAPPER from 'core/api/client-portal/model/deposit-state';

import { GetIncompleteDepositsResponse } from 'core/api/client-portal/autogenerated/data-contracts';
import IncompleteDeposit from 'ui/deposits/incomplete-deposit';
import useIncompleteDepositsV2 from 'core/api/client-portal-v2/hooks/deposit/use-incomplete-deposits-v2';

type IncompleteDepositType = {
    agreementId: number;
    agreementNumber: number;
    created: string;
    createdByUserId: string;
    depositId: number;
    lastSavedState: number;
    softwarePackageName: string;
    versionOrReleaseNumber: string;
};

function IncompleteDepositsModal() {
    const { t } = useTranslation();

    const { data: incompleteDeposits } =
        useIncompleteDepositsV2<GetIncompleteDepositsResponse>();

    return (
        <Modal className="overflow-y-auto" size="m">
            <Modal.Header>
                {t('incomplete-deposits-widget.heading')}
            </Modal.Header>
            <Modal.Body className="flex flex-col gap-2.5">
                {incompleteDeposits?.deposits?.map(
                    (item: IncompleteDepositType) => (
                        <IncompleteDeposit
                            key={`${item.agreementId}-${item.depositId}`}
                            agreementId={item.agreementId}
                            agreementNumber={item.agreementNumber}
                            created={item.created}
                            depositId={item.depositId}
                            name={item.softwarePackageName}
                            state={
                                DEPOSIT_STATE_MAPPER[item.lastSavedState ?? 0]
                            }
                            version={item.versionOrReleaseNumber}
                        />
                    )
                )}
            </Modal.Body>
        </Modal>
    );
}

export default IncompleteDepositsModal;
export type { IncompleteDepositType };
