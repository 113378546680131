import { AgreementDepositStatus } from 'core/api/client-portal/model/agreement-deposit-status';
import { CSSProperties, PropsWithChildren, useMemo } from 'react';
import { Tag } from 'lib-ui';

interface AgreementDepositStatusTagProps {
    variant: AgreementDepositStatus | undefined;
}

function AgreementDepositStatusTag({
    children,
    variant,
    ...restProps
}: PropsWithChildren<AgreementDepositStatusTagProps>) {
    const style = useMemo<CSSProperties>(
        () =>
            variant === 'never'
                ? { background: '#d9dce1', color: '#505b6d' } // black
                : variant === 'overdue'
                ? { background: '#FAE5EB', color: '#D93362' } // red
                : variant === 'standard'
                ? {
                      background: '#ffffff',
                      border: '1px solid #d9dce1',
                      color: '#505b6d'
                  } // yellow
                : variant === 'verified'
                ? { background: '#99FFBB', color: '#3C475B' } // green
                : {},
        [variant]
    );

    return (
        <Tag {...restProps} style={style}>
            {children}
        </Tag>
    );
}

export default AgreementDepositStatusTag;
export type { AgreementDepositStatusTagProps };
