import { Modal } from 'lib-ui';
import { useTranslation } from 'react-i18next';
type OversizedfilesModalParams = {
    isOnPrem?: boolean
}

function OversizedfilesModal({ isOnPrem }: OversizedfilesModalParams) {
    const { t } = useTranslation();

    return (
        <Modal className="overflow-y-auto" size="s">
            <Modal.Header>
                <h2 className="text-general-red-red-100 text-xl">
                    {t('file-size-exceeded')}
                </h2>
            </Modal.Header>
            <span className="py-4 mt-2">
                {
                    isOnPrem ?
                        t('file-size-exceeded-message')
                        : t('file-size-exceeded-vv-message')

                }
            </span>
            <Modal.Body className="flex flex-col gap-2.5"></Modal.Body>
        </Modal>
    );
}

export default OversizedfilesModal;
