import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import useBeautify from 'core/beautifiers/use-beautify';

interface CalendarWeekHeaderDayProps {
    active?: boolean;
    date: Date;
}

function CalendarWeekHeaderDay({
    active = false,
    date
}: CalendarWeekHeaderDayProps) {
    const { i18n } = useTranslation();
    const { beautifyOrdinal } = useBeautify();

    return (
        <th
            role="columnheader"
            className={classNames(
                {
                    'text-brand-escode-neonblue-neonblue-100 font-medium':
                        active,
                    'text-general-grey-grey-90 font-normal': !active
                },
                'py-5'
            )}
        >
            {beautifyOrdinal(
                date.toLocaleString(i18n.language, {
                    day: '2-digit',
                    weekday: 'short'
                })
            )}
        </th>
    );
}

export default CalendarWeekHeaderDay;
export type { CalendarWeekHeaderDayProps };
