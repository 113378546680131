import { ComponentType, PropsWithChildren } from 'react';
import BaseModal, { ModalProps } from './modal';
import ModalBody, { ModalBodyProps } from './modal-body';
import ModalFooter, { ModalFooterProps } from './modal-footer';
import ModalHeader, { ModalHeaderProps } from './modal-header';

type ModalType = ComponentType<PropsWithChildren<ModalProps>> & {
    Body: ComponentType<PropsWithChildren<ModalBodyProps>>;
    Footer: ComponentType<PropsWithChildren<ModalFooterProps>>;
    Header: ComponentType<PropsWithChildren<ModalHeaderProps>>;
};
const Modal = BaseModal as ModalType;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;
Modal.Header = ModalHeader;

export default Modal;
export type { ModalBodyProps, ModalHeaderProps, ModalProps, ModalType };
