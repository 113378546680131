import {
    CalendarItem as CoreCalendarItem,
    CalendarItemProps as CoreCalendarItemProps,
    TailwindStyle
} from '@ncc-frontend/core';
import { memo } from 'react';
import { merge } from 'lodash';
import { useMemo } from 'react';

interface CalendarItemProps
    extends Omit<
        CoreCalendarItemProps,
        'buttonComponent' | 'buttonGroupComponent' | 'calendarItemComponent'
    > {}

function CalendarItem({
    active,
    highlight,
    highlightTailwindStyle,
    tailwindStyle,
    ...restProps
}: CalendarItemProps) {
    const mergedTailwindStyle = useMemo<TailwindStyle>(
        () =>
            merge<TailwindStyle, TailwindStyle | undefined>(
                {
                    background: active
                        ? 'bg-brand-escode-neonblue-neonblue-100'
                        : undefined,
                    textColor: active && 'text-ncc-white',
                    variant: {
                        hover: {
                            background:
                                'hover:bg-brand-escode-neonblue-neonblue-10',
                            textColor:
                                'hover:text-brand-escode-neonblue-neonblue-80'
                        }
                    }
                },
                tailwindStyle
            ),
        [active, tailwindStyle]
    );

    const mergedHighlightTailwindStyle = useMemo<TailwindStyle>(
        () =>
            merge<TailwindStyle, TailwindStyle | undefined>(
                {
                    background: !!highlight
                        ? 'bg-brand-escode-neonblue-neonblue-10'
                        : undefined
                },
                highlightTailwindStyle
            ),
        [highlight, highlightTailwindStyle]
    );

    return (
        <CoreCalendarItem
            {...restProps}
            highlight={highlight}
            tailwindStyle={mergedTailwindStyle}
            highlightTailwindStyle={mergedHighlightTailwindStyle}
        />
    );
}

export default memo(CalendarItem);
export type { CalendarItemProps };
