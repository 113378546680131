import { ContactViewModel } from 'core/api/client-portal/autogenerated/data-contracts';
import { ContactsTableContext } from './contacts-table-context';
import { FilterFieldData } from 'lib-ui/widget/data/data-widget-types';
import { ICellRendererParams, ValueGetterParams } from 'lib-ui';
import { memo } from 'react';

import ContactStatusTag from './contact-status-tag';
import getContactStatus, {
    ContactStatus
} from 'core/api/client-portal/model/contact-status';

function getContactStatusString({
    context: { t },
    status
}: Pick<
    ValueGetterParams<ContactViewModel, ContactsTableContext>,
    'context'
> & { status: ContactStatus }): string {
    if (!status) return '';
    return t(`contacts.status-${status}`);
}

function getContactsStatusOptions(
    context: ContactsTableContext
): FilterFieldData['options'] {
    return [
        {
            key: 'disabled',
            label: getContactStatusString({
                context,
                status: 'disabled'
            })
        },
        {
            key: 'pending',
            label: getContactStatusString({
                context,
                status: 'pending'
            })
        },
        {
            key: 'invite',
            label: getContactStatusString({
                context,
                status: 'invite'
            })
        }
    ];
}

function contactStatusValueGetter({
    context,
    data
}: ValueGetterParams<ContactViewModel, ContactsTableContext>) {
    const status = getContactStatus(data);
    return getContactStatusString({ context, status });
}

function ContactStatusCellRenderer({
    data,
    value
}: ICellRendererParams<ContactViewModel, ContactsTableContext>) {
    const status = getContactStatus(data);

    return (
        <div className="flex h-full items-center">
            <ContactStatusTag status={status}>{value}</ContactStatusTag>
        </div>
    );
}

export default memo(ContactStatusCellRenderer);
export { contactStatusValueGetter, getContactsStatusOptions };
