import AgreementStep from '../../ui/deposit-now/agreement-step/agreement-step';
import DepositNowContainer from '../../ui/deposit-now/deposit-now-container';
import React from 'react';

const DepositNowAgreementSelect = () => {
    return (
        <DepositNowContainer step="agreement">
            <AgreementStep />
        </DepositNowContainer>
    );
};

export default DepositNowAgreementSelect;
