import { useContext } from 'react';

import { DataWidgetContext } from './data-widget-provider';

function useDataWidget() {
    const context = useContext(DataWidgetContext);

    if (!context) throw new Error('No auth root!');

    return context;
}

export default useDataWidget;
