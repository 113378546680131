const useIncompleteDepositsV2MockData = [
    {
        agreementId: 7,
        agreementNumber: 35434,
        created: '2023-03-20T15:51:47.082539Z',
        createdByUserId: 'c54f70aa-7576-4f68-9ff7-490c3d304c64',
        depositId: 1674,
        lastSavedState: 5,
        softwarePackageName: 'Test Software 1',
        versionOrReleaseNumber: 'Test'
    },
    {
        agreementId: 7,
        agreementNumber: 35434,
        created: '2024-04-22T13:14:43.750704Z',
        createdByUserId: 'da836e78-258b-409f-b836-8e72b1ee1f63',
        depositId: 3039,
        lastSavedState: 3,
        softwarePackageName: 'Test Software 2',
        versionOrReleaseNumber: 'v4.0.0.0'
    },
    {
        agreementId: 108,
        agreementNumber: 35726,
        created: '2024-03-27T18:27:35.538019Z',
        createdByUserId: 'da836e78-258b-409f-b836-8e72b1ee1f63',
        depositId: 2749,
        lastSavedState: 1,
        softwarePackageName: 'TIBCO Adapter SDK',
        versionOrReleaseNumber: null
    }
];

export default useIncompleteDepositsV2MockData;
