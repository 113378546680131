import AppBody from './app-body';
import Schedules from 'ui/schedules/schedules';

function SchedulesBody() {
    return (
        <AppBody>
            <Schedules />
        </AppBody>
    );
}

export default SchedulesBody;
