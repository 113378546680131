import { Dropdown, useLocalStorage } from '@ncc-frontend/core';
import { FilterData } from './data-widget-types';
import {
    ForwardedRef,
    PropsWithChildren,
    ReactNode,
    forwardRef,
    useCallback,
    useImperativeHandle,
    useMemo,
    useState
} from 'react';
import { useTranslation } from 'react-i18next';

import DataWidgetProvider, {
    DataWidgetContextValue
} from './data-widget-provider';
import FilterButton from './filter-button/filter-button';
import Pager, { PagerProps } from '../../pager/pager';
import ResetButton from './reset-button/reset-button';
import SearchBar, { SearchBarProps } from '../../search-bar/search-bar';
import SelectFieldsButton from './select-fields-button/select-fields-button';
import Widget, { WidgetProps } from '../base/widget';

interface DataWidgetProps extends WidgetProps {
    disableFilter?: boolean;
    disablePager?: boolean;
    disableReset?: boolean;
    disableSearch?: boolean;
    error?: number;
    exportDataAsCsv?: DataWidgetContextValue['exportDataAsCsv'];
    filterableFields?: DataWidgetContextValue['filterableFields'];
    getDataAsCsv?: DataWidgetContextValue['getDataAsCsv'];
    hideTableActions?: boolean;
    id: string;
    loading?: boolean;
    onApplyFilters?: DataWidgetContextValue['onApplyFilters'];
    onHiddenFieldsChange?: DataWidgetContextValue['onHiddenFieldsChange'];
    onPageChange: DataWidgetContextValue['onPageChange'];
    onQuickSearchChange?: DataWidgetContextValue['setQuickSearchValue'];
    onReset?: DataWidgetContextValue['onReset'];
    post?: ReactNode;
    pre?: ReactNode;
    resetPageLocationOnSearch?: boolean;
    selectableFields?: DataWidgetContextValue['selectableFields'];
    suppressSaveFilter?: DataWidgetContextValue['suppressSaveFilter'];
    title?: string;
}

function DataWidget(
    {
        children,
        className,
        disableFilter,
        disablePager,
        disableReset,
        disableSearch,
        error,
        filterableFields,
        hideTableActions = false,
        id,
        loading = false,
        onApplyFilters,
        onClick,
        onHiddenFieldsChange,
        onPageChange,
        onQuickSearchChange,
        onReload,
        onReset,
        post,
        pre,
        resetPageLocationOnSearch: resetPageOnSearch,
        selectableFields,
        style,
        suppressSaveFilter = false,
        tailwindStyle,
        title
    }: PropsWithChildren<DataWidgetProps>,
    ref: ForwardedRef<DataWidgetContextValue>
) {
    const { t } = useTranslation();

    const [filters, setFilters] = useLocalStorage<FilterData[]>(
        `current-filter-${id}`,
        []
    );
    const [hiddenFields, setHiddenFields] = useState<
        DataWidgetContextValue['hiddenFields']
    >({});
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [quickSearchValue, setQuickSearchValue] = useState('');

    const contextSetQuickSearchValue = useCallback(
        (value: string) => {
            setQuickSearchValue(value);
            onQuickSearchChange?.(value);
        },
        [onQuickSearchChange, setQuickSearchValue]
    );

    const goToPage = useCallback<DataWidgetContextValue['goToPage']>(
        (toPage) => {
            setPage(toPage);
            onPageChange(toPage);
        },
        [onPageChange]
    );

    const handleQuickSearchChange = useCallback<
        NonNullable<SearchBarProps['onChange']>
    >(
        (event) => {
            contextSetQuickSearchValue(event.target.value);
            if (resetPageOnSearch) {
                goToPage(1);
            }
        },
        [contextSetQuickSearchValue, goToPage, resetPageOnSearch]
    );

    const handlePageChange = useCallback<PagerProps['onChange']>(
        (toPage) => {
            setPage(toPage);
            onPageChange(toPage);
        },
        [onPageChange]
    );

    const applyFilters = useCallback<DataWidgetContextValue['onApplyFilters']>(
        (filtersToApply) => {
            console.log('Filters to apply:', filtersToApply);
            setFilters(filtersToApply);
            onApplyFilters?.(filtersToApply);
        },
        [onApplyFilters, setFilters]
    );

    const context = useMemo<DataWidgetContextValue>(
        () => ({
            filterableFields,
            filters,
            goToPage,
            hiddenFields,
            id,
            loading,
            onApplyFilters: applyFilters,
            onHiddenFieldsChange,
            onPageChange,
            onReset,
            quickSearchValue,
            selectableFields,
            setHiddenFields,
            setPage,
            setQuickSearchValue: contextSetQuickSearchValue,
            setTotalPages,
            suppressSaveFilter
        }),
        [
            applyFilters,
            contextSetQuickSearchValue,
            filterableFields,
            filters,
            goToPage,
            hiddenFields,
            id,
            loading,
            onHiddenFieldsChange,
            onPageChange,
            onReset,
            quickSearchValue,
            selectableFields,
            setPage,
            suppressSaveFilter
        ]
    );

    useImperativeHandle(ref, () => context);

    return (
        <DataWidgetProvider value={context}>
            <Widget
                className={className}
                error={error}
                onClick={onClick}
                onReload={onReload}
                style={style}
                tailwindStyle={tailwindStyle}
            >
                <Widget.Header title={title}>
                    {!hideTableActions && (
                        <>
                            <SearchBar
                                data-testid="input"
                                className="ml-auto mr-auto flex-1"
                                placeholder={`${t('table.search')}...`}
                                value={quickSearchValue}
                                onChange={handleQuickSearchChange}
                                disabled={loading || disableSearch}
                            />

                            <Dropdown
                                multiTrigger
                                className="flex gap-1.5"
                                suppressCloseOnClick
                                suppressMaxSize
                                disabled={loading}
                            >
                                {pre}

                                {totalPages > 1 && (
                                    <Pager
                                        currentPage={page}
                                        disabled={loading || disablePager}
                                        onChange={handlePageChange}
                                        totalPages={totalPages}
                                    />
                                )}

                                {onReset !== undefined && (
                                    <ResetButton disabled={disableReset} />
                                )}

                                {filterableFields !== undefined &&
                                    onApplyFilters !== undefined && (
                                        <FilterButton
                                            disabled={disableFilter}
                                        />
                                    )}

                                {selectableFields !== undefined &&
                                    onHiddenFieldsChange !== undefined && (
                                        <SelectFieldsButton />
                                    )}

                                {post}
                            </Dropdown>
                        </>
                    )}
                </Widget.Header>

                <Widget.Body>{children}</Widget.Body>
            </Widget>
        </DataWidgetProvider>
    );
}

export default forwardRef(DataWidget);
export type { DataWidgetProps };
