import { Asterisk, Optional } from 'lib-ui';
import { PropsWithChildren, ReactNode } from 'react';

import { useFormContext } from 'react-hook-form';

import cn from 'core/utils/cn';

type CheckFieldTypes = {
    checkboxStyles?: string;
    checked?: boolean;
    errorPosition?: 'left' | 'bottom' | 'right';
    id: string;
    label: string | number | ReactNode;
    name: string;
    optional?: boolean;
    orientation?: 'text-first' | 'checkbox-first';
    required?: boolean;
};

export default function CheckField({
    checkboxStyles,
    errorPosition = 'right',
    id,
    label,
    name,
    optional,
    orientation = 'text-first',
    required,
    ...restProps
}: PropsWithChildren<CheckFieldTypes>) {
    const {
        formState: { errors },
        register
    } = useFormContext();

    const errorStyles = 'text-sm text-general-red-red-100';

    return (
        <div>
            <div
                className={cn(
                    'flex gap-2 items-center w-full justify-start',
                    orientation === 'text-first' && 'flex-row',
                    orientation === 'checkbox-first' &&
                        'flex-row-reverse justify-end'
                )}
                key={id}
            >
                {orientation === 'checkbox-first' &&
                    errorPosition === 'left' &&
                    errors[name] && (
                        <div className={errorStyles}>
                            <p>
                                {!!errors[name] ? (
                                    <>{errors[name]?.message}</>
                                ) : undefined}
                            </p>
                        </div>
                    )}
                <label className="cursor-pointer" htmlFor={id}>
                    {label} {required && <Asterisk />}{' '}
                    {optional && <Optional />}
                </label>
                <input
                    className={cn(
                        'accent-brand-escode-neonblue-neonblue-100 hover:accent-brand-escode-neonblue-neonblue-100 active:accent-brand-escode-neonblue-neonblue-100 focus:ring-brand-escode-neonblue-neonblue-100 transition-all duration-300 w-4 h-4',
                        checkboxStyles
                    )}
                    id={id}
                    type="checkbox"
                    {...register(name)}
                    {...restProps}
                />
                {orientation === 'text-first' &&
                    errorPosition === 'right' &&
                    errors[name] && (
                        <div className={errorStyles}>
                            {!!errors[name] && <>{errors[name]?.message}</>}
                        </div>
                    )}
            </div>
            {errorPosition === 'bottom' && !!errors[name] && (
                <div className={errorStyles}>
                    <>{errors[name]?.message}</>
                </div>
            )}
        </div>
    );
}
