import { useCallback, useState } from 'react';

import ScheduleDepositWidget from './schedule-deposit-widget/schedule-deposit-widget';
import SchedulesContextProvider from './schedules-context-provider';
import SchedulesMetrics from './schedules-metrics/schedules-metrics';
import SchedulesWidget, {
    SchedulesWidgetProps
} from './schedules-widget/schedules-widget';
import useSchedulesList from 'core/api/client-portal/hooks/schedules/use-schedules-list';

function Schedules() {
    const [currentTab, setCurrentTab] = useState<string | undefined>('table');

    const {
        data: schedules,
        error,
        isFetching,
        isLoading,
        refetch
    } = useSchedulesList();
    const loading = isLoading || isFetching;

    const reload = useCallback(() => {
        refetch();
    }, [refetch]);

    const handleTabChange = useCallback<
        NonNullable<SchedulesWidgetProps['onTabChange']>
    >((tabId) => {
        setCurrentTab(tabId);
    }, []);

    return (
        <SchedulesContextProvider>
            <div className="flex gap-5 h-full w-full">
                <SchedulesWidget
                    currentTab={currentTab}
                    data={schedules?.schedules}
                    loading={loading}
                    error={error?.response.status}
                    onReload={reload}
                    onTabChange={handleTabChange}
                    className="flex-1"
                />
                {currentTab === 'table' ? (
                    <SchedulesMetrics data={schedules?.schedules} />
                ) : (
                    <ScheduleDepositWidget />
                )}
            </div>
        </SchedulesContextProvider>
    );
}

export default Schedules;
