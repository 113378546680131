import { FilterFieldData } from 'lib-ui/widget/data/data-widget-types';
import { ICellRendererParams, ValueGetterParams } from 'lib-ui';
import { memo } from 'react';

import { AgreementViewModel } from 'core/api/client-portal/autogenerated/data-contracts';
import { AgreementsTableContext } from './agreements-table-context';
import AgreementStatusTag, { Variant } from './agreement-status-tag';

function getAgreementStatus(agreement: AgreementViewModel): Variant {
    switch (agreement.agreementStatus) {
        case 'Provisional':
            return 'provisional';
        case 'Live':
            return 'live';
        default:
            return;
    }
}

function getAgreementStatusString({
    context: { t },
    status
}: Pick<
    ValueGetterParams<AgreementViewModel, AgreementsTableContext>,
    'context'
> & { status: Variant }) {
    if (!status) return;
    return t(`agreement.status-${status}`);
}

function getAgreementStatusOptions(
    context: AgreementsTableContext
): FilterFieldData['options'] {
    return [
        {
            key: 'live',
            label: getAgreementStatusString({
                context,
                status: 'live'
            })
        },
        {
            key: 'provisional',
            label: getAgreementStatusString({
                context,
                status: 'provisional'
            })
        }
    ];
}

function agreementStatusValueGetter({
    context,
    data
}: ValueGetterParams<AgreementViewModel, AgreementsTableContext>) {
    const status = getAgreementStatus(data);
    return getAgreementStatusString({ context, status });
}

function AgreementStatusCellRenderer({
    data,
    value
}: ICellRendererParams<AgreementViewModel>) {
    return (
        <div className="flex h-full items-center">
            <AgreementStatusTag variant={getAgreementStatus(data)}>
                {value}
            </AgreementStatusTag>
        </div>
    );
}

export default memo(AgreementStatusCellRenderer);
export {
    agreementStatusValueGetter,
    getAgreementStatus,
    getAgreementStatusOptions,
    getAgreementStatusString
};
