/* eslint-disable sort-keys-fix/sort-keys-fix */
import * as yup from 'yup';

const schema = yup.object({
    confirmed: yup
        .boolean()
        .oneOf([true], 'This is a required field')
        .typeError('This is a required field'),
    read: yup
        .boolean()
        .oneOf([true], 'This is a required field')
        .typeError('This is a required field')
});

export default schema;
