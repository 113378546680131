import {
    Dispatch,
    PropsWithChildren,
    SetStateAction,
    createContext
} from 'react';
import { FieldData, FilterData, FilterFieldData } from './data-widget-types';
import { GridApi } from 'ag-grid-community';
import { PagerProps } from '../../pager/pager';

interface DataWidgetContextValue {
    exportDataAsCsv?: GridApi['exportDataAsCsv'];
    filterableFields?: FilterFieldData[];
    filters: FilterData[];
    getDataAsCsv?: GridApi['getDataAsCsv'];
    goToPage: (toPage: number) => void;
    hiddenFields: Record<string, boolean>;
    id: string;
    loading: boolean;
    onApplyFilters: (filters: FilterData[]) => void;
    onHiddenFieldsChange?: (value: Record<string, boolean>) => void;
    onPageChange: PagerProps['onChange'];
    onReset?: () => void;
    quickSearchValue: string;
    selectableFields?: FieldData[];
    setHiddenFields: Dispatch<SetStateAction<Record<string, boolean>>>;
    setQuickSearchValue: (value: string) => void;
    setTotalPages: Dispatch<SetStateAction<number>>;
    suppressSaveFilter: boolean;
}

const DataWidgetContext = createContext<DataWidgetContextValue | undefined>(
    undefined
);

interface DataWidgetProviderProps {
    value: DataWidgetContextValue;
}

function DataWidgetProvider({
    children,
    value
}: PropsWithChildren<DataWidgetProviderProps>) {
    return (
        <DataWidgetContext.Provider value={value}>
            {children}
        </DataWidgetContext.Provider>
    );
}

export default DataWidgetProvider;
export { DataWidgetContext };
export type { DataWidgetContextValue, DataWidgetProviderProps };
