import { AgreementViewModel } from 'core/api/client-portal/autogenerated/data-contracts';
import { FilterFieldData } from 'lib-ui/widget/data/data-widget-types';
import { ValueGetterParams } from 'lib-ui';

import { AgreementsTableContext } from './agreements-table-context';

type AgreementType =
    | '3-party-(single)'
    | '4-party-legacy-agreement'
    | 'ams-holding-agreement'
    | 'ams-holding-agreement-english'
    | 'ams-information-escode-agreement'
    | 'ams-information-escode-agreement-english'
    | 'ams-ml-(epc)'
    | 'ams-ml-distributor-with-staggered-release'
    | 'ams-ml-distributor-with-staggered-release-english'
    | 'ams-ml-distributor-without-staggered-release'
    | 'ams-ml-distributor-without-staggered-release-english'
    | 'ams-ml-saas-data-secure'
    | 'ams-ml-saas-secure'
    | 'ams-ml-saas-with-data-bolt-on'
    | 'ams-mlddddm'
    | 'ams-multi-licensee'
    | 'ams-multi-licensee-(auto)'
    | 'ams-multi-licensee-english-lang'
    | 'ams-sl-distributor-with-staggered-release'
    | 'ams-sl-distributor-with-staggered-release-english'
    | 'ams-sl-distributor-without-staggered-release'
    | 'ams-sl-distributor-without-staggered-release-english'
    | 'ams-sl-saas-english'
    | 'ams-sl-saas-data-secure'
    | 'ams-sl-saas-secure'
    | 'ams-sl-saas-with-data-bolt-on'
    | 'ams-single-distributor'
    | 'ams-single-distributor-english'
    | 'ams-single-licensee-english'
    | 'ams-web-agreement'
    | 'atl-custodial-(multi-deposit)'
    | 'atl-escode-protection-certificate'
    | 'atl-hdiscovery'
    | 'atl-iea-epc'
    | 'atl-jp-morgan-data-holding-agreement'
    | 'atl-jp-morgan-deposit-account'
    | 'atl-jp-morgan-deposit-account-agreement'
    | 'atl-mdha'
    | 'atl-mdha-deposit-account'
    | 'atl-ml-saas-data-secure'
    | 'atl-ml-saas-secure'
    | 'atl-ml-saas-with-data-bolt-on'
    | 'atl-microsoft-azure-saas-secure'
    | 'atl-multiowner'
    | 'atl-ncc-group-custodial-(multi-deposit-accounts)-june-2018'
    | 'atl-ncc-group-iea'
    | 'atl-s&r-ml-distributor-deposit-agreement'
    | 'atl-s&r-mlda'
    | 'atl-sl-aws-saas-data-secure'
    | 'atl-sl-aws-secure'
    | 'atl-sl-microsoft-azure-saas-data-secure'
    | 'atl-sl-saas-data-secure'
    | 'atl-sl-saas-secure'
    | 'atl-sl-saas-with-data-bolt-on'
    | 'atl-us-custodial'
    | 'atl-us-custodial-with-des'
    | 'atl-us-deposit-account'
    | 'atl-us-deposit-agreement'
    | 'atl-us-deposit-agreement-with-des'
    | 'atl-usml'
    | 'atl-usml-cfmd'
    | 'atl-usml-custodial-deposit-account'
    | 'atl-usml-custodial-deposit-account-depositor-deposits'
    | 'atl-usml-custodial-deposit-agreement'
    | 'atl-usml-saas'
    | 'atl-usml-saas-assured'
    | 'atl-usmld'
    | 'atl-usmld-staggered-release'
    | 'atl-usmo'
    | 'atl-usmo-custodial-deposit-agreement'
    | 'atl-usmo-deposit-account'
    | 'atl-usmo-with-des'
    | 'atl-ussl'
    | 'atl-ussl-saas-deposit-account'
    | 'atl-ussl-saas-with-des'
    | 'atl-ussl-with-des'
    | 'atl-ussld'
    | 'atl-ussld-staggered-release'
    | 'copyright-protection'
    | 'copyright-protection-framework'
    | 'custodial'
    | 'custodial-premium'
    | 'custodial-standard'
    | 'deha--(depositor-deposits)'
    | 'data-escode-holding-agreement'
    | 'deposit-agreement'
    | 'deposit-agreement-premium'
    | 'deposit-agreement-standard'
    | 'description'
    | 'esipr'
    | 'eaas-access'
    | 'eaas-access-+-deposit-account'
    | 'eaas-access+'
    | 'eaas-access+-mda'
    | 'eaas-replicate'
    | 'eaas-replicate+'
    | 'eaas-replicate+-recover'
    | 'escode-protect'
    | 'escode-secure-multi-licensee'
    | 'escode-secure-single-licensee'
    | 'financial-model'
    | 'gmbh-saas-secure-+-data-agreement-(eng)'
    | 'ha'
    | 'hflex-account-for-multi-accounts'
    | 'hflex-agreement-for-multi-accounts'
    | 'hflex-agreement-inc-1-account'
    | 'hlend-agreement-inc-1-account'
    | 'hmaster-account-for-multi-accounts'
    | 'hmaster-agreement-for-multi-accounts'
    | 'hmaster-agreement-inc-1-account'
    | 'hsourceone'
    | 'hsourcepro-account-for-multi-accounts'
    | 'hsourcepro-aggrement-inc-1-account'
    | 'hsourcepro-agreement-for-multi-accounts'
    | 'hsure-agreement-inc-1-account'
    | 'htech-account-for-multi-accounts'
    | 'htech-agreement-for-multi-accounts'
    | 'htech-agreement-inc-1-account'
    | 'htrail-account-for-multi-accounts'
    | 'htrail-agreement-for-multi-accounts'
    | 'htrail-agreement-inc-1-account'
    | 'hdiscovery'
    | 'holding-agreement'
    | 'holding-agreement-(owner-deposits)-deposit-account'
    | 'holding-agreement,-depositor-deposits'
    | 'icann'
    | 'icann-umbrella-manchester'
    | 'icann-umbrella-san-francisco'
    | 'iea-(eng)'
    | 'iea-(ger)'
    | 'ig2multi'
    | 'ig2single'
    | 'independent-verification-template'
    | 'information-escrow'
    | 'international-multiple'
    | 'international-multiple-distributor'
    | 'international-sole'
    | 'international-sole-distributor'
    | 'ml-escode-protection-certificate'
    | 'ml-saas-data-secure'
    | 'ml-saas-secure'
    | 'ml-saas-with-data-bolt-on'
    | 'ml-distributor-germany'
    | 'ml-distributor-germany(ger)'
    | 'ml-germany(ger)'
    | 'mldoddm-(swz)'
    | 'misys-subsidary-ml'
    | 'multi--licensee--distributor-staggered-release-(owner-deposits)'
    | 'multi-distributor/-multi-licensee'
    | 'multi-licensee'
    | 'multi-licensee-(2p)'
    | 'multi-licensee-deposit-account'
    | 'multi-licensee-deposit-account-premium'
    | 'multi-licensee-deposit-agreement'
    | 'multi-licensee-distributor'
    | 'multi-licensee-distributor-(distributor-deposits-and-maintains'
    | 'multi-licensee-distributor-(owner-deposits-and-maintains)'
    | 'multi-licensee-premium'
    | 'multi-licensee-saas'
    | 'multi-licensee-standard'
    | 'outsourcer-single-licensee-single-licensee'
    | 'recover-&-replicate'
    | 'sd-germany'
    | 'sl-saas-data-secure'
    | 'sl-saas-secure'
    | 'sl-saas-with-data-bolt-on'
    | 'sl-distributor-germany'
    | 'sl-distributor-germany(ger)'
    | 'sl-germany-gd-aok_bitmarck'
    | 'sl-germany(ger)'
    | 'sl-single-lender'
    | 'sl-with-multiple-owner'
    | 'sl(epc)'
    | 'sldoddm-(swz)'
    | 'swe-multi-licencee'
    | 'swe-single-licencee'
    | 'swz-mldoddm'
    | 'saas-assured-(level-1)'
    | 'saas-assured-(level-2)'
    | 'saas-assured-(level-3)'
    | 'saas-assured-(level-4)'
    | 'saas-assured-level-1-english'
    | 'saas-assured-level-3'
    | 'saas-deposit-account'
    | 'single-distributor'
    | 'single-licensee'
    | 'single-licensee-(escode-protection-certificate)'
    | 'single-licensee-deposit-agreement'
    | 'single-licensee-developer'
    | 'single-licensee-development'
    | 'single-licensee-distributor'
    | 'single-licensee-distributor-(ddm)'
    | 'single-licensee-distributor-(distributor-deposits-and-maintains)'
    | 'single-licensee-distributor-(owner-deposits-and-maintains)'
    | 'single-licensee-distributor-staggered-release-(owner-deposits)'
    | 'single-licensee-lender'
    | 'single-licensee-nokia'
    | 'single-licensee-premium'
    | 'single-licensee-saas'
    | 'single-licensee-saas-assured'
    | 'single-licensee-standard'
    | 'single-licensee-web'
    | 'single-licensee-web-and-soft'
    | 'standard-&-registered-multi-licensee-deposit-account'
    | 'stes-test-agreement-type'
    | 'swedish-agreement'
    | 'test-agree-type'
    | 'us-epc'
    | 'us-single-licensee-distributor-agreement'
    | 'us-technology-escode-prem'
    | 'us-technology-escode-std'
    | 'usml-distributor-staggered-release'
    | 'usmo'
    | 'usmo-deposit-account'
    | 'usmo-deposit-agreement'
    | 'umbrella-owndist-fixed-parties'
    | 'umbrella-ownlic-fixed-parties'
    | 'umbrella-owner-fixed-party'
    | 'umbrella-fixed-licencee'
    | 'umbrella-fixed-licensee'
    | 'vamadgex'
    | 'vamidas';

const mapper: Record<string, AgreementType> = {
    '3-Party (Single)': '3-party-(single)',
    '4 Party Legacy Agreement': '4-party-legacy-agreement',
    'AMS - Holding Agreement': 'ams-holding-agreement',
    'AMS - Holding Agreement English': 'ams-holding-agreement-english',
    'AMS - Information Escrow Agreement': 'ams-information-escode-agreement',
    'AMS - Information Escrow Agreement english':
        'ams-information-escode-agreement-english',
    'AMS - ML (EPC)': 'ams-ml-(epc)',
    'AMS - ML Distributor WITH Staggered Release':
        'ams-ml-distributor-with-staggered-release',
    'AMS - ML Distributor WITH Staggered Release English':
        'ams-ml-distributor-with-staggered-release-english',
    'AMS - ML Distributor WITHOUT Staggered Release':
        'ams-ml-distributor-without-staggered-release',
    'AMS - ML Distributor WITHOUT Staggered Release English':
        'ams-ml-distributor-without-staggered-release-english',
    'AMS - ML SaaS Data Secure': 'ams-ml-saas-data-secure',
    'AMS - ML SaaS Secure': 'ams-ml-saas-secure',
    'AMS - ML SaaS with Data Bolt-on': 'ams-ml-saas-with-data-bolt-on',
    'AMS - MLDDDDM': 'ams-mlddddm',
    'AMS - Multi Licensee': 'ams-multi-licensee',
    'AMS - Multi Licensee (Auto)': 'ams-multi-licensee-(auto)',
    'AMS - Multi Licensee English Lang': 'ams-multi-licensee-english-lang',
    'AMS - SL Distributor WITH Staggered Release':
        'ams-sl-distributor-with-staggered-release',
    'AMS - SL Distributor WITH Staggered Release English':
        'ams-sl-distributor-with-staggered-release-english',
    'AMS - SL Distributor WITHOUT Staggered Release':
        'ams-sl-distributor-without-staggered-release',
    'AMS - SL Distributor WITHOUT Staggered Release English':
        'ams-sl-distributor-without-staggered-release-english',
    'AMS - SL SAAS english': 'ams-sl-saas-english',
    'AMS - SL SaaS Data Secure': 'ams-sl-saas-data-secure',
    'AMS - SL SaaS Secure': 'ams-sl-saas-secure',
    'AMS - SL SaaS with Data Bolt-on': 'ams-sl-saas-with-data-bolt-on',
    'AMS - Single Distributor': 'ams-single-distributor',
    'AMS - Single Distributor English': 'ams-single-distributor-english',
    'AMS - Single Licensee english': 'ams-single-licensee-english',
    'AMS - Web Agreement': 'ams-web-agreement',
    'ATL - Custodial (Multi Deposit)': 'atl-custodial-(multi-deposit)',
    'ATL - Escrow Protection Certificate': 'atl-escode-protection-certificate',
    'ATL - HDiscovery': 'atl-hdiscovery',
    'ATL - IEA EPC': 'atl-iea-epc',
    'ATL - JP Morgan Data Holding Agreement':
        'atl-jp-morgan-data-holding-agreement',
    'ATL - JP Morgan Deposit Account': 'atl-jp-morgan-deposit-account',
    'ATL - JP Morgan Deposit Account Agreement':
        'atl-jp-morgan-deposit-account-agreement',
    'ATL - MDHA': 'atl-mdha',
    'ATL - MDHA Deposit Account': 'atl-mdha-deposit-account',
    'ATL - ML SaaS Data Secure': 'atl-ml-saas-data-secure',
    'ATL - ML SaaS Secure': 'atl-ml-saas-secure',
    'ATL - ML SaaS with Data Bolt-on': 'atl-ml-saas-with-data-bolt-on',
    'ATL - Microsoft Azure SaaS Secure': 'atl-microsoft-azure-saas-secure',
    'ATL - MultiOwner': 'atl-multiowner',
    'ATL - NCC Group Custodial (Multi Deposit Accounts) June 2018':
        'atl-ncc-group-custodial-(multi-deposit-accounts)-june-2018',
    'ATL - NCC Group IEA': 'atl-ncc-group-iea',
    'ATL - S&R ML Distributor Deposit Agreement':
        'atl-s&r-ml-distributor-deposit-agreement',
    'ATL - S&R MLDA': 'atl-s&r-mlda',
    'ATL - SL AWS SaaS Data Secure': 'atl-sl-aws-saas-data-secure',
    'ATL - SL AWS Secure': 'atl-sl-aws-secure',
    'ATL - SL Microsoft Azure SaaS Data Secure':
        'atl-sl-microsoft-azure-saas-data-secure',
    'ATL - SL SaaS Data Secure': 'atl-sl-saas-data-secure',
    'ATL - SL SaaS Secure': 'atl-sl-saas-secure',
    'ATL - SL SaaS with Data Bolt-on': 'atl-sl-saas-with-data-bolt-on',
    'ATL - US Custodial': 'atl-us-custodial',
    'ATL - US Custodial with DES': 'atl-us-custodial-with-des',
    'ATL - US Deposit Account': 'atl-us-deposit-account',
    'ATL - US Deposit Agreement': 'atl-us-deposit-agreement',
    'ATL - US Deposit Agreement with DES': 'atl-us-deposit-agreement-with-des',
    'ATL - USML': 'atl-usml',
    'ATL - USML CfMD': 'atl-usml-cfmd',
    'ATL - USML Custodial Deposit Account':
        'atl-usml-custodial-deposit-account',
    'ATL - USML Custodial Deposit Account - Depositor Deposits':
        'atl-usml-custodial-deposit-account-depositor-deposits',
    'ATL - USML Custodial Deposit Agreement':
        'atl-usml-custodial-deposit-agreement',
    'ATL - USML SaaS': 'atl-usml-saas',
    'ATL - USML SaaS Assured': 'atl-usml-saas-assured',
    'ATL - USMLD': 'atl-usmld',
    'ATL - USMLD - Staggered Release': 'atl-usmld-staggered-release',
    'ATL - USMO': 'atl-usmo',
    'ATL - USMO Custodial Deposit Agreement':
        'atl-usmo-custodial-deposit-agreement',
    'ATL - USMO Deposit Account': 'atl-usmo-deposit-account',
    'ATL - USMO with DES': 'atl-usmo-with-des',
    'ATL - USSL': 'atl-ussl',
    'ATL - USSL SaaS Deposit Account': 'atl-ussl-saas-deposit-account',
    'ATL - USSL SaaS with DES': 'atl-ussl-saas-with-des',
    'ATL - USSL with DES': 'atl-ussl-with-des',
    'ATL - USSLD': 'atl-ussld',
    'ATL - USSLD - Staggered Release': 'atl-ussld-staggered-release',
    'Copyright Protection': 'copyright-protection',
    'Copyright Protection Framework': 'copyright-protection-framework',
    Custodial: 'custodial',
    'Custodial Premium': 'custodial-premium',
    'Custodial Standard': 'custodial-standard',
    'DEHA- (Depositor Deposits)': 'deha--(depositor-deposits)',
    'Data Escrow Holding Agreement': 'data-escode-holding-agreement',
    'Deposit Agreement': 'deposit-agreement',
    'Deposit Agreement Premium': 'deposit-agreement-premium',
    'Deposit Agreement Standard': 'deposit-agreement-standard',
    Description: 'description',
    ESIPR: 'esipr',
    'EaaS Access': 'eaas-access',
    'EaaS Access + Deposit Account': 'eaas-access-+-deposit-account',
    'EaaS Access+': 'eaas-access+',
    'EaaS Access+ MDA': 'eaas-access+-mda',
    'EaaS Replicate': 'eaas-replicate',
    'EaaS Replicate+': 'eaas-replicate+',
    'EaaS Replicate+ Recover': 'eaas-replicate+-recover',
    'Escrow Protect': 'escode-protect',
    'Escrow Secure Multi Licensee': 'escode-secure-multi-licensee',
    'Escrow Secure Single Licensee': 'escode-secure-single-licensee',
    'Financial Model': 'financial-model',
    'GmbH Saas Secure + Data Agreement (ENG)':
        'gmbh-saas-secure-+-data-agreement-(eng)',
    HA: 'ha',
    'HFlex - Account for Multi-Accounts': 'hflex-account-for-multi-accounts',
    'HFlex - Agreement for Multi-Accounts':
        'hflex-agreement-for-multi-accounts',
    'HFlex - Agreement inc. 1 Account': 'hflex-agreement-inc-1-account',
    'HLend - Agreement inc. 1 Account': 'hlend-agreement-inc-1-account',
    'HMaster - Account for Multi-Accounts':
        'hmaster-account-for-multi-accounts',
    'HMaster - Agreement for Multi-Accounts':
        'hmaster-agreement-for-multi-accounts',
    'HMaster - Agreement inc. 1 Account': 'hmaster-agreement-inc-1-account',
    HSourceOne: 'hsourceone',
    'HSourcePro - Account for Multi-Accounts':
        'hsourcepro-account-for-multi-accounts',
    'HSourcePro - Aggrement inc. 1 Account':
        'hsourcepro-aggrement-inc-1-account',
    'HSourcePro - Agreement for Multi-Accounts':
        'hsourcepro-agreement-for-multi-accounts',
    'HSure - Agreement inc. 1 Account': 'hsure-agreement-inc-1-account',
    'HTech - Account for Multi-Accounts': 'htech-account-for-multi-accounts',
    'HTech - Agreement for Multi-Accounts':
        'htech-agreement-for-multi-accounts',
    'HTech - Agreement inc. 1 Account': 'htech-agreement-inc-1-account',
    'HTrail - Account for Multi-Accounts': 'htrail-account-for-multi-accounts',
    'HTrail - Agreement for Multi-Accounts':
        'htrail-agreement-for-multi-accounts',
    'HTrail - Agreement inc. 1 Account': 'htrail-agreement-inc-1-account',
    Hdiscovery: 'hdiscovery',
    'Holding Agreement': 'holding-agreement',
    'Holding Agreement (Owner Deposits) Deposit Account':
        'holding-agreement-(owner-deposits)-deposit-account',
    'Holding Agreement, Depositor Deposits':
        'holding-agreement,-depositor-deposits',
    ICANN: 'icann',
    'ICANN Umbrella - Manchester': 'icann-umbrella-manchester',
    'ICANN Umbrella - San Francisco': 'icann-umbrella-san-francisco',
    'IEA (ENG)': 'iea-(eng)',
    'IEA (GER)': 'iea-(ger)',
    IG2Multi: 'ig2multi',
    IG2single: 'ig2single',
    'Independent Verification Template': 'independent-verification-template',
    'Information Escrow': 'information-escrow',
    'International Multiple': 'international-multiple',
    'International Multiple Distributor': 'international-multiple-distributor',
    'International Sole': 'international-sole',
    'International Sole Distributor': 'international-sole-distributor',
    'ML - Escrow Protection Certificate': 'ml-escode-protection-certificate',
    'ML - SaaS Data Secure': 'ml-saas-data-secure',
    'ML - SaaS Secure': 'ml-saas-secure',
    'ML - SaaS with Data Bolt-on': 'ml-saas-with-data-bolt-on',
    'ML Distributor Germany': 'ml-distributor-germany',
    'ML Distributor Germany(GER)': 'ml-distributor-germany(ger)',
    'ML Germany(GER)': 'ml-germany(ger)',
    'MLDODDM (SWZ)': 'mldoddm-(swz)',
    'Misys Subsidary ML': 'misys-subsidary-ml',
    'Multi  Licensee  Distributor Staggered Release (Owner Deposits)':
        'multi--licensee--distributor-staggered-release-(owner-deposits)',
    'Multi Distributor/ Multi Licensee': 'multi-distributor/-multi-licensee',
    'Multi Licensee': 'multi-licensee',
    'Multi Licensee (2P)': 'multi-licensee-(2p)',
    'Multi Licensee Deposit Account': 'multi-licensee-deposit-account',
    'Multi Licensee Deposit Account Premium':
        'multi-licensee-deposit-account-premium',
    'Multi Licensee Deposit Agreement': 'multi-licensee-deposit-agreement',
    'Multi Licensee Distributor': 'multi-licensee-distributor',
    'Multi Licensee Distributor (Distributor Deposits And Maintains':
        'multi-licensee-distributor-(distributor-deposits-and-maintains',
    'Multi Licensee Distributor (Owner Deposits And Maintains)':
        'multi-licensee-distributor-(owner-deposits-and-maintains)',
    'Multi Licensee Premium': 'multi-licensee-premium',
    'Multi Licensee SaaS': 'multi-licensee-saas',
    'Multi Licensee Standard': 'multi-licensee-standard',
    'Outsourcer Single Licensee Single Licensee':
        'outsourcer-single-licensee-single-licensee',
    'Recover & Replicate': 'recover-&-replicate',
    'SD Germany': 'sd-germany',
    'SL - SaaS Data Secure': 'sl-saas-data-secure',
    'SL - SaaS Secure': 'sl-saas-secure',
    'SL - SaaS with Data Bolt-on': 'sl-saas-with-data-bolt-on',
    'SL Distributor Germany': 'sl-distributor-germany',
    'SL Distributor Germany(GER)': 'sl-distributor-germany(ger)',
    'SL Germany GD AOK_Bitmarck': 'sl-germany-gd-aok_bitmarck',
    'SL Germany(GER)': 'sl-germany(ger)',
    'SL Single Lender': 'sl-single-lender',
    'SL with Multiple Owner': 'sl-with-multiple-owner',
    'SL(EPC)': 'sl(epc)',
    'SLDODDM (SWZ)': 'sldoddm-(swz)',
    'SWE - Multi Licencee': 'swe-multi-licencee',
    'SWE - Single Licencee': 'swe-single-licencee',
    'SWZ MLDODDM': 'swz-mldoddm',
    'SaaS Assured (Level 1)': 'saas-assured-(level-1)',
    'SaaS Assured (Level 2)': 'saas-assured-(level-2)',
    'SaaS Assured (Level 3)': 'saas-assured-(level-3)',
    'SaaS Assured (Level 4)': 'saas-assured-(level-4)',
    'SaaS Assured Level 1 English': 'saas-assured-level-1-english',
    'SaaS Assured Level 3': 'saas-assured-level-3',
    'SaaS Deposit Account': 'saas-deposit-account',
    'Single Distributor': 'single-distributor',
    'Single Licensee': 'single-licensee',
    'Single Licensee (Escrow Protection Certificate)':
        'single-licensee-(escode-protection-certificate)',
    'Single Licensee Deposit Agreement': 'single-licensee-deposit-agreement',
    'Single Licensee Developer': 'single-licensee-developer',
    'Single Licensee Development': 'single-licensee-development',
    'Single Licensee Distributor': 'single-licensee-distributor',
    'Single Licensee Distributor (DDM)': 'single-licensee-distributor-(ddm)',
    'Single Licensee Distributor (Distributor Deposits And Maintains)':
        'single-licensee-distributor-(distributor-deposits-and-maintains)',
    'Single Licensee Distributor (Owner Deposits And Maintains)':
        'single-licensee-distributor-(owner-deposits-and-maintains)',
    'Single Licensee Distributor Staggered Release (Owner Deposits)':
        'single-licensee-distributor-staggered-release-(owner-deposits)',
    'Single Licensee Lender': 'single-licensee-lender',
    'Single Licensee Nokia': 'single-licensee-nokia',
    'Single Licensee Premium': 'single-licensee-premium',
    'Single Licensee SaaS': 'single-licensee-saas',
    'Single Licensee SaaS Assured': 'single-licensee-saas-assured',
    'Single Licensee Standard': 'single-licensee-standard',
    'Single Licensee Web': 'single-licensee-web',
    'Single Licensee Web and Soft': 'single-licensee-web-and-soft',
    'Standard & Registered Multi Licensee Deposit Account':
        'standard-&-registered-multi-licensee-deposit-account',
    'Stes Test Agreement Type': 'stes-test-agreement-type',
    'Swedish Agreement': 'swedish-agreement',
    'Test Agree Type': 'test-agree-type',
    'US - EPC': 'us-epc',
    'US Single Licensee Distributor Agreement':
        'us-single-licensee-distributor-agreement',
    'US Technology Escrow Prem': 'us-technology-escode-prem',
    'US Technology Escrow Std': 'us-technology-escode-std',
    'USML Distributor Staggered Release': 'usml-distributor-staggered-release',
    USMO: 'usmo',
    'USMO Deposit account': 'usmo-deposit-account',
    'USMO Deposit agreement': 'usmo-deposit-agreement',
    'Umbrella - OwnDist Fixed Parties': 'umbrella-owndist-fixed-parties',
    'Umbrella - OwnLic Fixed Parties': 'umbrella-ownlic-fixed-parties',
    'Umbrella - Owner Fixed Party': 'umbrella-owner-fixed-party',
    'Umbrella Fixed Licencee': 'umbrella-fixed-licencee',
    'Umbrella Fixed Licensee': 'umbrella-fixed-licensee',
    VAMADGEX: 'vamadgex',
    VAMIDAS: 'vamidas'
};

function getAgreementType(
    agreement: AgreementViewModel
): AgreementType | undefined {
    return mapper[agreement.agreementType || ''];
}

function getAgreementTypeString({
    context: { t },
    type
}: Pick<
    ValueGetterParams<AgreementViewModel, AgreementsTableContext>,
    'context'
> & { type: AgreementType }): string {
    if (!type) return '';
    return t(`agreement.type.${type}`);
}

function getAgreementTypeOptions(
    context: AgreementsTableContext
): FilterFieldData['options'] {
    const options: FilterFieldData['options'] = Object.values(mapper).map(
        (key) => ({
            key,
            label: getAgreementTypeString({
                context,
                type: key
            })
        })
    );

    return options;
}

function agreementTypeValueGetter({
    context,
    data
}: ValueGetterParams<AgreementViewModel, AgreementsTableContext>) {
    const type = getAgreementType(data);
    if (!type) return data.agreementType;
    return getAgreementTypeString({ context, type });
}

export {
    agreementTypeValueGetter,
    getAgreementType,
    getAgreementTypeOptions,
    getAgreementTypeString
};
