/* eslint-disable @typescript-eslint/no-explicit-any */
import { MutationOptions, useMutation } from 'react-query';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import {
    PrepareFile,
    StartDepositRequest
} from '../../autogenerated/data-contracts';
import { useAuth } from 'react-oidc-context';

type CustomFileParams = {
    depositId: string;
    spectreAuthToken: string;
};

function useUploadsStart<TError = unknown, TContext = unknown>(
    options?: Omit<
        MutationOptions<
            AxiosResponse<PrepareFile>,
            ReactQueryError<TError>,
            CustomFileParams,
            TContext
        >,
        'mutationFn'
    >
) {
    const auth = useAuth();

    return useMutation(
        [
            'uploadsStart',
            { token: auth.user?.access_token, tokenType: auth.user?.token_type }
        ],
        async ({
            depositId,
            spectreAuthToken
        }: CustomFileParams): Promise<AxiosResponse<PrepareFile>> => {
            if (process.env.REACT_APP_API_MOCK) {
                console.warn('Mock is enabled useUploadsStart call ignored.');
                return Promise.resolve({} as AxiosResponse<PrepareFile>);
            }
            const baseURL = process.env.REACT_APP_SPECTRE_AGENT_ENDPOINT;

            const startDeposit = (
                data: StartDepositRequest,
                params: AxiosRequestConfig = {}
            ): Promise<AxiosResponse<PrepareFile>> => {
                const axiosInstance = axios.create({
                    baseURL,
                    headers: {
                        Authorization: `Bearer ${spectreAuthToken}`,
                        'Cache-FormControl':
                            'no-cache, no-store, must-revalidate',
                        Expires: '0',
                        Pragma: 'no-cache'
                    }
                });

                return axiosInstance
                    .post('/api/uploads', data, params)
                    .then((response: AxiosResponse<PrepareFile>) => {
                        return response;
                    });
            };

            return await startDeposit({
                depositId: depositId
            }).then((response) => {
                return response;
            });
        },
        {
            ...options
        }
    );
}

export default useUploadsStart;
