import { Dropdown } from '@ncc-frontend/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-solid-svg-icons';

import HeaderButton from '../header-button';
import UserPanel from './user-panel';

function UserButton() {
    const panelId = 'user';

    return (
        <Dropdown.Trigger panelId={panelId} panel={<UserPanel />}>
            <HeaderButton
                fab
                variant="primary"
                tailwindStyle={{
                    borderRadius: 'rounded-full'
                }}
                icon={<FontAwesomeIcon icon={faUser} className="text-lg" />}
            />
        </Dropdown.Trigger>
    );
}

export default UserButton;
