import { AUDIT_RECORD_TYPE_MAPPER } from 'core/api/client-portal/model/audit-record-type';
import { AuditRecordType } from 'core/api/client-portal/autogenerated/data-contracts';
import {
    DepositFrequency,
    DepositFrequencyInitials
} from 'core/api/client-portal/model/deposit-frequency';
import {
    EscrowDepositStatus,
    SourceCodeProviderType
} from 'core/api/client-portal/autogenerated/data-contracts';
import { Lang } from 'localization';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

type DateStyles = 'full' | 'long' | 'medium' | 'short';

function useBeautify() {
    const { t } = useTranslation();

    const beautifyDate = useCallback(
        (text: string) => text || t('no-date'),
        [t]
    );

    const beautifyDateLocalised = useCallback(
        (
            date: Date | string,
            lang: Lang,
            dateStyle: DateStyles = 'short',
            timeStyle: DateStyles = 'short',
            timeZone = 'Europe/London'
        ) => {
            if (!date) return t('no-date');
            const localisedDate = new Intl.DateTimeFormat(lang, {
                dateStyle: dateStyle,
                timeStyle: timeStyle,
                timeZone: timeZone
            }).format(new Date(date));
            return localisedDate.replace(/,/g, '');
        },
        [t]
    );

    const beautifyRecordType = useCallback(
        (recordType: AuditRecordType | undefined) => {
            const strKey = AUDIT_RECORD_TYPE_MAPPER[recordType ?? 0];
            return t(`audit-record-type.${strKey}`);
        },
        [t]
    );

    const beautifyProviderType = useCallback(
        (type: SourceCodeProviderType | null | undefined) => {
            const strKey =
                type === 1
                    ? 'git'
                    : type === 2
                    ? 'mercurial'
                    : type === 3
                    ? 'svn'
                    : 'none';

            if (strKey === 'none') return '';

            return t(`provider-type.${strKey}`);
        },
        [t]
    );

    const beautifyDepositStatus = useCallback(
        (status: EscrowDepositStatus | undefined) => {
            return t(`deposits.status.status-${status || 0}`);
        },
        [t]
    );

    const beautifyFrequency = useCallback(
        (
            frequency: DepositFrequency | DepositFrequencyInitials | undefined
        ) => {
            t('schedules.frequency.q');
            return t(`schedules.frequency.${frequency || 'none'}`);
        },
        [t]
    );

    const beautifyOrdinal = (number: string) => {
        const date = number.split(' ').at(0)!;
        const day = number.split(' ').at(1)!;

        const lastDigit = Number(day) % 10;
        const lastTwoDigits = Number(day) % 100;

        if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
            return day + 'th';
        }

        switch (lastDigit) {
            case 1:
                return `${date} ${day}st`;
            case 2:
                return `${date} ${day}nd`;
            case 3:
                return `${date} ${day}rd`;
            default:
                return `${date} ${day}th`;
        }
    };

    const beautifyTimestamp = (
        value: string | Date | null | undefined,
        locale?: string
    ) => {
        if (!value) return '';

        if (typeof value === 'object')
            return value.toLocaleString(locale).replace(/,/g, '');

        return new Date(value).toLocaleString(locale).replace(/,/g, '');
    };

    return {
        beautifyDate,
        beautifyDateLocalised,
        beautifyDepositStatus,
        beautifyFrequency,
        beautifyOrdinal,
        beautifyProviderType,
        beautifyRecordType,
        beautifyTimestamp
    };
}

export default useBeautify;
