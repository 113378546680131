import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/pro-solid-svg-icons';
import { useCallback, useState } from 'react';
import { useModals } from '@ncc-frontend/core';
import { useTranslation } from 'react-i18next';
import Banner from 'lib-ui/banner/banner';
import FeedbackFormModal, {
    FeedbackFormTriggers
} from 'ui/feedback-form/feedback-form-modal';

function BetaHoldingBanner() {
    const storedValue = localStorage.getItem('beta-banner')
        ? JSON.parse(localStorage.getItem('beta-banner')!)
        : true;
    const [open, setOpen] = useState(storedValue);
    const { push } = useModals();
    const { t } = useTranslation();
    const OLD_PORTAL_URL = process.env.REACT_APP_OLD_PORTAL_URL ?? '';

    const handleFeedbackForm = useCallback(
        (trigger: FeedbackFormTriggers) => {
            if (trigger === 'betaBanner') {
                push(FeedbackFormModal, { trigger: 'userPanelFeedback' });
            }

            if (trigger === 'userPanelClassicView') {
                if (localStorage.getItem('feedback-form-submitted')) {
                    localStorage.removeItem('feedback-form-submitted');
                    window.location.href = OLD_PORTAL_URL;
                    return;
                } else {
                    push(FeedbackFormModal, {
                        trigger: 'userPanelClassicView'
                    });
                }
            }
        },
        [OLD_PORTAL_URL, push]
    );

    return open ? (
        <Banner name="beta-holding-banner">
            <div className="ml-auto mr-auto">
                <p>
                    <span className="rounded-[10px] bg-brand-escode-neonblue-neonblue-100 py-1 px-2 text-white mr-1">
                        BETA
                    </span>
                    {t('beta-banner.part-one')}{' '}
                    <span
                        className="cursor-pointer font-bold underline"
                        data-testid="feedback-link"
                        onClick={() => handleFeedbackForm('betaBanner')}
                        role="link"
                    >
                        {t('beta-banner.part-two')}
                    </span>{' '}
                    {t('beta-banner.part-three')}{' '}
                    <span
                        onClick={() =>
                            handleFeedbackForm('userPanelClassicView')
                        }
                        className="font-bold underline cursor-pointer"
                        data-testid="portal-link"
                    >
                        {t('beta-banner.part-four')}
                    </span>
                </p>
            </div>
            <FontAwesomeIcon
                icon={faClose}
                className="cursor-pointer"
                onClick={() => {
                    setOpen(false);
                    localStorage.setItem('beta-banner', 'false');
                }}
            />
        </Banner>
    ) : null;
}

export default BetaHoldingBanner;
