/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    DepositContactDetails,
    DepositDetails1,
    DepositDetails2,
    NewPortalFeedbackRequest,
    ProblemDetails,
    RelatedDepositAgreements,
    ReviewDepositRequest,
    ValidationProblemDetails
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class ApiController<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags Agreement
     * @name V2AgreementTypesList
     * @summary Gets agreement types pertinent to the user's agreements.
     * @request GET:/api/v2/Agreement/Types
     * @secure
     */
    v2AgreementTypesList = (params: RequestParams = {}) =>
        this.request<any, any>({
            path: `/api/v2/Agreement/Types`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags Agreement
     * @name V2AgreementDetail
     * @summary Get details for a specified agreement.
     * @request GET:/api/v2/Agreement/{agreementId}
     * @secure
     */
    v2AgreementDetail = (agreementId: number, params: RequestParams = {}) =>
        this.request<any, ProblemDetails>({
            path: `/api/v2/Agreement/${agreementId}`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags Deposit
     * @name V2DepositDetail
     * @summary Get Deposit details.
     * @request GET:/api/v2/Deposit/{depositId}
     * @secure
     */
    v2DepositDetail = (depositId: number, params: RequestParams = {}) =>
        this.request<any, ValidationProblemDetails | ProblemDetails>({
            path: `/api/v2/Deposit/${depositId}`,
            method: 'GET',
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags Deposit
     * @name V2DepositPartialUpdate
     * @summary Edit Deposit: Step 5; Review and update all details.
     * @request PATCH:/api/v2/Deposit/{depositId}
     * @secure
     */
    v2DepositPartialUpdate = (depositId: number, data: ReviewDepositRequest, params: RequestParams = {}) =>
        this.request<any, ValidationProblemDetails>({
            path: `/api/v2/Deposit/${depositId}`,
            method: 'PATCH',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * No description
     *
     * @tags Deposit
     * @name V2DepositDelete
     * @summary Discard the specified deposit.
     * @request DELETE:/api/v2/Deposit/{depositId}
     * @secure
     */
    v2DepositDelete = (depositId: number, params: RequestParams = {}) =>
        this.request<void, ValidationProblemDetails>({
            path: `/api/v2/Deposit/${depositId}`,
            method: 'DELETE',
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags Deposit
     * @name V2DepositCreateCreate
     * @summary Create Deposit: New front-end.
     * @request POST:/api/v2/Deposit/Create
     * @secure
     */
    v2DepositCreateCreate = (data: RelatedDepositAgreements, params: RequestParams = {}) =>
        this.request<any, ValidationProblemDetails>({
            path: `/api/v2/Deposit/Create`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * No description
     *
     * @tags Deposit
     * @name V2DepositConfirmUpdate
     * @summary Set deposit status to confirmed.
     * @request PUT:/api/v2/Deposit/{depositId}/confirm
     * @secure
     */
    v2DepositConfirmUpdate = (depositId: number, params: RequestParams = {}) =>
        this.request<void, ValidationProblemDetails>({
            path: `/api/v2/Deposit/${depositId}/confirm`,
            method: 'PUT',
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags Deposit
     * @name V2DepositFilesDetail
     * @summary Get files added to the deposit.
     * @request GET:/api/v2/Deposit/{depositId}/files
     * @secure
     */
    v2DepositFilesDetail = (depositId: number, params: RequestParams = {}) =>
        this.request<any, ValidationProblemDetails>({
            path: `/api/v2/Deposit/${depositId}/files`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags Deposit
     * @name V2DepositContactPartialUpdate
     * @summary Edit Deposit: Step 2; Contact Details.
     * @request PATCH:/api/v2/Deposit/{depositId}/contact
     * @secure
     */
    v2DepositContactPartialUpdate = (depositId: number, data: DepositContactDetails, params: RequestParams = {}) =>
        this.request<any, ValidationProblemDetails>({
            path: `/api/v2/Deposit/${depositId}/contact`,
            method: 'PATCH',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * No description
     *
     * @tags Deposit
     * @name V2DepositDetailsPartialUpdate
     * @summary Edit Deposit: Step 3; Software, Testing Requirements, Licensees, Spec, Content.
     * @request PATCH:/api/v2/Deposit/{depositId}/Details
     * @secure
     */
    v2DepositDetailsPartialUpdate = (depositId: number, data: DepositDetails1, params: RequestParams = {}) =>
        this.request<any, ValidationProblemDetails>({
            path: `/api/v2/Deposit/${depositId}/Details`,
            method: 'PATCH',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags Deposit
     * @name V2DepositMoreDetailsPartialUpdate
     * @summary Edit Deposit: Step 4; Deposit Details and Content (continued).
     * @request PATCH:/api/v2/Deposit/{depositId}/MoreDetails
     * @secure
     */
    v2DepositMoreDetailsPartialUpdate = (depositId: number, data: DepositDetails2, params: RequestParams = {}) =>
        this.request<any, ValidationProblemDetails>({
            path: `/api/v2/Deposit/${depositId}/MoreDetails`,
            method: 'PATCH',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags Deposits
     * @name V2DepositsIncompleteList
     * @summary Get incomplete deposits for the current user.
     * @request GET:/api/v2/Deposits/Incomplete
     * @secure
     */
    v2DepositsIncompleteList = (params: RequestParams = {}) =>
        this.request<any, any>({
            path: `/api/v2/Deposits/Incomplete`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags Feedback
     * @name V2FeedbackCreate
     * @summary Submit feedback.
     * @request POST:/api/v2/Feedback
     * @secure
     */
    v2FeedbackCreate = (data: NewPortalFeedbackRequest, params: RequestParams = {}) =>
        this.request<void, ValidationProblemDetails>({
            path: `/api/v2/Feedback`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * No description
     *
     * @tags Register
     * @name V2RegisterCreate
     * @summary Registers a new user with the system.
     * @request POST:/api/v2/Register
     * @secure
     */
    v2RegisterCreate = (data: any, params: RequestParams = {}) =>
        this.request<any, any>({
            path: `/api/v2/Register`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        });
}
