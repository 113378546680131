import { Button, ICellRendererParams } from 'lib-ui';
import { DepositItem } from 'core/api/client-portal/autogenerated/data-contracts';
import { isUndefined } from 'lodash';
import { memo, useCallback } from 'react';

import { DepositsTableContext } from './deposits-table-context';
import DepositDocumentsModal from './documents/deposit-documents-modal';

function DepositDocumentsCellRenderer({
    context: { agreementNumber, push, t },
    data
}: ICellRendererParams<DepositItem, DepositsTableContext>) {
    const viewDocuments = useCallback(() => {
        push(DepositDocumentsModal, {
            agreementId: data.agreementId!,
            agreementNumber: agreementNumber!,
            depositId: data.depositId!,
            mediaCheck: data.mediaCheck
        });
    }, [
        agreementNumber,
        data?.agreementId,
        data?.depositId,
        data?.mediaCheck,
        push
    ]);

    return (
        (!isUndefined(data) &&
            !isUndefined(data.agreementId) &&
            !isUndefined(data.depositId) &&
            !isUndefined(agreementNumber) && (
                <div className="flex h-full items-center">
                    <Button variant="tertiary" onClick={viewDocuments}>
                        {t('deposits.view-documents')}
                    </Button>
                </div>
            )) ||
        null
    );
}

export default memo(DepositDocumentsCellRenderer);
