type AgreementTestingLevel =
    | 'access-verification'
    | 'access-plus-verification'
    | 'architecture-verification'
    | 'data-extract-verification'
    | 'deposit-evaluation-service'
    | 'deposit-review'
    | 'eaas-control-verification'
    | 'entry-level-secure-verification'
    | 'entry-level-verification'
    | 'escode-secure'
    | 'independent-build-verification'
    | 'independent-secure-buildverification'
    | 'media-check'
    | 'media-file-review'
    | 'none'
    | 'not-tested'
    | 'operational-maintenance-verification'
    | 'operational-maintenance-verificationaws'
    | 'replicate-verification'
    | 'replicate-plus-verification'
    | 'sast-re-scan'
    | 'saas-assured-operational-verification'
    | 'saas-assured-verification'
    | 'saas-integrity-test'
    | 'secure-user-assured-verification'
    | 'security-vulnerability-scan'
    | 'user-assured-verification'
    | 'vmit-testing';

const AGREEMENT_TESTING_LEVEL_MAPPER: Record<string, AgreementTestingLevel> = {
    'Access Verification': 'access-verification',
    'Access+ Verification': 'access-plus-verification',
    'Architecture Verification': 'architecture-verification',
    'Data Extract Verification': 'data-extract-verification',
    'Deposit Evaluation Service': 'deposit-evaluation-service',
    'Deposit Review': 'deposit-review',
    'EaaS Control Verification': 'eaas-control-verification',
    'Entry Level Secure Verification': 'entry-level-secure-verification',
    'Entry Level Verification': 'entry-level-verification',
    'Escrow Secure': 'escode-secure',
    'Independent Build Verification': 'independent-build-verification',
    'Independent Secure Build Verification':
        'independent-secure-buildverification',
    'Media Check': 'media-check',
    'Media File Review': 'media-file-review',
    'Not Tested': 'not-tested',
    'Operational Maintenance Verification':
        'operational-maintenance-verification',
    'Operational Maintenance Verification for AWS':
        'operational-maintenance-verificationaws',
    'Replicate Verification': 'replicate-verification',
    'Replicate+ Verification': 'replicate-plus-verification',
    'SAST Re-Scan': 'sast-re-scan',
    'SaaS Assured Operational Verification':
        'saas-assured-operational-verification',
    'SaaS Assured Verification': 'saas-assured-verification',
    'SaaS Integrity Test': 'saas-integrity-test',
    'Secure User Assured Verification': 'secure-user-assured-verification',
    'Security Vulnerability Scan': 'security-vulnerability-scan',
    'User Assured Verification': 'user-assured-verification',
    'VM IT Testing': 'vmit-testing'
};

export { AGREEMENT_TESTING_LEVEL_MAPPER };
export type { AgreementTestingLevel };
