import { CommonProps, useModals } from '@ncc-frontend/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropsWithChildren, ReactElement, useCallback } from 'react';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';

interface ModalHeaderProps extends CommonProps {
    suppressClose?: boolean;
}

function ModalHeader({
    children,
    className,
    suppressClose = false,
    ...restProps
}: PropsWithChildren<ModalHeaderProps>): ReactElement | null {
    const { pop } = useModals();

    const close = useCallback(() => {
        pop();
    }, [pop]);

    const cssClasses = classNames(
        'flex items-center justify-center gap-2.5 border-b border-escode-grey-30 h-14 pb-2',
        className
    );

    return (
        <div {...restProps} className={cssClasses}>
            <div className="flex-1 flex items-center justify-start gap-2.5 font-medium text-general-grey-grey-90 text-xl leading-6">
                {children}
            </div>
            {!suppressClose && (
                <FontAwesomeIcon
                    onClick={close}
                    className="ml-auto cursor-pointer text-general-red-red-100"
                    icon={faTimes}
                />
            )}
        </div>
    );
}

export default ModalHeader;
export type { ModalHeaderProps };
