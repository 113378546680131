import {
    Button,
    CheckField,
    ConfirmationTooltip,
    DatePickerField,
    InputField,
    Modal,
    MultiCheckboxField,
    MultiCheckboxFieldProps,
    RadioGroupField,
    ResultModal,
    SelectField,
    SelectFieldProps,
    TextareaField
} from 'lib-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormProvider, Path, SubmitHandler, useForm } from 'react-hook-form';
import { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { RequestNovationRequestViewModel } from 'core/api/client-portal/autogenerated/data-contracts';
import {
    faDiamondExclamation,
    faInfoCircle
} from '@fortawesome/pro-solid-svg-icons';
import { isUndefined } from 'lodash';
import { radioOptions } from 'ui/deposit-now/deposit-details-step/deposit-details-options';
import { useModals } from '@ncc-frontend/core';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import Asterisk from 'lib-ui/form/element/asterisk';
import schema from './request-novation-schema';
import useAgreementsList from 'core/api/client-portal/hooks/agreements/use-agreements-list';
import useAgreementsNovationGuideDownload from 'core/api/client-portal/hooks/agreements/use-agreements-novation-guide-download';
import useAgreementsRequestNovation from 'core/api/client-portal/hooks/agreements/use-agreements-request-novation';
import useBackendValidation from 'common/use-backend-validation';

type AddLicenseeFormValues = Omit<
    RequestNovationRequestViewModel,
    'newPartyCompanyAddress' | 'newPartyInvoiceAddress'
> & {
    customTemplate: string | boolean;
    entityConfirmation: boolean;
    newPartyCompanyAddressLine1: string;
    newPartyCompanyAddressLine2: string;
    newPartyCompanyAddressLine3: string;
    newPartyCompanyAddressLinePostCode: string;
    newPartyCompanyInvoiceAddressLine1: string;
    newPartyCompanyInvoiceAddressLine2: string;
    newPartyCompanyInvoiceAddressLine3: string;
    newPartyCompanyInvoiceAddressLinePostCode: string;
    sameAddress: boolean;
};

interface RequestNovationModalProps {}

function RequestNovationModal({}: RequestNovationModalProps): ReactElement | null {
    const { t } = useTranslation();
    const { pop, push } = useModals();

    const methods = useForm<AddLicenseeFormValues>({
        defaultValues: {
            agreementIds: [],
            comments: '',
            customTemplate: 'no',
            effectiveDate: '',
            entityConfirmation: false,
            newPartyCompanyAddressLine1: '',
            newPartyCompanyAddressLine2: '',
            newPartyCompanyAddressLine3: '',
            newPartyCompanyAddressLinePostCode: '',
            newPartyCompanyContactName: '',
            newPartyCompanyInvoiceAddressLine1: '',
            newPartyCompanyInvoiceAddressLine2: '',
            newPartyCompanyInvoiceAddressLine3: '',
            newPartyCompanyInvoiceAddressLinePostCode: '',
            newPartyCompanyName: '',
            newPartyCompanyRegistrationNumber: '',
            newPartyEmailAddress: '',
            newPartyVatNumber: '',
            oldPartyCompanyName: '',
            poNumber: '',
            sameAddress: true,
            typeOfChange: ''
        },
        mode: 'onTouched',
        resolver: yupResolver(schema)
    });
    const sameAddress = methods.watch('sameAddress', false);

    const { data: agreements } = useAgreementsList({
        IncludeRestrictedAgreements: true
    });

    const {
        error,
        isLoading,
        mutate: requestNovation
    } = useAgreementsRequestNovation<{
        errors: Record<Path<AddLicenseeFormValues>, string[]>;
    }>({
        onError: () => {
            push(ResultModal, {
                description: t('result.error-description'),
                title: t('agreement.novation-request.error-title')
            });
        },
        onSuccess: () => {
            pop();
            push(ResultModal, {
                description: t(
                    'agreement.novation-request.success-description'
                ),
                title: t('agreement.novation-request.success-title')
            });
        }
    });

    const { mutate: requestNovationGuideDownload } =
        useAgreementsNovationGuideDownload();

    const agreementIdsOptions = useMemo<MultiCheckboxFieldProps['options']>(
        () =>
            agreements?.agreements
                ?.filter(
                    (item) =>
                        !isUndefined(item.agreementNumber) &&
                        !isUndefined(item.agreementId)
                )
                .map((item) => ({
                    label: item.agreementNumber as number,
                    value: item.agreementId as number
                })) ?? [],
        [agreements?.agreements]
    );

    const typeOfChangeOptions = useMemo<SelectFieldProps['options']>(
        () => [
            {
                label: t(
                    'agreement.novation-request.type-of-change-option.name'
                ),
                value: 'Simple Name Change'
            },
            {
                label: t(
                    'agreement.novation-request.type-of-change-option.ipr-change'
                ),
                value: 'IPR Change'
            },
            {
                label: t(
                    'agreement.novation-request.type-of-change-option.other'
                ),
                value: 'Other'
            }
        ],
        [t]
    );

    const submit = useCallback<SubmitHandler<AddLicenseeFormValues>>(
        (formData) => {
            // We need to concat address into one field.
            const {
                customTemplate,
                newPartyCompanyAddressLine1,
                newPartyCompanyAddressLine2,
                newPartyCompanyAddressLine3,
                newPartyCompanyAddressLinePostCode,
                newPartyCompanyInvoiceAddressLine1,
                newPartyCompanyInvoiceAddressLine2,
                newPartyCompanyInvoiceAddressLine3,
                newPartyCompanyInvoiceAddressLinePostCode,
                ...requiredFields
            } = formData;

            const data: RequestNovationRequestViewModel = requiredFields;
            data.newPartyCompanyAddress = [
                newPartyCompanyAddressLine1,
                newPartyCompanyAddressLine2,
                newPartyCompanyAddressLine3,
                newPartyCompanyAddressLinePostCode
            ]
                .filter(Boolean)
                .join(', ');
            data.newPartyInvoiceAddress = [
                newPartyCompanyInvoiceAddressLine1,
                newPartyCompanyInvoiceAddressLine2,
                newPartyCompanyInvoiceAddressLine3,
                newPartyCompanyInvoiceAddressLinePostCode
            ]
                .filter(Boolean)
                .join(', ');

            data.useCustomTemplate = customTemplate === 'yes';

            // if (phone) data.phone = phone;
            // if (comments) data.comments = comments;

            requestNovation({ data });
        },
        [requestNovation]
    );

    const downloadNovationGuide = useCallback(() => {
        requestNovationGuideDownload();
    }, [requestNovationGuideDownload]);

    useBackendValidation(error?.response?.data?.errors, methods.setError, [
        'newPartyCompanyAddress',
        'newPartyInvoiceAddress'
    ]);

    useEffect(() => {
        if (sameAddress) {
            methods.resetField('newPartyCompanyInvoiceAddressLine1');
            methods.resetField('newPartyCompanyInvoiceAddressLine2');
            methods.resetField('newPartyCompanyInvoiceAddressLine3');
            methods.resetField('newPartyCompanyInvoiceAddressLinePostCode');
        }
    }, [methods, sameAddress]);

    const columnCssClasses = 'flex flex-col gap-2.5';

    return (
        <Modal className="max-w-2xl" size="l">
            <Modal.Header>{t('agreement.novation-request.title')}</Modal.Header>
            <Modal.Body>
                <FormProvider {...methods}>
                    <form
                        onSubmit={methods.handleSubmit(submit)}
                        className="grid grid-cols-2 gap-2.5 pl-1"
                    >
                        <div className={columnCssClasses}>
                            <DatePickerField
                                name="effectiveDate"
                                label={t(
                                    'agreement.novation-request.effective-date'
                                )}
                                className="w-full"
                                required
                            />
                            <MultiCheckboxField
                                name="agreementIds"
                                label={t(
                                    'agreement.novation-request.agreement-ids'
                                )}
                                options={agreementIdsOptions}
                                required
                            />
                            <SelectField
                                name="typeOfChange"
                                placeholder={t(
                                    'agreement.novation-request.type-placeholder'
                                )}
                                options={typeOfChangeOptions}
                                required
                            />

                            <InputField
                                name="oldPartyCompanyName"
                                label={t(
                                    'agreement.novation-request.old-party-company-name'
                                )}
                                placeholder={t(
                                    'agreement.novation-request.old-party-company-name-placeholder'
                                )}
                                required
                            />
                            <InputField
                                name="newPartyCompanyName"
                                label={t(
                                    'agreement.novation-request.new-party-company-name'
                                )}
                                placeholder={t(
                                    'agreement.novation-request.new-party-company-name-placeholder'
                                )}
                                required
                            />

                            <InputField
                                name="newPartyCompanyContactName"
                                label={t(
                                    'agreement.novation-request.new-party-company-contact-name'
                                )}
                                placeholder={t(
                                    'agreement.novation-request.new-party-company-contact-name-placeholder-placeholder'
                                )}
                                required
                            />

                            <InputField
                                name="newPartyCompanyRegistrationNumber"
                                label={t(
                                    'agreement.novation-request.new-party-company-registration-number'
                                )}
                                placeholder={t(
                                    'agreement.novation-request.new-party-company-registration-number-placeholder'
                                )}
                                required
                            />
                            <div className="flex w-full gap-3">
                                <p>
                                    {t(
                                        'agreement.novation-request.new-party-vat-number'
                                    )}
                                    <Asterisk />
                                </p>

                                <ConfirmationTooltip
                                    placement="right"
                                    icon={
                                        <FontAwesomeIcon
                                            icon={faDiamondExclamation}
                                        />
                                    }
                                    message={t(
                                        'agreement-novation-request.vat'
                                    )}
                                    variant="default"
                                    disabled={false}
                                    className="relative"
                                >
                                    <FontAwesomeIcon
                                        icon={faInfoCircle}
                                        className="text-brand-escode-neonblue-neonblue-100"
                                    />
                                </ConfirmationTooltip>
                            </div>
                            <InputField
                                name="newPartyVatNumber"
                                placeholder={t(
                                    'agreement.novation-request.new-party-vat-number-placeholder'
                                )}
                                required
                            />

                            <InputField
                                name="newPartyEmailAddress"
                                label={t(
                                    'agreement.novation-request.new-party-email-address'
                                )}
                                placeholder={t(
                                    'agreement.novation-request.new-party-email-address-placeholder'
                                )}
                                required
                            />

                            <InputField
                                name="poNumber"
                                label={t(
                                    'agreement.novation-request.po-number'
                                )}
                                placeholder={t(
                                    'agreement.novation-request.po-number-placeholder'
                                )}
                                required
                            />
                        </div>
                        <div className={columnCssClasses}>
                            <InputField
                                name="newPartyCompanyAddressLine1"
                                label={t(
                                    'agreement.novation-request.new-party-company-address'
                                )}
                                placeholder={t(
                                    'agreement.novation-request.new-party-company-address-line-1-placeholder'
                                )}
                                required
                            />
                            <InputField
                                name="newPartyCompanyAddressLine2"
                                placeholder={t(
                                    'agreement.novation-request.new-party-company-address-line-2-placeholder'
                                )}
                            />
                            <InputField
                                name="newPartyCompanyAddressLine3"
                                placeholder={t(
                                    'agreement.novation-request.new-party-company-address-line-3-placeholder'
                                )}
                            />
                            <InputField
                                name="newPartyCompanyAddressLinePostCode"
                                placeholder={t(
                                    'agreement.novation-request.new-party-company-address-line-post-code-placeholder'
                                )}
                                required
                            />

                            <CheckField
                                id="sameAddress"
                                label={t(
                                    'agreement.novation-request.same-address'
                                )}
                                name="sameAddress"
                                orientation="checkbox-first"
                            />

                            <InputField
                                name="newPartyCompanyInvoiceAddressLine1"
                                label={t(
                                    'agreement.novation-request.new-party-company-invoice-address'
                                )}
                                placeholder={t(
                                    'agreement.novation-request.new-party-company-address-line-1-placeholder'
                                )}
                                disabled={sameAddress}
                                required={!sameAddress}
                            />
                            <InputField
                                name="newPartyCompanyInvoiceAddressLine2"
                                placeholder={t(
                                    'agreement.novation-request.new-party-company-address-line-2-placeholder'
                                )}
                                disabled={sameAddress}
                            />
                            <InputField
                                name="newPartyCompanyInvoiceAddressLine3"
                                placeholder={t(
                                    'agreement.novation-request.new-party-company-address-line-3-placeholder'
                                )}
                                disabled={sameAddress}
                            />
                            <InputField
                                name="newPartyCompanyInvoiceAddressLinePostCode"
                                placeholder={t(
                                    'agreement.novation-request.new-party-company-address-line-post-code-placeholder'
                                )}
                                disabled={sameAddress}
                                required={!sameAddress}
                            />

                            <TextareaField
                                name="comments"
                                label={t('agreement.novation-request.comments')}
                                placeholder={t(
                                    'agreement.novation-request.comments-placeholder'
                                )}
                            />

                            <div>
                                {t(
                                    'agreement.novation-request.additional-documents'
                                )}
                                <Button
                                    type="button"
                                    onClick={downloadNovationGuide}
                                >
                                    {t(
                                        'agreement.novation-request.request-novation-guide'
                                    )}
                                </Button>
                            </div>
                            <RadioGroupField
                                label={t(
                                    'agreement.novation-request.custom-template'
                                )}
                                name="customTemplate"
                                options={radioOptions}
                            />
                        </div>

                        <div className="col-span-2">
                            <CheckField
                                id="entityConfirmation"
                                label={t(
                                    'agreement.novation-request.entity-confirmation'
                                )}
                                name="entityConfirmation"
                                orientation="checkbox-first"
                                required
                            />
                        </div>

                        <Button
                            variant="primary"
                            className="col-span-2"
                            loading={isLoading}
                        >
                            {t('licensee.add.confirm')}
                        </Button>
                    </form>
                </FormProvider>
            </Modal.Body>
        </Modal>
    );
}

export default RequestNovationModal;
export type { AddLicenseeFormValues, RequestNovationModalProps };
