import { DocumentMetadataResponseViewModel } from '../../autogenerated/data-contracts';
import { UseQueryOptions, useQuery } from 'react-query';
import { isUndefined } from 'lodash';

import { ApiController } from '../../autogenerated/Api';
import { useAuth } from 'react-oidc-context';
import interceptors from '../../../interceptors';
import securityWorker from '../../../security-worker';
import useAgreementsContractsMockData from './use-agreements-contracts-mock-data';

function useAgreementsContracts<TError = unknown>(
    agreementId: number | undefined,
    options?: Omit<
        UseQueryOptions<
            DocumentMetadataResponseViewModel | undefined,
            ReactQueryError<TError>,
            DocumentMetadataResponseViewModel | undefined
        >,
        'queryKey' | 'queryFn'
    >
) {
    const auth = useAuth();

    return useQuery(
        [
            'contracts',
            agreementId,
            { token: auth.user?.access_token, tokenType: auth.user?.token_type }
        ],
        async () => {
            if (isUndefined(agreementId)) {
                console.warn('Undefined ID in useAgreementsContracts');
                return undefined;
            }
            if (process.env.REACT_APP_API_MOCK) {
                console.warn('Using mock data for useAgreementsContracts');
                return await new Promise<DocumentMetadataResponseViewModel>(
                    (resolve, reject) => {
                        setTimeout(
                            () => resolve(useAgreementsContractsMockData),
                            1000
                        );
                    }
                );
            }

            if (
                isUndefined(auth.user?.access_token) ||
                isUndefined(auth.user?.token_type)
            ) {
                return undefined;
            }

            const controller = new ApiController({
                interceptors,
                securityWorker: securityWorker(
                    auth.user?.token_type,
                    auth.user?.access_token
                )
            });

            // We don't want to catch any errors here since it will broke react query "error" status
            const response = await controller.getAgreementContracts(
                agreementId
            );
            return response.data;
        },
        options
    );
}

export default useAgreementsContracts;
