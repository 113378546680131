import { AxiosResponse } from 'axios';
import { InviteContactResponse } from '../../autogenerated/data-contracts';
import { useMutation, useQueryClient } from 'react-query';

import { ApiController } from '../../autogenerated/Api';
import { useAuth } from 'react-oidc-context';
import interceptors from '../../../interceptors';
import securityWorker from '../../../security-worker';

function useUserInvite() {
    const queryClient = useQueryClient();
    const auth = useAuth();

    return useMutation(
        [{ token: auth.user?.access_token, tokenType: auth.user?.token_type }],
        async (id: number) => {
            if (process.env.REACT_APP_API_MOCK) {
                console.warn('Mock is enabled useUserInvite call ignored.');
                return Promise.resolve(
                    {} as AxiosResponse<InviteContactResponse>
                );
            }

            const controller = new ApiController({
                interceptors,
                securityWorker: securityWorker(
                    auth.user?.token_type,
                    auth.user?.access_token
                )
            });

            return controller.inviteContact(id);
        },
        {
            onSettled: (data, error, variables, context) => {
                queryClient.invalidateQueries({ queryKey: ['contacts'] });
            }
        }
    );
}

export default useUserInvite;
