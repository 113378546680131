import { AgreementPartiesTableContext } from './agreement-parties-table-context';
import { AgreementPartyViewModel } from 'core/api/client-portal/autogenerated/data-contracts';
import { Button, ICellRendererParams } from 'lib-ui';
import { useCallback } from 'react';
import AgreementPartiesContactModal from './agreement-parties-contact-modal';

function AgreementPartyContactsCellRenderer({
    context: { push, t },
    data
}: ICellRendererParams<AgreementPartyViewModel, AgreementPartiesTableContext>) {
    const viewContactDetails = useCallback(() => {
        push(AgreementPartiesContactModal, {
            agreementPartyContacts: data?.agreementPartyContacts,
            agreementPartyId: data?.agreementPartyId,
            billingAccount: data?.billingAccount,
            canEditContacts: data?.canEditContacts,
            depositor: data?.depositor,
            partyStatus: data?.partyStatus,
            partyStatusDate: data?.partyStatusDate,
            partyType: data?.partyType,
            registeredName: data?.registeredName
        });
    }, [
        data?.agreementPartyContacts,
        data?.agreementPartyId,
        data?.billingAccount,
        data?.canEditContacts,
        data?.depositor,
        data?.partyStatus,
        data?.partyStatusDate,
        data?.partyType,
        data?.registeredName,
        push
    ]);

    return (
        <div className="py-3 flex flex-col">
            <Button onClick={viewContactDetails} variant="tertiary">
                {t('agreement-party-contact-details')}
            </Button>
        </div>
    );
}

export default AgreementPartyContactsCellRenderer;
