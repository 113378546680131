import { saveAsFile } from '@ncc-frontend/core';
import { useMutation } from 'react-query';
import axios from 'axios';

function useAgreementsNovationGuideDownload() {
    return useMutation(async () => {
        const instance = axios.create({});

        return new Promise((resolve, reject) => {
            instance
                .request({
                    responseType: 'blob',
                    url: '/assets/docs/novation-guide.pdf'
                })
                .then((response) => {
                    saveAsFile(`Novation Guide.pdf`, response.data, {
                        type: 'application/pdf'
                    });
                    resolve(response.data);
                });
        });
    });
}
export default useAgreementsNovationGuideDownload;
