import { CSSProperties, PropsWithChildren, useRef } from 'react';
import { TailwindStyle } from '@ncc-frontend/core';
import { Widget, WidgetHeaderProps } from 'lib-ui';
import { merge } from 'lodash';

interface DashboardWidgetHeaderProps extends WidgetHeaderProps {}

function DashboardWidgetHeader({
    children,
    style,
    tailwindStyle,
    ...restProps
}: PropsWithChildren<DashboardWidgetHeaderProps>) {
    const { current: mergedTailwindStyle } = useRef<TailwindStyle>(
        merge<TailwindStyle, TailwindStyle | undefined>(
            {
                background: '',
                padding: '',
                textColor: 'text-general-grey-grey-90',
                textSize: 'text-2xl',
                textWeight: 'font-medium'
            },
            tailwindStyle
        )
    );

    const mergedStyle = merge<CSSProperties, CSSProperties | undefined>(
        {
            height: 'var(--escode-dashboard-widget-height)',
            padding: 'var(--escode-dashboard-widget-padding)'
        },
        style
    );

    return (
        <Widget.Header
            {...restProps}
            tailwindStyle={mergedTailwindStyle}
            style={mergedStyle}
        >
            {children}
        </Widget.Header>
    );
}

export default DashboardWidgetHeader;
export type { DashboardWidgetHeaderProps };
