import AppBody from './app-body';
import Dashboard from 'ui/dashboard/dashboard';
import WelcomeMessage from 'ui/dashboard/welcome-message';

function DashboardBody() {
    return (
        <AppBody
            suppressPadding
            className="pl-5 pb-5 pr-7 pt-10 flex flex-col gap-5"
        >
            <WelcomeMessage />
            <Dashboard style={{ height: 'calc(100% - 2.5rem - 2.25rem' }} />
        </AppBody>
    );
}

export default DashboardBody;
