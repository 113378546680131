function monthFormatter(
    value: string | Date | null | undefined,
    locale = 'en-GB'
) {
    if (!value) return '';

    const date = typeof value === 'object' ? value : new Date(value);

    return date.toLocaleString(locale, { month: 'long' });
}

export default monthFormatter;
