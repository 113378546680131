import { GetIncompleteDepositsResponse } from '../../autogenerated/data-contracts';

const useIncompleteDepositsMockData: GetIncompleteDepositsResponse = {
    deposits: [
        {
            agreementId: 0,
            agreementNumber: 0,
            created: '2023-03-13T11:07:57.445Z',
            depositId: 0,
            softwarePackageName: 'softwarePackageName0',
            versionOrReleaseNumber: 'versionOrReleaseNumber0'
        },
        {
            agreementId: 1,
            agreementNumber: 1,
            created: '2023-03-13T11:07:57.445Z',
            depositId: 1,
            softwarePackageName: 'softwarePackageName1',
            versionOrReleaseNumber: 'versionOrReleaseNumber1'
        },
        {
            agreementId: 2,
            agreementNumber: 2,
            created: '2023-03-13T11:07:57.445Z',
            depositId: 2,
            softwarePackageName: 'softwarePackageName2',
            versionOrReleaseNumber: 'versionOrReleaseNumber2'
        },
        {
            agreementId: 3,
            agreementNumber: 3,
            created: '2023-03-13T11:07:57.445Z',
            depositId: 3,
            softwarePackageName: 'softwarePackageName1',
            versionOrReleaseNumber: 'versionOrReleaseNumber1'
        },
        {
            agreementId: 4,
            agreementNumber: 4,
            created: '2023-03-13T11:07:57.445Z',
            depositId: 4,
            softwarePackageName: 'softwarePackageName2',
            versionOrReleaseNumber: 'versionOrReleaseNumber2'
        },
        {
            agreementId: 5,
            agreementNumber: 5,
            created: '2023-03-13T11:07:57.445Z',
            depositId: 5,
            softwarePackageName: 'softwarePackageName1',
            versionOrReleaseNumber: 'versionOrReleaseNumber1'
        },
        {
            agreementId: 6,
            agreementNumber: 6,
            created: '2023-03-13T11:07:57.445Z',
            depositId: 6,
            softwarePackageName: 'softwarePackageName2',
            versionOrReleaseNumber: 'versionOrReleaseNumber2'
        },
        {
            agreementId: 7,
            agreementNumber: 7,
            created: '2023-03-13T11:07:57.445Z',
            depositId: 7,
            softwarePackageName: 'softwarePackageName1',
            versionOrReleaseNumber: 'versionOrReleaseNumber1'
        },
        {
            agreementId: 8,
            agreementNumber: 8,
            created: '2023-03-13T11:07:57.445Z',
            depositId: 8,
            softwarePackageName: 'softwarePackageName2',
            versionOrReleaseNumber: 'versionOrReleaseNumber2'
        },
        {
            agreementId: 9,
            agreementNumber: 9,
            created: '2023-03-13T11:07:57.445Z',
            depositId: 9,
            softwarePackageName: 'softwarePackageName1',
            versionOrReleaseNumber: 'versionOrReleaseNumber1'
        },
        {
            agreementId: 10,
            agreementNumber: 10,
            created: '2023-03-13T11:07:57.445Z',
            depositId: 10,
            softwarePackageName: 'softwarePackageName2',
            versionOrReleaseNumber: 'versionOrReleaseNumber2'
        },
        {
            agreementId: 11,
            agreementNumber: 11,
            created: '2023-03-13T11:07:57.445Z',
            depositId: 11,
            softwarePackageName: 'softwarePackageName1',
            versionOrReleaseNumber: 'versionOrReleaseNumber1'
        },
        {
            agreementId: 12,
            agreementNumber: 12,
            created: '2023-03-13T11:07:57.445Z',
            depositId: 12,
            softwarePackageName: 'softwarePackageName2',
            versionOrReleaseNumber: 'versionOrReleaseNumber2'
        }
    ],
    isValid: true,
    validationErrors: {
        additionalProp1: 'additionalProp1',
        additionalProp2: 'additionalProp2',
        additionalProp3: 'additionalProp3'
    }
};

export default useIncompleteDepositsMockData;
