import { AxiosResponse } from 'axios';
import { MutationOptions, useMutation } from 'react-query';
import { saveAsFile } from '@ncc-frontend/core';

import { ApiController } from '../../autogenerated/Api';
import { useAuth } from 'react-oidc-context';
import interceptors from '../../../interceptors';
import securityWorker from '../../../security-worker';

type DepositVerificationDownloadParams = {
    agreementId: number;
    depositId: number;
    index: string;
    slxType: string;
};

function useDepositVerificationDownload<TError = unknown, TContext = unknown>(
    options?: Omit<
        MutationOptions<
            AxiosResponse,
            ReactQueryError<TError>,
            DepositVerificationDownloadParams,
            TContext
        >,
        'mutationFn'
    >
) {
    const auth = useAuth();

    return useMutation(
        [{ token: auth.user?.access_token, tokenType: auth.user?.token_type }],
        async ({
            agreementId,
            depositId,
            index,
            slxType
        }: DepositVerificationDownloadParams) => {
            if (process.env.REACT_APP_API_MOCK) {
                console.warn(
                    'Mock is enabled useDepositVerificationDownload call ignored.'
                );
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                return Promise.resolve({} as any);
            }

            const controller = new ApiController({
                format: 'blob',
                interceptors,
                securityWorker: securityWorker(
                    auth.user?.token_type,
                    auth.user?.access_token
                )
            });

            return new Promise((resolve, reject) => {
                controller
                    .downloadVerificationReports(
                        agreementId,
                        depositId,
                        index,
                        {
                            format: 'blob'
                        }
                    )
                    // BACKEND: swagger doc
                    .then((response: AxiosResponse) => {
                        saveAsFile(
                            `Deposit Verification Report ${slxType}`,
                            response.data
                        );
                        resolve(response.data);
                    });
            });
        },
        options
    );
}

export default useDepositVerificationDownload;
export type { DepositVerificationDownloadParams };
