import { DownloadScheduleItem } from 'core/api/client-portal/autogenerated/data-contracts';
import { useTranslation } from 'react-i18next';

import SchedulesMetricPanel from '../schedules-metric-panel';

function previousMonthFilter(schedule: DownloadScheduleItem): boolean {
    if (!schedule.nextDownloadAt) return false;

    const today = new Date();
    const date = new Date(schedule.nextDownloadAt);
    const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1);
    const lastMonthEnd = new Date(today.getFullYear(), today.getMonth());

    return date >= lastMonthStart && date < lastMonthEnd;
}

interface PreviousMonthSchedulesProps {
    data: DownloadScheduleItem[] | null | undefined;
    loading?: boolean;
}

function PreviousMonthSchedules({
    data,
    loading
}: PreviousMonthSchedulesProps) {
    const { t } = useTranslation();

    return (
        <SchedulesMetricPanel
            data={data}
            title={t('schedules.metrics.previous-month')}
            filter={previousMonthFilter}
            loading={loading}
        />
    );
}

export default PreviousMonthSchedules;
export type { PreviousMonthSchedulesProps };
