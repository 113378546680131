import { CSSProperties, PropsWithChildren } from 'react';
import { Widget, WidgetProps } from 'lib-ui';
import { merge } from 'lodash';
import DashboardWidgetBody from './dashboard-widget-body';
import DashboardWidgetHeader from './dashboard-widget-header';
import classNames from 'classnames';

interface DashboardWidgetProps extends WidgetProps {
    suppressPadding?: boolean;
}

function DashboardWidget({
    children,
    className,
    style,
    suppressPadding = false,
    ...restProps
}: PropsWithChildren<DashboardWidgetProps>) {
    const cssClasses = classNames({ 'px-6 py-4': !suppressPadding }, className);
    const mergedStyle = merge<CSSProperties, CSSProperties | undefined>(
        {
            gap: 'var(--escode-dashboard-widget-gap)'
        },
        style
    );

    return (
        <Widget {...restProps} className={cssClasses} style={mergedStyle}>
            {children}
        </Widget>
    );
}

DashboardWidget.Header = DashboardWidgetHeader;
DashboardWidget.Body = DashboardWidgetBody;

export default DashboardWidget;
export type { DashboardWidgetProps };
