import { CommonProps } from '@ncc-frontend/core';
import { PropsWithChildren } from 'react';
import classNames from 'classnames';

interface FilterStepFooterProps extends CommonProps {}

function FilterStepFooter({
    children,
    className,
    ...restProps
}: PropsWithChildren<FilterStepFooterProps>) {
    const cssClasses = classNames(
        'flex items-center justify-center gap-2.5',
        className
    );

    return (
        <div className={cssClasses} {...restProps}>
            {children}
        </div>
    );
}

export default FilterStepFooter;
export type { FilterStepFooterProps };
