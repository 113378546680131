import { TailwindProps, tailwindClasses } from '@ncc-frontend/core';
import classNames from 'classnames';

interface SkeletonCircleProps extends TailwindProps {}

function SkeletonCircle({
    className,
    tailwindStyle,
    ...restProps
}: SkeletonCircleProps) {
    const cssClasses = classNames(
        tailwindClasses(
            {
                display: 'flex',
                justify: 'justify-center',
                position: 'relative'
            },
            tailwindStyle
        ),
        className
    );

    const size = 300;
    const radius = size / 2;

    return (
        <div {...restProps} className={cssClasses}>
            <svg
                viewBox={`0 0 ${size} ${size}`}
                className="max-h-full max-w-full"
            >
                <circle
                    cx={size / 2}
                    cy={size / 2}
                    r={radius}
                    className="animate-pulse fill-escode-grey-30"
                />
            </svg>
        </div>
    );
}

export default SkeletonCircle;
export type { SkeletonCircleProps };
