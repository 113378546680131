import { Button, ConfirmationTooltip, ResultModal } from 'lib-ui';
import { DEPOSIT_FREQUENCY_MAPPER } from 'core/api/client-portal/model/deposit-frequency';
import { DownloadScheduleItem } from 'core/api/client-portal/autogenerated/data-contracts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiamondExclamation } from '@fortawesome/pro-solid-svg-icons';
import { useModals } from '@ncc-frontend/core';
import { useTranslation } from 'react-i18next';
import EditScheduleModal from 'ui/schedules/edit-schedule-modal/edit-schedule-modal';
import ScheduleFrequencyTag from '../schedule-frequency-tag';
import timestampFormatter from 'core/formatters/timestamp-formatter';
import useBeautify from 'core/beautifiers/use-beautify';
import useSchedulesDelete from 'core/api/client-portal/hooks/schedules/use-schedules-delete';

export interface ScheduleDepositItemProps {
    data: DownloadScheduleItem;
}

function ScheduleDepositItem({ data }: ScheduleDepositItemProps) {
    const { i18n, t } = useTranslation();
    const { beautifyDate, beautifyFrequency } = useBeautify();
    const { push } = useModals();

    const { mutate: requestScheduleDelete } = useSchedulesDelete({
        onError: () => {
            push(ResultModal, {
                description: t('schedules.delete-modal.error-description'),
                title: t('schedules.delete-modal.error-title')
            });
        }
    });

    const amend = () => {
        push(EditScheduleModal, { data });
    };

    const remove = () => {
        requestScheduleDelete({ query: { downloadId: data.id } });
    };

    return (
        <div className="border-b border-brand-escode-neonblue-neonblue-80 mx-2 py-4 px-0 flex justify-around items-center ">
            <div>
                <div className="text-base text-brand-escode-neonblue-neonblue-100 font-medium">
                    <p
                        className="truncate w-28"
                        data-testid="schedule-deposit-item-title"
                        title={`${data.agreementNumber} - ${data.softwarePackageName} (${data.versionOrReleaseNumber})`}
                    >
                        {data.agreementNumber} - {data.softwarePackageName}
                        {`(${data.versionOrReleaseNumber})`}
                    </p>
                </div>
                <div className="text-xs text-escode-grey-50">
                    {beautifyDate(
                        timestampFormatter(data.nextDownloadAt, i18n.language)
                    )}
                </div>
            </div>
            <ScheduleFrequencyTag
                size="s"
                value={DEPOSIT_FREQUENCY_MAPPER[data?.frequencyType ?? 0]}
            >
                {beautifyFrequency(
                    DEPOSIT_FREQUENCY_MAPPER[data?.frequencyType ?? 0]
                )}
            </ScheduleFrequencyTag>
            <div className="flex gap-1">
                <Button size="s" variant="tertiary" onClick={amend}>
                    {t('schedules.amend')}
                </Button>

                <ConfirmationTooltip
                    placement="left"
                    icon={<FontAwesomeIcon icon={faDiamondExclamation} />}
                    title={t('schedule.delete-tooltip-title')}
                    onConfirm={remove}
                    variant="warning"
                    confirmMessage={t('tooltip.proceed')}
                    rejectMessage={t('tooltip.reject')}
                >
                    <Button size="s" variant="danger">
                        {t('delete')}
                    </Button>
                </ConfirmationTooltip>
            </div>
        </div>
    );
}

export default ScheduleDepositItem;
