import { CalendarData } from '../types';
import { useEffect } from 'react';
import CalendarMonthDay from './calendar-month-day';
import ScrollHarness from '../../scroll-harness/scroll-harness';

interface CalendarMonthProps {
    currentDate: Date;
    data: CalendarData[] | undefined;
    onUpdateHasData: (newValue: boolean) => void;
}
const weekDays: string[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
function CalendarMonth({
    currentDate,
    data,
    onUpdateHasData
}: CalendarMonthProps) {
    const days: Date[] = [];
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    // Add the days from the previous month until the last Sunday to the array
    const firstDayOfMonth = new Date(currentYear, currentMonth, 1).getDay();
    const lastDayOfPreviousMonth = new Date(
        currentYear,
        currentMonth,
        0
    ).getDate();
    const daysFromPreviousMonth = (firstDayOfMonth + 7) % 7;

    for (
        let day = lastDayOfPreviousMonth - daysFromPreviousMonth + 1;
        day <= lastDayOfPreviousMonth;
        day++
    ) {
        days.push(new Date(currentYear, currentMonth - 1, day));
    }

    // Add the days of the current month to the array
    const totalDays = new Date(currentYear, currentMonth + 1, 0).getDate();

    for (let day = 1; day <= totalDays; day++) {
        days.push(new Date(currentYear, currentMonth, day));
    }

    // Add days from the next month until the array has 35 positions
    const remainingDays = 35 - days.length;

    for (let day = 1; day <= remainingDays; day++) {
        days.push(new Date(currentYear, currentMonth + 1, day));
    }

    useEffect(() => {
        function isInCurrentMonth(date: Date) {
            return date.getMonth() === currentMonth;
        }
        const objectsInCurrentMonth = data?.filter((obj) =>
            isInCurrentMonth(obj.date)
        );

        // onUpdateHasData(objectsInCurrentMonth?.length > 0);
        onUpdateHasData(
            objectsInCurrentMonth ? objectsInCurrentMonth.length > 0 : false
        );
    }, [currentMonth, data, onUpdateHasData]);

    return (
        <table
            role="grid"
            className="h-full w-full border-collapse table-fixed"
        >
            <thead>
                <tr role="presentation">
                    <th role="presentation" className="p-0">
                        <table
                            role="presentation"
                            className="w-full border-collapse table-fixed  text-center"
                        >
                            <colgroup>
                                <col className="w-0" />
                            </colgroup>
                            <thead role="presentation">
                                <tr role="row">
                                    <th aria-hidden />
                                    {weekDays.map((dayOfWeek, index) => (
                                        <th
                                            key={index + dayOfWeek}
                                            className="text-general-grey-grey-90 font-normal py-5"
                                        >
                                            {dayOfWeek.toString()}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                        </table>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr role="presentation">
                    <td role="presentation">
                        <ScrollHarness>
                            <div className="absolute inset-0">
                                <table className="w-full">
                                    <tbody role="presentation">
                                        <tr role="row">
                                            <CalendarMonthDay
                                                allDays={days}
                                                currentDate={currentDate}
                                                data={data}
                                            />
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </ScrollHarness>
                    </td>
                </tr>
            </tbody>
        </table>
    );
}

export default CalendarMonth;
export type { CalendarMonthProps };
