import { CheckboxField, RadioGroupField } from 'lib-ui';
import { Frequency } from './frequency-enum';
import { FrequencySection } from './frequency-section';
import {
    automatedOptions,
    frequencyOptions,
    hoursOptions,
    minutesOptions
} from './provider-scd-utils';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type AutomateFutureDepositsParams = {
    isAutomated?: boolean;
};

const AutomateFutureDeposits = ({
    isAutomated = false
}: AutomateFutureDepositsParams) => {
    const { t } = useTranslation();
    const { frequency } = useFormContext().watch();

    // Determine which sections to show
    const showWeekly = frequency === Frequency.Weekly;
    const showMonth = frequency === Frequency.Monthly;
    const showQuarterly = frequency === Frequency.Quarterly;
    const showAnnually = frequency === Frequency.Annually;

    return (
        <div className="mt-6 flex-1 items-center">
            <RadioGroupField
                name="isAutomated"
                options={automatedOptions}
                required
            />

            {isAutomated && (
                <section
                    className="flex-1 pt-4"
                    data-testid="automated-section"
                >
                    <div className="rounded-lg border border-general-grey-grey-40 px-3 py-3">
                        <CheckboxField
                            name="agreeAutomatedDepositsCheckbox"
                            label={t('automate-future-deposits.automate')}
                        />
                    </div>
                    <div className="mb-4 mt-4 rounded-lg border border-general-grey-grey-40 px-3 py-3">
                        <CheckboxField
                            name="notifyChangesCheckbox"
                            label={t('automate-future-deposits.notify')}
                        />
                    </div>

                    <p className="text-md font-medium">
                        {t('automate-future-deposits.frequency')}
                    </p>
                    <p className="text-sm mt-2">
                        {t('automate-future-deposits.past-date')}
                    </p>
                    <div className="mt-3 flex w-full justify-around gap-3 pt-2">
                        <div className="flex flex-col gap-4 rounded-lg border border-general-grey-grey-40 p-3">
                            <p className="text-md font-medium">
                                {t('automate-future-deposits.frequency')}
                            </p>
                            <RadioGroupField
                                name="frequency"
                                options={frequencyOptions}
                                required
                                orientation="vertical"
                            />
                        </div>
                        <FrequencySection
                            showWeekly={showWeekly}
                            showQuarterly={showQuarterly}
                            showMonth={showMonth}
                            showAnnually={showAnnually}
                            allowedHours={hoursOptions}
                            allowedMinutes={minutesOptions}
                        />
                    </div>
                </section>
            )}
        </div>
    );
};

export default AutomateFutureDeposits;
