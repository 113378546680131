import * as yup from 'yup';
import { Button, SubmitButton, SubmitButtonRef } from 'lib-ui';
import { EditScheduleFormValues } from './types';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { TailwindStyle, useModals } from '@ncc-frontend/core';
import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import { DEPOSIT_FREQUENCY_MAPPER } from 'core/api/client-portal/model/deposit-frequency';
import {
    DownloadScheduleItem,
    FrequencyType
} from 'core/api/client-portal/autogenerated/data-contracts';
import { Lang } from 'localization';
import FrequencyControl from './frequency-control';
import TimeControl from './time-control';
import getDayOfWeek, {
    DAY_OF_WEEK_MAPPER
} from 'core/formatters/get-day-of-week';
import useBeautify from 'core/beautifiers/use-beautify';

interface ScheduleFormProps {
    data: DownloadScheduleItem;
    frequency?: FrequencyType;
    loading?: boolean;
    onSubmit: SubmitHandler<EditScheduleFormValues>;
}

function ScheduleForm({
    data,
    frequency,
    loading,
    onSubmit
}: ScheduleFormProps) {
    const { t } = useTranslation();
    const { pop } = useModals();
    const { beautifyDateLocalised } = useBeautify();

    const localisation = localStorage!.getItem('i18nextLng') || 'en-GB';

    const localisedTime = beautifyDateLocalised(
        data.nextDownloadAt!,
        localisation as Lang
    ).split(' ')[1];

    const submitButton = useRef<SubmitButtonRef>(null);
    const widgetBodyCssClasses =
        'grid grid-rows-[1fr_auto] gap-2.5 overflow-auto py-3 px-4';
    const { current: widgetHeaderStyle } = useRef<TailwindStyle>({
        background: '',
        border: 'border-b',
        borderColor: 'border-escode-grey-30',
        textColor: 'text-general-grey-grey-90',
        textSize: 'text-base',
        textWeight: 'font-medium'
    });
    const headerHeight = '3.25rem';

    const schema = yup.object<
        Partial<Record<keyof EditScheduleFormValues, yup.AnySchema>>
    >({
        dayOfMonth: yup.string().when(['frequency'], {
            is: (frequency: EditScheduleFormValues['frequency']) =>
                frequency === 'monthly' || frequency === 'annually',
            otherwise: (schema) => schema,
            then: (schema) => {
                return schema.required();
            }
        }),
        dayOfWeek: yup.string().when(['frequency'], {
            is: (frequency: EditScheduleFormValues['frequency']) =>
                frequency === 'weekly',
            otherwise: (schema) => schema,
            then: (schema) => schema.required()
        }),
        frequency: yup.string().required(),
        hour: yup.string().required(),
        minute: yup.string().required(),
        month: yup.string().when(['frequency'], {
            is: (frequency: EditScheduleFormValues['frequency']) =>
                frequency === 'annually',
            otherwise: (schema) => schema,
            then: (schema) => schema.required()
        })
    });

    const today = new Date();
    let defaultDayOfMonth = today.getDate();
    if (today.getMonth() === 1 && defaultDayOfMonth > 28) {
        defaultDayOfMonth = 28;
    }

    const dayOfMonth = data.nextDownloadAt?.split('T')[0].split('-')[2];
    const month = data.nextDownloadAt?.split('T')[0].split('-')[1];
    const hour = localisedTime.split(':')[0];
    const minute = localisedTime.split(':')[1];
    const dayOfWeek = getDayOfWeek(data.nextDownloadAt || '');
    const rangeOfQuarterly = (month: string) => {
        switch (month) {
            case '01':
            case '04':
            case '07':
            case '10':
                return '01';
            case '02':
            case '05':
            case '08':
            case '11':
                return '02';
            case '03':
            case '06':
            case '09':
            case '12':
                return '03';
        }
    };

    const methods = useForm<EditScheduleFormValues>({
        defaultValues: {
            dayOfMonth: dayOfMonth,
            dayOfWeek:
                DAY_OF_WEEK_MAPPER[
                    dayOfWeek as keyof typeof DAY_OF_WEEK_MAPPER
                ],
            frequency: DEPOSIT_FREQUENCY_MAPPER[frequency || 0],
            hour: hour,
            minute: minute,
            month: month,
            rangeOfQuarterly: rangeOfQuarterly(month!)
        },
        mode: 'onChange',
        resolver: yupResolver(schema)
    });

    const onInvalid = useCallback(() => {
        submitButton.current?.shake();
    }, []);

    return (
        <FormProvider {...methods}>
            <form
                onSubmit={methods.handleSubmit(onSubmit, onInvalid)}
                className="grid grid-cols-2 grid-rows-[1fr_auto] gap-2.5 max-h-[20.5rem]"
                // h-72
            >
                <FrequencyControl
                    bodyClassName={widgetBodyCssClasses}
                    headerTailwindStyle={widgetHeaderStyle}
                    headerHeight={headerHeight}
                />

                <TimeControl
                    bodyClassName={widgetBodyCssClasses}
                    headerTailwindStyle={widgetHeaderStyle}
                    headerHeight={headerHeight}
                />

                <div className="col-span-2">
                    <SubmitButton ref={submitButton} loading={loading}>
                        {t('schedules.edit-modal.confirm')}
                    </SubmitButton>
                    <Button variant="danger" onClick={pop} type="button">
                        {t('cancel')}
                    </Button>
                </div>
            </form>
        </FormProvider>
    );
}

export default ScheduleForm;
