import { Modal } from 'lib-ui';
import { useTranslation } from 'react-i18next';
import cn from 'core/utils/cn';
type TerminationFormInfoModalParams = {
    isError: boolean;
};

function TerminationFormInfoModal({ isError }: TerminationFormInfoModalParams) {
    const { t } = useTranslation();

    return (
        <Modal className="overflow-y-auto" size="s">
            <Modal.Header>
                <h2
                    className={cn(
                        'text-xl',
                        isError
                            ? 'text-general-red-red-100'
                            : 'text-general-blue-blue-100'
                    )}
                >
                    {isError
                        ? t('termination-form-info.failed-title')
                        : t('termination-form-info.title')}
                </h2>
            </Modal.Header>
            <span className="py-4 mt-2">
                {isError
                    ? t('termination-form-info.error-message')
                    : t('termination-form-info.success-message')}
            </span>
            <Modal.Body className="flex flex-col gap-2.5"></Modal.Body>
        </Modal>
    );
}

export default TerminationFormInfoModal;
