import { CSSTransition } from 'react-transition-group';
import { CsvExportParams } from 'ag-grid-community';
import { DropdownPanel, Wizard, useDataWidget } from 'lib-ui';
import { RefObject, createRef, useCallback, useState } from 'react';
import { useDropdown } from '@ncc-frontend/core';
import { useTranslation } from 'react-i18next';
import writeXlsxFile from '@ncc-frontend/write-excel-file';

import ExportSelectionFormatStep, {
    FormatSelectEventHandler
} from './export-select-format-step/export-selection-format-step';
import ExportSelectionRangeStep, {
    RangeSelectEventHandler
} from './export-select-range-step/export-selection-range-step';
import ExportSelectionTypeStep, {
    ExportType,
    TypeSelectEventHandler
} from './export-selection-type-step/export-selection-type-step';
import useDepositHistoryReportDownload from 'core/api/client-portal/hooks/agreements/use-deposit-history-report-download';

type ExportStep = 'type' | 'range' | 'format';

function ExportWizard() {
    const { i18n } = useTranslation();
    const { close } = useDropdown();
    const { exportDataAsCsv, getDataAsCsv } = useDataWidget();
    const { mutate: download } = useDepositHistoryReportDownload();

    const [{ node, step }, setActiveStep] = useState<{
        node: RefObject<HTMLDivElement>;
        step: ExportStep;
    }>(() => ({ node: createRef<HTMLDivElement>(), step: 'type' }));
    const [type, setType] = useState<ExportType>();
    const [range, setRange] = useState<[Date, Date]>();

    const handleTypeSelection = useCallback<TypeSelectEventHandler>(
        (selectedType) => {
            setType(selectedType);
            if (selectedType === 'deposit-history') {
                setActiveStep({
                    node: createRef<HTMLDivElement>(),
                    step: 'range'
                });
            } else {
                setActiveStep({
                    node: createRef<HTMLDivElement>(),
                    step: 'format'
                });
            }
        },
        []
    );

    const handleRangeSelection = useCallback<RangeSelectEventHandler>(
        (selectedRange) => {
            setRange(selectedRange);
            setActiveStep({
                node: createRef<HTMLDivElement>(),
                step: 'format'
            });
        },
        []
    );

    const handleFormatSelection = useCallback<FormatSelectEventHandler>(
        async (selectedFormat) => {
            const now = new Date();
            const fileName = `NCC Group Escrow General Export - ${now.toLocaleString(
                i18n.language,
                {
                    month: 'short'
                }
            )} ${now.getFullYear()}.${selectedFormat}`;
            if (type === 'deposit-history') {
                download({
                    endDate: range?.[1].toISOString(),
                    reportFormat: 1,
                    startDate: range?.[0].toISOString()
                });
            } else {
                if (selectedFormat === 'csv' && exportDataAsCsv !== undefined) {
                    const params: CsvExportParams = {
                        fileName
                    };

                    exportDataAsCsv(params);
                } else if (
                    selectedFormat === 'xlsx' &&
                    getDataAsCsv !== undefined
                ) {
                    const csv = getDataAsCsv({
                        suppressQuotes: true
                    });
                    if (!csv) return;

                    const rows = csv.split('\r\n');
                    const headers = rows[0].split(',');
                    const objects = rows.slice(1).map((row) => {
                        const obj: Record<string, string> = {};

                        row.split(',').forEach((column, index) => {
                            obj[headers[index]] = column;
                        });

                        return obj;
                    });

                    const schema = headers.map((header) => ({
                        column: header,
                        type: String,
                        value: (obj: Record<string, string>) => obj[header],
                        width:
                            Math.max(
                                header.length,
                                ...objects.map(
                                    (obj) => obj[header]?.length ?? 0
                                )
                            ) + 2
                    }));

                    await writeXlsxFile(objects, {
                        fileName,
                        schema,
                        table: true
                    });
                }
            }

            close();
        },
        [
            close,
            download,
            exportDataAsCsv,
            getDataAsCsv,
            i18n.language,
            range,
            type
        ]
    );

    return (
        <DropdownPanel>
            <Wizard className="w-104">
                <CSSTransition
                    id={step}
                    nodeRef={node}
                    key={step}
                    timeout={300}
                    classNames="wizard-step"
                >
                    {step === 'type' ? (
                        <ExportSelectionTypeStep
                            ref={node}
                            onSelect={handleTypeSelection}
                        />
                    ) : step === 'range' ? (
                        <ExportSelectionRangeStep
                            ref={node}
                            onSelect={handleRangeSelection}
                        />
                    ) : step === 'format' && type ? (
                        <ExportSelectionFormatStep
                            ref={node}
                            type={type}
                            onSelect={handleFormatSelection}
                        />
                    ) : null}
                </CSSTransition>
            </Wizard>
        </DropdownPanel>
    );
}

export default ExportWizard;
