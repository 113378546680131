import { CommonProps } from '@ncc-frontend/core';
import { PropsWithChildren } from 'react';
import classNames from 'classnames';

interface AppHeaderProps extends CommonProps {}

function AppHeader({
    children,
    className,
    ...restProps
}: PropsWithChildren<AppHeaderProps>) {
    const cssClasses = classNames(
        className,
        'z-10 fixed top-0 right-0',
        'h-escode-header px-escode-l py-escode-xxl',
        'flex items-center gap-escode-l',
        'bg-ncc-white shadow-header'
    );

    return (
        <div {...restProps} className={cssClasses}>
            {children}
        </div>
    );
}

export default AppHeader;
export type { AppHeaderProps };
