import { useAuth } from 'react-oidc-context';

export const useAuthActions = () => {
    const { signinRedirect, signinSilent, signoutRedirect } = useAuth();

    const handleSignInRedirect = () => {
        signinRedirect();
    };

    const handleSignInSilent = () => {
        signinSilent();
    };

    const handleSignoutRedirect = () => {
        signoutRedirect();
    };

    return { handleSignInRedirect, handleSignInSilent, handleSignoutRedirect };
};
