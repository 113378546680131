import {
    Button,
    ButtonProps,
    ConfirmationTooltip,
    ConfirmationTooltipProps,
    TooltipPanelProps
} from 'lib-ui';
import { ConfirmationTooltipEventHandler } from 'lib-ui/tooltip/confirmation-tooltip';
import { ContactViewModel } from 'core/api/client-portal/autogenerated/data-contracts';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import getContactStatus from 'core/api/client-portal/model/contact-status';
import useUserInvite from 'core/api/client-portal/hooks/user/use-user-invite';

interface ContactActionProps {
    data: ContactViewModel;
    variant?: ButtonProps['variant'];
}

function ContactAction({ data, variant: propVariant }: ContactActionProps) {
    const { t } = useTranslation();
    const status = getContactStatus(data);
    const { isLoading: isLoadingUserInvite, mutate: requestUserInvite } =
        useUserInvite();

    const invite = useCallback<ConfirmationTooltipEventHandler>(() => {
        if (!data.id) return;

        requestUserInvite(data.id);
    }, [data.id, requestUserInvite]);

    let variant: ButtonProps['variant'];
    let handleConfirm: ConfirmationTooltipEventHandler | undefined;
    let isButtonDisabled = false;
    let trigger: ConfirmationTooltipProps['trigger'] = 'click';
    let text: string | undefined;
    let tooltipMessage = '';

    switch (status) {
        case 'enabled':
            variant = 'default';
            trigger = 'hover';
            // handleConfirm = disable;
            handleConfirm = undefined;
            text = t('contacts.disable-user');
            // isButtonDisabled = !data.userId;
            isButtonDisabled = true;
            // tooltipMessage = t('contacts.disable-user-tooltip');
            tooltipMessage = t('tooltip.coming-soon');
            break;
        case 'disabled':
            variant = 'default';
            trigger = 'hover';
            // handleConfirm = enable;
            handleConfirm = undefined;
            text = t('contacts.enable-user');
            // isButtonDisabled = !data.userId;
            isButtonDisabled = true;
            // tooltipMessage = t('contacts.enable-user-tooltip');
            tooltipMessage = t('tooltip.coming-soon');
            break;
        case 'pending':
            variant = 'default';
            handleConfirm = invite;
            text = t('contacts.re-invite-user');
            isButtonDisabled = !data.id;
            tooltipMessage = t('contacts.re-invite-user-tooltip');
            break;
        case 'invite':
        default:
            variant = 'default';
            handleConfirm = invite;
            text = t('contacts.invite-user');
            isButtonDisabled = !data.id;
            tooltipMessage = t('contacts.invite-user-tooltip');
            break;
    }

    const panelProps = useMemo<TooltipPanelProps>(
        () => ({ className: 'flex flex-col items-center gap-2.5' }),
        []
    );

    console.log(propVariant, variant);

    return (
        <ConfirmationTooltip
            placement="right"
            trigger={trigger}
            message={tooltipMessage}
            onConfirm={handleConfirm}
            panelProps={panelProps}
        >
            <Button
                variant={propVariant ?? variant}
                disabled={isButtonDisabled}
                loading={isLoadingUserInvite}
            >
                {text}
            </Button>
        </ConfirmationTooltip>
    );
}

export default ContactAction;
export type { ContactActionProps };
