import { countries } from 'core/utils/countries';

const uniquePhoneNumbers = Array.from(new Set(Object.values(countries)));
const uniqueCountries = Array.from(new Set(Object.keys(countries)));

const phoneOptions = uniquePhoneNumbers
    .map((phoneNumber) => ({
        label: `+${phoneNumber}`,
        value: `+${phoneNumber}`
    }))
    .sort((a, b) => {
        const numA = parseInt(a.label.slice(1), 10);
        const numB = parseInt(b.label.slice(1), 10);

        return numA - numB;
    });

const provenceOptions = uniqueCountries
    .map((country) => ({
        label: country,
        value: country
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

const titleOptions = [
    { label: 'Mr', value: 'Mr' },
    { label: 'Mrs', value: 'Mrs' }
];

export {
    phoneOptions,
    provenceOptions,
    titleOptions,
    uniqueCountries,
    uniquePhoneNumbers
};
