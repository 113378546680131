import ContactDetailsStep from '../../ui/deposit-now/contact-details-step/contact-details-step';
import DepositNowContainer from '../../ui/deposit-now/deposit-now-container';

const DepositNowContactDetails = () => {
    return (
        <DepositNowContainer step="contact-details">
            <ContactDetailsStep />
        </DepositNowContainer>
    );
};

export default DepositNowContactDetails;
