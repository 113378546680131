import Agreements from 'ui/agreements/agreements';
import AppBody from './app-body';

function AgreementsBody() {
    return (
        <AppBody>
            <Agreements />
        </AppBody>
    );
}

export default AgreementsBody;
