import { FilterData } from 'lib-ui';
import { Path, get } from './path';
import { getDatePattern } from '@ncc-frontend/core';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

type DataValueGetterParams<TData = unknown> = { data: TData };
type DataValueGetter<TData = unknown> = (
    params: DataValueGetterParams<TData>
) => string;

interface DataConfig<TData = unknown> {
    data: TData[] | undefined | null;
    fields: {
        path?: Path<TData>;
        valueGetter?: DataValueGetter<TData>;
    }[];
    pageSize?: number;
}

function useData<TData = unknown>({
    data,
    fields,
    pageSize = 0
}: DataConfig<TData>) {
    const { i18n } = useTranslation();
    const [filters, setFilters] = useState<FilterData[]>([]);
    const [quickFilter, setQuickFilter] = useState('');
    const [page, setPage] = useState(1);

    const goToPage = useCallback((toPage: number) => {
        setPage(toPage);
    }, []);

    const nextPage = useCallback(() => {
        if (quickFilter) return;

        setPage((prev) => prev + 1);
    }, [quickFilter]);

    const previousPage = useCallback(() => {
        if (quickFilter) return;

        setPage((prev) => {
            const next = prev - 1;
            if (next < 1) return 1;
            return next;
        });
    }, [quickFilter]);

    const filteredData = useMemo(() => {
        if (!data) return [];

        let result = data;
        const filterBy = quickFilter.toLowerCase().split(' ').filter(Boolean);
        if (filterBy.length > 0 || filters.length > 0) {
            result = result.filter(
                (item) =>
                    filterBy.every((filter) =>
                        fields.some(({ path, valueGetter }) => {
                            let value: string | undefined;

                            if (valueGetter) {
                                value = valueGetter({ data: item });
                            } else if (path) {
                                value = get(item, path);
                            }

                            return (
                                value &&
                                value.toString().toLowerCase().includes(filter)
                            );
                        })
                    ) &&
                    filters.every(({ colId, model }) => {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        let value: string | undefined;

                        const valueGetter = fields.find(
                            (field) => field.path === colId
                        )?.valueGetter;
                        if (valueGetter) {
                            value = valueGetter({ data: item });
                        } else if (colId) {
                            value = get(item, colId);
                        }

                        if (model?.type === 'inList') {
                            return model.filter.includes(value);
                        } else if (model?.type === 'inRange') {
                            const dateFrom = moment(
                                model.dateFrom,
                                'YYYY-MM-DD'
                            );
                            const dateTo = moment(model.dateTo, 'YYYY-MM-DD');
                            dateTo.endOf('day');

                            // Get applied pattern for current lang.
                            const pattern = getDatePattern(i18n.language);
                            const cellDate = moment(value, pattern);

                            return (
                                dateFrom.isSameOrBefore(cellDate) &&
                                cellDate.isSameOrBefore(dateTo)
                            );
                        }

                        console.warn('Filter type not implemented');
                        return false;
                    })
            );
        }

        return result;
    }, [data, fields, filters, i18n.language, quickFilter]);

    const paginatedData = useMemo(() => {
        if (!pageSize) return [...filteredData];

        const startIndex = (page - 1) * pageSize;
        return [...filteredData].splice(startIndex, pageSize);
    }, [filteredData, page, pageSize]);

    return useMemo(
        () => ({
            filteredData,
            goToPage,
            nextPage,
            page,
            previousPage,
            processedData: paginatedData,
            quickFilter,
            setFilters,
            setQuickFilter,
            totalPages:
                (pageSize && Math.ceil((data?.length ?? 0) / pageSize)) || 1
        }),
        [
            data?.length,
            filteredData,
            goToPage,
            nextPage,
            page,
            pageSize,
            paginatedData,
            previousPage,
            quickFilter
        ]
    );
}

export default useData;
export type { DataConfig };
