import { Button, DataWidget, DataWidgetProps } from 'lib-ui';
import { FixedSourceProvider } from 'core/api/client-portal/hooks/providers/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    MouseEventHandler,
    useCallback,
    useEffect,
    useMemo,
    useRef
} from 'react';
import { SourceControlConnectionsGridContext } from './source-control-connections-grid-context';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

import { DataWidgetContextValue } from 'lib-ui/widget/data/data-widget-provider';
import { isUndefined } from 'lodash';
import AddSourceControlConnectionModal from '../add-source-control-connection-modal/add-source-control-connection-modal';
import ProviderCard from './provider-card/provider-card';
import useData, { DataConfig } from 'common/use-data';

interface SourceControlConnectionsGridProps {
    context: SourceControlConnectionsGridContext;
    data: FixedSourceProvider[] | undefined;
    error?: number;
    loading?: boolean;
    onReload?: MouseEventHandler<HTMLButtonElement>;
}

function SourceControlConnectionsGrid({
    context,
    data,
    error,
    loading,
    onReload
}: SourceControlConnectionsGridProps) {
    const dataWidget = useRef<DataWidgetContextValue | null>(null);
    const fields = useMemo<DataConfig<FixedSourceProvider>['fields']>(
        () => [
            {
                path: 'id'
            },
            {
                path: 'connectionName'
            },
            {
                path: 'repositoryAddress'
            },
            {
                valueGetter: ({ data: dataItem }) =>
                    context.beautifyProviderType(dataItem.providerType)
            }
        ],
        [context]
    );

    const {
        filteredData,
        goToPage,
        processedData,
        setQuickFilter,
        totalPages
    } = useData({
        data,
        fields,
        pageSize: 10
    });

    const setDataWidgetRef = useCallback(
        (ref: DataWidgetContextValue | null) => {
            if (ref === null) return;

            dataWidget.current = ref;

            const amountOfPages = Math.round(filteredData.length / 10) || 1;

            ref.setTotalPages(amountOfPages || totalPages);
        },
        [filteredData.length, totalPages]
    );

    const handleSearchChange = useCallback<
        NonNullable<DataWidgetProps['onQuickSearchChange']>
    >(
        (value) => {
            setQuickFilter(value);
        },
        [setQuickFilter]
    );

    const addConnection = useCallback(() => {
        context.push(AddSourceControlConnectionModal, {});
    }, [context]);

    const handlePageChanged = useCallback<DataWidgetProps['onPageChange']>(
        (toPage) => {
            goToPage(toPage);
        },
        [goToPage]
    );

    useEffect(() => {
        dataWidget.current?.setTotalPages(totalPages);
    }, [totalPages]);

    return (
        <DataWidget
            ref={setDataWidgetRef}
            className="h-full flex flex-col overflow-hidden"
            disablePager={filteredData?.length < 11}
            id="source-control-connection-grid"
            onPageChange={handlePageChanged}
            error={error}
            onReload={onReload}
            resetPageLocationOnSearch
            title={context.t('source-control-connections.title')}
            onQuickSearchChange={handleSearchChange}
            pre={
                <Button
                    onClick={addConnection}
                    icon={<FontAwesomeIcon icon={faPlus} />}
                >
                    {context.t('source-control-connections.add')}
                </Button>
            }
        >
            <div className="flex flex-wrap justify-center p-4 gap-4">
                {!loading &&
                (isUndefined(processedData) || processedData.length === 0)
                    ? context.t('source-control-connections.no-data')
                    : processedData?.map((item) => (
                          <ProviderCard
                              key={item.id}
                              data={item}
                              context={context}
                          />
                      ))}
            </div>
        </DataWidget>
    );
}

export default SourceControlConnectionsGrid;
export type { SourceControlConnectionsGridProps };
