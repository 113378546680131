import { AxiosResponse } from 'axios';
import { DepositReportFilter } from '../../autogenerated/data-contracts';
import { MutationOptions, useMutation } from 'react-query';
import { saveAsFile } from '@ncc-frontend/core';

import { ApiController } from '../../autogenerated/Api';
import { useAuth } from 'react-oidc-context';
import interceptors from '../../../interceptors';
import securityWorker from '../../../security-worker';

function useDepositHistoryReportDownload<TError = unknown, TContext = unknown>(
    options?: Omit<
        MutationOptions<
            AxiosResponse,
            ReactQueryError<TError>,
            DepositReportFilter,
            TContext
        >,
        'mutationFn'
    >
) {
    const auth = useAuth();

    return useMutation<
        AxiosResponse,
        ReactQueryError<TError>,
        DepositReportFilter,
        TContext
    >(
        [{ token: auth.user?.access_token, tokenType: auth.user?.token_type }],
        async (data) => {
            if (process.env.REACT_APP_API_MOCK) {
                console.warn(
                    'Mock is enabled useDepositHistoryReportDownload call ignored.',
                    data
                );
                return Promise.resolve({} as AxiosResponse);
            }

            const controller = new ApiController({
                format: 'blob',
                interceptors,
                securityWorker: securityWorker(
                    auth.user?.token_type,
                    auth.user?.access_token
                )
            });

            return new Promise((resolve, reject) => {
                controller
                    .v1DepositsGetDepositHistoryReportCreate(data, {
                        format: 'blob'
                    })
                    // BACKEND: swagger doc
                    .then((response: AxiosResponse) => {
                        const now = new Date();
                        saveAsFile(
                            `NCC Group Escrow Deposit Report - ${now.toLocaleString(
                                'en-GB',
                                {
                                    month: 'short'
                                }
                            )} ${now.getFullYear()}.xlsx`,
                            response.data,
                            {
                                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                            }
                        );
                        resolve(response.data);
                    });
            });
        },
        options
    );
}

export default useDepositHistoryReportDownload;
