import { ApiController } from '../../autogenerated/Api';
import { AxiosResponse } from 'axios';
import { MutationOptions, useMutation } from 'react-query';
import { useAuth } from 'react-oidc-context';
import interceptors from 'core/api/interceptors';
import securityWorker from 'core/api/security-worker';

type useDepositDeleteParams = {
    depositId: number;
};

function useDepositDelete<TError = unknown, TContext = unknown>(
    options?: Omit<
        MutationOptions<
            AxiosResponse<void>,
            ReactQueryError<TError>,
            useDepositDeleteParams,
            TContext
        >,
        'mutationFn'
    >
) {
    const auth = useAuth();

    return useMutation(
        [{ token: auth.user?.access_token, tokenType: auth.user?.token_type }],
        async ({ depositId }: useDepositDeleteParams) => {
            if (process.env.REACT_APP_API_MOCK) {
                console.warn(
                    'Mock is enabled useSchedulesDelete call ignored.',
                    depositId
                );
                return Promise.resolve({} as AxiosResponse<void>);
            }

            const controller = new ApiController({
                interceptors,
                securityWorker: securityWorker(
                    auth.user?.token_type,
                    auth.user?.access_token
                )
            });

            return controller.v2DepositDelete(depositId);
        },
        {
            ...options
        }
    );
}

export default useDepositDelete;
export type { useDepositDeleteParams };
