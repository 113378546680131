import { ReactNode, useEffect, useRef } from 'react';
import { useAuth } from 'react-oidc-context';
import { useLocation, useNavigate } from 'react-router-dom';
import useDepositNowStore from 'core/stores/use-deposit-now-store';

export function TimeoutProvider({ children }: { children: ReactNode }) {
    const navigate = useNavigate();
    const location = useLocation();
    const { signoutRedirect } = useAuth();
    const { isUploadingFile } = useDepositNowStore();
    const timeoutRef = useRef<NodeJS.Timeout>();

    useEffect(() => {
        const handleWindowEvents = () => {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = setTimeout(() => {
                if (
                    localStorage.getItem('isAnyFileBeingUploaded') === 'true' &&
                    isUploadingFile
                ) {
                    return;
                }
                signoutRedirect();
            }, 2 * 60 * 60 * 1000); // 2 hours in milliseconds
        };

        // listen for specific window events to ensure user is still active
        window.addEventListener('mousemove', handleWindowEvents);
        window.addEventListener('keydown', handleWindowEvents);
        window.addEventListener('click', handleWindowEvents);
        window.addEventListener('scroll', handleWindowEvents);

        handleWindowEvents();

        // cleanup function
        return () => {
            window.removeEventListener('mousemove', handleWindowEvents);
            window.removeEventListener('keydown', handleWindowEvents);
            window.removeEventListener('click', handleWindowEvents);
            window.removeEventListener('scroll', handleWindowEvents);
        };
    }, [navigate, location.pathname, signoutRedirect, isUploadingFile]);

    return children as JSX.Element;
}
