import { Button, ConfirmationTooltip, SkeletonLine } from 'lib-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ForwardedRef, forwardRef, useCallback } from 'react';
import {
    SourceCodeAuthenticationType,
    SourceCodeProviderType
} from 'core/api/client-portal/autogenerated/data-contracts';
import { faDiamondExclamation } from '@fortawesome/pro-solid-svg-icons';
import { isNull, isUndefined } from 'lodash';
import { useModals } from '@ncc-frontend/core';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import EditSourceControlConnectionModal from 'ui/source-control-connections/edit-source-control-connection-modal/edit-source-control-connection-modal';
import useBeautify from 'core/beautifiers/use-beautify';
import useProvidersDelete from 'core/api/client-portal/hooks/providers/use-providers-delete';

interface ProviderListItemProps {
    authenticationType?: SourceCodeAuthenticationType | null;
    connectionName?: string | null;
    id?: number | null;
    loading?: boolean;
    password?: string | null;
    privateKey?: string | null;
    providerType?: SourceCodeProviderType | null;
    repositoryAddress?: string | null;
    userName?: string | null;
}

function ProviderListItem(
    {
        authenticationType,
        connectionName,
        id,
        loading,
        password,
        privateKey,
        providerType,
        repositoryAddress,
        userName
    }: ProviderListItemProps,
    ref: ForwardedRef<HTMLDivElement>
) {
    const { t } = useTranslation();
    const { beautifyProviderType } = useBeautify();
    const { push } = useModals();

    const { isLoading: isDeletingProvider, mutate: deleteProvider } =
        useProvidersDelete();

    const isRemoveDisabled =
        isDeletingProvider || isUndefined(id) || isNull(id);
    const dtCssClasses = 'text-general-grey-grey-90 font-medium';
    const ddCssClasses = 'text-general-grey-grey-90 text-sm truncate';

    const edit = useCallback(() => {
        push(EditSourceControlConnectionModal, {
            authenticationType: authenticationType?.toString(),
            connectionName,
            password,
            privateKey,
            providerId: id ?? undefined,
            providerType: providerType?.toString(),
            repositoryAddress,
            userName
        });
    }, [
        authenticationType,
        connectionName,
        id,
        password,
        privateKey,
        providerType,
        push,
        repositoryAddress,
        userName
    ]);

    const remove = useCallback(() => {
        if (isRemoveDisabled) return;
        deleteProvider({ query: { providerId: id } });
    }, [deleteProvider, id, isRemoveDisabled]);

    return (
        <div
            ref={ref}
            className="flex gap-1.5 justify-between items-center py-2.5 border-b border-brand-escode-neonblue-neonblue-10"
        >
            <div className="flex gap-12 py-3.5">
                <div
                    className={classNames(
                        'relative row-span-2 text-brand-escode-neonblue-neonblue-100 text-xl font-medium'
                    )}
                >
                    {!!loading && <SkeletonLine absolute />}
                    {t('provider-card.title', { id })}
                </div>

                <dl className="relative grid grid-rows-2 grid-flow-col gap-x-7 gap-y-2">
                    {!!loading && <SkeletonLine absolute />}

                    <dt className={dtCssClasses}>
                        {t('provider-card.connection-name')}
                    </dt>
                    <dd className={ddCssClasses}>{connectionName}</dd>

                    <dt className={dtCssClasses}>
                        {t('provider-card.provider-type')}
                    </dt>
                    <dd className={ddCssClasses}>
                        {beautifyProviderType(providerType)}
                    </dd>

                    <dt className={dtCssClasses}>
                        {t('provider-card.repository-address')}
                    </dt>
                    <dd className={ddCssClasses}>{repositoryAddress}</dd>
                </dl>
            </div>
            <div className="flex flex-col gap-1">
                <Button
                    variant="tertiary"
                    onClick={edit}
                    size="s"
                    className="relative"
                >
                    {!!loading && (
                        <SkeletonLine className="absolute -inset-px bg-ncc-white" />
                    )}
                    {t('provider-card.details')}
                </Button>
                <ConfirmationTooltip
                    placement="left"
                    icon={<FontAwesomeIcon icon={faDiamondExclamation} />}
                    title={t('provider-card.delete-tooltip-title')}
                    message={t('provider-card.delete-tooltip-message')}
                    onConfirm={remove}
                    variant="warning"
                    confirmMessage={t('tooltip.proceed')}
                    rejectMessage={t('tooltip.reject')}
                    disabled={isRemoveDisabled}
                    className="relative"
                >
                    {!!loading && <SkeletonLine absolute />}
                    <Button
                        variant="danger"
                        size="s"
                        disabled={isRemoveDisabled}
                        className="w-full"
                    >
                        {t('provider-card.delete')}
                    </Button>
                </ConfirmationTooltip>
            </div>
        </div>
    );
}

export default forwardRef(ProviderListItem);
export type { ProviderListItemProps };
