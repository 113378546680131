import { UseQueryOptions, useQuery } from 'react-query';
import { isUndefined } from 'lodash';

import { ApiController } from '../../autogenerated/Api';
import { DataCentreResponse } from '../../autogenerated/data-contracts';
import { useAuth } from 'react-oidc-context';
import interceptors from '../../../interceptors';
import securityWorker from '../../../security-worker';
import useAgreementsGetDataCentreMockData from './use-agreements-get-data-centre-mock-data';

function useAgreementsGetDataCentre<TError = unknown>(
    agreementId: string | undefined,
    options?: Omit<
        UseQueryOptions<
            DataCentreResponse | undefined,
            ReactQueryError<TError>
        >,
        'queryKey' | 'queryFn'
    >
) {
    const auth = useAuth();

    return useQuery<DataCentreResponse | undefined, ReactQueryError<TError>>(
        [
            'agreements',
            'getDataCentre',
            {
                query: { agreementId },
                token: auth.user?.access_token,
                tokenType: auth.user?.token_type
            }
        ],
        async () => {
            if (isUndefined(agreementId)) {
                console.warn('Undefined ID in useAgreementsGetDataCentre');
                return undefined;
            }
            if (process.env.REACT_APP_API_MOCK) {
                console.warn('Using mock data for useAgreementsGetDataCentre');
                return useAgreementsGetDataCentreMockData;
            }

            const controller = new ApiController({
                interceptors,
                securityWorker: securityWorker(
                    auth.user?.token_type,
                    auth.user?.access_token
                )
            });

            const response = await controller.getAgreementDataCentres(
                Number(agreementId),
                {
                    Method: 'Upload'
                }
            );
            return response.data;
        },
        options
    );
}

export default useAgreementsGetDataCentre;
