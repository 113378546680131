import { Button, ConfirmationTooltip } from 'lib-ui';
import { ConfirmationTooltipEventHandler } from 'lib-ui/tooltip/confirmation-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MouseEventHandler, useCallback } from 'react';
import { faDiamondExclamation } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';

type SavedFilterEventHandler = (name: string) => void;

interface SavedFilterProps {
    active?: boolean;
    name: string;
    onDelete: SavedFilterEventHandler;
    onSelect: SavedFilterEventHandler;
    onViewDetails: SavedFilterEventHandler;
    warning?: boolean;
}

function SavedFilter({
    active,
    name,
    onDelete,
    onSelect,
    onViewDetails,
    warning
}: SavedFilterProps) {
    const { t } = useTranslation();

    const handleSelect = useCallback<ConfirmationTooltipEventHandler>(() => {
        onSelect(name);
    }, [name, onSelect]);

    const handleDelete = useCallback<MouseEventHandler<HTMLButtonElement>>(
        (event) => {
            event.stopPropagation();
            onDelete(name);
        },
        [name, onDelete]
    );

    const handleViewDetails = useCallback<MouseEventHandler<HTMLButtonElement>>(
        (event) => {
            event.stopPropagation();
            onViewDetails(name);
        },
        [name, onViewDetails]
    );

    return (
        <div className="flex flex-col gap-2.5 p-2.5 shadow-filter rounded-lg">
            <div>{name}</div>
            <div className="flex gap-2.5">
                <ConfirmationTooltip
                    icon={<FontAwesomeIcon icon={faDiamondExclamation} />}
                    title={t('filters.column-disable-tooltip-title')}
                    message={t('filters.column-disable-tooltip-message')}
                    confirmMessage={t('tooltip.proceed')}
                    variant="warning"
                    suppressConfirmation={!warning}
                    onConfirm={handleSelect}
                    placement="left"
                >
                    <Button
                        variant={
                            warning && !active
                                ? 'warning'
                                : active
                                ? 'primary'
                                : 'secondary'
                        }
                    >
                        {t('filters.select-filter')}
                    </Button>
                </ConfirmationTooltip>
                <Button onClick={handleViewDetails} variant="secondary">
                    {t('filters.filter-details')}
                </Button>
                <Button variant="danger" onClick={handleDelete}>
                    {t('filters.delete')}
                </Button>
            </div>
        </div>
    );
}

export default SavedFilter;
export type { SavedFilterEventHandler, SavedFilterProps };
