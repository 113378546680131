import AppBody from './app-body';
import WorkingOn from 'ui/working-on/working-on';

function WorkingOnBody() {
    return (
        <AppBody suppressBanner>
            <WorkingOn />
        </AppBody>
    );
}

export default WorkingOnBody;
