import { CalendarData } from '../types';
import CalendarWeekEvent from './calendar-week-event';
import cn from 'core/utils/cn';
interface CalendarWeekColumnProps {
    currentDay: boolean;
    data: CalendarData[] | undefined;
    isWeekend: boolean;
}

function CalendarWeekColumn({
    currentDay,
    data,
    isWeekend
}: CalendarWeekColumnProps) {
    return (
        <td
            role="gridcell"
            className={cn(
                'relative outline outline-1 outline-general-grey-grey-30 border border-general-grey-grey-30',
                currentDay &&
                    'bg-general-lightpurple-lightpurple-60/20 outline-general-grey-grey-30 border-general-grey-grey-30',
                isWeekend && 'bg-general-grey-grey-20/50'
            )}
        >
            {data?.map(
                ({ date, softwarePackageName, tailwindStyle, title }) => (
                    <CalendarWeekEvent
                        key={date.toISOString()}
                        date={date}
                        tailwindStyle={tailwindStyle}
                        title={`${title} - ${softwarePackageName}`}
                    >
                        {title} - {softwarePackageName}
                    </CalendarWeekEvent>
                )
            )}
        </td>
    );
}

export default CalendarWeekColumn;
export type { CalendarWeekColumnProps };
