import { InputField, Section, SelectField, StepSection } from 'lib-ui';
import { provenceOptions } from 'ui/deposit-now/contact-details-step/contact-details-options';
import { useTranslation } from 'react-i18next';
import React from 'react';

const ContactDetailsSection = () => {
    const { t } = useTranslation();
    return (
        <StepSection stepTitle={t('deposit-now-stage-two.title')}>
            <Section
                className="border-none p-0 bg-general-grey-grey-10"
                wrapperClasses="flex-row gap-4"
            >
                <Section
                    heading={t('deposit-now-stage-two.personal-details')}
                    className="flex flex-col w-1/2 rounded-lg gap-3"
                >
                    <InputField
                        label={t('deposit-now-stage-two.contact-name')}
                        name="contactDetails.contactName"
                        placeholder={t('deposit-now-stage-two.contact-name')}
                        className="w-full"
                        required
                    />

                    <InputField
                        description={t(
                            'deposit-now-stage-two.company-name-detail'
                        )}
                        disabled
                        label={t('deposit-now-stage-two.company-name-title')}
                        name="contactDetails.companyName"
                        placeholder={t('deposit-now-stage-two.company-name')}
                        required
                        tailwindStyle={{
                            width: 'w-full'
                        }}
                    />

                    <InputField
                        label={t('deposit-now-stage-two.email')}
                        name="contactDetails.emailAddress"
                        type="email"
                        placeholder={t(
                            'deposit-now-stage-two.email-placeholder'
                        )}
                        tailwindStyle={{
                            width: 'w-full'
                        }}
                        tooltip={t('deposit-now-stage-two.confirm')}
                        required
                    />
                    <InputField
                        label={t('deposit-now-stage-two.phone')}
                        name="contactDetails.telephoneNumber"
                        placeholder={t(
                            'deposit-now-stage-two.phone-placeholder'
                        )}
                        className="w-full"
                        required
                    />
                    <InputField
                        label={t('deposit-now-stage-two.job')}
                        name="contactDetails.jobTitle"
                        placeholder={t('deposit-now-stage-two.job-placeholder')}
                        tailwindStyle={{
                            width: 'w-full'
                        }}
                        required
                    />
                </Section>

                <Section
                    heading={t('deposit-now-stage-two.address-details')}
                    className="flex flex-col w-1/2 rounded-lg gap-3"
                >
                    <InputField
                        label={t('deposit-now-stage-two.address-line-one')}
                        name="contactDetails.addressLine1"
                        placeholder={t('deposit-now-stage-two.job-placeholder')}
                        tailwindStyle={{
                            width: 'w-full'
                        }}
                        required
                    />

                    <InputField
                        label={t('deposit-now-stage-two.address-line-two')}
                        name="contactDetails.addressLine2"
                        placeholder={t('deposit-now-stage-two.job-placeholder')}
                        tailwindStyle={{
                            width: 'w-full'
                        }}
                    />
                    <InputField
                        label={t('deposit-now-stage-two.address-line-three')}
                        name="contactDetails.addressLine3"
                        placeholder={t('deposit-now-stage-two.job-placeholder')}
                        tailwindStyle={{
                            width: 'w-full'
                        }}
                    />
                    <InputField
                        label={t('deposit-now-stage-two.city')}
                        name="contactDetails.city"
                        placeholder={t('deposit-now-stage-two.job-placeholder')}
                        tailwindStyle={{
                            width: 'w-full'
                        }}
                        required
                    />

                    <div className="flex gap-2 justify-around">
                        <InputField
                            label={t('deposit-now-stage-two.county')}
                            name="contactDetails.county"
                            placeholder={t(
                                'deposit-now-stage-two.job-placeholder'
                            )}
                            tailwindStyle={{
                                width: 'w-full'
                            }}
                        />
                        <InputField
                            label={t('deposit-now-stage-two.post-code')}
                            name="contactDetails.postCode"
                            placeholder={t(
                                'deposit-now-stage-two.job-placeholder'
                            )}
                            tailwindStyle={{
                                width: 'w-full'
                            }}
                            required
                        />
                    </div>

                    <SelectField
                        label={t('deposit-now-stage-two.country')}
                        name="contactDetails.country"
                        options={provenceOptions}
                        tailwindStyle={{
                            width: 'w-full'
                        }}
                        required
                    />
                </Section>
            </Section>
        </StepSection>
    );
};

export default ContactDetailsSection;
