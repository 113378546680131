import { AgreementPartyViewModel } from 'core/api/client-portal/autogenerated/data-contracts';
import { Modal } from 'lib-ui';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

interface LicenseesDetailsModalProps {
    data: AgreementPartyViewModel[];
}

function LicenseesDetailsModal({
    data
}: LicenseesDetailsModalProps): ReactElement | null {
    const { t } = useTranslation();

    return (
        <Modal className="min-w-4xl">
            <Modal.Header>{t('agreements.licensees-modal.title')}</Modal.Header>
            <Modal.Body className="text-sm space-y-4">
                {data.map((licensee, index) => (
                    <div key={index}>{licensee.registeredName}</div>
                ))}
            </Modal.Body>
            <Modal.Footer children />
        </Modal>
    );
}

export default LicenseesDetailsModal;
export type { LicenseesDetailsModalProps };
