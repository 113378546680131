import { Stepper, StepperProps } from 'lib-ui';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useMemo, useState } from 'react';

type DepositNowStepOptions =
    | 'agreement'
    | 'contact-details'
    | 'deposit-details'
    | 'content'
    | 'review-deposit'
    | 'disclaimer'
    | 'upload';

interface DepositNowStepperProps {
    step: DepositNowStepOptions;
}

const DepositStepper = ({ step }: DepositNowStepperProps) => {
    const { t } = useTranslation();
    const [activeStep, setActiveStep] = useState<DepositNowStepOptions>(step);

    const steps = useMemo<StepperProps<DepositNowStepOptions>['steps']>(
        () => [
            {
                node: t('deposit-now.agreement'),
                step: 'agreement'
            },
            {
                node: t('deposit-now.contact-details'),
                step: 'contact-details'
            },
            {
                node: t('deposit-now.deposit-details'),
                step: 'deposit-details'
            },
            {
                node: t('deposit-now.content'),
                step: 'content'
            },
            {
                node: t('deposit-now.review-deposit'),
                step: 'review-deposit'
            },
            {
                node: t('deposit-now.disclaimer'),
                step: 'disclaimer'
            },
            {
                node: t('deposit-now.upload'),
                step: 'upload'
            }
        ],
        [t]
    );

    const handleChangeStep = useCallback<
        NonNullable<StepperProps<DepositNowStepOptions>['onChange']>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    >((step: any) => {
        setActiveStep(step);
    }, []);

    return (
        <Stepper<DepositNowStepOptions>
            steps={steps}
            active={activeStep}
            onChange={handleChangeStep}
            className="ml-auto"
            disabled
            userControlled={false}
        />
    );
};

export default DepositStepper;
