import { ChangeEvent, useCallback, useState } from 'react';
import { ConfirmationTooltip, Select } from 'lib-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faDiamondExclamation,
    faSpinner
} from '@fortawesome/pro-solid-svg-icons';
import { isNull, isUndefined } from 'lodash';
import { useAuth } from 'react-oidc-context';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import Button from 'lib-ui/button/button';
import useAgreementsDepositsDataCentreUpdate from 'core/api/client-portal/hooks/agreements/use-agreements-deposits-data-centre-update';
import useAgreementsGetDataCentre from 'core/api/client-portal/hooks/agreements/use-agreements-get-data-centre';
import useGetDepositDetails from 'core/api/client-portal-v2/hooks/deposits/use-get-deposit-details';

type DataCentreProps = {
    agreementId: string;
    depositId: number;
};

const DataCentre = ({ agreementId, depositId }: DataCentreProps) => {
    const auth = useAuth();
    const queryClient = useQueryClient();

    const { t } = useTranslation();

    const [selectDisabled, setSelectDisabled] = useState<boolean | undefined>(
        undefined
    );

    // Are there any data centres previously selected?
    const { data: depositData, isLoading: isDepositsLoading } =
        useGetDepositDetails(depositId);

    // get all Data Centres
    const { data: dataCentres, isLoading: isDataCentreLoading } =
        useAgreementsGetDataCentre(agreementId);
    // function to pick one Data Centre
    const { mutate: mutationFn } = useAgreementsDepositsDataCentreUpdate({
        onSettled() {
            setSelectDisabled(true);

            queryClient.invalidateQueries([
                'agreements',
                'getDepositDetails',
                {
                    token: auth.user?.access_token,
                    tokenType: auth.user?.token_type
                }
            ]);
        }
    });

    const [value, setValue] = useState<string | undefined>(undefined);

    const placeholder = depositData?.dataCentre
        ? depositData.dataCentre.split(':')[0]
        : t('data-centre.select-data-centre');
    const isDataCentreReady =
        isNull(depositData?.dataCentre) || isUndefined(depositData?.dataCentre);

    const handleOnChange = useCallback(
        (event: ChangeEvent<{ value: string }>): void => {
            const inputValue = event.target.value;
            setValue(inputValue);
        },
        []
    );

    const handleOnClick = useCallback((): void => {
        if (!value) return;

        mutationFn({
            data: {
                agreementId: agreementId.toString(),
                depositDataCentre: {
                    isOnPrem: false,
                    platform: value,
                    region: value
                },
                depositId
            }
        });
    }, [agreementId, depositId, mutationFn, value]);

    const optionsArray =
        dataCentres?.items?.map((item) => ({
            label: item.platform || '',
            value: item.status || ''
        })) || [];

    if (isDataCentreLoading || isDepositsLoading) {
        return (
            <div className="flex justify-center items-center">
                <FontAwesomeIcon
                    className="h-4 w-4 mx-auto"
                    icon={faSpinner}
                    spin
                />
            </div>
        );
    }

    return (
        <div className="flex w-72">
            <Select
                placeholder={value ?? placeholder}
                disabled={selectDisabled || !isDataCentreLoading}
                options={optionsArray}
                onChange={handleOnChange}
            ></Select>
            {isDataCentreReady && (
                <ConfirmationTooltip
                    placement="bottom"
                    icon={<FontAwesomeIcon icon={faDiamondExclamation} />}
                    title={t('data-centre.confirmation')}
                    message={t('data-centre.confirm-tooltip-message')}
                    onConfirm={handleOnClick}
                    variant="warning"
                    confirmMessage={t('tooltip.proceed')}
                    rejectMessage={t('tooltip.reject')}
                    disabled={false}
                    className="relative"
                >
                    <Button
                        variant="tertiary"
                        type="button"
                        size="s"
                        disabled={false}
                        className="w-full ml-2"
                    >
                        {t('data-centre.confirm')}
                    </Button>
                </ConfirmationTooltip>
            )}
        </div>
    );
};

export default DataCentre;
