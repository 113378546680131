interface DividerProps {
    /** Css class for background color. Defaults to "bg-escode-white/20" */
    color?: string;
}

function Divider({ color = 'bg-escode-white/20' }: DividerProps) {
    return <div className={`w-full ${color}`} style={{ height: 1 }} />;
}

export default Divider;
