import { GetContactAuditResponseViewModel } from '../../autogenerated/data-contracts';
import { UseQueryOptions, useQuery } from 'react-query';

import { ApiController } from '../../autogenerated/Api';
import { useAuth } from 'react-oidc-context';
import interceptors from '../../../interceptors';
import securityWorker from '../../../security-worker';

import useContactsAuditMockData from './use-contacts-audit-mock-data';

function useContactsAudit<TError = unknown>(
    options?: Omit<
        UseQueryOptions<
            GetContactAuditResponseViewModel,
            ReactQueryError<TError>,
            GetContactAuditResponseViewModel
        >,
        'queryKey' | 'queryFn'
    >
) {
    const auth = useAuth();

    return useQuery<
        GetContactAuditResponseViewModel,
        ReactQueryError<TError>,
        GetContactAuditResponseViewModel
    >(
        [
            'contacts',
            'audit-history',
            { token: auth.user?.access_token, tokenType: auth.user?.token_type }
        ],
        async () => {
            if (process.env.REACT_APP_API_MOCK) {
                console.warn('Using mock data for useContactAudit');
                return await new Promise<GetContactAuditResponseViewModel>(
                    (resolve) =>
                        setTimeout(
                            () => resolve(useContactsAuditMockData),
                            2000
                        )
                );
            }

            const controller = new ApiController({
                interceptors,
                securityWorker: securityWorker(
                    auth.user?.token_type,
                    auth.user?.access_token
                )
            });

            const response = await controller.getAuditForContact();
            return response.data;
        },
        options
    );
}

export default useContactsAudit;
