import { TailwindProps, tailwindClasses } from '@ncc-frontend/core';
import { useCallback } from 'react';
import classNames from 'classnames';

import useAnimatedNumber from 'common/use-animated-number';
import useDebounce from 'common/use-debounce';

import './progress-ring.scss';

interface ProgressRingProps extends TailwindProps {
    loading?: boolean;
    /** [0, 1] */
    value: number;
}

function ProgressRing({
    className,
    loading,
    tailwindStyle,
    value,
    ...restProps
}: ProgressRingProps) {
    const { ref: valueElementRef, updateValue } =
        useAnimatedNumber<HTMLDivElement>({
            formatter: (val) => `${val}%`
        });

    const debounce = useDebounce(250);

    const cssClasses = classNames(
        tailwindClasses(
            {
                display: 'flex',
                justify: 'justify-center',
                position: 'relative'
            },
            tailwindStyle
        ),
        className
    );

    const size = 300;
    const width = 30;
    const radius = size / 2 - width / 2;
    const circumference = Math.ceil(2 * Math.PI * radius);

    const setCircleRef = useCallback(
        (ref: SVGCircleElement | null) => {
            if (ref === null) return;

            debounce(() => {
                ref.style.strokeDashoffset = `${-circumference * (1 - value)}`;
                updateValue(Math.floor(value * 100));
            });
        },
        [circumference, debounce, updateValue, value]
    );

    return (
        <div {...restProps} className={cssClasses}>
            <svg
                viewBox={`0 0 ${size} ${size}`}
                className="max-h-full max-w-full -rotate-90"
            >
                <circle
                    cx={size / 2}
                    cy={size / 2}
                    r={radius}
                    strokeWidth={width - 2}
                    className={classNames({
                        'animate-pulse fill-escode-grey-30 stroke-escode-grey-30':
                            !!loading,
                        'fill-transparent stroke-brand-escode-neonblue-neonblue-100':
                            !loading
                    })}
                    fill={!loading ? 'transparent' : ''}
                />
                {!loading && (
                    <circle
                        cx={size / 2}
                        ref={setCircleRef}
                        cy={size / 2}
                        r={radius}
                        stroke="#0B1932"
                        strokeWidth={width}
                        strokeDasharray={circumference}
                        strokeDashoffset={-circumference}
                        strokeLinecap="round"
                        fill="transparent"
                        className="progress-circle__fill"
                    />
                )}
            </svg>
            {!loading && (
                <div
                    ref={valueElementRef}
                    className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-4xl text-general-grey-grey-90 font-medium"
                />
            )}
        </div>
    );
}

export default ProgressRing;
export type { ProgressRingProps };
