import { DownloadScheduleItem } from 'core/api/client-portal/autogenerated/data-contracts';
import { ICellRendererParams } from 'lib-ui';

import { Lang } from 'localization';
import useBeautify from 'core/beautifiers/use-beautify';

function AgreementDetailsDateCellRenderer({
    value
}: ICellRendererParams<DownloadScheduleItem>) {
    const { beautifyDateLocalised } = useBeautify();

    const localisation = localStorage!.getItem('i18nextLng') || 'en-GB';

    return (
        <div className="flex h-full items-center">
            {beautifyDateLocalised(
                value as unknown as Date,
                localisation as Lang
            )}
        </div>
    );
}

export default AgreementDetailsDateCellRenderer;
