import { ClientContactsResponseViewModel } from '../../autogenerated/data-contracts';
import { UseQueryOptions, useQuery } from 'react-query';

import { ApiController } from '../../autogenerated/Api';
import { useAuth } from 'react-oidc-context';
import interceptors from '../../../interceptors';
import securityWorker from '../../../security-worker';

import useContactsListMockData from './use-contacts-list-mock-data';

function useContactsList<TError = unknown>(
    options?: Omit<
        UseQueryOptions<
            ClientContactsResponseViewModel,
            ReactQueryError<TError>,
            ClientContactsResponseViewModel
        >,
        'queryKey' | 'queryFn'
    >
) {
    const auth = useAuth();

    return useQuery<
        ClientContactsResponseViewModel,
        ReactQueryError<TError>,
        ClientContactsResponseViewModel
    >(
        [
            'contacts',
            'list',
            { token: auth.user?.access_token, tokenType: auth.user?.token_type }
        ],
        async () => {
            if (process.env.REACT_APP_API_MOCK) {
                console.warn('Using mock data for useContactsList');
                return await new Promise<ClientContactsResponseViewModel>(
                    (resolve) =>
                        setTimeout(() => resolve(useContactsListMockData), 2000)
                );
            }

            const controller = new ApiController({
                interceptors,
                securityWorker: securityWorker(
                    auth.user?.token_type,
                    auth.user?.access_token
                )
            });

            const response = await controller.getClientContacts();
            return response.data;
        },
        options
    );
}

export default useContactsList;
