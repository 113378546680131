import { AgreementPartiesTableContext } from './agreement-parties-table-context';
import { AgreementPartyViewModel } from 'core/api/client-portal/autogenerated/data-contracts';
import { ICellRendererParams, Tag } from 'lib-ui';

// This list is not exhaustive - there are 13 possible values:
type AgreementPartyType =
    | 'Outsourcer'
    | 'New Pending-Novation Party'
    | 'MLtoSL'
    | 'Registry Operator'
    | 'Registry Service Provider'
    | 'ICANN'
    | 'Registered Licensee'
    | 'Competitor'
    | 'Partner'
    | 'Owner'
    | 'Licensee'
    | 'Distributor'
    | 'Depositor';

function AgreementPartyTypeCellRenderer({
    value
}: ICellRendererParams<AgreementPartyViewModel, AgreementPartiesTableContext>) {
    let tailwindStyle = {};
    switch (value as unknown as AgreementPartyType) {
        case 'Owner':
            tailwindStyle = {
                background: 'bg-general-red-red-10',
                textColor: 'text-general-red-red-100'
            };
            break;
        case 'Outsourcer':
        case 'New Pending-Novation Party':
        case 'MLtoSL':
        case 'Registry Operator':
        case 'Registry Service Provider':
        case 'ICANN':
        case 'Registered Licensee':
        case 'Competitor':
        case 'Partner':
        case 'Licensee':
        case 'Distributor':
        case 'Depositor':
        default:
            tailwindStyle = {
                background: 'bg-general-lightpurple-lightpurple-20',
                textColor: 'text-general-lightpurple-lightpurple-100'
            };
            break;
    }

    return <Tag tailwindStyle={tailwindStyle}>{value}</Tag>;
}

export default AgreementPartyTypeCellRenderer;
