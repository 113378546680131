import { RadioGroupField, SelectField } from 'lib-ui';
import {
    dayOptions,
    daysOfMonth,
    monthOptions,
    weekOptions
} from './provider-scd-utils';
import { useTranslation } from 'react-i18next';

export const FrequencySection = ({
    allowedHours,
    allowedMinutes,
    showAnnually,
    showMonth,
    showQuarterly,
    showWeekly
}: {
    allowedHours: { label: string; value: string }[];
    allowedMinutes: { label: string; value: string }[];
    showAnnually: boolean;
    showMonth: boolean;
    showQuarterly: boolean;
    showWeekly: boolean;
}) => {
    const { t } = useTranslation();

    return (
        <div className="flex flex-1 flex-col gap-4 rounded-lg border border-general-grey-grey-40 p-3">
            <p className="text-md text-center font-medium">
                {t('automate-future-deposits.date-time')}
            </p>

            {showWeekly && (
                <RadioGroupField
                    name="week"
                    options={weekOptions}
                    required
                    orientation="vertical"
                    className="h-auto overflow-y-auto"
                />
            )}
            {(showAnnually || showQuarterly) && (
                <>
                    <SelectField
                        name="day"
                        placeholder={t('automate-future-deposits.day.title')}
                        options={dayOptions}
                    />
                    <SelectField
                        name="monthOptions"
                        placeholder={t('automate-future-deposits.month.title')}
                        options={monthOptions}
                        className="h-auto"
                    />
                </>
            )}

            {showMonth && (
                <RadioGroupField
                    name="month"
                    options={daysOfMonth}
                    required
                    orientation="vertical"
                    className="h-40 overflow-y-auto"
                />
            )}

            <div className="flex gap-2">
                <div className="w-full">
                    <SelectField
                        name="hour"
                        placeholder={t('automate-future-deposits.hour')}
                        className="w-full"
                        options={allowedHours}
                    />
                </div>
                <div className="w-full">
                    <SelectField
                        name="minutes"
                        placeholder={t('automate-future-deposits.minutes')}
                        className="w-full"
                        options={allowedMinutes}
                    />
                </div>
            </div>
        </div>
    );
};
