import { AgreementListResponseViewModel } from '../../autogenerated/data-contracts';

const useAgreementsMockData: AgreementListResponseViewModel = {
    agreements: [
        {
            agreementId: 1,
            agreementNumber: 35434,
            agreementStatus: 'Provisional',
            agreementType: '3-Party (Single)',
            billingAccount: 'billingAccount',
            billingAccountAddress: 'billingAccountAddress',
            hasBespokeTestingRequirements: true,
            hasOptedOutOfVVStorage: true,
            isMultiAgreementType: true,
            lastDepositDate: '2022-10-14T08:29:59Z',
            lastDepositIsVerified: 'lastDepositIsVerified',
            lastDepositLevelOfVerification: 'Access Verification',
            lastDepositVersion: 'lastDepositVersion',
            lastVerificationExerciseDate: '2022-10-14T08:29:59Z',
            licensees: [{ registeredName: 'Paco' }, { registeredName: 'Juan' }],
            liveDate: 'value',
            monthsSinceLastDeposit: '20',
            monthsSinceLastVerification: '11',
            renewalMonth: 'renewalMonth',
            softwareOwner: 'softwareOwner',
            softwarePackageName: 'softwarePackageName',
            uploaderName: 'uploaderName',
            viewingPartyCanCreateDeposit: true
        },
        {
            agreementId: 2,
            agreementNumber: 35704,
            agreementStatus: 'Live',
            agreementType: '4 Party Legacy Agreement',
            billingAccount: 'billingAccount',
            billingAccountAddress: 'billingAccountAddress',
            hasBespokeTestingRequirements: true,
            hasOptedOutOfVVStorage: true,
            isMultiAgreementType: true,
            lastDepositDate: '2022-10-10T08:29:59Z',
            lastDepositIsVerified: 'lastDepositIsVerified',
            lastDepositLevelOfVerification: 'Deposit Evaluation Service',
            lastDepositVersion: 'lastDepositVersion',
            lastVerificationExerciseDate: '2022-10-20T08:29:59Z',
            licensees: [{ registeredName: 'Luis' }],
            liveDate: 'value',
            monthsSinceLastDeposit: '11',
            monthsSinceLastVerification: '12',
            renewalMonth: 'renewalMonth',
            softwareOwner: 'softwareOwner',
            softwarePackageName: 'softwarePackageName',
            uploaderName: 'uploaderName',
            viewingPartyCanCreateDeposit: true
        },
        {
            agreementId: 3,
            agreementNumber: 35707,
            agreementStatus: 'Provisional',
            agreementType: '4 Party Legacy Agreement',
            billingAccount: 'billingAccount',
            billingAccountAddress: 'billingAccountAddress',
            hasBespokeTestingRequirements: true,
            hasOptedOutOfVVStorage: true,
            isMultiAgreementType: true,
            lastDepositDate: '2022-08-10T08:29:59Z',
            lastDepositIsVerified: 'lastDepositIsVerified',
            lastDepositLevelOfVerification: 'Escrow Secure',
            lastDepositVersion: 'lastDepositVersion',
            lastVerificationExerciseDate: '2022-10-18T08:29:59Z',
            licensees: [],
            liveDate: 'value',
            monthsSinceLastDeposit: '12',
            monthsSinceLastVerification: '',
            renewalMonth: 'renewalMonth',
            softwareOwner: 'softwareOwner',
            softwarePackageName: 'softwarePackageName',
            uploaderName: 'uploaderName',
            viewingPartyCanCreateDeposit: true
        },
        {
            agreementId: 10,
            agreementNumber: 35721,
            agreementStatus: 'Live',
            agreementType: 'Some random unknown type',
            billingAccount: 'billingAccount',
            billingAccountAddress: 'billingAccountAddress',
            hasBespokeTestingRequirements: true,
            hasOptedOutOfVVStorage: true,
            isMultiAgreementType: true,
            lastDepositDate: '2022-11-10T08:29:59Z',
            lastDepositIsVerified: 'lastDepositIsVerified',
            lastDepositLevelOfVerification: '',
            lastDepositVersion: 'lastDepositVersion',
            lastVerificationExerciseDate: '2022-10-19T08:29:59Z',
            licensees: [],
            liveDate: 'value',
            monthsSinceLastDeposit: '',
            monthsSinceLastVerification: '',
            renewalMonth: 'renewalMonth',
            softwareOwner: 'softwareOwner',
            softwarePackageName: 'softwarePackageName',
            uploaderName: 'uploaderName',
            viewingPartyCanCreateDeposit: true
        },
        {
            agreementId: 11,
            agreementNumber: 35726,
            agreementStatus: 'Live',
            agreementType: 'AMS - Holding Agreement',
            billingAccount: 'billingAccount',
            billingAccountAddress: 'billingAccountAddress',
            hasBespokeTestingRequirements: true,
            hasOptedOutOfVVStorage: true,
            isMultiAgreementType: true,
            lastDepositDate: null,
            lastDepositIsVerified: 'lastDepositIsVerified',
            lastDepositLevelOfVerification: 'Random unknown testing level',
            lastDepositVersion: 'lastDepositVersion',
            lastVerificationExerciseDate: '2022-10-14T08:32:59Z',
            licensees: [],
            liveDate: 'value',
            monthsSinceLastDeposit: '',
            monthsSinceLastVerification: '',
            renewalMonth: 'renewalMonth',
            softwareOwner: 'softwareOwner',
            softwarePackageName: 'softwarePackageName',
            uploaderName: 'uploaderName',
            viewingPartyCanCreateDeposit: true
        },
        ...new Array(100)
            .fill({
                agreementId: 11,
                agreementNumber: 11,
                agreementStatus: 'Live',
                agreementType: 'AMS - Holding Agreement',
                billingAccount: 'billingAccount',
                billingAccountAddress: 'billingAccountAddress',
                hasBespokeTestingRequirements: true,
                hasOptedOutOfVVStorage: true,
                isMultiAgreementType: true,
                lastDepositDate: '2022-10-14T08:29:59Z',
                lastDepositIsVerified: 'lastDepositIsVerified',
                lastDepositLevelOfVerification: 'Random unknown testing level',
                lastDepositVersion: 'lastDepositVersion',
                lastVerificationExerciseDate: '2022-10-14T08:32:59Z',
                licensees: [],
                liveDate: 'value',
                monthsSinceLastDeposit: '',
                monthsSinceLastVerification: '',
                renewalMonth: 'renewalMonth',
                softwareOwner: 'softwareOwner',
                softwarePackageName: 'softwarePackageName',
                uploaderName: 'uploaderName',
                viewingPartyCanCreateDeposit: true
            })
            .map((item, index) => ({
                ...item,
                agreementId: 12 + index,
                agreementNumber: 12 + index
            }))
    ]
};

export default useAgreementsMockData;
