import { PropsWithChildren } from 'react';

import Button, { ButtonProps } from '../../../button/button';

interface DataHeaderButtonProps extends Omit<ButtonProps, 'variant'> {
    active?: boolean;
}

function DataHeaderButton({
    active = false,
    children,
    ref,
    ...restProps
}: PropsWithChildren<DataHeaderButtonProps>) {
    return (
        <Button {...restProps} variant={active ? 'primary' : 'default'}>
            {children}
        </Button>
    );
}

export default DataHeaderButton;
export type { DataHeaderButtonProps };
