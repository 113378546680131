import { DownloadScheduleItem } from 'core/api/client-portal/autogenerated/data-contracts';
import { Widget } from 'lib-ui';
import { useTranslation } from 'react-i18next';

import PreviousMonthSchedules from './previous-month-schedules/previous-month-schedules';
import UpcomingThisMonthSchedules from './upcoming-this-month-schedules/upcoming-this-month-schedules';

interface SchedulesMetricsProps {
    data: DownloadScheduleItem[] | null | undefined;
    loading?: boolean;
}

function SchedulesMetrics({ data, loading }: SchedulesMetricsProps) {
    const { t } = useTranslation();

    return (
        <Widget>
            <Widget.Header title={t('schedules.metrics.title')} />
            <Widget.Body className="px-2.5 py-3.5 space-y-2.5">
                <UpcomingThisMonthSchedules data={data} loading={loading} />
                <PreviousMonthSchedules data={data} loading={loading} />
            </Widget.Body>
        </Widget>
    );
}

export default SchedulesMetrics;
export type { SchedulesMetricsProps };
