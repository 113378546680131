import { ModalBackdrop } from 'lib-ui';
import { ModalsRoot } from '@ncc-frontend/core';
import { UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';

import { AuthProvider } from 'react-oidc-context';
import AppLayout from 'app-layout';
// import AuthProvider from 'common/auth/auth-provider';

import './app.css';
import { TimeoutProvider } from 'core/providers/timeout-provider';
import { Toaster } from 'react-hot-toast';

const OidcClientSettings: UserManagerSettings = {
    accessTokenExpiringNotificationTimeInSeconds: 30,
    authority: process.env.REACT_APP_AUTHORIZATION_ENDPOINT ?? '',
    automaticSilentRenew: true,
    client_id: 'EscrowPortalClient',
    // disablePKCE: true,
    filterProtocolClaims: false,
    loadUserInfo: true,
    post_logout_redirect_uri: `${window.location.origin}/?postLogout=true`,
    redirect_uri: `${
        window.location.origin
    }/assets/login-redirect.html?cookiesAccepted=${false}`,
    response_type: 'code',
    scope: 'openid profile portal_api ui_culture role full_name offline_access has_limited_access',
    silent_redirect_uri: `${window.location.origin}/assets/silent-renew.html`,
    userStore: new WebStorageStateStore({ store: window.localStorage })
};

// Log.setLogger(console);
// Log.setLevel(Log.DEBUG);

function App() {
    return (
        // <AuthProvider settings={OidcClientSettings}>
        <AuthProvider {...OidcClientSettings}>
            <TimeoutProvider>
                <ModalsRoot backdropComponent={ModalBackdrop}>
                    <Toaster toastOptions={{ duration: 6000 }} />
                    <AppLayout />
                </ModalsRoot>
            </TimeoutProvider>
        </AuthProvider>
    );
}

export default App;
