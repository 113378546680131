import { Menu } from 'lib-ui';
import {
    MouseEventHandler,
    PropsWithChildren,
    MouseEvent as ReactMouseEvent,
    useCallback
} from 'react';

type ExportType = 'general' | 'deposit-history';

type ExportTypeOptionClickHandler = (
    Type: ExportType,
    event: ReactMouseEvent<HTMLDivElement, MouseEvent>
) => void;

interface ExportTypeOptionProps {
    active?: boolean;
    onClick: ExportTypeOptionClickHandler;
    type: ExportType;
}

function TypeOption({
    active,
    children,
    onClick,
    type
}: PropsWithChildren<ExportTypeOptionProps>) {
    const handleClick = useCallback<MouseEventHandler<HTMLDivElement>>(
        (event) => {
            onClick(type, event);
        },
        [type, onClick]
    );

    return (
        <Menu.Item onClick={handleClick} active={active}>
            {children}
        </Menu.Item>
    );
}

export default TypeOption;
export type { ExportType, ExportTypeOptionClickHandler, ExportTypeOptionProps };
