import { create } from 'zustand';

interface StoreState {
    isUploadingFile: boolean;
    setIsUploadingFile: (isUploadingFile: boolean) => void;
}

const useDepositNowStore = create<StoreState>()((set) => ({
    isUploadingFile: false,
    setIsUploadingFile: (isUploadingFile: boolean) => set({ isUploadingFile })
}));

export default useDepositNowStore;
