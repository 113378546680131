import { ComponentType, PropsWithChildren } from 'react';
import TypographyLink, { TypographyLinkProps } from './typography-link';

type TypographyType = {
    Link: ComponentType<PropsWithChildren<TypographyLinkProps>>;
};

const Typography: TypographyType = {
    Link: TypographyLink
};

export default Typography;
export type { TypographyLinkProps, TypographyType };
