import { GetSourceCodeProvidersResponse } from '../../autogenerated/data-contracts';
import { UseQueryOptions, useQuery } from 'react-query';

import { ApiController } from '../../autogenerated/Api';
import { useAuth } from 'react-oidc-context';
import interceptors from '../../../interceptors';
import securityWorker from '../../../security-worker';

import useGetProvidersMockData from './use-get-providers-mock-data';

function useGetProviders<TError = unknown>(
    options?: Omit<
        UseQueryOptions<
            GetSourceCodeProvidersResponse | undefined,
            ReactQueryError<TError>,
            GetSourceCodeProvidersResponse | undefined
        >,
        'queryKey' | 'queryFn'
    >
) {
    const auth = useAuth();

    return useQuery<
        GetSourceCodeProvidersResponse | undefined,
        ReactQueryError<TError>,
        GetSourceCodeProvidersResponse | undefined
    >(
        [
            'getProviders',
            { token: auth.user?.access_token, tokenType: auth.user?.token_type }
        ],
        async () => {
            if (process.env.REACT_APP_API_MOCK) {
                console.warn('Using mock data for useGetProvidersMockData');
                return useGetProvidersMockData;
            }

            const controller = new ApiController({
                interceptors,
                securityWorker: securityWorker(
                    auth.user?.token_type,
                    auth.user?.access_token
                )
            });
            const response = await controller.getSourceCodeProviders();
            return response.data;
        },
        options
    );
}

export default useGetProviders;
