import ContentStep from '../../ui/deposit-now/content-step/content-step';
import DepositNowContainer from '../../ui/deposit-now/deposit-now-container';

const DepositNowContent = () => {
    return (
        <DepositNowContainer step="content">
            <ContentStep />
        </DepositNowContainer>
    );
};

export default DepositNowContent;
