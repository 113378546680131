/* eslint-disable @typescript-eslint/no-explicit-any */
import { Control, useController } from 'react-hook-form';
import { Switch as HeadlessSwitch } from '@headlessui/react';
import { forwardRef } from 'react';
import cn from 'core/utils/cn';

interface SwitchProps {
    control: Control<any>;
    dataTestId?: string;
    defaultValue?: boolean;
    disabled?: boolean;
    name: string;
}

const Switch = forwardRef<HTMLButtonElement, SwitchProps>(
    ({ control, dataTestId, defaultValue, disabled = false, name }, ref) => {
        const {
            field: { ...inputProps }
        } = useController({
            control,
            defaultValue,
            name
        });

        return (
            <div
                className={cn(
                    'w-fit rounded-full',
                    inputProps.value
                        ? 'bg-brand-escode-neonblue-neonblue-100'
                        : 'bg-general-grey-grey-40',
                    disabled && 'cursor-not-allowed bg-general-grey-grey-40'
                )}
            >
                <HeadlessSwitch
                    {...inputProps}
                    data-testid={dataTestId}
                    disabled={disabled}
                    ref={ref}
                    onChange={(value) => inputProps.onChange(value)}
                    checked={inputProps.value}
                    className={cn(
                        'relative inline-flex items-center h-7 rounded-full w-11',
                        disabled && 'cursor-not-allowed'
                    )}
                >
                    <span className="sr-only">Enable {name}</span>
                    <span
                        className={cn(
                            inputProps.value
                                ? 'translate-x-6'
                                : 'translate-x-1',
                            'inline-block w-4 h-4 transform bg-white rounded-full transition-all duration-150',
                            disabled && 'bg-general-grey-grey-60'
                        )}
                    />
                </HeadlessSwitch>
            </div>
        );
    }
);

export default Switch;
