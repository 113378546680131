import { CalendarData } from 'lib-ui/calendar/types';

export function findClosestDate(schedules: CalendarData[], currentDate: Date) {
    let closestDate = null;
    let timeDifference = Infinity;

    for (const schedule of schedules) {
        const scheduleDate = new Date(schedule.date);

        if (scheduleDate.getTime() >= currentDate.getTime()) {
            const diff = Math.abs(
                scheduleDate.getTime() - currentDate.getTime()
            );

            if (diff < timeDifference) {
                timeDifference = diff;
                closestDate = schedule;
            }
        }
    }

    return closestDate;
}
