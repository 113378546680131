import {
    ForwardRefExoticComponent,
    ForwardedRef,
    PropsWithChildren,
    RefAttributes,
    forwardRef
} from 'react';
import { TailwindProps, tailwindClasses } from '@ncc-frontend/core';
import classNames from 'classnames';

interface BannerProps extends TailwindProps {
    name: string;
}

const Banner = forwardRef(function Menu(
    {
        children,
        className,
        name,
        tailwindStyle,
        ...restProps
    }: PropsWithChildren<BannerProps>,
    ref: ForwardedRef<HTMLDivElement>
) {
    const cssClasses = classNames(
        className,
        tailwindClasses(
            {
                background: 'bg-general-grey-grey-10',
                borderRadius: 'rounded-xl',
                display: 'flex',
                items: 'items-center',
                justify: 'justify-center',
                margin: 'mb-[20px]',
                padding: 'p-5.5',
                textColor: 'text-general-grey-grey-70',
                textSize: 'text-[14px]'
            },
            tailwindStyle
        )
    );

    return (
        <div ref={ref} className={cssClasses} {...restProps} data-testid={name}>
            {children}
        </div>
    );
}) as ForwardRefExoticComponent<
    PropsWithChildren<BannerProps> & RefAttributes<HTMLDivElement>
>;

export default Banner;
export type { BannerProps };
