import { ApiController } from '../../autogenerated/Api';
import { AxiosResponse } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { ValidationProblemDetails } from '../../autogenerated/data-contracts';
import { useAuth } from 'react-oidc-context';
import interceptors from 'core/api/interceptors';
import securityWorker from 'core/api/security-worker';
import useAgreementsDepositsFilesMockData, {
    GetDepositFilesResponse
} from './use-agreements-deposits-files-mock-data';

function useAgreementsDepositsFiles<TError = unknown>(
    depositId: number,
    options?: Omit<
        UseQueryOptions<
            ValidationProblemDetails | undefined,
            ReactQueryError<TError>,
            ValidationProblemDetails | undefined
        >,
        'queryKey' | 'queryFn'
    >
) {
    const auth = useAuth();

    return useQuery(
        [
            {
                depositId,
                token: auth.user?.access_token,
                tokenType: auth.user?.token_type
            }
        ],
        async () => {
            if (process.env.REACT_APP_API_MOCK) {
                console.warn(
                    'Mock is enabled useAgreementsDepositsFiles call ignored.',
                    {
                        depositId
                    }
                );
                return new Promise<ValidationProblemDetails>(
                    (resolve, reject) => {
                        setTimeout(() => {
                            resolve(useAgreementsDepositsFilesMockData);
                        }, 1000);
                    }
                );
            }
            if (
                auth.user?.access_token === undefined ||
                auth.user.token_type === undefined
            ) {
                return undefined;
            }
            if (isNaN(depositId)) {
                console.warn(
                    'agreementId or depositId has been called with errors'
                );
                return undefined;
            }
            const apiController = new ApiController({
                interceptors,
                securityWorker: securityWorker(
                    auth.user?.token_type,
                    auth.user?.access_token
                )
            });
            const response: AxiosResponse<GetDepositFilesResponse> =
                await apiController.v2DepositFilesDetail(depositId);
            return response.data;
        },
        options
    );
}

export default useAgreementsDepositsFiles;
