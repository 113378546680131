import { Modal } from 'lib-ui';
import { useTranslation } from 'react-i18next';

const FileRepeatedModal = () => {
    const { t } = useTranslation();

    return (
        <Modal className="overflow-y-auto" size="s">
            <Modal.Header>
                <h2 className="text-brand-escode-neonblue-neonblue-100 text-xl">
                    {t('file-repeated')}
                </h2>
            </Modal.Header>
            <span className="py-4 mt-2">{t('file-repeated-message')}</span>
            <Modal.Body className="flex flex-col gap-2.5"></Modal.Body>
        </Modal>
    );
};
export default FileRepeatedModal;
