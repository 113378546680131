import { PropsWithChildren } from 'react';

import { Button, ButtonProps } from 'lib-ui';

interface HeaderButtonProps extends ButtonProps {}

function HeaderButton({
    children,
    ref,

    ...restProps
}: PropsWithChildren<HeaderButtonProps>) {
    return (
        <Button {...restProps} size="l">
            {children}
        </Button>
    );
}

export default HeaderButton;
export type { HeaderButtonProps };
