import { useCallback } from 'react';
import classNames from 'classnames';

interface XAxisProps {
    active?: boolean;
    index: number;
    label: string;
    onSelected: (index: number | undefined) => void;
    rect: {
        height: number;
        width: number;
        x: number;
        y: number;
    };
    x: number;
    y: number;
}

function XAxis({
    active = false,
    index,
    label,
    onSelected,
    rect,
    x,
    y
}: XAxisProps) {
    const rectCssClasses = classNames('transition-colors', {
        'fill-brand-escode-neonblue-neonblue-100/10': active,
        'fill-transparent': !active
    });

    const textCssClasses = classNames('transition-colors text-xs', {
        'fill-brand-escode-neonblue-neonblue-10': !active,
        'fill-escode-grey-100': active
    });

    const handleMouseEnter = useCallback(() => {
        onSelected(index);
    }, [index, onSelected]);

    const handleMouseLeave = useCallback(() => {
        onSelected(undefined);
    }, [onSelected]);

    return (
        <>
            <rect
                x={rect.x}
                y={rect.y}
                height={rect.height}
                width={rect.width}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className={rectCssClasses}
            />

            <text
                key={label}
                x={x}
                y={y}
                textAnchor="middle"
                dominantBaseline="hanging"
                className={textCssClasses}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {label}
            </text>
        </>
    );
}

export default XAxis;
export type { XAxisProps };
