import { EditScheduleFormValues } from './types';
import { MONTH_MAPPER } from './month-mapper';
import {
    RadioGroupField,
    RadioGroupFieldProps,
    SelectField,
    SelectFieldProps,
    Widget
} from 'lib-ui';
import { TailwindStyle } from '@ncc-frontend/core';
import { useEffect, useMemo, useRef } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface TimeControlProps {
    bodyClassName: string;
    /** Css units: px, rem, ... */
    headerHeight?: string;
    headerTailwindStyle: TailwindStyle;
}

function TimeControl({
    bodyClassName,
    headerHeight,
    headerTailwindStyle
}: TimeControlProps) {
    const { i18n, t } = useTranslation();
    const { setValue } = useFormContext();
    const beautifyOrdinal = (number: string) => {
        const num = parseInt(number, 10);
        const lastDigit = num % 10;
        const lastTwoDigits = num % 100;

        let suffix = 'th';
        if (lastTwoDigits < 11 || lastTwoDigits > 13) {
            switch (lastDigit) {
                case 1:
                    suffix = 'st';
                    break;
                case 2:
                    suffix = 'nd';
                    break;
                case 3:
                    suffix = 'rd';
                    break;
            }
        }

        return number + suffix;
    };

    const today = new Date();

    const currentCalendarDate =
        localStorage.getItem('current-calendar-date') || '17th April 2024';

    const formattedMonth = currentCalendarDate.split(' ')[1];
    const calendarYear = +currentCalendarDate.split(' ')[2];
    const calendarMonth =
        MONTH_MAPPER[formattedMonth as keyof typeof MONTH_MAPPER];

    const frequency = useWatch<EditScheduleFormValues>({
        name: 'frequency'
    }) as EditScheduleFormValues['frequency'];

    const formMonth = useWatch<EditScheduleFormValues>({
        name: 'month'
    }) as EditScheduleFormValues['month'];
    const month = !isNaN(+formMonth) ? +formMonth : today.getMonth();

    const formDayOfMonth = useWatch<EditScheduleFormValues>({
        name: 'dayOfMonth'
    }) as EditScheduleFormValues['dayOfMonth'];
    const dayOfMonth = +formDayOfMonth;

    const { current: hourOptions } = useRef<SelectFieldProps['options']>(
        new Array(24).fill(0).map((_, index) => ({
            label: index.toString().padStart(2, '0'),
            value: index.toString().padStart(2, '0')
        }))
    );

    const { current: minuteOptions } = useRef<SelectFieldProps['options']>(
        new Array(60).fill(0).map((_, index) => ({
            label: index.toString().padStart(2, '0'),
            value: index.toString().padStart(2, '0')
        }))
    );

    const { current: daysOfWeek } = useRef<RadioGroupFieldProps['options']>(
        // Date(1970, 1, 2) was a Monday
        new Array(7).fill(0).map((_, index) => ({
            label: new Date(1970, 1, 2 + index).toLocaleDateString(
                i18n.language,
                { weekday: 'long' }
            ),
            // Loop 0 index falls on Monday but Javascript dates starts week on Sunday
            value: (() => {
                const aux = index + 1;
                return (aux > 6 ? 0 : aux).toString();
            })()
        }))
    );

    const { current: rangesOfQuarterly } = useRef<
        RadioGroupFieldProps['options']
    >(
        new Array(3).fill(0).map((_, index) => {
            const quarters = [
                ['January', 'April', 'July', 'October'],
                ['February', 'May', 'August', 'November'],
                ['March', 'June', 'September', 'December']
            ];

            return {
                label: quarters[index].join(' - '),
                value: (index + 1).toString().padStart(2, '0')
            };
        })
    );

    const { current: months } = useRef<SelectFieldProps['options']>(
        new Array(12).fill(0).map((_, index) => ({
            label: new Date(1970, index, 1).toLocaleDateString(i18n.language, {
                month: 'long'
            }),
            value: index.toString().padStart(2, '0')
        }))
    );

    const lastDayOfMonth = new Date(calendarYear, calendarMonth, 0).getDate();

    const daysOfMonth = useMemo<SelectFieldProps['options']>(
        () =>
            new Array(lastDayOfMonth).fill(0).map((_, index) => ({
                label: beautifyOrdinal((index + 1).toString()),
                value: (index + 1).toString().padStart(2, '0')
            })),
        [lastDayOfMonth]
    );

    useEffect(() => {
        if (month === 1 && !isNaN(dayOfMonth) && dayOfMonth >= lastDayOfMonth) {
            setValue('dayOfMonth', '');
        }
    }, [dayOfMonth, lastDayOfMonth, month, setValue]);

    return (
        <Widget>
            <Widget.Header
                tailwindStyle={headerTailwindStyle}
                height={headerHeight}
            >
                {frequency === 'daily' && t('schedules.edit-modal.time')}
                {frequency === 'weekly' &&
                    t('schedules.edit-modal.day-of-week')}
                {frequency === 'monthly' &&
                    t('schedules.edit-modal.day-of-month')}
                {frequency === 'annually' &&
                    t('schedules.edit-modal.date-per-year')}
                {frequency === 'quarterly' &&
                    t('schedules.edit-modal.date-per-quarterly')}
            </Widget.Header>
            <Widget.Body className={bodyClassName} headerHeight={headerHeight}>
                {frequency !== 'daily' && (
                    <div className="space-y-2.5 border-b border-escode-grey-30 overflow-auto pb-2">
                        {frequency === 'weekly' && (
                            <RadioGroupField
                                name="dayOfWeek"
                                options={daysOfWeek}
                                orientation="vertical"
                                className="overflow-auto"
                            />
                        )}

                        {frequency === 'monthly' && (
                            <>
                                <RadioGroupField
                                    name="dayOfMonth"
                                    options={daysOfMonth}
                                    orientation="vertical"
                                    className="overflow-auto"
                                />
                            </>
                        )}

                        {frequency === 'quarterly' && (
                            <>
                                <RadioGroupField
                                    name="rangeOfQuarterly"
                                    options={rangesOfQuarterly}
                                    orientation="vertical"
                                    className="overflow-auto"
                                />
                            </>
                        )}

                        {frequency === 'annually' && (
                            <>
                                <SelectField
                                    options={daysOfMonth}
                                    name="dayOfMonth"
                                    placeholder={t('day')}
                                />
                                <SelectField options={months} name="month" />
                            </>
                        )}
                    </div>
                )}
                <div className="flex flex-col place-content-between">
                    {frequency === 'quarterly' && (
                        <div className="flex flex-1 gap-2.5 items-center pb-3">
                            <SelectField
                                fieldStyles="flex-1"
                                options={daysOfMonth}
                                name="dayOfMonth"
                                placeholder={t('day')}
                            />
                        </div>
                    )}
                    <div className="w-full flex gap-2.5 place-content-between">
                        <SelectField
                            fieldStyles="flex-1"
                            name="hour"
                            options={hourOptions}
                            placeholder="00"
                        />
                        <SelectField
                            fieldStyles="flex-1"
                            name="minute"
                            options={minuteOptions}
                            placeholder="00"
                        />
                    </div>
                </div>
            </Widget.Body>
        </Widget>
    );
}

export default TimeControl;
