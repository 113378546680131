import { ConfirmationTooltip } from 'lib-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Placement } from '@ncc-frontend/core';
import { PropsWithChildren } from 'react';
import {
    faDiamondExclamation,
    faInfoCircle
} from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import Asterisk from './asterisk';
import Optional from './optional';
import cn from 'core/utils/cn';

type SectionHeadingType = {
    className?: string;
    dataTestId?: string;
    heading?: string;
    headingTextClasses?: string;
    optional?: boolean;
    required?: boolean;
    tooltip?: string;
    tooltipDisabled?: boolean;
    tooltipPlacement?: Placement;
};

const SectionHeading = ({
    className,
    dataTestId,
    heading,
    headingTextClasses,
    optional = false,
    required = false,
    tooltip,
    tooltipDisabled,
    tooltipPlacement
}: PropsWithChildren<SectionHeadingType>) => {
    const { t } = useTranslation();

    return (
        <div className="flex justify-between">
            <div className={cn('flex gap-1 mb-4', className)}>
                <div className="flex text-center items-center gap-2">
                    <h2
                        className={cn(
                            'font-medium text-xl text-escrow-grey-110/75',
                            headingTextClasses
                        )}
                        data-testid={`section-heading-${dataTestId}`}
                    >
                        {heading}
                    </h2>
                    {optional && <Optional />}
                </div>
                {required && <Asterisk />}
            </div>
            {tooltip && (
                <div className="mb-2">
                    <ConfirmationTooltip
                        placement={tooltipPlacement}
                        icon={<FontAwesomeIcon icon={faDiamondExclamation} />}
                        message={t(tooltip)}
                        variant="default"
                        disabled={tooltipDisabled}
                    >
                        <FontAwesomeIcon
                            icon={faInfoCircle}
                            className="text-brand-escode-neonblue-neonblue-100"
                        />
                    </ConfirmationTooltip>
                </div>
            )}
        </div>
    );
};

export default SectionHeading;
