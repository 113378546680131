import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import RadioGroupField, { RadioGroupFieldProps } from './radio-group-field';

interface YesNoFieldProps extends Omit<RadioGroupFieldProps, 'options'> {}

function YesNoField({ ...restProps }: YesNoFieldProps) {
    const { t } = useTranslation();

    const options = useMemo<RadioGroupFieldProps['options']>(
        () => [
            {
                label: t('yes'),
                value: 'yes'
            },
            {
                label: t('no'),
                value: 'no'
            }
        ],
        [t]
    );

    return <RadioGroupField {...restProps} options={options} />;
}

export default YesNoField;
export type { YesNoFieldProps };
