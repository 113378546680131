import { Button } from 'lib-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightToLine } from '@fortawesome/pro-solid-svg-icons';
import { useCallback } from 'react';
import { useModals } from '@ncc-frontend/core';
import { useTranslation } from 'react-i18next';
import DEPOSIT_STATE_MAPPER from 'core/api/client-portal/model/deposit-state';

import { GetIncompleteDepositsResponse } from 'core/api/client-portal/autogenerated/data-contracts';
import DashboardWidget, { DashboardWidgetProps } from '../dashboard-widget';
import IncompleteDeposit from 'ui/deposits/incomplete-deposit';
import IncompleteDepositsModal from 'ui/incomplete-deposits-modal/incomplete-deposits-modal';
import useIncompleteDepositsV2 from 'core/api/client-portal-v2/hooks/deposit/use-incomplete-deposits-v2';
import useMaxItems from 'common/use-max-items';

type IncompleteDepositType = {
    agreementId: number;
    agreementNumber: number;
    created: string;
    createdByUserId: string;
    depositId: number;
    lastSavedState: number;
    softwarePackageName: string;
    versionOrReleaseNumber: string;
};

interface IncompleteDepositsWidgetProps
    extends Omit<DashboardWidgetProps, 'loading'> {}

const IncompleteDepositsWidget = ({
    className
}: IncompleteDepositsWidgetProps) => {
    const { t } = useTranslation();
    const { push } = useModals();

    const { rows, setContainerRef, setItemRef } = useMaxItems({
        rowGap: 10
    });

    const {
        data: incompleteDeposits,
        isFetching,
        isLoading
    } = useIncompleteDepositsV2<GetIncompleteDepositsResponse>();

    const loading = isLoading || isFetching;

    const viewDeposits = useCallback(() => {
        push(IncompleteDepositsModal, {});
    }, [push]);

    return (
        <DashboardWidget className={className}>
            <DashboardWidget.Header
                title={t('incomplete-deposits-widget.heading')}
                loading={loading}
            >
                <Button size="s" className="ml-auto" onClick={viewDeposits}>
                    {t('incomplete-deposits-widget.button-view-deposits')}{' '}
                    <FontAwesomeIcon
                        className="text-brand-escode-neonblue-neonblue-100 text-escode-xxxs"
                        icon={faArrowRightToLine}
                    />
                </Button>
            </DashboardWidget.Header>
            <DashboardWidget.Body
                className="flex flex-col gap-2.5"
                ref={setContainerRef}
            >
                {!!loading &&
                    [...new Array(rows)].map((_, index) => (
                        <IncompleteDeposit
                            key={index}
                            ref={index === 0 ? setItemRef : undefined}
                            loading={loading}
                        />
                    ))}
                {!loading &&
                    incompleteDeposits?.deposits
                        ?.slice(0, rows)
                        .map((item: IncompleteDepositType, index: number) => (
                            <IncompleteDeposit
                                key={item.depositId}
                                ref={index === 0 ? setItemRef : undefined}
                                agreementId={item.agreementId}
                                agreementNumber={item.agreementNumber}
                                created={item.created}
                                depositId={item.depositId}
                                lastSavedState={item.lastSavedState ?? 0}
                                name={item.softwarePackageName}
                                restrictWidth
                                state={
                                    DEPOSIT_STATE_MAPPER[
                                        item!.lastSavedState ?? 0
                                    ]
                                }
                                version={item.versionOrReleaseNumber}
                            />
                        ))}
            </DashboardWidget.Body>
        </DashboardWidget>
    );
};

export default IncompleteDepositsWidget;
