import { Frequency } from './frequency-enum';

export const automatedOptions: { label: string; value: string }[] = [
    { label: 'Yes', value: 'true' },
    { label: 'No', value: 'false' }
];

export const dayOptions: { label: string; value: string }[] = [];

for (let i = 1; i <= 30; i++) {
    const dayLabel = i < 10 ? `0${i}` : i.toString();
    dayOptions.push({ label: dayLabel, value: dayLabel });
}

export const frequencyOptions: { label: string; value: string }[] = [
    { label: 'Daily', value: '1' },
    { label: 'Weekly', value: '2' },
    { label: 'Monthly', value: '3' },
    { label: 'Quarterly', value: '5' },
    { label: 'Annually', value: '4' }
];
export const hoursOptions: { label: string; value: string }[] = [];
for (let i = 0; i <= 23; i++) {
    const hourLabel = i < 10 ? `0${i}` : i.toString();
    hoursOptions.push({ label: hourLabel, value: hourLabel });
}
export const minutesOptions: { label: string; value: string }[] = [];
for (let i = 0; i <= 59; i++) {
    const minuteLabel = i < 10 ? `0${i}` : i.toString();
    minutesOptions.push({ label: minuteLabel, value: minuteLabel });
}
export const monthOptions: { label: string; value: string }[] = [
    { label: 'January', value: '01' },
    { label: 'February', value: '02' },
    { label: 'March', value: '03' },
    { label: 'April', value: '04' },
    { label: 'May', value: '05' },
    { label: 'June', value: '06' },
    { label: 'July', value: '07' },
    { label: 'August', value: '08' },
    { label: 'September', value: '09' },
    { label: 'October', value: '10' },
    { label: 'November', value: '11' },
    { label: 'December', value: '12' }
];
export const weekOptions: { label: string; value: string }[] = [
    { label: 'Monday', value: '1' },
    { label: 'Tuesday', value: '2' },
    { label: 'Wednesday', value: '3' },
    { label: 'Thursday', value: '4' },
    { label: 'Friday', value: '5' },
    { label: 'Saturday', value: '6' },
    { label: 'Sunday', value: '7' }
];

// suffixes for days of the month
function getOrdinalSuffix(day: number): string {
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const v = day % 100;
    return day + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
}
// days of the month
// eslint-disable-next-line sort-exports/sort-exports
export const daysOfMonth: { label: string; value: string }[] = Array.from(
    { length: 31 },
    (_, index) => {
        const dayNumber = index + 1;
        const label = getOrdinalSuffix(dayNumber);
        const value = dayNumber.toString().padStart(2, '0');
        return { label, value };
    }
);
function getNextOccurrenceInDay(hour: number, minutes: number): string {
    const now = new Date();

    const todayLocal = new Date(
        Date.UTC(
            now.getUTCFullYear(),
            now.getUTCMonth(),
            now.getUTCDate(),
            hour,
            minutes
        )
    );

    if (todayLocal <= now) {
        todayLocal.setDate(todayLocal.getDate() + 1);
    }

    // Adjust for local timezone
    const localTimeOffset = now.getTimezoneOffset() * -60000; // in milliseconds
    const todayLocalInUserTZ = new Date(todayLocal.getTime() - localTimeOffset);

    return todayLocalInUserTZ.toISOString();
}
function getNextWeekOccurrence(
    hour: number,
    minutes: number,
    weekDay: number
): string {
    const now = new Date();
    const nowUTC = new Date(
        Date.UTC(
            now.getUTCFullYear(),
            now.getUTCMonth(),
            now.getUTCDate(),
            now.getUTCHours(),
            now.getUTCMinutes()
        )
    );
    const currentDay = nowUTC.getUTCDay();

    const normalizedWeekDay = weekDay % 7;

    let daysUntilNext = normalizedWeekDay - currentDay;

    // If the day has already passed or it's today but the hour/minute has passed
    if (
        daysUntilNext < 0 ||
        (daysUntilNext === 0 &&
            (hour < nowUTC.getUTCHours() ||
                (hour === nowUTC.getUTCHours() &&
                    minutes <= nowUTC.getUTCMinutes())))
    ) {
        // Add 7 days to ensure it is scheduled for the following week
        daysUntilNext += 7;
    }

    // Create a new date for the next occurrence by adding the calculated days
    const nextDate = new Date(
        Date.UTC(
            nowUTC.getUTCFullYear(),
            nowUTC.getUTCMonth(),
            nowUTC.getUTCDate() + daysUntilNext,
            hour,
            minutes
        )
    );

    const userTimeZoneOffset = now.getTimezoneOffset();
    const nextDateInUserTZ = new Date(
        nextDate.getTime() - userTimeZoneOffset * -60000
    );

    return nextDateInUserTZ.toISOString();
}
function getNextOccurrenceInMonth(
    hour: number,
    minutes: number,
    dayOfMonth: number
): string {
    const now = new Date();
    const nowUTC = new Date(
        Date.UTC(
            now.getUTCFullYear(),
            now.getUTCMonth(),
            now.getUTCDate(),
            now.getUTCHours(),
            now.getUTCMinutes()
        )
    );
    const currentYear = nowUTC.getUTCFullYear();
    const currentMonth = nowUTC.getUTCMonth();

    // Create a tentative date for this month in UTC
    let nextDate = new Date(
        Date.UTC(currentYear, currentMonth, dayOfMonth, hour, minutes, 0, 0)
    );

    // If the calculated date has already passed this month, set to next month
    if (nextDate <= nowUTC) {
        nextDate = new Date(
            Date.UTC(
                currentYear,
                currentMonth + 1,
                dayOfMonth,
                hour,
                minutes,
                0,
                0
            )
        );
    }

    // If the day doesn't exist in the next month, adjust to the last day of the month
    if (nextDate.getUTCDate() !== dayOfMonth) {
        nextDate = new Date(
            Date.UTC(currentYear, currentMonth + 1, 0, hour, minutes, 0, 0)
        );
    }

    // Adjust the date to the user's local timezone
    const userTimezoneOffset = -now.getTimezoneOffset();
    nextDate.setMinutes(nextDate.getMinutes() - userTimezoneOffset);

    return nextDate.toISOString();
}

function getNextOccurrenceInYearly(
    dayOfMonth: number,
    month: number,
    hour: number,
    minutes: number
): string {
    const now = new Date();
    const nowUTC = new Date(
        Date.UTC(
            now.getUTCFullYear(),
            now.getUTCMonth(),
            now.getUTCDate(),
            now.getUTCHours(),
            now.getUTCMinutes()
        )
    );
    const currentYear = nowUTC.getUTCFullYear();

    // Create a date in UTC with the provided year, month, day, hour, and minutes
    let nextDate = new Date(
        Date.UTC(currentYear, month - 1, dayOfMonth, hour, minutes, 0, 0)
    );

    if (nextDate <= nowUTC) {
        // If the date is less than or equal to the current date, increment the year
        nextDate = new Date(
            Date.UTC(
                currentYear + 1,
                month - 1,
                dayOfMonth,
                hour,
                minutes,
                0,
                0
            )
        );
    }

    const userTimezoneOffset = -now.getTimezoneOffset();
    nextDate.setMinutes(nextDate.getMinutes() - userTimezoneOffset);

    return nextDate.toISOString();
}
function parseFrequency(frequencyStr: string): Frequency | null {
    switch (frequencyStr) {
        case '1':
            return Frequency.Daily;
        case '2':
            return Frequency.Weekly;
        case '3':
            return Frequency.Monthly;
        case '4':
            return Frequency.Annually;
        case '5':
            return Frequency.Quarterly;
        default:
            return null; // Or handle invalid values as needed
    }
}

export const parseDownloadDate = (
    day?: string,
    hour?: string,
    minutes?: string,
    monthOptions?: string,
    month?: string,
    week?: string,
    frequency?: string
): string => {
    const parsedFrequency = parseFrequency(frequency || '');

    switch (parsedFrequency) {
        case Frequency.Daily: {
            return getNextOccurrenceInDay(Number(hour), Number(minutes));
        }

        case Frequency.Weekly:
            return getNextWeekOccurrence(
                Number(hour),
                Number(minutes),
                Number(week)
            );

        case Frequency.Monthly:
            return getNextOccurrenceInMonth(
                Number(hour),
                Number(minutes),
                Number(month)
            );
        case Frequency.Quarterly:
        case Frequency.Annually:
            return getNextOccurrenceInYearly(
                Number(day),
                Number(monthOptions),
                Number(hour),
                Number(minutes)
            );

        default:
            break;
    }

    return '';
};
