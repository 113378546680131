import { useFormContext } from 'react-hook-form';
import { useId } from 'react';

import Field, { FieldProps } from './field';
import FileInput, { FileInputProps } from '../element/file-input';

interface FileInputFieldProps
    extends FieldProps,
        Omit<FileInputProps, 'name' | 'id' | 'error'> {}

function FileInputField({
    disabled,
    label,
    name,
    orientation,
    readOnly,
    required,
    ...restProps
}: FileInputFieldProps) {
    const {
        formState: { errors },
        register
    } = useFormContext();
    const id = useId();

    return (
        <Field
            name={name}
            label={label}
            orientation={orientation}
            readOnly={readOnly}
            disabled={disabled}
            id={id}
            required={required}
            error={!!errors[name] ? <>{errors[name]?.message}</> : undefined}
        >
            <FileInput
                {...restProps}
                id={id}
                {...register(name)}
                readOnly={readOnly}
                disabled={disabled}
                error={!!errors[name]}
            />
        </Field>
    );
}

export default FileInputField;
export type { FileInputFieldProps };
