import { AgreementDetails } from '../../autogenerated/data-contracts';

const useAgreementsDetailsMockData: AgreementDetails = {
    agreementId: 1,
    agreementNumber: 1,
    agreementStatus: 'Provisional',
    agreementType: '3-Party (Single)',
    freeDepositsRemaining: 2,
    hasBespokeTestingRequirements: true,
    hasOptedOutOfVVStorage: true,
    isMultiAgreementType: true,
    isOwner: true,
    lastDepositDate: '2022-10-14T08:29:59Z',
    numberOfAssociatedParties: 3,
    softwareOwner: 'Mr Smith',
    softwarePackageName: undefined,
    viewableAgreementParties: [
        {
            agreementPartyContacts: [
                {
                    contactId: 1,
                    id: 1,
                    name: 'test',
                    roles: ['Main', 'Finance']
                }
            ],
            agreementPartyId: 1,
            billingAccount: 'billingAccount',
            canEditContacts: true,
            depositor: true,
            partyStatus: 'partyStatus',
            partyStatusDate: 'partyStatusDate',
            partyType: 'partyType',
            registeredName: 'registeredName'
        }
    ],
    viewingPartyCanCreateDeposit: true
};

export default useAgreementsDetailsMockData;
