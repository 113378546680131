import { GetDownloadSchedulesResponse } from '../../autogenerated/data-contracts';

const useSchedulesListMockData: GetDownloadSchedulesResponse = {
    schedules: [
        {
            agreementNumber: '1',
            canDelete: true,
            canEdit: true,
            frequencyType: 1,
            id: 1,
            items: [],
            nextDownloadAt: '2024-02-18T08:45:59Z',
            softwareOwnerCompanyName: 'softwareOwnerCompanyName',
            softwarePackageName: 'softwarePackageName',
            versionOrReleaseNumber: 'versionOrReleaseNumber'
        },
        {
            agreementNumber: '2',
            canDelete: false,
            canEdit: false,
            frequencyType: 1,
            id: 2,
            items: [],
            nextDownloadAt: '2024-02-20T08:29:59Z',
            softwareOwnerCompanyName: 'softwareOwnerCompanyName',
            softwarePackageName: 'softwarePackageName',
            versionOrReleaseNumber: 'versionOrReleaseNumber'
        },
        {
            agreementNumber: '3',
            canDelete: true,
            canEdit: false,
            frequencyType: 2,
            id: 3,
            items: [],
            nextDownloadAt: '2024-02-21T10:00:00Z',
            softwareOwnerCompanyName: 'softwareOwnerCompanyName',
            softwarePackageName: 'softwarePackageName',
            versionOrReleaseNumber: 'versionOrReleaseNumber'
        },
        {
            agreementNumber: '4',
            canDelete: false,
            canEdit: true,
            frequencyType: 3,
            id: 4,
            items: [],
            nextDownloadAt: '2024-02-22T16:29:59Z',
            softwareOwnerCompanyName: 'softwareOwnerCompanyName',
            softwarePackageName: 'softwarePackageName',
            versionOrReleaseNumber: 'versionOrReleaseNumber'
        },
        {
            agreementNumber: '5',
            canDelete: false,
            canEdit: false,
            frequencyType: 4,
            id: 5,
            items: [],
            nextDownloadAt: 'nextDownloadAt',
            softwareOwnerCompanyName: 'softwareOwnerCompanyName',
            softwarePackageName: 'softwarePackageName',
            versionOrReleaseNumber: 'versionOrReleaseNumber'
        },
        {
            agreementNumber: '6',
            frequencyType: 4,
            id: 6,
            items: [],
            nextDownloadAt: '2024-02-22T13:29:59Z',
            softwareOwnerCompanyName: 'mock data',
            softwarePackageName: 'mock data',
            versionOrReleaseNumber: 'versionOrReleaseNumber'
        },
        {
            agreementNumber: '7',
            frequencyType: 1,
            id: 7,
            items: [],
            nextDownloadAt: '2024-02-23T06:00:00Z',
            softwareOwnerCompanyName: 'Test company',
            softwarePackageName: 'Test software',
            versionOrReleaseNumber: 'versionOrReleaseNumber'
        },
        {
            agreementNumber: '8',
            frequencyType: 5,
            id: 8,
            items: [],
            nextDownloadAt: '2024-02-09T06:00:00Z',
            softwareOwnerCompanyName: 'Test company',
            softwarePackageName: 'Test software Quarterly',
            versionOrReleaseNumber: 'versionOrReleaseNumber'
        }
    ]
};

export default useSchedulesListMockData;
