import { AgreementPartyViewModel } from 'core/api/client-portal/autogenerated/data-contracts';
import { Modal } from 'lib-ui';
import { useTranslation } from 'react-i18next';

const ROLES = ['Main', 'Finance', 'Code'];

function getContactByRol(agreement: AgreementPartyViewModel, role: string) {
    return agreement?.agreementPartyContacts?.find((item) =>
        item.roles?.some((r) => r === role)
    )?.name;
}

function AgreementPartiesContactModal({
    agreementPartyContacts,
    agreementPartyId,
    billingAccount,
    canEditContacts,
    depositor,
    partyStatus,
    partyStatusDate,
    partyType,
    registeredName
}: AgreementPartyViewModel) {
    const { t } = useTranslation();

    return (
        <Modal size="m">
            <Modal.Header>
                {t('agreement-parties-contact-modal.header')}
            </Modal.Header>
            <Modal.Body>
                <dl>
                    {ROLES.map((role) => (
                        <li
                            key={role}
                            className="grid grid-cols-2 content-stretch text-general-grey-grey-90 text-base py-5 border-b border-escode-grey-20"
                        >
                            <span className="text-left font-normal">
                                {role}
                            </span>
                            <span className="text-right font-medium">
                                {getContactByRol(
                                    {
                                        agreementPartyContacts,
                                        agreementPartyId,
                                        billingAccount,
                                        canEditContacts,
                                        depositor,
                                        partyStatus,
                                        partyStatusDate,
                                        partyType,
                                        registeredName
                                    },
                                    role
                                )}
                            </span>
                        </li>
                    ))}
                </dl>
            </Modal.Body>
        </Modal>
    );
}

export default AgreementPartiesContactModal;
