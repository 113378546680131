import { SVGProps, useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames';

interface BarProps {
    active: boolean;
    color: string;
    index: number;
    loading?: boolean;
    onSelected: (index: number | undefined) => void;
    width: number;
    x: number;
    y1: number;
    y2: number;
}

function Bar({
    active,
    color,

    index,
    loading,
    onSelected,
    width,
    x,
    y1,
    y2
}: BarProps) {
    const duration = '500ms';
    const markerStroke = 2;

    const lineAnimateElement = useRef<SVGAnimateElement | null>(null);
    const circleAnimateElement = useRef<SVGAnimateElement | null>(null);

    const handleMouseEnter = useCallback(() => {
        onSelected(index);
    }, [index, onSelected]);

    const handleMouseLeave = useCallback(() => {
        onSelected(undefined);
    }, [onSelected]);

    // Account for Y axis line with
    const yAxisFix = 0.5;

    const animate: SVGProps<SVGElement> = {
        begin: '500ms',
        calcMode: 'spline',
        dur: duration,
        fill: 'freeze',
        keySplines: '0.25 0.1 0.25 1',
        values: `${y1};${y2 + width / 2 - yAxisFix}`
    };

    useEffect(() => {
        lineAnimateElement.current?.beginElement();
        circleAnimateElement.current?.beginElement();
    }, [y2, circleAnimateElement, lineAnimateElement]);

    return y1 - y2 === 0 ? null : (
        <>
            <line
                x1={x}
                y1={y1 - yAxisFix}
                x2={x}
                strokeWidth={width}
                stroke={!loading ? color : undefined}
                className={classNames({
                    'animate-pulse stroke-escode-grey-30': !!loading
                })}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <animate
                    ref={(ref: SVGAnimateElement) =>
                        (lineAnimateElement.current = ref)
                    }
                    attributeName="y2"
                    {...animate}
                />
            </line>
            <circle
                cx={x}
                r={width / 2}
                fill={!loading ? color : undefined}
                stroke="white"
                strokeWidth={active ? markerStroke : 0}
                className={classNames({
                    'animate-pulse fill-escode-grey-30': !!loading
                })}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <animate
                    ref={(ref: SVGAnimateElement) =>
                        (circleAnimateElement.current = ref)
                    }
                    attributeName="cy"
                    {...animate}
                />
            </circle>

            {/* Cutoff hack for below 0 */}
            <line
                x1={x}
                y1={y1 + width}
                x2={x}
                y2={y1 + 0.5}
                strokeWidth={width + 1}
                className={classNames('transition-colors', {
                    'stroke-transparent': active,
                    'stroke-white': !active
                })}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            ></line>
        </>
    );
}

export default Bar;
export type { BarProps };
