import { useCallback } from 'react';
import { useDropdown } from '@ncc-frontend/core';
import { useTranslation } from 'react-i18next';

import Button from '../../../button/button';
import TooltipPanel from '../../../tooltip/tooltip-panel';
import useDataWidget from '../use-data-widget';

interface ResetLayoutPanelProps {}

function ResetLayoutPanel({}: ResetLayoutPanelProps) {
    const { t } = useTranslation();
    const { close } = useDropdown();
    const { onReset, setHiddenFields } = useDataWidget();

    const reset = useCallback(() => {
        setHiddenFields({});
        onReset!();
        close();
    }, [close, onReset, setHiddenFields]);

    const cancel = useCallback(() => {
        close();
    }, [close]);

    return (
        <TooltipPanel suppressTick className="min-w-2xs text-center space-y-3">
            <div className="text-base">{t('table.reset-layout-tooltip')}</div>
            <div className="flex gap-escode-m items-center">
                <Button variant="danger" onClick={reset} className="flex-1">
                    {t('table.reset-layout-confirm')}
                </Button>
                <Button variant="primary" onClick={cancel} className="flex-1">
                    {t('table.reset-layout-reject')}
                </Button>
            </div>
        </TooltipPanel>
    );
}

export default ResetLayoutPanel;
export type { ResetLayoutPanelProps };
