import { DownloadScheduleItem } from 'core/api/client-portal/autogenerated/data-contracts';
import { ICellRendererParams, Typography } from 'lib-ui';

function SchedulesAgreementNumberCellRenderer({
    value
}: ICellRendererParams<DownloadScheduleItem>) {
    return <Typography.Link>{value}</Typography.Link>;
}

export default SchedulesAgreementNumberCellRenderer;
