import { useCallback, useRef, useState } from 'react';

import useDebounce from './use-debounce';

interface MaxItemsParams {
    colGap?: number;
    debounceMs?: number;
    rowGap?: number;
}

function useMaxItems({
    colGap = 0,
    debounceMs = 300,
    rowGap = 0
}: MaxItemsParams = {}) {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const itemRef = useRef<HTMLDivElement | null>(null);
    const [gridSize, setGridSize] = useState({ cols: 1, rows: 1 });

    const debounce = useDebounce(debounceMs);

    const { current: update } = useRef(() => {
        debounce(() => {
            if (!containerRef.current || !itemRef.current) return;

            const { height: containerHeight, width: containerWidth } =
                containerRef.current.getBoundingClientRect();
            const { height: itemHeight, width: itemWidth } =
                itemRef.current.getBoundingClientRect();

            let cols = 1;
            let colSpace = itemWidth + colGap;
            while (colSpace + itemWidth <= containerWidth) {
                cols++;
                colSpace += itemWidth + colGap;
            }

            let rows = 1;
            let rowSpace = itemHeight + rowGap;
            while (rowSpace + itemHeight <= containerHeight) {
                rows++;
                rowSpace += itemHeight + rowGap;
            }

            setGridSize({ cols, rows });
        });
    });

    const { current: onResize } = useRef(() => {
        update();
    });

    const setContainerRef = useCallback(
        (ref: HTMLDivElement | null) => {
            containerRef.current = ref;
            update();
            if (ref === null) return;

            ref.removeEventListener('resize', onResize);
            ref.addEventListener('resize', onResize);

            window.removeEventListener('resize', onResize);
            window.addEventListener('resize', onResize);
        },
        [onResize, update]
    );

    const setItemRef = useCallback(
        (ref: HTMLDivElement | null) => {
            itemRef.current = ref;
            update();
        },
        [update]
    );

    return {
        cols: gridSize.cols,
        rows: gridSize.rows,
        setContainerRef,
        setItemRef
    };
}

export default useMaxItems;
