import { ReactNode, useCallback } from 'react';

import { SkeletonLine } from 'lib-ui';
import useAnimatedNumber from 'common/use-animated-number';

interface AgreementsMetricProps {
    extra?: ReactNode;
    loading?: boolean;
    name: string;
    value: number;
}

function AgreementsMetric({
    extra,
    loading,
    name,
    value
}: AgreementsMetricProps) {
    const { ref: valueElementRef, updateValue } =
        useAnimatedNumber<HTMLSpanElement>();

    const setValueElementRef = useCallback(
        (ref: HTMLSpanElement | null) => {
            valueElementRef.current = ref;
            if (ref === null) return;
            updateValue(value);
        },
        [updateValue, value, valueElementRef]
    );

    return (
        <div className="flex flex-col gap-2 px-2.5 mt-4 items-center">
            <div className="relative text-sm text-general-grey-grey-90 whitespace-nowrap">
                {!!loading && <SkeletonLine absolute />}
                {name}
            </div>
            <div className="relative py-0.5 flex items-center gap-1.5 text-3xl text-general-grey-grey-90 font-semibold">
                {!!loading && <SkeletonLine absolute />}
                <span ref={setValueElementRef}>0</span>
                {extra}
            </div>
        </div>
    );
}

export default AgreementsMetric;
export type { AgreementsMetricProps };
