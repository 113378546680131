import { PropsWithChildren, useCallback, useRef } from 'react';

function ScrollHarness({ children }: PropsWithChildren) {
    const container = useRef<HTMLDivElement | null>(null);
    const body = useRef<HTMLDivElement | null>(null);

    const refresh = useCallback(() => {
        if (!body.current || !container.current) return;

        body.current.style.width = `${
            container.current.getBoundingClientRect().width
        }px`;
    }, []);

    const setContainer = useCallback(
        (ref: HTMLDivElement | null) => {
            container.current = ref;

            refresh();

            if (ref) {
                ref.removeEventListener('resize', refresh);
                ref.addEventListener('resize', refresh);

                window.removeEventListener('resize', refresh);
                window.addEventListener('resize', refresh);
            }
        },
        [refresh]
    );

    const setBody = useCallback(
        (ref: HTMLDivElement | null) => {
            body.current = ref;

            refresh();
        },
        [refresh]
    );

    return (
        <div className="h-full overflow-hidden relative">
            <div ref={setContainer} className="absolute inset-0 overflow-auto">
                {/* Body */}
                <div ref={setBody} className="min-h-full relative">
                    {children}
                </div>
            </div>
        </div>
    );
}

export default ScrollHarness;
