function getPlotResolution(max: number, targetNumTicks: number) {
    // TODO instead of if else use closest (1, 2, 5) * 10^x where x is target gap
    // Decide on target base multiple for scale.
    const multipleOf = max <= 6 ? 1 : max <= 12 ? 2 : max <= 35 ? 5 : 10;

    // Given the target number of ticks calculate how many
    // of our multiples are needed per tick.
    const targetGap = max / targetNumTicks;
    const multiplesPerTick =
        targetGap > multipleOf ? Math.round(targetGap / multipleOf) : 1;

    const gap = multiplesPerTick * multipleOf;
    // Add 0 tick for 0 line,
    const numTicks = Math.ceil(max / gap) + 1;

    return { gap, numTicks };
}

export default getPlotResolution;
