import { CheckboxField, TextareaField } from 'lib-ui';
import { useTranslation } from 'react-i18next';

import FeedbackFormStep, { FeedbackFormStepProps } from './feedback-form-step';

type FieldValues = {
    improvements: string[];
    improvementsComment: string;
};

interface ImprovementsStepProps {
    defaultValues: FieldValues;
    onChange: FeedbackFormStepProps<FieldValues>['onChange'];
}

function ImprovementsStep({ defaultValues, onChange }: ImprovementsStepProps) {
    const { t } = useTranslation();

    return (
        <FeedbackFormStep<FieldValues>
            message={t('feedback-form.improvements-question')}
            title={t('feedback-form.improvements')}
            onChange={onChange}
            defaultValues={defaultValues}
        >
            <div className="grid grid-cols-2 gap-2.5">
                <CheckboxField
                    name="improvements"
                    value="deposit-now"
                    label={t('feedback-form.improvements-deposit-now')}
                />
                {/* <CheckboxField
                    name="improvements"
                    value="faqs"
                    label={t('feedback-form.improvements-faqs')}
                /> */}
                <CheckboxField
                    name="improvements"
                    value="home"
                    label={t('feedback-form.improvements-home')}
                />
                {/* <CheckboxField
                    name="improvements"
                    value="encryption"
                    label={t('feedback-form.improvements-encryption')}
                /> */}
                <CheckboxField
                    name="improvements"
                    value="agreements"
                    label={t('feedback-form.improvements-agreements')}
                />
                {/* <CheckboxField
                    name="improvements"
                    value="terms-and-conditions"
                    label={t('feedback-form.improvements-terms-and-conditions')}
                /> */}
                <CheckboxField
                    name="improvements"
                    value="source-control-connections"
                    label={t(
                        'feedback-form.improvements-source-control-connections'
                    )}
                />
                {/* <CheckboxField
                    name="improvements"
                    value="my-profile"
                    label={t('feedback-form.improvements-my-profile')}
                /> */}
                <CheckboxField
                    name="improvements"
                    value="repository-schedule"
                    label={t('feedback-form.improvements-repository-schedule')}
                />
                {/* <CheckboxField
                    name="improvements"
                    value="settings"
                    label={t('feedback-form.improvements-settings')}
                /> */}
                <CheckboxField
                    name="improvements"
                    value="contacts"
                    label={t('feedback-form.improvements-contacts')}
                />
                <CheckboxField
                    name="improvements"
                    value="none"
                    label={t('feedback-form.experience-none')}
                />
            </div>
            <TextareaField
                name="improvementsComment"
                placeholder={t('feedback-form.comment-placeholder')}
            />
        </FeedbackFormStep>
    );
}

export default ImprovementsStep;
export type { ImprovementsStepProps };
