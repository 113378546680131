import { ApiController } from '../../../client-portal/autogenerated/Api';
import { AxiosResponse } from 'axios';
import { MutationOptions, useMutation } from 'react-query';

import { UploadStartRequest } from 'core/api/client-portal/autogenerated/data-contracts';
import { useAuth } from 'react-oidc-context';
import interceptors from 'core/api/interceptors';
import securityWorker from 'core/api/security-worker';

type CustomFileParams = {
    depositId: number;
    fileHash?: string;
    fileId?: string;
    fileName: string;
    fileSize: number;
    lastModified?: number;
    uploadedById?: string;
};

function useStartFileUpload<TError = unknown, TContext = unknown>(
    options?: Omit<
        MutationOptions<
            AxiosResponse,
            ReactQueryError<TError>,
            CustomFileParams,
            TContext
        >,
        'mutationFn'
    >
) {
    const auth = useAuth();

    return useMutation(
        [
            'startUpload',
            { token: auth.user?.access_token, tokenType: auth.user?.token_type }
        ],
        async (file?: CustomFileParams) => {
            if (process.env.REACT_APP_API_MOCK) {
                console.warn(
                    'Mock is enabled useStartFileUpload call ignored.',
                    file
                );
                return Promise.resolve({} as AxiosResponse<void | unknown>);
            }

            const controller = new ApiController({
                interceptors,
                securityWorker: securityWorker(
                    auth.user?.token_type,
                    auth.user?.access_token
                )
            });

            //TODO: add Data Centre for VV
            // const storedValue = localStorage.getItem('selectedDataCentre');
            // const mockedDataCentre: DataCentreItem = {
            //     location: null,
            //     method: 'Upload',
            //     platform: 'uksouth',
            //     priorityOrder: 1,
            //     region: 'uksouth',
            //     status: 'uksouth'
            // };
            const mutatedObject: UploadStartRequest = {
                //TODO: add Data Centre
                // dataCenter:
                //     storedValue !== null
                //         ? JSON.parse(storedValue)
                //         : mockedDataCentre,
                fileName: file?.fileName,
                lastModified: file?.lastModified,
                size: file?.fileSize
            };

            const startFileUpload = await controller.startFileUpload(
                file!.depositId,
                mutatedObject,
                {}
            );

            return startFileUpload;
        },
        {
            ...options
        }
    );
}

export default useStartFileUpload;
export type { CustomFileParams };
