import {
    FileInput as CoreFileInput,
    FileInputProps as CoreFileInputProps,
    TailwindStyle
} from '@ncc-frontend/core';
import { forwardRef, useMemo } from 'react';
import { memo } from 'react';
import { merge } from 'lodash';
import { useTranslation } from 'react-i18next';

interface FileInputProps extends Omit<CoreFileInputProps, 'type'> {
    error?: boolean;
}

const FileInput = forwardRef<HTMLInputElement, FileInputProps>(
    ({ error, filledTailwindStyle, tailwindStyle, ...restProps }, ref) => {
        const { t } = useTranslation();

        const mergedTailwindStyle = useMemo<TailwindStyle>(
            () =>
                merge<TailwindStyle, TailwindStyle | undefined>(
                    {
                        background: 'bg-brand-escode-neonblue-neonblue-10',
                        borderColor: error
                            ? 'border-general-red-red-100'
                            : 'border-brand-escode-neonblue-neonblue-100',
                        textColor: 'text-brand-escode-neonblue-neonblue-100',
                        variant: {
                            'focus-within': {
                                borderColor:
                                    !error &&
                                    'focus-within:border-brand-escode-neonblue-neonblue-100'
                            }
                        }
                    },
                    tailwindStyle
                ),
            [error, tailwindStyle]
        );

        const mergedFilledTailwindStyle = useMemo<TailwindStyle>(
            () =>
                merge<TailwindStyle, TailwindStyle | undefined>(
                    {
                        background: 'bg-brand-escode-neonblue-neonblue-100',
                        textColor: 'text-ncc-white'
                    },
                    filledTailwindStyle
                ),
            [filledTailwindStyle]
        );

        const translations = useMemo<CoreFileInputProps['translations']>(
            () => ({
                close: '',
                placeholder: t('file-input.placeholder'),
                title: ''
            }),
            [t]
        );

        return (
            <CoreFileInput
                {...restProps}
                ref={ref}
                tailwindStyle={mergedTailwindStyle}
                filledTailwindStyle={mergedFilledTailwindStyle}
                translations={translations}
            />
        );
    }
);

export default memo(FileInput);
export type { FileInputProps };
