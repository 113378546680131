import { FilterData } from '../../data-widget-types';
import { ForwardedRef, ReactElement, forwardRef, useCallback } from 'react';
import { useDropdown } from '@ncc-frontend/core';
import { useTranslation } from 'react-i18next';

import Button from '../../../../button/button';
import FilterField, { FilterFieldProps } from './filter-field';
import FilterStep from '../filter-step/filter-step';
import useDataWidget from '../../use-data-widget';

interface FilterOverviewStepProps {
    filters: FilterData[];
    isLoadingSavedFilters?: boolean;
    onClearFilters: () => void;
    onFieldSelected: NonNullable<FilterFieldProps['onSelect']>;
    onSaveFilters: () => void;
    onViewSavedFilters: () => void;
    savedFilters: Record<string, FilterData[]> | undefined;
}

function FilterOverviewStep(
    {
        filters,
        isLoadingSavedFilters = false,
        onClearFilters,
        onFieldSelected,
        onSaveFilters,
        onViewSavedFilters,
        savedFilters
    }: FilterOverviewStepProps,
    ref: ForwardedRef<HTMLDivElement>
): ReactElement | null {
    const { t } = useTranslation();
    const { close } = useDropdown();
    const {
        filterableFields,
        hiddenFields,
        onApplyFilters,
        suppressSaveFilter
    } = useDataWidget();

    const handleApplyFilters = useCallback(() => {
        onApplyFilters!(filters);
        close();
    }, [close, filters, onApplyFilters]);

    return (
        <FilterStep ref={ref}>
            <FilterStep.Header suppressBackIcon>
                {filters.filter((item) => !!item.model).length === 0
                    ? t('filters.title-empty')
                    : t('filters.title-selected', {
                          count: filters.length
                      })}
            </FilterStep.Header>
            <FilterStep.Body className="flex flex-col gap-2.5">
                {filterableFields!.map((field) => (
                    <FilterField
                        key={field.colId}
                        onSelect={onFieldSelected}
                        data={field}
                        active={filters.some(
                            (item) => item.colId === field.colId && !!item.model
                        )}
                        columnHidden={hiddenFields[field.colId]}
                    />
                ))}
            </FilterStep.Body>
            <FilterStep.Footer className="grid grid-cols-2 gap-2.5">
                {!suppressSaveFilter && (
                    <Button
                        variant="tertiary"
                        disabled={
                            Object.entries(savedFilters ?? {}).length === 0
                        }
                        className="col-span-2"
                        onClick={onViewSavedFilters}
                        loading={isLoadingSavedFilters}
                    >
                        {t('filters.view-saved')}
                    </Button>
                )}
                {!suppressSaveFilter && (
                    <Button
                        variant="tertiary"
                        disabled={filters.length === 0}
                        onClick={onSaveFilters}
                    >
                        {t('filters.save-filter')}
                    </Button>
                )}
                <Button
                    variant="danger"
                    disabled={filters.length === 0}
                    onClick={onClearFilters}
                    className={suppressSaveFilter ? 'col-span-2' : undefined}
                >
                    {t('filters.clear')}
                </Button>
                <Button
                    variant="primary"
                    className="col-span-2"
                    onClick={handleApplyFilters}
                >
                    {t('filters.apply')}
                </Button>
            </FilterStep.Footer>
        </FilterStep>
    );
}

export default forwardRef(FilterOverviewStep);
export type { FilterOverviewStepProps };
