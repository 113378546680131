import { CheckboxField, TextareaField } from 'lib-ui';
import { useTranslation } from 'react-i18next';

import FeedbackFormStep, { FeedbackFormStepProps } from './feedback-form-step';

type FieldValues = {
    experience: string[];
    experienceComment: string;
};

interface ExperienceStepProps {
    defaultValues: FieldValues;
    onChange: FeedbackFormStepProps<FieldValues>['onChange'];
}

function ExperienceStep({ defaultValues, onChange }: ExperienceStepProps) {
    const { t } = useTranslation();

    return (
        <FeedbackFormStep<FieldValues>
            message={t('feedback-form.experience-question')}
            title={t('feedback-form.experience')}
            onChange={onChange}
            defaultValues={defaultValues}
        >
            <div className="grid grid-cols-2 gap-2.5">
                <CheckboxField
                    name="experience"
                    value="time-to-deposit"
                    label={t('feedback-form.experience-time-to-deposit')}
                />
                <CheckboxField
                    name="experience"
                    value="design"
                    label={t('feedback-form.experience-design')}
                />
                <CheckboxField
                    name="experience"
                    value="reports"
                    label={t('feedback-form.experience-reports')}
                />
                <CheckboxField
                    name="experience"
                    value="localization"
                    label={t('feedback-form.experience-localization')}
                />
                <CheckboxField
                    name="experience"
                    value="source-control-connection"
                    label={t(
                        'feedback-form.experience-source-control-connection'
                    )}
                />
                <CheckboxField
                    name="experience"
                    value="workflow"
                    label={t('feedback-form.experience-workflow')}
                />
                <CheckboxField
                    name="experience"
                    value="visualization-of-functionality"
                    label={t(
                        'feedback-form.experience-visualization-of-functionality'
                    )}
                />
                <CheckboxField
                    name="experience"
                    value="support-access"
                    label={t('feedback-form.experience-support-access')}
                />
                <CheckboxField
                    name="experience"
                    value="search-filter"
                    label={t('feedback-form.experience-search-filter')}
                />
                <CheckboxField
                    name="experience"
                    value="navigation"
                    label={t('feedback-form.experience-navigation')}
                />
                <CheckboxField
                    name="experience"
                    value="none"
                    label={t('feedback-form.experience-none')}
                />
            </div>
            <TextareaField
                name="experienceComment"
                placeholder={t('feedback-form.comment-placeholder')}
            />
        </FeedbackFormStep>
    );
}

export default ExperienceStep;
export type { ExperienceStepProps };
