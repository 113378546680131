import {
    MouseEventHandler,
    PropsWithChildren,
    SyntheticEvent,
    useCallback
} from 'react';

interface SelectOptionProps {
    onClick: (value: string, event: SyntheticEvent<HTMLDivElement>) => void;
    value: string;
}

function SelectOption({
    children,
    onClick,
    value
}: PropsWithChildren<SelectOptionProps>) {
    const handleClick = useCallback<MouseEventHandler<HTMLDivElement>>(
        (event) => {
            onClick(value, event);
        },
        [onClick, value]
    );

    return (
        <div
            className="hover:bg-general-grey-grey-10 py-1 px-3 rounded-lg cursor-pointer"
            onClick={handleClick}
        >
            {children}
        </div>
    );
}

export default SelectOption;
export type { SelectOptionProps };
