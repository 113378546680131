import { AgreementListResponseViewModel } from 'core/api/client-portal/autogenerated/data-contracts';
import { Button, SkeletonLine } from 'lib-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightToLine } from '@fortawesome/pro-regular-svg-icons';
import { useCallback } from 'react';
import { useModals } from '@ncc-frontend/core';
import { useTranslation } from 'react-i18next';

import { getAgreementDepositStatus } from 'core/api/client-portal/model/agreement-deposit-status';
import DashboardWidget, { DashboardWidgetProps } from '../dashboard-widget';
import HighlightAgreementGridItem from './highlight-agreement-grid-item';
import HighlightAgreementsModal from './highlight-agreements-modal';
import useAgreementsList from 'core/api/client-portal/hooks/agreements/use-agreements-list';
import useAnimatedNumber from 'common/use-animated-number';
import useMaxItems from 'common/use-max-items';

function getHighLightAgreements(
    data: AgreementListResponseViewModel | undefined
) {
    return data?.agreements?.filter(
        (item) =>
            !(['verified', 'standard'] as (string | undefined)[]).includes(
                getAgreementDepositStatus(item)
            )
    );
}

interface HighlightAgreementsWidgetProps
    extends Omit<DashboardWidgetProps, 'loading'> {}

function HighlightAgreementsWidget({
    ...restProps
}: HighlightAgreementsWidgetProps) {
    const { t } = useTranslation();
    const { push } = useModals();

    const { ref: countElementRef, updateValue } =
        useAnimatedNumber<HTMLDivElement>();
    const { cols, rows, setContainerRef, setItemRef } = useMaxItems({
        colGap: 44,
        rowGap: 20
    });

    const {
        data: agreements,
        isFetching,
        isLoading
    } = useAgreementsList(
        {
            IncludeRestrictedAgreements: true
        },
        {
            onSettled: (data) => {
                updateValue(getHighLightAgreements(data)?.length ?? 0);
            }
        }
    );
    const loading = isFetching || isLoading;

    const highlightAgreements = getHighLightAgreements(agreements);

    const setCountRef = useCallback(
        (ref: HTMLDivElement | null) => {
            countElementRef.current = ref;
            if (ref === null) return;
            updateValue(highlightAgreements?.length ?? 0);
        },
        [countElementRef, highlightAgreements?.length, updateValue]
    );

    const viewAll = useCallback(() => {
        if (highlightAgreements) {
            push(HighlightAgreementsModal, { data: highlightAgreements });
        }
    }, [highlightAgreements, push]);

    return (
        <DashboardWidget {...restProps}>
            <DashboardWidget.Header
                title={t('dashboard.highlight-agreements-widget.title')}
                tailwindStyle={{ textColor: 'text-general-red-red-100' }}
                loading={loading}
            >
                <Button
                    onClick={viewAll}
                    icon={<FontAwesomeIcon icon={faArrowRightToLine} />}
                    size="s"
                    iconPosition="right"
                    className="ml-auto"
                >
                    {t('dashboard.highlight-agreements-widget.see-all')}
                </Button>
            </DashboardWidget.Header>
            <DashboardWidget.Body className="flex justify-between items-stretch gap-12">
                <div className="relative flex flex-col gap-4 px-2.5 max-w-60">
                    {!!loading && <SkeletonLine absolute />}
                    <div className="text-sm text-general-grey-grey-90">
                        {t('dashboard.highlight-agreements-widget.description')}
                    </div>
                    <div
                        ref={setCountRef}
                        className="text-4xl text-general-grey-grey-90 font-medium"
                    >
                        0
                    </div>
                </div>
                <div
                    ref={setContainerRef}
                    className="flex-1 grid grid-cols-2 grid-rows-2 gap-x-11 gap-y-5 grid-flow-col items-center justify-items-start"
                    style={{
                        direction: 'rtl',
                        gridTemplateColumns: `repeat(${cols}, minmax(0,1fr))`,
                        gridTemplateRows: `repeat(${rows}, minmax(0,1fr))`
                    }}
                >
                    {!!loading &&
                        [...new Array(cols * rows)].map((_, index) => (
                            <HighlightAgreementGridItem
                                key={index}
                                ref={index === 0 ? setItemRef : undefined}
                                loading
                            />
                        ))}
                    {!loading &&
                        highlightAgreements
                            ?.slice(0, cols * rows)
                            .reverse()
                            .map((item, index) => (
                                <HighlightAgreementGridItem
                                    key={item.agreementId}
                                    ref={index === 0 ? setItemRef : undefined}
                                    agreementNumber={item.agreementNumber}
                                    agreementId={item.agreementId}
                                    viewingPartyCanCreateDeposit={
                                        item.viewingPartyCanCreateDeposit
                                    }
                                />
                            ))}
                </div>
            </DashboardWidget.Body>
        </DashboardWidget>
    );
}

export default HighlightAgreementsWidget;
export type { HighlightAgreementsWidgetProps };
