import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { ComponentType, createRef, useCallback, useState } from 'react';
import { TailwindProps, tailwindClasses } from '@ncc-frontend/core';
import classNames from 'classnames';

import useInterval from 'common/use-interval';

import './carousel.scss';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type CarouselOption<TProps = any> = {
    component: ComponentType<TProps>;
    props?: TProps;
};

interface CarouselProps extends TailwindProps {
    options: CarouselOption[];
}

function Carousel({
    className,
    options,
    tailwindStyle,
    ...restProps
}: CarouselProps) {
    const [activeIndex, setActiveIndex] = useState(0);
    const { reset } = useInterval(() => {
        setActiveIndex((prev) => {
            let next = prev + 1;
            if (next >= options.length) next = 0;
            return next;
        });
    }, 30000);

    const componentRef = createRef<HTMLDivElement>();
    const Component = options[activeIndex].component;

    const handleClick = useCallback(
        (index: number) => {
            setActiveIndex(index);
            reset();
        },
        [reset]
    );

    return (
        <>
            <TransitionGroup
                {...restProps}
                className={classNames(
                    'relative',
                    tailwindClasses(tailwindStyle),
                    className
                )}
            >
                <CSSTransition
                    id={activeIndex}
                    key={activeIndex}
                    nodeRef={componentRef}
                    timeout={500}
                    classNames="carousel__step"
                >
                    {
                        <Component
                            {...options[activeIndex].props}
                            ref={componentRef}
                            className="absolute inset-0 carousel__step"
                        />
                    }
                </CSSTransition>
                <div className="absolute bottom-2.5 left-1/2 -translate-x-1/2 flex gap-2.5">
                    {options.map((_, index) => (
                        <div
                            key={index}
                            data-testid={`button-${index}`}
                            className={classNames(
                                'h-4 w-4 rounded-full border-2 border-ncc-white transition-colors',
                                {
                                    'bg-ncc-white': index === activeIndex
                                }
                            )}
                            onClick={() => handleClick(index)}
                        />
                    ))}
                </div>
            </TransitionGroup>
        </>
    );
}

export default Carousel;
export type { CarouselOption, CarouselProps };
