import { DepositItem } from 'core/api/client-portal/autogenerated/data-contracts';
import { ICellRendererParams } from 'lib-ui';
import { memo } from 'react';

function DepositNameCellRenderer({ data }: ICellRendererParams<DepositItem>) {
    if (data.versionOrReleaseNumber === '') {
        return (
            <div
                className="flex h-full items-center"
                data-testid="deposit-name-container"
                title={`${data.softwarePackageName}`}
            >
                {data.softwarePackageName}
            </div>
        );
    } else {
        return (
            <div
                className="flex h-full items-center"
                data-testid="deposit-name-container"
                title={`${data.softwarePackageName} (${data.versionOrReleaseNumber})`}
            >
                {data.softwarePackageName} ({data.versionOrReleaseNumber})
            </div>
        );
    }
}

export default memo(DepositNameCellRenderer);
