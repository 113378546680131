import DepositNowContainer from '../../ui/deposit-now/deposit-now-container';
import DisclaimerStep from 'ui/deposit-now/disclaimer-step/disclaimer-step';

const DepositNowDisclaimer = () => {
    return (
        <DepositNowContainer step="disclaimer">
            <DisclaimerStep />
        </DepositNowContainer>
    );
};

export default DepositNowDisclaimer;
