import DepositDetailsStep from '../../ui/deposit-now/deposit-details-step/deposit-details-step';
import DepositNowContainer from '../../ui/deposit-now/deposit-now-container';

const DepositNowDepositDetails = () => {
    return (
        <DepositNowContainer step="deposit-details">
            <DepositDetailsStep />
        </DepositNowContainer>
    );
};

export default DepositNowDepositDetails;
