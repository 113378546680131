import { ApiController } from '../../autogenerated/Api';
import { AxiosResponse } from 'axios';
import { MutationOptions, useMutation } from 'react-query';
import { useAuth } from 'react-oidc-context';
import { useEffect, useRef } from 'react';
import CryptoJS from 'crypto-js';

interface ChunkUploadParams {
    dataCentre: 'NCC:UK' | 'NCC:US';
    depositUuid: string;
    file: File;
    uniqueReference: string;
}
interface MutationResponse {
    axiosResponse: AxiosResponse<void, unknown>;
    combinedHash: string;
}

function useChunkUpload<TError = unknown, TContext = unknown>(
    options?: Omit<
        MutationOptions<
            MutationResponse,
            ReactQueryError<TError>,
            ChunkUploadParams,
            TContext
        >,
        'mutationFn'
    > & {
        onUploadProgress?: (percentCompleted: number) => void;
    }
) {
    const auth = useAuth();
    const isCancelledRef = useRef(false);
    const tokenRef = useRef(auth.user?.access_token);

    const cancelUpload = () => {
        isCancelledRef.current = true;
    };
    useEffect(() => {
        tokenRef.current = auth.user?.access_token;
    }, [auth.user?.access_token]);

    const { mutate: chunkUploadFn } = useMutation<
        MutationResponse,
        ReactQueryError<TError>,
        ChunkUploadParams,
        TContext
    >(
        [
            'chunkUpload',
            {
                token: auth.user?.access_token,
                tokenType: auth.user?.token_type
            }
        ],

        async ({
            dataCentre,
            depositUuid,
            file,
            uniqueReference
        }): Promise<MutationResponse> => {
            if (process.env.REACT_APP_API_MOCK) {
                console.warn('Mock is enabled. Chunk upload call ignored.', {
                    file
                });

                return Promise.resolve({
                    axiosResponse: {} as AxiosResponse<void, unknown>,
                    combinedHash: 'mocked-hash'
                });
            }

            const baseURL =
                dataCentre === 'NCC:US'
                    ? process.env.REACT_APP_US_UPLOADER_ENDPOINT
                    : process.env.REACT_APP_UK_UPLOADER_ENDPOINT;

            const CHUNK_SIZE = 4 * 1024 * 1024; // 4 MB
            const totalFileSize = file.size;
            const totalParts = Math.ceil(totalFileSize / CHUNK_SIZE);
            const combinedHash = CryptoJS.algo.MD5.create();

            const controller = new ApiController({
                baseURL
            });

            for (let partIndex = 0; partIndex < totalParts; partIndex++) {
                if (isCancelledRef.current) {
                    throw new Error('Upload cancelled by user');
                }

                const startByte = partIndex * CHUNK_SIZE;
                const endByte = Math.min(
                    (partIndex + 1) * CHUNK_SIZE,
                    totalFileSize
                );
                const chunk = file.slice(startByte, endByte);
                const buffer = await chunk.arrayBuffer();
                const wordArray = CryptoJS.lib.WordArray.create(buffer);
                combinedHash.update(wordArray);
                const currentToken = tokenRef.current;

                await controller.chunkUpload(
                    depositUuid,
                    {
                        qqchunksize: endByte - startByte,
                        qqfile: new File([chunk], file.name, {
                            lastModified: file.lastModified,
                            type: file.type
                        }),
                        qqfilename: file.name,
                        qqpartbyteoffset: startByte,
                        qqpartindex: partIndex,
                        qqtotalfilesize: totalFileSize,
                        qqtotalparts: totalParts,
                        qquuid: uniqueReference
                    },
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Expires: '0',
                            Pragma: 'no-cache',
                            authorization: `${auth.user?.token_type} ${currentToken}` // if token is refreshed during upload, it will be updated in the interceptor
                        }
                    }
                );

                const percentCompleted = Math.round(
                    ((partIndex + 1) / totalParts) * 100
                );
                options?.onUploadProgress?.(percentCompleted);
            }
            const finalHash = combinedHash
                .finalize()
                .toString(CryptoJS.enc.Hex)
                .toUpperCase();

            return Promise.resolve({
                axiosResponse: {} as AxiosResponse<void, unknown>,
                combinedHash: finalHash
            });
        },
        {
            ...options
        }
    );

    return { cancelUpload, chunkUploadFn };
}

export default useChunkUpload;
