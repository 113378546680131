import { ContactViewModel } from 'core/api/client-portal/autogenerated/data-contracts';
import { ICellRendererParams } from 'lib-ui';
import { memo } from 'react';

import { ContactsTableContext } from './contacts-table-context';
import ContactAction from './contact-action';

function ContactActionsCellRenderer({
    data
}: ICellRendererParams<ContactViewModel, ContactsTableContext>) {
    return (
        <div className="flex h-full items-center">
            <ContactAction data={data} />
        </div>
    );
}

export default memo(ContactActionsCellRenderer);
