import { Button } from 'lib-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { faGear } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import './working-on.scss';

function WorkingOn() {
    const { t } = useTranslation();

    return (
        <div className="w-full h-full bg-gradient-to-r from-escode-overlay-texture-light to-escode-overlay-texture-dark rounded-2xl">
            <div className="texture-overlay w-full h-full flex flex-col gap-8 items-center justify-center text-general-grey-grey-10">
                <FontAwesomeIcon icon={faGear} className="text-9xl" />
                <div className="flex flex-col gap-9 max-w-xl items-center">
                    <div className="text-5xl text-general-white-white-100 font-semibold">
                        {t('working-on.title')}
                    </div>
                    <div className="text-2xl text-center font-normal">
                        {t('working-on.description')}
                    </div>
                    <NavLink to="/">
                        <Button
                            className="self-start"
                            icon={<FontAwesomeIcon icon={faArrowLeft} />}
                        >
                            {t('button.back-to-dashboard')}
                        </Button>
                    </NavLink>
                </div>
            </div>
        </div>
    );
}

export default WorkingOn;
