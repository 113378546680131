import { DownloadScheduleRepo } from 'core/api/client-portal/autogenerated/data-contracts';
import { useTranslation } from 'react-i18next';
import AutomateFutureDeposits from './automate-future-deposits';
import ControlConnections from './control-connections';

type SourceControlDepositProps = {
    automate: string;
    connectionsAdded: DownloadScheduleRepo[];
};
const SourceControlDeposits = ({
    automate,
    connectionsAdded
}: SourceControlDepositProps) => {
    const { t } = useTranslation();

    return (
        <section
            className="flex w-full gap-12 pt-5"
            data-testid="layout-section"
        >
            <div className="flex w-auto  flex-col">
                <ControlConnections connectionsAdded={connectionsAdded} />
            </div>
            <div className="flex min-w-72 flex-col">
                <div className="rounded-lg border border-escode-grey-30 p-5 ">
                    <h2 className="text-md font-medium">
                        {t('source-control.automate')}
                    </h2>
                    <AutomateFutureDeposits isAutomated={automate === 'true'} />
                </div>
            </div>
        </section>
    );
};

export default SourceControlDeposits;
