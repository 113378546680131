import { ReactNode, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { Switch } from 'lib-ui';
import ConfirmationTooltip, {
    ConfirmationTooltipEventHandler
} from '../../tooltip/confirmation-tooltip';
import Field, { FieldProps } from './field';

interface ToggleFieldProps extends FieldProps {
    warning?: boolean;
    warningConfirmMessage?: string;
    warningIcon?: ReactNode;
    warningMessage?: ReactNode;
    warningRejectMessage?: string;
    warningTitle?: ReactNode;
}

function ToggleField({
    label,
    name,
    orientation,
    warning,
    warningConfirmMessage,
    warningIcon,
    warningMessage,
    warningRejectMessage,
    warningTitle
}: ToggleFieldProps) {
    const { control, register, setValue, watch } = useFormContext();
    const checked = watch(name, true);

    const handleConfirm = useCallback<ConfirmationTooltipEventHandler>(() => {
        setValue(name, !checked);
    }, [checked, name, setValue]);

    return (
        <Field name={name} label={label} orientation={orientation} as="div">
            <ConfirmationTooltip
                variant="warning"
                suppressConfirmation={!warning || !checked}
                confirmMessage={warningConfirmMessage}
                icon={warningIcon}
                message={warningMessage}
                rejectMessage={warningRejectMessage}
                title={warningTitle}
                onConfirm={handleConfirm}
            >
                <div className="relative">
                    <Switch
                        {...register(name)}
                        control={control}
                        dataTestId={`toggle-${name}`}
                        defaultValue={false}
                    />
                    {/* Workaround to control an input from the tooltip confirm. We need to block clicks from getting
                    to the input. */}
                    <div className="absolute top-0 left-0 right-0 bottom-0" />
                </div>
            </ConfirmationTooltip>
        </Field>
    );
}

export default ToggleField;
export type { ToggleFieldProps };
