import { AgreementPartiesTableContext } from './agreement-parties-table-context';
import { AgreementPartyViewModel } from 'core/api/client-portal/autogenerated/data-contracts';
import { Button, ICellRendererParams } from 'lib-ui';
import { isUndefined } from 'lodash';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import useAgreementsContractDownload from 'core/api/client-portal/hooks/contracts/use-agreements-contract-download';
function AgreementPartyContractCellRenderer({
    context: { contracts, t },
    data
}: ICellRendererParams<AgreementPartyViewModel, AgreementPartiesTableContext>) {
    const { agreementNumber, id } = useParams();
    const contract =
        (!!data.registeredName &&
            contracts?.items?.find(
                (item) =>
                    (item.slxType?.indexOf(data.registeredName as string) ||
                        -1) > -1
            )) ||
        undefined;
    const agreementId = Number(id);
    const contractIndex = contract?.index || '';

    const {
        isLoading: isLoadingContractDownload,
        mutate: requestContractDownload
    } = useAgreementsContractDownload();

    const downloadContract = useCallback(() => {
        if (!contractIndex) return;

        requestContractDownload({
            agreementId,
            agreementNumber,
            index: contractIndex
        });
    }, [agreementId, agreementNumber, contractIndex, requestContractDownload]);

    return isUndefined(contract) ? (
        <>{t('agreement-parties.contract.unavailable')}</>
    ) : (
        <Button onClick={downloadContract} loading={isLoadingContractDownload}>
            {t('agreement-parties.contract.download') as string}
        </Button>
    );
}

export default AgreementPartyContractCellRenderer;
