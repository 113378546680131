import { DepositItem } from 'core/api/client-portal/autogenerated/data-contracts';
import { DepositsTableContext } from './deposits-table-context';
import { ICellRendererParams, ValueGetterParams } from 'lib-ui';
import { memo } from 'react';

import DepositStatusTag from './deposit-status-tag';

function depositStatusValueGetter({
    context: { beautifyDepositStatus },
    data
}: ValueGetterParams<DepositItem, DepositsTableContext>) {
    return beautifyDepositStatus(data.escrowDepositStatus);
}

function DepositStatusCellRenderer({
    data,
    value
}: ICellRendererParams<DepositItem>) {
    return (
        value && (
            <div className="flex h-full items-center">
                <DepositStatusTag value={data.escrowDepositStatus}>
                    {value}
                </DepositStatusTag>
            </div>
        )
    );
}

export default memo(DepositStatusCellRenderer);
export { depositStatusValueGetter };
