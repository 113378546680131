import { PropsWithChildren } from 'react';
import BetaHoldingBanner from 'app-layout/beta-holding-banner';
import classNames from 'classnames';

interface AppBodyProps {
    className?: string;
    suppressBanner?: boolean;
    suppressPadding?: boolean;
}

function AppBody({
    children,
    className,
    suppressBanner = false,
    suppressPadding = false
}: PropsWithChildren<AppBodyProps>) {
    const cssClasses = classNames(
        'absolute left-0 right-0 top-0 bottom-0 overflow-auto',
        {
            'px-escode-body-padding-x py-escode-body-padding-y':
                !suppressPadding
        },
        className
    );
    return (
        <div className={cssClasses}>
            {!suppressBanner && <BetaHoldingBanner></BetaHoldingBanner>}
            {children}
        </div>
    );
}

export default AppBody;
