import { ContactViewModel } from '../autogenerated/data-contracts';

type ContactStatus = 'enabled' | 'disabled' | 'pending' | 'invite';

function getContactStatus(contact: ContactViewModel): ContactStatus {
    return contact.userName && contact.userIsEnabled
        ? 'enabled'
        : contact.inviteEmailSent &&
          contact.userName === null &&
          !contact.userIsEnabled
        ? 'pending'
        : !contact.inviteEmailSent &&
          !contact.userIsEnabled &&
          contact.userName === null
        ? 'invite'
        : 'disabled';
}

export default getContactStatus;
export type { ContactStatus };
