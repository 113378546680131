import { Button, InputField, Modal } from 'lib-ui';
import { ContactViewModel } from 'core/api/client-portal/autogenerated/data-contracts';
import { FormProvider, useForm } from 'react-hook-form';
import { ReactElement, useCallback } from 'react';
import { useModals } from '@ncc-frontend/core';
import { useTranslation } from 'react-i18next';

import ContactAction from '../contact-action';
import ContactStatusTag from '../contact-status-tag';
import ContactsAuditHistoryModal from 'ui/contacts-audit-history-modal/contacts-audit-history-modal';
import getContactStatus from 'core/api/client-portal/model/contact-status';

interface FieldValues {
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    email: string;
    firstName: string;
    jobTitle: string;
    lastName: string;
    postcode: string;
    telephone: string;
    title: string;
    userName: string;
}

interface ContactDetailsProps {
    data: ContactViewModel;
}

function ContactDetailsModal({
    data
}: ContactDetailsProps): ReactElement | null {
    const { t } = useTranslation();
    const { push } = useModals();
    const methods = useForm<FieldValues>({
        defaultValues: {
            addressLine1: data.addressLine1 ?? '',
            addressLine2: data.addressLine2 ?? '',
            addressLine3: data.addressLine3 ?? '',
            email: data.email ?? '',
            firstName: data.firstName ?? '',
            jobTitle: data.jobTitle ?? '',
            lastName: data.lastName ?? '',
            postcode: data.postcode ?? '',
            telephone: data.telephone ?? '',
            title: data.title ?? '',
            userName: data.userName ?? ''
        }
    });

    const status = getContactStatus(data);

    const viewAuditHistory = useCallback(() => {
        push(ContactsAuditHistoryModal, {});
    }, [push]);

    return (
        <Modal size="m">
            <Modal.Header className="font-semibold">
                {data.userName}
                <ContactStatusTag status={status}>
                    {t(`contacts.status-${status}`)}
                </ContactStatusTag>
            </Modal.Header>
            <Modal.Body className="flex flex-col items-center gap-2.5 text-sm">
                <FormProvider<FieldValues> {...methods}>
                    <form className="grid grid-cols-2 gap-y-2.5 gap-x-6 w-full">
                        <InputField
                            name="title"
                            label={t('contacts.details.title')}
                            readOnly
                        />
                        <InputField
                            name="firstName"
                            label={t('contacts.details.first-name')}
                            readOnly
                        />
                        <InputField
                            name="lastName"
                            label={t('contacts.details.last-name')}
                            readOnly
                        />
                        <InputField
                            name="userName"
                            label={t('contacts.details.username')}
                            readOnly
                        />
                        <InputField
                            name="jobTitle"
                            label={t('contacts.details.job-title')}
                            readOnly
                        />
                        <InputField
                            name="addressLine1"
                            label={t('contacts.details.address-1')}
                            readOnly
                        />
                        <InputField
                            name="addressLine2"
                            label={t('contacts.details.address-2')}
                            readOnly
                        />
                        <InputField
                            name="addressLine3"
                            label={t('contacts.details.address-3')}
                            readOnly
                        />
                        <InputField
                            name="postcode"
                            label={t('contacts.details.postcode')}
                            readOnly
                        />
                        <InputField
                            name="telephone"
                            label={t('contacts.details.telephone')}
                            readOnly
                        />
                        <InputField
                            name="email"
                            label={t('contacts.details.email')}
                            readOnly
                        />
                    </form>
                </FormProvider>
                <div className="flex justify-between gap-2 w-full">
                    <ContactAction data={data} variant="primary" />
                    <Button onClick={viewAuditHistory}>
                        {t('contacts.audit-history')}
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ContactDetailsModal;
