import { CommonProps } from '@ncc-frontend/core';
import { PropsWithChildren } from 'react';
import classNames from 'classnames';

interface FilterStepNestedProps extends CommonProps {}

function FilterStepNested({
    children,
    className,
    ...restProps
}: PropsWithChildren<FilterStepNestedProps>) {
    const cssClasses = classNames(
        // Workaround to have new steps relative to bottom of the step it self.
        // negative margins offset step paddings and gap.
        'relative -ml-3 -mr-3 -mt-4',
        className
    );

    return (
        <div className={cssClasses} {...restProps}>
            {children}
        </div>
    );
}

export default FilterStepNested;
export type { FilterStepNestedProps };
