import { AgreementViewModel } from 'core/api/client-portal/autogenerated/data-contracts';
import { ICellRendererParams, Typography } from 'lib-ui';
import { NavLink } from 'react-router-dom';
import { memo } from 'react';

function AgreementLinkCellRenderer({
    data,
    value
}: ICellRendererParams<AgreementViewModel>) {
    return (
        <NavLink
            data-testid="link"
            to={`/agreements/${data.agreementId}/${data.agreementNumber}`}
        >
            <Typography.Link>{value}</Typography.Link>
        </NavLink>
    );
}

export default memo(AgreementLinkCellRenderer);
