import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as MercurialIcon } from 'assets/icons/mercurial.svg';
import { SelectFieldProps } from 'lib-ui';
import { SourceCodeProviderType } from 'core/api/client-portal/autogenerated/data-contracts';
import { ReactComponent as SvnIcon } from 'assets/icons/svn.svg';
import { faGithub } from '@fortawesome/free-brands-svg-icons';

const providerTypeOptions: SelectFieldProps['options'] = [
    {
        label: (
            <div>
                <FontAwesomeIcon icon={faGithub} /> Git
            </div>
        ),
        value: SourceCodeProviderType.Value1.toString()
    },
    {
        label: (
            <div>
                <MercurialIcon className="svg-inline--fa" role="img" />{' '}
                Mercurial
            </div>
        ),
        value: SourceCodeProviderType.Value2.toString()
    },
    {
        label: (
            <div>
                <SvnIcon className="svg-inline--fa" role="img" /> SVN
            </div>
        ),
        value: SourceCodeProviderType.Value3.toString()
    }
];

export default providerTypeOptions;
