import { Dropdown, useDropdown } from '@ncc-frontend/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faColumns3 } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';

import DataHeaderButton from '../data-header-button/data-header-button';
import FieldsSelectionPanel from './fields-selection-panel';
import useDataWidget from '../use-data-widget';

interface SelectFieldsButtonProps {}

function SelectFieldsButton({}: SelectFieldsButtonProps) {
    const { t } = useTranslation();
    const { openPanelId } = useDropdown();
    const { loading } = useDataWidget();

    const panelId = 'select-fields';

    return (
        <Dropdown.Trigger
            panelId={panelId}
            panel={<FieldsSelectionPanel />}
            disabled={loading}
        >
            <DataHeaderButton
                icon={<FontAwesomeIcon icon={faColumns3} />}
                active={openPanelId === panelId}
                disabled={loading}
            >
                {t('table.columns')}
            </DataHeaderButton>
        </Dropdown.Trigger>
    );
}

export default SelectFieldsButton;
export type { SelectFieldsButtonProps };
