import {
    Tag as CoreTag,
    TagProps as CoreTagProps,
    TailwindStyle
} from '@ncc-frontend/core';
import { PropsWithChildren, useMemo } from 'react';
import { merge } from 'lodash';

interface TagProps extends CoreTagProps {
    size?: 's' | 'm';
}

function Tag({
    children,
    size = 'm',
    tailwindStyle,
    ...restProps
}: PropsWithChildren<TagProps>) {
    const mergedTailwindStyle = useMemo(
        () =>
            merge<TailwindStyle, TailwindStyle | undefined>(
                {
                    borderRadius: 'rounded-full',
                    padding: 'px-2.25 py-1',
                    textSize: size === 's' ? 'text-xs' : 'text-base',
                    textWeight: 'font-medium'
                },
                tailwindStyle
            ),
        [size, tailwindStyle]
    );

    return (
        <CoreTag {...restProps} tailwindStyle={mergedTailwindStyle}>
            {children}
        </CoreTag>
    );
}

export default Tag;
export type { TagProps };
