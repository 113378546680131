import {
    DepositSortColumn,
    DepositTypesFilter,
    GetDepositsResponse
} from '../../autogenerated/data-contracts';
import { UseInfiniteQueryOptions, useInfiniteQuery } from 'react-query';
import { isUndefined } from 'lodash';

import { ApiController } from '../../autogenerated/Api';
import { getAgreementsDepositsMockData } from './use-agreements-deposits-mock-data';
import { useAuth } from 'react-oidc-context';
import interceptors from '../../../interceptors';
import securityWorker from '../../../security-worker';

function useAgreementsDeposits<TError = unknown>(
    agreementId: number | undefined,
    query: {
        depositType?: DepositTypesFilter;
        orderBy?: DepositSortColumn;
        orderByDescending?: boolean;
        pageSize?: number;
    } = {},
    options?: Omit<
        UseInfiniteQueryOptions<
            GetDepositsResponse | undefined,
            ReactQueryError<TError>,
            GetDepositsResponse | undefined
        >,
        'queryKey' | 'queryFn' | 'getNextPageParam'
    >
) {
    const auth = useAuth();

    return useInfiniteQuery<
        GetDepositsResponse | undefined,
        ReactQueryError<TError>,
        GetDepositsResponse | undefined
    >(
        [
            'deposits',
            'list',
            agreementId,
            {
                query,
                token: auth.user?.access_token,
                tokenType: auth.user?.token_type
            }
        ],
        async ({ pageParam = 1 }) => {
            if (isUndefined(agreementId)) {
                console.warn('Undefined ID in useAgreementsDeposits');
                return undefined;
            }
            if (process.env.REACT_APP_API_MOCK) {
                console.warn('Using mock data for useAgreementsDeposits');
                // return Promise.reject({ response: { status: 404 } });
                return await new Promise<GetDepositsResponse>(
                    (resolve, reject) => {
                        setTimeout(
                            () =>
                                resolve(
                                    getAgreementsDepositsMockData(pageParam)
                                ),
                            1000
                        );
                    }
                );
            }

            if (
                auth.user?.access_token === undefined ||
                auth.user.token_type === undefined
            ) {
                return undefined;
            }

            const controller = new ApiController({
                interceptors,
                securityWorker: securityWorker(
                    auth.user?.token_type,
                    auth.user?.access_token
                )
            });

            // We don't want to catch any errors here since it will broke react query "error" status
            const response = await controller.getDeposits(agreementId, {
                pageNumber: pageParam,
                ...query
            });
            return response.data;
        },
        {
            ...options,
            getNextPageParam: (lastPage, pages) => {
                const currentRecords = pages
                    .map((page) => page?.deposits?.length ?? 0)
                    .reduce((acc = 0, length) => acc + length);

                return (lastPage?.totalRecords ?? 0) > currentRecords
                    ? pages.length + 1
                    : false;
            }
        }
    );
}

export default useAgreementsDeposits;
