import {
    ButtonGroupType,
    ButtonProps,
    CalendarItemProps,
    DatePickerWizard as CoreDatePickerWizard,
    DatePickerWizardProps as CoreDatePickerWizardProps,
    TailwindStyle
} from '@ncc-frontend/core';
import { ComponentType, memo } from 'react';
import { merge } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import Button from '../../../button/button';
import ButtonGroup from '../../../button-group/button-group';
import CalendarItem from './calendar-item';

interface DatePickerWizardProps
    extends Omit<
        CoreDatePickerWizardProps,
        | 'buttonComponent'
        | 'buttonGroupComponent'
        | 'calendarItemComponent'
        | 'translations'
    > {
    translations?: Partial<CoreDatePickerWizardProps['translations']>;
}

function DatePickerWizard({
    tailwindStyle,
    translations,
    ...restProps
}: DatePickerWizardProps) {
    const { t } = useTranslation();

    const mergedTailwindStyle = useMemo<TailwindStyle>(
        () =>
            merge<TailwindStyle, TailwindStyle | undefined>(
                {
                    gap: 'gap-4'
                },
                tailwindStyle
            ),
        [tailwindStyle]
    );

    const mergedTranslations = useMemo<
        CoreDatePickerWizardProps['translations']
    >(
        () =>
            merge(
                {
                    allDates: t('date-picker.all-dates'),
                    confirm: t('date-picker.confirm'),
                    confirmRange: t('date-picker.confirm-range'),
                    day: t('date-picker.day'),
                    fromDate: t('date-picker.from-date'),
                    month: t('date-picker.month'),
                    readonlyConfirm: t('date-picker.readonly-confirm'),
                    toDate: t('date-picker.to-date'),
                    year: t('date-picker.year')
                },
                translations
            ),
        [t, translations]
    );

    const arrowsCssClasses =
        'text-brand-escode-neonblue-neonblue-100 disabled:text-escode-grey-50';
    const previousArrowsCssClasses = classNames('ml-20', arrowsCssClasses);
    const nextArrowsCssClasses = classNames('mr-20', arrowsCssClasses);

    return (
        <CoreDatePickerWizard
            {...restProps}
            previousClassName={previousArrowsCssClasses}
            nextClassName={nextArrowsCssClasses}
            tailwindStyle={mergedTailwindStyle}
            buttonComponent={Button as ComponentType<ButtonProps>}
            buttonAllDatesProps={{ variant: 'tertiary' }}
            buttonConfirmProps={{ variant: 'primary' }}
            buttonGroupComponent={ButtonGroup as ButtonGroupType}
            calendarItemComponent={
                CalendarItem as ComponentType<CalendarItemProps>
            }
            translations={mergedTranslations}
        />
    );
}

export default memo(DatePickerWizard);
export type { DatePickerWizardProps };
