import { AxiosResponse } from 'axios';
import { MutationOptions, useMutation, useQueryClient } from 'react-query';

import { ApiController } from '../../autogenerated/Api';
import { useAuth } from 'react-oidc-context';
import interceptors from '../../../interceptors';
import securityWorker from '../../../security-worker';

type SchedulesDeleteParams = { query: { downloadId?: number } };

function useSchedulesDelete<TError = unknown, TContext = unknown>(
    options?: Omit<
        MutationOptions<
            AxiosResponse<void>,
            ReactQueryError<TError>,
            SchedulesDeleteParams,
            TContext
        >,
        'mutationFn'
    >
) {
    const queryClient = useQueryClient();
    const auth = useAuth();

    return useMutation(
        [{ token: auth.user?.access_token, tokenType: auth.user?.token_type }],
        async ({ query }: SchedulesDeleteParams) => {
            if (process.env.REACT_APP_API_MOCK) {
                console.warn(
                    'Mock is enabled useSchedulesDelete call ignored.',
                    query
                );
                return Promise.resolve({} as AxiosResponse<void>);
            }

            const controller = new ApiController({
                interceptors,
                securityWorker: securityWorker(
                    auth.user?.token_type,
                    auth.user?.access_token
                )
            });

            return controller.deleteDownloadSchedule(query);
        },
        {
            ...options,
            onSettled: (data, error, variables, context) => {
                options?.onSettled?.(data, error, variables, context);

                queryClient.invalidateQueries({ queryKey: ['schedules'] });
            }
        }
    );
}

export default useSchedulesDelete;
export type { SchedulesDeleteParams };
