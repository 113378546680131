import {
    CommitFile,
    PrepareResponse
} from '../../autogenerated/data-contracts';
import { MutationOptions, useMutation } from 'react-query';
// import { useAuth } from 'react-oidc-context';
import { useRef } from 'react';
import axios, { AxiosResponse } from 'axios';

export type UploadsFilesBlocks = {
    blocks: PrepareResponse;
    depositUniqueReference: string;
    file: File;
    spectreAuthToken: string;
    uniqueReference: string;
};

const useUploadsFilesBlocks = <TError = unknown, TContext = unknown>(
    props: {
        onSettled?: () => void;
        onUploadProgress?: (percentCompleted: number) => void;
    } & Omit<
        MutationOptions<
            AxiosResponse<void | unknown>,
            TError,
            UploadsFilesBlocks,
            TContext
        >,
        'mutationFn'
    >
) => {
    // const auth = useAuth();
    const isCancelledRef = useRef(false);

    const cancelUpload = () => {
        isCancelledRef.current = true;
    };

    const { mutate: uploadFilesBlocksFn } = useMutation<
        AxiosResponse<void | unknown>,
        TError,
        UploadsFilesBlocks,
        TContext
    >(
        async ({
            blocks,
            depositUniqueReference,
            file,
            spectreAuthToken,
            uniqueReference
        }: UploadsFilesBlocks) => {
            isCancelledRef.current = false;

            if (process.env.REACT_APP_API_MOCK) {
                console.warn(
                    'Mock is enabled useUploadsFilesBlocks call ignored.'
                );
                return Promise.resolve({} as AxiosResponse<void | unknown>);
            }

            const baseURL = process.env.REACT_APP_SPECTRE_AGENT_ENDPOINT;
            const axiosInstance = axios.create({
                baseURL,
                headers: {
                    Authorization: `Bearer ${spectreAuthToken}`,
                    'Cache-Control': 'no-cache, no-store, must-revalidate',
                    'Content-Type': 'multipart/form-data',
                    Expires: '0',
                    Pragma: 'no-cache'
                }
            });

            const successfulChunkUploads = [];
            if (blocks && blocks.blockIds && blocks.blockSize) {
                for (let i = 0; i < blocks.blockIds.length; i++) {
                    if (isCancelledRef.current) {
                        throw new Error('Upload cancelled by user');
                    }

                    const blockId = blocks.blockIds[i];
                    const start = i * blocks.blockSize;
                    const end = Math.min((i + 1) * blocks.blockSize, file.size);

                    const formData = new FormData();
                    formData.append('block', file.slice(start, end));

                    const chunkResponse = await axiosInstance.put(
                        `/api/uploads/${depositUniqueReference}/${uniqueReference}/${blockId}`,
                        formData,
                        {
                            onUploadProgress: (progressEvent) => {
                                const totalUploaded =
                                    i * blocks.blockSize! +
                                    progressEvent.loaded;
                                const percentCompleted = Math.round(
                                    (totalUploaded / file.size) * 100
                                );

                                props.onUploadProgress?.(percentCompleted);
                            }
                        }
                    );

                    if (chunkResponse.status === 204) {
                        successfulChunkUploads.push(blockId);
                    } else break;
                }

                if (successfulChunkUploads.length === blocks.blockIds.length) {
                    const commitInstance = axios.create({
                        baseURL,
                        headers: {
                            Authorization: `Bearer ${spectreAuthToken}`,
                            'Cache-Control':
                                'no-cache, no-store, must-revalidate',
                            'Content-Type': 'application/json',
                            Expires: '0',
                            Pragma: 'no-cache'
                        }
                    });
                    const data: CommitFile = {
                        blockIds: blocks.blockIds,
                        fileName: file.name,
                        size: blocks.blockSize
                    };

                    const commitResponse = await commitInstance.post(
                        `/api/uploads/${depositUniqueReference}/${uniqueReference}/commit`,
                        JSON.stringify(data)
                    );

                    return commitResponse;
                }
            }

            return {} as AxiosResponse<void>;
        },
        {
            ...props
        }
    );

    return { cancelUpload, uploadFilesBlocksFn };
};

export default useUploadsFilesBlocks;
