import { AgGridReact } from 'ag-grid-react';
import { DataWidgetContextValue } from '../data/data-widget-provider';
import { DataWidgetProps } from '../data/data-widget';
import {
    GridReadyEvent,
    PaginationChangedEvent,
    RowDataUpdatedEvent
} from 'ag-grid-community';
import { RefObject, useCallback, useEffect } from 'react';
import { get } from 'common/path';

type TableDataParams<TData> = {
    agGridRef: RefObject<AgGridReact<TData>>;
    backendPagination: boolean;
    dataWidget: RefObject<DataWidgetContextValue>;
    fetchNextPage?: () => void | never;
};

function useTableData<TData>({
    agGridRef,
    backendPagination,
    dataWidget,
    fetchNextPage
}: TableDataParams<TData>) {
    const handleGridReady = useCallback(
        (event: GridReadyEvent) => {
            if (!dataWidget.current) return;

            if (!backendPagination) {
                dataWidget.current.setTotalPages(
                    event.api.paginationGetTotalPages()
                );
            }
        },
        [backendPagination, dataWidget]
    );

    const handleQuickSearchChange = useCallback<
        NonNullable<DataWidgetProps['onQuickSearchChange']>
    >(
        (value) => {
            if (!agGridRef.current?.api) {
                console.warn('Table has not been initialized yet.');
                return;
            }

            agGridRef.current.api.setQuickFilter(value);
        },
        [agGridRef]
    );

    const handleHiddenFieldsChange = useCallback<
        NonNullable<DataWidgetProps['onHiddenFieldsChange']>
    >(
        (value) => {
            if (!agGridRef.current?.columnApi) {
                console.warn('Table has not been initialized yet.');
                return;
            }

            agGridRef.current.columnApi.applyColumnState({
                state: agGridRef.current.columnApi
                    .getColumnState()
                    .map((column) => ({
                        ...column,
                        hide: get(value, column.colId) ?? false
                    }))
            });
        },
        [agGridRef]
    );

    const handlePageChange = useCallback<DataWidgetProps['onPageChange']>(
        (toPage) => {
            if (!agGridRef.current?.api) {
                console.warn('Table has not been initialized yet.');
                return;
            }

            const currentTotalPages =
                agGridRef.current.api.paginationGetTotalPages();
            if (backendPagination && toPage > currentTotalPages) {
                fetchNextPage!();
            } else {
                agGridRef.current.api.paginationGoToPage(toPage - 1);
            }
        },
        [agGridRef, backendPagination, fetchNextPage]
    );

    const handlePaginationChanged = useCallback(
        (event: PaginationChangedEvent) => {
            if (!dataWidget.current) {
                console.warn('Data widget has not been initialized yet.');
                return;
            }

            if (!backendPagination) {
                dataWidget.current.setTotalPages(
                    event.api.paginationGetTotalPages()
                );
            }
        },
        [backendPagination, dataWidget]
    );

    const handleReset = useCallback<
        NonNullable<DataWidgetProps['onReset']>
    >(() => {
        if (!agGridRef.current?.columnApi) {
            console.warn('Table has not been initialized yet.');
            return;
        }

        agGridRef.current.columnApi.resetColumnState();
        agGridRef.current.columnApi.autoSizeAllColumns();
    }, [agGridRef]);

    const handleRowDataUpdated = useCallback(
        (event: RowDataUpdatedEvent) => {
            if (backendPagination) {
                event.api.paginationGoToLastPage();
            }
        },
        [backendPagination]
    );

    const handleApplyFilters = useCallback<
        NonNullable<DataWidgetProps['onApplyFilters']>
    >(
        (filters) => {
            if (!agGridRef.current?.api) {
                console.warn('Table has not been initialized yet.');
                return;
            }

            agGridRef.current.api.setFilterModel(null);

            filters.forEach((filter) => {
                // Get a reference to the filter instance
                const filterInstance = agGridRef.current!.api.getFilterInstance(
                    filter.colId
                );
                if (!filterInstance) return;

                // Set the filter model
                filterInstance.setModel({
                    ...filter.model
                });
            });

            // Tell grid to run filter operation again
            agGridRef.current.api.onFilterChanged();
        },
        [agGridRef]
    );

    const refresh = useCallback(() => {
        if (dataWidget.current?.filters) {
            handleApplyFilters(dataWidget.current.filters);
        }
        if (dataWidget.current?.quickSearchValue) {
            handleQuickSearchChange(dataWidget.current?.quickSearchValue);
        }
    }, [dataWidget, handleApplyFilters, handleQuickSearchChange]);

    useEffect(() => {
        if (dataWidget.current?.filters) {
            handleApplyFilters(dataWidget.current.filters);
        }
    }, [dataWidget, handleApplyFilters]);

    useEffect(() => {
        if (
            agGridRef.current === null ||
            agGridRef.current?.columnApi === null ||
            agGridRef.current?.columnApi === undefined ||
            dataWidget.current === null
        ) {
            console.warn('Table has not been initialized yet.');
            return;
        }

        const hiddenFields: Record<string, boolean> = {};

        agGridRef.current.columnApi
            .getColumnState()
            .forEach(({ colId, hide }) => {
                hiddenFields[colId] = !!hide;
            });

        dataWidget.current.setHiddenFields(hiddenFields);
    }, [agGridRef, dataWidget]);

    return {
        agGridRef,
        dataWidget,
        handleApplyFilters,
        handleGridReady,
        handleHiddenFieldsChange,
        handlePageChange,
        handlePaginationChanged,
        handleQuickSearchChange,
        handleReset,
        handleRowDataUpdated,
        refresh
    };
}

export default useTableData;
