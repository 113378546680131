import { AgreementDetails } from 'core/api/client-portal/autogenerated/data-contracts';
import { Divider, Modal } from 'lib-ui';
import { dateFormatter } from '@ncc-frontend/core';
import { useTranslation } from 'react-i18next';

interface MoreInfoModalProps {
    agreement: AgreementDetails | undefined;
}

function MoreInfoModal({
    agreement: {
        freeDepositsRemaining,
        lastDepositDate,
        numberOfAssociatedParties,
        softwareOwner
    } = {}
}: MoreInfoModalProps) {
    const { i18n, t } = useTranslation();

    const containerStyles = 'flex items-center justify-between';
    const dtStyles = 'font-normal text-md text-general-grey-grey-90';
    const ddStyles = 'text-md font-semibold h-7';
    const dividerColour = 'bg-gray-400 my-2';

    return (
        <Modal size="l" className="max-w-xl">
            <Modal.Header className="font-sans">
                {t('agreement.more-info-modal.title')}
            </Modal.Header>
            <Modal.Body>
                <dl className="flex flex-col gap-2">
                    <div className={containerStyles}>
                        <dt className="font-normal text-md text-general-grey-grey-90">
                            {t('agreement.more-info-modal.main-contact')}
                        </dt>

                        <dd
                            className="text-base font-medium text-general-grey-grey-90"
                            data-testid="software-owner"
                        >
                            {softwareOwner}
                        </dd>
                    </div>
                    <Divider color={dividerColour} />
                    <div className={containerStyles}>
                        <dt className={dtStyles}>
                            {t('agreement.more-info-modal.deposit-entitlement')}
                        </dt>
                        <dd
                            className={ddStyles}
                            data-testid="free-deposit-remaining"
                        >
                            {freeDepositsRemaining || 0}
                        </dd>
                    </div>

                    <Divider color={dividerColour} />
                    <div className={containerStyles}>
                        <dt className={dtStyles}>
                            {t('agreement.more-info-modal.deposit-verified')}
                        </dt>
                        <dd
                            className={ddStyles}
                            data-testid="last-deposit-date"
                        >
                            {dateFormatter(lastDepositDate, i18n.language)}
                        </dd>
                    </div>
                    <Divider color={dividerColour} />
                    <div className={containerStyles}>
                        <dt className={dtStyles}>
                            {t('agreement.more-info-modal.associated-parties')}
                        </dt>
                        <dd
                            className={ddStyles}
                            data-testid="number-of-associated-parties"
                        >
                            {numberOfAssociatedParties || 0}
                        </dd>
                    </div>
                    {/* <Divider color={dividerColour} /> */}
                </dl>
            </Modal.Body>
        </Modal>
    );
}

export default MoreInfoModal;
export type { MoreInfoModalProps };
