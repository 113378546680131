import { UseQueryOptions, useQuery } from 'react-query';
import axios, { AxiosResponse } from 'axios';

import { ProblemDetails } from '../../autogenerated/data-contracts';
import { useAuth } from 'react-oidc-context';

function useRefreshToken(
    spectreAuthToken?: string,
    depositId?: string,
    options?: Omit<UseQueryOptions, 'queryFn'>
) {
    // const queryClient = useQueryClient();
    const auth = useAuth();

    return useQuery(
        [
            'spectreControlRefresh',
            { token: auth.user?.access_token, tokenType: auth.user?.token_type }
        ],
        async () => {
            if (process.env.REACT_APP_API_MOCK) {
                console.warn(
                    'Mock is enabled; useSpectreControlRefresh call ignored.'
                );
                return Promise.resolve({} as AxiosResponse<void | unknown>);
            }
            const baseURL = process.env.REACT_APP_SPECTRE_CONTROL_ENDPOINT;

            const refreshVVToken = (params = {}) => {
                const axiosInstance = axios.create({
                    baseURL,
                    headers: {
                        Authorization: `Bearer ${spectreAuthToken}`,
                        'Cache-FormControl':
                            'no-cache, no-store, must-revalidate',
                        Expires: '0'
                    },
                    withCredentials: true
                });

                return axiosInstance.post<void | ProblemDetails>(
                    `/api/auth/refresh`,
                    { depositId }
                );
            };
            return (await refreshVVToken()).data!.token || null;
        },
        {
            enabled: false
        }
    );
}

export default useRefreshToken;
