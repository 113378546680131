import { Dispatch, SetStateAction, useEffect, useState } from 'react';

function usePropState<TValue>(
    value: TValue | undefined | null,
    defaultValue: TValue
): [TValue, Dispatch<SetStateAction<TValue>>] {
    const [state, setState] = useState(value ?? defaultValue);

    useEffect(() => {
        if (value !== undefined && value !== null) {
            setState(value);
        }
    }, [value]);

    return [state, setState];
}

export default usePropState;
