import { Modal } from 'lib-ui';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import DepositDocument from './deposit-document';
import useDepositMediaCheckDownload from 'core/api/client-portal/hooks/deposits/use-deposit-media-check-download';
import useDepositVerificationDownload, {
    DepositVerificationDownloadParams
} from 'core/api/client-portal/hooks/deposits/use-deposit-verification-download';
import useDepositVerificationList from 'core/api/client-portal/hooks/deposits/use-deposit-verification-list';

interface DepositDocumentsModalProps {
    agreementId: number;
    agreementNumber: number;
    depositId: number;
    mediaCheck: boolean | undefined;
}

function DepositDocumentsModal({
    agreementId,
    agreementNumber,
    depositId,
    mediaCheck
}: DepositDocumentsModalProps) {
    const { t } = useTranslation();

    const {
        data: verificationReports,
        isFetching: isFetchingVerificationReports,
        isLoading: isLoadingVerificationReports
    } = useDepositVerificationList({
        agreementId: agreementId,
        depositId: depositId
    });

    const {
        isLoading: isLoadingDepositMediaCheckDownload,
        mutate: requestDepositMediaCheckDownload
    } = useDepositMediaCheckDownload();

    const {
        isLoading: isLoadingVerificationDownload,
        mutate: requestVerificationDownload
    } = useDepositVerificationDownload();

    const downloadMediaCheck = useCallback(() => {
        requestDepositMediaCheckDownload({
            agreementNumber,
            depositId
        });
    }, [agreementNumber, depositId, requestDepositMediaCheckDownload]);

    const loadingVerificationReports =
        isLoadingVerificationReports || isFetchingVerificationReports;

    const downloadVerification = useCallback(
        (data?: DepositVerificationDownloadParams) => {
            if (!data) return;
            requestVerificationDownload(data);
        },
        [requestVerificationDownload]
    );

    return (
        <Modal>
            <Modal.Header>{t('deposit-documents.title')}</Modal.Header>
            <Modal.Body className="flex flex-col items-center gap-2.5">
                {!!mediaCheck && (
                    <DepositDocument
                        name={t('deposits.header.media-check')}
                        onDownload={downloadMediaCheck}
                        loading={isLoadingDepositMediaCheckDownload}
                    />
                )}
                {loadingVerificationReports && (
                    <FontAwesomeIcon icon={faSpinner} spin />
                )}
                {!loadingVerificationReports &&
                    verificationReports?.items
                        ?.filter(({ index, slxType }) => !!index && !!slxType)
                        .map(({ index, slxType }) => (
                            <DepositDocument<DepositVerificationDownloadParams>
                                key={index}
                                name={slxType!}
                                onDownload={downloadVerification}
                                data={{
                                    agreementId,
                                    depositId,
                                    index: index!,
                                    slxType: slxType!
                                }}
                                loading={isLoadingVerificationDownload}
                            />
                        ))}
                {!loadingVerificationReports &&
                    !mediaCheck &&
                    (verificationReports?.items?.length ?? 0) === 0 && (
                        <>{t('deposit-documents.no-data')}</>
                    )}
                <> </>
            </Modal.Body>
        </Modal>
    );
}

export default DepositDocumentsModal;
export type { DepositDocumentsModalProps };
