import { useCallback } from 'react';
import AgreementsTable from './agreements-table';
import useAgreementsList from 'core/api/client-portal/hooks/agreements/use-agreements-list';

function Agreements() {
    const { data, error, isFetching, isLoading, refetch } = useAgreementsList({
        IncludeRestrictedAgreements: true
    });

    const reload = useCallback(() => {
        refetch();
    }, [refetch]);

    return (
        <div className="h-full">
            <AgreementsTable
                data={data}
                loading={isLoading || isFetching}
                error={error?.response.status}
                onReload={reload}
            />
        </div>
    );
}

export default Agreements;
