import { AgreementViewModel } from 'core/api/client-portal/autogenerated/data-contracts';
import { AgreementsTableContext } from './agreements-table-context';
import { ICellRendererParams, ValueGetterParams } from 'lib-ui';
import { memo } from 'react';

import { getAgreementDepositStatus } from 'core/api/client-portal/model/agreement-deposit-status';
import AgreementDepositStatusTag from './agreement-deposit-status-tag';

function agreementDepositStatusValueGetter({
    context: { t },
    data
}: ValueGetterParams<AgreementViewModel, AgreementsTableContext>) {
    const status = getAgreementDepositStatus(data);
    if (!status) return;
    return t(`agreements.deposit.status-${status}`);
}

function AgreementDepositStatusCellRenderer({
    data,
    value
}: ICellRendererParams<AgreementViewModel>) {
    return (
        value && (
            <div className="flex h-full items-center">
                <AgreementDepositStatusTag
                    variant={getAgreementDepositStatus(data)}
                >
                    {value}
                </AgreementDepositStatusTag>
            </div>
        )
    );
}

export default memo(AgreementDepositStatusCellRenderer);
export { agreementDepositStatusValueGetter };
