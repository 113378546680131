import * as yup from 'yup';
import {
    Button,
    CheckboxField,
    Field,
    FileInputField,
    InputField,
    Modal,
    MultiCheckboxField,
    MultiCheckboxFieldProps,
    ResultModal,
    SelectField,
    SelectFieldProps
} from 'lib-ui';
import { FormProvider, Path, SubmitHandler, useForm } from 'react-hook-form';
import { ReactElement, useCallback, useMemo } from 'react';
import { TailwindStyle, useModals } from '@ncc-frontend/core';
import { isUndefined } from 'lodash';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import useAgreementsInitiateRelease, {
    AgreementsInitiateReleaseParams
} from 'core/api/client-portal/hooks/agreements/use-agreements-initiate-release';
import useAgreementsList from 'core/api/client-portal/hooks/agreements/use-agreements-list';
import useAgreementsReleaseGuideDownload from 'core/api/client-portal/hooks/agreements/use-agreements-release-guide-download';
import useAgreementsStatutoryDeclarationFormDownload from 'core/api/client-portal/hooks/agreements/use-agreements-statutory-declaration-from-download';
import useBackendValidation from 'common/use-backend-validation';
import useContactsList from 'core/api/client-portal/hooks/contacts/use-contacts-list';

const CHECKBOX_TAILWIND_STYLE: TailwindStyle = {
    items: 'items-start'
};

type InitiateReleaseFormValues = Omit<
    AgreementsInitiateReleaseParams['data'],
    'deliveryMethod' | 'releaseContactId'
> & {
    accepted?: boolean;
    deliveryMethod?:
    | AgreementsInitiateReleaseParams['data']['deliveryMethod']
    | '';
    releaseContactId?:
    | AgreementsInitiateReleaseParams['data']['releaseContactId']
    | '';
};

interface InitiateReleaseModalProps { }

// TODO: move to common file.
// yup.mixed().test(
//     'file',
//     t('form.validation.required', {
//         field: t('agreement.initiate-release-modal.evidence-file')
//     }),
//     function (value, { createError }) {
//         if (!value) return createError();

//         const valid = value.length > 0;

//         if (valid) return true;

//         return createError();
//     }
// )

function InitiateReleaseModal({ }: InitiateReleaseModalProps): ReactElement | null {
    const { t } = useTranslation();
    const { pop, push } = useModals();

    const schema = yup.object({
        accepted: yup.boolean().isTrue(),
        agreementIds: yup
            .array()
            .min(
                1,
                t('form.validation.required-min-1', {
                    field: t('agreement.initiate-release-modal.agreement-ids')
                })
            )
            .of(yup.string()),

        deliveryMethod: yup.string().required(
            t('form.validation.required', {
                field: t('agreement.initiate-release-modal.delivery-method')
            })
        ),
        invoiceAccountAddress: yup
            .string()
            .required(
                t('form.validation.required', {
                    field: t(
                        'agreement.initiate-release-modal.invoice-account-address'
                    )
                })
            )
            .max(
                150,
                t('form.validation.max-length', {
                    field: t(
                        'agreement.initiate-release-modal.invoice-account-address'
                    ),
                    length: 500
                })
            ),
        invoiceAccountName: yup
            .string()
            .required(
                t('form.validation.required', {
                    field: t(
                        'agreement.initiate-release-modal.invoice-account-name'
                    )
                })
            )
            .max(
                150,
                t('form.validation.max-length', {
                    field: t(
                        'agreement.initiate-release-modal.invoice-account-name'
                    ),
                    length: 150
                })
            ),

        releaseContactId: yup.number().required(
            t('form.validation.required', {
                field: t('agreement.initiate-release-modal.release-contact')
            })
        )
    });
    const methods = useForm<InitiateReleaseFormValues>({
        defaultValues: {
            accepted: false,
            agreementIds: [],
            deliveryMethod: '',
            invoiceAccountAddress: '',
            invoiceAccountName: '',
            releaseContactId: ''
        },
        mode: 'onTouched',
        resolver: yupResolver(schema)
    });

    const {
        data: agreements,
        isFetching: isFetchingAgreements,
        isLoading: isLoadingAgreements
    } = useAgreementsList({
        IncludeLiveOnly: true
    });

    const {
        data: contacts,
        isFetching: isFetchingContacts,
        isLoading: isLoadingContacts
    } = useContactsList();

    const {
        error,
        isLoading: isLoadingRequest,
        mutate: initiateRelease
    } = useAgreementsInitiateRelease<{
        errors: Record<Path<InitiateReleaseFormValues>, string[]>;
    }>({
        onError: () => {
            push(ResultModal, {
                description: t('result.error-description'),
                title: t('agreement.initiate-release-modal.error-title')
            });
        },
        onSuccess: () => {
            pop();
            push(ResultModal, {
                description: t(
                    'agreement.initiate-release-modal.success-description'
                ),
                title: t('agreement.initiate-release-modal.success-title')
            });
        }
    });

    const { mutate: requestReleaseGuideDownload } =
        useAgreementsReleaseGuideDownload();

    const { mutate: requestStatutoryDeclarationFormDownload } =
        useAgreementsStatutoryDeclarationFormDownload();

    const isLoading =
        isFetchingAgreements ||
        isLoadingAgreements ||
        isFetchingContacts ||
        isLoadingContacts ||
        isLoadingRequest;

    const agreementIdsOptions = useMemo<MultiCheckboxFieldProps['options']>(
        () =>
            agreements?.agreements
                ?.filter(
                    (item) =>
                        !isUndefined(item.agreementNumber) &&
                        !isUndefined(item.agreementId)
                )
                .map((item) => ({
                    label: item.agreementNumber as number,
                    value: item.agreementId as number
                })) ?? [],
        [agreements?.agreements]
    );

    const deliveryMethodOptions = useMemo<SelectFieldProps['options']>(
        () => [
            {
                label: t(
                    'agreement.initiate-release-modal.delivery-method-options.download'
                ),
                value: 'Download'
            },
            {
                label: t(
                    'agreement.initiate-release-modal.delivery-method-options.post'
                ),
                value: 'Post'
            }
        ],
        [t]
    );

    const releaseContactOptions = useMemo<SelectFieldProps['options']>(
        () =>
            contacts?.contacts
                ?.filter(
                    (item) =>
                        !isUndefined(item.id) &&
                        (!isUndefined(item.firstName) ||
                            !isUndefined(item.lastName))
                )
                .map((item) => ({
                    label: [item.firstName, item.lastName]
                        .filter(Boolean)
                        .join(' '),
                    value: item.id?.toString() as string // BACKEND: swagger type should be a string.
                })) ?? [],
        [contacts?.contacts]
    );

    const submit = useCallback<SubmitHandler<InitiateReleaseFormValues>>(
        (formData) => {
            initiateRelease({
                data: formData as AgreementsInitiateReleaseParams['data']
            });
        },
        [initiateRelease]
    );

    const downloadReleaseGuide = useCallback(async () => {
        requestReleaseGuideDownload();
    }, [requestReleaseGuideDownload]);

    const downloadStatutoryDeclarationForm = useCallback(async () => {
        requestStatutoryDeclarationFormDownload();
    }, [requestStatutoryDeclarationFormDownload]);

    useBackendValidation(error?.response?.data?.errors, methods.setError);

    return (
        <Modal className="max-w-md">
            <Modal.Header>
                {t('agreement.initiate-release-modal.title')}
            </Modal.Header>
            <Modal.Body>
                <FormProvider {...methods}>
                    <form
                        onSubmit={methods.handleSubmit(submit)}
                        className="flex flex-col gap-2.5"
                    >
                        <MultiCheckboxField
                            label={t(
                                'agreement.initiate-release-modal.agreement-ids'
                            )}
                            name="agreementIds"
                            options={agreementIdsOptions}
                            required
                        />
                        <SelectField
                            placeholder={t(
                                'agreement.initiate-release-modal.delivery-method'
                            )}
                            name="deliveryMethod"
                            options={deliveryMethodOptions}
                            required
                        />
                        <SelectField
                            placeholder={t(
                                'agreement.initiate-release-modal.release-contact'
                            )}
                            name="releaseContactId"
                            options={releaseContactOptions}
                            required
                        />
                        <FileInputField
                            label={t(
                                'agreement.initiate-release-modal.statutory-declaration'
                            )}
                            name="statutoryDeclaration"
                            multiple
                        />
                        <FileInputField
                            label={t(
                                'agreement.initiate-release-modal.evidence-file'
                            )}
                            name="evidenceFile"
                            multiple
                        />
                        <InputField
                            label={t(
                                'agreement.initiate-release-modal.invoice-account-name'
                            )}
                            name="invoiceAccountName"
                            placeholder="Adam Smith"
                            required
                        />
                        <InputField
                            label={t(
                                'agreement.initiate-release-modal.invoice-account-address'
                            )}
                            name="invoiceAccountAddress"
                            placeholder="Buckingham Palace, London, SW1A 1AA"
                            required
                        />
                        <Field
                            name=""
                            label={t(
                                'agreement.initiate-release-modal.documents'
                            )}
                        >
                            <div className="flex gap-2.5">
                                <Button
                                    type="button"
                                    onClick={downloadReleaseGuide}
                                >
                                    {t(
                                        'agreement.initiate-release-modal.guide'
                                    )}
                                </Button>
                                <Button
                                    type="button"
                                    onClick={downloadStatutoryDeclarationForm}
                                >
                                    {t(
                                        'agreement.initiate-release-modal.declaration-form'
                                    )}
                                </Button>
                            </div>
                        </Field>

                        <CheckboxField
                            name="accepted"
                            label={t(
                                'agreement.initiate-release-modal.confirmation'
                            )}
                            tailwindStyle={CHECKBOX_TAILWIND_STYLE}
                            required
                        />
                        <Button
                            variant="primary"
                            loading={isLoading}
                            disabled={!methods.formState.isDirty}
                        >
                            {t('agreement.initiate-release-modal.confirm')}
                        </Button>
                    </form>
                </FormProvider>
            </Modal.Body>
        </Modal>
    );
}

export default InitiateReleaseModal;
export type { InitiateReleaseFormValues, InitiateReleaseModalProps };
