import AppBody from './app-body';
import Contacts from 'ui/contacts/contacts';

function ContactsBody() {
    return (
        <AppBody>
            <Contacts />
        </AppBody>
    );
}

export default ContactsBody;
