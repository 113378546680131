import { TextareaField, YesNoField } from 'lib-ui';
import { useTranslation } from 'react-i18next';

import FeedbackFormStep, { FeedbackFormStepProps } from './feedback-form-step';

type FieldValues = {
    isImproved: 'yes' | 'no' | '';
    isImprovedComment: string;
};

interface ComparisonStepProps {
    defaultValues: FieldValues;
    onChange: FeedbackFormStepProps<FieldValues>['onChange'];
}

function ComparisonStep({ defaultValues, onChange }: ComparisonStepProps) {
    const { t } = useTranslation();

    return (
        <FeedbackFormStep<FieldValues>
            message={t('feedback-form.comparison-question')}
            title={t('feedback-form.comparison')}
            onChange={onChange}
            defaultValues={defaultValues}
        >
            <YesNoField name="isImproved" />
            <TextareaField
                name="isImprovedComment"
                placeholder={t('feedback-form.comment-placeholder')}
            />
        </FeedbackFormStep>
    );
}

export default ComparisonStep;
export type { ComparisonStepProps };
