import { Button, MultiCheckboxFieldProps } from 'lib-ui';
import { DownloadScheduleRepo } from 'core/api/client-portal/autogenerated/data-contracts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCodeBranch } from '@fortawesome/pro-regular-svg-icons';
import { isUndefined } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useModals } from '@ncc-frontend/core';
import { useTranslation } from 'react-i18next';
import AddBranchSelectionModal from './add-branch-selection-modal/add-branch-selection-modal';
import useGetProviders from 'core/api/client-portal/hooks/source-control-deposits/use-get-providers';

type ControlConnectionsProps = {
    connectionsAdded?: DownloadScheduleRepo[];
};

const ControlConnections = ({ connectionsAdded }: ControlConnectionsProps) => {
    const methods = useFormContext();
    const { push } = useModals();
    const { data: providers, isFetching } = useGetProviders({});

    const { t } = useTranslation();

    const providersIdsOptions = useMemo<MultiCheckboxFieldProps['options']>(
        () =>
            providers?.providers
                ?.filter(
                    (item) =>
                        !isUndefined(item.connectionName) &&
                        !isUndefined(item.id)
                )
                .map((item) => ({
                    label: `${item.connectionName} - ${item.id}`,
                    value: item.id as number
                })) ?? [],
        [providers]
    );
    const getProviderName = useCallback(
        (id?: number) => {
            const provider = providers?.providers?.find(
                (item) => item.id === Number(id)
            );
            return provider?.connectionName ?? '';
        },
        [providers]
    );
    // modals section
    const selectConnection = useCallback(() => {
        !isFetching &&
            push(AddBranchSelectionModal, { methods, providersIdsOptions });
    }, [isFetching, methods, providersIdsOptions, push]);

    return (
        <div className="rounded-lg border border-escode-grey-30 p-5">
            <div className="flex items-center gap-14 text-center">
                <h2 className="text-md font-medium">
                    {t('source-control.control-connections')}
                </h2>
                <div className="flex gap-2">
                    <Button
                        onClick={selectConnection}
                        type="button"
                        variant="tertiary"
                    >
                        {t('source-control.select-connection')}
                    </Button>
                </div>
            </div>

            {!isUndefined(connectionsAdded) ? (
                <div
                    className="mt-5 flex flex-col gap-2 border-t-2"
                    data-testid="connections-container"
                >
                    {connectionsAdded.map((item, index) => (
                        <div
                            className="pt2 flex items-center justify-between pt-5"
                            key={index}
                        >
                            <div className="flex items-center justify-center gap-4">
                                <span className="font-medium">
                                    <FontAwesomeIcon
                                        icon={faCodeBranch}
                                        className="icon-large text-brand-escode-neonblue-neonblue-100"
                                    />
                                </span>
                                <span className="font-medium">
                                    {getProviderName(item?.providerId)}
                                </span>
                                <span className="gap-2 text-sm text-general-grey-grey-90">
                                    {item.branchName ??
                                        t('source-control.whole-repository')}
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="mt-4">
                    <span> {t('source-control.no-connections')}</span>
                </div>
            )}
        </div>
    );
};

export default ControlConnections;
