import { AxiosResponse } from 'axios';
import { MutationOptions, useMutation, useQueryClient } from 'react-query';
import { UserSetting } from '../../autogenerated/data-contracts';

import { ApiController } from '../../autogenerated/Api';
import { useAuth } from 'react-oidc-context';
import interceptors from '../../../interceptors';
import securityWorker from '../../../security-worker';

type UserSettingsUpdateParams = {
    data: UserSetting[];
};

function useUserSettingsUpdate<TError = unknown, TContext = unknown>(
    options?: Omit<
        MutationOptions<
            AxiosResponse<boolean>,
            ReactQueryError<TError>,
            UserSettingsUpdateParams,
            TContext
        >,
        'mutationFn'
    >
) {
    const queryClient = useQueryClient();
    const auth = useAuth();

    return useMutation(
        [{ token: auth.user?.access_token, tokenType: auth.user?.token_type }],
        async ({ data }: UserSettingsUpdateParams) => {
            if (process.env.REACT_APP_API_MOCK) {
                console.warn(
                    'Mock is enabled useUserSettingsUpdate call ignored.'
                );
                return Promise.resolve({} as AxiosResponse<boolean>);
            }

            const controller = new ApiController({
                interceptors,
                securityWorker: securityWorker(
                    auth.user?.token_type,
                    auth.user?.access_token
                )
            });

            return controller.saveUserSettings(data);
        },
        {
            ...options,
            onSettled: (data, error, variables, context) => {
                options?.onSettled?.(data, error, variables, context);

                queryClient.invalidateQueries({ queryKey: ['user-settings'] });
            }
        }
    );
}

export default useUserSettingsUpdate;
export type { UserSettingsUpdateParams };
