import { DataCentreResponse } from '../../autogenerated/data-contracts';

const useAgreementsDetailsMockData: DataCentreResponse = {
    agreement: {
        agreementNumber: 60906,
        agreementStatus: 'Live',
        agreementType: 'AMS - ML (EPC)',
        billingAccount: 'Chelsea and Westminster Hospital NHS Foundation Trust',
        billingAccountAddress: '',
        freeDepositsRemaining: 999,
        hasBespokeTestingRequirements: false,
        hasOptedOutOfVVStorage: false,
        id: 90,
        isMultiAgreementType: true,
        isOwner: true,
        lastVerificationExerciseDate: null,
        latestDeposit: {
            depositDate: '2023-10-01T21:02:12.626123Z',
            isVerified: false,
            levelOfVerification: '',
            version: '1'
        },
        licensees: [
            {
                billingAccount: 'Hooman Escrow 1',
                billingAccountAddress: '',
                canEditContacts: false,
                depositor: false,
                id: 259,
                partyContacts: [],
                partyStatus: 'Live',
                partyStatusDate: '2014-03-11T00:00:00Z',
                partyType: 'Licensee',
                registeredName: 'Hooman Escrow 1'
            },
            {
                billingAccount: 'Helen Mulreid Test  2',
                billingAccountAddress: '',
                canEditContacts: false,
                depositor: false,
                id: 260,
                partyContacts: [],
                partyStatus: 'Live',
                partyStatusDate: '2014-03-11T00:00:00Z',
                partyType: 'Licensee',
                registeredName: 'Helen Mulreid Test  2'
            },
            {
                billingAccount: 'E2E RMCG 2',
                billingAccountAddress: '',
                canEditContacts: false,
                depositor: false,
                id: 261,
                partyContacts: [],
                partyStatus: 'Live',
                partyStatusDate: '2014-03-11T00:00:00Z',
                partyType: 'Licensee',
                registeredName: 'E2E RMCG 2'
            },
            {
                billingAccount: 'TESTqwerty',
                billingAccountAddress: '',
                canEditContacts: false,
                depositor: false,
                id: 262,
                partyContacts: [],
                partyStatus: 'Live',
                partyStatusDate: '2014-03-11T00:00:00Z',
                partyType: 'Licensee',
                registeredName: 'TESTqwerty'
            },
            {
                billingAccount: 'JS005',
                billingAccountAddress: '',
                canEditContacts: false,
                depositor: false,
                id: 263,
                partyContacts: [],
                partyStatus: 'Live',
                partyStatusDate: '2014-03-11T00:00:00Z',
                partyType: 'Licensee',
                registeredName: 'JS005'
            },
            {
                billingAccount: 'Danny Licensee',
                billingAccountAddress: '',
                canEditContacts: false,
                depositor: false,
                id: 264,
                partyContacts: [],
                partyStatus: 'Live',
                partyStatusDate: '2014-03-11T00:00:00Z',
                partyType: 'Licensee',
                registeredName: 'Danny Licensee'
            },
            {
                billingAccount: 'NCC E2E 4',
                billingAccountAddress: '',
                canEditContacts: false,
                depositor: false,
                id: 265,
                partyContacts: [],
                partyStatus: 'Live',
                partyStatusDate: '2014-03-11T00:00:00Z',
                partyType: 'Licensee',
                registeredName: 'NCC E2E 4'
            },
            {
                billingAccount: 'JS003',
                billingAccountAddress: '',
                canEditContacts: false,
                depositor: false,
                id: 266,
                partyContacts: [],
                partyStatus: 'Live',
                partyStatusDate: '2014-03-11T00:00:00Z',
                partyType: 'Licensee',
                registeredName: 'JS003'
            },
            {
                billingAccount: 'Danny Owner',
                billingAccountAddress: '',
                canEditContacts: false,
                depositor: false,
                id: 267,
                partyContacts: [],
                partyStatus: 'Live',
                partyStatusDate: '2014-03-11T00:00:00Z',
                partyType: 'Licensee',
                registeredName: 'Danny Owner'
            },
            {
                billingAccount: 'JS002',
                billingAccountAddress: '',
                canEditContacts: false,
                depositor: false,
                id: 268,
                partyContacts: [],
                partyStatus: 'Live',
                partyStatusDate: '2014-03-11T00:00:00Z',
                partyType: 'Licensee',
                registeredName: 'JS002'
            },
            {
                billingAccount: 'Roisin Test Acccount 10',
                billingAccountAddress: '',
                canEditContacts: false,
                depositor: false,
                id: 270,
                partyContacts: [],
                partyStatus: 'Live',
                partyStatusDate: '2014-03-11T00:00:00Z',
                partyType: 'Licensee',
                registeredName: 'Roisin Test Acccount 10'
            },
            {
                billingAccount: 'NCC E2E 1',
                billingAccountAddress: '',
                canEditContacts: false,
                depositor: false,
                id: 271,
                partyContacts: [],
                partyStatus: 'Live',
                partyStatusDate: '2014-03-11T00:00:00Z',
                partyType: 'Licensee',
                registeredName: 'NCC E2E 1'
            },
            {
                billingAccount: 'NCC E2E 2',
                billingAccountAddress: '',
                canEditContacts: false,
                depositor: false,
                id: 272,
                partyContacts: [],
                partyStatus: 'Live',
                partyStatusDate: '2014-03-11T00:00:00Z',
                partyType: 'Licensee',
                registeredName: 'NCC E2E 2'
            },
            {
                billingAccount: 'JS004',
                billingAccountAddress: '',
                canEditContacts: false,
                depositor: false,
                id: 273,
                partyContacts: [],
                partyStatus: 'Live',
                partyStatusDate: '2014-03-11T00:00:00Z',
                partyType: 'Licensee',
                registeredName: 'JS004'
            },
            {
                billingAccount: 'JS006',
                billingAccountAddress: '',
                canEditContacts: false,
                depositor: false,
                id: 274,
                partyContacts: [],
                partyStatus: 'Live',
                partyStatusDate: '2014-03-11T00:00:00Z',
                partyType: 'Licensee',
                registeredName: 'JS006'
            },
            {
                billingAccount: 'JS001',
                billingAccountAddress: '',
                canEditContacts: false,
                depositor: false,
                id: 275,
                partyContacts: [],
                partyStatus: 'Live',
                partyStatusDate: '2014-03-11T00:00:00Z',
                partyType: 'Licensee',
                registeredName: 'JS001'
            },
            {
                billingAccount: 'JS002',
                billingAccountAddress: '',
                canEditContacts: false,
                depositor: false,
                id: 276,
                partyContacts: [],
                partyStatus: 'Live',
                partyStatusDate: '2014-03-11T00:00:00Z',
                partyType: 'Licensee',
                registeredName: 'JS002'
            },
            {
                billingAccount: 'E2E RMCG1',
                billingAccountAddress: '',
                canEditContacts: false,
                depositor: false,
                id: 277,
                partyContacts: [],
                partyStatus: 'Live',
                partyStatusDate: '2014-03-11T00:00:00Z',
                partyType: 'Licensee',
                registeredName: 'E2E RMCG1'
            },
            {
                billingAccount: 'Roisin E2E',
                billingAccountAddress: '',
                canEditContacts: false,
                depositor: false,
                id: 278,
                partyContacts: [],
                partyStatus: 'Live',
                partyStatusDate: '2014-03-11T00:00:00Z',
                partyType: 'Licensee',
                registeredName: 'Roisin E2E'
            },
            {
                billingAccount: 'NCC E2E 3',
                billingAccountAddress: '',
                canEditContacts: false,
                depositor: false,
                id: 279,
                partyContacts: [],
                partyStatus: 'Live',
                partyStatusDate: '2014-03-11T00:00:00Z',
                partyType: 'Licensee',
                registeredName: 'NCC E2E 3'
            },
            {
                billingAccount: 'Hooman escrow 2',
                billingAccountAddress: '',
                canEditContacts: false,
                depositor: false,
                id: 280,
                partyContacts: [],
                partyStatus: 'Live',
                partyStatusDate: '2014-03-11T00:00:00Z',
                partyType: 'Licensee',
                registeredName: 'Hooman escrow 2'
            },
            {
                billingAccount: 'JS001',
                billingAccountAddress: '',
                canEditContacts: false,
                depositor: false,
                id: 281,
                partyContacts: [],
                partyStatus: 'Live',
                partyStatusDate: '2014-03-11T00:00:00Z',
                partyType: 'Licensee',
                registeredName: 'JS001'
            },
            {
                billingAccount: 'roisin Test Account 11',
                billingAccountAddress: '',
                canEditContacts: false,
                depositor: false,
                id: 283,
                partyContacts: [],
                partyStatus: 'Live',
                partyStatusDate: '2014-03-11T00:00:00Z',
                partyType: 'Licensee',
                registeredName: 'roisin Test Account 11'
            }
        ],
        liveDate: '2014-03-11T00:00:00Z',
        location: 'SAN',
        numberOfAssociatedParties: 24,
        owners: [
            {
                billingAccount:
                    'Chelsea and Westminster Hospital NHS Foundation Trust',
                billingAccountAddress: '',
                canEditContacts: true,
                depositor: true,
                id: 269,
                partyContacts: [
                    {
                        contactId: 67,
                        id: 185,
                        name: 'Jay Patel',
                        roles: ['Main']
                    },
                    {
                        contactId: 68,
                        id: 186,
                        name: 'Wadood Ashraf',
                        roles: ['Main']
                    },
                    {
                        contactId: 89,
                        id: 216,
                        name: 'Diana Bal',
                        roles: ['Main']
                    },
                    {
                        contactId: 183,
                        id: 260,
                        name: 'Latch Jack',
                        roles: ['Main']
                    }
                ],
                partyStatus: 'Live',
                partyStatusDate: '2021-11-26T08:25:24.46Z',
                partyType: 'Owner',
                registeredName:
                    'Chelsea and Westminster Hospital NHS Foundation Trust'
            }
        ],
        renewalDate: '2020-03-01T00:00:00Z',
        softwarePackageName: 'Emphasys Enterprise Solutions',
        uploaderName: 'US',
        viewingPartyCanUploadDeposit: true
    },
    isValid: true,
    items: [
        {
            location: null,
            method: 'Upload',
            platform: 'uksouth',
            priorityOrder: 1,
            region: 'uksouth',
            status: 'uksouth'
        },
        {
            location: null,
            method: 'Upload',
            platform: 'eastus',
            priorityOrder: 2,
            region: 'eastus',
            status: 'eastus'
        }
    ],
    validationErrors: {}
};

export default useAgreementsDetailsMockData;
