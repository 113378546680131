import { CommonProps } from '@ncc-frontend/core';
import { Fragment, useCallback, useState } from 'react';
import classNames from 'classnames';

import Bar from './bar';
import ValueTooltip from './value-tooltip';
import XAxis, { XAxisProps } from './x-axis';
import useBarPlot, { PlotParams } from './use-bar-plot';

interface BarPlotProps extends Partial<PlotParams>, CommonProps {
    loading?: boolean;
}

function BarPlot({
    className,
    data = [],
    loading = false,
    dataPadding = 0.2,
    xAxisHeight = 35,
    xAxisPadding = 15,
    yAxisPadding = 4,
    ...restProps
}: BarPlotProps) {
    const [selectedXIndex, setSelectedXIndex] = useState<number>();

    const { setRef, viewBoxSize, xAxis, yAxis, yData } = useBarPlot({
        data,
        dataPadding,
        xAxisHeight,
        xAxisPadding,
        yAxisPadding
    });

    const handleXAxisSelected = useCallback<XAxisProps['onSelected']>(
        (value) => {
            setSelectedXIndex(value);
        },
        []
    );

    return (
        <div
            {...restProps}
            ref={setRef}
            className={classNames('relative', className)}
        >
            <svg
                viewBox={`0 0 ${viewBoxSize.width} ${viewBoxSize.height}`}
                className="absolute top-0 left-0"
                width={viewBoxSize.width}
                height={viewBoxSize.height}
            >
                <>
                    {/* Grid */}
                    {!loading &&
                        yAxis.map(({ label, x, x1, x2, y }) => (
                            <Fragment key={y}>
                                <text
                                    x={x}
                                    y={y}
                                    textAnchor="end"
                                    dominantBaseline="middle"
                                    className="text-xs fill-brand-escode-neonblue-neonblue-10"
                                >
                                    {label}
                                </text>
                                <line
                                    x1={x1}
                                    y1={y}
                                    x2={x2}
                                    y2={y}
                                    className="stroke-brand-escode-neonblue-neonblue-10"
                                />
                            </Fragment>
                        ))}
                    {!loading &&
                        xAxis[0]?.data.map(({ label, rect, x, y }, index) => (
                            <XAxis
                                key={label}
                                label={label}
                                index={index}
                                active={selectedXIndex === index}
                                rect={rect}
                                x={x}
                                y={y}
                                onSelected={handleXAxisSelected}
                            />
                        ))}

                    {/* Data */}
                    {yData.map((trace, traceIndex) =>
                        trace.data.map(({ x, y1, y2 }, index) => (
                            <Fragment key={`${traceIndex}-${index}`}>
                                <Bar
                                    index={index}
                                    onSelected={handleXAxisSelected}
                                    active={selectedXIndex === index}
                                    x={x}
                                    y1={y1}
                                    y2={y2}
                                    width={trace.width}
                                    color={trace.color}
                                    loading={loading}
                                />
                            </Fragment>
                        ))
                    )}

                    {/* Tooltips */}
                    {!loading &&
                        yData.map((trace, traceIndex) =>
                            trace.data.map(({ label }, index) => (
                                <Fragment key={`${traceIndex}-${index}`}>
                                    <ValueTooltip
                                        hidden={selectedXIndex !== index}
                                        height={label.height}
                                        value={label.value}
                                        width={label.width}
                                        x={label.x}
                                        y={label.y}
                                        color={trace.color}
                                    />
                                </Fragment>
                            ))
                        )}
                </>
            </svg>
        </div>
    );
}

export default BarPlot;
export type { BarPlotProps };
