function timestampFormatter(
    value: string | Date | null | undefined,
    locale?: string
) {
    // TODO use moment + getDatePattern to format dates.
    if (!value) return '';

    if (typeof value === 'object')
        return value.toLocaleString(locale).replace(/,/g, '');

    return new Date(value).toLocaleString(locale).replace(/,/g, '');
}

export default timestampFormatter;
