import { DEPOSIT_FREQUENCY_MAPPER } from 'core/api/client-portal/model/deposit-frequency';
import { DownloadScheduleItem } from 'core/api/client-portal/autogenerated/data-contracts';
import { FilterFieldData } from 'lib-ui/widget/data/data-widget-types';
import { ICellRendererParams, ValueGetterParams } from 'lib-ui';
import { SchedulesTableContext } from './schedules-table-context';

import ScheduleFrequencyTag from 'ui/schedules/schedule-frequency-tag';

function getScheduleFrequencyOptions(
    context: SchedulesTableContext
): FilterFieldData['options'] {
    const { beautifyFrequency } = context;
    return [
        { key: 'daily', label: beautifyFrequency('daily') },
        { key: 'weekly', label: beautifyFrequency('weekly') },
        { key: 'monthly', label: beautifyFrequency('monthly') },
        { key: 'quarterly', label: beautifyFrequency('quarterly') },
        { key: 'annually', label: beautifyFrequency('annually') }
    ];
}

function schedulesFrequencyTypeValueGetter({
    context: { beautifyFrequency },
    data
}: Pick<
    ValueGetterParams<DownloadScheduleItem, SchedulesTableContext>,
    'context' | 'data'
>) {
    return beautifyFrequency(
        DEPOSIT_FREQUENCY_MAPPER[data?.frequencyType ?? 0]
    );
}

function SchedulesFrequencyTypeCellRenderer({
    data,
    value
}: ICellRendererParams<DownloadScheduleItem>) {
    return (
        <div className="flex h-full items-center">
            <ScheduleFrequencyTag
                value={DEPOSIT_FREQUENCY_MAPPER[data?.frequencyType ?? 0]}
            >
                {value}
            </ScheduleFrequencyTag>
        </div>
    );
}

export default SchedulesFrequencyTypeCellRenderer;
export { getScheduleFrequencyOptions, schedulesFrequencyTypeValueGetter };
