import {
    CSSProperties,
    ForwardedRef,
    PropsWithChildren,
    forwardRef
} from 'react';
import { TailwindProps, tailwindClasses } from '@ncc-frontend/core';
import { merge } from 'lodash';
import classNames from 'classnames';

interface WidgetBodyProps extends TailwindProps {
    /** Css units: px, rem, ... */
    headerHeight?: string;
    // TODO: implement
    loading?: boolean;
}

function WidgetBody(
    {
        children,
        className,
        headerHeight,
        loading,
        style,
        tailwindStyle,
        ...restProps
    }: PropsWithChildren<WidgetBodyProps>,
    ref: ForwardedRef<HTMLDivElement>
) {
    const cssClasses = classNames(
        tailwindClasses(
            { flex: 'flex-1', position: 'relative' },
            tailwindStyle
        ),
        className
    );

    const mergedStyle = merge<CSSProperties, CSSProperties | undefined>(
        {
            maxHeight: `calc(100% - ${
                headerHeight || 'var(--escode-widget-height)'
            })`
        },
        style
    );

    return (
        <div
            {...restProps}
            ref={ref}
            className={cssClasses}
            style={mergedStyle}
        >
            {children}
        </div>
    );
}

export default forwardRef(WidgetBody);
export type { WidgetBodyProps };
