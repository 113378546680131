import { Modal } from 'lib-ui';
import { useTranslation } from 'react-i18next';

import AuditHistoryTableItem from './audit-history-table-item';
import useContactsAudit from 'core/api/client-portal/hooks/contacts/use-contacts-audit';

function ContactsAuditHistoryModal() {
    const { t } = useTranslation();

    const { data: auditHistory } = useContactsAudit({});
    const headingStyle =
        'text-start text-sm text-general-grey-grey-90 font-medium px-2.5 py-1 sticky top-0 bg-escode-grey-10';

    /**
     * The div is in the modal body to mask the table data that scrolls behind the sticky table header. It is relative to the table as if relative is applied to the modal body instead, with div will be included in the scrolling.
     */
    return (
        <Modal size="l">
            <Modal.Header>
                {t('contacts-audit-history-modal.heading')}
            </Modal.Header>
            <Modal.Body>
                <div className="z-20 bg-white h-escode-l absolute left-0 right-0 top-[3.47rem]" />
                <table className="z-10 w-full relative">
                    <thead className="sticky top-0">
                        <tr>
                            <th className={headingStyle}>
                                {t('contacts-audit-history-modal.type')}
                            </th>
                            <th className={headingStyle}>
                                {t('contacts-audit-history-modal.date')}
                            </th>
                            <th className={headingStyle}>
                                {t('contacts-audit-history-modal.user')}
                            </th>
                            <th className={headingStyle}>
                                {t('contacts-audit-history-modal.description')}
                            </th>
                        </tr>
                    </thead>
                    <tbody className="z-10">
                        {auditHistory?.auditRecords?.map((item, index) => {
                            return (
                                <AuditHistoryTableItem
                                    item={item}
                                    key={index}
                                />
                            );
                        })}
                    </tbody>
                </table>
            </Modal.Body>
        </Modal>
    );
}

export default ContactsAuditHistoryModal;
