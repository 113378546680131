import { ApiController } from '../../autogenerated/Api';
import { AxiosResponse } from 'axios';
import { MutationOptions, useMutation } from 'react-query';
import { useAuth } from 'react-oidc-context';
import interceptors from 'core/api/interceptors';
import securityWorker from 'core/api/security-worker';

type DeleteUploadParams = {
    dataCentre: 'NCC:UK' | 'NCC:US';
    depositUniqueReference: string;
    uniqueReference: string;
};

function useDeleteUpload<TError = unknown, TContext = unknown>(
    options?: Omit<
        MutationOptions<
            AxiosResponse<void, unknown>,
            ReactQueryError<TError>,
            DeleteUploadParams,
            TContext
        >,
        'mutationFn'
    >
) {
    const auth = useAuth();

    return useMutation<
        AxiosResponse<void, unknown>,
        ReactQueryError<TError>,
        DeleteUploadParams,
        TContext
    >(
        [
            'upload-complete',
            {
                token: auth.user?.access_token,
                tokenType: auth.user?.token_type
            }
        ],
        async ({ dataCentre, depositUniqueReference, uniqueReference }) => {
            if (process.env.REACT_APP_API_MOCK) {
                console.warn('Mock is enabled. Upload complete call ignored.', {
                    uniqueReference
                });
                return Promise.resolve({} as AxiosResponse<void, unknown>);
            }
            const baseURL =
                dataCentre === 'NCC:US'
                    ? process.env.REACT_APP_US_UPLOADER_ENDPOINT
                    : process.env.REACT_APP_UK_UPLOADER_ENDPOINT;
            const controller = new ApiController({
                baseURL,
                headers: {
                    'Cache-FormControl': 'no-cache, no-store, must-revalidate',
                    Expires: '0'
                },
                interceptors,
                securityWorker: securityWorker(
                    auth.user?.token_type,
                    auth.user?.access_token
                )
            });

            return await controller.deleteUpload(depositUniqueReference, {
                FileId: uniqueReference
            });
        },
        {
            ...options
        }
    );
}

export default useDeleteUpload;
