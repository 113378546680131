import { Button, ConfirmationTooltip, ICellRendererParams } from 'lib-ui';
import { DownloadScheduleItem } from 'core/api/client-portal/autogenerated/data-contracts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SchedulesTableContext } from './schedules-table-context';
import { faDiamondExclamation } from '@fortawesome/pro-solid-svg-icons';

import EditScheduleModal from 'ui/schedules/edit-schedule-modal/edit-schedule-modal';

function SchedulesActionsCellRenderer({
    context: { loading, push, requestScheduleDelete, t },
    data
}: ICellRendererParams<DownloadScheduleItem, SchedulesTableContext>) {
    const amend = () => {
        push(EditScheduleModal, { data });
    };

    const remove = () => {
        requestScheduleDelete({ query: { downloadId: data.id } });
    };

    const canEdit = data.canEdit && !loading;
    const canDelete = data.canDelete && !loading;

    return (
        <div className="flex h-full items-center gap-2.5">
            <Button variant="tertiary" onClick={amend} disabled={!canEdit}>
                {t('schedules.amend')}
            </Button>
            <ConfirmationTooltip
                placement="left"
                icon={<FontAwesomeIcon icon={faDiamondExclamation} />}
                title={t('schedule.delete-tooltip-title')}
                onConfirm={remove}
                variant="warning"
                confirmMessage={t('tooltip.proceed')}
                rejectMessage={t('tooltip.reject')}
                disabled={!canDelete}
            >
                <Button variant="danger" disabled={!canDelete}>
                    {t('delete')}
                </Button>
            </ConfirmationTooltip>
        </div>
    );
}

export default SchedulesActionsCellRenderer;
