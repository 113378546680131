import {
    CommonProps,
    Dropdown,
    DropdownContextValue,
    DropdownProps,
    Placement
} from '@ncc-frontend/core';
import {
    ForwardedRef,
    PropsWithChildren,
    ReactElement,
    ReactNode,
    forwardRef,
    useCallback,
    useState
} from 'react';
import classNames from 'classnames';

import TooltipPanel, { TooltipPanelProps } from './tooltip-panel';

interface TooltipProps extends CommonProps {
    message?: ReactNode;
    onOpen?: DropdownProps['onOpen'];
    panelProps?: TooltipPanelProps;
    placement?: Placement;
    title?: ReactNode;
    trigger?: DropdownProps['trigger'];
}

function Tooltip(
    {
        children,
        className,
        message,
        panelProps,
        ...restProps
    }: PropsWithChildren<TooltipProps>,
    ref: ForwardedRef<DropdownContextValue>
): ReactElement | null {
    const cssClasses = classNames(className, '');
    const [finalPlacement, setFinalPlacement] = useState(restProps.placement);

    const handlePlacementChange = useCallback((placement: Placement) => {
        setFinalPlacement(placement);
    }, []);

    return (
        <Dropdown
            {...restProps}
            ref={ref}
            className={cssClasses}
            offset={4}
            onPlacementChange={handlePlacementChange}
            panel={
                <TooltipPanel {...panelProps} placement={finalPlacement}>
                    {message}
                </TooltipPanel>
            }
            suppressMaxSize
        >
            {children}
        </Dropdown>
    );
}

export default forwardRef(Tooltip);
export type { TooltipProps };
