const yesOrNo = (value: boolean): 'yes' | 'no' | null => {
    if (value === null) {
        return null;
    }
    if (typeof value === 'boolean') {
        return value ? 'yes' : 'no';
    }
    return null;
};

export default yesOrNo;
