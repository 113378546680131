import { Dropdown, useDropdown } from '@ncc-frontend/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilterList } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import DataHeaderButton from '../data-header-button/data-header-button';
import FilterWizard from './filter-wizard';
import useDataWidget from '../use-data-widget';

interface FilterButtonProps {
    disabled?: boolean;
}

function FilterButton({ disabled }: FilterButtonProps) {
    const { t } = useTranslation();
    const { openPanelId } = useDropdown();
    const { filters, loading } = useDataWidget();

    const panelId = 'filter';

    const countCssClasses = classNames(
        'flex items-center justify-center w-4 h-4 rounded-full',
        'bg-brand-escode-neonblue-neonblue-100',
        'text-escode-3xs',
        'transition-all',
        {
            'bg-ncc-white text-brand-escode-neonblue-neonblue-100':
                openPanelId === panelId,
            'text-ncc-white': openPanelId !== panelId
        }
    );

    return (
        <Dropdown.Trigger
            panelId={panelId}
            panel={<FilterWizard />}
            disabled={loading || disabled}
        >
            <DataHeaderButton
                disabled={loading || disabled}
                className="group"
                active={openPanelId === panelId}
                icon={<FontAwesomeIcon icon={faFilterList} />}
            >
                {t('table.filter')}
                {!!filters.filter(({ model }) => !!model).length && (
                    <span className={countCssClasses}>{filters.length}</span>
                )}
            </DataHeaderButton>
        </Dropdown.Trigger>
    );
}

export default FilterButton;
export type { FilterButtonProps };
