/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormProvider, useForm } from 'react-hook-form';
import { InputField, Section, SelectField } from 'lib-ui';
import { provenceOptions } from './contact-details-options';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import DNStep from '../deposit-now-step/dn-step';
import schema from './contact-details-step-schema';
import toastFunctions from 'lib-ui/toast/Toast';
import useDepositDetailsV2 from 'core/api/client-portal-v2/hooks/deposits/use-deposit-details-v2';
import useEditDepositContactDetails from 'core/api/client-portal-v2/hooks/deposits/use-edit-deposit-contact';

type FormData = {
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    city: string;
    companyName: string;
    contactName: string;
    country: string;
    county: string;
    emailAddress: string;
    jobTitle: string;
    postCode: string;
    telephoneNumber: string;
};

const ContactDetailsStep = () => {
    const { depositId } = useParams();
    const { t } = useTranslation();
    const goTo = useNavigate();

    const { data, isFetching } = useDepositDetailsV2(
        depositId as unknown as number
    );

    const { isLoading, mutate: updateContactDetails } =
        useEditDepositContactDetails(depositId as unknown as number);

    // const toggleAutoFill = useCallback(() => {
    //     console.log('autofill');
    // }, []);

    const methods = useForm<FormData>({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        resolver: yupResolver(schema),
        values: {
            addressLine1: data?.contactDetails.addressLine1 || '',
            addressLine2: data?.contactDetails.addressLine2 || '',
            addressLine3: data?.contactDetails.addressLine3 || '',
            city: data?.contactDetails.city || '',
            companyName: data?.contactDetails.companyName || '',
            contactName: data?.contactDetails.contactName || '',
            country: data?.contactDetails.country || '',
            county: data?.contactDetails.county || '',
            emailAddress: data?.contactDetails.emailAddress || '',
            jobTitle: data?.contactDetails.jobTitle || '',
            postCode: data?.contactDetails.postCode || '',
            telephoneNumber: data?.contactDetails.telephoneNumber || ''
        }
    });

    const submit = useCallback(
        (formData: any) => {
            updateContactDetails(formData, {
                onError() {
                    toastFunctions.error(t('toast.error'));
                },
                onSuccess() {
                    toastFunctions.success(t('toast.success'));
                    goTo(`/deposit-now/${depositId}/deposit-details`);
                }
            });
        },
        [depositId, goTo, t, updateContactDetails]
    );

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(submit)}>
                <DNStep
                    // actions={
                    //     <Button
                    //         variant="tertiary"
                    //         onClick={toggleAutoFill}
                    //         type="button"
                    //     >
                    //         {t('deposit-now-stage-one.auto-fill')}
                    //     </Button>
                    // }
                    classNames="w-3/4 flex gap-5"
                    disableBack
                    loading={isFetching}
                    submitting={isLoading}
                    title={t('deposit-now-stage-two.title')}
                >
                    <Section
                        heading={t('deposit-now-stage-two.personal-details')}
                    >
                        <InputField
                            label={t('deposit-now-stage-two.contact-name')}
                            name="contactName"
                            placeholder={t(
                                'deposit-now-stage-two.contact-name'
                            )}
                            className="w-full"
                            required
                        />

                        <InputField
                            description={t(
                                'deposit-now-stage-two.company-name-detail'
                            )}
                            disabled
                            label={t(
                                'deposit-now-stage-two.company-name-title'
                            )}
                            name="companyName"
                            placeholder={t(
                                'deposit-now-stage-two.company-name'
                            )}
                            required
                            tailwindStyle={{
                                width: 'w-full'
                            }}
                        />

                        <InputField
                            label={t('deposit-now-stage-two.email')}
                            name="emailAddress"
                            type="email"
                            placeholder={t(
                                'deposit-now-stage-two.email-placeholder'
                            )}
                            tailwindStyle={{
                                width: 'w-full'
                            }}
                            tooltip="deposit-now-stage-two.confirm"
                            required
                        />

                        <InputField
                            label={t('deposit-now-stage-two.phone')}
                            name="telephoneNumber"
                            placeholder={t(
                                'deposit-now-stage-two.phone-placeholder'
                            )}
                            className="w-full"
                            required
                        />

                        <InputField
                            label={t('deposit-now-stage-two.job')}
                            name="jobTitle"
                            placeholder={t(
                                'deposit-now-stage-two.job-placeholder'
                            )}
                            tailwindStyle={{
                                width: 'w-full'
                            }}
                            required
                        />
                    </Section>

                    <Section
                        heading={t('deposit-now-stage-two.address-details')}
                    >
                        <InputField
                            label={t('deposit-now-stage-two.address-line-one')}
                            name="addressLine1"
                            placeholder={t(
                                'deposit-now-stage-two.job-placeholder'
                            )}
                            tailwindStyle={{
                                width: 'w-full'
                            }}
                            required
                        />

                        <InputField
                            label={t('deposit-now-stage-two.address-line-two')}
                            name="addressLine2"
                            placeholder={t(
                                'deposit-now-stage-two.job-placeholder'
                            )}
                            tailwindStyle={{
                                width: 'w-full'
                            }}
                        />
                        <InputField
                            label={t(
                                'deposit-now-stage-two.address-line-three'
                            )}
                            name="addressLine3"
                            placeholder={t(
                                'deposit-now-stage-two.job-placeholder'
                            )}
                            tailwindStyle={{
                                width: 'w-full'
                            }}
                        />
                        <InputField
                            label={t('deposit-now-stage-two.city')}
                            name="city"
                            placeholder={t(
                                'deposit-now-stage-two.job-placeholder'
                            )}
                            tailwindStyle={{
                                width: 'w-full'
                            }}
                            required
                        />

                        <div className="flex gap-2 justify-around">
                            <InputField
                                label={t('deposit-now-stage-two.county')}
                                name="county"
                                placeholder={t(
                                    'deposit-now-stage-two.job-placeholder'
                                )}
                                tailwindStyle={{
                                    width: 'w-full'
                                }}
                            />
                            <InputField
                                label={t('deposit-now-stage-two.post-code')}
                                name="postCode"
                                placeholder={t(
                                    'deposit-now-stage-two.job-placeholder'
                                )}
                                tailwindStyle={{
                                    width: 'w-full'
                                }}
                                required
                            />
                        </div>
                        <SelectField
                            label={t('deposit-now-stage-two.country')}
                            name="country"
                            placeholder="Select a country"
                            options={provenceOptions}
                            tailwindStyle={{
                                width: 'w-full'
                            }}
                            required
                        />
                    </Section>
                </DNStep>
            </form>
        </FormProvider>
    );
};

export default ContactDetailsStep;
