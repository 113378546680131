import {
    Tabs as CoreTabs,
    TabsProps as CoreTabsProps,
    TailwindStyle
} from '@ncc-frontend/core';
import { PropsWithChildren, useMemo } from 'react';
import { merge } from 'lodash';
import TabsTab from './tabs-tab';

interface TabsProps extends CoreTabsProps {}

function Tabs({
    buttonsWrapperTailwindStyle,
    children,
    tailwindStyle,
    ...restProps
}: PropsWithChildren<TabsProps>) {
    const mergedTailwindStyle = useMemo(
        () =>
            merge<TailwindStyle, TailwindStyle | undefined>({}, tailwindStyle),
        [tailwindStyle]
    );

    const mergedButtonsWrapperTailwindStyle = useMemo(
        () =>
            merge<TailwindStyle, TailwindStyle | undefined>(
                { gap: 'gap-2.5', padding: 'p-2.5' },
                buttonsWrapperTailwindStyle
            ),
        [buttonsWrapperTailwindStyle]
    );

    return (
        <CoreTabs
            {...restProps}
            buttonsWrapperTailwindStyle={mergedButtonsWrapperTailwindStyle}
            tailwindStyle={mergedTailwindStyle}
        >
            {children}
        </CoreTabs>
    );
}
Tabs.Tab = TabsTab;

export default Tabs;
export type { TabsProps };
