import { EscrowDepositStatus } from 'core/api/client-portal/autogenerated/data-contracts';
import { PropsWithChildren, useMemo } from 'react';
import { Tag } from 'lib-ui';
import { TailwindStyle } from '@ncc-frontend/core';

// BACKEND: we shouldn't need this type. Instead swagger should use an string like enum
// instead of a numerical enum for EscrowDepositStatus
enum DepositStatus {
    // None.
    None = 0,

    // The deposit form is incomplete.
    Incomplete = 1,

    // The deposit is currently uploading files.
    InProgress = 2,

    // The deposit has been discarded by the client user.
    Discarded = 4,

    // The deposit has been canceled by the integrity team.
    Cancelled = 5,

    // The deposit has been confirmed.
    Confirmed = 6,

    // The deposit has been copied and is awaiting burn to disk.
    AwaitingBurn = 7,

    // The deposit has been burnt to media
    BurntToMedia = 8,

    // The deposit is assigned for a full verification
    FullVerificationAssigned = 9,

    // The deposit is awaiting a deposit check
    AwaitingTesting = 10,

    // The deposit is awaiting scanning
    AwaitingScanning = 11,

    // The deposit has been deposited
    Deposited = 12,

    // The deposit has been canceled by the integrity team.
    Cancelling = 13,

    // The source control upload failed
    UploadFailed = 14,

    // Testing complete
    TestingComplete = 15,

    // Pending IT
    PendingIT = 16,

    // Destroyed
    Destroyed = 17,

    // Cloning
    Cloning = 18,

    // Undergoing IT
    UndergoingIT = 19,

    // To Be Transferred
    ToBeTransferred = 20,

    // Transferred
    Transferred = 21,

    // Returning
    Returning = 22,

    // Returned
    Returned = 23,

    // Destroying
    Destroying = 24,

    // Undergoing Testing - A generic status to display to users, could be Awaiting Scanning, Burnt to Media, Awaiting Putaway, Awaiting Burn etc
    UndergoingTesting = 25,

    // Not present in database; shown when scheduled deposits are incomplete.
    Scheduled = 26,

    // Not present in database; shown when scheduled deposits are in progress.
    ScheduleInProgress = 27,

    // Discarding; the deposit has been discarded, but files can still be recovered.
    Discarding = 28
}

interface DepositStatusTagProps {
    value: EscrowDepositStatus | undefined;
}

function DepositStatusTag({
    children,
    value,
    ...restProps
}: PropsWithChildren<DepositStatusTagProps>) {
    const tailwindStyle = useMemo<TailwindStyle>(() => {
        switch (value as unknown as DepositStatus) {
            // case DepositStatus.None:
            case DepositStatus.Deposited:
            case DepositStatus.Confirmed:
            case DepositStatus.FullVerificationAssigned:
            case DepositStatus.TestingComplete:
            case DepositStatus.Transferred:
            case DepositStatus.Scheduled:
                return {
                    background:
                        'bg-brand-escode-harlequingreen-harlequingreen-40',
                    textColor:
                        'text-brand-escode-tourmalineblack-tourmalineblack-100'
                };
            case DepositStatus.UploadFailed:
            case DepositStatus.Incomplete:
            case DepositStatus.Discarded:
            case DepositStatus.Discarding:
            case DepositStatus.Cancelling:
            case DepositStatus.Cancelled:
            case DepositStatus.Destroyed:
            case DepositStatus.Destroying:
            case DepositStatus.Returned:
                return {
                    background: 'bg-general-red-red-10',
                    textColor: 'text-general-red-red-100'
                };
            case DepositStatus.InProgress:
            case DepositStatus.AwaitingBurn:
            case DepositStatus.AwaitingTesting:
            case DepositStatus.AwaitingScanning:
            case DepositStatus.PendingIT:
            case DepositStatus.Cloning:
            case DepositStatus.UndergoingIT:
            case DepositStatus.Returning:
            case DepositStatus.ScheduleInProgress:
            case DepositStatus.UndergoingTesting:
            case DepositStatus.BurntToMedia:
            case DepositStatus.ToBeTransferred:
            default:
                return {
                    background: 'bg-general-orange-orange-10',
                    textColor: 'text-general-orange-orange-100'
                };
        }
    }, [value]);

    return (
        <Tag {...restProps} tailwindStyle={tailwindStyle}>
            {children}
        </Tag>
    );
}

export default DepositStatusTag;
export { DepositStatus };
export type { DepositStatusTagProps };
