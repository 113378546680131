import DepositNowContainer from '../../ui/deposit-now/deposit-now-container';
import React from 'react';
import ReviewDepositStep from '../../ui/deposit-now/review-deposit-step/review-deposit-step';

const DepositNowReview = () => {
    return (
        <DepositNowContainer step="review-deposit">
            <ReviewDepositStep />
        </DepositNowContainer>
    );
};

export default DepositNowReview;
