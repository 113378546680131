import { Button, ConfirmationTooltip, Divider } from 'lib-ui';
import { FixedSourceProvider } from 'core/api/client-portal/hooks/providers/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SourceControlConnectionsGridContext } from '../source-control-connections-grid-context';
import { faDiamondExclamation } from '@fortawesome/pro-solid-svg-icons';
import { isNull, isUndefined } from 'lodash';
import { useCallback } from 'react';

import EditSourceControlConnectionModal from 'ui/source-control-connections/edit-source-control-connection-modal/edit-source-control-connection-modal';

interface ProviderCardProps {
    context: SourceControlConnectionsGridContext;
    data: FixedSourceProvider;
}

function ProviderCard({
    context: { beautifyProviderType, deleteProvider, push, t },
    data: {
        authenticationType,
        connectionName,
        id,
        password,
        privateKey,
        providerType,
        repositoryAddress,
        schedules,
        userName
    }
}: ProviderCardProps) {
    const dtCssClasses = 'text-general-grey-grey-90 font-medium';
    const ddCssClasses = 'h-5 text-sm truncate mb-2';

    const isRemoveDisabled = isUndefined(id) || isNull(id);

    const edit = useCallback(() => {
        push(EditSourceControlConnectionModal, {
            authenticationType: authenticationType?.toString(),
            connectionName,
            password,
            privateKey,
            providerId: id ?? undefined,
            providerType: providerType?.toString(),
            repositoryAddress,
            userName
        });
    }, [
        authenticationType,
        connectionName,
        id,
        password,
        privateKey,
        providerType,
        push,
        repositoryAddress,
        userName
    ]);

    const remove = useCallback(() => {
        if (isRemoveDisabled) return;
        deleteProvider({ query: { providerId: id } });
    }, [deleteProvider, id, isRemoveDisabled]);

    return (
        <div className="w-80 p-4 flex flex-col gap-2.5 rounded-lg bg-ncc-white border">
            <div className="text-brand-escode-neonblue-neonblue-100 font-medium text-center">
                {t('provider-card.title', { id })}
            </div>
            <Divider color="bg-general-grey-grey-10" />
            <dl>
                <dt className={dtCssClasses}>
                    {t('provider-card.connection-name')}
                </dt>
                <dd className={ddCssClasses}>{connectionName}</dd>

                <dt className={dtCssClasses}>
                    {t('provider-card.provider-type')}
                </dt>
                <dd className={ddCssClasses}>
                    {beautifyProviderType(providerType)}
                </dd>

                <dt className={dtCssClasses}>
                    {t('provider-card.repository-address')}
                </dt>
                <dd className={ddCssClasses}>{repositoryAddress}</dd>
            </dl>
            <Button variant="secondary" onClick={edit}>
                {t('provider-card.details')}
            </Button>
            <ConfirmationTooltip
                placement="left"
                icon={<FontAwesomeIcon icon={faDiamondExclamation} />}
                title={t('provider-card.delete-tooltip-title')}
                message={
                    !isUndefined(schedules) && schedules > 0 ? t('provider-card.delete-tooltip-message-complex') :
                        t('provider-card.delete-tooltip-message-simple')
                }
                onConfirm={remove}
                variant="warning"
                confirmMessage={t('tooltip.proceed')}
                rejectMessage={t('tooltip.reject')}
                disabled={isRemoveDisabled}
                className='font-medium'
            >
                <Button
                    variant="danger"
                    disabled={isRemoveDisabled}
                    className="w-full"
                >
                    {t('provider-card.delete')}
                </Button>
            </ConfirmationTooltip>
        </div>
    );
}

export default ProviderCard;
export type { ProviderCardProps };
