import { CalendarData } from '../types';
import { useEffect } from 'react';
import CalendarWeekColumn from './calendar-week-column';
import CalendarWeekHeaderDay from './calendar-week-header-day';
import ScrollHarness from '../../scroll-harness/scroll-harness';
import isSameDate from 'core/utils/is-same-date';
import moment from 'moment';

interface CalendarWeekProps {
    data: CalendarData[] | undefined;
    onUpdateHasData: (newValue: boolean) => void;
    weekStart: Date;
}

function CalendarWeek({
    data,
    onUpdateHasData,
    weekStart: propWeekStart
}: CalendarWeekProps) {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const weekStart = new Date(propWeekStart);
    const weekDays: Date[] = [];

    for (let day = 0; day < 7; day++) {
        weekDays.push(new Date(weekStart));
        weekStart.setDate(weekStart.getDate() + 1);
    }
    useEffect(() => {
        if (data) {
            const weekData = data.filter((obj) => {
                const date = obj.date;
                return date >= propWeekStart && date < weekStart;
            });
            onUpdateHasData(weekData.length > 0);
        }
    }, [data, onUpdateHasData, propWeekStart, weekStart]);

    return (
        <table
            role="grid"
            className="h-full w-full border-collapse table-fixed"
        >
            <thead>
                <tr role="presentation">
                    <th role="presentation" className="p-0">
                        <table
                            role="presentation"
                            className="w-full border-collapse table-fixed"
                        >
                            <colgroup>
                                <col className="w-10" />
                            </colgroup>
                            <thead role="presentation">
                                <tr role="row">
                                    <th aria-hidden />
                                    {weekDays.map((day) => (
                                        <CalendarWeekHeaderDay
                                            key={day.toISOString()}
                                            active={isSameDate(day, today)}
                                            date={day}
                                        />
                                    ))}
                                </tr>
                            </thead>
                        </table>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr role="presentation">
                    <td role="presentation" className="w-full">
                        <ScrollHarness>
                            {/* Time slots */}
                            <table
                                aria-hidden
                                className="w-full border-collapse table-fixed"
                            >
                                <colgroup>
                                    <col className="w-10" />
                                </colgroup>
                                <tbody>
                                    {new Array(24).fill(0).map((_, index) => (
                                        <tr key={index} className="">
                                            <td className="h-32 align-top general-grey-grey-80 text-xs bg-general-grey-grey-10 pl-1">
                                                {index
                                                    .toString()
                                                    .padStart(2, '0')}
                                                :00
                                            </td>
                                            <td className="h-32 border-b border-general-grey-grey-30" />
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            {/* Days */}
                            <div className="absolute inset-0">
                                <table className="w-full h-full">
                                    <colgroup>
                                        <col className="w-10" />
                                    </colgroup>
                                    <tbody role="presentation">
                                        <tr role="row">
                                            <td aria-hidden />
                                            {weekDays.map((day, index) => (
                                                <CalendarWeekColumn
                                                    key={index}
                                                    currentDay={isSameDate(
                                                        day,
                                                        today
                                                    )}
                                                    data={data?.filter(
                                                        (item) =>
                                                            moment(
                                                                day
                                                            ).isSameOrBefore(
                                                                item.date
                                                            ) &&
                                                            moment(
                                                                item.date
                                                            ).isSameOrBefore(
                                                                moment(
                                                                    day
                                                                ).endOf('day')
                                                            )
                                                    )}
                                                    isWeekend={
                                                        day.getDay() === 0 ||
                                                        day.getDay() === 6
                                                    }
                                                />
                                            ))}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </ScrollHarness>
                    </td>
                </tr>
            </tbody>
        </table>
    );
}

export default CalendarWeek;
export type { CalendarWeekProps };
