import { Section } from 'lib-ui';
import React from 'react';

type StepSectionProps = {
    children?: React.ReactNode;
    stepTitle: string;
};

const StepSection = ({ children, stepTitle }: StepSectionProps) => {
    return (
        <Section className="bg-general-grey-grey-10">
            <div className="w-fit text-xl rounded p-2">
                <span className="text-brand-escode-neonblue-neonblue-100 font-medium">
                    {stepTitle}
                </span>
            </div>
            <div className="bg-white rounded-md">{children}</div>
        </Section>
    );
};

export default StepSection;
