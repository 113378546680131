/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import {
    InputField,
    RadioGroupField,
    Section,
    SelectField,
    SelectionField,
    SwitchField
} from 'lib-ui';
import {
    applicationCategoryOptions,
    financialServicesOptions,
    languages,
    radioLicenseesOptions,
    radioOptions
} from './deposit-details-options';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useModals } from '@ncc-frontend/core';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import DNStep from '../deposit-now-step/dn-step';
import RequestNovationModal from 'ui/agreements/novation/request-novation-modal';
import schema from './deposit-details-schema';
import toastFunctions from 'lib-ui/toast/Toast';
import useAgreementDetailsV2 from 'core/api/client-portal-v2/hooks/agreement/use-agreement-details-v2';
import useDepositDetailsV2 from 'core/api/client-portal-v2/hooks/deposits/use-deposit-details-v2';
import useDepositPartialUpdate from 'core/api/client-portal-v2/hooks/deposits/use-deposit-partial-update';
import yesOrNo from 'common/yes-or-no';

type FormData = {
    applicationCategory?: string;
    approximateSizeOfMediaContent: number;
    containsVirtualMachine: 'yes' | 'no' | null;
    contentsOfThisDeposit: Record<string, boolean>;
    fileTypes?: string[] | { label: string; value: string }[];
    financialServicesCategory?: string;
    hardwareUsedToCreateTheDeposit?: string;
    isForAMediaFileReview: 'yes' | 'no' | null;
    isForAScheduledVerificationExercise: 'yes' | 'no' | null;
    isForASpecificLicensee: '0' | '1';
    licenseesThatRequireThisDeposit?:
        | {
              id: string;
              label: any;
              value: number;
          }[]
        | [];
    operatingSystemUsed?: string;
    otherFileTypes?: string;
    softwareOwnerCompanyName?: string;
    softwarePackageName?: string;
    versionOrReleaseNumber: string;
};

const DepositDetailsStep = () => {
    const { push } = useModals();
    const { depositId } = useParams();
    const { t } = useTranslation();
    const goTo = useNavigate();
    const [disableLicensee, setDisableLicensee] = useState(true);

    const { data, isFetched } = useDepositDetailsV2(
        depositId as unknown as number
    );
    const { isLoading, mutate: updateDeposit } = useDepositPartialUpdate(
        depositId as unknown as number
    );

    const { data: agreement } = useAgreementDetailsV2(
        data?.relatedAgreements[0],
        { enabled: !!data }
    );

    const availableLicensees = useMemo(() => {
        if (isFetched && data) {
            return Object.keys(
                data.licenseesThatCanBeSelectedForThisDeposit
            ).map((key, index) => ({
                id: (index + 1).toString(),
                label: data.licenseesThatCanBeSelectedForThisDeposit[key],
                value: parseInt(key)
            }));
        } else {
            return [];
        }
    }, [data, isFetched]);

    const savedLicensees = availableLicensees.filter((licensee) => {
        return data?.details.licenseesThatRequireThisDeposit.includes(
            licensee.value as unknown as number
        );
    });

    const savedLangs = languages.filter((lang) => {
        return data?.details.fileTypes.includes(
            lang.value as unknown as string
        );
    });

    const methods = useForm<FormData>({
        reValidateMode: 'onSubmit',
        resolver: yupResolver(schema),
        values: {
            applicationCategory: data?.details.applicationCategory || '',
            approximateSizeOfMediaContent:
                data?.details.approximateSizeOfMediaContent || '',
            containsVirtualMachine: yesOrNo(
                data?.details.containsVirtualMachine
            ),
            contentsOfThisDeposit: {
                configurationFiles:
                    data?.details.contentsOfThisDeposit.configurationFiles ||
                    false,
                database: data?.details.contentsOfThisDeposit.data || false,
                development:
                    data?.details.contentsOfThisDeposit.development || false,
                environmentOrBuildTools:
                    data?.details.contentsOfThisDeposit
                        .environmentOrBuildTools || false,
                otherData:
                    data?.details.contentsOfThisDeposit.otherData || false,
                sourceCode:
                    data?.details.contentsOfThisDeposit.sourceCode || false,
                testEnvironment:
                    data?.details.contentsOfThisDeposit.testEnvironment || false
            },
            fileTypes:
                savedLangs.map((lang) => ({
                    label: lang.label,
                    value: lang.value as unknown as string
                })) || [],
            financialServicesCategory:
                data?.details.financialServicesCategory || '',
            hardwareUsedToCreateTheDeposit:
                data?.details.hardwareUsedToCreateTheDeposit || '',
            isForAMediaFileReview: yesOrNo(data?.details.isForAMediaFileReview),
            isForAScheduledVerificationExercise: yesOrNo(
                data?.details.isForAScheduledVerificationExercise
            ),
            isForASpecificLicensee:
                data?.details.isForASpecificLicensee.toString() || '0',
            licenseesThatRequireThisDeposit: savedLicensees || [],
            operatingSystemUsed: data?.details.operatingSystemUsed || '',
            otherFileTypes: data?.details.otherFileTypes || '',
            softwareOwnerCompanyName:
                data?.details.softwareOwnerCompanyName || '',
            softwarePackageName: data?.details.softwarePackageName || '',

            versionOrReleaseNumber: data?.details.versionOrReleaseNumber || ''
        }
    });

    const isScheduled = methods.watch('isForAScheduledVerificationExercise');
    const containsVM = methods.watch('containsVirtualMachine');
    const allLicensees = methods.watch('isForASpecificLicensee') === '0';
    const selectedLicensees = methods.watch('isForASpecificLicensee') === '1';
    const specificLicensees = methods.watch('licenseesThatRequireThisDeposit');
    const sourceCode = methods.watch('contentsOfThisDeposit.sourceCode');

    useEffect(() => {
        if (
            specificLicensees &&
            specificLicensees.length > 0 &&
            !allLicensees
        ) {
            methods.setValue('isForASpecificLicensee', '1');
        }
        if (specificLicensees && specificLicensees.length > 0 && allLicensees) {
            methods.setValue('licenseesThatRequireThisDeposit', []);
        }
    }, [specificLicensees, allLicensees, methods]);

    useEffect(() => {
        if (isScheduled === 'yes' || containsVM === 'yes') {
            setDisableLicensee(false);
            return;
        }
        if (isScheduled === 'no' || containsVM === 'no') {
            setDisableLicensee(true);
            methods.setValue('isForASpecificLicensee', '0');
            methods.setValue('licenseesThatRequireThisDeposit', []);
            return;
        }
    }, [allLicensees, containsVM, isScheduled, methods]);

    const handleOnProceed = useCallback<SubmitHandler<FormData>>(
        (formData: any) => {
            formData.isForAMediaFileReview =
                formData.isForAMediaFileReview === 'yes';

            formData.isForAScheduledVerificationExercise =
                formData.isForAScheduledVerificationExercise === 'yes';

            formData.containsVirtualMachine =
                formData.containsVirtualMachine === 'yes';

            formData.fileTypes = formData.fileTypes.map(
                (fileType: any) => fileType.value
            );

            formData.licenseesThatRequireThisDeposit =
                formData.licenseesThatRequireThisDeposit.map(
                    (licensee: { id: string; label: string; value: number }) =>
                        licensee.value
                );

            updateDeposit(formData, {
                onError() {
                    toastFunctions.error(t('toast.error'));
                },
                onSuccess() {
                    toastFunctions.success(t('toast.success'));
                    goTo(`/deposit-now/${depositId}/content`);
                }
            });
        },
        [depositId, goTo, t, updateDeposit]
    );

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods?.handleSubmit(handleOnProceed)}>
                <DNStep
                    classNames="flex flex-col gap-4 mb-2"
                    onBack={() =>
                        goTo(`/deposit-now/${depositId}/contact-details`)
                    }
                    submitting={isLoading}
                    title={t('deposit-now-stage-three.title')}
                >
                    <div className="mt-2 space-y-2">
                        <div className="flex h-auto gap-5">
                            <Section
                                heading={t(
                                    'deposit-now-stage-three.software-details'
                                )}
                                required
                            >
                                <InputField
                                    disabled
                                    name="softwareOwnerCompanyName"
                                    description={
                                        <span>
                                            {t(
                                                'deposit-now-stage-two.novation-request'
                                            )}{' '}
                                            <strong
                                                className="underline cursor-pointer"
                                                onClick={() =>
                                                    push(
                                                        RequestNovationModal,
                                                        {}
                                                    )
                                                }
                                            >
                                                {t(
                                                    'deposit-now-stage-two.novation'
                                                )}
                                            </strong>
                                        </span>
                                    }
                                    label={t(
                                        'deposit-now-stage-three.company-owner-title'
                                    )}
                                    placeholder={t(
                                        'deposit-now-stage-two.company-name'
                                    )}
                                    tailwindStyle={{
                                        width: 'w-full'
                                    }}
                                />

                                <InputField
                                    disabled
                                    label={t(
                                        'deposit-now-stage-three.company-package-title'
                                    )}
                                    name="softwarePackageName"
                                    placeholder={t(
                                        'deposit-now-stage-two.company-name'
                                    )}
                                    tailwindStyle={{
                                        width: 'w-full'
                                    }}
                                    tooltip="deposit-now-stage-three.software-package-tooltip"
                                />
                                <InputField
                                    label={t('deposit-now-stage-three.version')}
                                    name="versionOrReleaseNumber"
                                    placeholder={t(
                                        'deposit-now-stage-three.version-placeholder'
                                    )}
                                    tailwindStyle={{
                                        width: 'w-full'
                                    }}
                                    tooltip="deposit-now-stage-three.version-tooltip"
                                    tooltipPlacement="right"
                                />
                                <RadioGroupField
                                    label={t(
                                        'deposit-now-stage-three.deposit-scheduled'
                                    )}
                                    name="isForAScheduledVerificationExercise"
                                    options={radioOptions}
                                    tooltip="deposit-now-stage-three.deposit-scheduled-tooltip"
                                />
                                <RadioGroupField
                                    label={t(
                                        'deposit-now-stage-three.deposit-media'
                                    )}
                                    name="isForAMediaFileReview"
                                    options={radioOptions}
                                    tooltip="deposit-now-stage-three.deposit-media-tooltip"
                                />
                                <RadioGroupField
                                    label={t(
                                        'deposit-now-stage-four.deposit-virtual'
                                    )}
                                    name="containsVirtualMachine"
                                    options={radioOptions}
                                    required
                                    tooltip="deposit-now-stage-four.deposit-vm-tooltip"
                                />
                            </Section>

                            <div className="col-span-2 grid grid-cols-1 gap-5 flex-1">
                                <Section
                                    heading={t(
                                        'deposit-now-stage-three.licensee-details'
                                    )}
                                    required
                                >
                                    {agreement?.isMultiAgreementType && (
                                        <RadioGroupField
                                            disabled={disableLicensee}
                                            label={t(
                                                'deposit-now-stage-three.specific-licensee'
                                            )}
                                            name="isForASpecificLicensee"
                                            options={radioLicenseesOptions}
                                            tooltip="deposit-now-stage-three.licensee-details-tooltip"
                                            radioStyles="gap-4"
                                        />
                                    )}
                                    {selectedLicensees && isFetched && (
                                        <SelectionField
                                            closeMenuOnSelect={false}
                                            id="licenseesThatRequireThisDeposit"
                                            isMulti
                                            label={t(
                                                'deposit-now-stage-three.specify-licensees'
                                            )}
                                            name="licenseesThatRequireThisDeposit"
                                            options={availableLicensees}
                                            placeholder={t(
                                                'deposit-now-stage-three.licensees-select'
                                            )}
                                        />
                                    )}
                                    <Section
                                        className="border-none p-0"
                                        data-testid="contentsOfThisDeposit"
                                        heading={t(
                                            'deposit-now-stage-three.deposit-content'
                                        )}
                                        headingClasses="mb-2"
                                        headingTextClasses="text-base font-normal text-general-grey-grey-90"
                                        wrapperDefault={false}
                                    >
                                        <div className="grid grid-cols-2 gap-2 flex-1">
                                            <SwitchField
                                                label="Source Code"
                                                name="contentsOfThisDeposit.sourceCode"
                                                id="contentsOfThisDeposit.sourceCode"
                                            />
                                            <SwitchField
                                                label="Development"
                                                name="contentsOfThisDeposit.development"
                                                id="contentsOfThisDeposit.development"
                                            />
                                            <SwitchField
                                                label="Environment or Build Tools"
                                                name="contentsOfThisDeposit.environmentOrBuildTools"
                                                id="contentsOfThisDeposit.environmentOrBuildTools"
                                            />
                                            <SwitchField
                                                label="Database"
                                                name="contentsOfThisDeposit.database"
                                                id="contentsOfThisDeposit.database"
                                            />
                                            <SwitchField
                                                label="Test Environment"
                                                name="contentsOfThisDeposit.testEnvironment"
                                                id="contentsOfThisDeposit.testEnvironment"
                                            />
                                            <SwitchField
                                                label="Other Data"
                                                name="contentsOfThisDeposit.otherData"
                                                id="contentsOfThisDeposit.otherData"
                                            />
                                            <SwitchField
                                                label="Configuration Files"
                                                name="contentsOfThisDeposit.configurationFiles"
                                                id="contentsOfThisDeposit.configurationFiles"
                                            />
                                        </div>
                                        {methods.formState.errors
                                            .contentsOfThisDeposit && (
                                            <div className="text-sm text-general-red-red-100 mt-2">
                                                {
                                                    methods.formState.errors
                                                        .contentsOfThisDeposit
                                                        .message
                                                }
                                            </div>
                                        )}
                                    </Section>
                                </Section>
                                <Section
                                    heading={t(
                                        'deposit-now-stage-three.application-details'
                                    )}
                                    optional
                                >
                                    <SelectField
                                        label={t(
                                            'deposit-now-stage-three.financial-services'
                                        )}
                                        name="financialServicesCategory"
                                        placeholder={t(
                                            'deposit-now-stage-three.banking-type'
                                        )}
                                        options={financialServicesOptions}
                                        tailwindStyle={{
                                            width: 'w-full'
                                        }}
                                    />

                                    <SelectField
                                        label={t(
                                            'deposit-now-stage-three.application-category'
                                        )}
                                        name="applicationCategory"
                                        placeholder={t(
                                            'deposit-now-stage-three.banking-app'
                                        )}
                                        options={applicationCategoryOptions}
                                        tailwindStyle={{
                                            width: 'w-full'
                                        }}
                                    />
                                </Section>
                            </div>

                            <Section
                                heading={t('deposit-now-stage-deposit.specs')}
                            >
                                <InputField
                                    label={t('deposit-now-stage-three.os')}
                                    name="operatingSystemUsed"
                                    optional
                                    placeholder={t(
                                        'deposit-now-stage-two.job-placeholder'
                                    )}
                                    tailwindStyle={{
                                        width: 'w-full'
                                    }}
                                    tooltip="deposit-now-stage-three.os-tooltip"
                                />
                                <InputField
                                    label={t(
                                        'deposit-now-stage-three.hardware'
                                    )}
                                    name="hardwareUsedToCreateTheDeposit"
                                    optional
                                    placeholder={t(
                                        'deposit-now-stage-two.job-placeholder'
                                    )}
                                    tailwindStyle={{
                                        width: 'w-full'
                                    }}
                                    tooltip="deposit-now-stage-three.hardware-tooltip"
                                />
                                {sourceCode && (
                                    <>
                                        <SelectionField
                                            closeMenuOnSelect={false}
                                            id="fileTypes"
                                            isMulti
                                            label={t(
                                                'deposit-now-stage-three.source-code'
                                            )}
                                            name="fileTypes"
                                            options={languages}
                                            placeholder={t(
                                                'deposit-now-stage-three.source-placeholder'
                                            )}
                                            tooltip="deposit-now-stage-three.source-code-tooltip"
                                        />
                                        <InputField
                                            name="otherFileTypes"
                                            placeholder={t(
                                                'deposit-now-stage-three.source-code-other'
                                            )}
                                            tailwindStyle={{
                                                width: 'w-full'
                                            }}
                                            required
                                        />
                                    </>
                                )}
                                <InputField
                                    label={t(
                                        'deposit-now-stage-three.content-size'
                                    )}
                                    name="approximateSizeOfMediaContent"
                                    placeholder={t(
                                        'deposit-now-stage-three.maximum'
                                    )}
                                    required
                                    tailwindStyle={{
                                        width: 'w-full'
                                    }}
                                />
                            </Section>
                        </div>
                    </div>
                </DNStep>
            </form>
        </FormProvider>
    );
};

export default DepositDetailsStep;
