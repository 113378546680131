export interface DepositFile {
    completed: boolean;
    depositUniqueReference: string;
    fileId: number;
    fileName: string;
    fileSize: number;
    uniqueReference: string;
    uploaderUrl: string;
    verificationState: number;
}

export interface GetDepositFilesResponse {
    depositFiles: DepositFile[];
}

const useAgreementsDepositsFilesMockData: GetDepositFilesResponse = {
    depositFiles: [
        {
            completed: false,
            depositUniqueReference: 'klsj342-da4b-4df2-9688-c4133097d47c',
            fileId: 8234,
            fileName: 'first',
            fileSize: 10407854,
            uniqueReference: 'a4ae8c6e-7c79-4e25-9e98-073d33f8zz676',
            uploaderUrl: 'https://view-us-uploader-api.dev.dev.nccgroup.com/',
            verificationState: 0
        },
        {
            completed: false,
            depositUniqueReference: 'kjnvnv344-da4b-4df2-9688-c4133097d47c',
            fileId: 90823,
            fileName: 'second',
            fileSize: 1048111,
            uniqueReference: 'a4ae8c6e-7c79-4e25-9e98-073d33f8aa09',
            uploaderUrl: 'https://view-us-uploader-api.dev.dev.nccgroup.com/',
            verificationState: 2
        }
    ]
};

export default useAgreementsDepositsFilesMockData;
