import { DropdownPanel, Menu } from 'lib-ui';
import { LANGUAGES } from 'localization';

import LanguageOption from './language-option';

function LanguageSelectionPanel() {
    return (
        <DropdownPanel>
            <Menu tailwindStyle={{ gap: 'gap-1' }}>
                {LANGUAGES.map(({ lang, name }) => (
                    <LanguageOption key={lang} lang={lang}>
                        {name}
                    </LanguageOption>
                ))}
            </Menu>
        </DropdownPanel>
    );
}

export default LanguageSelectionPanel;
