import { CalendarData } from 'lib-ui/calendar/types';
import { CalendarViewResolution } from 'lib-ui/calendar/calendar';
import {
    PropsWithChildren,
    createContext,
    useCallback,
    useContext,
    useState
} from 'react';

interface SchedulesContextValue {
    depositScheduleData: CalendarData[] | null | undefined;
    isNextDayWithDeposits: boolean;
    onUpdateDepositScheduleData: (newValue: CalendarData[]) => void;
    onUpdateIsNextDayWithDeposits: (newValue: boolean) => void;
    onUpdateSchedulesTitle: (newValue: string) => void;
    onUpdateViewResolution: (newValue: CalendarViewResolution) => void;
    onUpdateWeekTitleProvider: (newValue: string) => void;
    schedulesTitle: string;
    viewResolutionProvider: CalendarViewResolution;
    weekTitleProvider: string | undefined;
}

const defaultValue: SchedulesContextValue = {
    depositScheduleData: undefined,

    isNextDayWithDeposits: false,

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onUpdateDepositScheduleData: () => {},

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onUpdateIsNextDayWithDeposits: () => {},

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onUpdateSchedulesTitle: () => {},

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onUpdateViewResolution: () => {},

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onUpdateWeekTitleProvider: () => {},

    schedulesTitle: '',
    viewResolutionProvider: 'month',
    weekTitleProvider: undefined
};

const ScheduleContext = createContext<SchedulesContextValue>(defaultValue);

function SchedulesContextProvider({ children }: PropsWithChildren) {
    const [viewResolutionProvider, setViewResolutionProvider] =
        useState<CalendarViewResolution>('month');
    const [depositScheduleData, setDepositScheduleData] = useState<
        CalendarData[]
    >([]);
    const [weekTitleProvider, setWeekTitleProvider] = useState<
        string | undefined
    >();
    const [schedulesTitle, setSchedulesTitle] = useState<string>('');
    const [isNextDayWithDeposits, setIsNextDayWithDeposits] = useState(false);

    const onUpdateViewResolution = useCallback(
        (newValue: CalendarViewResolution) => {
            setViewResolutionProvider(newValue);
        },
        []
    );
    const onUpdateDepositScheduleData = useCallback(
        (newValues: CalendarData[]) => {
            setDepositScheduleData(newValues);
        },
        []
    );
    const onUpdateWeekTitleProvider = useCallback((newValues: string) => {
        setWeekTitleProvider(newValues);
    }, []);
    const onUpdateSchedulesTitle = useCallback((newValues: string) => {
        setSchedulesTitle(newValues);
    }, []);
    const onUpdateIsNextDayWithDeposits = useCallback((newValues: boolean) => {
        setIsNextDayWithDeposits(newValues);
    }, []);
    return (
        <ScheduleContext.Provider
            value={{
                depositScheduleData,
                isNextDayWithDeposits,
                onUpdateDepositScheduleData,
                onUpdateIsNextDayWithDeposits,
                onUpdateSchedulesTitle,
                onUpdateViewResolution,
                onUpdateWeekTitleProvider,
                schedulesTitle,
                viewResolutionProvider,
                weekTitleProvider
            }}
        >
            {children}
        </ScheduleContext.Provider>
    );
}

// Custom hook to access the context easily
function useSchedulesContext() {
    return useContext(ScheduleContext);
}

export default SchedulesContextProvider;
export { ScheduleContext, useSchedulesContext };
