import { AgGridReact } from 'ag-grid-react';
import {
    ForwardedRef,
    createRef,
    forwardRef,
    useImperativeHandle
} from 'react';
import classNames from 'classnames';

import { DataWidgetContextValue } from '../data/data-widget-provider';
import DataWidget, { DataWidgetProps } from '../data/data-widget';
import Table, { TableProps } from '../../table/table';
import useTableData from './use-table-data';

interface TableWidgetProps<TBackendPagination extends boolean | undefined>
    extends Pick<
            DataWidgetProps,
            | 'error'
            | 'className'
            | 'filterableFields'
            | 'id'
            | 'loading'
            | 'onClick'
            | 'onReload'
            | 'post'
            | 'pre'
            | 'selectableFields'
            | 'style'
            | 'suppressSaveFilter'
            | 'tailwindStyle'
            | 'title'
        >,
        Omit<TableProps, 'id'> {
    backendPagination?: TBackendPagination;
    fetchNextPage?: TBackendPagination extends true ? () => void : never;
}

function TableWidget<TData, TBackendPagination extends boolean | undefined>(
    {
        backendPagination = false,
        className,
        columnDefs,
        context,
        defaultColDef,
        error,
        fetchNextPage,
        filterableFields,
        id,
        loading,
        noRowsOverlayComponentParams,
        onClick,
        onReload,
        post,
        pre,
        rowData,
        selectableFields,
        style,
        suppressSaveFilter,
        tailwindStyle,
        title,
        ...restProps
    }: TableWidgetProps<TBackendPagination>,
    ref: ForwardedRef<DataWidgetContextValue>
) {
    const agGridRef = createRef<AgGridReact<TData>>();
    const dataWidget = createRef<DataWidgetContextValue>();

    const {
        handleApplyFilters,
        handleGridReady,
        handleHiddenFieldsChange,
        handlePageChange,
        handlePaginationChanged,
        handleQuickSearchChange,
        handleReset,
        handleRowDataUpdated
    } = useTableData<TData>({
        agGridRef,
        backendPagination,
        dataWidget,
        fetchNextPage
    });

    useImperativeHandle(
        ref,
        () => dataWidget.current as DataWidgetContextValue
    );

    return (
        <DataWidget
            ref={dataWidget}
            id={id}
            onPageChange={handlePageChange}
            onReset={handleReset}
            className={classNames('h-full', className)}
            onApplyFilters={handleApplyFilters}
            error={error}
            filterableFields={filterableFields}
            loading={loading}
            onClick={onClick}
            onQuickSearchChange={handleQuickSearchChange}
            exportDataAsCsv={agGridRef.current?.api.exportDataAsCsv}
            getDataAsCsv={agGridRef.current?.api.getDataAsCsv}
            onReload={onReload}
            post={post}
            pre={pre}
            selectableFields={selectableFields}
            style={style}
            suppressSaveFilter={suppressSaveFilter}
            tailwindStyle={tailwindStyle}
            title={title}
            onHiddenFieldsChange={handleHiddenFieldsChange}
            data-testid="widget"
        >
            <Table
                ref={agGridRef}
                id={id}
                columnDefs={columnDefs}
                context={context}
                defaultColDef={defaultColDef}
                noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                rowData={rowData}
                loading={loading}
                onPaginationChanged={handlePaginationChanged}
                onGridReady={handleGridReady}
                onRowDataUpdated={handleRowDataUpdated}
                {...restProps}
            />
        </DataWidget>
    );
}

export default forwardRef(TableWidget);
export type { TableWidgetProps };
