import { ApiController } from '../../autogenerated/Api';
import {
    ProblemDetails,
    ValidationProblemDetails
} from '../../autogenerated/data-contracts';
import { UseQueryOptions, useQuery } from 'react-query';
import { useAuth } from 'react-oidc-context';
import { useDepositDetailsV2MockData } from './use-deposit-details-v2-mock-data';
import interceptors from 'core/api/interceptors';
import securityWorker from 'core/api/security-worker';

function useDepositDetailsV2<TError = unknown>(
    depositId: number,
    additionalKey?: string,
    options?: Omit<
        UseQueryOptions<
            ValidationProblemDetails | ProblemDetails,
            ReactQueryError<TError>,
            ValidationProblemDetails | ProblemDetails
        >,
        'queryKey' | 'queryFn'
    >
) {
    const { user } = useAuth();

    return useQuery(
        ['use-deposit-details-v2'],
        async () => {
            if (process.env.REACT_APP_API_MOCK) {
                console.warn('Using mock data for useDepositDetailsV2');
                return useDepositDetailsV2MockData;
            }

            if (
                user?.access_token === undefined ||
                user?.token_type === undefined
            ) {
                return undefined;
            }

            const controller = new ApiController({
                interceptors,
                securityWorker: securityWorker(
                    user?.token_type,
                    user?.access_token
                )
            });

            const response = await controller.v2DepositDetail(depositId, {});
            return response.data;
        },
        { cacheTime: 0, refetchOnMount: true }
    );
}

export default useDepositDetailsV2;
