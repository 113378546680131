import { FixedSourceProviderResponse } from './types';

const useAgreementsMockData: FixedSourceProviderResponse = {
    providers: [
        {
            authenticationType: 0,
            connectionName: 'connectionName',
            id: 1,
            password: 'password',
            privateKey: 'privateKey',
            providerType: 0,
            repositoryAddress: 'repositoryAddress',
            userName: 'userName'
        },
        {
            authenticationType: 1,
            connectionName: 'connectionName',
            id: 2,
            password: 'password',
            privateKey: 'privateKey',
            providerType: 1,
            repositoryAddress: 'repositoryAddress',
            userName: 'userName'
        },
        {
            authenticationType: 0,
            connectionName: 'connectionName',
            id: 3,
            password: 'password',
            privateKey: 'privateKey',
            providerType: 2,
            repositoryAddress: 'repositoryAddress',
            userName: 'userName'
        },
        {
            authenticationType: 0,
            connectionName: 'connectionName',
            id: 4,
            password: 'password',
            privateKey: 'privateKey',
            providerType: 3,
            repositoryAddress: 'repositoryAddress',
            userName: 'userName'
        },
        {
            authenticationType: 0,
            connectionName: 'connectionName',
            id: 5,
            password: 'password',
            privateKey: 'privateKey',
            providerType: 0,
            repositoryAddress: 'repositoryAddress',
            userName: 'userName'
        },
        {
            authenticationType: 0,
            connectionName: 'connectionName',
            id: 6,
            password: 'password',
            privateKey: 'privateKey',
            providerType: 0,
            repositoryAddress: 'repositoryAddress',
            userName: 'userName'
        },
        {
            authenticationType: 0,
            connectionName: 'connectionName',
            id: 7,
            password: 'password',
            privateKey: 'privateKey',
            providerType: 0,
            repositoryAddress: 'repositoryAddress',
            userName: 'userName'
        },
        {
            authenticationType: 0,
            connectionName: 'connectionName',
            id: 8,
            password: 'password',
            privateKey: 'privateKey',
            providerType: 0,
            repositoryAddress: 'repositoryAddress',
            userName: 'userName'
        },
        {
            authenticationType: 0,
            connectionName: 'connectionName',
            id: 9,
            password: 'password',
            privateKey: 'privateKey',
            providerType: 0,
            repositoryAddress: 'repositoryAddress',
            userName: 'userName'
        },
        {
            authenticationType: 0,
            connectionName: 'connectionName',
            id: 10,
            password: 'password',
            privateKey: 'privateKey',
            providerType: 1,
            repositoryAddress: 'repositoryAddress',
            userName: 'userName'
        },
        {
            authenticationType: 0,
            connectionName: 'connectionName',
            id: 11,
            password: 'password',
            privateKey: 'privateKey',
            providerType: 2,
            repositoryAddress: 'repositoryAddress',
            userName: 'userName'
        },
        {
            authenticationType: 0,
            connectionName: 'connectionName',
            id: 12,
            password: 'password',
            privateKey: 'privateKey',
            providerType: 3,
            repositoryAddress: 'repositoryAddress',
            userName: 'userName'
        }
    ]
};

export default useAgreementsMockData;
