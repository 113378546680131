import { RadioGroupField, RadioGroupFieldProps, Widget } from 'lib-ui';
import { TailwindStyle } from '@ncc-frontend/core';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

interface FrequencyControlProps {
    bodyClassName: string;
    /** Css units: px, rem, ... */
    headerHeight?: string;
    headerTailwindStyle: TailwindStyle;
}

function FrequencyControl({
    bodyClassName,
    headerHeight,
    headerTailwindStyle
}: FrequencyControlProps) {
    const { t } = useTranslation();

    const { current: frequencyOptions } = useRef<
        RadioGroupFieldProps['options']
    >([
        { label: t('schedules.frequency.daily'), value: 'daily' },
        { label: t('schedules.frequency.weekly'), value: 'weekly' },
        { label: t('schedules.frequency.monthly'), value: 'monthly' },
        { label: t('schedules.frequency.quarterly'), value: 'quarterly' },
        { label: t('schedules.frequency.annually'), value: 'annually' }
    ]);

    return (
        <Widget>
            <Widget.Header
                tailwindStyle={headerTailwindStyle}
                height={headerHeight}
            >
                {t('schedules.edit-modal.frequency')}
            </Widget.Header>
            <Widget.Body className={bodyClassName} headerHeight={headerHeight}>
                <RadioGroupField
                    name="frequency"
                    options={frequencyOptions}
                    orientation="vertical"
                />
            </Widget.Body>
        </Widget>
    );
}

export default FrequencyControl;
export type { FrequencyControlProps };
