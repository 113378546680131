import { FormProvider, useForm } from 'react-hook-form';
import { useAuth } from 'react-oidc-context';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import DNStep from '../deposit-now-step/dn-step';
import schema from './disclaimer-step-schema';
import toastFunctions from 'lib-ui/toast/Toast';
import useAgreementsDepositsDetailsCreate from 'core/api/client-portal/hooks/agreements/use-agreements-deposit-details-create';

type FormData = {
    confirmed: boolean;
    read: boolean;
};

const DisclaimerStep = () => {
    const { depositId } = useParams();
    const { signinSilent } = useAuth();
    const { t } = useTranslation();
    const goTo = useNavigate();
    const { mutate: mutateFn } = useAgreementsDepositsDetailsCreate({
        onError() {
            toastFunctions.error(t('toast.error'));
        },
        onSuccess() {
            signinSilent(); // we get the token ready for upload files
            toastFunctions.success(t('toast.success'));
            goTo(`/deposit-now/${depositId}/upload-files`);
        }
    });

    const methods = useForm<FormData>({
        resolver: yupResolver(schema)
    });

    const handleOnProceed = useCallback(() => {
        mutateFn(depositId as unknown as number);
    }, [depositId, mutateFn]);

    const checkboxContainer = 'flex items-center gap-2 p-3 border-2 rounded-lg';
    const checkboxClasses =
        'accent-brand-escode-neonblue-neonblue-100 hover:accent-brand-escode-neonblue-neonblue-100 active:accent-brand-escode-neonblue-neonblue-100 focus:ring-brand-escode-neonblue-neonblue-100 transition-all duration-300 w-4 h-4';

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods?.handleSubmit(handleOnProceed)}>
                <DNStep
                    onBack={() =>
                        goTo(`/deposit-now/${depositId}/review-deposit`)
                    }
                    title={t('deposit-now-stage-six.title')}
                >
                    <div className="flex flex-col gap-6">
                        <article className="ml-5">
                            <ul>
                                <li className="list-disc mb-8">
                                    {t('deposit-now-stage-six.info')}
                                </li>
                                <li className="list-disc mb-8">
                                    {t('deposit-now-stage-six.second-info')}
                                </li>
                                <li className="list-disc">
                                    {t('deposit-now-stage-six.third-info')}
                                </li>
                            </ul>
                        </article>

                        <div className="flex flex-col justify-start gap-5 ">
                            <div className="flex flex-col items-start gap-5">
                                <div className={checkboxContainer}>
                                    <input
                                        className={checkboxClasses}
                                        data-testid="read-checkbox"
                                        id="read"
                                        type="checkbox"
                                        {...methods.register('read')}
                                    />
                                    <label
                                        className="text-general-grey-grey-90"
                                        data-testid="read-label"
                                        htmlFor="read"
                                    >
                                        {t('deposit-now-stage-six.agreed')} *
                                    </label>
                                    {methods.formState?.errors?.read && (
                                        <div className="max-w-4xl text-sm pt-1 text-general-red-red-100">
                                            <p>
                                                {
                                                    methods.formState.errors
                                                        .read.message
                                                }
                                            </p>
                                        </div>
                                    )}
                                </div>

                                <div className={checkboxContainer}>
                                    <input
                                        className={checkboxClasses}
                                        data-testid="confirmed-checkbox"
                                        id="confirmed"
                                        type="checkbox"
                                        {...methods.register('confirmed')}
                                    />
                                    <label
                                        className="text-general-grey-grey-90"
                                        data-testid="confirmed-label"
                                        htmlFor="confirmed"
                                    >
                                        {t('deposit-now-stage-six.confirm')} *
                                    </label>
                                    {methods.formState?.errors?.confirmed && (
                                        <div className="max-w-4xl text-sm pt-1 text-general-red-red-100">
                                            <p>
                                                {
                                                    methods.formState.errors
                                                        .confirmed.message
                                                }
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </DNStep>
            </form>
        </FormProvider>
    );
};

export default DisclaimerStep;
