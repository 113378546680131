import DepositNowContainer from '../../ui/deposit-now/deposit-now-container';
import UploadStep from 'ui/deposit-now/upload-step/upload-step';

const DepositNowUpload = () => {
    return (
        <DepositNowContainer step="upload">
            <UploadStep />
        </DepositNowContainer>
    );
};

export default DepositNowUpload;
