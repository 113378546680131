import { ApiController } from '../../autogenerated/Api';
import { AxiosResponse } from 'axios';
import { MutationOptions, useMutation } from 'react-query';
import {
    ReviewDepositRequest,
    ValidationProblemDetails
} from '../../autogenerated/data-contracts';
import { useAuth } from 'react-oidc-context';
import { useDepositPartialMockData } from './use-deposit-partial-mock-data';
import interceptors from 'core/api/interceptors';
import securityWorker from 'core/api/security-worker';

function useEditDepositV2<TError = unknown>(
    depositId: number,
    options?: Omit<
        MutationOptions<
            AxiosResponse<void, unknown>,
            ReactQueryError<TError>,
            ValidationProblemDetails
        >,
        'mutationFn'
    >
) {
    const { user } = useAuth();

    return useMutation(
        ['deposit-now', 'step-5', 'update'],
        async (data: ReviewDepositRequest) => {
            if (process.env.REACT_APP_API_MOCK) {
                console.warn('Using mock data for useEditDepositV2');
                return useDepositPartialMockData;
            }

            if (
                user?.access_token === undefined ||
                user?.token_type === undefined
            ) {
                return undefined;
            }

            const controller = new ApiController({
                interceptors,
                securityWorker: securityWorker(
                    user?.token_type,
                    user?.access_token
                )
            });

            const response = await controller.v2DepositPartialUpdate(
                depositId,
                data,
                {}
            );
            return response.data;
        },
        { ...options }
    );
}

export default useEditDepositV2;
