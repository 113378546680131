import { Button } from 'lib-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faDisplayCode, faEnvelope } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import './maintenance.scss';

function Maintenance() {
    const { t } = useTranslation();

    return (
        <div className="w-full h-full bg-gradient-to-r from-escode-overlay-texture-light to-escode-overlay-texture-dark rounded-2xl">
            <div className="escode-overlay w-full h-full flex flex-col gap-8 items-center justify-center text-general-grey-grey-10 border border-red-500">
                <FontAwesomeIcon icon={faDisplayCode} className="text-9xl" />
                <div className="flex flex-col gap-9 max-w-4xl items-center">
                    <div className="text-5xl text-general-white-white-100 font-semibold ">
                        {t('maintenance.title')}
                    </div>
                    <div className="text-xl text-center font-normal max-w-3xl">
                        {t('maintenance.description')}
                    </div>
                    <div className="text-xl text-center font-normal">
                        {t('maintenance.text')}
                    </div>
                    <Link
                        to="#"
                        onClick={(e) => {
                            window.location.href =
                                'mailto:escrowviewsupport@nccgroup.com';
                            e.preventDefault();
                        }}
                    >
                        <Button
                            type="button"
                            className="self-start"
                            icon={<FontAwesomeIcon icon={faEnvelope} />}
                        >
                            {t('maintenance.button')}
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Maintenance;
