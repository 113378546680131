import { Button, DropdownPanel, ToggleField } from 'lib-ui';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiamondExclamation } from '@fortawesome/pro-solid-svg-icons';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Menu from '../../../menu/menu';
import useDataWidget from '../use-data-widget';
import useDebounce from 'common/use-debounce';
import useSubscription from 'common/use-subscription';

interface FieldsSelectionPanelProps {}

function FieldsSelectionPanel({}: FieldsSelectionPanelProps) {
    const { t } = useTranslation();
    const {
        filters,
        hiddenFields,
        onHiddenFieldsChange,
        selectableFields,
        setHiddenFields
    } = useDataWidget();

    const debounce = useDebounce(500);

    const { getValues, setValue, watch, ...methods } = useForm({
        defaultValues: Object.fromEntries(
            selectableFields!.map((item) => [
                item.fieldId,
                !hiddenFields[item.fieldId]
            ])
        )
    });

    const apply = useCallback(
        (formValue: FieldValues) => {
            debounce(() => {
                const value = Object.fromEntries(
                    Object.entries(formValue).map(([key, value]) => [
                        key,
                        !value
                    ])
                );
                setHiddenFields(value);
                onHiddenFieldsChange!(value);
            });
        },
        [debounce, onHiddenFieldsChange, setHiddenFields]
    );

    useSubscription(
        watch((values) => {
            apply(values);
        })
    );

    const reset = useCallback(() => {
        selectableFields!.forEach(({ fieldId }) => {
            setValue(fieldId, true, { shouldDirty: true });
        });

        apply(getValues());
    }, [apply, getValues, selectableFields, setValue]);

    return (
        <DropdownPanel>
            <Menu>
                <FormProvider
                    {...methods}
                    getValues={getValues}
                    setValue={setValue}
                    watch={watch}
                >
                    <form className="flex flex-col gap-escode-xl">
                        {selectableFields!.map(({ fieldId, icon, label }) => (
                            <ToggleField
                                key={fieldId}
                                name={fieldId}
                                label={
                                    <div className="ml-escode-l flex items-center gap-escode-m text-sm">
                                        {icon}
                                        {label}
                                    </div>
                                }
                                warning={filters.some(
                                    (filter) => filter.colId === fieldId
                                )}
                                warningIcon={
                                    <FontAwesomeIcon
                                        icon={faDiamondExclamation}
                                    />
                                }
                                warningTitle={t(
                                    'filters.conflict-filter-tooltip-title'
                                )}
                                warningConfirmMessage={t('tooltip.proceed')}
                                warningMessage={t(
                                    'filters.conflict-filter-tooltip-message'
                                )}
                                className="text-black/75"
                                orientation="horizontal"
                            />
                        ))}
                    </form>
                </FormProvider>
                <Button
                    variant="danger"
                    className="w-full"
                    style={{ marginTop: '0.75rem' }}
                    onClick={reset}
                >
                    {t('table.reset-columns')}
                </Button>
            </Menu>
        </DropdownPanel>
    );
}

export default FieldsSelectionPanel;
export type { FieldsSelectionPanelProps };
