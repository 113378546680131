import { CSSProperties, PropsWithChildren, useMemo } from 'react';
import { ContactStatus } from 'core/api/client-portal/model/contact-status';
import { Tag } from 'lib-ui';

interface ContactStatusTagProps {
    status: ContactStatus;
}

function ContactStatusTag({
    children,
    status,
    ...restProps
}: PropsWithChildren<ContactStatusTagProps>) {
    const style = useMemo<CSSProperties>(
        () =>
            status === 'enabled'
                ? { background: '#99FFBB', color: '#0B1932' } // green
                : status === 'invite'
                ? {
                      background: '#ffffff',
                      border: '1px solid #d9dce1',
                      color: '#6b7386'
                  } // yellow
                : status === 'pending'
                ? { background: '#FAE5EB', color: '#D0003B' } // dark grey
                : status === 'disabled'
                ? { background: '#f3f4f8', color: '#334252' } // light grey
                : {},
        [status]
    );

    return (
        <Tag {...restProps} style={style}>
            {children}
        </Tag>
    );
}

export default ContactStatusTag;
export type { ContactStatusTagProps };
