import { AxiosResponse } from 'axios';
import { MutationOptions, useMutation } from 'react-query';
import { saveAsFile } from '@ncc-frontend/core';

import { ApiController } from '../../autogenerated/Api';
import { useAuth } from 'react-oidc-context';
import interceptors from '../../../interceptors';
import securityWorker from '../../../security-worker';

type AgreementsContractDownloadParams = {
    agreementId: number;
    agreementNumber?: string;
    index: string;
};

function useAgreementsContractDownload<TError = unknown, TContext = unknown>(
    options?: Omit<
        MutationOptions<
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            any,
            ReactQueryError<TError>,
            AgreementsContractDownloadParams,
            TContext
        >,
        'mutationFn'
    >
) {
    const auth = useAuth();

    return useMutation<
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        any,
        ReactQueryError<TError>,
        AgreementsContractDownloadParams,
        TContext
    >(
        [{ token: auth.user?.access_token, tokenType: auth.user?.token_type }],
        async ({ agreementId, agreementNumber, index }) => {
            if (process.env.REACT_APP_API_MOCK) {
                console.warn(
                    'Mock is enabled useAgreementsContractDownload call ignored.'
                );
                return Promise.resolve({} as AxiosResponse);
            }

            const controller = new ApiController({
                format: 'blob',
                interceptors,
                securityWorker: securityWorker(
                    auth.user?.token_type,
                    auth.user?.access_token
                )
            });

            return new Promise<AxiosResponse>((resolve, reject) => {
                controller
                    .downloadAgreementContracts(agreementId, index, {
                        format: 'blob'
                    })
                    // BACKEND: swagger doc
                    .then((response: AxiosResponse) => {
                        saveAsFile(
                            !agreementNumber
                                ? `signed_agreement_${agreementId}.pdf`
                                : `confirmation_agreement_${agreementNumber}.pdf`,
                            response.data,
                            {
                                type: 'application/pdf'
                            }
                        );
                        resolve(response.data);
                    });
            });
        },
        options
    );
}

export default useAgreementsContractDownload;
export type { AgreementsContractDownloadParams };
