import { Dropdown, useDropdown } from '@ncc-frontend/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLanguage } from '@fortawesome/pro-solid-svg-icons';

import HeaderButton from '../header-button';
import LanguageSelectionPanel from './language-selection-panel';

function LanguageSwitch() {
    const { openPanelId } = useDropdown();
    const panelId = 'language';

    return (
        <Dropdown.Trigger panelId={panelId} panel={<LanguageSelectionPanel />}>
            <HeaderButton
                fab
                variant={openPanelId === panelId ? 'primary' : 'default'}
                icon={<FontAwesomeIcon icon={faLanguage} className="text-lg" />}
            />
        </Dropdown.Trigger>
    );
}

export default LanguageSwitch;
