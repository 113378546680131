import { ApiController } from '../../autogenerated/Api';
import { AxiosResponse } from 'axios';
import { SpectreDepositResponse } from '../../autogenerated/data-contracts';
import { UseQueryOptions, useQuery } from 'react-query';
import { useAuth } from 'react-oidc-context';
import interceptors from 'core/api/interceptors';
import securityWorker from 'core/api/security-worker';

function useRequestSpectreToken(
    depositId: number,
    // options?: Omit<UseQueryOptions, 'queryFn'>
    options?: Omit<
        UseQueryOptions<
            SpectreDepositResponse | undefined,
            ReactQueryError<Error>,
            SpectreDepositResponse | undefined
        >,
        'queryKey' | 'queryFn'
    >
) {
    const auth = useAuth();

    return useQuery(
        [
            'requestToken',
            { token: auth.user?.access_token, tokenType: auth.user?.token_type }
        ],
        async () => {
            if (process.env.REACT_APP_API_MOCK) {
                console.warn('Mock is enabled useRequestToken call ignored.');
                return Promise.resolve({} as AxiosResponse<void | unknown>);
            }

            const controller = new ApiController({
                interceptors,
                securityWorker: securityWorker(
                    auth.user?.token_type,
                    auth.user?.access_token
                )
            });

            const data = controller.v1DepositsSpectretokenDetail(depositId);
            return await data;
        },
        {
            enabled: options?.enabled ?? false
        }
    );
}

export default useRequestSpectreToken;
