import { CSSProperties } from 'react';

import AgreementsWidget from './widgets/agreements-widget/agreements-widget';
import DepositTrackerWidget from './widgets/deposit-tracker-widget/deposit-tracker-widget';
import DocumentsWidget from './widgets/documents-widget/documents-widget';
import HighlightAgreementsWidget from './widgets/highlight-agreements-widget/highlight-agreements-widget';
import IncompleteDepositsWidget from './widgets/incomplete-deposits-widget/incomplete-deposits-widget';
import NewsWidget from './widgets/news-widget/news-widget';
import SourceControlConnectionsWidget from './widgets/source-control-connections-widget/source-control-connections-widget';
import UpcomingSchedulesWidget from './widgets/upcoming-schedules-widget/upcoming-schedules-widget';

interface DashboardProps {
    style?: CSSProperties;
}

function Dashboard({ ...restProps }) {
    return (
        <div
            {...restProps}
            className="h-full grid grid-cols-4 grid-rows-4 gap-5"
        >
            <AgreementsWidget className="row-span-2" />
            <DocumentsWidget />
            <HighlightAgreementsWidget className="col-span-2" />
            <DepositTrackerWidget className="col-span-2" />
            <NewsWidget />
            <UpcomingSchedulesWidget className="row-span-2" />
            <SourceControlConnectionsWidget className="col-span-2 row-span-2" />
            <IncompleteDepositsWidget className="row-span-2" />
        </div>
    );
}

export default Dashboard;
export type { DashboardProps };
