import { DropdownContextValue } from '@ncc-frontend/core';
import {
    PropsWithChildren,
    MouseEvent as ReactMouseEvent,
    ReactNode,
    useCallback,
    useRef
} from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../button/button';
import Tooltip, { TooltipProps } from './tooltip';

type ConfirmationTooltipEventHandler = (
    event?: ReactMouseEvent<HTMLButtonElement, MouseEvent>
) => void;

interface ConfirmationTooltipProps extends TooltipProps {
    confirmMessage?: string;
    disabled?: boolean;
    icon?: ReactNode;
    onConfirm?: ConfirmationTooltipEventHandler;
    onReject?: ConfirmationTooltipEventHandler;
    rejectMessage?: string;
    suppressConfirmation?: boolean;
    variant?: 'default' | 'warning';
}

function ConfirmationTooltip({
    children,
    confirmMessage,
    icon,
    message,
    onConfirm,
    onReject,
    rejectMessage,
    suppressConfirmation = false,
    title,
    variant = 'default',
    ...restProps
}: PropsWithChildren<ConfirmationTooltipProps>) {
    const { t } = useTranslation();
    const dropdown = useRef<DropdownContextValue>(null);

    const handleOpen = useCallback(() => {
        if (suppressConfirmation) {
            dropdown.current?.close();
            onConfirm?.();
        }
    }, [onConfirm, suppressConfirmation]);

    return (
        <Tooltip
            {...restProps}
            ref={dropdown}
            message={
                <div className="flex flex-col gap-2.5 max-w-xs">
                    {title && (
                        <div className="flex gap-2.5 text-base">
                            <span
                                className={
                                    variant === 'warning'
                                        ? 'text-general-orange-orange-100-100'
                                        : undefined
                                }
                            >
                                {icon}
                            </span>
                            {title}
                        </div>
                    )}
                    <div className="text-sm">{message}</div>
                    {!!onConfirm && (
                        <div className="flex gap-2.5">
                            <Button
                                onClick={onConfirm}
                                className="min-w-4xs"
                                variant={
                                    variant === 'default'
                                        ? 'primary'
                                        : 'warning'
                                }
                            >
                                {confirmMessage ?? t('confirmation.confirm')}
                            </Button>
                            <Button
                                onClick={onReject}
                                className="min-w-4xs"
                                variant="danger"
                            >
                                {rejectMessage ?? t('confirmation.reject')}
                            </Button>
                        </div>
                    )}
                </div>
            }
            onOpen={handleOpen}
        >
            {children}
        </Tooltip>
    );
}

export default ConfirmationTooltip;
export type { ConfirmationTooltipEventHandler, ConfirmationTooltipProps };
