import {
    AgreementDetails,
    AgreementPartyViewModel,
    DocumentMetadataResponseViewModel
} from 'core/api/client-portal/autogenerated/data-contracts';
import { AgreementPartiesTableContext } from './agreement-parties-table-context';
import { ColDef, TableWidget, TableWidgetProps } from 'lib-ui';
import { MouseEventHandler, useMemo } from 'react';
import { booleanFormatter, useModals } from '@ncc-frontend/core';
import { useTranslation } from 'react-i18next';

import AgreementPartyContactsCellRenderer from './agreement-party-contacts-cell-renderer';
import AgreementPartyContractCellRenderer from './agreement-party-contract-cell-renderer';
import AgreementPartyTypeCellRenderer from './agreement-party-type-cell-renderer';
import getDateFilter from 'core/ag-grid/get-date-filter';

interface AgreementPartiesTableWidgetProps {
    agreement: AgreementDetails | undefined;
    className?: string;
    contracts: DocumentMetadataResponseViewModel | undefined;
    error?: number;
    loading?: boolean;
    onReload?: MouseEventHandler<HTMLButtonElement>;
}

function AgreementPartiesTableWidget({
    agreement,
    className,
    contracts,
    error,
    loading,
    onReload
}: AgreementPartiesTableWidgetProps) {
    const { i18n, t } = useTranslation();
    const { push } = useModals();

    const id = 'agreement-parties-table';

    const columnDefs = useMemo<ColDef<AgreementPartyViewModel>[]>(
        () => [
            {
                field: 'registeredName',
                headerName: t('agreement-parties.header.registered-name')
            },
            {
                cellRenderer: AgreementPartyTypeCellRenderer,
                field: 'partyType',
                headerName: t('agreement-parties.header.party-type')
            },
            {
                field: 'partyStatus',
                headerName: t('agreement-parties.header.party-status')
            },
            {
                ...getDateFilter('partyStatusDate', i18n.language),
                colId: 'partyStatusDate',
                headerName: t('agreement-parties.header.status-date')
            },
            {
                colId: 'depositor',
                headerName: t('agreement-parties.header.depositor'),
                valueGetter: ({ data }) => t(booleanFormatter(data?.depositor))
            },
            {
                field: 'billingAccount',
                headerName: t('agreement-parties.header.bill-account')
            },
            {
                cellRenderer: AgreementPartyContractCellRenderer,
                colId: 'partyContract',
                headerName: t('agreement-parties.header.confirmation')
            },
            {
                autoHeight: true,
                cellRenderer: AgreementPartyContactsCellRenderer,
                colId: 'agreementPartyContacts',
                headerName: t('agreement-parties.header.contacts')
            }
        ],
        [i18n.language, t]
    );

    const noRowsOverlayComponentParams = useMemo<
        TableWidgetProps<false>['noRowsOverlayComponentParams']
    >(() => ({ message: t('agreement-parties.empty') }), [t]);

    const tableContext = useMemo<AgreementPartiesTableContext>(
        () => ({
            contracts,
            push,
            t
        }),
        [contracts, push, t]
    );

    return (
        <TableWidget
            id={id}
            context={tableContext}
            title={t('agreement-parties.title')}
            columnDefs={columnDefs}
            rowData={agreement?.viewableAgreementParties}
            loading={loading}
            className={className}
            noRowsOverlayComponentParams={noRowsOverlayComponentParams}
            error={error}
            onReload={onReload}
        />
    );
}

export default AgreementPartiesTableWidget;
export type { AgreementPartiesTableWidgetProps };
