import {
    InputField,
    RadioGroupField,
    Section,
    StepSection,
    TextareaField
} from 'lib-ui';
import { radioOptions } from 'ui/deposit-now/deposit-details-step/deposit-details-options';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ContentSection = ({ methods }: any) => {
    const { t } = useTranslation();
    const { setValue } = useForm();

    const compressed = useWatch({ name: 'moreDetails.isCompressed' });
    const encrypted = useWatch({ name: 'moreDetails.isEncrypted' });
    const exportControls =
        useWatch({ name: 'moreDetails.isSubjectToAnyExportControls' }) ===
        'yes';
    const personalData = useWatch({ name: 'moreDetails.containsPersonalData' });
    const requiresPassword = useWatch({
        name: 'moreDetails.isPasswordRequired'
    });

    useEffect(() => {
        if (personalData === 'yes') {
            setValue('moreDetails.isEncrypted', 'yes');
        }
    }, [personalData, setValue]);

    return (
        <StepSection stepTitle={t('deposit-now-stage-four.title')}>
            <Section
                className="flex h-auto min-w-5xl"
                heading={t('deposit-now-stage-four.deposit-content')}
            >
                <div className="flex gap-12">
                    <div className="flex flex-col flex-1 gap-y-6">
                        <RadioGroupField
                            label={t(
                                'deposit-now-stage-four.deposit-compressed'
                            )}
                            name="moreDetails.isCompressed"
                            options={radioOptions}
                            required
                            tooltip="deposit-now-stage-four.deposit-compressed-tooltip"
                        />

                        {compressed === 'yes' && (
                            <InputField
                                label={t(
                                    'deposit-now-stage-four.compression-method'
                                )}
                                name="moreDetails.compressionMethod"
                                placeholder={t(
                                    'deposit-now-stage-four.compression-placeholder'
                                )}
                                required
                            />
                        )}

                        <RadioGroupField
                            label={t('deposit-now-stage-four.deposit-export')}
                            name="moreDetails.isSubjectToAnyExportControls"
                            required
                            options={radioOptions}
                            tooltip="deposit-now-stage-four.deposit-export-tooltip"
                        />
                        {exportControls && (
                            <InputField
                                label={t(
                                    'deposit-now-stage-four.export-method'
                                )}
                                name="moreDetails.exportControlsDetails"
                                placeholder={t(
                                    'deposit-now-stage-four.export-placeholder'
                                )}
                                required
                            />
                        )}

                        <RadioGroupField
                            label={t('deposit-now-stage-four.deposit-personal')}
                            name="moreDetails.containsPersonalData"
                            required
                            options={radioOptions}
                            tooltip="deposit-now-stage-four.deposit-personal-tooltip"
                        />
                        {personalData === 'yes' && (
                            <InputField
                                label={t(
                                    'deposit-now-stage-four.personal-details'
                                )}
                                name="moreDetails.personalDataDetails"
                                placeholder={t(
                                    'deposit-now-stage-four.personal-placeholder'
                                )}
                                required
                            />
                        )}

                        <div className="flex items-start w-full gap-4">
                            <RadioGroupField
                                label={t('deposit-now-stage-four.password')}
                                name="moreDetails.isPasswordRequired"
                                required
                                options={radioOptions}
                            />
                            <InputField
                                disabled={
                                    requiresPassword === 'no' ||
                                    requiresPassword === null
                                }
                                name="moreDetails.password"
                                placeholder={t(
                                    'deposit-now-stage-four.enter-password'
                                )}
                                className="flex-1 pt-5"
                            />
                        </div>
                    </div>

                    <div className="flex flex-col flex-1 gap-y-6">
                        <RadioGroupField
                            disabled={personalData === 'yes'}
                            label={t(
                                'deposit-now-stage-four.deposit-encrypted'
                            )}
                            name="moreDetails.isEncrypted"
                            options={radioOptions}
                            required
                            tooltip="deposit-now-stage-four.deposit-personal-tooltip"
                        />

                        {encrypted === 'yes' && (
                            <InputField
                                label={t(
                                    'deposit-now-stage-four.encryption-method'
                                )}
                                name="moreDetails.encryptMethod"
                                placeholder={t(
                                    'deposit-now-stage-four.encryption-placeholder'
                                )}
                                required
                            />
                        )}

                        <RadioGroupField
                            label={t('deposit-now-stage-four.replacement')}
                            name="moreDetails.isReplacementOfPreviousDeposit"
                            options={radioOptions}
                            tooltip="deposit-now-stage-four.previous-deposits-tooltip"
                        />

                        <TextareaField
                            name="moreDetails.documentation"
                            label="Documentation"
                            placeholder={'type your message'}
                        />
                    </div>
                </div>
            </Section>
        </StepSection>
    );
};

export default ContentSection;
