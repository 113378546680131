import {
    ForwardedRef,
    PropsWithChildren,
    forwardRef,
    useCallback,
    useImperativeHandle,
    useRef,
    useState
} from 'react';
import classNames from 'classnames';

import Button from '../../button/button';

type SubmitButtonRef = {
    shake: () => void;
};

interface SubmitButtonProps {
    disabled?: boolean;
    loading?: boolean;
}

function SubmitButton(
    { children, disabled, loading }: PropsWithChildren<SubmitButtonProps>,
    ref: ForwardedRef<SubmitButtonRef>
) {
    const shakeTimeoutRef = useRef<NodeJS.Timeout>();
    const [isShake, setIsShake] = useState(false);

    const submitCssClasses = classNames({ 'animate-shake-once': isShake });

    const shake = useCallback(() => {
        setIsShake(true);
        shakeTimeoutRef.current = setTimeout(() => {
            setIsShake(false);
        }, 300);
    }, []);

    useImperativeHandle(ref, () => ({ shake }));

    return (
        <Button
            variant={isShake ? 'danger' : 'primary'}
            className={submitCssClasses}
            loading={loading}
            disabled={disabled}
        >
            {children}
        </Button>
    );
}
export default forwardRef(SubmitButton);
export type { SubmitButtonProps, SubmitButtonRef };
