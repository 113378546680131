import { FilterBackEventHandler, FilterData } from '../../data-widget-types';
import { ForwardedRef, forwardRef, useCallback } from 'react';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';

import Button from '../../../../button/button';
import FilterStep from '../filter-step/filter-step';
import useUserSettingsUpdate from 'core/api/client-portal/hooks/user-settings/use-user-settings-update';

interface FilterClearStepProps {
    name: string | undefined;
    onBack: FilterBackEventHandler;
    savedFilters: Record<string, FilterData[]>;
    savedFiltersSettingId: string;
}

function FilterClearStep(
    { name, onBack, savedFilters, savedFiltersSettingId }: FilterClearStepProps,
    ref: ForwardedRef<HTMLDivElement>
) {
    const { mutate: updateUserSettings } = useUserSettingsUpdate();
    const { t } = useTranslation();

    const handleDelete = useCallback(() => {
        if (name) {
            const next = cloneDeep(savedFilters);
            delete next[name];
            updateUserSettings({
                data: [
                    {
                        settingId: savedFiltersSettingId,
                        settingJson: JSON.stringify(next)
                    }
                ]
            });
        }

        onBack();
    }, [name, onBack, savedFilters, savedFiltersSettingId, updateUserSettings]);

    return (
        <FilterStep ref={ref}>
            <FilterStep.Header onBack={onBack}>
                {t('filters.delete-confirmation-title', { name })}
            </FilterStep.Header>

            <FilterStep.Footer>
                <Button
                    variant="danger"
                    className="flex-1"
                    onClick={handleDelete}
                >
                    {t('filters.delete')}
                </Button>
                <Button variant="primary" className="flex-1" onClick={onBack}>
                    {t('filters.clear-cancel')}
                </Button>
            </FilterStep.Footer>
        </FilterStep>
    );
}

export default forwardRef(FilterClearStep);
export type { FilterClearStepProps };
