import { DownloadScheduleItem } from 'core/api/client-portal/autogenerated/data-contracts';
import { EditScheduleFormValues } from '../schedule-form/types';
import { INVERT_DEPOSIT_FREQUENCY_MAPPER } from 'core/api/client-portal/model/deposit-frequency';
import { Modal, ResultModal } from 'lib-ui';
import { SubmitHandler } from 'react-hook-form';
import { useCallback } from 'react';
import { useModals } from '@ncc-frontend/core';
import { useTranslation } from 'react-i18next';

import ScheduleForm from '../schedule-form/schedule-form';
import useSchedulesUpdate from 'core/api/client-portal/hooks/schedules/use-schedules-update';

interface EditScheduleModalProps {
    data: DownloadScheduleItem;
}

function EditScheduleModal({ data }: EditScheduleModalProps) {
    const { t } = useTranslation();
    const { pop, push } = useModals();

    const { isLoading, mutate: schedulesUpdate } = useSchedulesUpdate({
        onError: () => {
            push(ResultModal, {
                description: t('schedules.edit-modal.error-description'),
                title: t('schedules.edit-modal.error-title')
            });
        },
        onSuccess: () => {
            pop();
            push(ResultModal, {
                description: t('schedules.edit-modal.success-description'),
                title: t('schedules.edit-modal.success-title')
            });
        }
    });

    const onSubmit = useCallback<SubmitHandler<EditScheduleFormValues>>(
        (formData) => {
            const today = new Date();
            let dayOfMonth = today.getDate();
            let month = today.getMonth();
            let year = today.getFullYear();

            switch (formData.frequency) {
                case 'daily': {
                    const day = new Date(
                        year,
                        month,
                        dayOfMonth,
                        +formData.hour,
                        +formData.minute
                    );
                    const isInThePast = day <= today;
                    if (isInThePast) dayOfMonth += 1;
                    break;
                }

                case 'weekly': {
                    dayOfMonth =
                        today.getDate() + +formData.dayOfWeek - today.getDay();

                    const day = new Date(
                        year,
                        month,
                        dayOfMonth,
                        +formData.hour,
                        +formData.minute
                    );
                    const isInThePast = day <= today;
                    if (isInThePast) dayOfMonth += 7;
                    break;
                }

                case 'monthly': {
                    dayOfMonth = +formData.dayOfMonth;

                    const day = new Date(
                        year,
                        month,
                        dayOfMonth,
                        +formData.hour,
                        +formData.minute
                    );
                    const isInThePast = day <= today;
                    if (isInThePast) month += 1;
                    break;
                }

                case 'annually': {
                    dayOfMonth = +formData.dayOfMonth;
                    month = +formData.month;

                    const day = new Date(
                        year,
                        month,
                        dayOfMonth,
                        +formData.hour,
                        +formData.minute
                    );
                    const isInThePast = day <= today;
                    if (isInThePast) year += 1;
                    break;
                }

                case 'quarterly': {
                    const range = formData.rangeOfQuarterly;
                    dayOfMonth = +formData.dayOfMonth;
                    month = parseInt(range) - 1;
                    break;
                }
            }

            const nextDownloadAt = new Date(
                year,
                month,
                dayOfMonth,
                +formData.hour,
                +formData.minute
            ).toISOString();

            schedulesUpdate({
                data: {
                    downloadId: data.id,
                    frequencyType:
                        INVERT_DEPOSIT_FREQUENCY_MAPPER[formData.frequency],
                    items: data.items!,
                    nextDownloadAt
                }
            });
        },
        [data.id, data.items, schedulesUpdate]
    );

    return (
        <Modal className="overflow-hidden max-h-4/5">
            <Modal.Header>{t('schedules.edit-modal.title')}</Modal.Header>
            <Modal.Body>
                <ScheduleForm
                    data={data}
                    frequency={data.frequencyType || 0}
                    onSubmit={onSubmit}
                    loading={isLoading}
                />
            </Modal.Body>
        </Modal>
    );
}

export default EditScheduleModal;
export type { EditScheduleModalProps };
