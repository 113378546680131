import { AgreementViewModel } from 'core/api/client-portal/autogenerated/data-contracts';
import { Modal } from 'lib-ui';
import { useTranslation } from 'react-i18next';

import HighlightAgreementListModalItem from './highlight-agreement-modal-item';
import useIncompleteDeposits from 'core/api/client-portal/hooks/deposits/use-incomplete-deposits';

interface HighlightAgreementsModalProps {
    data: AgreementViewModel[];
}

function HighlightAgreementsModal({ data }: HighlightAgreementsModalProps) {
    const { t } = useTranslation();

    const { data: incompleteDeposits } = useIncompleteDeposits();

    return (
        <Modal size="m">
            <Modal.Header>
                {t('dashboard.highlight-agreements-widget.title')}
            </Modal.Header>
            <Modal.Body className="flex flex-col gap-2.5">
                {data.map((agreement) => (
                    <HighlightAgreementListModalItem
                        key={agreement.agreementId}
                        agreement={agreement}
                        deposit={
                            incompleteDeposits?.deposits
                                ?.filter(
                                    (entry) =>
                                        entry.agreementId ===
                                        agreement.agreementId
                                )
                                .slice(-1)[0]
                        }
                    />
                ))}
            </Modal.Body>
        </Modal>
    );
}

export default HighlightAgreementsModal;
export type { HighlightAgreementsModalProps };
