import { TextareaField, YesNoField } from 'lib-ui';
import { useTranslation } from 'react-i18next';
import FeedbackFormStep, { FeedbackFormStepProps } from './feedback-form-step';

type FieldValues = {
    isEasierToUse: 'yes' | 'no' | '';
    isEasierToUseComment: string;
};

interface EaseOfUseStepProps {
    defaultValues: FieldValues;
    onChange: FeedbackFormStepProps<FieldValues>['onChange'];
}

function EaseOfUseStep({ defaultValues, onChange }: EaseOfUseStepProps) {
    const { t } = useTranslation();

    return (
        <FeedbackFormStep<FieldValues>
            message={t('feedback-form.ease-of-use-question')}
            title={t('feedback-form.ease-of-use')}
            onChange={onChange}
            defaultValues={defaultValues}
        >
            <YesNoField name="isEasierToUse" required />
            <TextareaField
                name="isEasierToUseComment"
                placeholder={t('feedback-form.comment-placeholder')}
            />
        </FeedbackFormStep>
    );
}

export default EaseOfUseStep;
export type { EaseOfUseStepProps };
