import { CommonProps } from '@ncc-frontend/core';
import classNames from 'classnames';

import Skeleton, { SkeletonProps } from './skeleton';

interface SkeletonLineProps extends CommonProps {
    absolute?: boolean;
    variant?: SkeletonProps['variant'];
}

function SkeletonLine({
    absolute,
    className,
    variant,
    ...restProps
}: SkeletonLineProps) {
    const cssClasses = classNames(
        {
            'absolute inset-0 bg-ncc-white': !!absolute
        },
        className
    );
    return (
        <div {...restProps} className={cssClasses}>
            <Skeleton className="w-full h-full rounded-lg" variant={variant} />
        </div>
    );
}

export default SkeletonLine;
export type { SkeletonLineProps };
