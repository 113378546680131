import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Transition } from '@headlessui/react';
import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';

import classNames from 'classnames';
import toast from 'react-hot-toast';

function customToast({
    message,
    type
}: {
    message: string;
    type: 'success' | 'error';
}) {
    const cssClasses = classNames({
        'text-escode-green': type === 'success',
        'text-escode-red': type === 'error'
    });
    const bgClass =
        type === 'success' ? 'bg-escode-green-20' : 'bg-escode-red-20';

    return toast.custom((t) => (
        <Transition
            appear
            className={`flex items-center transform gap-3 p-3 rounded-lg shadow-lg ${bgClass} bg-dark text-sm`}
            data-testid="toast"
            enter="transition-all duration-150"
            enterFrom="opacity-0 scale-50"
            enterTo="opacity-100 scale-100"
            leave="transition-all duration-150"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-75"
            show={t.visible}
        >
            {type === 'success' && (
                <FontAwesomeIcon
                    icon={type === 'success' ? faCheckCircle : faTimesCircle}
                    className={cssClasses}
                />
            )}

            <p className={cssClasses}> {message}</p>
            <button onClick={() => toast.dismiss(t.id)} type="button">
                <FontAwesomeIcon icon={faTimesCircle} className={cssClasses} />
            </button>
        </Transition>
    ));
}

const toastFunctions = {
    error: (message: string) => customToast({ message, type: 'error' }),
    success: (message: string) => customToast({ message, type: 'success' })
};

export default toastFunctions;
