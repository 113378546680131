import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import {
    CSSProperties,
    createRef,
    forwardRef,
    memo,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef
} from 'react';
import {
    ColDef,
    ColGroupDef,
    ColumnState,
    GridOptions,
    GridReadyEvent
} from 'ag-grid-community';
import { TailwindProps, tailwindClasses } from '@ncc-frontend/core';
import { merge } from 'lodash';
import classNames from 'classnames';

import TableNoRowsOverlay, {
    TableNoRowsOverlayProps
} from './table-no-rows-overlay';
import TableTooltip from './table-tooltip';
import useUserSettings from 'core/api/client-portal/hooks/user-settings/use-user-settings';
import useUserSettingsUpdate from 'core/api/client-portal/hooks/user-settings/use-user-settings-update';

interface TableProps
    extends Omit<
            AgGridReactProps,
            | 'suppressDragLeaveHidesColumns'
            | 'pagination'
            | 'suppressPaginationPanel'
            | 'noRowsOverlayComponent'
            | 'noRowsOverlayComponentParams'
            | 'onFirstDataRendered'
            | 'onSortChanged'
            | 'onColumnResized'
            | 'onColumnGroupOpened'
            | 'onColumnMoved'
            | 'onColumnPinned'
            | 'onColumnVisible'
            | 'onColumnPivotChanged'
        >,
        TailwindProps {
    /** Id is used to store table state on local storage so it must be unique to work properly */
    id?: string;
    loading?: boolean;
    noRowsOverlayComponentParams?: TableNoRowsOverlayProps;
}
const Table = forwardRef<AgGridReact, TableProps>(
    (
        {
            className,
            defaultColDef,
            gridOptions,
            id,
            loading,
            onGridReady,
            paginationPageSize = 50,
            style,
            tailwindStyle,
            ...agGridProps
        },
        ref
    ) => {
        const agGrid = createRef<AgGridReact>();
        const wrapper = useRef<HTMLDivElement>(null);

        const settingId = id ? `table-state-${id}` : undefined;
        const { data: settings, isLoading: isLoadingSettings } =
            useUserSettings(
                { settingId },
                (data) => {
                    if (!data?.[0]?.settingJson) return;
                    return JSON.parse(data?.[0]?.settingJson) as {
                        colId: string;
                        hide: boolean;
                    }[];
                },
                {
                    onSuccess: (data) => {
                        if (!data || !agGrid.current) return;

                        const state =
                            agGrid.current?.columnApi.getColumnState();

                        if (
                            data.some(
                                (savedItem) =>
                                    state?.find(
                                        (currentItem) =>
                                            currentItem.colId ===
                                            savedItem.colId
                                    )?.hide !== savedItem.hide
                            )
                        ) {
                            // Override hide property with backend value
                            agGrid.current.columnApi.applyColumnState({
                                applyOrder: true,
                                state: state.map((col) => ({
                                    ...col,
                                    hide: data.find(
                                        (item) => item.colId === col.colId
                                    )?.hide
                                }))
                            });
                        }
                    }
                }
            );

        const { mutate: updateUserSettings } = useUserSettingsUpdate();

        const cssClasses = useMemo(
            () =>
                classNames(
                    'ag-theme-alpine',
                    className,
                    tailwindClasses(
                        {
                            height: 'h-full'
                        },
                        tailwindStyle
                    )
                ),
            [className, tailwindStyle]
        );

        const mergedGridOptions = useMemo(
            () =>
                merge<GridOptions, GridOptions | undefined>(
                    {
                        components: {
                            tooltip: TableTooltip
                        },
                        loadingOverlayComponent: memo(() => <>Loading...</>),
                        noRowsOverlayComponent: memo(() => <>None...</>),
                        rowClass: 'escode-row',
                        // rowHeight has to equal line-height defined on escode-row css class */
                        rowHeight: 51
                    },
                    gridOptions
                ),
            [gridOptions]
        );

        const mergedDefaultColDef = useMemo(
            () =>
                merge<ColDef | ColGroupDef, ColDef | ColGroupDef | undefined>(
                    {
                        cellClass: 'select-text',
                        resizable: true,
                        sortable: true,
                        suppressMenu: true,
                        tooltipComponent: 'tooltip'
                    },
                    defaultColDef
                ),
            [defaultColDef]
        );

        const mergedStyle = useMemo<CSSProperties>(
            () => merge({}, style),
            [style]
        );

        const autoSize = useCallback(() => {
            setTimeout(() => {
                agGrid.current?.columnApi.autoSizeAllColumns(false);
            }, 50);
        }, [agGrid]);

        // Restore table state on grid ready and show loading overlay if loading.
        const handleGridReady = useCallback<(event: GridReadyEvent) => void>(
            (event) => {
                if (settingId) {
                    const stateString = localStorage.getItem(settingId);
                    if (stateString) {
                        let state = JSON.parse(stateString) as ColumnState[];
                        if (!isLoadingSettings && settings) {
                            // Override hide property with backend value
                            state = state.map((col) => ({
                                ...col,
                                hide: settings.find(
                                    (item) => item.colId === col.colId
                                )?.hide
                            }));
                        }

                        event.columnApi.applyColumnState({
                            applyOrder: true,
                            state
                        });
                    }
                }

                autoSize();

                onGridReady?.(event);
            },
            [autoSize, isLoadingSettings, onGridReady, settingId, settings]
        );

        const saveTableState = useCallback(() => {
            if (settingId) {
                const state = agGrid.current?.columnApi.getColumnState();
                const storedState = localStorage.getItem(settingId);
                if (JSON.stringify(state) !== storedState) {
                    localStorage.setItem(settingId, JSON.stringify(state));

                    const data = state?.map((item) => ({
                        colId: item.colId,
                        hide: item.hide
                    }));
                    if (
                        !settings ||
                        (settings &&
                            settings.some(
                                (savedItem) =>
                                    data?.find(
                                        (currentItem) =>
                                            currentItem.colId ===
                                            savedItem.colId
                                    )?.hide !== savedItem.hide
                            ))
                    )
                        // Save hide property to backend
                        updateUserSettings({
                            data: [
                                { settingId, settingJson: JSON.stringify(data) }
                            ]
                        });
                }
            }
        }, [agGrid, settingId, settings, updateUserSettings]);

        // Hide show loading/error overlay depending on state.
        useEffect(() => {
            if (loading) {
                agGrid.current?.api?.showLoadingOverlay();
            } else if (agGridProps.rowData?.length === 0) {
                agGrid.current?.api?.showNoRowsOverlay();
            } else {
                agGrid.current?.api?.hideOverlay();
                autoSize();
            }
        }, [agGrid, agGridProps.rowData?.length, autoSize, loading]);

        useImperativeHandle(ref, () => agGrid.current!);

        return (
            <div className={cssClasses} style={mergedStyle} ref={wrapper}>
                <AgGridReact
                    {...agGridProps}
                    ref={agGrid}
                    gridOptions={mergedGridOptions}
                    animateRows
                    tooltipShowDelay={1000}
                    tooltipMouseTrack
                    noRowsOverlayComponent={TableNoRowsOverlay}
                    paginationPageSize={paginationPageSize}
                    pagination={true}
                    suppressPaginationPanel={true}
                    defaultColDef={mergedDefaultColDef}
                    onGridReady={handleGridReady}
                    suppressDragLeaveHidesColumns
                    onFirstDataRendered={autoSize}
                    onSortChanged={saveTableState}
                    onColumnResized={saveTableState}
                    onColumnGroupOpened={saveTableState}
                    onColumnMoved={saveTableState}
                    onColumnPinned={saveTableState}
                    onColumnVisible={saveTableState}
                    onColumnPivotChanged={saveTableState}
                />
            </div>
        );
    }
);

export default Table;
export type { TableProps };
