import { DEPOSIT_FREQUENCY_MAPPER } from 'core/api/client-portal/model/deposit-frequency';
import { DownloadScheduleItem } from 'core/api/client-portal/autogenerated/data-contracts';

import SchedulesMetric from './schedules-metric';

interface SchedulesMetricPanelProps {
    data: DownloadScheduleItem[] | null | undefined;
    filter: (schedule: DownloadScheduleItem) => boolean;
    loading?: boolean;
    title?: string;
}

function SchedulesMetricPanel({
    data,
    filter,
    title
}: SchedulesMetricPanelProps) {
    const daily =
        data
            ?.filter(filter)
            .filter(
                (item) =>
                    DEPOSIT_FREQUENCY_MAPPER[item.frequencyType ?? 0] ===
                    'daily'
            ).length || 0;

    const weekly =
        data
            ?.filter(filter)
            .filter(
                (item) =>
                    DEPOSIT_FREQUENCY_MAPPER[item.frequencyType ?? 0] ===
                    'weekly'
            ).length || 0;

    const monthly =
        data
            ?.filter(filter)
            .filter(
                (item) =>
                    DEPOSIT_FREQUENCY_MAPPER[item.frequencyType ?? 0] ===
                    'monthly'
            ).length || 0;
    const quarterly =
        data
            ?.filter(filter)
            .filter(
                (item) =>
                    DEPOSIT_FREQUENCY_MAPPER[item.frequencyType ?? 0] ===
                    'quarterly'
            ).length || 0;

    const annually =
        data
            ?.filter(filter)
            .filter(
                (item) =>
                    DEPOSIT_FREQUENCY_MAPPER[item.frequencyType ?? 0] ===
                    'annually'
            ).length || 0;

    const total = daily + weekly + monthly + quarterly + annually;
    const factor = 100 / total;

    return (
        <div className="flex flex-col gap-5 border px-6 pt-5 pb-7.5 border-general-grey-grey-30 rounded-lg">
            <div className="pb-3.5 border-b border-escode-grey-20">
                <div className="text-general-grey-grey-90 text-sm">{title}</div>
                <div className="text-general-grey-grey-90 text-xl font-semibold">
                    {total}
                </div>
            </div>
            <SchedulesMetric variant="daily" value={daily * factor}>
                {daily}
            </SchedulesMetric>
            <SchedulesMetric variant="weekly" value={weekly * factor}>
                {weekly}
            </SchedulesMetric>
            <SchedulesMetric variant="monthly" value={monthly * factor}>
                {monthly}
            </SchedulesMetric>
            <SchedulesMetric variant="quarterly" value={quarterly * factor}>
                {quarterly}
            </SchedulesMetric>
            <SchedulesMetric variant="annually" value={annually * factor}>
                {annually}
            </SchedulesMetric>
        </div>
    );
}

export default SchedulesMetricPanel;
export type { SchedulesMetricPanelProps };
