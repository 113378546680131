import AgreementDetails from 'ui/agreements/agreement-details';
import AppBody from './app-body';

function AgreementDetailsBody() {
    return (
        <AppBody>
            <AgreementDetails />
        </AppBody>
    );
}

export default AgreementDetailsBody;
