import { Button } from 'lib-ui';
import { useCallback } from 'react';
import { useModals } from '@ncc-frontend/core';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';

import { AgreementViewModel } from 'core/api/client-portal/autogenerated/data-contracts';
import timestampFormatter from 'core/formatters/timestamp-formatter';
import useBeautify from 'core/beautifiers/use-beautify';
import useCreateDeposit from 'core/api/client-portal-v2/hooks/deposits/use-create-deposit';

import toastFunctions from 'lib-ui/toast/Toast';

interface HighlightAgreementListItemProps {
    agreement: AgreementViewModel;
    agreementId?: number;
    date?: string | null;
    name?: string | number;
}

function HighlightAgreementListItem({
    agreement,
    agreementId,
    date,
    name
}: HighlightAgreementListItemProps) {
    const queryClient = useQueryClient();
    const { i18n, t } = useTranslation();
    const { beautifyDate } = useBeautify();
    const { pop } = useModals();
    const goTo = useNavigate();

    const { isLoading: creating, mutate: createDeposit } = useCreateDeposit();

    const submit = useCallback(() => {
        queryClient.invalidateQueries(['use-deposit-details-v2']);

        createDeposit(
            { relatedAgreements: { relatedAgreements: [agreementId || 0] } },
            {
                onError() {
                    toastFunctions.error(t('toast.error'));
                },
                onSuccess(res) {
                    pop();
                    toastFunctions.success(t('toast.success'));
                    localStorage.setItem('depositId', res.data.depositId);
                    goTo(`/deposit-now/${res.data.depositId}/contact-details`);
                }
            }
        );
    }, [agreementId, createDeposit, goTo, pop, queryClient, t]);

    return (
        <div className="grid grid-cols-[1fr,auto] gap-6 items-center border-b border-brand-escode-neonblue-neonblue-10 pb-4">
            <div className="grid gap-1 grid-cols-1">
                <p className="text-general-grey-grey-90 text-sm">{name}</p>
                <p className="text-escode-grey-50 text-xs ">
                    {beautifyDate(timestampFormatter(date, i18n.language))}
                </p>
            </div>
            <Button
                disabled={!agreement.viewingPartyCanCreateDeposit || creating}
                onClick={submit}
                variant="tertiary"
                size="s"
            >
                {t('highlight-agreement.deposit')}
            </Button>
        </div>
    );
}

export default HighlightAgreementListItem;
export type { HighlightAgreementListItemProps };
