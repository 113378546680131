import { AUDIT_RECORD_TYPE_MAPPER } from 'core/api/client-portal/model/audit-record-type';
import { ContactAuditRecordViewModel } from 'core/api/client-portal/autogenerated/data-contracts';
import { useTranslation } from 'react-i18next';
import timestampFormatter from 'core/formatters/timestamp-formatter';
import useBeautify from 'core/beautifiers/use-beautify';

import RecordTypeTag from './record-type-tag';

interface AuditHistoryTableItemProps {
    item: ContactAuditRecordViewModel;
}

function AuditHistoryTableItem({ item }: AuditHistoryTableItemProps) {
    const { description, eventTime, recordType, user } = item;
    const { beautifyDate } = useBeautify();
    const { i18n } = useTranslation();

    const tableDataStyles = 'p-2.5';

    return (
        <tr className="border-escode-grey-30 border-b">
            <td className={tableDataStyles}>
                <RecordTypeTag
                    recordType={AUDIT_RECORD_TYPE_MAPPER[recordType ?? 0]}
                />
            </td>
            <td className={tableDataStyles}>
                {beautifyDate(timestampFormatter(eventTime, i18n.language))}
            </td>
            <td className={tableDataStyles}>{user}</td>
            <td className={tableDataStyles}>{description}</td>
        </tr>
    );
}

export default AuditHistoryTableItem;
