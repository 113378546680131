import { MutationOptions, useMutation } from 'react-query';
import { RequestNovationRequestViewModel } from '../../autogenerated/data-contracts';

import { ApiController } from '../../autogenerated/Api';
import { AxiosResponse } from 'axios';
import { useAuth } from 'react-oidc-context';
import interceptors from '../../../interceptors';
import securityWorker from '../../../security-worker';

type AgreementsRequestNovationParams = {
    data: RequestNovationRequestViewModel;
};

function useAgreementsRequestNovation<TError = unknown, TContext = unknown>(
    options?: Omit<
        MutationOptions<
            AxiosResponse<void>,
            ReactQueryError<TError>,
            AgreementsRequestNovationParams,
            TContext
        >,
        'mutationFn'
    >
) {
    const auth = useAuth();

    return useMutation(
        [{ token: auth.user?.access_token, tokenType: auth.user?.token_type }],
        async ({ data }: AgreementsRequestNovationParams) => {
            if (process.env.REACT_APP_API_MOCK) {
                console.warn(
                    'Mock is enabled useAgreementsRequestNovation call ignored.',
                    data
                );
                return Promise.resolve({} as AxiosResponse<void>);
            }

            const controller = new ApiController({
                interceptors,
                securityWorker: securityWorker(
                    auth.user?.token_type,
                    auth.user?.access_token
                )
            });

            return controller.v1ClientAgreementsRequestNovationCreate(data);
        },
        options
    );
}

export default useAgreementsRequestNovation;
export type { AgreementsRequestNovationParams };
