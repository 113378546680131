import {
    TypographyLinkProps as CoreTypographyLinkProps,
    TailwindStyle,
    Typography
} from '@ncc-frontend/core';
import { MouseEventHandler, PropsWithChildren, useMemo } from 'react';
import { merge } from 'lodash';

interface TypographyLinkProps extends CoreTypographyLinkProps {
    onClick?: MouseEventHandler<HTMLElement>;
}

function TypographyLink({
    children,
    tailwindStyle,
    ...restProps
}: PropsWithChildren<TypographyLinkProps>) {
    const mergedTailwindStyle = useMemo(
        () =>
            merge<TailwindStyle, TailwindStyle | undefined>(
                {
                    cursor: 'cursor-pointer',
                    textColor: 'text-brand-escode-neonblue-neonblue-100',
                    transition: 'transition-colors',
                    variant: {
                        hover: {
                            textColor:
                                'hover:text-brand-escode-neonblue-neonblue-80'
                        }
                    }
                },
                tailwindStyle
            ),
        [tailwindStyle]
    );

    return (
        <Typography.Link {...restProps} tailwindStyle={mergedTailwindStyle}>
            {children}
        </Typography.Link>
    );
}

export default TypographyLink;
export type { TypographyLinkProps };
