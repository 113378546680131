import { CommonProps } from '@ncc-frontend/core';
import classNames from 'classnames';

interface SkeletonProps extends CommonProps {
    variant?: 'default' | 'white';
}

function Skeleton({
    className,
    variant = 'default',
    ...restProps
}: SkeletonProps) {
    const cssClasses = classNames(
        'animate-pulse',
        {
            'bg-general-grey-grey-20': variant === 'default',
            'bg-ncc-white': variant === 'white'
        },
        className
    );

    return <div {...restProps} className={cssClasses} />;
}

export default Skeleton;
export type { SkeletonProps };
