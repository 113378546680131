import { CommonProps } from '@ncc-frontend/core';
import { useEffect, useState } from 'react';

import { CalendarData } from '../types';
import { useSchedulesContext } from 'ui/schedules/schedules-context-provider';
import CalendarDayEvent from './calendar-day-event';
import ScrollHarness from 'lib-ui/scroll-harness/scroll-harness';

interface CalendarDayProps extends CommonProps {
    data: CalendarData[] | undefined;
    day: Date;
    onUpdateHasData: (newValue: boolean) => void;
}
const params = {
    timeZone: 'Europe/London'
};

function CalendarDay({ data, day, onUpdateHasData }: CalendarDayProps) {
    const { onUpdateDepositScheduleData } = useSchedulesContext();

    const [time, setTime] = useState(new Date());
    const [eventsOfDay, setEventsOfDay] = useState<CalendarData[]>([]);

    useEffect(() => {
        // Filter events for the current day and update the state variable
        if (data) {
            const eventsToday = data.filter(
                ({ date }) =>
                    date.toLocaleDateString() === day.toLocaleDateString()
            );
            setEventsOfDay(eventsToday);
        }
    }, [data, day]);

    useEffect(() => {
        const actualDate = new Date();
        const currentSeconds = actualDate.getSeconds();

        const delay = (60 - currentSeconds) * 1000;

        const timer = setTimeout(() => {
            setTime(new Date());
        }, delay);

        return () => {
            clearTimeout(timer);
        };
    }, [time]);

    useEffect(() => {
        onUpdateHasData(eventsOfDay.length > 0);
        onUpdateDepositScheduleData(eventsOfDay);
    }, [eventsOfDay, onUpdateDepositScheduleData, onUpdateHasData]);

    return (
        <table
            role="grid"
            className="h-full w-full border-collapse table-fixed mb-6"
        >
            <thead>
                <tr role="presentation">
                    <th role="presentation" className="p-0">
                        <table
                            role="presentation"
                            className="w-full border-collapse table-fixed"
                        >
                            <colgroup>
                                <col className="w-10" />
                            </colgroup>
                        </table>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr role="presentation">
                    <td role="presentation" className="w-full pt-0">
                        <ScrollHarness>
                            {/* Time slots */}
                            <table
                                aria-hidden
                                className="w-full border-collapse table-fixed "
                            >
                                <colgroup>
                                    <col className="w-10" />
                                </colgroup>
                                <tbody data-testid="table_body">
                                    {new Array(24).fill(0).map((_, index) => {
                                        const isCurrentHour =
                                            index === new Date().getHours();

                                        return (
                                            <tr
                                                key={index}
                                                data-testid="tr_hours"
                                            >
                                                <td className="h-32 align-top general-grey-grey-10 text-xs bg-escode-grey-10 pl-1">
                                                    {index
                                                        .toString()
                                                        .padStart(2, '0')}
                                                    :00
                                                </td>
                                                <td className="h-32 border border-escode-grey-30">
                                                    {eventsOfDay
                                                        ?.filter((event) => {
                                                            return (
                                                                event.date.toLocaleDateString() ===
                                                                day.toLocaleDateString()
                                                            );
                                                        })
                                                        .map((event) => {
                                                            return (
                                                                <CalendarDayEvent
                                                                    key={`${event.date.toDateString()} ${
                                                                        event.title
                                                                    }`}
                                                                    date={
                                                                        event.date
                                                                    }
                                                                    tailwindStyle={
                                                                        event.tailwindStyle
                                                                    }
                                                                >
                                                                    {
                                                                        event.title
                                                                    }
                                                                </CalendarDayEvent>
                                                            );
                                                        })}
                                                    {isCurrentHour && (
                                                        <CalendarDayEvent
                                                            key={time.toLocaleDateString(
                                                                'en-GB',
                                                                params
                                                            )}
                                                            date={
                                                                new Date(time)
                                                            }
                                                            tailwindStyle={{
                                                                margin: 'ml-5 -mt-1',
                                                                width: 'w-full'
                                                            }}
                                                        >
                                                            <hr className="border-2 border-brand-escode-neonblue-neonblue-100" />
                                                            <div className="absolute left-2.5 top-0 bg-brand-escode-neonblue-neonblue-100 w-3 h-3 z-10 rounded-xl" />
                                                        </CalendarDayEvent>
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </ScrollHarness>
                    </td>
                </tr>
            </tbody>
        </table>
    );
}

export default CalendarDay;
export type { CalendarDayProps };
