import { PropsWithChildren } from 'react';
import { TailwindStyle, tailwindClasses } from '@ncc-frontend/core';

function mapRange(
    value: number,
    fromMin: number,
    fromMax: number,
    toMin: number,
    toMax: number
) {
    return ((value - fromMin) * (toMax - toMin)) / (fromMax - fromMin) + toMin;
}

interface CalendarWeekEventProps {
    date: Date;
    tailwindStyle?: TailwindStyle;
    title: string;
}

function CalendarWeekEvent({
    children,
    date,
    tailwindStyle,
    title
}: PropsWithChildren<CalendarWeekEventProps>) {
    const value = mapRange(
        date.getHours() + mapRange(date.getMinutes(), 0, 60, 0, 1),
        0,
        24,
        0,
        100
    );

    return (
        <div
            className={tailwindClasses(
                {
                    borderRadius: 'rounded-full',
                    left: 'left-1',
                    padding: 'pt-1 px-2 pb-1.5',
                    position: 'absolute',
                    right: 'right-1',
                    textWeight: 'font-medium',
                    truncate: 'truncate'
                },
                tailwindStyle
            )}
            style={{
                top: `${value}%`
            }}
            title={title}
        >
            {children}
        </div>
    );
}

export default CalendarWeekEvent;
export type { CalendarWeekEventProps };
