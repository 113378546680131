import { GetDepositsResponse } from '../../autogenerated/data-contracts';
import { UseQueryOptions, useQuery } from 'react-query';

import { ApiController } from '../../autogenerated/Api';
import { getDepositsListMockData } from './use-deposits-list-mock-data';
import { useAuth } from 'react-oidc-context';
import interceptors from '../../../interceptors';
import securityWorker from '../../../security-worker';

// TODO: use Query type in all other hooks.

function useDepositsList<TError = unknown>(
    options?: Omit<
        UseQueryOptions<
            GetDepositsResponse | undefined,
            ReactQueryError<TError>,
            GetDepositsResponse | undefined
        >,
        'queryKey' | 'queryFn' | 'getNextPageParam'
    >
) {
    const auth = useAuth();

    return useQuery<
        GetDepositsResponse | undefined,
        ReactQueryError<TError>,
        GetDepositsResponse | undefined
    >(
        [
            'deposits',
            'list',
            { token: auth.user?.access_token, tokenType: auth.user?.token_type }
        ],
        async () => {
            if (process.env.REACT_APP_API_MOCK) {
                console.warn('Using mock data for useDepositsList');
                return await new Promise((resolve) =>
                    setTimeout(() => resolve(getDepositsListMockData(1)), 1000)
                );
            }

            if (
                auth.user?.access_token === undefined ||
                auth.user?.token_type === undefined
            ) {
                return undefined;
            }

            const controller = new ApiController({
                interceptors,
                securityWorker: securityWorker(
                    auth.user?.token_type,
                    auth.user?.access_token
                )
            });

            // We don't want to catch any errors here since it will broke react query "error" status
            const response = await controller.getAllDeposits();
            return response.data;
        },
        {
            ...options
        }
    );
}

export default useDepositsList;
