import {
    AGREEMENT_TESTING_LEVEL_MAPPER,
    AgreementTestingLevel
} from 'core/api/client-portal/model/agreement-testing-level';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProgressRing } from 'lib-ui';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import AgreementsMetric from './agreements-metric';
import DashboardWidget, { DashboardWidgetProps } from '../dashboard-widget';
import useAgreementsList from 'core/api/client-portal/hooks/agreements/use-agreements-list';

const VerifiedTestingLevels: Record<AgreementTestingLevel, boolean> = {
    'access-plus-verification': true,
    'access-verification': true,
    'architecture-verification': true,
    'data-extract-verification': true,
    'deposit-evaluation-service': false,
    'deposit-review': false,
    'eaas-control-verification': true,
    'entry-level-secure-verification': true,
    'entry-level-verification': true,
    'escode-secure': false,
    'independent-build-verification': true,
    'independent-secure-buildverification': true,
    'media-check': false,
    'media-file-review': false,
    none: false,
    'not-tested': false,
    'operational-maintenance-verification': true,
    'operational-maintenance-verificationaws': true,
    'replicate-plus-verification': true,
    'replicate-verification': true,
    'saas-assured-operational-verification': true,
    'saas-assured-verification': true,
    'saas-integrity-test': false,
    'sast-re-scan': false,
    'secure-user-assured-verification': true,
    'security-vulnerability-scan': false,
    'user-assured-verification': true,
    'vmit-testing': false
};

interface AgreementsWidgetProps extends Omit<DashboardWidgetProps, 'loading'> {}

function AgreementsWidget({ className }: AgreementsWidgetProps) {
    const { t } = useTranslation();

    const {
        data: agreements,
        isFetching,
        isLoading
    } = useAgreementsList({
        IncludeRestrictedAgreements: true
    });
    const loading = isFetching || isLoading;

    const totalAgreements = agreements?.agreements?.length ?? 0;
    const verifiedAgreements =
        agreements?.agreements
            ?.filter((item) => item.lastDepositLevelOfVerification)
            .map<number>((item) =>
                VerifiedTestingLevels[
                    AGREEMENT_TESTING_LEVEL_MAPPER[
                        item.lastDepositLevelOfVerification as string
                    ]
                ]
                    ? 1
                    : 0
            )
            .reduce((acc, item) => acc + item, 0) ?? 0;
    const notVerifiedAgreements = totalAgreements - verifiedAgreements;
    const progress =
        totalAgreements === 0 ? 1 : verifiedAgreements / totalAgreements;

    return (
        <DashboardWidget className={className}>
            <DashboardWidget.Header
                title={t('dashboard.agreements-widget.title')}
                loading={loading}
            />
            <DashboardWidget.Body className="grid grid-cols-3 grid-rows-5 gap-3">
                <ProgressRing
                    value={progress}
                    className="col-span-3 row-span-3"
                    loading={loading}
                />
                <AgreementsMetric
                    name={t('dashboard.agreements-widget.total-agreements')}
                    value={totalAgreements}
                    loading={loading}
                />
                <AgreementsMetric
                    name={t('dashboard.agreements-widget.verified-agreements')}
                    value={verifiedAgreements}
                    loading={loading}
                    extra={
                        <FontAwesomeIcon
                            className="brand-escode-tourmalineblack-tourmalineblack-100 text-escode-xxxs"
                            icon={faCircle}
                        />
                    }
                />
                <AgreementsMetric
                    name={t(
                        'dashboard.agreements-widget.not-verified-agreements'
                    )}
                    value={notVerifiedAgreements}
                    loading={loading}
                    extra={
                        <FontAwesomeIcon
                            className="text-brand-escode-neonblue-neonblue-100 text-escode-xxxs"
                            icon={faCircle}
                        />
                    }
                />
            </DashboardWidget.Body>
        </DashboardWidget>
    );
}

export default AgreementsWidget;
export type { AgreementsWidgetProps };
