import { Button, Wizard } from 'lib-ui';
import { CommonProps } from '@ncc-frontend/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    ForwardedRef,
    MouseEventHandler,
    ReactNode,
    forwardRef,
    useCallback,
    useMemo,
    useState
} from 'react';
import { faFileCsv, faFileExcel } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';

import { ExportType } from '../export-selection-type-step/type-option';
import FormatOption, {
    ExportFormat,
    ExportFormatOptionClickHandler
} from './format-option';

type FormatSelectEventHandler = (type: ExportFormat) => void;

interface ExportSelectionFormatStepProps extends CommonProps {
    onSelect: FormatSelectEventHandler;
    type: ExportType;
}

function ExportSelectionFormatStep(
    { className, onSelect, type }: ExportSelectionFormatStepProps,
    ref: ForwardedRef<HTMLDivElement>
) {
    const { t } = useTranslation();

    const options = useMemo<
        { format: ExportFormat; label: ReactNode }[]
    >(() => {
        if (type === 'general') {
            return [
                {
                    format: 'csv',
                    label: (
                        <>
                            <FontAwesomeIcon icon={faFileCsv} />{' '}
                            {t('table.export-format-csv')}
                        </>
                    )
                },
                {
                    format: 'xlsx',
                    label: (
                        <>
                            <FontAwesomeIcon icon={faFileExcel} />{' '}
                            {t('table.export-format-xlsx')}
                        </>
                    )
                }
            ];
        } else {
            return [
                {
                    format: 'xlsx',
                    label: (
                        <>
                            <FontAwesomeIcon icon={faFileExcel} />{' '}
                            {t('table.export-format-xlsx')}
                        </>
                    )
                }
            ];
        }
    }, [t, type]);
    const [format, setFormat] = useState<ExportFormat | undefined>(
        options.length === 1 ? options[0].format : undefined
    );

    const handleChange = useCallback<ExportFormatOptionClickHandler>(
        (format) => {
            setFormat(format);
        },
        []
    );

    const handleSelection = useCallback<MouseEventHandler>(
        (event) => {
            event?.stopPropagation();
            format && onSelect(format);
        },
        [onSelect, format]
    );

    return (
        <Wizard.Step ref={ref} className={className}>
            {options.map((item) => (
                <FormatOption
                    key={item.format}
                    format={item.format}
                    onClick={handleChange}
                    active={format === item.format}
                >
                    {item.label}
                </FormatOption>
            ))}

            <Button
                onClick={handleSelection}
                disabled={!format}
                className="w-full"
                variant="primary"
            >
                {t('table.export')}
            </Button>
        </Wizard.Step>
    );
}

export default forwardRef(ExportSelectionFormatStep);
export type {
    ExportFormat,
    ExportSelectionFormatStepProps,
    FormatSelectEventHandler
};
