import { Controller, useFormContext } from 'react-hook-form';
import React from 'react';

// import { styles } from './select-theme';
import Field from './field';
import Select from 'react-select';
import Skeleton from 'lib-ui/skeleton/skeleton';
import makeAnimated from 'react-select/animated';

type FormSelectProps = {
    className?: string;
    closeMenuOnSelect?: boolean;
    defaultValue?: {
        id: string | number;
        label: string;
        value: string | number;
    }[];
    disabled?: boolean;
    id: string;
    isMulti?: boolean;
    label: string;
    loading?: boolean;
    name: string;
    optional?: boolean;
    options: { id: string | number; label: string; value: string | number }[];
    placeholder?: string;
    required?: boolean;
    suppressReq?: boolean;
    tooltip?: string;
};

const animatedComponents = makeAnimated();

export default function SelectionField({
    className,
    disabled,
    id,
    label,
    loading = false,
    name,
    optional,
    options,
    placeholder,
    required,
    suppressReq,
    tooltip,
    ...restProps
}: FormSelectProps) {
    const {
        control,
        formState: { errors }
        // watch
    } = useFormContext();
    // const field = watch(name, {});
    // const err = errors?.[name]?.value?.message || errors?.[name]?.message;

    return (
        <Field
            className={className}
            disabled={disabled}
            error={!!errors[name] ? <>{errors[name]?.message}</> : undefined}
            id={id}
            label={label}
            name={name}
            optional={optional}
            required={required}
            suppressReq={suppressReq}
            tooltip={tooltip}
        >
            {loading ? (
                <div className="h-[40px] relative cursor-wait">
                    <Skeleton className="h-full rounded-lg left-0 right-0 bg-general-grey-grey-40" />
                </div>
            ) : (
                <Controller
                    name={name}
                    control={control}
                    render={({ field }) => (
                        <Select
                            // styles={styles('light')}
                            components={animatedComponents}
                            options={options}
                            {...field}
                            id={id}
                            isClearable
                            placeholder={placeholder}
                            data-testid={`form-select-${name}`}
                            {...restProps}
                        />
                    )}
                />
            )}
        </Field>
    );
}
