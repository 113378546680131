import { UseQueryOptions, useQuery } from 'react-query';
import { isUndefined } from 'lodash';

import { ApiController } from '../../autogenerated/Api';
import { UserSetting } from '../../autogenerated/data-contracts';
import { useAuth } from 'react-oidc-context';
import interceptors from '../../../interceptors';
import securityWorker from '../../../security-worker';

import useUserSettingsMockData from './use-user-settings-mock-data';

type UserSettingsParams = {
    settingId?: string;
};

function useUserSettings<TData = unknown, TError = unknown>(
    query: UserSettingsParams,
    formatter: (setting: UserSetting[]) => TData,
    options?: Omit<
        UseQueryOptions<
            TData | undefined,
            ReactQueryError<TError>,
            TData | undefined
        >,
        'queryKey' | 'queryFn'
    >
) {
    const auth = useAuth();

    return useQuery(
        [
            'user-settings',
            {
                query,
                token: auth.user?.access_token,
                tokenType: auth.user?.token_type
            }
        ],
        async () => {
            if (isUndefined(query.settingId)) {
                console.warn('Undefined ID in useUserSettings');
                return undefined;
            }
            if (process.env.REACT_APP_API_MOCK) {
                console.warn('Using mock data for useUserSettings');
                return formatter(useUserSettingsMockData);
            }

            if (
                isUndefined(auth.user?.access_token) ||
                isUndefined(auth.user?.token_type)
            ) {
                return undefined;
            }

            const controller = new ApiController({
                interceptors,
                securityWorker: securityWorker(
                    auth.user?.token_type,
                    auth.user?.access_token
                )
            });

            // We don't want to catch any errors here since it will broke react query "error" status
            const response = await controller.getUserSettings(query);
            return formatter(response.data);
        },
        options
    );
}

export default useUserSettings;
