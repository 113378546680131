import { Placement, TailwindProps, tailwindClasses } from '@ncc-frontend/core';
import { PropsWithChildren, ReactElement } from 'react';
import DropdownPanel from 'lib-ui/dropdown/dropdown-panel';
import classNames from 'classnames';

interface TooltipPanelProps extends TailwindProps {
    placement?: Placement;
    suppressTick?: boolean;
}

function TooltipPanel({
    children,
    className,
    placement,
    suppressTick,
    tailwindStyle,
    ...restProps
}: PropsWithChildren<TooltipPanelProps>): ReactElement | null {
    const cssClasses = classNames(
        className,
        tailwindClasses(
            {
                background: 'bg-general-dark-dark-100',
                borderRadius: 'rounded-xl',
                borderWidth: '2px',
                padding: 'p-escode-m',
                position: 'relative',
                textColor: 'text-white',
                textSize: 'text-sm'
            },
            tailwindStyle
        ),
        {
            'before:bg-general-dark-dark-100 before:w-2 before:h-2 before:absolute before:-translate-x-1/2 before:-translate-y-1/2 before:rotate-45':
                !suppressTick,
            'before:left-0 before:top-1/2':
                !suppressTick && placement === 'right',
            'before:left-full before:top-1/2':
                !suppressTick && placement === 'left',
            'before:top-0 before:left-1/2':
                !suppressTick && placement === 'bottom',
            'before:top-full before:left-1/2':
                !suppressTick && placement === 'top'
        }
    );

    return (
        <DropdownPanel>
            <div {...restProps} className={cssClasses}>
                {children}
            </div>
        </DropdownPanel>
    );
}

export default TooltipPanel;
export type { TooltipPanelProps };
