import { twMerge } from 'tailwind-merge';

type AsteriskType = {
    className?: string;
};

const Asterisk = ({ className }: AsteriskType) => {
    return (
        <span
            className={twMerge('text-escrow-red-100 inline-block', className)}
            data-testid="required-asterisk"
        >
            *
        </span>
    );
};

export default Asterisk;
