import AppBody from './app-body';
import SourceControlConnections from 'ui/source-control-connections/source-control-connections';

function SourceControlConnectionsBody() {
    return (
        <AppBody>
            <SourceControlConnections />
        </AppBody>
    );
}

export default SourceControlConnectionsBody;
