import { ApiController } from '../../autogenerated/Api';
import { AxiosResponse } from 'axios';
import { MutationOptions, useMutation } from 'react-query';
import { useAuth } from 'react-oidc-context';
import interceptors from '../../../interceptors';
import securityWorker from '../../../security-worker';

type DepositConfirmUpdateType = {
    depositId: number;
};

function useDepositConfirmUpdate<TError = unknown, TContext = unknown>(
    options?: Omit<
        MutationOptions<
            AxiosResponse<void, unknown>,
            ReactQueryError<TError>,
            DepositConfirmUpdateType,
            TContext
        >,
        'mutationFn'
    >
) {
    const auth = useAuth();

    return useMutation<
        AxiosResponse,
        ReactQueryError<TError>,
        DepositConfirmUpdateType,
        TContext
    >(
        [
            {
                token: auth.user?.access_token,
                tokenType: auth.user?.token_type
            },
            'Confirm Update'
        ],
        async ({ depositId }: DepositConfirmUpdateType) => {
            if (process.env.REACT_APP_API_MOCK) {
                console.warn(
                    'Mock is enabled useDepositConfirmUpdate call ignored.'
                );
                return Promise.resolve({} as AxiosResponse<void, unknown>);
            }

            const controller = new ApiController({
                interceptors,
                securityWorker: securityWorker(
                    auth.user?.token_type,
                    auth.user?.access_token
                )
            });

            return controller.v2DepositConfirmUpdate(depositId);
        },
        {
            ...options
        }
    );
}

export default useDepositConfirmUpdate;
