import { CSSProperties, PropsWithChildren, useMemo } from 'react';

import { Tag } from 'lib-ui';

type Variant = 'kkk' | 'live' | 'provisional' | undefined;

interface AgreementStatusTagProps {
    variant: Variant;
}

function AgreementStatusTag({
    children,
    variant,
    ...restProps
}: PropsWithChildren<AgreementStatusTagProps>) {
    const style = useMemo<CSSProperties>(
        () =>
            variant === 'provisional'
                ? { background: '#C4903C33', color: '#C4903C' } // yellow
                : variant === 'live'
                ? { background: '#C43C9533', color: '#C43C95' } // pink
                : {},
        [variant]
    );

    return (
        <Tag {...restProps} style={style}>
            {children}
        </Tag>
    );
}

export default AgreementStatusTag;
export type { AgreementStatusTagProps, Variant };
