import { AxiosResponse } from 'axios';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { DepositFileItem } from 'core/api/client-portal/autogenerated/data-contracts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    PrepareFile,
    PrepareResponse
} from 'core/api/spectre-agent/autogenerated/data-contracts';
import { faCloudArrowUp } from '@fortawesome/pro-regular-svg-icons';
import { useAuth } from 'react-oidc-context';
import { useModals } from '@ncc-frontend/core';
import { useTranslation } from 'react-i18next';
import CardProcess from 'lib-ui/card-process/card-process';

import useDeleteFile from 'core/api/spectre-agent/hooks/delete-file/use-delete-file';
import useSpectreControlAuth from 'core/api/spectre-control/hooks/spectre-control-auth/use-spectre-control-auth';
import useStartFileUpload from 'core/api/spectre-agent/hooks/upload-file/use-start-file-upload';
import useUploadsFilesBlocks, {
    UploadsFilesBlocks
} from 'core/api/spectre-agent/hooks/uploads-files-block/use-uploads-files-block';
import useUploadsFilesPrepare, {
    UploadsFilesPrepareData
} from 'core/api/spectre-agent/hooks/uploads-files-prepare/use-uploads-files-prepare';
import useUploadsStart from 'core/api/spectre-agent/hooks/uploads-start/use-uploads-start';

import {
    AuthToken,
    CustomFileParams,
    DepositData,
    MergedFileItem
} from 'ui/deposit-now/upload-step/upload-types';
import { useBoolean } from 'core/utils/useBoolean';
// import FileRepeatedModal from './file-repeated-modal';
import OversizedfilesModal from './oversized-files-modal';
import classNames from 'classnames';
import useAgreementsDepositsFiles from 'core/api/client-portal-v2/hooks/deposits/use-agreements-deposits-files';
import useRefreshToken from 'core/api/spectre-control/hooks/spectre-control-refresh/use-refresh-token';
import useRequestSpectreToken from 'core/api/client-portal/hooks/request-token/use-request-spectre-token';

type VVUploaderProps = {
    agreementId: string;
    dataCentre?: string | null | undefined;
    depositId: number;
};

const initialDepositData: DepositData = {
    depositUniqueReference: '',
    error: null,
    fileId: 0,
    spectreDepositResponse: {
        authToken: {
            audiences: [],
            id: '',
            location: '',
            token: ''
        },
        id: '',
        location: ''
    },
    success: false,
    uniqueReference: '',
    uploaderBaseUrl: null
};

const VVUploader = ({
    agreementId,
    dataCentre,
    depositId
}: VVUploaderProps) => {
    const auth = useAuth();
    const { t } = useTranslation();
    const { push } = useModals();

    const {
        data: depositFilesFetched,
        isLoading: isDepositsLoading,
        refetch: refetchDeposits
    } = useAgreementsDepositsFiles(depositId);
    const {
        setFalse: setDeletingFalse,
        setTrue: setDeletingTrue,
        value: isDeleting
    } = useBoolean(false);

    const [depositFiles, setDepositFiles] = useState<MergedFileItem[]>(
        depositFilesFetched?.depositFiles || []
    );
    const [queuedFiles, setQueuedFiles] = useState<MergedFileItem[]>([]);
    const [currentFileProcessing, setCurrentFileProcessing] =
        useState<MergedFileItem | null>();
    const [depositReferences, setDepositReferences] =
        useState<DepositData>(initialDepositData);
    const [filePercentCompleted, setfilePercentCompleted] = useState<number>(0);
    const depositUniqueReference = depositReferences.depositUniqueReference;
    let uniqueReference = depositReferences.uniqueReference;
    // spectre token and spectreAuthToken
    const { data: spectreToken, isLoading: isTokenLoading } =
        useRequestSpectreToken(depositId, {
            enabled: !isDepositsLoading
        });

    const isReadyDataCentre = dataCentre === null || dataCentre === undefined;
    const isAnyFileBeingDeleted = queuedFiles.some(
        (file) => file.uploadStatus === 'deleting'
    );

    const cssClasses = classNames(
        'flex',
        'h-40',
        'min-h-full',
        'gap-2.5',
        'rounded-lg',
        'border-2',
        'border-dashed',
        'justify-center',
        'items-center',
        'font-semibold',
        {
            'bg-brand-escode-neonblue-neonblue-10': !isReadyDataCentre,
            'bg-escode-grey-10': isReadyDataCentre,
            'border-brand-escode-neonblue-neonblue-80': !isReadyDataCentre,
            'border-escode-grey-50': isReadyDataCentre,
            'text-brand-escode-neonblue-neonblue-100': !isReadyDataCentre,
            'text-general-grey-grey-90': isReadyDataCentre
        }
    );

    const errorQueueFile = () => {
        if (isDeleting) return;

        setfilePercentCompleted(0);
        setCurrentFileProcessing(null);

        const firstFileInQueue = queuedFiles[0];
        // firstFileInQueue.uploadStatus = 'error';
        const updatedQueuedFiles = queuedFiles.filter(
            (file) => file.fileName !== firstFileInQueue.fileName
        );
        setQueuedFiles(updatedQueuedFiles);
        refetchDeposits();
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const spectreControlAuthData: AuthToken | any = spectreToken?.data;

    const { data: spectreAuthToken, isLoading: isSpectreAuthLoading } =
        useSpectreControlAuth(spectreControlAuthData, {
            enabled: spectreControlAuthData !== undefined
        });

    const { data: spectreAuthTokenRefreshed, refetch: refetchTokenData } =
        useRefreshToken(spectreAuthToken, depositUniqueReference, {});

    const { cancelUpload, uploadFilesBlocksFn } = useUploadsFilesBlocks({
        onError() {
            // errorQueueFile();
        },
        onSuccess() {
            // refetch data
            // TODO: change it when the service is quicker.

            if (currentFileProcessing) {
                const currentFileIndex = queuedFiles.findIndex((file) => {
                    return (
                        file.file?.name === currentFileProcessing.fileName &&
                        file.file?.size === currentFileProcessing.fileSize
                    );
                });

                if (currentFileIndex !== -1) {
                    queuedFiles[currentFileIndex].uploadStatus = 'verifying';
                }
            }
            // if (isDeleting) setDeletingFalse();

            setTimeout(() => {
                setCurrentFileProcessing(null);
                setfilePercentCompleted(0);
                refetchDeposits();
            }, 2500);
        },
        onUploadProgress(percentCompleted) {
            setfilePercentCompleted(percentCompleted);
        }
    });

    const { mutate: startUploadMutation } = useStartFileUpload({
        onError() {
            errorQueueFile();
        },
        onSuccess(data) {
            // add depositUniqueReference and uniqueReference to the current file
            if (currentFileProcessing) {
                setCurrentFileProcessing((prev) => {
                    return {
                        ...prev!,
                        depositUniqueReference:
                            data.data.depositUniqueReference,
                        uniqueReference: data.data.uniqueReference
                    };
                });
                uniqueReference = data.data.uniqueReference;
            }

            startUploads(data);
        }
    });
    const { mutate: uploadsStartMutation } = useUploadsStart({
        onError() {
            errorQueueFile();
        },
        onSuccess(data, variables, context) {
            const uploadFilesData: PrepareFile | undefined = data?.data;
            if (uploadFilesData) {
                uploadFilesPrepare(uploadFilesData, variables);
            }
            // errorQueueFile();
        }
    });

    const { mutate: uploadsFilesPrepareMutation } = useUploadsFilesPrepare({
        onError() {
            errorQueueFile();
        },
        onSuccess(data) {
            const uploadsFilesPrepareData = data.data;
            startUploadsFilesBlocksMutationFn(uploadsFilesPrepareData);
        }
    });

    const { isLoading: isDeleteLoading, mutate: deleteFile } = useDeleteFile({
        onError() {
            errorQueueFile();
        },
        onSettled(data, error, variables) {
            if (queuedFiles.some((file) => file.uploadStatus === 'deleting')) {
                const fileToDeleteIndex = queuedFiles.findIndex(
                    (file) => file.uploadStatus === 'deleting'
                );

                const updatedQueuedFiles = [...queuedFiles];
                updatedQueuedFiles.splice(fileToDeleteIndex, 1);

                setTimeout(() => {
                    setQueuedFiles(updatedQueuedFiles);
                    setCurrentFileProcessing(null);
                    // setDeletingFalse();
                    // refetchDeposits();
                }, 1000);
                return;
            }

            if (queuedFiles.some((file) => file.uploadStatus === 'uploading')) {
                setDepositFiles((prev) => {
                    const updatedDepositFiles = [...prev];
                    const fileToDeleteIndex = updatedDepositFiles.findIndex(
                        (file) => file.uniqueReference === uniqueReference
                    );

                    updatedDepositFiles.splice(fileToDeleteIndex, 1);
                    return updatedDepositFiles;
                });
                return;
            }
            refetchDeposits();
        }
    });

    const spectreAuthTokenData = spectreAuthTokenRefreshed ?? spectreAuthToken;

    // const isReadyToProcess =
    //     !isSpectreAuthLoading &&
    //     !isTokenLoading &&
    //     spectreAuthTokenData !== undefined &&
    //     spectreAuthTokenData !== null;

    const startUploadsFilesBlocksMutationFn = (
        fileBlockInfo: PrepareResponse
    ) => {
        if (currentFileProcessing?.file && spectreAuthTokenData) {
            const file = currentFileProcessing.file;

            const config: UploadsFilesBlocks = {
                blocks: fileBlockInfo,
                depositUniqueReference,
                file,
                spectreAuthToken: spectreAuthTokenData,
                uniqueReference
            };
            uploadFilesBlocksFn(config);
        }
        return;
    };

    const uploadFilesPrepare = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (data: PrepareFile, variables: any) => {
            if (data && currentFileProcessing && spectreAuthTokenData) {
                const currentData: UploadsFilesPrepareData = {
                    depositUniqueReference: variables.depositId.toString(),
                    spectreAuthToken: spectreAuthTokenData,
                    uniqueReference: variables.uniqueReference.toString(),
                    variables: {
                        fileName: currentFileProcessing?.fileName,
                        size: currentFileProcessing?.fileSize
                    }
                };

                const currentFileIndex = queuedFiles.findIndex((file) => {
                    return (
                        file.file?.name === currentData.variables.fileName &&
                        file.file?.size === currentData.variables.size
                    );
                });
                if (currentFileIndex !== -1) {
                    queuedFiles[currentFileIndex].uploadStatus = 'uploading';
                    setQueuedFiles(queuedFiles);
                }

                uploadsFilesPrepareMutation(currentData);
            }
        },
        [
            currentFileProcessing,
            queuedFiles,
            spectreAuthTokenData,
            uploadsFilesPrepareMutation
        ]
    );

    const startFileUpload = useCallback(
        (fileToUpload: File) => {
            if (!fileToUpload || !auth || isDeleting) return;

            const mutatedObject: CustomFileParams = {
                depositId: depositId,
                fileHash: 'hash',
                fileId: fileToUpload.name,
                fileName: fileToUpload.name,
                fileSize: fileToUpload.size,
                lastModified: fileToUpload.lastModified,
                uploadedById: auth.user!.id_token!.toString()
            };

            const currentFileIndex = queuedFiles.findIndex((file) => {
                return (
                    file.file?.name === fileToUpload.name &&
                    file.file.size === fileToUpload.size
                );
            });

            if (currentFileIndex !== -1) {
                queuedFiles[currentFileIndex].uploadStatus = 'uploading';
                setQueuedFiles(queuedFiles);
            }
            startUploadMutation(mutatedObject);
        },
        [auth, depositId, isDeleting, queuedFiles, startUploadMutation]
    );

    const startUploads = useCallback(
        (data: AxiosResponse) => {
            setDepositReferences(data.data);
            const depositUniqueReferenceData = data.data.depositUniqueReference;
            const variables = {
                depositId: depositUniqueReferenceData,
                spectreAuthToken: spectreAuthTokenData,
                uniqueReference: data.data.uniqueReference
            };

            uploadsStartMutation(variables);
        },
        [spectreAuthTokenData, uploadsStartMutation]
    );
    const handleStartUploadProcess = useCallback(() => {
        // if (queuedFiles.length > 0 && !isDeleting) {

        if (isAnyFileBeingDeleted) return; // if there is a file being deleted, do not start the upload process

        if (queuedFiles.length > 0) {
            isDeleting && setDeletingFalse();
            // Check if the file is already in the depositFiles
            const isQueuedFilesInDepositFiles = queuedFiles.some((queuedFile) =>
                depositFiles.some(
                    (depositFile) =>
                        depositFile.fileName === queuedFile.fileName &&
                        depositFile.fileSize === queuedFile.fileSize
                )
            );

            // If there are repeated files in depositFiles, show FilesRepeated modal
            if (isQueuedFilesInDepositFiles) {
                // Filter queuedFiles to get files without matches in depositFiles
                const queueFilesWithoutDepositFiles = queuedFiles.filter(
                    (queuedFile) =>
                        !depositFiles.some(
                            (depositFile) =>
                                depositFile.fileName === queuedFile.fileName &&
                                depositFile.fileSize === queuedFile.fileSize
                        )
                );

                // Update queuedFiles with files not found in depositFiles
                setQueuedFiles(queueFilesWithoutDepositFiles);
                return;
            } else {
                const firstQueueStatusFile = queuedFiles.find(
                    (file) => file.uploadStatus === 'queued'
                );

                if (firstQueueStatusFile && !isDeleting) {
                    const queueFileUploading = queuedFiles.some(
                        (file) => file.uploadStatus === 'uploading'
                    );
                    if (queueFileUploading) return;

                    queuedFiles[0].uploadStatus = 'uploading';
                    setCurrentFileProcessing(queuedFiles[0]);
                    setQueuedFiles(queuedFiles);
                    const fileToProcess: MergedFileItem = firstQueueStatusFile;

                    if (fileToProcess.file) {
                        setfilePercentCompleted(0);
                        startFileUpload(fileToProcess.file);
                    }
                }
                return;
            }
        }
        return;
    }, [
        isAnyFileBeingDeleted,
        queuedFiles,
        isDeleting,
        setDeletingFalse,
        depositFiles,
        startFileUpload
    ]);

    const handleSelectedFiles = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files || event.target.files?.length === 0) {
            // nothing has been selected
            return;
        }

        const filesSelected = Array.from(event.target.files);
        const maxFileSizeInBytes = 200 * 1024 * 1024 * 1024; // 200 GB
        // const maxFileSizeInBytes = 10 * 1024 * 1024; // 10 MB

        const filteredFiles = filesSelected.filter((file) => {
            if (file.size > maxFileSizeInBytes) {
                push(OversizedfilesModal, { isOnPrem: false });
                return false; // Exclude the file from the filtered array
            }
            return true; // Include the file in the filtered array
        });

        const mutatedObjectArray: MergedFileItem[] = filteredFiles.map(
            (file: File) => ({
                completed: false,
                file: file,
                fileName: file.name,
                fileSize: file.size,
                progress: 0,
                uploadStatus: 'queued',
                verificationState: 0
            })
        );

        setQueuedFiles((prev) => [...prev, ...mutatedObjectArray]);

        // handleStartUploadProcess();
    };

    const handleDeleteFile = async (
        fileItem: DepositFileItem,
        queue?: boolean
    ) => {
        if (!fileItem || !spectreAuthToken) return;

        if (!queue) {
            // Deposit File Data
            const fileItemIndex = depositFiles.findIndex((file) => {
                return (
                    file.fileName === fileItem.fileName &&
                    file.fileSize === fileItem.fileSize
                );
            });

            if (fileItemIndex !== -1) {
                const updatedDepositFiles = [...depositFiles];
                updatedDepositFiles[fileItemIndex].uploadStatus = 'deleting';
                setDepositFiles(updatedDepositFiles);
            }

            deleteFile({
                depositUniqueReference: fileItem.depositUniqueReference,
                spectreAuthToken,
                uniqueReference: fileItem.uniqueReference
            });
        } else {
            const fileToDelete = queuedFiles.find((file) => {
                return (
                    file.fileName === fileItem.fileName &&
                    file.fileSize === fileItem.fileSize
                );
            });
            // are we deleting a file that is currently uploading?
            if (fileToDelete?.uploadStatus === 'uploading') {
                cancelUpload();
                setDeletingTrue();

                fileToDelete.uploadStatus = 'deleting';

                deleteFile({
                    depositUniqueReference,
                    spectreAuthToken,
                    uniqueReference: uniqueReference
                });
                return;
            }

            setQueuedFiles((prev) => {
                const updatedQueuedFiles = prev.filter(
                    (qFile) =>
                        qFile.fileName !== fileItem.fileName ||
                        qFile.fileSize !== fileItem.fileSize
                );
                return updatedQueuedFiles;
            });
        }
    };

    // useEffect(() => {
    //     const data: PrepareFile | undefined = uploadsStartData?.data;

    //     data && uploadFilesPrepare(data);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [uploadsStartData?.data]);

    useEffect(() => {
        // queuedFiles.length > 0 && !isDeleting && handleStartUploadProcess();
        queuedFiles.length > 0 && handleStartUploadProcess();
    }, [handleStartUploadProcess, queuedFiles]);

    useEffect(() => {
        if (depositFilesFetched && !isDepositsLoading) {
            setDepositFiles(depositFilesFetched.depositFiles || []);
        }
    }, [depositFilesFetched, isDepositsLoading]);

    // refresh token data each 10 minutes
    useEffect(() => {
        const intervalId = setInterval(() => {
            refetchTokenData();
            // }, 8000);
        }, 600000);

        return () => clearInterval(intervalId);
    }, [refetchTokenData]);
    return (
        <div className="grid grid-cols-2 gap-14">
            {/* <label className="flex h-40 min-h-full gap-2.5 rounded-lg bg-escode-grey-10 border-2 border-escode-grey-50 border-dashed justify-center items-center"> */}
            <label className={cssClasses}>
                <FontAwesomeIcon icon={faCloudArrowUp} />
                {t('file-uploader.title')}
                <input
                    type={'file'}
                    multiple
                    hidden
                    disabled={isReadyDataCentre}
                    onChange={handleSelectedFiles}
                />
            </label>
            {!!isTokenLoading || !!isSpectreAuthLoading ? (
                <div>loading...</div>
            ) : (
                <div className="space-y-2.5">
                    {depositFiles !== null &&
                        depositFiles.map((fileItem, index) => (
                            <CardProcess
                                key={fileItem?.fileId + '' + index}
                                onDeleteFile={() =>
                                    !isDeleteLoading &&
                                    handleDeleteFile(fileItem)
                                }
                                file={fileItem}
                            />
                        ))}

                    {queuedFiles !== null &&
                        queuedFiles.length > 0 &&
                        queuedFiles.map((fileItem, index) => (
                            <CardProcess
                                key={fileItem.fileName! + index}
                                onDeleteFile={() =>
                                    handleDeleteFile(fileItem, true)
                                }
                                file={fileItem}
                                percentCompleted={filePercentCompleted}
                            />
                        ))}
                </div>
            )}
            VIRTUAL VAULT
        </div>
    );
};

export default VVUploader;
