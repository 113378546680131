import { AgreementDetails } from '../../autogenerated/data-contracts';
import { UseQueryOptions, useQuery } from 'react-query';
import { isUndefined } from 'lodash';

import { ApiController } from '../../autogenerated/Api';
import { useAuth } from 'react-oidc-context';
import interceptors from '../../../interceptors';
import securityWorker from '../../../security-worker';
import useAgreementsDetailsMockData from './use-agreements-details-mock-data';

function useAgreementsDetails<TError = unknown>(
    agreementId: number | undefined,
    options?: Omit<
        UseQueryOptions<AgreementDetails | undefined, ReactQueryError<TError>>,
        'queryKey' | 'queryFn'
    >
) {
    const auth = useAuth();

    return useQuery<AgreementDetails | undefined, ReactQueryError<TError>>(
        [
            'agreements',
            'details',
            {
                query: { agreementId },
                token: auth.user?.access_token,
                tokenType: auth.user?.token_type
            }
        ],
        async () => {
            if (isUndefined(agreementId)) {
                console.warn('Undefined ID in useAgreementsDetails');
                return undefined;
            }
            if (process.env.REACT_APP_API_MOCK) {
                console.warn('Using mock data for useAgreementsDetails');
                // return Promise.reject({ response: { status: 500 } });
                return useAgreementsDetailsMockData;
            }

            const controller = new ApiController({
                interceptors,
                securityWorker: securityWorker(
                    auth.user?.token_type,
                    auth.user?.access_token
                )
            });

            const response = await controller.getAgreement(agreementId);
            return response.data;
        },
        options
    );
}

export default useAgreementsDetails;
