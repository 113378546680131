import { ApiController } from '../../autogenerated/Api';
import { AxiosResponse } from 'axios';
import { MutationOptions, useMutation } from 'react-query';

import { useAuth } from 'react-oidc-context';
import interceptors from '../../../interceptors';
import securityWorker from '../../../security-worker';

type AgreementsInitiateReleaseParams = {
    data: {
        agreementIds: number[];
        deliveryMethod?: 'Download' | 'Post';
        evidenceFile?: FileList;
        invoiceAccountAddress?: string;
        invoiceAccountName?: string;
        releaseContactId?: number;
        statutoryDeclaration?: FileList;
    };
};

function useAgreementsInitiateRelease<TError = unknown, TContext = unknown>(
    options?: Omit<
        MutationOptions<
            AxiosResponse<void, unknown>,
            ReactQueryError<TError>,
            AgreementsInitiateReleaseParams,
            TContext
        >,
        'mutationFn'
    >
) {
    const auth = useAuth();

    return useMutation(
        [{ token: auth.user?.access_token, tokenType: auth.user?.token_type }],
        async ({ data }: AgreementsInitiateReleaseParams) => {
            if (process.env.REACT_APP_API_MOCK) {
                console.warn(
                    'Mock is enabled useAgreementsInitiateRelease call ignored.',
                    { data }
                );
                return Promise.resolve({} as AxiosResponse<void, unknown>);
            }

            const controller = new ApiController({
                interceptors,
                securityWorker: securityWorker(
                    auth.user?.token_type,
                    auth.user?.access_token
                )
            });

            return controller.v1ClientAgreementsInitiateReleaseCreate({
                AgreementIds: data.agreementIds,
                // BACKEND: swagger doc
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                DeliveryMethod: data.deliveryMethod as any,
                EvidenceFile: data.evidenceFile?.item(0) ?? undefined,
                InvoiceAccountAddress: data.invoiceAccountAddress,
                InvoiceAccountName: data.invoiceAccountName,
                ReleaseContactId: data.releaseContactId,
                StatutoryDeclaration:
                    data.statutoryDeclaration?.item(0) ?? undefined
            });
        },
        options
    );
}

export default useAgreementsInitiateRelease;
export type { AgreementsInitiateReleaseParams };
