import { FrontendAuditRecordType } from 'core/api/client-portal/model/audit-record-type';
import { Tag } from 'lib-ui';

interface RecordTypeTagProps {
    recordType: FrontendAuditRecordType | undefined;
}

function RecordTypeTag({ recordType }: RecordTypeTagProps) {
    return (
        <Tag
            tailwindStyle={{
                background: 'bg-escode-green-20',
                textColor: 'text-escode-green',
                textWeight: 'font-medium'
            }}
        >
            {recordType}
        </Tag>
    );
}

export default RecordTypeTag;
