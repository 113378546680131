import {
    DeleteSavedFilterEventHandler,
    FilterBackEventHandler,
    FilterData
} from '../../data-widget-types';
import {
    ForwardedRef,
    forwardRef,
    useCallback,
    useEffect,
    useMemo,
    useState
} from 'react';
import { useDropdown } from '@ncc-frontend/core';
import { useTranslation } from 'react-i18next';

import Button from '../../../../button/button';
import FilterStep from '../filter-step/filter-step';
import SavedFilter, { SavedFilterEventHandler } from './saved-filter';
import useDataWidget from '../../use-data-widget';

type ViewDetailsEventHandler = (filters: FilterData[]) => void;

interface FilterViewSavedFiltersStepProps {
    onBack: FilterBackEventHandler;
    onDelete: DeleteSavedFilterEventHandler;
    onViewDetails: ViewDetailsEventHandler;
    savedFilters: Record<string, FilterData[]>;
}

function FilterViewSavedFiltersStep(
    {
        onBack,
        onDelete,
        onViewDetails,
        savedFilters
    }: FilterViewSavedFiltersStepProps,
    ref: ForwardedRef<HTMLDivElement>
) {
    const { t } = useTranslation();
    const { close } = useDropdown();
    const { hiddenFields, onApplyFilters } = useDataWidget();

    const [selectedFilter, setSelectedFilter] = useState<string>();

    const apply = useCallback(() => {
        if (!selectedFilter) return;

        onApplyFilters!(savedFilters[selectedFilter]);

        close();
    }, [close, onApplyFilters, savedFilters, selectedFilter]);

    const handleSelect = useCallback<SavedFilterEventHandler>((name) => {
        setSelectedFilter(name);
    }, []);

    const handleDelete = useCallback<SavedFilterEventHandler>(
        (name) => {
            onDelete(name);
        },
        [onDelete]
    );

    const handleViewDetails = useCallback<SavedFilterEventHandler>(
        (name) => {
            onViewDetails(savedFilters[name]);
        },
        [onViewDetails, savedFilters]
    );

    // Immediately call onBack if there are no saved filters.
    useEffect(() => {
        if (Object.entries(savedFilters).length === 0) onBack();
    }, [onBack, savedFilters]);

    // NOTE: this component uses item paddings with negative margins to allow for a shadow to
    // appear on an overflow hidden element.
    return (
        <FilterStep
            ref={ref}
            className='max-h-126'
            tailwindStyle={useMemo(() => ({ padding: '' }), [])}
        >
            <FilterStep.Header onBack={onBack} className="p-3 pb-0 -mb-3">
                {t('filters.saved-filters')}
            </FilterStep.Header>
            <FilterStep.Body className="flex flex-col gap-2.5 p-3">
                {Object.keys(savedFilters).map((key) => (
                    <SavedFilter
                        key={key}
                        name={key}
                        active={selectedFilter === key}
                        onSelect={handleSelect}
                        onDelete={handleDelete}
                        onViewDetails={handleViewDetails}
                        warning={savedFilters[key].some(
                            ({ colId }) => hiddenFields[colId]
                        )}
                    />
                ))}
            </FilterStep.Body>
            <FilterStep.Footer className="p-3 pt-0 -mt-3">
                <Button
                    variant="primary"
                    className="flex-1"
                    disabled={!selectedFilter}
                    onClick={apply}
                >
                    {t('filters.apply')}
                </Button>
            </FilterStep.Footer>
        </FilterStep>
    );
}

export default forwardRef(FilterViewSavedFiltersStep);
export type { FilterViewSavedFiltersStepProps, ViewDetailsEventHandler };
