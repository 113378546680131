import { MutationOptions, useMutation } from 'react-query';
import { ProblemDetails } from '../../autogenerated/data-contracts';
import { useAuth } from 'react-oidc-context';
import axios, { AxiosResponse } from 'axios';

type DeleteFileParams = {
    depositUniqueReference: string | null | undefined;
    spectreAuthToken: string;
    uniqueReference: string | undefined;
};
function useDeleteFile<TError = unknown, TContext = unknown>(
    options?: Omit<
        MutationOptions<
            AxiosResponse<void, unknown>,
            ReactQueryError<TError>,
            DeleteFileParams,
            TContext
        >,
        'mutationFn'
    >
) {
    const auth = useAuth();

    return useMutation<
        AxiosResponse,
        ReactQueryError<TError>,
        DeleteFileParams,
        TContext
    >(
        [
            {
                token: auth.user?.access_token,
                tokenType: auth.user?.token_type
            }
        ],
        async ({
            depositUniqueReference,
            spectreAuthToken,
            uniqueReference
        }) => {
            if (process.env.REACT_APP_API_MOCK) {
                console.warn('Mock is enabled useDeleteFile call ignored.', {
                    depositUniqueReference,
                    uniqueReference
                });
                return Promise.resolve({} as AxiosResponse<void, unknown>);
            }

            const baseURL = process.env.REACT_APP_SPECTRE_AGENT_ENDPOINT;

            const uploadsFilesDelete = (
                depositUniqueReference: string | null | undefined,
                uniqueReference: string | undefined,
                params = {}
            ) => {
                const axiosInstance = axios.create({
                    baseURL,
                    headers: {
                        Authorization: `Bearer ${spectreAuthToken}`,
                        'Cache-FormControl':
                            'no-cache, no-store, must-revalidate',
                        Expires: '0',
                        Pragma: 'no-cache'
                    }
                });

                return axiosInstance.delete<void | ProblemDetails>(
                    `/api/uploads/${depositUniqueReference}/${uniqueReference}`,
                    params
                );
            };

            return await uploadsFilesDelete(
                depositUniqueReference,
                uniqueReference
            );
        },
        {
            ...options
        }
    );
}

export default useDeleteFile;
