import { Calendar, CalendarInstance } from 'lib-ui';
import { CommonProps } from '@ncc-frontend/core';
import { DEPOSIT_FREQUENCY_MAPPER } from 'core/api/client-portal/model/deposit-frequency';
import { DownloadScheduleItem } from 'core/api/client-portal/autogenerated/data-contracts';
import { forwardRef } from 'react';

interface SchedulesCalendarProps extends CommonProps {
    data: DownloadScheduleItem[] | null | undefined;
}

const SchedulesCalendar = forwardRef<CalendarInstance, SchedulesCalendarProps>(
    ({ data, ...restProps }, ref) => {
        return (
            <Calendar
                {...restProps}
                ref={ref}
                data={data
                    ?.filter((item) => !!item.nextDownloadAt)
                    .map((item) => ({
                        date: new Date(item.nextDownloadAt!),
                        softwarePackageName:
                            item.softwarePackageName +
                            DEPOSIT_FREQUENCY_MAPPER[item.frequencyType ?? 0],
                        tailwindStyle:
                            DEPOSIT_FREQUENCY_MAPPER[
                                item.frequencyType ?? 0
                            ] === 'daily'
                                ? {
                                      background:
                                          'bg-brand-escode-harlequingreen-harlequingreen-40',
                                      border: 'border border-brand-escode-harlequingreen-harlequingreen-120',
                                      textColor:
                                          'text-brand-escode-tourmalineblack-tourmalineblack-100',
                                      zIndex: 'hover:z-[100]'
                                  }
                                : DEPOSIT_FREQUENCY_MAPPER[
                                      item.frequencyType ?? 0
                                  ] === 'weekly'
                                ? {
                                      background:
                                          'bg-general-lightpurple-lightpurple-10',
                                      border: 'border border-general-lightpurple-lightpurple-100',
                                      textColor:
                                          'text-general-lightpurple-lightpurple-100',
                                      zIndex: 'hover:z-[100]'
                                  }
                                : DEPOSIT_FREQUENCY_MAPPER[
                                      item.frequencyType ?? 0
                                  ] === 'monthly'
                                ? {
                                      background: 'bg-general-red-red-10',
                                      border: 'border border-general-red-red-100',
                                      textColor: 'text-general-red-red-100',
                                      zIndex: 'hover:z-[100]'
                                  }
                                : DEPOSIT_FREQUENCY_MAPPER[
                                      item.frequencyType ?? 0
                                  ] === 'annually'
                                ? {
                                      background: 'bg-general-orange-orange-10',
                                      border: 'border border-general-orange-orange-100',
                                      textColor:
                                          'text-general-orange-orange-100',
                                      zIndex: 'hover:z-[100]'
                                  }
                                : DEPOSIT_FREQUENCY_MAPPER[
                                      item.frequencyType ?? 0
                                  ] === 'quarterly'
                                ? {
                                      background: 'bg-general-blue-blue-20',
                                      border: 'border border-general-blue-blue-100',
                                      textColor: 'text-general-blue-blue-100',
                                      zIndex: 'hover:z-[100]'
                                  }
                                : undefined,
                        title: item.agreementNumber,
                        versionOrReleaseNumber: item.versionOrReleaseNumber
                    }))}
            />
        );
    }
);

export default SchedulesCalendar;
export type { SchedulesCalendarProps };
