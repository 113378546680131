import { AxiosResponse } from 'axios';
import { MutationOptions, useMutation } from 'react-query';

import { ApiController } from '../../autogenerated/Api';
import { CreateDownloadScheduleRequest } from '../../autogenerated/data-contracts';
import { useAuth } from 'react-oidc-context';
import interceptors from '../../../interceptors';
import securityWorker from '../../../security-worker';

function useSchedulesCreate<TError = unknown, TContext = unknown>(
    options?: Omit<
        MutationOptions<
            AxiosResponse<void>,
            ReactQueryError<TError>,
            CreateDownloadScheduleRequest,
            TContext
        >,
        'mutationFn'
    >
) {
    const auth = useAuth();

    return useMutation(
        [{ token: auth.user?.access_token, tokenType: auth.user?.token_type }],
        async ({
            depositId,
            frequencyType,
            items,
            nextDownloadAt
        }: CreateDownloadScheduleRequest) => {
            if (process.env.REACT_APP_API_MOCK) {
                console.warn(
                    'Mock is enabled useSchedulesCreate call ignored.',
                    depositId
                );
                return Promise.resolve({} as AxiosResponse<void>);
            }

            const controller = new ApiController({
                interceptors,
                securityWorker: securityWorker(
                    auth.user?.token_type,
                    auth.user?.access_token
                )
            });

            return controller.createDownloadSchedule({
                depositId,
                frequencyType,
                items,
                nextDownloadAt
            });
        },
        {
            ...options
            // onSettled: (data, error, variables, context) => {
            //     options?.onSettled?.(data, error, variables, context);

            //     queryClient.invalidateQueries({ queryKey: ['schedules'] });
            // }
        }
    );
}

export default useSchedulesCreate;
// export type { SchedulesDeleteParams };
