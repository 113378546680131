import { CommonProps } from '@ncc-frontend/core';
import { FilterBackEventHandler } from '../../data-widget-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MouseEventHandler, PropsWithChildren, useCallback } from 'react';
import { faLongArrowLeft } from '@fortawesome/pro-solid-svg-icons';

import classNames from 'classnames';

interface FilterStepHeaderProps extends CommonProps {
    onBack?: FilterBackEventHandler;
    suppressBackIcon?: boolean;
}

function FilterStepHeader({
    children,
    className,
    onBack,
    suppressBackIcon = false,
    ...restProps
}: PropsWithChildren<FilterStepHeaderProps>) {
    const cssClasses = classNames('flex items-center text-base', className);

    const goBack = useCallback<MouseEventHandler<SVGSVGElement>>(() => {
        onBack?.();
    }, [onBack]);

    return (
        <div className={cssClasses} {...restProps}>
            {!suppressBackIcon && (
                <FontAwesomeIcon
                    icon={faLongArrowLeft}
                    onClick={goBack}
                    className="text-brand-escode-neonblue-neonblue-100 cursor-pointer"
                    data-testid="back-icon"
                />
            )}
            <div className="flex-1 flex items-center justify-center">
                {children}
            </div>
        </div>
    );
}

export default FilterStepHeader;
export type { FilterStepHeaderProps };
