import { ApiController } from '../../autogenerated/Api';
import { AxiosResponse } from 'axios';
import { MutationOptions, useMutation } from 'react-query';
import { ValidationProblemDetails } from '../../autogenerated/data-contracts';
import { useAuth } from 'react-oidc-context';
import { useDepositPartialMockData } from './use-deposit-partial-mock-data';
import interceptors from 'core/api/interceptors';
import securityWorker from 'core/api/security-worker';

function useDepositMoreDetailsPartialUpdateV2<TError = unknown>(
    depositId: number,
    options?: Omit<
        MutationOptions<
            AxiosResponse<void, unknown>,
            ReactQueryError<TError>,
            ValidationProblemDetails
        >,
        'mutationFn'
    >
) {
    const auth = useAuth();

    return useMutation(
        ['deposit-now', 'step-4', 'update'],
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        async (data: any) => {
            if (process.env.REACT_APP_API_MOCK) {
                console.warn(
                    'Using mock data for useDepositMoreDetailsPartialUpdateV2'
                );
                return useDepositPartialMockData;
            }

            if (
                auth.user?.access_token === undefined ||
                auth.user?.token_type === undefined
            ) {
                return undefined;
            }

            const controller = new ApiController({
                interceptors,
                securityWorker: securityWorker(
                    auth.user?.token_type,
                    auth.user?.access_token
                )
            });

            const response = await controller.v2DepositMoreDetailsPartialUpdate(
                depositId,
                data,
                {}
            );
            return response.data;
        },
        { ...options }
    );
}

export default useDepositMoreDetailsPartialUpdateV2;
