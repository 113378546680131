import { CalendarData } from '../types';
import { PropsWithChildren } from 'react';
import cn from 'core/utils/cn';
import isSameDate from 'core/utils/is-same-date';

type CalendarMonthDayProps = {
    allDays: Date[];
    currentDate: Date;
    data: CalendarData[] | undefined;
};

const CalendarMonthDay = ({
    allDays,
    currentDate,
    data
}: PropsWithChildren<CalendarMonthDayProps>) => {
    const rows = [];
    const rowCount = Math.ceil(allDays.length / 7);
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

    const getClassNames = (el: CalendarData): string => {
        const style = el.tailwindStyle?.background?.toString();
        let parsedStyle = '';

        if (style) {
            const colorMap: Record<string, string> = {
                'bg-brand-escode-harlequingreen-harlequingreen-40':
                    'bg-brand-escode-harlequingreen-harlequingreen-100',
                'bg-general-blue-blue-10': 'bg-general-blue-blue-100',
                'bg-general-lightpurple-lightpurple-10':
                    'bg-general-lightpurple-lightpurple-100',
                'bg-general-orange-orange-10': 'bg-general-orange-orange-100',
                'bg-general-red-red-10': 'bg-general-red-red-100'
            };

            parsedStyle = colorMap[style] || '';
        }

        return `w-3 h-3 rounded-full ${parsedStyle} mr-1`;
    };

    for (let i = 0; i < rowCount; i++) {
        const weekDays = allDays.slice(i * 7, (i + 1) * 7);
        const row = (
            <tr key={Math.random() * 100000}>
                {weekDays.map((day, index) => (
                    <td
                        role="gridcell"
                        className={cn(
                            'h-32 border',
                            day.getDay() === 0 && 'bg-escode-grey-10/50',
                            day.getDay() === 6 && 'bg-escode-grey-10/50',
                            isSameDate(day, today) &&
                                'bg-brand-escode-neonblue-neonblue-80/20 outline outline-brand-escode-neonblue-neonblue-80'
                        )}
                        key={index + day.getDay()}
                    >
                        <div className="w-10 h-full">
                            <div
                                key={`${day} - ${index}`}
                                className={`ml-4 mt-12 ${
                                    day.getMonth() === currentDate.getMonth()
                                        ? ''
                                        : 'text-ncc-grey-70'
                                }`}
                            >
                                <p className={`relative top-0 ${day}`}>
                                    {day.getDate().toString()}
                                </p>
                                <div className="inline-flex ">
                                    {data?.map((el, index) => {
                                        return (
                                            <div key={index}>
                                                {el.date.toDateString() ===
                                                    day.toDateString() && (
                                                    <div
                                                        className={getClassNames(
                                                            el
                                                        )}
                                                    ></div>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </td>
                ))}
            </tr>
        );
        rows.push(row);
    }

    return (
        <table className="w-full h-auto">
            <tbody>{rows}</tbody>
        </table>
    );
};

export default CalendarMonthDay;
