import { ColDef, ValueGetterParams } from 'lib-ui';
import { IRowNode } from 'ag-grid-community';
import { Path, get } from 'common/path';
import { dateFormatter, getDatePattern } from '@ncc-frontend/core';
import compareDate from 'core/comparers/compare-date';
import moment from 'moment-timezone';

function getDateFilter<TData = unknown>(
    colId: Path<TData>,
    lang: string
): ColDef<TData> {
    return {
        comparator: (
            _valueA: unknown,
            _valueB: unknown,
            { data: dataA }: IRowNode<TData>,
            { data: dataB }: IRowNode<TData>,
            _isDescending: boolean
        ): number => {
            const valueA = get(dataA, colId);
            const valueB = get(dataB, colId);

            return compareDate(valueA, valueB);
        },
        filter: 'agDateColumnFilter',
        filterParams: {
            comparator: (
                filterLocalDateAtMidnight: Date,
                cellValue: string | null
            ) => {
                if (!cellValue) return -1;

                // Get applied pattern for current lang.
                const pattern = getDatePattern(lang);
                const cellDate = moment(cellValue, pattern).toDate();

                return compareDate(cellDate, filterLocalDateAtMidnight);
            },
            inRangeInclusive: true
        },
        headerTooltip: getDatePattern(lang),
        valueGetter: ({ data }: ValueGetterParams<TData>) => {
            const userTimeZone =
                Intl.DateTimeFormat().resolvedOptions().timeZone;
            const cellValue = get(data, colId);
            const formattedDate = dateFormatter(cellValue, lang);
            const utcDate = moment.utc(cellValue);
            return formattedDate
                ? utcDate.tz(userTimeZone).format('L LT')
                : null;
        }
    };
}

export default getDateFilter;
