import { AgreementViewModel } from '../autogenerated/data-contracts';
import isDefined from 'core/utils/is-defined';

type AgreementDepositStatus = 'verified' | 'standard' | 'overdue' | 'never';

function getAgreementDepositStatus(
    agreement: AgreementViewModel
): AgreementDepositStatus | undefined {
    const monthsSinceLastVerification = Number(
        agreement.monthsSinceLastVerification
    );

    const monthsSinceLastDeposit = Number(agreement.monthsSinceLastDeposit);

    // Verified in the last 12 months.
    if (
        isDefined(agreement.monthsSinceLastVerification) &&
        Number.isInteger(monthsSinceLastVerification) &&
        monthsSinceLastVerification < 12
    ) {
        return 'verified';
    }

    // Deposited in the last 12 months.
    if (
        isDefined(agreement.monthsSinceLastDeposit) &&
        Number.isInteger(monthsSinceLastDeposit) &&
        monthsSinceLastDeposit < 12
    ) {
        return 'standard';
    }

    // Has at least one deposit.
    if (
        isDefined(agreement.monthsSinceLastDeposit) &&
        Number.isInteger(monthsSinceLastDeposit)
    ) {
        return 'overdue';
    }

    return 'never';
}

export type { AgreementDepositStatus };
export { getAgreementDepositStatus };
