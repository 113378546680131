import { Button, ButtonGroup, Select, SelectProps, Tag } from 'lib-ui';
import { CalendarData } from './types';
import { CommonProps } from '@ncc-frontend/core';
import {
    forwardRef,
    useCallback,
    useContext,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState
} from 'react';
import { isUndefined, range } from 'lodash';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ScheduleContext } from 'ui/schedules/schedules-context-provider';
import {
    faChevronLeft,
    faChevronRight
} from '@fortawesome/pro-solid-svg-icons';
import { findClosestDate } from 'core/utils/find-closest-date';
import CalendarDay from './calendar-day/calendar-day';
import CalendarMonth from './calendar-month/calendar-month';
import CalendarWeek from './calendar-week/calendar-week';

type CalendarInstance = {
    reset: () => void;
};

interface CalendarProps extends CommonProps {
    data: CalendarData[] | undefined;
}
export type { CalendarInstance, CalendarProps };

const Calendar = forwardRef<CalendarInstance, CalendarProps>(
    ({ className, data, ...restProps }, ref) => {
        const { i18n, t } = useTranslation();
        const {
            isNextDayWithDeposits,
            onUpdateDepositScheduleData,
            onUpdateIsNextDayWithDeposits,
            onUpdateSchedulesTitle,
            onUpdateViewResolution,
            onUpdateWeekTitleProvider,
            viewResolutionProvider
        } = useContext(ScheduleContext);

        const [visibleDate, setVisibleDate] = useState(new Date());
        const [month, setMonth] = useState(visibleDate.getMonth());
        const [year, setYear] = useState(visibleDate.getFullYear());
        const [hasData, setHasData] = useState<boolean>(false);

        const weekStart = useMemo(() => {
            const start = new Date(
                visibleDate.getFullYear(),
                visibleDate.getMonth(),
                visibleDate.getDate()
            );
            start.setDate(start.getDate() - start.getDay() + 1);
            return start;
        }, [visibleDate]);

        // Offset by 1 to start week on Monday instead of Sunday.
        weekStart.setDate(weekStart.getDate() - weekStart.getDay() + 1);
        const weekEnd = useMemo(() => {
            const end = new Date(
                weekStart.getFullYear(),
                weekStart.getMonth(),
                weekStart.getDate() + 6
            );
            return end;
        }, [weekStart]);
        const formatDate = useCallback((date: Date): string => {
            const day = date.getDate();
            const month = date.toLocaleString('default', { month: 'long' });
            const year = date.getFullYear();
            let daySuffix = 'th';

            if (day === 1 || day === 21 || day === 31) {
                daySuffix = 'st';
            } else if (day === 2 || day === 22) {
                daySuffix = 'nd';
            } else if (day === 3 || day === 23) {
                daySuffix = 'rd';
            }

            return `${day}${daySuffix} ${month} ${year}`;
        }, []);
        const weekTitle = useCallback(() => {
            const title = `${weekStart
                .getDate()
                .toString()
                .padStart(2, '0')} - ${weekEnd
                .getDate()
                .toString()
                .padStart(2, '0')} ${weekStart.toLocaleString(i18n.language, {
                month: 'long'
            })} ${weekStart.getFullYear()}`;
            return title;
        }, [i18n.language, weekEnd, weekStart]);

        const title =
            viewResolutionProvider === 'day'
                ? formatDate(visibleDate)
                : viewResolutionProvider === 'week'
                ? weekTitle()
                : visibleDate.toLocaleString(i18n.language, { month: 'long' }) +
                  ' ' +
                  visibleDate.getFullYear();

        const monthOptions = useMemo<SelectProps['options']>(
            () =>
                new Array(12).fill(0).map((_, index) => ({
                    label: new Date(2023, index).toLocaleString(i18n.language, {
                        month: 'long'
                    }),
                    value: index.toString()
                })),
            [i18n.language]
        );

        const { current: yearOptions } = useRef<SelectProps['options']>(
            range(1990, 2051).map((value) => ({
                label: value,
                value: value.toString()
            }))
        );

        const handleMonthChange = useCallback<
            NonNullable<SelectProps['onChange']>
        >((event) => {
            const nextMonth = +event.target.value;
            setVisibleDate((prev) => {
                let next = new Date(
                    prev.getFullYear(),
                    nextMonth,
                    prev.getDate()
                );
                if (next.getMonth() !== nextMonth) {
                    // Changed to a month with less max number of days resulting on month change.
                    // need to "jump" to last day of month.
                    next = new Date(prev.getFullYear(), nextMonth + 1, 0);
                }
                return next;
            });
            setMonth(nextMonth);
        }, []);

        const handleYearChange = useCallback<
            NonNullable<SelectProps['onChange']>
        >((event) => {
            const nextYear = +event.target.value;
            // FIXME: consider leap years.
            setVisibleDate(
                (prev) => new Date(nextYear, prev.getMonth(), prev.getDate())
            );
            setYear(nextYear);
        }, []);

        const goToToday = useCallback(() => {
            const today = new Date();
            setVisibleDate(today);
            setMonth(today.getMonth());
            setYear(today.getFullYear());
        }, []);

        const goPrevious = useCallback(() => {
            if (viewResolutionProvider === 'month') {
                setVisibleDate((prev) => {
                    const newDate = new Date(
                        prev.getFullYear(),
                        prev.getMonth() - 1,
                        prev.getDate(),
                        prev.getHours(),
                        prev.getMinutes()
                    );
                    setMonth(newDate.getMonth());
                    setYear(newDate.getFullYear());
                    return newDate;
                });
                return;
            } else if (viewResolutionProvider === 'week') {
                setVisibleDate((prev) => {
                    const newDate = new Date(
                        prev.getFullYear(),
                        prev.getMonth(),
                        prev.getDate() - 7
                    );
                    setMonth(newDate.getMonth());
                    setYear(newDate.getFullYear());
                    return newDate;
                });
                return;
            }

            const currentResolution = viewResolutionProvider === 'day' ? 1 : 7;
            setVisibleDate((prev) => {
                const newDate = new Date(
                    prev.getFullYear(),
                    prev.getMonth(),
                    prev.getDate() - currentResolution
                );
                setMonth(newDate.getMonth());
                setYear(newDate.getFullYear());
                return newDate;
            });
        }, [viewResolutionProvider]);

        const goNext = useCallback(() => {
            if (viewResolutionProvider === 'month') {
                setVisibleDate((prev) => {
                    const newDate = new Date(
                        prev.getFullYear(),
                        prev.getMonth() + 1,
                        prev.getDate(),
                        prev.getHours(),
                        prev.getMinutes()
                    );
                    setMonth(newDate.getMonth());
                    setYear(newDate.getFullYear());
                    return newDate;
                });
                return;
            } else if (viewResolutionProvider === 'week') {
                setVisibleDate((prev) => {
                    const newDate = new Date(
                        prev.getFullYear(),
                        prev.getMonth(),
                        prev.getDate() + 7
                    );
                    setMonth(newDate.getMonth());
                    setYear(newDate.getFullYear());
                    return newDate;
                });
                return;
            }

            const currentResolution = viewResolutionProvider === 'day' ? 1 : 7;
            setVisibleDate((prev) => {
                const newDate = new Date(
                    prev.getFullYear(),
                    prev.getMonth(),
                    prev.getDate() + currentResolution
                );
                setMonth(newDate.getMonth());
                setYear(newDate.getFullYear());
                return newDate;
            });
        }, [viewResolutionProvider]);

        const setDayViewResolution = useCallback(() => {
            onUpdateViewResolution('day');
        }, [onUpdateViewResolution]);

        const setWeekViewResolution = useCallback(() => {
            onUpdateViewResolution('week');
        }, [onUpdateViewResolution]);

        const setMonthViewResolution = useCallback(() => {
            onUpdateViewResolution('month');
        }, [onUpdateViewResolution]);

        const reset = useCallback(() => {
            const now = new Date();
            setVisibleDate(now);
            setMonth(now.getMonth());
            setYear(now.getFullYear());
            onUpdateViewResolution('week');
        }, [onUpdateViewResolution]);

        const onUpdateHasData = (newValue: boolean): void => {
            setHasData(newValue);
        };

        useImperativeHandle(ref, () => ({ reset }));

        useEffect(() => {
            onUpdateWeekTitleProvider(weekTitle());
        }, [onUpdateWeekTitleProvider, weekTitle]);

        useEffect(() => {
            const name = formatDate(visibleDate).toString();

            onUpdateSchedulesTitle(name);
        }, [formatDate, onUpdateSchedulesTitle, visibleDate]);

        useEffect(() => {
            if (viewResolutionProvider === 'week') {
                const firstDayOfNextWeek = new Date(weekStart);
                firstDayOfNextWeek.setDate(weekStart.getDate() + 7);

                const dataWithinWeek = data?.filter((item) => {
                    const itemDate = item.date;
                    return (
                        itemDate >= weekStart && itemDate <= firstDayOfNextWeek
                    );
                });

                !isUndefined(dataWithinWeek) &&
                    onUpdateDepositScheduleData(dataWithinWeek);
            }
            if (viewResolutionProvider === 'month') {
                const currentMonth = visibleDate.getMonth() + 1;
                const currentYear = visibleDate.getFullYear();

                const dataWithinMonth = data?.filter((item) => {
                    const itemMonth = item.date.getMonth() + 1;
                    const itemYear = item.date.getFullYear();

                    return (
                        itemMonth === currentMonth && itemYear === currentYear
                    );
                });

                !isUndefined(dataWithinMonth) &&
                    onUpdateDepositScheduleData(dataWithinMonth);
            }
        }, [
            data,
            month,
            onUpdateDepositScheduleData,
            viewResolutionProvider,
            visibleDate,
            weekEnd,
            weekStart
        ]);

        // Go to next day with deposits scheduled
        useEffect(() => {
            if (isNextDayWithDeposits) {
                const closestEvent = findClosestDate(data!, visibleDate);
                if (closestEvent?.date) {
                    setVisibleDate(closestEvent?.date);
                    onUpdateIsNextDayWithDeposits(false); // we already have the data
                }
            }
        }, [
            data,
            isNextDayWithDeposits,
            onUpdateIsNextDayWithDeposits,
            visibleDate
        ]);

        return (
            <div
                {...restProps}
                className={classNames('flex flex-col h-full', className)}
            >
                <div className="flex xl:flex-row md:flex-col  gap-2 items-center content-center py-5 border-brand-escode-neonblue-neonblue-10">
                    <h3 className="flex items-end text-xl">{title}</h3>
                    {!hasData && (
                        <span className="ml-2">
                            <Tag
                                tailwindStyle={{
                                    background: 'bg-general-red-red-20',
                                    textColor: 'text-general-red-red-100',
                                    textWeight: 'font-medium'
                                }}
                            >
                                No Deposits
                            </Tag>
                        </span>
                    )}

                    <div className=" flex-1" />
                    <div className="flex flex-row items-end justify-end w-108 gap-1.5">
                        <Button variant="tertiary" onClick={goToToday}>
                            {t('today')}
                        </Button>
                        <Button
                            variant="ghost"
                            icon={<FontAwesomeIcon icon={faChevronLeft} />}
                            onClick={goPrevious}
                        />
                        <Select
                            className="w-52"
                            options={monthOptions}
                            value={month.toString()}
                            onChange={handleMonthChange}
                        />
                        <Select
                            className="w-36"
                            options={yearOptions}
                            value={year.toString()}
                            onChange={handleYearChange}
                        />

                        <Button
                            variant="ghost"
                            icon={<FontAwesomeIcon icon={faChevronRight} />}
                            onClick={goNext}
                        />
                    </div>
                    <div>
                        <ButtonGroup activeId={viewResolutionProvider}>
                            <ButtonGroup.Button
                                id="day"
                                onClick={setDayViewResolution}
                            >
                                {t('date-picker.day')}
                            </ButtonGroup.Button>
                            <ButtonGroup.Button
                                id="week"
                                onClick={setWeekViewResolution}
                            >
                                {t('date-picker.week')}
                            </ButtonGroup.Button>
                            <ButtonGroup.Button
                                id="month"
                                onClick={setMonthViewResolution}
                            >
                                {t('date-picker.month')}
                            </ButtonGroup.Button>
                        </ButtonGroup>
                    </div>
                </div>

                <div className="h-[calc(100%-theme(space.10))] overflow-auto">
                    {viewResolutionProvider === 'day' ? (
                        <CalendarDay
                            data={data}
                            day={visibleDate}
                            onUpdateHasData={onUpdateHasData}
                        />
                    ) : viewResolutionProvider === 'week' ? (
                        <CalendarWeek
                            data={data}
                            weekStart={weekStart}
                            onUpdateHasData={onUpdateHasData}
                        />
                    ) : (
                        <CalendarMonth
                            data={data}
                            currentDate={weekStart}
                            onUpdateHasData={onUpdateHasData}
                        />
                    )}
                </div>
            </div>
        );
    }
);

export default Calendar;
export type CalendarViewResolution = 'day' | 'week' | 'month';
