import {
    CSSProperties,
    ForwardedRef,
    PropsWithChildren,
    forwardRef,
    useRef
} from 'react';
import { TailwindStyle } from '@ncc-frontend/core';
import { Widget, WidgetBodyProps } from 'lib-ui';
import { merge } from 'lodash';

interface DashboardWidgetBodyProps extends WidgetBodyProps {}

function DashboardWidgetBody(
    {
        children,
        style,
        ...restProps
    }: PropsWithChildren<DashboardWidgetBodyProps>,
    ref: ForwardedRef<HTMLDivElement>
) {
    const { current: tailwindStyle } = useRef<TailwindStyle>({});

    const mergedStyle = merge<CSSProperties, CSSProperties | undefined>(
        {
            maxHeight:
                'calc(100% - var(--escode-dashboard-widget-height) - var(--escode-dashboard-widget-gap))'
        },
        style
    );

    return (
        <Widget.Body
            {...restProps}
            ref={ref}
            tailwindStyle={tailwindStyle}
            style={mergedStyle}
        >
            {children}
        </Widget.Body>
    );
}

export default forwardRef(DashboardWidgetBody);
export type { DashboardWidgetBodyProps };
