import { CSSTransition } from 'react-transition-group';
import {
    DeleteSavedFilterEventHandler,
    FilterBackEventHandler,
    FilterConfirmEventHandler,
    FilterData,
    FilterFieldData,
    FilterStep
} from '../data-widget-types';
import { RefObject, createRef, useCallback, useState } from 'react';
import usePropState from 'common/use-prop-state';

import DropdownPanel from '../../../dropdown/dropdown-panel';
import FilterClearStep from './filter-clear-step/filter-clear-step';
import FilterDateStep from './filter-date-step/filter-date-step';
import FilterDeleteStep from './filter-delete-step/filter-delete-step';
import FilterOverviewStep, {
    FilterOverviewStepProps
} from './filter-overview-step/filter-overview-step';
import FilterSaveStep from './filter-save-step/filter-save-step';
import FilterTextStep from './filter-text-step/filter-text-step';
import FilterViewSavedFiltersStep, {
    ViewDetailsEventHandler
} from './filter-view-saved-filters-step/filter-view-saved-filters-step';
import Wizard from '../../../wizard/wizard';
import useDataWidget from '../use-data-widget';
import useUserSettings from 'core/api/client-portal/hooks/user-settings/use-user-settings';

interface FilterWizardProps {}

function FilterWizard({}: FilterWizardProps) {
    const { filters: activeFilters, id } = useDataWidget();

    const [filters, setFilters] = usePropState<FilterData[]>(activeFilters, []);
    const [
        { field, filterName, node, readonly, step, stepFilters },
        setActiveStep
    ] = useState<{
        field?: FilterFieldData;
        filterName?: string;
        node: RefObject<HTMLDivElement>;
        path: FilterStep[];
        readonly?: boolean;
        step: FilterStep;
        stepFilters?: FilterData[];
    }>(() => ({
        node: createRef<HTMLDivElement>(),
        path: [],
        step: 'overview'
    }));

    const savedFiltersSettingId = id ? `table-filters-${id}` : undefined;
    const { data: savedFilters, isLoading: isLoadingSavedFilters } =
        useUserSettings(
            {
                settingId: savedFiltersSettingId
            },
            (data) => {
                if (!data[0]?.settingJson) return {};
                return JSON.parse(data[0].settingJson) as Record<
                    string,
                    FilterData[]
                >;
            }
        );

    const handleFieldSelected = useCallback<
        FilterOverviewStepProps['onFieldSelected']
    >((field) => {
        setActiveStep({
            field,
            node: createRef<HTMLDivElement>(),
            path: [],
            step: field.filterType
        });
    }, []);

    const handleFilterConfirm = useCallback<FilterConfirmEventHandler>(
        ({ colId, model }) => {
            setFilters((prev) => {
                const filter = prev.find((item) => item.colId === colId);

                const validModel =
                    (!!model.filter && model.filter.length) || !!model.dateFrom;

                if (!filter && validModel) {
                    // Add new filter
                    return [...prev, { colId, model }];
                } else if (!!filter) {
                    if (validModel) {
                        // Update filter values
                        filter.model = model;
                        return [...prev];
                    } else {
                        // Remove filter. To remove a filter in AG Grid set model has to
                        // be called with null
                        filter.model = null;
                        return [...prev];
                    }
                } else {
                    return prev;
                }
            });

            setActiveStep({
                node: createRef<HTMLDivElement>(),
                path: [],
                step: 'overview'
            });
        },
        [setFilters]
    );

    const handleSaveFilters = useCallback(() => {
        setActiveStep({
            node: createRef<HTMLDivElement>(),
            path: [],
            step: 'save-filter'
        });
    }, []);

    const handleViewSavedFilters = useCallback(() => {
        setActiveStep({
            node: createRef<HTMLDivElement>(),
            path: [],
            step: 'view-saved-filters'
        });
    }, []);

    const handleViewFilterDetails = useCallback<ViewDetailsEventHandler>(
        (filters) => {
            setActiveStep({
                node: createRef<HTMLDivElement>(),
                path: ['view-saved-filters'],
                readonly: true,
                step: 'save-filter',
                stepFilters: filters
            });
        },
        []
    );

    const handleDeleteSavedFilter = useCallback<DeleteSavedFilterEventHandler>(
        (name) => {
            setActiveStep({
                filterName: name,
                node: createRef<HTMLDivElement>(),
                path: ['view-saved-filters'],
                step: 'delete-saved-filter'
            });
        },
        []
    );

    const handleBack = useCallback<FilterBackEventHandler>(() => {
        setActiveStep((prev) => ({
            node: createRef<HTMLDivElement>(),
            path: prev.path.slice(1),
            step: prev.path[0] ?? 'overview'
        }));
    }, []);

    const handleClearFilters = useCallback(() => {
        setActiveStep((prev) => ({
            node: createRef<HTMLDivElement>(),
            path: [],
            step: 'clear-filter'
        }));
    }, []);

    return (
        <DropdownPanel>
            <Wizard className="h-fit w-104 text-general-grey-grey-90">
                <CSSTransition
                    id={step}
                    key={step}
                    nodeRef={node}
                    timeout={300}
                    classNames="wizard-step"
                >
                    {step === 'overview' ? (
                        <FilterOverviewStep
                            ref={node}
                            filters={stepFilters ?? filters}
                            savedFilters={savedFilters}
                            onFieldSelected={handleFieldSelected}
                            onClearFilters={handleClearFilters}
                            onSaveFilters={handleSaveFilters}
                            onViewSavedFilters={handleViewSavedFilters}
                            isLoadingSavedFilters={isLoadingSavedFilters}
                        />
                    ) : step === 'text' && !!field ? (
                        <FilterTextStep
                            ref={node}
                            filter={filters.find(
                                (item) => item.colId === field.colId
                            )}
                            data={field}
                            onConfirm={handleFilterConfirm}
                            onBack={handleBack}
                        />
                    ) : step === 'date' && !!field ? (
                        <FilterDateStep
                            ref={node}
                            filter={filters.find(
                                (item) => item.colId === field.colId
                            )}
                            data={field}
                            onConfirm={handleFilterConfirm}
                            onBack={handleBack}
                        />
                    ) : step === 'save-filter' &&
                      !!savedFilters &&
                      !!savedFiltersSettingId ? (
                        <FilterSaveStep
                            ref={node}
                            filters={stepFilters ?? filters}
                            onBack={handleBack}
                            readonly={readonly}
                            savedFilters={savedFilters}
                            savedFiltersSettingId={savedFiltersSettingId}
                        />
                    ) : step === 'view-saved-filters' && !!savedFilters ? (
                        <FilterViewSavedFiltersStep
                            ref={node}
                            onBack={handleBack}
                            onViewDetails={handleViewFilterDetails}
                            onDelete={handleDeleteSavedFilter}
                            savedFilters={savedFilters}
                        />
                    ) : step === 'clear-filter' ? (
                        <FilterClearStep ref={node} onBack={handleBack} />
                    ) : step === 'delete-saved-filter' &&
                      !!savedFilters &&
                      !!savedFiltersSettingId ? (
                        <FilterDeleteStep
                            ref={node}
                            name={filterName}
                            onBack={handleBack}
                            savedFilters={savedFilters}
                            savedFiltersSettingId={savedFiltersSettingId}
                        />
                    ) : null}
                </CSSTransition>
            </Wizard>
        </DropdownPanel>
    );
}

export default FilterWizard;
