import { GetContactAuditResponseViewModel } from '../../autogenerated/data-contracts';

const useContactsAuditMockData: GetContactAuditResponseViewModel = {
    auditRecords: [
        {
            changedFields: [
                {
                    fieldName: 'fieldName1',
                    newValue: 'newValue1',
                    oldValue: 'oldValue1'
                }
            ],
            description: 'Change Enable for Disabled.',
            eventTime: '2023-03-21T17:07:36.455Z',
            recordType: 0,
            user: 'Diana Bal'
        },
        {
            changedFields: [
                {
                    fieldName: 'fieldName2',
                    newValue: 'newValue2',
                    oldValue: 'oldValue2'
                }
            ],
            description: 'Updated File.zip for John Fisk.',
            eventTime: '2023-03-21T17:08:36.455Z',
            recordType: 0,
            user: 'Samantha Philomena'
        },
        {
            changedFields: [
                {
                    fieldName: 'fieldName3',
                    newValue: 'newValue3',
                    oldValue: 'oldValue3'
                }
            ],
            description: 'Download Document.pdf for NCC Group.',
            eventTime: '2023-03-21T17:09:36.455Z',
            recordType: 0,
            user: 'Priapos Gavis'
        },
        {
            changedFields: [
                {
                    fieldName: 'fieldName3',
                    newValue: 'newValue3',
                    oldValue: 'oldValue3'
                }
            ],
            description: 'Submitted Deposit for Agreement 46832.',
            eventTime: '2023-03-21T17:10:36.455Z',
            recordType: 0,
            user: 'Terrence Hilda'
        },
        {
            changedFields: [
                {
                    fieldName: 'fieldName3',
                    newValue: 'newValue3',
                    oldValue: 'oldValue3'
                }
            ],
            description: 'Completed Novation for Agreement 37423.',
            eventTime: '2023-03-21T17:11:36.455Z',
            recordType: 0,
            user: 'Keysha Carrol'
        },
        {
            changedFields: [
                {
                    fieldName: 'fieldName3',
                    newValue: 'newValue3',
                    oldValue: 'oldValue3'
                }
            ],
            description: 'Download Document.pdf for Agreement 2743.',
            eventTime: '2023-03-21T17:12:36.455Z',
            recordType: 0,
            user: 'Agda Beata'
        },
        {
            changedFields: [
                {
                    fieldName: 'fieldName3',
                    newValue: 'newValue3',
                    oldValue: 'oldValue3'
                }
            ],
            description: 'Download Document.pdf for Agreement 51763.',
            eventTime: '2023-03-21T17:13:36.455Z',
            recordType: 0,
            user: 'Andy Smith'
        },
        {
            changedFields: [
                {
                    fieldName: 'fieldName3',
                    newValue: 'newValue3',
                    oldValue: 'oldValue3'
                }
            ],
            description: 'Updated File.zip for Hannah Bridges.',
            eventTime: '2023-03-21T17:14:36.455Z',
            recordType: 0,
            user: 'James Cavolli'
        },
        {
            changedFields: [
                {
                    fieldName: 'fieldName3',
                    newValue: 'newValue3',
                    oldValue: 'oldValue3'
                }
            ],
            description: 'Completed Novation for Agreement 95832.',
            eventTime: '2023-03-21T17:15:50.455Z',
            recordType: 0,
            user: 'Tim Bernards'
        },
        {
            changedFields: [
                {
                    fieldName: 'fieldName3',
                    newValue: 'newValue3',
                    oldValue: 'oldValue3'
                }
            ],
            description: 'Download Document.pdf for Agreement 41781.',
            eventTime: '2023-03-21T17:16:03.455Z',
            recordType: 0,
            user: 'Gavin Trump'
        },
        {
            changedFields: [
                {
                    fieldName: 'fieldName3',
                    newValue: 'newValue3',
                    oldValue: 'oldValue3'
                }
            ],
            description: 'Updated File.zip for Ringo Star.',
            eventTime: '2023-03-21T17:16:03.455Z',
            recordType: 0,
            user: 'Michael Schofield'
        },
        {
            changedFields: [
                {
                    fieldName: 'fieldName3',
                    newValue: 'newValue3',
                    oldValue: 'oldValue3'
                }
            ],
            description: 'Change Disabled for Enable.',
            eventTime: '2023-03-21T17:16:03.455Z',
            recordType: 0,
            user: 'Lenin White'
        },
        {
            changedFields: [
                {
                    fieldName: 'fieldName3',
                    newValue: 'newValue3',
                    oldValue: 'oldValue3'
                }
            ],
            description: 'Download Document.pdf for NCC Group.',
            eventTime: '2023-03-21T17:16:03.455Z',
            recordType: 0,
            user: 'Meyer Goldman'
        },
        {
            changedFields: [
                {
                    fieldName: 'fieldName3',
                    newValue: 'newValue3',
                    oldValue: 'oldValue3'
                }
            ],
            description: 'Submitted Deposit for Agreement 46832.',
            eventTime: '2023-03-21T17:16:03.455Z',
            recordType: 0,
            user: 'Pam Beesly'
        },
        {
            changedFields: [
                {
                    fieldName: 'fieldName3',
                    newValue: 'newValue3',
                    oldValue: 'oldValue3'
                }
            ],
            description: 'Submitted Deposit for Agreement 46832.',
            eventTime: '2023-03-21T17:16:03.455Z',
            recordType: 0,
            user: 'Oscar Piernas'
        },
        {
            changedFields: [
                {
                    fieldName: 'fieldName3',
                    newValue: 'newValue3',
                    oldValue: 'oldValue3'
                }
            ],
            description: 'Updated File.zip for Tina Pond.',
            eventTime: '2023-03-21T17:16:03.455Z',
            recordType: 0,
            user: 'Douglas Stone'
        },
        {
            changedFields: [
                {
                    fieldName: 'fieldName3',
                    newValue: 'newValue3',
                    oldValue: 'oldValue3'
                }
            ],
            description: 'Completed Novation for Agreement 95832.',
            eventTime: '2023-03-21T17:16:03.455Z',
            recordType: 0,
            user: 'Jim Cricket'
        },
        {
            changedFields: [
                {
                    fieldName: 'fieldName3',
                    newValue: 'newValue3',
                    oldValue: 'oldValue3'
                }
            ],
            description: 'Download Document.pdf for NCC Group.',
            eventTime: '2023-03-21T17:16:03.455Z',
            recordType: 0,
            user: 'Kevin Bacon'
        },
        {
            changedFields: [
                {
                    fieldName: 'fieldName3',
                    newValue: 'newValue3',
                    oldValue: 'oldValue3'
                }
            ],
            description: 'Download Document.pdf for NCC Group.',
            eventTime: '2023-03-21T17:16:03.455Z',
            recordType: 0,
            user: 'Phyllis Humphries'
        },
        {
            changedFields: [
                {
                    fieldName: 'fieldName3',
                    newValue: 'newValue3',
                    oldValue: 'oldValue3'
                }
            ],
            description: 'Download Document.pdf for NCC Group.',
            eventTime: '2023-03-21T17:16:03.455Z',
            recordType: 0,
            user: 'Toby Carver'
        },
        {
            changedFields: [
                {
                    fieldName: 'fieldName3',
                    newValue: 'newValue3',
                    oldValue: 'oldValue3'
                }
            ],
            description: 'Download Document.pdf for NCC Group.',
            eventTime: '2023-03-21T17:16:03.455Z',
            recordType: 0,
            user: 'Stanley Legobrick'
        },
        {
            changedFields: [
                {
                    fieldName: 'fieldName3',
                    newValue: 'newValue3',
                    oldValue: 'oldValue3'
                }
            ],
            description: 'Download Document.pdf for NCC Group.',
            eventTime: '2023-03-21T17:16:03.455Z',
            recordType: 0,
            user: 'Jan Scott'
        },
        {
            changedFields: [
                {
                    fieldName: 'fieldName3',
                    newValue: 'newValue3',
                    oldValue: 'oldValue3'
                }
            ],
            description: 'Download Document.pdf for NCC Group.',
            eventTime: '2023-03-21T17:16:03.455Z',
            recordType: 0,
            user: 'Michael Levinson-Gould'
        }
    ],
    errors: {
        additionalProp1: ['string'],
        additionalProp2: ['string'],
        additionalProp3: ['string']
    },
    success: true
};

export default useContactsAuditMockData;
