import { Button } from 'lib-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import { faArrowRightToLine } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';

import DashboardWidget, { DashboardWidgetProps } from '../dashboard-widget';
import ProviderListItem from 'ui/source-control-connections/source-control-connections-grid/provider-list-item/provider-list-item';
import useMaxItems from 'common/use-max-items';
import useProvidersList from 'core/api/client-portal/hooks/providers/use-providers-list';

interface SourceControlConnectionsWidgetProps
    extends Omit<DashboardWidgetProps, 'loading'> {}

function SourceControlConnectionsWidget({
    ...restProps
}: SourceControlConnectionsWidgetProps) {
    const { t } = useTranslation();

    const { rows, setContainerRef, setItemRef } = useMaxItems({
        rowGap: 10
    });

    const { data: providers, isFetching, isLoading } = useProvidersList();
    const loading = isLoading || isFetching;

    return (
        <DashboardWidget {...restProps}>
            <DashboardWidget.Header
                title={t('dashboard.source-control-connections-widget.title', {
                    count: Math.min(rows, providers?.providers.length ?? 0)
                })}
                loading={loading}
            >
                <NavLink to="/source-control" className="ml-auto">
                    <Button
                        size="s"
                        icon={<FontAwesomeIcon icon={faArrowRightToLine} />}
                        iconPosition="right"
                    >
                        {t(
                            'dashboard.source-control-connections-widget.see-all'
                        )}
                    </Button>
                </NavLink>
            </DashboardWidget.Header>
            <DashboardWidget.Body
                ref={setContainerRef}
                className="flex flex-col gap-2.5"
            >
                {!!loading &&
                    [...new Array(rows)].map((_, index) => (
                        <ProviderListItem
                            key={index}
                            ref={index === 0 ? setItemRef : undefined}
                            loading
                        />
                    ))}
                {!loading &&
                    providers?.providers
                        .slice(0, rows)
                        .map((item, index) => (
                            <ProviderListItem
                                key={item.id}
                                ref={index === 0 ? setItemRef : undefined}
                                authenticationType={item.authenticationType}
                                connectionName={item.connectionName}
                                password={item.password}
                                privateKey={item.privateKey}
                                providerType={item.providerType}
                                repositoryAddress={item.repositoryAddress}
                                userName={item.userName}
                                id={item.id}
                            />
                        ))}
            </DashboardWidget.Body>
        </DashboardWidget>
    );
}

export default SourceControlConnectionsWidget;
export type { SourceControlConnectionsWidgetProps };
