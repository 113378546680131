import * as yup from 'yup';

const schema = yup.object({
    agreementIds: yup
        .array()
        .min(1, 'You must select at least one agreement')

        .of(yup.string()),
    comments: yup.string().max(600, 'Comments must be under 600 characters'),
    customTemplate: yup.string().required(),
    effectiveDate: yup.string().required('This is required'),
    entityConfirmation: yup
        .boolean()
        .isTrue('Please confirm to proceed')
        .typeError('Please confirm to proceed'),
    newPartyCompanyAddressLine1: yup
        .string()
        .required('This field is required')
        .min(1, 'This field is required')
        .max(150, 'New Party Company Address must be under 150 characters'),
    newPartyCompanyAddressLine2: yup
        .string()
        .max(150, 'Must be under 150 characters'),
    newPartyCompanyAddressLine3: yup
        .string()
        .max(150, 'Must be under 150 characters'),
    newPartyCompanyAddressLinePostCode: yup
        .string()
        .required('This is required')
        .max(150, 'Must be under 150 characters'),
    newPartyCompanyContactName: yup
        .string()
        .required('This is required')
        .max(150, 'Must be under 150 characters'),
    newPartyCompanyInvoiceAddressLine1: yup
        .string()
        .when(['sameAddress'], (sameAddress, schema) =>
            !sameAddress
                ? schema
                      .required('New Party Company Invoice Address is required')
                      .max(150, 'Must be under 150 characters')
                : schema.max(0)
        ),
    newPartyCompanyInvoiceAddressLine2: yup
        .string()
        .max(150, 'Must be under 150 characters'),
    newPartyCompanyInvoiceAddressLine3: yup
        .string()
        .max(150, 'Must be under 150 characters'),
    newPartyCompanyInvoiceAddressLinePostCode: yup
        .string()
        .when(['sameAddress'], (sameAddress, schema) =>
            !sameAddress
                ? schema.required().max(150, 'Must be under 150 characters')
                : schema.max(0)
        ),
    newPartyCompanyName: yup
        .string()
        .required('This is required')
        .max(150, 'Must be under 150 characters'),
    newPartyCompanyRegistrationNumber: yup
        .string()
        .required('This is required')
        .max(150, 'Must be under 150 characters'),
    newPartyEmailAddress: yup
        .string()
        .required('This is required')
        .max(150, 'Must be under 150 characters')
        .email('This must be a valid email address'),
    newPartyVatNumber: yup
        .string()
        .required('This is required')
        .max(150, 'Must be under 150 characters'),
    oldPartyCompanyName: yup
        .string()
        .required('This is required')
        .max(150, 'Must be under 150 characters'),
    poNumber: yup
        .string()
        .required('This is required')
        .max(150, 'Must be under 150 characters'),
    sameAddress: yup.boolean(),
    typeOfChange: yup
        .string()
        .required('This is required, please select an option')
});

export default schema;
