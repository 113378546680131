import { ApiController } from '../../autogenerated/Api';
import { AxiosResponse } from 'axios';
import { DepositContactDetails } from '../../autogenerated/data-contracts';
import { MutationOptions, useMutation } from 'react-query';
import { useAuth } from 'react-oidc-context';
import { useDepositPartialMockData } from './use-deposit-partial-mock-data';
import interceptors from 'core/api/interceptors';
import securityWorker from 'core/api/security-worker';

function useEditDepositContactDetails<TError = unknown, TContext = unknown>(
    depositId: number,
    options?: Omit<
        MutationOptions<
            AxiosResponse<void, unknown>,
            ReactQueryError<TError>,
            DepositContactDetails,
            TContext
        >,
        'mutationFn'
    >
) {
    const auth = useAuth();

    return useMutation<
        AxiosResponse<void, unknown>,
        ReactQueryError<TError>,
        DepositContactDetails,
        TContext
    >(
        ['deposit-now', 'step-2'],
        async (data: DepositContactDetails) => {
            if (process.env.REACT_APP_API_MOCK) {
                console.warn(
                    'Using mock data for useEditDepositContactDetails'
                );
                return useDepositPartialMockData;
            }

            if (
                auth.user?.access_token === undefined ||
                auth.user?.token_type === undefined
            ) {
                return undefined;
            }

            const controller = new ApiController({
                interceptors,
                securityWorker: securityWorker(
                    auth.user?.token_type,
                    auth.user?.access_token
                )
            });

            const response = await controller.v2DepositContactPartialUpdate(
                depositId,
                data
            );
            return response.data;
        },
        { ...options }
    );
}

export default useEditDepositContactDetails;
