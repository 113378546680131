import { ForwardedRef, PropsWithChildren, forwardRef, useMemo } from 'react';
import { TailwindProps, TailwindStyle } from '@ncc-frontend/core';
import { merge } from 'lodash';
import classNames from 'classnames';

import Menu from '../menu/menu';

import './wizard-step.scss';

interface WizardStepProps extends TailwindProps {
    className?: string;
}

function WizardStep(
    {
        children,
        className,
        tailwindStyle,
        ...restProps
    }: PropsWithChildren<WizardStepProps>,
    ref: ForwardedRef<HTMLDivElement>
) {
    const mergedTailwindStyle = useMemo(
        () =>
            merge<TailwindStyle, TailwindStyle | undefined>(
                {
                    height: 'max-h-120',
                    left: 'left-0',
                    position: 'absolute',
                    right: 'right-0',
                    top: 'top-0'
                },
                tailwindStyle
            ),
        [tailwindStyle]
    );

    const cssClasses = classNames('wizard-step', className);

    return (
        <Menu
            ref={ref}
            {...restProps}
            className={cssClasses}
            tailwindStyle={mergedTailwindStyle}
        >
            {children}
        </Menu>
    );
}

export default forwardRef(WizardStep);
export type { WizardStepProps };
