import { ApiController } from '../../autogenerated/Api';
import {
    ProblemDetails,
    ValidationProblemDetails
} from '../../autogenerated/data-contracts';
import { UseQueryOptions, useQuery } from 'react-query';
import { useAuth } from 'react-oidc-context';
import interceptors from 'core/api/interceptors';
import securityWorker from 'core/api/security-worker';
import useAgreementDetailsV2MockData from './use-agreement-details-v2-mock-data';

function useAgreementDetailsV2<TError = unknown>(
    agreementId: number,
    options?: Omit<
        UseQueryOptions<
            ValidationProblemDetails | ProblemDetails,
            ReactQueryError<TError>,
            ValidationProblemDetails | ProblemDetails
        >,
        'queryKey' | 'queryFn'
    >
) {
    const { user } = useAuth();

    return useQuery(
        ['use-agreement-details-v2'],
        async () => {
            if (process.env.REACT_APP_API_MOCK) {
                console.warn('Using mock data for useAgreementDetailsV2');
                return useAgreementDetailsV2MockData;
            }

            if (
                user?.access_token === undefined ||
                user?.token_type === undefined
            ) {
                return undefined;
            }

            const controller = new ApiController({
                interceptors,
                securityWorker: securityWorker(
                    user?.token_type,
                    user?.access_token
                )
            });

            const response = await controller.v2AgreementDetail(
                agreementId,
                {}
            );
            return response.data;
        },
        options
    );
}

export default useAgreementDetailsV2;
