import {
    AgreementViewModel,
    IncompleteDepositItem
} from 'core/api/client-portal/autogenerated/data-contracts';
import DEPOSIT_STATE_MAPPER from 'core/api/client-portal/model/deposit-state';

import HighlightAgreementListItem from './highlight-agreement-list-item';
import IncompleteDeposit from 'ui/deposits/incomplete-deposit';

interface HighlightAgreementListModalItemProps {
    agreement: AgreementViewModel;
    deposit?: IncompleteDepositItem;
}

function HighlightAgreementListModalItem({
    agreement,
    deposit
}: HighlightAgreementListModalItemProps) {
    return !!deposit ? (
        <IncompleteDeposit
            agreementId={deposit.agreementId}
            created={deposit?.created}
            depositId={deposit?.depositId}
            name={deposit?.agreementNumber}
            state={DEPOSIT_STATE_MAPPER[deposit.lastSavedState ?? 0]}
            danger
        />
    ) : (
        <HighlightAgreementListItem
            agreement={agreement}
            agreementId={agreement.agreementId}
            date={agreement.lastDepositDate}
            name={agreement.agreementNumber}
        />
    );
}

export default HighlightAgreementListModalItem;
export type { HighlightAgreementListModalItemProps };
