import { DepositFileItem } from 'core/api/client-portal/autogenerated/data-contracts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MergedFileItem } from 'ui/deposit-now/upload-step/upload-types';
import { faCloudArrowUp } from '@fortawesome/pro-solid-svg-icons';
import { isUndefined } from 'lodash';
import { useBoolean } from 'core/utils/useBoolean';
import { useModals } from '@ncc-frontend/core';
import { useTranslation } from 'react-i18next';
import CardProcess from 'lib-ui/card-process/card-process';
import FileRepeatedModal from 'lib-ui/vv-uploader/file-repeated-modal';
import OversizedfilesModal from 'lib-ui/vv-uploader/oversized-files-modal';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import useAgreementsDepositsFiles from 'core/api/client-portal-v2/hooks/deposits/use-agreements-deposits-files';
import useChunkUpload from 'core/api/uploader-api/hooks/deposit-upload/use-chunk-upload';
import useDeleteUpload from 'core/api/uploader-api/hooks/delete-upload/use-delete-upload';
import useDepositNowStore from 'core/stores/use-deposit-now-store';
import useStartFileUpload from 'core/api/spectre-agent/hooks/upload-file/use-start-file-upload';
import useUploadComplete from 'core/api/uploader-api/hooks/upload-complete/use-upload-complete';

type OnPremUploaderProps = {
    dataCentre: 'NCC:UK' | 'NCC:US';
    depositId: number;
};

type CustomEvent =
    | ChangeEvent<HTMLInputElement>
    | React.DragEvent<HTMLLabelElement>;

const OnPremUploader = ({ dataCentre, depositId }: OnPremUploaderProps) => {
    const { t } = useTranslation();
    const { push } = useModals();
    const { setIsUploadingFile } = useDepositNowStore();
    const [depositFiles, setDepositFiles] = useState<MergedFileItem[]>([]);
    const [queuedFiles, setQueuedFiles] = useState<MergedFileItem[]>([]);
    const [filePercentCompleted, setfilePercentCompleted] = useState<number>(0);
    const {
        setFalse: setVerifyingFalse,
        setTrue: setVerifyingTrue,
        value: isVerifying
    } = useBoolean(false);
    const {
        setFalse: setDeletingFalse,
        setTrue: setDeletingTrue,
        value: isDeleting
    } = useBoolean(false);

    const isUploading = queuedFiles.some(
        (el) => el.uploadStatus === 'uploading'
    );
    const {
        data: depositFilesData,
        isLoading: isDepositsLoading,
        refetch: refetchDeposits
    } = useAgreementsDepositsFiles(depositId);

    const errorQueueFile = () => {
        setfilePercentCompleted(0);

        const firstFileInQueue = queuedFiles[0];
        // firstFileInQueue.uploadStatus = 'error';
        const updatedQueuedFiles = queuedFiles.filter(
            (file) => file.fileName !== firstFileInQueue.fileName
        );
        setQueuedFiles(updatedQueuedFiles);
        refetchDeposits();
    };

    const isFileBeingVerified =
        depositFilesData?.depositFiles?.some(
            (file: MergedFileItem) => file.verificationState === 0
        ) ?? false;

    const cssClasses = classNames(
        'flex',
        'h-40',
        'min-h-full',
        'gap-2.5',
        'rounded-lg',
        'border-2',
        'border-dashed',
        'justify-center',
        'items-center',
        'font-semibold',
        'bg-brand-escode-neonblue-neonblue-10',
        'border-general-grey-grey-40',
        'text-brand-escode-neonblue-neonblue-100'
    );

    const handleDragOver = useCallback(
        (event: React.DragEvent<HTMLLabelElement>) => {
            event.preventDefault();
        },
        []
    );

    const { mutate: uploadCompleteFn } = useUploadComplete({
        onError() {
            errorQueueFile();
        },
        onSuccess(data, variables, context) {
            if (data.status === 200) {
                const fileUploading = queuedFiles.find(
                    (file) => file.uploadStatus === 'uploading'
                );
                if (fileUploading) {
                    fileUploading.uploadStatus = 'verifying';
                }

                if (isDeleting) setDeletingFalse();

                // setTimeout(() => {
                setfilePercentCompleted(0);
                refetchDeposits();
                // }, 2500);
            }
        }
    });
    const { chunkUploadFn } = useChunkUpload({
        onError() {
            errorQueueFile();
        },
        onSuccess(data, variables, context) {
            uploadCompleteFn({
                dataCentre,
                depositUuid: variables.depositUuid,
                fileHash: data.combinedHash,
                fileId: variables.uniqueReference
            });
        },
        onUploadProgress(percentCompleted) {
            setfilePercentCompleted(percentCompleted);
        }
    });
    const { mutate: deleteUploadFn } = useDeleteUpload({
        onSettled(data, error, variables) {
            // eslint-disable-next-line no-debugger
            // debugger;

            // is there any queued file with deleting status?
            if (queuedFiles.some((file) => file.uploadStatus === 'deleting')) {
                setfilePercentCompleted(0);

                const fileToDeleteIndex = queuedFiles.findIndex(
                    (file) => file.uploadStatus === 'deleting'
                );

                const updatedQueuedFiles = [...queuedFiles];
                updatedQueuedFiles.splice(fileToDeleteIndex, 1);
                setQueuedFiles(updatedQueuedFiles);

                setDeletingFalse();
                refetchDeposits();
                return;
            }

            if (queuedFiles.some((file) => file.uploadStatus === 'uploading')) {
                const { uniqueReference } = variables;

                isDeleting && setDeletingFalse();

                setDepositFiles((prev) => {
                    const updatedDepositFiles = [...prev];
                    const fileToDeleteIndex = updatedDepositFiles.findIndex(
                        (file) => file.uniqueReference === uniqueReference
                    );

                    updatedDepositFiles.splice(fileToDeleteIndex, 1);
                    return updatedDepositFiles;
                });
                return;
            }
            refetchDeposits();
        }
    });

    const { data: startFileUploadData, mutate: startFileUploadFn } =
        useStartFileUpload({
            onError() {
                errorQueueFile();
            },
            onSuccess(data) {
                const { depositUniqueReference, uniqueReference } =
                    data.data ?? '';

                const queueFilesUploadingStatus = queuedFiles.filter(
                    (file) => file.uploadStatus === 'uploading'
                );

                if (!isUndefined(queueFilesUploadingStatus[0].file)) {
                    chunkUploadFn({
                        dataCentre,
                        depositUuid: depositUniqueReference,
                        file: queueFilesUploadingStatus[0].file,
                        uniqueReference
                    });
                }
            }
        });

    const handleStartUploadProcess = useCallback(() => {
        if (queuedFiles.length > 0 && !isDeleting) {
            // Check if the file is already in the depositFiles
            const isQueuedFilesInDepositFiles = queuedFiles.some((queuedFile) =>
                depositFiles.some(
                    (depositFile) =>
                        depositFile.fileName === queuedFile.fileName &&
                        depositFile.fileSize === queuedFile.fileSize
                )
            );

            // If there are repeated files in depositFiles, show FilesRepeated modal
            if (isQueuedFilesInDepositFiles) {
                // Filter queuedFiles to get files without matches in depositFiles
                const queueFilesWithoutDepositFiles = queuedFiles.filter(
                    (queuedFile) =>
                        !depositFiles.some(
                            (depositFile) =>
                                depositFile.fileName === queuedFile.fileName &&
                                depositFile.fileSize === queuedFile.fileSize
                        )
                );

                // Update queuedFiles with files not found in depositFiles
                setQueuedFiles(queueFilesWithoutDepositFiles);
                return;
            } else {
                const firstQueueStatusFile = queuedFiles.find(
                    (file) => file.uploadStatus === 'queued'
                );

                if (firstQueueStatusFile && !isDeleting) {
                    const queueFileUploading = queuedFiles.some(
                        (file) => file.uploadStatus === 'uploading'
                    );
                    if (queueFileUploading) return;

                    queuedFiles[0].uploadStatus = 'uploading';
                    setQueuedFiles(queuedFiles);

                    startFileUploadFn({
                        depositId,
                        fileName: firstQueueStatusFile.fileName ?? '',
                        fileSize: firstQueueStatusFile.fileSize ?? 0
                    });
                }
                return;
            }
        }
        return;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queuedFiles, depositFiles, startFileUploadFn, depositId]);

    const handleSelectedFiles = (event: CustomEvent) => {
        event.preventDefault();
        localStorage.setItem('isAnyFileBeingUploaded', 'true');
        setIsUploadingFile(true);

        const isChangeEvent =
            'target' in event && event.target instanceof HTMLInputElement;

        const filesSelected = isChangeEvent
            ? Array.from((event.target as HTMLInputElement).files || [])
            : Array.from(
                  (event as React.DragEvent<HTMLLabelElement>).dataTransfer
                      .files || []
              );

        const maxFileSizeInBytes = 46 * 1024 * 1024 * 1024; // 46 GB

        const filteredFiles = filesSelected.filter((file) => {
            if (file.size > maxFileSizeInBytes) {
                push(OversizedfilesModal, { isOnPrem: true });
                return false; // Exclude the file from the filtered array
            }
            if (queuedFiles.some((qFile) => qFile.fileName === file.name)) {
                push(FileRepeatedModal, {});
                return false; // Exclude the file from the filtered array
            }
            return true; // Include the file in the filtered array
        });

        const mutatedObjectArray: MergedFileItem[] = filteredFiles.map(
            (file: File) => ({
                completed: false,
                file: file,
                fileName: file.name,
                fileSize: file.size,
                progress: 0,
                uploadStatus: 'queued',
                verificationState: 0
            })
        );
        // digestFile(filesSelected[0]);
        setQueuedFiles((prev) => [...prev, ...mutatedObjectArray]);

        handleStartUploadProcess();
    };

    function handleDeleteFile(fileItem: DepositFileItem, queue = false) {
        if (!fileItem) return;

        if (!queue) {
            // Deposit Files Data
            const uniqueReference = fileItem.uniqueReference ?? '';
            const depositUniqueReference =
                fileItem.depositUniqueReference ?? '';

            const fileToDelete = depositFiles.find((file) => {
                return (
                    file.uniqueReference === uniqueReference &&
                    file.depositUniqueReference === depositUniqueReference
                );
            });

            if (fileToDelete) {
                fileToDelete.uploadStatus = 'deleting';
            }

            deleteUploadFn({
                dataCentre,
                depositUniqueReference,
                uniqueReference
            });
        } else {
            // Queued Files Data (Files that are not yet uploaded)
            const fileToDelete = queuedFiles.find((file) => {
                return (
                    file.fileName === fileItem.fileName &&
                    file.fileSize === fileItem.fileSize
                );
            });

            // are we deleting a file that is currently uploading?
            if (fileToDelete?.uploadStatus === 'uploading') {
                setDeletingTrue();

                fileToDelete.uploadStatus = 'deleting';

                const uniqueReference =
                    (startFileUploadData?.data as { uniqueReference?: string })
                        ?.uniqueReference ?? '';
                const depositUniqueReference =
                    (
                        startFileUploadData?.data as {
                            depositUniqueReference?: string;
                        }
                    )?.depositUniqueReference ?? '';

                deleteUploadFn({
                    dataCentre,
                    depositUniqueReference,
                    uniqueReference
                });
                return;
            }

            setQueuedFiles((prev) => {
                const updatedQueuedFiles = prev.filter(
                    (qFile) =>
                        qFile.fileName !== fileItem.fileName ||
                        qFile.fileSize !== fileItem.fileSize
                );
                return updatedQueuedFiles;
            });
        }
    }

    useEffect(() => {
        if (!isDepositsLoading && !isUploading) {
            if (
                (queuedFiles.length === 1 || queuedFiles.length === 2) &&
                isFileBeingVerified
            ) {
                // by this point, it's still 1 file in the queue
                setVerifyingTrue();
            }

            setDepositFiles(depositFilesData?.depositFiles ?? []);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDepositsLoading, depositFilesData]);

    useEffect(() => {
        queuedFiles.length > 0 && !isDeleting && handleStartUploadProcess();
    }, [handleStartUploadProcess, isDeleting, queuedFiles]);

    // When large files are uploaded, they take a long time to be verified by the server.
    useEffect(() => {
        let intervalId: NodeJS.Timeout | null = null;

        if (!isFileBeingVerified && queuedFiles.length === 0) {
            localStorage.setItem('isAnyFileBeingUploaded', 'false');
            setIsUploadingFile(false);
            setVerifyingFalse();
        }

        if (isVerifying) {
            intervalId = setInterval(() => {
                refetchDeposits();
            }, 8000);
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [
        isFileBeingVerified,
        isVerifying,
        queuedFiles.length,
        refetchDeposits,
        setIsUploadingFile,
        setVerifyingFalse
    ]);

    return (
        <div className="grid grid-cols-2 gap-8">
            <label
                className={cssClasses}
                onDrop={handleSelectedFiles}
                onDragOver={handleDragOver}
            >
                <FontAwesomeIcon icon={faCloudArrowUp} />
                {t('file-uploader.title')}
                <input
                    type={'file'}
                    multiple
                    hidden
                    onChange={handleSelectedFiles}
                    data-testid="on-prem-uploader"
                    disabled={isDepositsLoading}
                />
            </label>
            <div className="space-y-2.5">
                {depositFiles !== null &&
                    depositFiles.map((fileItem, index) => (
                        <CardProcess
                            key={fileItem?.fileId + '' + index}
                            onDeleteFile={() =>
                                handleDeleteFile(fileItem, false)
                            }
                            file={fileItem}
                        />
                    ))}
                {queuedFiles !== null &&
                    queuedFiles.length > 0 &&
                    queuedFiles.map((fileItem, index) => (
                        <CardProcess
                            key={fileItem.fileName! + index}
                            onDeleteFile={() =>
                                handleDeleteFile(fileItem, true)
                            }
                            file={fileItem}
                            percentCompleted={filePercentCompleted}
                        />
                    ))}
            </div>
        </div>
    );
};

export default OnPremUploader;
