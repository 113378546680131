import * as yup from 'yup';
import {
    Button,
    InputField,
    Modal,
    RadioGroupField,
    ResultModal,
    SelectField,
    Stepper,
    StepperProps,
    TextareaField
} from 'lib-ui';
import { FormProvider, Path, SubmitHandler, useForm } from 'react-hook-form';
import {
    MouseEventHandler,
    useCallback,
    useEffect,
    useMemo,
    useState
} from 'react';
import { NavLink } from 'react-router-dom';
import { isUndefined } from 'lodash';
import { useModals } from '@ncc-frontend/core';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import authenticationTypeOptions from '../authentication-type-options';
import providerTypeOptions from '../provider-type-options';

import useBackendValidation from 'common/use-backend-validation';
import useProvidersAdd, {
    ProvidersAddParams
} from 'core/api/client-portal/hooks/providers/use-providers-add';

type AddSourceControlConnectionFormValues = Omit<
    ProvidersAddParams['data'],
    'authenticationType' | 'providerType'
> & {
    authenticationType: string;
    providerType: string;
};
type Errors = Record<
    | 'authenticationType'
    | 'providerType'
    | 'connectionName'
    | 'password'
    | 'privateKey'
    | 'repositoryAddress'
    | 'userName'
    | 'repositorySettingsNotValid',
    string[]
> & {
    message?: string[];
};

type Step = 'provider' | 'authentication';

const linkedValidationPropertiesAuthentication = [
    'connectionName',
    'providerType'
];
const isValidateAuthentication =
    (step: Step) => (connectionName: string, providerType: string) =>
        !!connectionName && !!providerType && step !== 'provider';

interface AddSourceControlConnectionModalProps {}

function AddSourceControlConnectionModal({}: AddSourceControlConnectionModalProps) {
    const { t } = useTranslation();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const { pop, push } = useModals();
    const {
        error,
        isLoading,
        mutate: requestProvidersAdd
    } = useProvidersAdd<{
        errors: Record<
            | Path<AddSourceControlConnectionFormValues>
            | 'repositorySettingsNotValid',
            string[]
        >;
    }>({
        onError: (error) => {
            let message = t('result.error-description');
            const errors: Errors = error.response.data.errors;

            if (errors.message?.[0] === 'Connection name already in use.') {
                message = t(
                    'add-source-control-connection.connection-name-already-in-use'
                );
            }

            setErrorMessage(message);
            return;
        },
        onSuccess: () => {
            pop();
            push(ResultModal, {
                children: (
                    <NavLink to="/schedules" onClick={pop}>
                        <Button variant="primary" className="w-full">
                            {t(
                                'add-source-control-connection.success-to-schedules'
                            )}
                        </Button>
                    </NavLink>
                ),
                description: t(
                    'add-source-control-connection.success-description'
                ),
                title: t('add-source-control-connection.success-title')
            });
        }
    });
    const connectionFailed = !isUndefined(
        error?.response?.data?.errors?.repositorySettingsNotValid
    );

    const [step, setStep] = useState<Step>('provider');

    const formSchema = yup.object({
        authenticationType: yup
            .string()
            .when(linkedValidationPropertiesAuthentication, {
                is: isValidateAuthentication(step),
                otherwise: (schema) => schema,
                then: (schema) =>
                    schema.when(['providerType'], {
                        is: (providerType: string) => providerType === '1', // 1 == Git,
                        otherwise: (schema) => schema,
                        then: schema.required(
                            t('form.validation.required', {
                                field: t(
                                    'add-source-control-connection.authentication-type'
                                )
                            })
                        )
                    })
            }),
        connectionName: yup
            .string()
            .required(
                t('form.validation.required', {
                    field: t('add-source-control-connection.connection-name')
                })
            )
            .max(
                150,
                t('form.validation.max-length', {
                    field: t('add-source-control-connection.connection-name'),
                    length: 150
                })
            ),
        password: yup.string().when(linkedValidationPropertiesAuthentication, {
            is: isValidateAuthentication(step),
            otherwise: (schema) => schema,
            then: (schema) =>
                schema.when(['authenticationType'], {
                    is: (authenticationType: string) =>
                        authenticationType === '1', // 1 == HTTPS
                    otherwise: (schema) =>
                        schema.required(
                            t('form.validation.required', {
                                field: t(
                                    'add-source-control-connection.key-passphrase'
                                )
                            })
                        ),
                    then: (schema) =>
                        schema.required(
                            t('form.validation.required', {
                                field: t(
                                    'add-source-control-connection.password'
                                )
                            })
                        )
                })
        }),
        privateKey: yup
            .string()
            .when(linkedValidationPropertiesAuthentication, {
                is: isValidateAuthentication(step),
                otherwise: (schema) => schema,
                then: (schema) =>
                    schema.when(['providerType', 'authenticationType'], {
                        is: (
                            providerType: string,
                            authenticationType: string
                        ) =>
                            providerType !== '1' || // 1 === Git
                            (providerType === '1' &&
                                authenticationType === '0'),
                        otherwise: (schema) => schema,
                        then: (schema) =>
                            schema.required(
                                t('form.validation.required', {
                                    field: t(
                                        'add-source-control-connection.private-key'
                                    )
                                })
                            )
                    })
                // 1 == HTTPS
            }),
        providerType: yup.string().required(
            t('form.validation.required', {
                field: t('add-source-control-connection.provider-type')
            })
        ),
        repositoryAddress: yup
            .string()
            .when(linkedValidationPropertiesAuthentication, {
                is: isValidateAuthentication(step),
                otherwise: (schema) => schema,
                then: (schema) =>
                    schema.required(
                        t('form.validation.required', {
                            field: t(
                                'add-source-control-connection.repository-address'
                            )
                        })
                    )
            }),
        userName: yup.string().when(linkedValidationPropertiesAuthentication, {
            is: isValidateAuthentication(step),
            otherwise: (schema) => schema,
            then: (schema) =>
                schema.required(
                    t('form.validation.required', {
                        field: t('add-source-control-connection.username')
                    })
                )
        })
    });
    const { setError, ...methods } =
        useForm<AddSourceControlConnectionFormValues>({
            defaultValues: {
                authenticationType: '',
                connectionName: '',
                password: '',
                privateKey: '',
                providerType: '',
                repositoryAddress: '',
                userName: ''
            },
            mode: 'onTouched',
            resolver: yupResolver(formSchema)
        });
    const values = methods.watch();

    const showPrivateKey =
        values.providerType !== '1' ||
        (values.providerType === '1' && values.authenticationType === '0');

    const steps = useMemo<StepperProps<Step>['steps']>(
        () => [
            {
                error:
                    !!methods.formState.errors.connectionName ||
                    !!methods.formState.errors.providerType,
                node: t('add-source-control-connection.provider-step'),
                step: 'provider'
            },
            {
                disabled:
                    step !== 'authentication' &&
                    (!values.connectionName || !values.providerType),
                error:
                    connectionFailed ||
                    !!methods.formState.errors.authenticationType ||
                    !!methods.formState.errors.password ||
                    !!methods.formState.errors.privateKey ||
                    !!methods.formState.errors.repositoryAddress ||
                    !!methods.formState.errors.userName,
                node: t('add-source-control-connection.authentication-step'),
                step: 'authentication'
            }
        ],
        [
            connectionFailed,
            methods.formState.errors.authenticationType,
            methods.formState.errors.connectionName,
            methods.formState.errors.password,
            methods.formState.errors.privateKey,
            methods.formState.errors.providerType,
            methods.formState.errors.repositoryAddress,
            methods.formState.errors.userName,
            step,
            t,
            values.connectionName,
            values.providerType
        ]
    );

    const handleStepperChange = useCallback(
        (value: Step) => setStep(value),
        []
    );

    const submit = useCallback<
        SubmitHandler<AddSourceControlConnectionFormValues>
    >(
        (formData) => {
            if (step === 'authentication') {
                const data: ProvidersAddParams['data'] = {
                    ...formData,
                    authenticationType: undefined,
                    privateKey: undefined,
                    providerType: undefined
                };

                if (!isUndefined(formData.providerType)) {
                    data.providerType = Number(formData.providerType);
                }
                if (
                    formData.providerType === '1' && // 1 === Git
                    !isUndefined(formData.authenticationType)
                ) {
                    data.authenticationType = Number(
                        formData.authenticationType
                    );
                }
                if (showPrivateKey) {
                    data.privateKey = formData.privateKey;
                }

                requestProvidersAdd({ data });
            } else {
                setStep('authentication');
            }
        },
        [requestProvidersAdd, showPrivateKey, step]
    );

    const handleBack = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
        if (step === 'provider') {
            pop();
            return;
        }

        setStep('provider');
    }, [pop, step]);

    useBackendValidation(error?.response?.data?.errors, setError, [
        'repositorySettingsNotValid'
    ]);

    useEffect(() => {
        if (
            !isUndefined(
                error?.response?.data?.errors?.repositorySettingsNotValid
            )
        ) {
            setError('password', {
                message: t('add-source-control-connection.key-passphrase')
            });
            setError('privateKey', {
                message: t('add-source-control-connection.private-key')
            });
            setError('repositoryAddress', {
                message: t('add-source-control-connection.repository-address')
            });
            setError('userName', {
                message: t('add-source-control-connection.username')
            });
        }
    }, [error, setError, t]);

    return (
        <Modal className="w-96">
            <Modal.Header>
                {t('add-source-control-connection.title')}
            </Modal.Header>
            <Modal.Body className="flex flex-col gap-2.5">
                <Stepper
                    steps={steps}
                    active={step}
                    onChange={handleStepperChange}
                    suppressLabel
                />
                <FormProvider {...methods} setError={setError}>
                    <form
                        onSubmit={methods.handleSubmit(submit)}
                        className="flex flex-col gap-2.5"
                    >
                        {step === 'provider' && (
                            <>
                                <InputField
                                    name="connectionName"
                                    data-testid="connectionName"
                                    label={t(
                                        'add-source-control-connection.connection-name'
                                    )}
                                    placeholder={t(
                                        'add-source-control-connection.connection-name-placeholder'
                                    )}
                                    required
                                />
                                <SelectField
                                    name="providerType"
                                    data-testid="providerType"
                                    label={t(
                                        'add-source-control-connection.provider-type'
                                    )}
                                    placeholder={t(
                                        'add-source-control-connection.provider-type-placeholder'
                                    )}
                                    options={providerTypeOptions}
                                    required
                                />
                            </>
                        )}
                        {step === 'authentication' && (
                            <>
                                {/* // 1 == Git */}
                                {values.providerType === '1' && (
                                    <RadioGroupField
                                        name="authenticationType"
                                        data-testid="authenticationType"
                                        label={t(
                                            'add-source-control-connection.authentication-type'
                                        )}
                                        options={authenticationTypeOptions}
                                        required
                                    />
                                )}
                                <InputField
                                    name="repositoryAddress"
                                    data-testid="repositoryAddress"
                                    label={t(
                                        'add-source-control-connection.repository-address'
                                    )}
                                    placeholder="viewtest.git"
                                    required
                                />
                                {/* 1 == Git */}
                                {(values.providerType !== '1' ||
                                    (values.providerType === '1' &&
                                        !!values.authenticationType)) && (
                                    <>
                                        <InputField
                                            name="userName"
                                            data-testid="userName"
                                            label={t(
                                                'add-source-control-connection.username'
                                            )}
                                            placeholder="john.smith"
                                            required
                                        />
                                        {showPrivateKey && (
                                            <TextareaField
                                                name="privateKey"
                                                data-testid="privateKey"
                                                label={t(
                                                    'add-source-control-connection.private-key'
                                                )}
                                                placeholder={t(
                                                    'add-source-control-connection.private-key-placeholder'
                                                )}
                                                required
                                            />
                                        )}
                                        <InputField
                                            name="password"
                                            data-testid="password"
                                            type="password"
                                            label={
                                                showPrivateKey
                                                    ? t(
                                                          'add-source-control-connection.key-passphrase'
                                                      )
                                                    : t(
                                                          'add-source-control-connection.password'
                                                      )
                                            }
                                            placeholder={t(
                                                'add-source-control-connection.key-passphrase-placeholder'
                                            )}
                                            required
                                        />
                                    </>
                                )}
                            </>
                        )}
                        {errorMessage && (
                            <span className="text-center text-general-red-red-100">
                                {errorMessage}
                            </span>
                        )}
                        {connectionFailed && (
                            <span className="text-center text-general-red-red-100">
                                {t(
                                    'add-source-control-connection.invalid-repository-settings'
                                )}
                            </span>
                        )}
                        <Button
                            variant={
                                connectionFailed && step === 'authentication'
                                    ? 'danger'
                                    : 'primary'
                            }
                            loading={isLoading}
                            disabled={!methods.formState.isDirty}
                        >
                            {step !== 'authentication'
                                ? t('add-source-control-connection.next')
                                : connectionFailed
                                ? t('add-source-control-connection.retry')
                                : t('add-source-control-connection.submit')}
                        </Button>
                    </form>
                    {/* There is a but with tailwindcss somewhere where a style tag is injected so
                    tailwind reset overrides it's own styles if a button has "type" attribute defined
                    because of this we have to put no submit buttons outside of the form area. */}
                    <Button
                        variant="tertiary"
                        onClick={handleBack}
                        disabled={step === 'provider'}
                        className="w-full"
                    >
                        {t('add-source-control-connection.back')}
                    </Button>
                </FormProvider>
            </Modal.Body>
        </Modal>
    );
}

export default AddSourceControlConnectionModal;
export type { AddSourceControlConnectionModalProps };
