import { Dispatch, SetStateAction, useEffect } from 'react';
import Turnstile, { useTurnstile } from 'react-turnstile';

interface CloudFlareTurnstileProps {
    cloudFlareToken: string | undefined;
    setCloudFlareToken: Dispatch<SetStateAction<string | undefined>>;
}

function CloudFlareTurnstile({
    cloudFlareToken,
    setCloudFlareToken
}: CloudFlareTurnstileProps) {
    const turnstile = useTurnstile();

    useEffect(() => {
        if (cloudFlareToken === 'reset') turnstile.reset();
    }, [cloudFlareToken, turnstile]);

    return (
        <Turnstile
            sitekey={process.env.REACT_APP_SITE_KEY!}
            onVerify={(token) => {
                setCloudFlareToken(token);
            }}
        />
    );
}

export default CloudFlareTurnstile;
