import { useMemo } from 'react';

import RadioGroupField, { RadioGroupFieldProps } from './radio-group-field';

interface RatingFieldProps extends Omit<RadioGroupFieldProps, 'options'> {}

function RatingField({ ...restProps }: RatingFieldProps) {
    const options = useMemo<RadioGroupFieldProps['options']>(
        () => [
            {
                label: '1',
                value: '1'
            },
            {
                label: '2',
                value: '2'
            },
            {
                label: '3',
                value: '3'
            },
            {
                label: '4',
                value: '4'
            },
            {
                label: '5',
                value: '5'
            }
        ],
        []
    );

    return <RadioGroupField {...restProps} options={options} />;
}

export default RatingField;
export type { RatingFieldProps };
