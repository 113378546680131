import { PropsWithChildren, useMemo } from 'react';
import { TabProps, Tabs, TailwindStyle, useTabs } from '@ncc-frontend/core';
import { merge } from 'lodash';

interface TabsTabProps extends TabProps {}

function TabsTab({
    children,
    tabId,
    tailwindStyle,
    ...restProps
}: PropsWithChildren<TabsTabProps>) {
    const { currentTabId } = useTabs();
    const active = currentTabId === tabId;

    const mergedTailwindStyle = useMemo(
        () =>
            merge<TailwindStyle, TailwindStyle | undefined>(
                {
                    border: 'border-b-2',
                    borderColor: active
                        ? 'border-brand-escode-neonblue-neonblue-100'
                        : 'border-transparent',
                    textColor: active
                        ? 'text-brand-escode-neonblue-neonblue-100'
                        : 'text-escode-grey-90',
                    textSize: 'text-lg'
                },
                tailwindStyle
            ),
        [active, tailwindStyle]
    );
    return (
        <Tabs.Tab
            {...restProps}
            tabId={tabId}
            tailwindStyle={mergedTailwindStyle}
        >
            {children}
        </Tabs.Tab>
    );
}

export default TabsTab;
export type { TabsTabProps };
