import { AuditRecordType } from '../autogenerated/data-contracts';

type FrontendAuditRecordType =
    | 'none'
    | 'email'
    | 'data-changed'
    | 'log-in'
    | 'log-out'
    | 'deposit-failure'
    | 'account-summary-generated'
    | 'deposit-history-generated'
    | 'signed-agreement-download'
    | 'confirmation-agreement-download';

const AUDIT_RECORD_TYPE_MAPPER: Record<
    AuditRecordType,
    FrontendAuditRecordType
> = {
    [AuditRecordType.Value0]: 'none',
    [AuditRecordType.Value1]: 'email',
    [AuditRecordType.Value2]: 'data-changed',
    [AuditRecordType.Value3]: 'log-in',
    [AuditRecordType.Value4]: 'log-out',
    [AuditRecordType.Value5]: 'deposit-failure',
    [AuditRecordType.Value6]: 'account-summary-generated',
    [AuditRecordType.Value7]: 'deposit-history-generated',
    [AuditRecordType.Value8]: 'signed-agreement-download',
    [AuditRecordType.Value9]: 'confirmation-agreement-download'
};

export { AUDIT_RECORD_TYPE_MAPPER };
export type { FrontendAuditRecordType };
