import { ClientContactsResponseViewModel } from '../../autogenerated/data-contracts';

const useAgreementsMockData: ClientContactsResponseViewModel = {
    contacts: [
        {
            addressLine1: 'addressLine1',
            addressLine2: 'addressLine2',
            addressLine3: 'addressLine3',
            email: 'paco@piedras.com',
            firstName: 'Paco',
            id: 1,
            inviteEmailSent: true,
            jobTitle: 'jobTitle',
            lastName: 'Piedras',
            postcode: 'postcode',
            telephone: 'telephone',
            title: 'Mr',
            userId: 'userId',
            userIsEnabled: true,
            userName: 'paco.piedras'
        },
        {
            addressLine1: 'addressLine1',
            addressLine2: 'addressLine2',
            addressLine3: 'addressLine3',
            email: 'juan@piernas.com',
            firstName: 'Juan',
            id: 2,
            inviteEmailSent: true,
            jobTitle: 'jobTitle',
            lastName: 'Piernas',
            postcode: 'postcode',
            telephone: 'telephone',
            title: 'title',
            userId: 'userId',
            userIsEnabled: false,
            userName: 'juan.piernas'
        },
        {
            addressLine1: 'addressLine1',
            addressLine2: 'addressLine2',
            addressLine3: 'addressLine3',
            email: 'willy@brazos.com',
            firstName: 'Willy',
            id: 3,
            inviteEmailSent: false,
            jobTitle: 'jobTitle',
            lastName: 'Brazos',
            postcode: 'postcode',
            telephone: 'telephone',
            title: 'title',
            userId: 'userId',
            userIsEnabled: false,
            userName: 'willy.brazos'
        },
        {
            addressLine1: 'addressLine1',
            addressLine2: 'addressLine2',
            addressLine3: 'addressLine3',
            email: 'email',
            firstName: 'Pepe',
            id: 4,
            inviteEmailSent: false,
            jobTitle: 'jobTitle',
            lastName: 'Pelotas',
            postcode: 'postcode',
            telephone: 'telephone',
            title: 'title',
            userId: 'userId',
            userIsEnabled: false,
            userName: ''
        },
        ...new Array(100).fill({
            addressLine1: 'addressLine1',
            addressLine2: 'addressLine2',
            addressLine3: 'addressLine3',
            email: 'email',
            firstName: 'firstName',
            inviteEmailSent: false,
            jobTitle: 'jobTitle',
            lastName: 'lastName',
            postcode: 'postcode',
            telephone: 'telephone',
            title: 'title',
            userId: 'userId',
            userIsEnabled: false,
            userName: ''
        })
    ]
};

useAgreementsMockData.contacts = useAgreementsMockData.contacts?.map(
    (item, index) => ({
        ...item,
        id: index + 1
    })
);

export default useAgreementsMockData;
