import { AgGridReact } from 'ag-grid-react';
import { Button } from 'lib-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement, useCallback, useMemo, useRef } from 'react';
import { dateFormatter, useModals } from '@ncc-frontend/core';
import { faEllipsis } from '@fortawesome/pro-regular-svg-icons';
import { get } from 'common/path';
import { isUndefined } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AgreementsTableContext } from './agreements-table-context';
import {
    DepositItem,
    DepositSortColumn,
    DepositTypesFilter
} from 'core/api/client-portal/autogenerated/data-contracts';
import { getAgreementLicenseesString } from './agreement-licensee-cell-render';
import {
    getAgreementStatus,
    getAgreementStatusString
} from './agreement-status-cell-renderer';
import AddLicenseeModal from '../licensee/add-licensee-modal';
import AgreementPartiesTableWidget from './parties/agreement-parties-table-widget';
import DepositsTableWidget from './deposits/deposits-table-widget';
import InfoSlate, { InfoSlateProps } from './info-slate';
import InitiateReleaseModal from './release/initiate-release-modal';
import LicenseesDetailsModal from 'ui/licensee/licensees-details-modal';
import MoreInfoModal from './more-info/more-info-modal';
import RequestNovationModal from './novation/request-novation-modal';
import useAgreementsContractDownload from 'core/api/client-portal/hooks/contracts/use-agreements-contract-download';
import useAgreementsContracts from 'core/api/client-portal/hooks/contracts/use-agreements-contracts';
import useAgreementsDeposits from 'core/api/client-portal/hooks/deposits/use-agreements-deposits';
import useAgreementsDetails from 'core/api/client-portal/hooks/agreements/use-agreements-details';

function AgreementDetails(): ReactElement {
    const { id } = useParams();
    const { i18n, t } = useTranslation();
    const { push } = useModals();
    const goTo = useNavigate();

    const depositsTableRef = useRef<AgGridReact<DepositItem>>(null);
    const slateInfoContext = useMemo<AgreementsTableContext>(
        () => ({ goTo, push, t }),
        [goTo, push, t]
    );
    const agreementId = Number(id);

    const {
        data: agreement,
        error: agreementError,
        isFetching: isFetchingAgreement,
        isLoading: isLoadingAgreement,
        refetch: refetchAgreement
    } = useAgreementsDetails(agreementId);

    const depositsPageSize = 50;
    const depositsQuery = useMemo<{
        depositType?: DepositTypesFilter;
        orderBy?: DepositSortColumn;
        orderByDescending?: boolean;
        pageSize?: number;
    }>(
        () => ({
            // BACKEND: swagger doc. looks like this should be a string...
            orderBy: 'depositDate' as unknown as DepositSortColumn,
            orderByDescending: true,
            pageSize: depositsPageSize
        }),
        []
    );
    const {
        data: deposits,
        error: depositsError,
        fetchNextPage,
        isFetching: isFetchingDeposits,
        isFetchingNextPage: isFetchingNextDeposits,
        isLoading: isLoadingDeposits,
        refetch: refetchDeposits
    } = useAgreementsDeposits(agreementId, depositsQuery);

    const {
        data: contracts,
        isFetching: isFetchingContracts,
        isLoading: isLoadingContracts
    } = useAgreementsContracts(agreementId);

    const {
        isLoading: isLoadingContractDownload,
        mutate: requestContractDownload
    } = useAgreementsContractDownload();

    const isLoadingDepositsTable =
        isLoadingDeposits || isFetchingDeposits || isFetchingNextDeposits;

    const isLoadingAgreementPartiesTable =
        isLoadingAgreement ||
        isFetchingAgreement ||
        isLoadingContracts ||
        isFetchingContracts;

    const depositsTotalPages = Math.ceil(
        (deposits?.pages[0]?.totalRecords ?? 1) / depositsPageSize
    );

    const contractIndex = useMemo(() => {
        const contract =
            (!!agreement &&
                !isUndefined(agreement.agreementNumber) &&
                contracts?.items?.find(
                    (item) =>
                        (item.slxType?.indexOf(
                            (agreement.agreementNumber as number).toString()
                        ) ?? -1) > -1
                )) ||
            undefined;

        return contract?.index;
    }, [agreement, contracts?.items]);

    const slateInformation = useMemo<Omit<InfoSlateProps, 'loading'>[]>(
        () => [
            {
                text: get(agreement, 'softwarePackageName'),
                title: t('agreements.header.software')
            },
            {
                text: get(agreement, 'softwareOwner'),
                title: t('agreements.header.software-owner')
            },
            {
                text:
                    (agreement &&
                        getAgreementStatusString({
                            context: slateInfoContext,
                            status: getAgreementStatus(agreement)
                        })) ||
                    undefined,
                title: t('agreements.header.status')
            },
            {
                text: get(agreement, 'agreementType'),
                title: t('agreements.header.type')
            },
            {
                onClick:
                    (agreement?.viewableAgreementParties?.length ?? 0) > 1
                        ? () => {
                              if (agreement?.viewableAgreementParties) {
                                  push(LicenseesDetailsModal, {
                                      data: agreement.viewableAgreementParties
                                  });
                              }
                          }
                        : undefined,
                text:
                    (agreement &&
                        getAgreementLicenseesString({
                            context: slateInfoContext,
                            data: agreement.viewableAgreementParties
                        })) ||
                    undefined,
                title: t('agreements.header.licensees')
            },
            {
                text: dateFormatter(
                    get(agreement, 'lastDepositDate'),
                    i18n.language
                ),
                title: t('agreements.header.last-deposit')
            }
        ],
        [agreement, t, slateInfoContext, i18n.language, push]
    );

    const handleNext = useCallback(() => {
        fetchNextPage();
    }, [fetchNextPage]);

    const addLicensee = useCallback(() => {
        push(AddLicenseeModal, { agreementId });
    }, [agreementId, push]);

    const initiateRelease = useCallback(() => {
        push(InitiateReleaseModal, { agreementId });
    }, [agreementId, push]);

    const downloadContract = useCallback(() => {
        if (!contractIndex) return;

        requestContractDownload({ agreementId, index: contractIndex });
    }, [agreementId, contractIndex, requestContractDownload]);

    const requestNovation = useCallback(() => {
        push(RequestNovationModal, {});
    }, [push]);

    const readMoreInfo = useCallback(() => {
        push(MoreInfoModal, { agreement });
    }, [agreement, push]);

    const reloadDeposits = useCallback(() => {
        refetchDeposits();
    }, [refetchDeposits]);

    const reloadAgreement = useCallback(() => {
        refetchAgreement();
    }, [refetchAgreement]);

    return (
        <div className="h-full flex flex-col gap-4">
            <div className="flex items-center justify-end gap-2.5">
                <div className="mr-auto font-medium text-3xl">
                    {t('agreement.title')} {agreement?.agreementNumber}
                </div>
                <Button variant="tertiary" onClick={addLicensee}>
                    {t('agreement.add-licensee')}
                </Button>
                <Button variant="tertiary" onClick={initiateRelease}>
                    {t('agreement.initiate-release')}
                </Button>
                <Button variant="tertiary" onClick={requestNovation}>
                    {t('agreement.request-novation')}
                </Button>
                <Button
                    variant="tertiary"
                    onClick={downloadContract}
                    disabled={!contractIndex}
                    loading={isLoadingContractDownload}
                >
                    {t('agreement.download-contract')}
                </Button>
                <Button
                    variant="tertiary"
                    onClick={readMoreInfo}
                    data-testid="more-info"
                >
                    <FontAwesomeIcon icon={faEllipsis} />
                </Button>
            </div>

            <div className="grid grid-cols-6 gap-4">
                {slateInformation.map((iconProps, index) => (
                    <InfoSlate
                        key={index}
                        {...iconProps}
                        loading={isLoadingAgreement || isFetchingAgreement}
                    />
                ))}
            </div>

            <DepositsTableWidget
                ref={depositsTableRef}
                agreementNumber={agreement?.agreementNumber}
                data={deposits?.pages}
                totalPages={depositsTotalPages}
                loading={isLoadingDepositsTable}
                onNext={handleNext}
                className="flex-1"
                error={depositsError?.response.status}
                onReload={reloadDeposits}
            />

            <AgreementPartiesTableWidget
                agreement={agreement}
                contracts={contracts}
                loading={isLoadingAgreementPartiesTable}
                className="flex-1"
                error={agreementError?.response.status}
                onReload={reloadAgreement}
            />
        </div>
    );
}

export default AgreementDetails;
