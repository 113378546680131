function deleteFromStorage(prefix: string) {
    const itemsInStorage = localStorage.length;
    const keysToDelete = [];

    for (let i = 0; i < itemsInStorage; i++) {
        const key: string = localStorage.key(i)!;

        if (key.startsWith(prefix)) {
            keysToDelete.push(key);
        }
    }

    for (const key of keysToDelete) {
        localStorage.removeItem(key);
    }
}

export default deleteFromStorage;
