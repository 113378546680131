import { AxiosResponse } from 'axios';
import { FixedSourceProviderResponse } from './types';
import { UseQueryOptions, useQuery } from 'react-query';
import { isUndefined } from 'lodash';

import { ApiController } from '../../autogenerated/Api';
import { useAuth } from 'react-oidc-context';
import interceptors from '../../../interceptors';
import securityWorker from '../../../security-worker';

import useProvidersListMockData from './use-providers-list-mock-data';

function useProvidersList<TError = unknown>(
    options?: Omit<
        UseQueryOptions<
            FixedSourceProviderResponse | undefined,
            ReactQueryError<TError>,
            FixedSourceProviderResponse | undefined
        >,
        'queryKey' | 'queryFn'
    >
) {
    const auth = useAuth();

    return useQuery(
        [
            'providers',
            'list',
            { token: auth.user?.access_token, tokenType: auth.user?.token_type }
        ],
        async () => {
            if (process.env.REACT_APP_API_MOCK) {
                console.warn('Using mock data for useProvidersList');
                // return Promise.reject({ response: { status: 404 } });
                return useProvidersListMockData;
            }

            if (
                isUndefined(auth.user?.access_token) ||
                isUndefined(auth.user?.token_type)
            ) {
                return undefined;
            }

            const controller = new ApiController({
                interceptors,
                securityWorker: securityWorker(
                    auth.user?.token_type,
                    auth.user?.access_token
                )
            });

            const response =
                (await controller.getSourceCodeProviders()) as unknown as AxiosResponse<FixedSourceProviderResponse>;
            return response.data;
        },
        options
    );
}

export default useProvidersList;
