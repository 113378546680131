import {
    Button as CoreButton,
    ButtonProps as CoreButtonProps,
    TailwindStyle
} from '@ncc-frontend/core';
import {
    ForwardedRef,
    PropsWithChildren,
    forwardRef,
    memo,
    useMemo
} from 'react';
import { merge } from 'lodash';

interface ButtonProps extends Omit<CoreButtonProps, 'variant'> {
    variant?:
        | 'primary'
        | 'secondary'
        | 'tertiary'
        | 'default'
        | 'ghost'
        | 'danger'
        | 'warning';
}

function Button(
    {
        children,
        disabled,
        tailwindStyle,
        variant = 'default',
        ...restProps
    }: PropsWithChildren<ButtonProps>,
    ref: ForwardedRef<HTMLButtonElement>
) {
    const mergedTailwindStyle = useMemo(() => {
        const baseStyle: TailwindStyle = {
            border: '',
            borderRadius: 'rounded-lg',
            textWeight: 'font-medium',
            truncate: ''
        };

        const variantStyle: TailwindStyle = {};
        switch (variant) {
            case 'primary':
                variantStyle.background =
                    'bg-brand-escode-neonblue-neonblue-100';
                variantStyle.textColor = 'text-ncc-white';
                variantStyle.variant = {
                    active: {
                        background:
                            'active:bg-brand-escode-neonblue-neonblue-80',
                        textColor: 'active:text-ncc-white'
                    },
                    disabled: {
                        background: 'disabled:bg-escode-grey-20',
                        textColor: 'disabled:!text-escode-grey-50'
                    },
                    hover: {
                        background:
                            'hover:bg-brand-escode-neonblue-neonblue-140',
                        textColor: 'hover:text-ncc-white'
                    }
                };
                break;
            case 'secondary':
                variantStyle.background =
                    'bg-brand-escode-neonblue-neonblue-10';
                variantStyle.textColor =
                    'text-brand-escode-neonblue-neonblue-100';
                variantStyle.variant = {
                    active: {
                        background:
                            'active:bg-brand-escode-neonblue-neonblue-10',
                        textColor:
                            'active:text-brand-escode-neonblue-neonblue-80'
                    },
                    disabled: {
                        background: 'disabled:bg-general-grey-grey-20',
                        textColor: 'disabled:text-escode-grey-50'
                    },
                    hover: {
                        background:
                            'hover:bg-brand-escode-neonblue-neonblue-10',
                        textColor:
                            'hover:text-brand-escode-neonblue-neonblue-100'
                    }
                };
                break;
            case 'tertiary':
                variantStyle.background =
                    'bg-ncc-white disabled:bg-general-grey-grey-20';
                variantStyle.border = 'border disabled:border-none';
                variantStyle.borderColor = 'border-border-general-grey-grey-40';
                variantStyle.textColor =
                    'text-brand-escode-neonblue-neonblue-100 disabled:text-general-grey-grey-50';
                variantStyle.variant = {
                    active: {
                        borderColor: 'active:border-general-grey-grey-40',
                        textColor: 'active:text-general-grey-grey-80'
                    },
                    disabled: {
                        background: 'disabled:bg-ncc-white',
                        borderColor: 'disabled:border-escode-grey-30',
                        textColor: 'disabled:text-escode-grey-50'
                    },
                    hover: {
                        borderColor:
                            'hover:border-brand-escode-neonblue-neonblue-100',
                        textColor:
                            'hover:text-brand-escode-neonblue-neonblue-100'
                    }
                };

                break;
            case 'default':
                variantStyle.background = 'bg-ncc-white';
                variantStyle.textColor =
                    'text-brand-escode-neonblue-neonblue-100';
                variantStyle.variant = {
                    active: {
                        textColor:
                            'active:text-brand-escode-neonblue-neonblue-80'
                    },
                    disabled: {
                        background: 'disabled:bg-escode-grey-20',
                        textColor: 'disabled:text-escode-grey-50'
                    },
                    hover: {
                        textColor:
                            'hover:text-brand-escode-neonblue-neonblue-140'
                    }
                };
                break;
            case 'ghost':
                variantStyle.background = '';
                variantStyle.textColor =
                    'text-brand-escode-neonblue-neonblue-100';
                variantStyle.variant = {
                    active: {
                        background: 'active:bg-transparent',
                        textColor:
                            'active:text-brand-escode-neonblue-neonblue-80'
                    },
                    disabled: {
                        textColor: 'disabled:text-escode-grey-50'
                    },
                    hover: {
                        background:
                            'hover:bg-brand-escode-neonblue-neonblue-10',
                        textColor:
                            'hover:text-brand-escode-neonblue-neonblue-100'
                    }
                };
                break;
            case 'danger':
                variantStyle.background = '';
                variantStyle.textColor = 'text-general-red-red-100';
                variantStyle.variant = {
                    active: {
                        background: 'active:bg-transparent',
                        textColor: 'active:text-general-red-red-100'
                    },
                    disabled: {
                        background: 'disabled:bg-transparent',
                        textColor: 'disabled:text-general-grey-grey-40'
                    },
                    hover: {
                        background: 'hover:bg-general-red-red-20',
                        textColor: 'hover:text-general-red-red-100'
                    }
                };
                break;
            case 'warning':
                variantStyle.background = 'bg-general-orange-orange-10';
                variantStyle.textColor = 'text-general-orange-orange-100';
                variantStyle.variant = {
                    active: {
                        background: 'active:bg-general-orange-orange-10',
                        textColor: 'active:!text-general-orange-orange-100'
                    },
                    disabled: {
                        background: 'disabled:bg-escode-grey-20',
                        textColor: 'disabled:!text-escode-grey-50'
                    },
                    hover: {
                        background: 'hover:bg-general-orange-orange-100-100',
                        textColor: 'hover:text-ncc-white'
                    }
                };
                break;
        }

        return merge(baseStyle, variantStyle, tailwindStyle);
    }, [tailwindStyle, variant]);

    return (
        <CoreButton
            {...restProps}
            ref={ref}
            tailwindStyle={mergedTailwindStyle}
            disabled={disabled}
        >
            {!!children && (
                <span className="truncate flex items-center gap-2.5">
                    {children}
                </span>
            )}
        </CoreButton>
    );
}

export default memo(forwardRef(Button));
export type { ButtonProps };
