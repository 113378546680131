function securityWorker(
    tokenType: string | undefined,
    accessToken: string | undefined
) {
    return () => ({
        headers: {
            Authorization: `${tokenType} ${accessToken}`
        }
    });
}

export default securityWorker;
