import { DocumentMetadataResponseViewModel } from '../../autogenerated/data-contracts';

const useAgreementsDetailsMockData: DocumentMetadataResponseViewModel = {
    items: [
        {
            index: 'index',
            mediaType: 'mediaType',
            slxType: 'slxType',
            uri: 'uri'
        }
    ]
};

export default useAgreementsDetailsMockData;
