import {
    DepositItem,
    GetDepositsResponse
} from '../../autogenerated/data-contracts';

function getDepositsListMockData(
    page: number,
    pageSize = 1
): GetDepositsResponse {
    const totalRecords = pageSize * 2 + 1;

    if (page === 1) {
        return {
            agreementPartyType: 'Owner',
            deposits: new Array(pageSize)
                .fill({
                    addressLine1: 'addressLine1',
                    addressLine2: 'addressLine2',
                    addressLine3: 'addressLine3',
                    agreementId: 1,
                    companyName: 'companyName',
                    contactName: 'contactName',
                    depositDate: '2023-10-10T08:29:59Z',
                    depositId: 1,
                    depositOwner: 'depositOwner',
                    emailAddress: 'emailAddress',
                    escrowDepositStatus: 0,
                    isReadOnly: false,
                    isScheduledDeposit: false,
                    isSftp: false,
                    isSourceControl: false,
                    jobTitle: 'jobTitle',
                    lastSavedState: 0,
                    mediaCheck: false,
                    postCode: 'postCode',
                    softwareOwnerCompanyName: 'softwareOwnerCompanyName',
                    softwarePackageName: 'softwarePackageName',
                    telephoneNumber: 'telephoneNumber',
                    testingLevel: 0,
                    testingLevelDescription: 'testingLevelDescription',
                    versionOrReleaseNumber: 'versionOrReleaseNumber'
                } as DepositItem)
                .map((deposit, index) => ({ ...deposit, depositId: index })),
            errors: {},
            success: true,
            totalRecords
        };
    }

    if (page === 2) {
        return {
            agreementPartyType: 'Owner',
            deposits: new Array(pageSize)
                .fill({
                    addressLine1: 'addressLine1',
                    addressLine2: 'addressLine2',
                    addressLine3: 'addressLine3',
                    agreementId: 1,
                    companyName: 'companyName',
                    contactName: 'contactName',
                    depositDate: '2023-10-10T08:29:59Z',
                    depositId: 1,
                    depositOwner: 'depositOwner',
                    emailAddress: 'emailAddress',
                    escrowDepositStatus: 0,
                    isReadOnly: false,
                    isScheduledDeposit: false,
                    isSftp: false,
                    isSourceControl: false,
                    jobTitle: 'jobTitle',
                    lastSavedState: 0,
                    mediaCheck: false,
                    postCode: 'postCode',
                    softwareOwnerCompanyName: 'softwareOwnerCompanyName',
                    softwarePackageName: 'softwarePackageName',
                    telephoneNumber: 'telephoneNumber',
                    testingLevel: 0,
                    testingLevelDescription: 'testingLevelDescription',
                    versionOrReleaseNumber: 'versionOrReleaseNumber'
                } as DepositItem)
                .map((deposit, index) => ({
                    ...deposit,
                    depositId: pageSize + index
                })),
            errors: {},
            success: true,
            totalRecords
        };
    }

    if (page === 3) {
        return {
            agreementPartyType: 'Owner',
            deposits: [
                {
                    addressLine1: 'addressLine1',
                    addressLine2: 'addressLine2',
                    addressLine3: 'addressLine3',
                    agreementId: 1,
                    companyName: 'companyName',
                    contactName: 'contactName',
                    depositDate: '2023-10-10T08:29:59Z',
                    depositId: pageSize * 2,
                    depositOwner: 'depositOwner',
                    emailAddress: 'emailAddress',
                    escrowDepositStatus: 0,
                    isReadOnly: false,
                    isScheduledDeposit: false,
                    isSftp: false,
                    isSourceControl: false,
                    jobTitle: 'jobTitle',
                    lastSavedState: 0,
                    mediaCheck: false,
                    postCode: 'postCode',
                    softwareOwnerCompanyName: 'softwareOwnerCompanyName',
                    softwarePackageName: 'softwarePackageName',
                    telephoneNumber: 'telephoneNumber',
                    testingLevel: 0,
                    testingLevelDescription: 'testingLevelDescription',
                    versionOrReleaseNumber: 'versionOrReleaseNumber'
                }
            ],
            errors: {},
            success: true,
            totalRecords
        };
    }

    return {
        agreementPartyType: 'Owner',
        deposits: [],
        errors: {},
        success: true,
        totalRecords
    };
}

export { getDepositsListMockData };
