import { PropsWithChildren } from 'react';
import { TailwindStyle, tailwindClasses } from '@ncc-frontend/core';

function mapRange(
    value: number,
    fromMin: number,
    fromMax: number,
    toMin: number,
    toMax: number
) {
    return ((value - fromMin) * (toMax - toMin)) / (fromMax - fromMin) + toMin;
}

interface CalendarDayEventProps {
    date: Date;
    tailwindStyle?: TailwindStyle;
}

function CalendarDayEvent({
    children,
    date,
    tailwindStyle
}: PropsWithChildren<CalendarDayEventProps>) {
    const value = mapRange(
        date.getHours() + mapRange(date.getMinutes(), 0, 60, 0, 1),
        0,
        24,
        0,
        100
    );

    return (
        <div
            className={tailwindClasses(
                {
                    borderRadius: 'rounded-full',
                    left: 'left-1',
                    margin: 'ml-14',
                    padding: 'pt-1 px-4 pb-1.5',
                    position: 'absolute',
                    textDecoration: 'text-center',
                    textWeight: 'font-medium',
                    truncate: 'truncate',
                    width: 'auto min-w-4xs'
                },
                tailwindStyle
            )}
            style={{
                top: `${value}%`
            }}
        >
            {children}
        </div>
    );
}

export default CalendarDayEvent;
export type { CalendarDayEventProps };
