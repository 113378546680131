import { DownloadScheduleItem } from 'core/api/client-portal/autogenerated/data-contracts';
import { useTranslation } from 'react-i18next';

import SchedulesMetricPanel from '../schedules-metric-panel';

function upcomingThisMonthFilter(schedule: DownloadScheduleItem): boolean {
    if (!schedule.nextDownloadAt) return false;

    const today = new Date();
    const date = new Date(schedule.nextDownloadAt);

    return (
        today.getMonth() === date.getMonth() &&
        today.getFullYear() === date.getFullYear() &&
        date > today
    );
}

interface UpcomingThisMonthSchedulesProps {
    data: DownloadScheduleItem[] | null | undefined;
    loading?: boolean;
}

function UpcomingThisMonthSchedules({
    data,
    loading
}: UpcomingThisMonthSchedulesProps) {
    const { t } = useTranslation();

    return (
        <SchedulesMetricPanel
            data={data}
            title={t('schedules.metrics.upcoming-this-month')}
            filter={upcomingThisMonthFilter}
            loading={loading}
        />
    );
}

export default UpcomingThisMonthSchedules;
export type { UpcomingThisMonthSchedulesProps };
