import {
    ChangeEventHandler,
    ForwardedRef,
    ReactElement,
    forwardRef,
    useCallback,
    useState
} from 'react';
import {
    FieldValues,
    FormProvider,
    SubmitHandler,
    useForm
} from 'react-hook-form';
import {
    FilterBackEventHandler,
    FilterConfirmEventHandler,
    FilterData,
    FilterFieldData
} from '../../data-widget-types';
import { IFilterOptionDef } from 'ag-grid-community';
import { TailwindProps } from '@ncc-frontend/core';
import { useTranslation } from 'react-i18next';

import Button from '../../../../button/button';
import CheckboxField from '../../../../form/field/checkbox-field';
import FilterStep from '../filter-step/filter-step';
import Input from '../../../../form/element/input';

const FILTER_IN_LIST_PARAMS = {
    filterOptions: [
        {
            displayKey: 'inList',
            displayName: 'inList',
            predicate: ([filterValues]: string[][], cellValue: string) => {
                return cellValue && filterValues.includes(cellValue);
            }
        } as IFilterOptionDef
    ]
};

interface FilterTextStepProps extends TailwindProps {
    data: FilterFieldData;
    filter: FilterData<'text'> | undefined;
    onBack: FilterBackEventHandler;
    onConfirm?: FilterConfirmEventHandler;
    readonly?: boolean;
}

function FilterTextStep(
    {
        data: { colId, options, searchable, title },
        filter: currentFilter,
        onBack,
        onConfirm,
        readonly = false,
        ...restProps
    }: FilterTextStepProps,
    ref: ForwardedRef<HTMLDivElement>
): ReactElement | null {
    const defaultValues: Record<string, boolean> = {};
    const [search, setSearch] = useState('');

    if (!options)
        throw new Error('Filter of type "text" requires and options array.');

    options.forEach(({ key, label }) => {
        defaultValues[key] =
            currentFilter?.model?.filter.includes(label) || false;
    });

    const { t } = useTranslation();
    const methods = useForm({ defaultValues });

    const submit = useCallback<SubmitHandler<FieldValues>>(
        (formData) => {
            if (readonly) {
                onBack();
                return;
            }

            const filter = Object.entries(formData)
                .filter(([, value]) => value)
                .map(
                    ([key]) => options.find((item) => item.key === key)?.label
                );

            onConfirm?.({
                colId,
                model: {
                    filter,
                    filterType: 'text',
                    type: 'inList'
                }
            });
        },
        [colId, onBack, onConfirm, options, readonly]
    );

    const handleSearch: ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearch(e.target.value);
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(submit)}>
                <FilterStep ref={ref} {...restProps}>
                    <FilterStep.Header
                        onBack={onBack}
                        suppressBackIcon={readonly}
                    >
                        {title}
                    </FilterStep.Header>
                    <FilterStep.Body className="space-y-2.5">
                        {!!searchable && (
                            <Input
                                wrapperClassName="w-full"
                                placeholder={t('search-bar')}
                                value={search}
                                onChange={handleSearch}
                            />
                        )}
                        <div className="flex flex-col gap-2.5">
                            {options
                                .filter(({ label }) => {
                                    if (!search) return true;
                                    return label
                                        ?.toLowerCase()
                                        .includes(search.toLowerCase());
                                })
                                .map(({ key, label }) => (
                                    <CheckboxField
                                        key={key}
                                        name={key}
                                        label={label}
                                        readOnly={readonly}
                                    />
                                ))}
                        </div>
                    </FilterStep.Body>
                    <FilterStep.Footer>
                        <Button variant="primary" className="flex-1">
                            {readonly
                                ? t('filters.go-back')
                                : t('filters.confirm')}
                        </Button>
                    </FilterStep.Footer>
                </FilterStep>
            </form>
        </FormProvider>
    );
}

export default forwardRef(FilterTextStep);
export { FILTER_IN_LIST_PARAMS };
export type { FilterTextStepProps };
