import {
    DeepPartial,
    FieldValues,
    FormProvider,
    useForm
} from 'react-hook-form';
import { PropsWithChildren } from 'react';
import useSubscription from 'common/use-subscription';

interface FeedbackFormStepProps<
    TFieldValues extends FieldValues = FieldValues
> {
    defaultValues: DeepPartial<TFieldValues>;
    message: string;
    onChange: (value: DeepPartial<TFieldValues>) => void;
    title: string;
}

function FeedbackFormStep<TFieldValues extends FieldValues = FieldValues>({
    children,
    defaultValues,
    message,
    onChange,
    title
}: PropsWithChildren<FeedbackFormStepProps<TFieldValues>>) {
    const methods = useForm<TFieldValues>({
        defaultValues
    });

    useSubscription(
        methods.watch((values) => {
            onChange(values);
        })
    );

    return (
        <FormProvider {...methods}>
            <div className="flex flex-col gap-2.5">
                <h4 className="text-general-grey-grey-90 text-xl font-medium">
                    {title}
                </h4>
                <p className="text-general-grey-grey-90 font-medium">
                    {message}
                </p>
            </div>
            {children}
        </FormProvider>
    );
}

export default FeedbackFormStep;
export type { FeedbackFormStepProps };
