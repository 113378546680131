import { GetDownloadSchedulesResponse } from '../../autogenerated/data-contracts';
import { UseQueryOptions, useQuery } from 'react-query';
import { isUndefined } from 'lodash';

import { ApiController } from '../../autogenerated/Api';
import { useAuth } from 'react-oidc-context';
import interceptors from '../../../interceptors';
import securityWorker from '../../../security-worker';
import useSchedulesListMockData from './use-schedules-list-mock-data';

function useSchedulesList<TError = unknown>(
    options?: Omit<
        UseQueryOptions<
            GetDownloadSchedulesResponse | undefined,
            ReactQueryError<TError>,
            GetDownloadSchedulesResponse | undefined
        >,
        'queryKey' | 'queryFn'
    >
) {
    const auth = useAuth();

    return useQuery(
        [
            'schedules',
            'list',
            { token: auth.user?.access_token, tokenType: auth.user?.token_type }
        ],
        async () => {
            if (process.env.REACT_APP_API_MOCK) {
                console.warn('Using mock data for useSchedulesList');
                return await new Promise<GetDownloadSchedulesResponse>(
                    (resolve) => {
                        setTimeout(() => {
                            resolve(useSchedulesListMockData);
                        }, 2000);
                    }
                );
            }

            if (
                isUndefined(auth.user?.access_token) ||
                isUndefined(auth.user?.token_type)
            ) {
                return undefined;
            }

            const controller = new ApiController({
                interceptors,
                securityWorker: securityWorker(
                    auth.user?.token_type,
                    auth.user?.access_token
                )
            });

            const response = await controller.getDownloadSchedules();
            return response.data;
        },
        options
    );
}

export default useSchedulesList;
