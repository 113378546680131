import { ConfirmationTooltip } from 'lib-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Placement, TailwindProps, tailwindClasses } from '@ncc-frontend/core';
import { PropsWithChildren, ReactNode } from 'react';
import {
    faDiamondExclamation,
    faInfoCircle
} from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import Asterisk from '../element/asterisk';
import Optional from '../element/optional';
import cn from 'core/utils/cn';

interface FieldProps extends TailwindProps {
    as?: 'label' | 'div' | 'radio';
    disabled?: boolean;
    error?: ReactNode;
    id?: string;
    label?: ReactNode;
    name: string;
    optional?: boolean;
    orientation?: 'vertical' | 'horizontal' | 'reversed-horizontal';
    readOnly?: boolean;
    required?: boolean;
    suppressReq?: boolean;
    tooltip?: string;
    tooltipDisabled?: boolean;
    tooltipPlacement?: Placement;
}

function Field({
    as,
    children,
    className,
    disabled,
    error,
    id,
    label,
    name,
    optional = false,
    orientation = 'vertical',
    readOnly,
    required,
    suppressReq = false,
    tailwindStyle,
    tooltip,
    tooltipDisabled = false,
    tooltipPlacement = 'right',
    ...restProps
}: PropsWithChildren<FieldProps>) {
    const { t } = useTranslation();
    const cssClasses = cn(
        tailwindClasses(
            {
                cursor: disabled
                    ? 'cursor-not-allowed'
                    : readOnly
                    ? 'cursor-default'
                    : 'cursor-pointer',
                display: 'flex',
                flexDirection:
                    orientation === 'vertical'
                        ? 'flex-col'
                        : orientation === 'reversed-horizontal'
                        ? 'flex-row-reverse'
                        : undefined,
                gap: ['horizontal', 'reversed-horizontal'].includes(orientation)
                    ? 'gap-10'
                    : undefined,
                items: ['horizontal', 'reversed-horizontal'].includes(
                    orientation
                )
                    ? 'items-center'
                    : undefined,
                textColor: !error
                    ? 'text-general-grey-grey-90'
                    : 'text-general-red-red-100'
            },
            tailwindStyle
        ),
        className
    );

    return (
        <div className={cssClasses} {...restProps}>
            {!!label && (
                <div
                    className={cn(
                        'flex w-full',
                        as === 'label' && 'items-center'
                    )}
                >
                    <label
                        htmlFor={id}
                        className={cn(
                            'flex-1 flex items-center gap-1 min-h-full text-general-grey-grey-90 text-base',
                            as === 'label' && 'mb-2',
                            as === 'radio' && 'mb-2'
                        )}
                    >
                        <div className="flex items-center gap-2">
                            <span className="space-x-1">
                                {label} {optional && <Optional />}
                                {required && !suppressReq && <Asterisk />}
                            </span>
                        </div>
                    </label>
                    {tooltip && (
                        <div className="mb-2 cursor-default">
                            <ConfirmationTooltip
                                placement={tooltipPlacement}
                                icon={
                                    <FontAwesomeIcon
                                        icon={faDiamondExclamation}
                                    />
                                }
                                message={t(tooltip)}
                                variant="default"
                                disabled={tooltipDisabled}
                            >
                                <FontAwesomeIcon
                                    icon={faInfoCircle}
                                    className="text-brand-escode-neonblue-neonblue-100"
                                />
                            </ConfirmationTooltip>
                        </div>
                    )}
                </div>
            )}
            {children}
            {error && (
                <div className="text-sm pt-1">
                    <p>{error}</p>
                </div>
            )}
        </div>
    );
}

export default Field;
export type { FieldProps };
