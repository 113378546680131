import { Button, ConfirmationTooltip } from 'lib-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment, PropsWithChildren, ReactNode, useCallback } from 'react';
import {
    faDiamondExclamation,
    faSpinner
} from '@fortawesome/pro-solid-svg-icons';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SelectedAgreementDisplay from '../selected-agreement-display/selected-agreement-display';
import cn from 'core/utils/cn';
import toast from 'react-hot-toast';
import useDepositDelete from 'core/api/client-portal-v2/hooks/deposits/use-deposit-delete';

interface DepositNowStepProps {
    actions?: ReactNode;
    canGoBack?: boolean;
    classNames?: string;
    containerClasses?: string;
    description?: string;
    disableBack?: boolean;
    disableProceed?: boolean;
    displayAgreements?: boolean;
    loading?: boolean;
    onBack?: () => void;
    proceedButton?: ReactNode;
    submitting?: boolean;
    title: string;
}

const DNStep = ({
    actions,
    canGoBack = true,
    children,
    classNames,
    containerClasses,
    description,
    disableBack = false,
    disableProceed = false,
    displayAgreements = true,
    loading,
    onBack,
    proceedButton,
    submitting,
    title
}: PropsWithChildren<DepositNowStepProps>) => {
    const { depositId } = useParams();
    const location = useLocation();
    const { t } = useTranslation();
    const goTo = useNavigate();

    const { isLoading, mutate: depositDeleteFn } = useDepositDelete({
        onError(error, variables, context) {
            toast.error(t('deposit-now-step.error-discarding-deposit'));
        },
        onSuccess(data, variables, context) {
            toast.success(t('deposit-now-step.deposit-discarded-successfully'));
            goTo('/');
        }
    });

    const handleDiscard = useCallback(() => {
        // if we're on step 1 we just nav to dashboard as we havent created a deposit yet
        if (location.pathname === '/deposit-now') {
            toast.success(t('deposit-now-step.deposit-discarded-successfully'));
            goTo('/');
            return;
        }
        if (depositId) {
            depositDeleteFn({ depositId: parseInt(depositId) });
        }
    }, [depositDeleteFn, depositId, goTo, location.pathname, t]);

    if (isLoading) {
        return (
            <div className="flex justify-center">
                <FontAwesomeIcon
                    className="h-14 w-14 mt-40 mx-auto"
                    icon={faSpinner}
                    spin
                />
            </div>
        );
    }
    return (
        <Fragment>
            <div className="flex justify-between mb-5">
                <div className="flex items-center w-56">
                    <h4 className="text-xl font-medium leading-5">{title}</h4>
                </div>
                {displayAgreements && <SelectedAgreementDisplay />}
                <div className="flex gap-1.5">
                    <ConfirmationTooltip
                        placement="left"
                        icon={<FontAwesomeIcon icon={faDiamondExclamation} />}
                        title={t(
                            'deposit-now-step.discard-deposit-tooltip-title'
                        )}
                        message={t('deposit-now-step.discard-deposit-tooltip')}
                        onConfirm={handleDiscard}
                        variant="warning"
                        confirmMessage={t('deposit-now-step.confirm')}
                        rejectMessage={t('deposit-now-step.continue-form')}
                        className="relative"
                    >
                        <Button variant="danger" type="button">
                            {t('deposit-now-step.discard')}
                        </Button>
                    </ConfirmationTooltip>
                    {canGoBack && (
                        <Button
                            disabled={disableBack}
                            data-testid="back-button"
                            variant="tertiary"
                            type="button"
                            onClick={onBack}
                        >
                            {t('deposit-now-step.back')}
                        </Button>
                    )}
                    {!proceedButton ? (
                        <Button
                            type="submit"
                            variant="secondary"
                            disabled={disableProceed}
                        >
                            {t('deposit-now-step.proceed')}
                        </Button>
                    ) : (
                        proceedButton
                    )}
                </div>
            </div>
            <div className="mt-2 space-y-2">
                <p className="max-w-6xl mb-6">{description}</p>
                <div className={cn('m-0', containerClasses)}>
                    {loading ? (
                        <div
                            className="flex justify-center"
                            data-testid="loading-container"
                        >
                            <FontAwesomeIcon
                                className="h-14 w-14 mt-40 mx-auto"
                                icon={faSpinner}
                                spin
                            />
                        </div>
                    ) : (
                        <div>
                            <div className="mb-2">{actions}</div>
                            <div className={cn('m-0 relative', classNames)}>
                                {children}
                            </div>

                            {submitting && (
                                <div className="absolute h-full w-full top-0 left-0 flex items-center justify-center">
                                    <FontAwesomeIcon
                                        className="h-14 w-14 mx-auto text-general-blue-blue-120"
                                        icon={faSpinner}
                                        spin
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </Fragment>
    );
};

export default DNStep;
