import { CommonProps } from '@ncc-frontend/core';
import { ReactNode, useCallback } from 'react';
import { isUndefined } from 'lodash';

import StepperStep, { StepperStepProps } from './stepper-step';
import classNames from 'classnames';

interface StepperProps<TStep = unknown> extends CommonProps {
    active: TStep;
    className?: string;
    disabled?: boolean;
    extra?: ReactNode;
    onChange?: (step: TStep) => void;
    steps: (Omit<StepperStepProps<TStep>, 'onChange' | 'active' | 'stepNum'> & {
        node: ReactNode;
    })[];
    suppressLabel?: boolean;
    userControlled?: boolean;
}

function Stepper<TStep extends string>({
    active,
    className,
    disabled,
    extra,
    onChange,
    steps,
    style,
    suppressLabel = false,
    userControlled = true
}: StepperProps<TStep>) {
    const handleChange = useCallback(
        (value: TStep) => {
            if (!userControlled) {
                return;
            }
            return onChange?.(value);
        },
        [onChange, userControlled]
    );

    return (
        <div className={classNames('flex gap-2.5', className)} style={style}>
            {steps.map(({ node, ...stepperStepProps }, index) => (
                <StepperStep<TStep>
                    {...stepperStepProps}
                    key={stepperStepProps.step}
                    stepNum={index + 1}
                    active={stepperStepProps.step === active}
                    completed={
                        index < steps.findIndex((step) => step.step === active)
                    }
                    onChange={handleChange}
                    toDo={stepperStepProps.step !== active}
                    disabled={disabled}
                    userControlled={userControlled}
                >
                    {!suppressLabel && node}
                </StepperStep>
            ))}
            {!isUndefined(extra) && (
                <div className="ml-auto flex gap-2.5">{extra}</div>
            )}
        </div>
    );
}

export default Stepper;
export type { StepperProps };
