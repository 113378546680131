import {
    Button,
    ConfirmationTooltip,
    Popover,
    ResultModal,
    SkeletonLine
} from 'lib-ui';
import {
    DEPOSIT_FREQUENCY_INITIALS_MAPPER,
    DEPOSIT_FREQUENCY_MAPPER
} from 'core/api/client-portal/model/deposit-frequency';
import { DownloadScheduleItem } from 'core/api/client-portal/autogenerated/data-contracts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ForwardedRef, forwardRef, useCallback } from 'react';
import { faDiamondExclamation } from '@fortawesome/pro-solid-svg-icons';
import { useModals } from '@ncc-frontend/core';
import { useTranslation } from 'react-i18next';

import { Lang } from 'localization';
import { capitalize } from 'lodash';
import EditScheduleModal from './edit-schedule-modal/edit-schedule-modal';
import ScheduleFrequencyTag from './schedule-frequency-tag';
import useBeautify from 'core/beautifiers/use-beautify';
import useSchedulesDelete from 'core/api/client-portal/hooks/schedules/use-schedules-delete';

interface ScheduleListItemProps {
    data?: DownloadScheduleItem;
    loading?: boolean;
}

function ScheduleListItem(
    { data, loading }: ScheduleListItemProps,
    ref: ForwardedRef<HTMLDivElement>
) {
    const { t } = useTranslation('');
    const { push } = useModals();
    const { beautifyDateLocalised, beautifyFrequency } = useBeautify();

    const { isLoading: isRemoveDisabled, mutate: requestScheduleDelete } =
        useSchedulesDelete({
            onError: () => {
                push(ResultModal, {
                    description: t('schedules.delete-modal.error-description'),
                    title: t('schedules.delete-modal.error-title')
                });
            }
        });
    const canEdit = data?.canEdit && !loading;
    const canDelete = data?.canDelete && !loading && !isRemoveDisabled;

    const remove = useCallback(() => {
        requestScheduleDelete({ query: { downloadId: data?.id } });
    }, [data?.id, requestScheduleDelete]);

    const edit = useCallback(() => {
        if (data) {
            push(EditScheduleModal, { data });
        }
    }, [data, push]);

    const localisation = localStorage!.getItem('i18nextLng') || 'en-GB';

    return (
        <div
            ref={ref}
            className="flex items-center justify-between pb-3.5 border-b border-brand-escode-neonblue-neonblue-10"
        >
            <div className="">
                <div className="relative text-brand-escode-neonblue-neonblue-100 font-medium">
                    {!!loading && <SkeletonLine absolute />}
                    {data?.agreementNumber ?? 0}
                </div>
                <div className="relative sm:whitespace-nowrap text-xs text-escode-grey-50">
                    {!!loading && <SkeletonLine absolute />}
                    {beautifyDateLocalised(
                        data?.nextDownloadAt as unknown as Date,
                        localisation as Lang
                    )}
                </div>
            </div>

            <div className="flex flex-col pt-1.5 items-end h-full select-none">
                <div className="relative h-full block 4xl:hidden">
                    {!!loading && <SkeletonLine absolute />}
                    <Popover
                        text={capitalize(
                            DEPOSIT_FREQUENCY_MAPPER[data?.frequencyType ?? 0]
                        )}
                        tooltipStyle="left-8"
                    >
                        <ScheduleFrequencyTag
                            size="s"
                            value={
                                DEPOSIT_FREQUENCY_MAPPER[
                                    data?.frequencyType ?? 0
                                ]
                            }
                        >
                            {beautifyFrequency(
                                DEPOSIT_FREQUENCY_INITIALS_MAPPER[
                                    data?.frequencyType ?? 0
                                ]
                            )}
                        </ScheduleFrequencyTag>
                    </Popover>
                </div>
                <div className="relative h-full sm:hidden 4xl:inline">
                    {!!loading && <SkeletonLine absolute />}
                    <ScheduleFrequencyTag
                        size="s"
                        value={
                            DEPOSIT_FREQUENCY_MAPPER[data?.frequencyType ?? 0]
                        }
                    >
                        {beautifyFrequency(
                            DEPOSIT_FREQUENCY_MAPPER[data?.frequencyType ?? 0]
                        )}
                    </ScheduleFrequencyTag>
                </div>
            </div>

            <div className="relative row-span-2 grid grid-cols-2 gap-1.5 ">
                {!!loading && <SkeletonLine absolute />}
                <ConfirmationTooltip
                    placement="left"
                    icon={<FontAwesomeIcon icon={faDiamondExclamation} />}
                    title={t('schedule.delete-tooltip-title')}
                    onConfirm={remove}
                    variant="warning"
                    confirmMessage={t('delete')}
                    rejectMessage={t('tooltip.reject')}
                    disabled={!canDelete}
                >
                    <Button size="s" disabled={!canDelete} variant="danger">
                        {t('delete')}
                    </Button>
                </ConfirmationTooltip>
                <Button
                    onClick={edit}
                    variant="tertiary"
                    size="s"
                    disabled={!canEdit}
                >
                    {t('amend')}
                </Button>
            </div>
        </div>
    );
}

export default forwardRef(ScheduleListItem);
export type { ScheduleListItemProps };
