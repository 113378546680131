import SiderLink, { SiderLinkProps } from './sider-link';

interface SiderSectionProps {
    collapsed?: boolean;
    links: Omit<SiderLinkProps, 'collapsed'>[];
}

function SiderSection({ collapsed, links }: SiderSectionProps) {
    return (
        <div style={{ marginBottom: '0.25rem' }}>
            <div className="flex flex-col gap-1">
                {links.map((linkProps, index) => (
                    <SiderLink
                        key={index}
                        {...linkProps}
                        collapsed={collapsed}
                    />
                ))}
            </div>
        </div>
    );
}

export default SiderSection;
export type { SiderSectionProps };
