function depositStepFormatter(lastSavedState: number) {
    switch (lastSavedState) {
        case 0:
            return 'contact-details';
        case 1:
            return 'contact-details';
        case 2:
            return 'deposit-details';
        case 3:
            return 'content';
        case 4:
            return 'review-deposit';
        case 5:
            return 'review-deposit';
        case 6:
            return 'disclaimer';
        case 7:
            return 'upload-files';
    }
}

export default depositStepFormatter;
