import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HTMLProps, PropsWithChildren, ReactNode } from 'react';
import { faRightLong } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';

interface MenuItemProps extends HTMLProps<HTMLDivElement> {
    active?: boolean;
    /** Icon visible while active */
    icon?: ReactNode;
    /** Hide icon while active */
    suppressIcon?: boolean;
}

function MenuItem({
    active,
    children,
    className,
    icon,
    suppressIcon,
    ...restProps
}: PropsWithChildren<MenuItemProps>) {
    const cssClasses = classNames(
        className,
        'p-escode-m rounded-lg',
        'flex items-center gap-2',
        'text-black/80',
        'hover:bg-brand-escode-neonblue-neonblue-05',
        'cursor-pointer transition-colors',
        {
            'bg-brand-escode-neonblue-neonblue-05 border border-brand-escode-neonblue-neonblue-80 font-medium':
                active
        }
    );

    const iconCssClasses = classNames(
        'ml-auto text-brand-escode-neonblue-neonblue-100 transition-opacity',
        {
            'opacity-0': !active
        }
    );

    return (
        <div {...restProps} className={cssClasses}>
            {children}
            {!suppressIcon && (
                <span className={iconCssClasses} data-testid="icon">
                    {icon || <FontAwesomeIcon icon={faRightLong} />}
                </span>
            )}
        </div>
    );
}

export default MenuItem;
export type { MenuItemProps };
