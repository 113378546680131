import { max } from 'lodash';
import { useCallback, useRef, useState } from 'react';

import getPlotResolution from './get-plot-resolution';
import useDebounce from 'common/use-debounce';

type Trace = {
    color: string;
    width?: number;
    x: string[];
    y: number[];
};

type PlotData = Trace[];

type ViewBoxSize = {
    height: number;
    width: number;
};

interface PlotParams {
    data: PlotData;
    /**
     * Used to calculate max visible value on the grid.
     * [0, 1].
     **/
    dataPadding: number;
    xAxisHeight: number;
    xAxisPadding: number;
    yAxisPadding: number;
}

function validData(data: PlotData, viewBoxSize: ViewBoxSize) {
    return viewBoxSize.height !== 0 && viewBoxSize.width !== 0;
}

function processData({
    data,
    dataPadding,
    viewBoxSize,
    xAxisHeight,
    xAxisPadding,
    yAxisPadding
}: PlotParams & { viewBoxSize: ViewBoxSize }) {
    function transformValue(value: number, factor: number) {
        return viewBoxSize.height - xAxisHeight - value * factor;
    }

    if (!validData(data, viewBoxSize))
        return { xAxis: [], yAxis: [], yData: [] };

    let maxY = 0;
    data.forEach((trace) => {
        maxY = max([...trace.y, maxY, 1]) ?? 1;
    });

    let numXEntries = 0;
    data.forEach((trace) => {
        numXEntries = max([trace.x.length, numXEntries]) ?? 0;
    });

    // Map values to ViewBox size.
    const factor =
        (viewBoxSize.height - xAxisHeight) / (maxY * (1 + dataPadding));
    const characterWidthInPx = 6;

    const yAxisWidth =
        characterWidthInPx * (maxY * (1 + dataPadding)).toString().length +
        yAxisPadding * 2;
    const { gap: yTickGap, numTicks: yNumTicks } = getPlotResolution(maxY, 5);

    const yAxis = new Array(yNumTicks).fill(0).map((_, index) => {
        const value = index * yTickGap;
        return {
            label: value,
            x: yAxisWidth - yAxisPadding,
            x1: yAxisWidth,
            x2: viewBoxSize.width,
            y: transformValue(value, factor)
        };
    });

    const xEntryWidth = Math.floor(
        (viewBoxSize.width - yAxisWidth) / numXEntries
    );
    const xAxis = data.map((trace) => ({
        data: trace.x.map((label, index) => ({
            label,
            rect: {
                height: viewBoxSize.height,
                width: xEntryWidth,
                x: xEntryWidth * index + yAxisWidth,
                y: 0
            },
            x: xEntryWidth * index + xEntryWidth / 2 + yAxisWidth,
            y: viewBoxSize.height - xAxisHeight + xAxisPadding
        }))
    }));

    const yData = data.map((trace, traceIndex) => ({
        color: trace.color,
        data: trace.y.map((value, index) => {
            const centerOffset =
                (xEntryWidth / (data.length + 1)) * (traceIndex + 1);
            const labelPaddingX = 16;
            const labelMargin = 10;
            const labelWidth =
                value.toString().length * characterWidthInPx +
                labelPaddingX +
                2;
            const labelHeight = 24;
            const x = index * xEntryWidth + centerOffset + yAxisWidth;
            const y2 = transformValue(value, factor);
            let labelX1 = x;
            if (traceIndex % 2 === 0) {
                // Handle right clipping
                labelX1 -= labelWidth + labelMargin;
                if (labelX1 < yAxisWidth) labelX1 = yAxisWidth;
            } else {
                // Handle left clipping
                labelX1 += labelMargin;
                if (labelX1 + labelWidth > viewBoxSize.width)
                    labelX1 = viewBoxSize.width - labelWidth;
            }

            return {
                label: {
                    height: labelHeight,
                    value,
                    width: labelWidth,
                    x: labelX1,
                    y: y2 - labelHeight / 2
                },
                x,
                y1: transformValue(0, factor),
                y2
            };
        }),
        width: trace.width ?? 8
    }));

    return { xAxis, yAxis, yData };
}

function useBarPlot(params: PlotParams) {
    const containerRef = useRef<HTMLElement | null>(null);
    const [viewBoxSize, setViewBowSize] = useState({ height: 0, width: 0 });
    const data = processData({ ...params, viewBoxSize });

    const debounce = useDebounce(300);

    const { current: update } = useRef(() => {
        debounce(() => {
            if (!containerRef.current) return;

            const { height, width } =
                containerRef.current.getBoundingClientRect();
            setViewBowSize({ height, width });
        });
    });

    const { current: onResize } = useRef(() => {
        update();
    });

    const setRef = useCallback(
        (ref: HTMLElement | null) => {
            containerRef.current = ref;
            if (!ref) return;
            update();

            ref.removeEventListener('resize', onResize);
            ref.addEventListener('resize', onResize);

            window.removeEventListener('resize', onResize);
            window.addEventListener('resize', onResize);
        },
        [onResize, update]
    );

    return { ...data, setRef, viewBoxSize };
}

export default useBarPlot;
export type { PlotData, PlotParams, Trace, ViewBoxSize };
