import { memo } from 'react';

interface TableNoRowsOverlayProps {
    message?: string;
}

function TableNoRowsOverlay({
    message = 'No Rows To Show'
}: TableNoRowsOverlayProps) {
    return <div>{message}</div>;
}
export default memo(TableNoRowsOverlay);
export type { TableNoRowsOverlayProps };
