import { CommonProps, TailwindStyle } from '@ncc-frontend/core';
import { DatePickerWizard, DatePickerWizardProps, Wizard } from 'lib-ui';
import { ForwardedRef, forwardRef, useCallback, useRef } from 'react';

type RangeSelectEventHandler = (range: [Date, Date]) => void;

interface ExportSelectionRangeStepProps extends CommonProps {
    onSelect: RangeSelectEventHandler;
}

function ExportSelectionRangeStep(
    { className, onSelect }: ExportSelectionRangeStepProps,
    ref: ForwardedRef<HTMLDivElement>
) {
    const handleSelect = useCallback<DatePickerWizardProps['onSelect']>(
        (range) => {
            onSelect(range as [Date, Date]);
        },
        [onSelect]
    );

    const { current: datePickerTailwindStyle } = useRef<TailwindStyle>({
        border: '',
        padding: ''
    });

    return (
        <Wizard.Step ref={ref} className={className}>
            <DatePickerWizard
                onSelect={handleSelect}
                range
                constrain="past"
                tailwindStyle={datePickerTailwindStyle}
            />
        </Wizard.Step>
    );
}

export default forwardRef(ExportSelectionRangeStep);
export type { ExportSelectionRangeStepProps, RangeSelectEventHandler };
