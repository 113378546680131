import { MouseEventHandler, PropsWithChildren, useCallback } from 'react';
import cn from 'core/utils/cn';

interface StepperStepProps<TStep = unknown> {
    active?: boolean;
    completed?: boolean;
    disabled?: boolean;
    error?: boolean;
    onChange?: (step: TStep) => void;
    step: TStep;
    stepNum: number;
    toDo?: boolean;
    userControlled?: boolean;
}

function StepperStep<TStep = unknown>({
    active,
    children,
    completed,
    disabled,
    error,
    onChange,
    step,
    stepNum,
    toDo,
    userControlled,
    ...restProps
}: PropsWithChildren<StepperStepProps<TStep>>) {
    const onClick = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
        onChange?.(step);
    }, [onChange, step]);

    return (
        <button
            {...restProps}
            type="button"
            onClick={!disabled ? onClick : undefined}
            className={cn(
                'flex items-center gap-2.5',
                disabled && userControlled && 'cursor-not-allowed',
                !userControlled && 'cursor-auto'
            )}
        >
            <div
                className={cn(
                    'flex items-center justify-center rounded-full w-8 h-8 text-lg transition-colors',
                    !active &&
                        !error &&
                        'bg-brand-escode-neonblue-neonblue-10 text-brand-escode-neonblue-neonblue-80',
                    active &&
                        !error &&
                        'bg-brand-escode-neonblue-neonblue-120 text-ncc-white',
                    disabled &&
                        userControlled &&
                        'bg-escode-grey-50 text-ncc-white',
                    error && 'bg-general-red-red-100 text-ncc-white',
                    toDo && 'bg-transparent text-general-grey-grey-90/50',
                    completed &&
                        'bg-brand-escode-neonblue-neonblue-10 text-brand-escode-neonblue-neonblue-120'
                )}
            >
                {stepNum}
            </div>
            {children && (
                <div
                    className={cn(
                        'transition-colors',
                        !active &&
                            !disabled &&
                            !error &&
                            'general-grey-grey-10',
                        disabled && !userControlled && 'text-escode-grey-50',
                        active && 'font-medium',
                        active &&
                            !disabled &&
                            !error &&
                            'text-general-grey-grey-90',
                        error && 'text-general-red-red-100',
                        toDo && 'text-general-grey-grey-90/50',
                        completed && 'text-general-grey-grey-90'
                    )}
                >
                    {children}
                </div>
            )}
        </button>
    );
}

export default StepperStep;
export type { StepperStepProps };
