import React, { PropsWithChildren } from 'react';
import cn from 'core/utils/cn';

type PopoverProps = {
    className?: string;
    containerStyle?: string;
    current?: string;
    icon?: string;
    origin?: 'origin-left' | 'origin-right';
    text?: string;
    textPosition?: string;
    textSelection?: boolean;
    tooltipStyle?: string;
};

function Popover({
    children,
    className,
    containerStyle,
    current,
    icon,
    origin = 'origin-left',
    text,
    textPosition = 'right',
    textSelection = false,
    tooltipStyle,
    ...restProps
}: PropsWithChildren<PopoverProps>) {
    const containerClasses = cn('group', containerStyle);
    const tooltipClasses = cn(
        'absolute w-auto min-w-max scale-0 rounded-md bg-gray-900 p-2 text-xs font-bold text-white shadow-md transition-all duration-100 group-hover:scale-100 z-[1000]',
        origin,
        tooltipStyle,
        // textPosition === 'right' ? 'left-8' : 'right-4',
        textSelection ? 'select-text' : 'select-none'
    );
    return (
        <div className={cn(containerClasses)} {...restProps}>
            {children}
            {text && <span className={tooltipClasses}>{text}</span>}
        </div>
    );
}

export default Popover;
