import { CSSProperties, PropsWithChildren, useMemo } from 'react';
import {
    ToggleTrack as CoreToggleTrack,
    ToggleTrackProps as CoreToggleTrackProps,
    TailwindStyle
} from '@ncc-frontend/core';
import { merge } from 'lodash';

interface ToggleTrackProps extends CoreToggleTrackProps {
    warning?: boolean;
}

function ToggleTrack({
    checked,
    children,
    style,
    tailwindStyle,
    warning = false,
    ...restProps
}: PropsWithChildren<ToggleTrackProps>) {
    const mergedTailwindStyle = useMemo(
        () =>
            merge<TailwindStyle, TailwindStyle | undefined>(
                {
                    background: checked
                        ? 'bg-brand-escode-neonblue-neonblue-100'
                        : warning
                        ? 'bg-general-orange-orange-100-100'
                        : 'bg-general-red-red-100',
                    cursor: 'cursor-pointer',
                    transition: 'transition-all'
                },
                tailwindStyle
            ),
        [checked, tailwindStyle, warning]
    );

    const mergedStyle = useMemo<CSSProperties>(
        () =>
            merge<CSSProperties, CSSProperties | undefined>(
                {
                    height: '1.563rem',
                    width: '3.063rem'
                },
                style
            ),
        [style]
    );

    return (
        <CoreToggleTrack
            {...restProps}
            tailwindStyle={mergedTailwindStyle}
            checked={checked}
            style={mergedStyle}
        >
            {children}
        </CoreToggleTrack>
    );
}

export default ToggleTrack;
export type { ToggleTrackProps };
