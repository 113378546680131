import {
    DepositItem,
    GetDepositsResponse
} from '../../autogenerated/data-contracts';

function getAgreementsDepositsMockData(page = 1): GetDepositsResponse {
    const totalRecords = 150;

    if (page === 1) {
        return {
            agreementPartyType: 'Owner',
            deposits: [
                {
                    addressLine1: 'addressLine1',
                    addressLine2: 'addressLine2',
                    addressLine3: 'addressLine3',
                    agreementId: 1,
                    companyName: 'companyName',
                    contactName: 'contactName',
                    depositDate: '2022-10-10T08:29:59Z',
                    depositId: 1,
                    depositOwner: 'depositOwner',
                    emailAddress: 'emailAddress',
                    escrowDepositStatus: 0,
                    isReadOnly: false,
                    isScheduledDeposit: false,
                    isSftp: false,
                    isSourceControl: false,
                    jobTitle: 'jobTitle',
                    lastSavedState: 0,
                    mediaCheck: false,
                    postCode: 'postCode',
                    softwareOwnerCompanyName: 'softwareOwnerCompanyName',
                    softwarePackageName: '1 softwarePackageName',
                    telephoneNumber: 'telephoneNumber',
                    testingLevel: 0,
                    testingLevelDescription: 'testingLevelDescription',
                    versionOrReleaseNumber: 'versionOrReleaseNumber'
                },
                {
                    addressLine1: 'addressLine1',
                    addressLine2: 'addressLine2',
                    addressLine3: 'addressLine3',
                    agreementId: 1,
                    companyName: 'companyName',
                    contactName: 'contactName',
                    depositDate: '2022-10-10T08:29:59Z',
                    depositId: 2,
                    depositOwner: 'depositOwner',
                    emailAddress: 'emailAddress',
                    escrowDepositStatus: 12,
                    isReadOnly: false,
                    isScheduledDeposit: false,
                    isSftp: false,
                    isSourceControl: false,
                    jobTitle: 'jobTitle',
                    lastSavedState: 0,
                    mediaCheck: false,
                    postCode: 'postCode',
                    softwareOwnerCompanyName: 'softwareOwnerCompanyName',
                    softwarePackageName: '2 softwarePackageName',
                    telephoneNumber: 'telephoneNumber',
                    testingLevel: 0,
                    testingLevelDescription: 'testingLevelDescription',
                    versionOrReleaseNumber: 'versionOrReleaseNumber'
                },
                {
                    addressLine1: 'addressLine1',
                    addressLine2: 'addressLine2',
                    addressLine3: 'addressLine3',
                    agreementId: 1,
                    companyName: 'companyName',
                    contactName: 'contactName',
                    depositDate: '2022-10-10T08:29:59Z',
                    depositId: 3,
                    depositOwner: 'depositOwner',
                    emailAddress: 'emailAddress',
                    escrowDepositStatus: 2,
                    isReadOnly: false,
                    isScheduledDeposit: false,
                    isSftp: false,
                    isSourceControl: false,
                    jobTitle: 'jobTitle',
                    lastSavedState: 0,
                    mediaCheck: false,
                    postCode: 'postCode',
                    softwareOwnerCompanyName: 'softwareOwnerCompanyName',
                    softwarePackageName: '3 softwarePackageName',
                    telephoneNumber: 'telephoneNumber',
                    testingLevel: 0,
                    testingLevelDescription: 'testingLevelDescription',
                    versionOrReleaseNumber: 'versionOrReleaseNumber'
                },
                {
                    addressLine1: 'addressLine1',
                    addressLine2: 'addressLine2',
                    addressLine3: 'addressLine3',
                    agreementId: 1,
                    companyName: 'companyName',
                    contactName: 'contactName',
                    depositDate: '2022-10-10T08:29:59Z',
                    depositId: 4,
                    depositOwner: 'depositOwner',
                    emailAddress: 'emailAddress',
                    escrowDepositStatus: 14,
                    isReadOnly: false,
                    isScheduledDeposit: false,
                    isSftp: false,
                    isSourceControl: false,
                    jobTitle: 'jobTitle',
                    lastSavedState: 0,
                    mediaCheck: false,
                    postCode: 'postCode',
                    softwareOwnerCompanyName: 'softwareOwnerCompanyName',
                    softwarePackageName: '4 softwarePackageName',
                    telephoneNumber: 'telephoneNumber',
                    testingLevel: 0,
                    testingLevelDescription: 'testingLevelDescription',
                    versionOrReleaseNumber: 'versionOrReleaseNumber'
                },
                ...new Array(50 - 4)
                    .fill({
                        addressLine1: 'addressLine1',
                        addressLine2: 'addressLine2',
                        addressLine3: 'addressLine3',
                        agreementId: 1,
                        companyName: 'companyName',
                        contactName: 'contactName',
                        depositDate: '2022-10-10T08:29:59Z',
                        depositId: 1,
                        depositOwner: 'depositOwner',
                        emailAddress: 'emailAddress',
                        escrowDepositStatus: 14,
                        isReadOnly: false,
                        isScheduledDeposit: false,
                        isSftp: false,
                        isSourceControl: false,
                        jobTitle: 'jobTitle',
                        lastSavedState: 0,
                        mediaCheck: false,
                        postCode: 'postCode',
                        softwareOwnerCompanyName: 'softwareOwnerCompanyName',
                        softwarePackageName: 'softwarePackageName',
                        telephoneNumber: 'telephoneNumber',
                        testingLevel: 0,
                        testingLevelDescription: 'testingLevelDescription',
                        versionOrReleaseNumber: 'versionOrReleaseNumber'
                    } as DepositItem)
                    .map((deposit, index) => ({
                        ...deposit,
                        depositId: index + 5,
                        softwarePackageName: `${index + 5} softwarePackageName`
                    }))
            ],
            errors: {},
            success: true,
            totalRecords
        };
    }

    if (page === 2) {
        return {
            agreementPartyType: 'Owner',
            deposits: new Array(50)
                .fill({
                    addressLine1: 'addressLine1',
                    addressLine2: 'addressLine2',
                    addressLine3: 'addressLine3',
                    agreementId: 1,
                    companyName: 'companyName',
                    contactName: 'contactName',
                    depositDate: '2022-10-10T08:29:59Z',
                    depositId: 1,
                    depositOwner: 'depositOwner',
                    emailAddress: 'emailAddress',
                    escrowDepositStatus: 14,
                    isReadOnly: false,
                    isScheduledDeposit: false,
                    isSftp: false,
                    isSourceControl: false,
                    jobTitle: 'jobTitle',
                    lastSavedState: 0,
                    mediaCheck: false,
                    postCode: 'postCode',
                    softwareOwnerCompanyName: 'softwareOwnerCompanyName',
                    softwarePackageName: 'softwarePackageName',
                    telephoneNumber: 'telephoneNumber',
                    testingLevel: 0,
                    testingLevelDescription: 'testingLevelDescription',
                    versionOrReleaseNumber: 'versionOrReleaseNumber'
                } as DepositItem)
                .map((deposit, index) => ({
                    ...deposit,
                    depositId: index + 51,
                    softwarePackageName: `${index + 51} softwarePackageName`
                })),
            errors: {},
            success: true,
            totalRecords
        };
    }

    return {
        agreementPartyType: 'Owner',
        deposits: new Array(50)
            .fill({
                addressLine1: 'addressLine1',
                addressLine2: 'addressLine2',
                addressLine3: 'addressLine3',
                agreementId: 1,
                companyName: 'companyName',
                contactName: 'contactName',
                depositDate: '2022-10-10T08:29:59Z',
                depositId: 1,
                depositOwner: 'depositOwner',
                emailAddress: 'emailAddress',
                escrowDepositStatus: 14,
                isReadOnly: false,
                isScheduledDeposit: false,
                isSftp: false,
                isSourceControl: false,
                jobTitle: 'jobTitle',
                lastSavedState: 0,
                mediaCheck: false,
                postCode: 'postCode',
                softwareOwnerCompanyName: 'softwareOwnerCompanyName',
                softwarePackageName: 'softwarePackageName',
                telephoneNumber: 'telephoneNumber',
                testingLevel: 0,
                testingLevelDescription: 'testingLevelDescription',
                versionOrReleaseNumber: 'versionOrReleaseNumber'
            } as DepositItem)
            .map((deposit, index) => ({
                ...deposit,
                depositId: index + 101,
                softwarePackageName: `${index + 101} softwarePackageName`
            })),
        errors: {},
        success: true,
        totalRecords
    };
}

export { getAgreementsDepositsMockData };
