import { SourceControlConnectionsGridContext } from './source-control-connections-grid/source-control-connections-grid-context';
import { useCallback, useMemo } from 'react';
import { useModals } from '@ncc-frontend/core';
import { useTranslation } from 'react-i18next';

import SourceControlConnectionsGrid from './source-control-connections-grid/source-control-connections-grid';
import useBeautify from 'core/beautifiers/use-beautify';
import useProvidersDelete from 'core/api/client-portal/hooks/providers/use-providers-delete';
import useProvidersList from 'core/api/client-portal/hooks/providers/use-providers-list';

function SourceControlConnections() {
    const { t } = useTranslation();
    const { beautifyProviderType } = useBeautify();
    const { push } = useModals();
    const {
        data: providers,
        error,
        isFetching: isFetchingProviders,
        isLoading: isLoadingProviders,
        refetch
    } = useProvidersList();

    const { isLoading: isDeletingProvider, mutate: deleteProvider } =
        useProvidersDelete();

    const context = useMemo<SourceControlConnectionsGridContext>(
        () => ({ beautifyProviderType, deleteProvider, push, t }),
        [beautifyProviderType, deleteProvider, push, t]
    );

    const reload = useCallback(() => {
        refetch();
    }, [refetch]);

    return (
        <SourceControlConnectionsGrid
            context={context}
            data={providers?.providers}
            loading={
                isLoadingProviders || isFetchingProviders || isDeletingProvider
            }
            error={error?.response.status}
            onReload={reload}
        />
    );
}

export default SourceControlConnections;
