import * as yup from 'yup';
import { AddAgreementParty } from 'core/api/client-portal/autogenerated/data-contracts';
import {
    CheckboxField,
    InputField,
    Modal,
    ResultModal,
    SubmitButton,
    SubmitButtonRef,
    TextareaField
} from 'lib-ui';
import { FormProvider, Path, SubmitHandler, useForm } from 'react-hook-form';
import { ReactElement, useCallback, useRef } from 'react';
import { TailwindStyle, useModals } from '@ncc-frontend/core';
import { phoneRegExp } from 'core/validators/phone';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import useAgreementsAddLicensee from 'core/api/client-portal/hooks/agreements/use-agreements-add-agreement-party';
import useBackendValidation from 'common/use-backend-validation';

const CHECKBOX_TAILWIND_STYLE: TailwindStyle = {
    items: 'items-start'
};

type AddLicenseeFormValues = AddAgreementParty & {
    accepted: boolean;
};

interface AddLicenseeModalProps {
    agreementId: number;
}

function AddLicenseeModal({
    agreementId
}: AddLicenseeModalProps): ReactElement | null {
    const { t } = useTranslation();
    const { pop, push } = useModals();
    const submitButton = useRef<SubmitButtonRef>(null);

    const schema = yup.object({
        accepted: yup.boolean().isTrue(),
        comments: yup.string().max(
            3000,
            t('form.validation.max-length', {
                field: t('licensee.add.comments'),
                length: 3000
            })
        ),
        company: yup
            .string()
            .required(
                t('form.validation.required', {
                    field: t('licensee.add.company')
                })
            )
            .max(
                150,
                t('form.validation.max-length', {
                    field: t('licensee.add.company'),
                    length: 600
                })
            ),
        emailAddress: yup
            .string()
            .required(
                t('form.validation.required', {
                    field: t('licensee.add.email')
                })
            )
            .email(
                t('form.validation.email', {
                    field: t('licensee.add.email')
                })
            ),
        name: yup
            .string()
            .required(
                t('form.validation.required', {
                    field: t('licensee.add.name')
                })
            )
            .max(
                150,
                t('form.validation.max-length', {
                    field: t('licensee.add.name'),
                    length: 600
                })
            ),
        phone: yup.string().matches(
            phoneRegExp,
            t('form.validation.phone', {
                field: t('licensee.add.phone')
            })
        )
    });
    const methods = useForm<AddLicenseeFormValues>({
        defaultValues: {
            accepted: false,
            comments: '',
            company: '',
            emailAddress: '',
            name: '',
            phone: ''
        },
        mode: 'onTouched',
        resolver: yupResolver(schema)
    });

    const {
        error,
        isLoading,
        mutate: addAgreementParty
    } = useAgreementsAddLicensee<{
        errors: Record<Path<AddLicenseeFormValues>, string[]>;
    }>({
        onError: () => {
            push(ResultModal, {
                description: t('result.error-description'),
                title: t('licensee.add.error-title')
            });
        },
        onSuccess: () => {
            pop();
            push(ResultModal, {
                description: t('licensee.add.success-description'),
                title: t('licensee.add.success-title')
            });
        }
    });

    const submit = useCallback<SubmitHandler<AddLicenseeFormValues>>(
        (formData) => {
            // API throws validation errors for optional fields if they are present on the request.
            const { accepted, comments, phone, ...requiredFields } = formData;
            const data: AddAgreementParty = requiredFields;
            if (phone) data.phone = phone;
            if (comments) data.comments = comments;

            addAgreementParty({ data, id: agreementId });
        },
        [addAgreementParty, agreementId]
    );

    const onInvalid = useCallback(() => {
        submitButton.current?.shake();
    }, []);

    useBackendValidation(error?.response?.data?.errors, methods.setError);

    return (
        <Modal className="max-w-md">
            <Modal.Header>{t('licensee.add.title')}</Modal.Header>
            <Modal.Body>
                <FormProvider {...methods}>
                    <form
                        onSubmit={methods.handleSubmit(submit, onInvalid)}
                        className="flex flex-col gap-2.5"
                    >
                        <InputField
                            label={t('licensee.add.name')}
                            name="name"
                            placeholder="John Smith"
                            required
                        />
                        <InputField
                            label={t('licensee.add.email')}
                            name="emailAddress"
                            placeholder="johnsmith@example.com"
                            required
                        />
                        <InputField
                            label={t('licensee.add.company')}
                            name="company"
                            placeholder={t('licensee.add.company-placeholder')}
                            required
                        />
                        <InputField
                            label={t('licensee.add.phone')}
                            name="phone"
                            placeholder="+44000000000"
                        />
                        <TextareaField
                            label={t('licensee.add.comments')}
                            name="comments"
                            placeholder={t('licensee.add.phone-placeholder')}
                        />
                        <CheckboxField
                            name="accepted"
                            label={t('licensee.add.confirmation')}
                            tailwindStyle={CHECKBOX_TAILWIND_STYLE}
                            required
                        />
                        <SubmitButton
                            ref={submitButton}
                            loading={isLoading}
                            disabled={!methods.formState.isDirty}
                        >
                            {t('licensee.add.confirm')}
                        </SubmitButton>
                    </form>
                </FormProvider>
            </Modal.Body>
        </Modal>
    );
}

export default AddLicenseeModal;
export type { AddLicenseeFormValues, AddLicenseeModalProps };
