interface ValueTooltipProps {
    color: string;
    height: number;
    hidden: boolean;
    value: number;
    width: number;
    x: number;
    y: number;
}

function ValueTooltip({
    color,
    height,
    hidden,
    value,
    width,
    x,
    y
}: ValueTooltipProps) {
    return (
        <>
            <rect
                x={x}
                y={y}
                width={width}
                height={height}
                fill="#fff"
                rx={height / 2}
                filter="drop-shadow(0px 4px 20px rgba(2, 113, 127, 0.15))"
                className="transition-opacity duration-300"
                opacity={hidden ? 0 : 1}
            />
            <text
                x={x + width / 2}
                y={y + height / 2 + 1}
                textAnchor="middle"
                dominantBaseline="middle"
                fill={color}
                className="text-xs transition-opacity duration-300"
                opacity={hidden ? 0 : 1}
            >
                {value}
            </text>
        </>
    );
}

export default ValueTooltip;
export type { ValueTooltipProps };
