import { isUndefined } from 'lodash';
import { useRef } from 'react';

interface AnimatedNumberParams {
    defaultValue?: number;
    /** Duration in ms */
    duration?: number;
    formatter?: (value: number) => string;
}

function useAnimatedNumber<TElement extends HTMLElement = HTMLElement>({
    defaultValue = 0,
    duration = 500,
    formatter
}: AnimatedNumberParams = {}) {
    const intervalRef = useRef<NodeJS.Timer | null>(null);
    const elementRef = useRef<TElement | null>(null);

    const valueRef = useRef(defaultValue);

    const updateValue = (to: number) => {
        const from = valueRef.current;
        const direction = to > from ? 1 : -1;

        const tickTime = duration / Math.abs(to - from);

        if (intervalRef.current) clearInterval(intervalRef.current);
        intervalRef.current = setInterval(() => {
            if (!elementRef.current) return;

            elementRef.current.innerHTML = isUndefined(formatter)
                ? `${valueRef.current}`
                : formatter(valueRef.current);
            if (valueRef.current !== to) {
                valueRef.current += direction;
            } else if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        }, tickTime);
    };

    return {
        ref: elementRef,
        updateValue,
        value: valueRef.current
    };
}

export default useAnimatedNumber;
export type { AnimatedNumberParams };
