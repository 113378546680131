import { TailwindStyle } from '@ncc-frontend/core';
import { merge } from 'lodash';
import { useFormContext, useWatch } from 'react-hook-form';
import { useId, useMemo } from 'react';
import classNames from 'classnames';

import Checkbox, { CheckboxProps } from '../element/checkbox';
import Field, { FieldProps } from './field';

interface CheckboxFieldProps
    extends Omit<FieldProps, 'error'>,
        Omit<CheckboxProps, 'name' | 'label' | 'children' | 'error'> {
    elementClassName?: string;
    suppressError?: boolean;
}

function CheckboxField({
    className,
    disabled,
    elementClassName,
    label,
    name,
    orientation = 'reversed-horizontal',
    readOnly,
    required,
    suppressError = false,
    tailwindStyle,
    value,
    ...restProps
}: CheckboxFieldProps) {
    const {
        formState: { errors },
        register
    } = useFormContext();

    const formValue = useWatch({ name });
    const checked =
        typeof formValue === 'object' &&
        ['string', 'number'].includes(typeof value)
            ? formValue.includes(value?.toString())
            : formValue;

    const id = useId();

    const mergedTailwindStyle = useMemo<TailwindStyle>(
        () =>
            merge(
                {
                    gap: 'gap-2',
                    items: 'items-center'
                },
                tailwindStyle
            ),
        [tailwindStyle]
    );

    return (
        <Field
            name={name}
            label={label}
            orientation={orientation}
            readOnly={readOnly}
            disabled={disabled}
            id={id}
            tailwindStyle={mergedTailwindStyle}
            className={className}
            required={required}
            error={!suppressError && !!errors[name] ? label : undefined}
        >
            <Checkbox
                {...restProps}
                id={id}
                className={classNames(elementClassName)}
                value={value}
                {...register(name)}
                error={!suppressError && !!errors[name]}
                checked={checked}
                readOnly={readOnly}
                disabled={disabled}
            />
        </Field>
    );
}

export default CheckboxField;
export type { CheckboxFieldProps };
