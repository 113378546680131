import { CommonProps } from '@ncc-frontend/core';
import { PropsWithChildren } from 'react';
import classNames from 'classnames';

interface AppSiderProps extends CommonProps {}

function AppSider({
    children,
    className,
    ...restProps
}: PropsWithChildren<AppSiderProps>) {
    const cssClasses = classNames(
        className,
        'fixed top-0 h-screen',
        'flex flex-col overflow-y-auto',
        'bg-brand-escode-neonblue-neonblue-140 transition-all'
    );

    return (
        <div {...restProps} className={cssClasses}>
            {children}
        </div>
    );
}

export default AppSider;
export type { AppSiderProps };
