import {
    ClientContactsResponseViewModel,
    ContactViewModel
} from 'core/api/client-portal/autogenerated/data-contracts';
import {
    ColDef,
    FILTER_IN_LIST_PARAMS,
    TableWidget,
    TableWidgetProps
} from 'lib-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MouseEventHandler, useCallback, useMemo } from 'react';
import { faMonitorWaveform } from '@fortawesome/pro-regular-svg-icons';
import { useModals } from '@ncc-frontend/core';
import { useTranslation } from 'react-i18next';

import { ContactsTableContext } from './contacts-table-context';
import ContactDetailsModal from './contact-details/contact-details-modal';
import ContactStatusCellRenderer, {
    contactStatusValueGetter,
    getContactsStatusOptions
} from './contact-status-cell-renderer';
import contactActionsCellRenderer from './contact-actions-cell-renderer';

interface ContactsTableProps {
    data: ClientContactsResponseViewModel | undefined;
    error?: number;
    loading: boolean;
    onReload?: MouseEventHandler<HTMLButtonElement>;
}

function ContactsTable({ data, error, loading, onReload }: ContactsTableProps) {
    const { t } = useTranslation();
    const { push } = useModals();

    const id = 'contacts-table';

    const columnDefs = useMemo<ColDef<ContactViewModel>[]>(
        () => [
            {
                cellRenderer: ContactStatusCellRenderer,
                colId: 'status',
                filter: true,
                filterParams: FILTER_IN_LIST_PARAMS,
                headerName: t('contacts.header.status'),
                valueGetter: contactStatusValueGetter
            },
            {
                field: 'title',
                headerName: t('contacts.header.title')
            },
            {
                field: 'firstName',
                headerName: t('contacts.header.firstName')
            },
            {
                field: 'lastName',
                headerName: t('contacts.header.lastName')
            },
            {
                field: 'jobTitle',
                headerName: t('contacts.header.jobTitle')
            },
            {
                field: 'telephone',
                headerName: t('contacts.header.telephone')
            },
            {
                field: 'email',
                headerName: t('contacts.header.email')
            },
            {
                field: 'userName',
                headerName: t('contacts.header.username')
            },
            {
                cellRenderer: contactActionsCellRenderer,
                headerName: t('contacts.header.actions'),
                sortable: false
            }
        ],
        [t]
    );

    const filterableFields = useMemo<
        TableWidgetProps<false>['filterableFields']
    >(
        () => [
            {
                colId: 'status',
                filterType: 'text',
                icon: <FontAwesomeIcon icon={faMonitorWaveform} />,
                mode: 'inList',
                name: t('contacts.header.status'),
                options: getContactsStatusOptions({ t }),
                title: t('contacts.filter.status')
            }
        ],
        [t]
    );

    const handleRowClick = useCallback<
        NonNullable<TableWidgetProps<false>['onRowClicked']>
    >(
        ({ data }) => {
            if (data) push(ContactDetailsModal, { data });
        },
        [push]
    );

    const tableContext = useMemo<ContactsTableContext>(
        () => ({
            t
        }),
        [t]
    );

    const noRowsOverlayComponentParams = useMemo<
        TableWidgetProps<false>['noRowsOverlayComponentParams']
    >(() => ({ message: t('contacts.no-data') }), [t]);

    return (
        <TableWidget
            id={id}
            context={tableContext}
            suppressSaveFilter
            title={t('contacts.title')}
            columnDefs={columnDefs}
            filterableFields={filterableFields}
            rowData={data?.contacts}
            loading={loading}
            rowSelection="single"
            onRowClicked={handleRowClick}
            noRowsOverlayComponentParams={noRowsOverlayComponentParams}
            error={error}
            onReload={onReload}
        />
    );
}

export default ContactsTable;
export type { ContactsTableProps };
