import { useCallback, useEffect, useRef } from 'react';

function useInterval(cb: () => void, ms: number) {
    const intervalRef = useRef<NodeJS.Timer>();
    const cbRef = useRef(cb);
    cbRef.current = cb;

    const set = useCallback(() => {
        intervalRef.current = setInterval(cbRef.current, ms);
    }, [ms]);

    const reset = useCallback(() => {
        if (intervalRef.current) clearInterval(intervalRef.current);

        set();
    }, [set]);

    useEffect(() => {
        set();
        return () => clearInterval(intervalRef.current);
    }, [ms, set]);

    return { reset };
}

export default useInterval;
