import { CommonProps } from '@ncc-frontend/core';
import { PropsWithChildren } from 'react';
import classNames from 'classnames';

interface FilterStepBodyProps extends CommonProps {}

function FilterStepBody({
    children,
    className,
    ...restProps
}: PropsWithChildren<FilterStepBodyProps>) {
    const cssClasses = classNames('max-h-144 overflow-auto', className);

    return (
        <div {...restProps} className={cssClasses}>
            {children}
        </div>
    );
}

export default FilterStepBody;
export type { FilterStepBodyProps };
