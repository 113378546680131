import { DepositDetails1 } from '../../autogenerated/data-contracts';

const useDepositPartialMockData: DepositDetails1 = {
    applicationCategory: '',
    approximateSizeOfMediaContent: 0,
    contentsOfThisDeposit: {
        configurationFiles: false,
        database: false,
        development: false,
        environmentOrBuildTools: false,
        otherData: false,
        sourceCode: false,
        testEnvironment: false
    },
    fileTypes: [],
    financialServicesCategory: '',
    hardwareUsedToCreateTheDeposit: '',
    isForAMediaFileReview: false,
    isForAScheduledVerificationExercise: false,
    isForASpecificLicensee: 1,
    licenseesThatRequireThisDeposit: [],
    operatingSystemUsed: '',
    otherFileTypes: '',
    softwareOwnerCompanyName: '',
    softwarePackageName: '',
    versionOrReleaseNumber: ''
};

export { useDepositPartialMockData };
