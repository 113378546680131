/* eslint-disable sort-keys-fix/sort-keys-fix */
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { InputField, RadioGroupField, Section, TextareaField } from 'lib-ui';

import { radioOptions } from '../deposit-details-step/deposit-details-options';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import DNStep from '../deposit-now-step/dn-step';
import React, { useCallback, useEffect } from 'react';
import schema from './content-step-schema';
import toastFunctions from 'lib-ui/toast/Toast';
import useDepositDetailsV2 from 'core/api/client-portal-v2/hooks/deposits/use-deposit-details-v2';
import useDepositMoreDetailsPartialUpdateV2 from 'core/api/client-portal-v2/hooks/deposits/use-deposit-more-details-partial-update-v2';
import yesOrNo from 'common/yes-or-no';

type NewFormData = {
    compressionMethod: string;
    containsPersonalData: 'yes' | 'no' | null;
    documentation: string;
    encryptMethod: string;
    exportControlsDetails: string;
    isCompressed: 'yes' | 'no' | null;
    isEncrypted: 'yes' | 'no' | null;
    isEncryptedConfirmation: 'yes' | 'no' | null;
    isPasswordRequired: 'yes' | 'no' | null;
    isReplacementOfPreviousDeposit: 'yes' | 'no' | null;
    isSubjectToAnyExportControls: 'yes' | 'no' | null;
    password: string;
    personalDataDetails: string;
};

const ContentStep = () => {
    const { depositId } = useParams();
    const { t } = useTranslation();
    const goTo = useNavigate();

    const { data, isFetching } = useDepositDetailsV2(
        depositId as unknown as number
    );

    const { isLoading, mutate: updateDeposit } =
        useDepositMoreDetailsPartialUpdateV2(depositId as unknown as number, {
            onError() {
                toastFunctions.error(t('toast.error'));
            },
            onSuccess() {
                toastFunctions.success(t('toast.success'));
                setTimeout(() => {
                    goTo(`/deposit-now/${depositId}/review-deposit`);
                }, 1500);
            }
        });

    const methods = useForm<NewFormData>({
        mode: 'all',
        reValidateMode: 'onChange',
        resolver: yupResolver(schema),
        values: {
            isCompressed: yesOrNo(data?.moreDetails.isCompressed),
            compressionMethod: data?.moreDetails.compressionMethod || '',
            isSubjectToAnyExportControls: yesOrNo(
                data?.moreDetails.isSubjectToAnyExportControls
            ),
            exportControlsDetails:
                data?.moreDetails.exportControlsDetails || '',
            containsPersonalData: yesOrNo(
                data?.moreDetails.containsPersonalData
            ),
            personalDataDetails: data?.moreDetails.personalDataDetails || '',
            isEncrypted: yesOrNo(data?.moreDetails.isEncrypted),
            encryptMethod: data?.moreDetails.encryptMethod || '',
            isEncryptedConfirmation: data?.moreDetails.isEncrypted,
            isPasswordRequired: yesOrNo(data?.moreDetails.isPasswordRequired),
            password: data?.moreDetails.password || '',
            isReplacementOfPreviousDeposit: yesOrNo(
                data?.moreDetails.isReplacementOfPreviousDeposit
            ),
            documentation: data?.moreDetails.documentation || ''
        }
    });

    const handleOnProceed = useCallback<SubmitHandler<NewFormData>>(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (formData: any) => {
            const {
                compressionMethod,
                containsPersonalData,
                documentation,
                encryptMethod,
                exportControlsDetails,
                isCompressed,
                isEncrypted,
                isPasswordRequired,
                isReplacementOfPreviousDeposit,
                isSubjectToAnyExportControls,
                password,
                personalDataDetails
            } = formData;

            updateDeposit({
                compressionMethod: compressionMethod,
                containsPersonalData: containsPersonalData === 'yes',
                documentation: documentation,
                encryptMethod: encryptMethod,
                exportControlsDetails: exportControlsDetails,
                isCompressed: isCompressed === 'yes',
                isEncrypted: isEncrypted === 'yes',
                isEncryptedConfirmation: isEncrypted === 'yes',
                isPasswordRequired: isPasswordRequired === 'yes',
                isReplacementOfPreviousDeposit:
                    isReplacementOfPreviousDeposit === 'yes',
                isSubjectToAnyExportControls:
                    isSubjectToAnyExportControls === 'yes',
                password: password,
                personalDataDetails: personalDataDetails
            });
        },
        [updateDeposit]
    );

    const compressed = methods.watch('isCompressed');
    const encrypted = methods.watch('isEncrypted');
    const exportControls =
        methods.watch('isSubjectToAnyExportControls') === 'yes';
    const personalData = methods.watch('containsPersonalData');
    const requiresPassword = methods.watch('isPasswordRequired');

    useEffect(() => {
        if (personalData === 'yes') {
            methods.setValue('isEncrypted', 'yes');
        }
    }, [methods, personalData]);

    useEffect(() => {
        if (requiresPassword === 'no') {
            methods.setValue('password', '');
        }
    }, [methods, requiresPassword]);

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleOnProceed)}>
                <DNStep
                    onBack={() =>
                        goTo(`/deposit-now/${depositId}/deposit-details`)
                    }
                    loading={isFetching}
                    submitting={isLoading}
                    title={t('deposit-now-stage-four.title')}
                >
                    <Section
                        className="mt-2 flex w-fit h-auto min-w-5xl"
                        heading={t('deposit-now-stage-four.deposit-content')}
                    >
                        <div className="flex gap-12">
                            <div className="flex flex-col flex-1 gap-y-6">
                                <RadioGroupField
                                    label={t(
                                        'deposit-now-stage-four.deposit-compressed'
                                    )}
                                    name="isCompressed"
                                    options={radioOptions}
                                    required
                                    tooltip="deposit-now-stage-four.deposit-compressed-tooltip"
                                />

                                {compressed === 'yes' && (
                                    <InputField
                                        label={t(
                                            'deposit-now-stage-four.compression-method'
                                        )}
                                        name="compressionMethod"
                                        placeholder={t(
                                            'deposit-now-stage-four.compression-placeholder'
                                        )}
                                        required
                                    />
                                )}

                                <RadioGroupField
                                    label={t(
                                        'deposit-now-stage-four.deposit-export'
                                    )}
                                    name="isSubjectToAnyExportControls"
                                    required
                                    options={radioOptions}
                                    tooltip="deposit-now-stage-four.deposit-export-tooltip"
                                />
                                {exportControls && (
                                    <InputField
                                        label={t(
                                            'deposit-now-stage-four.export-method'
                                        )}
                                        name="exportControlsDetails"
                                        placeholder={t(
                                            'deposit-now-stage-four.export-placeholder'
                                        )}
                                        required
                                    />
                                )}

                                <RadioGroupField
                                    label={t(
                                        'deposit-now-stage-four.deposit-personal'
                                    )}
                                    name="containsPersonalData"
                                    required
                                    options={radioOptions}
                                    tooltip="deposit-now-stage-four.deposit-personal-tooltip"
                                />
                                {personalData === 'yes' && (
                                    <InputField
                                        label={t(
                                            'deposit-now-stage-four.personal-details'
                                        )}
                                        name="personalDataDetails"
                                        placeholder={t(
                                            'deposit-now-stage-four.personal-placeholder'
                                        )}
                                        required
                                    />
                                )}

                                <div className="flex items-start w-full gap-4">
                                    <RadioGroupField
                                        label={t(
                                            'deposit-now-stage-four.password'
                                        )}
                                        name="isPasswordRequired"
                                        required
                                        options={radioOptions}
                                    />
                                    <InputField
                                        disabled={
                                            requiresPassword === 'no' ||
                                            requiresPassword === null
                                        }
                                        name="password"
                                        placeholder={t(
                                            'deposit-now-stage-four.enter-password'
                                        )}
                                        className="flex-1 pt-5"
                                    />
                                </div>
                            </div>

                            <div className="flex flex-col flex-1 gap-y-6">
                                <RadioGroupField
                                    disabled={personalData === 'yes'}
                                    label={t(
                                        'deposit-now-stage-four.deposit-encrypted'
                                    )}
                                    name="isEncrypted"
                                    options={radioOptions}
                                    required
                                    tooltip="deposit-now-stage-four.deposit-personal-tooltip"
                                />

                                {encrypted === 'yes' && (
                                    <InputField
                                        label={t(
                                            'deposit-now-stage-four.encryption-method'
                                        )}
                                        name="encryptMethod"
                                        placeholder={t(
                                            'deposit-now-stage-four.encryption-placeholder'
                                        )}
                                        required
                                    />
                                )}

                                <RadioGroupField
                                    label={t(
                                        'deposit-now-stage-four.replacement'
                                    )}
                                    name="isReplacementOfPreviousDeposit"
                                    options={radioOptions}
                                    tooltip="deposit-now-stage-four.previous-deposits-tooltip"
                                />

                                <TextareaField
                                    className="min-h-[108px]"
                                    name="documentation"
                                    label={t(
                                        'deposit-now-stage-four.documentation'
                                    )}
                                    tooltip="deposit-now-stage-four.documentation-tooltip"
                                    placeholder={t(
                                        'deposit-now-stage-four.documentation-placeholder'
                                    )}
                                />
                            </div>
                        </div>
                    </Section>
                </DNStep>
            </form>
        </FormProvider>
    );
};

export default ContentStep;
