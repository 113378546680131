import { UseQueryOptions, useQuery } from 'react-query';
import axios, { AxiosResponse } from 'axios';

import { AuthToken } from 'ui/deposit-now/upload-step/upload-types';
import { useAuth } from 'react-oidc-context';

function useSpectreControlAuth(
    spectreTokenResponse: AuthToken,
    options?: Omit<UseQueryOptions, 'queryFn'>
) {
    // const queryClient = useQueryClient();
    const auth = useAuth();

    return useQuery(
        [
            'spectreControlAuth',
            { token: auth.user?.access_token, tokenType: auth.user?.token_type }
        ],
        async () => {
            if (process.env.REACT_APP_API_MOCK) {
                console.warn(
                    'Mock is enabled; useSpectreControlAuth call ignored.'
                );
                return Promise.resolve({} as AxiosResponse<void | unknown>);
            }

            const objectData = {
                baseUrl: spectreTokenResponse.authToken.audiences[0],
                depositUniqueReference: spectreTokenResponse.id,
                spectreControlAuthToken: spectreTokenResponse.authToken.token,
                spectreControlUrl: spectreTokenResponse.authToken.audiences[1],
                tokenId: spectreTokenResponse.authToken.id
            };
            const auth = new URL(
                `/api/auth/${objectData.spectreControlAuthToken}`,
                objectData.spectreControlUrl
            );

            const authTokenClaim = async (
                id: string,
                query?: { depositId?: string },
                headers: { [key: string]: string } = {}
            ) => {
                const url = `/api/auth/${id}`;
                const queryParams = query
                    ? `?depositId=${query.depositId || ''}`
                    : '';

                try {
                    const response = await axios.get(url + queryParams, {
                        baseURL: auth.origin,
                        headers: {
                            Authorization: `Bearer ${spectreTokenResponse.authToken.token}`,
                            'Cache-FormControl':
                                'no-cache, no-store, must-revalidate',
                            Expires: '0',
                            Pragma: 'no-cache',
                            ...headers
                        },
                        withCredentials: true
                    });

                    return response.data;
                } catch (error) {
                    console.error('Error making Axios request:', error);
                    throw error;
                }
            };
            const data = await authTokenClaim(
                spectreTokenResponse.authToken.id,
                {
                    depositId: spectreTokenResponse.id
                }
            );

            return data?.token;
        },
        {
            enabled: options?.enabled ?? false
        }
    );
}

export default useSpectreControlAuth;
