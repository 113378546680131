import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';

function ConfirmRegistration() {
    const { t } = useTranslation();
    return (
        <div>
            <section
                className="w-full p-[7rem] text-center"
                data-testid="layout-section"
            >
                <FontAwesomeIcon
                    icon={faCheck}
                    className="bg-brand-escode-neonblue-neonblue-100 text-white p-6 rounded-full mb-6"
                />
                <h1 className="text-general-grey-grey-90 text-3xl pb-5.5 text-center">
                    <span className="font-semibold text-brand-escode-neonblue-neonblue-100">
                        View&nbsp;
                    </span>
                    {t('auth.confirmRegistration.title')}
                </h1>

                <p className="mb-5.5">
                    {t('auth.confirmRegistration.message')}
                </p>
                {/* TODO: Add a link to the contact us page */}
                {/* <p>Have any problems? Contact Us</p> */}
            </section>
        </div>
    );
}

export default ConfirmRegistration;
