import { FrequencyType } from '../autogenerated/data-contracts';

type DepositFrequency =
    | 'none'
    | 'daily'
    | 'weekly'
    | 'monthly'
    | 'annually'
    | 'quarterly';

type DepositFrequencyInitials = 'n' | 'd' | 'w' | 'm' | 'a' | 'q';

const DEPOSIT_FREQUENCY_INITIALS_MAPPER: Record<
    FrequencyType,
    DepositFrequencyInitials
> = {
    [FrequencyType.Value0]: 'n',
    [FrequencyType.Value1]: 'd',
    [FrequencyType.Value2]: 'w',
    [FrequencyType.Value3]: 'm',
    [FrequencyType.Value4]: 'a',
    [FrequencyType.Value5]: 'q'
};

const DEPOSIT_FREQUENCY_MAPPER: Record<FrequencyType, DepositFrequency> = {
    [FrequencyType.Value0]: 'none',
    [FrequencyType.Value1]: 'daily',
    [FrequencyType.Value2]: 'weekly',
    [FrequencyType.Value3]: 'monthly',
    [FrequencyType.Value4]: 'annually',
    [FrequencyType.Value5]: 'quarterly'
};

const INVERT_DEPOSIT_FREQUENCY_MAPPER = Object.entries(
    DEPOSIT_FREQUENCY_MAPPER
).reduce((acc, [key, value]) => ({
    ...acc,
    [value]: +key as FrequencyType
})) as unknown as Record<DepositFrequency, FrequencyType>;

export {
    DEPOSIT_FREQUENCY_INITIALS_MAPPER,
    DEPOSIT_FREQUENCY_MAPPER,
    INVERT_DEPOSIT_FREQUENCY_MAPPER
};
export type { DepositFrequency, DepositFrequencyInitials };
