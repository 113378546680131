import { CommonProps } from '@ncc-frontend/core';
import { PropsWithChildren } from 'react';
import { TransitionGroup } from 'react-transition-group';
import WizardStep from './wizard-step';
import classNames from 'classnames';

interface WizardProps extends CommonProps { }

function Wizard({
    children,
    className,
    ...restProps
}: PropsWithChildren<WizardProps>) {
    const cssClasses = classNames('relative text-sm', className);
    return (
        <TransitionGroup {...restProps} className={cssClasses}>
            {children}
        </TransitionGroup>
    );
}

Wizard.Step = WizardStep;

export default Wizard;
export type { WizardProps };
