import { Button } from 'lib-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/pro-regular-svg-icons';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import DashboardWidget, { DashboardWidgetProps } from '../dashboard-widget';
import useDepositHistoryReportDownload from 'core/api/client-portal/hooks/agreements/use-deposit-history-report-download';

interface DocumentsWidgetProps extends DashboardWidgetProps {}

function DocumentsWidget({ ...restProps }: DocumentsWidgetProps) {
    const { t } = useTranslation();

    const { isLoading, mutate: download } = useDepositHistoryReportDownload();

    const requestDepositHistory = useCallback(() => {
        download({
            reportFormat: 1
        });
    }, [download]);

    return (
        <DashboardWidget {...restProps}>
            <DashboardWidget.Header
                title={t('dashboard.documents-widget.title')}
            />
            <DashboardWidget.Body className="flex flex-col gap-2.5 place-items-start">
                <div className="text-general-grey-grey-90 text-sm">
                    {t('agreements.export.deposit-history')}
                </div>
                <Button
                    onClick={requestDepositHistory}
                    icon={<FontAwesomeIcon icon={faFileExcel} />}
                    loading={isLoading}
                    variant="tertiary"
                >
                    {t('dashboard.documents-widget.download-xlsx')}
                </Button>
            </DashboardWidget.Body>
        </DashboardWidget>
    );
}

export default DocumentsWidget;
