// V1ConfirmDepositDetailsCreate

import { MutationOptions, useMutation } from 'react-query';

import { AxiosResponse } from 'axios';
import { useAuth } from 'react-oidc-context';

import { ApiController } from '../../autogenerated/Api';
import interceptors from '../../../interceptors';
import securityWorker from '../../../security-worker';

// type AgreementsDepositDetailsCreateType = {
//     depositId: number;
// };

function useAgreementsDepositsDetailsCreate<
    TError = unknown,
    TContext = unknown
>(
    options?: Omit<
        MutationOptions<
            AxiosResponse<void, unknown>,
            ReactQueryError<TError>,
            number,
            TContext
        >,
        'mutationFn'
    >
) {
    const auth = useAuth();

    return useMutation<
        AxiosResponse,
        ReactQueryError<TError>,
        number,
        TContext
    >(
        [
            {
                token: auth.user?.access_token,
                tokenType: auth.user?.token_type
            }
        ],
        async (depositId) => {
            if (process.env.REACT_APP_API_MOCK) {
                console.warn(
                    'Mock is enabled useAgreementsDepositsDetailsCreate call ignored.',
                    { depositId }
                );
                return Promise.resolve({} as AxiosResponse<void, unknown>);
            }

            const controller = new ApiController({
                interceptors,
                securityWorker: securityWorker(
                    auth.user?.token_type,
                    auth.user?.access_token
                )
            });

            return controller.v1ConfirmDepositDetailsCreate(depositId);
        },
        {
            ...options
        }
    );
}

export default useAgreementsDepositsDetailsCreate;
