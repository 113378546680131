import { Button } from 'lib-ui';
import { DepositItem } from 'core/api/client-portal/autogenerated/data-contracts';
import { DepositsTableContext } from './deposits-table-context';
import { ICellRendererParams } from 'ag-grid-community';
import { NavLink } from 'react-router-dom';
import { memo } from 'react';

// import { DepositStatus } from './deposit-status-tag';
import depositStepFormatter from 'core/formatters/deposit-step-formatter';

function DepositActionsCellRenderer({
    context: { t },
    data
}: ICellRendererParams<DepositItem, DepositsTableContext>) {
    return (
        (data && !data.isSourceControl && !data.isReadOnly && (
            <div className="flex h-full items-center">
                <NavLink
                    to={`/deposit-now/${data.depositId}/${depositStepFormatter(
                        data.lastSavedState ?? 0
                    )}`}
                >
                    <Button>{t('deposits.continue')}</Button>
                </NavLink>
            </div>
        )) ||
        null
    );
}

export default memo(DepositActionsCellRenderer);

// return (
//     (data &&
//         data.escrowDepositStatus &&
//         data.escrowDepositStatus === 1 &&
//         [(DepositStatus.Incomplete, DepositStatus.InProgress)].includes(
//             data.escrowDepositStatus as unknown as DepositStatus
//         ) && (
//             <div className="flex h-full items-center">
//                 <NavLink
//                     to={`/deposit-now/${
//                         data.depositId
//                     }/${depositStepFormatter(data.lastSavedState ?? 0)}`}
//                 >
//                     <Button>
//                         {t('deposits.continue')} {data.escrowDepositStatus}
//                     </Button>
//                 </NavLink>
//             </div>
//         )) ||
//     null
// );
