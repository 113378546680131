import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { PropsWithChildren } from 'react';

import './dropdown-panel.scss';

interface DropdownPanelProps {}

function DropdownPanel({ children }: PropsWithChildren<DropdownPanelProps>) {
    return (
        <TransitionGroup appear>
            <CSSTransition timeout={500} classNames="dropdown-panel">
                <div>{children}</div>
            </CSSTransition>
        </TransitionGroup>
    );
}

export default DropdownPanel;
export type { DropdownPanelProps };
