import { Interceptors } from './client-portal/autogenerated/http-client';

const interceptors: Interceptors = {
    request: [
        {
            onFulfilled: (config) => {
                if (!config.headers) config.headers = {};

                config.headers['Cache-FormControl'] =
                    'no-cache, no-store, must-revalidate';
                config.headers['Pragma'] = 'no-cache';
                config.headers['Expires'] = '0';

                return config;
            }
        }
    ]
};

export default interceptors;
