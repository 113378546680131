/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    Deposit,
    DepositCloneRequest,
    DepositFile,
    SourceControlDeposit,
    SourceControlProvider,
    VerifyRepositoryConfigurationItemResponse
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class ApiController<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags Deposit
     * @name CloneDeposit
     * @summary Clone Deposit.
     * @request POST:/api/v1/Deposit/clone
     * @secure
     */
    cloneDeposit = (data: DepositCloneRequest, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/v1/Deposit/clone`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * No description
     *
     * @tags Deposit
     * @name CreateDeposit
     * @summary Create Deposit.
     * @request POST:/api/v1/Deposit/create
     * @secure
     */
    createDeposit = (data: Deposit, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/v1/Deposit/create`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * No description
     *
     * @tags Deposit
     * @name CopyDepositFilesToBurn
     * @summary Copy deposit files to burn storage.
     * @request PUT:/api/v1/Deposit/{depositId}/copytoburn
     * @secure
     */
    copyDepositFilesToBurn = (depositId: string, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/v1/Deposit/${depositId}/copytoburn`,
            method: 'PUT',
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags Deposit
     * @name V1DepositBurnfilesDelete
     * @summary Delete the specified deposits files from burn storage.
     * @request DELETE:/api/v1/Deposit/{uniqueReference}/burnfiles
     * @secure
     */
    v1DepositBurnfilesDelete = (uniqueReference: string, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/v1/Deposit/${uniqueReference}/burnfiles`,
            method: 'DELETE',
            secure: true,
            ...params
        });
    /**
 * No description
 *
 * @tags DepositUpload
 * @name StartFileUpload
 * @summary Create a file in the uploader database in preparation for uploading.
Called by the View portal back-end, not directly called by View front-end.
 * @request POST:/api/v1/depositupload/{uniqueReference}/startfileupload
 * @secure
 */
    startFileUpload = (uniqueReference: string, data: DepositFile, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/v1/depositupload/${uniqueReference}/startfileupload`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * No description
     *
     * @tags DepositUpload
     * @name ChunkUpload
     * @summary Upload endpoint for a file chunk.
     * @request POST:/api/v1/depositupload/{depositUuid}/chunkupload
     * @secure
     */
    chunkUpload = (
        depositUuid: string,
        data: {
            /**
             * Gets or sets the upload identifier.
             * @format uuid
             */
            qquuid?: string;
            /** Gets or sets the Filename. */
            qqfilename?: string;
            /**
             * Gets or sets the total filesize.
             * @format int64
             */
            qqtotalfilesize?: number;
            /**
             * Gets or sets the total number of parts.
             * @format int32
             */
            qqtotalparts?: number;
            /**
             * Gets or sets the file.
             * @format binary
             */
            qqfile?: File;
            /**
             * Gets or sets the index of the part.
             * @format int32
             */
            qqpartindex?: number;
            /**
             * Gets or sets the starting byte of the chunk.
             * @format int64
             */
            qqpartbyteoffset?: number;
            /**
             * Gets or sets the chunk size.
             * @format int64
             */
            qqchunksize?: number;
        },
        params: RequestParams = {}
    ) =>
        this.request<void, any>({
            path: `/api/v1/depositupload/${depositUuid}/chunkupload`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.FormData,
            ...params
        });
    /**
     * No description
     *
     * @tags DepositUpload
     * @name UploadComplete
     * @summary Upload of a single file complete.
     * @request POST:/api/v1/depositupload/{uniqueReference}/uploadcomplete
     * @secure
     */
    uploadComplete = (uniqueReference: string, data: DepositFile, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/v1/depositupload/${uniqueReference}/uploadcomplete`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * No description
     *
     * @tags DepositUpload
     * @name UpdateDepositStatus
     * @summary UpdateDepositStatus.
     * @request PUT:/api/v1/depositupload/updatedepositstatus
     * @secure
     */
    updateDepositStatus = (data: Deposit, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/v1/depositupload/updatedepositstatus`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * No description
     *
     * @tags DepositUpload
     * @name DeleteUpload
     * @summary Delete of a single file.
     * @request DELETE:/api/v1/depositupload/{uniqueReference}/deleteupload
     * @secure
     */
    deleteUpload = (
        uniqueReference: string,
        query?: {
            /**
             * Gets or sets the deposit file identifier/UniqueReference on main View DB.
             * @format uuid
             */
            FileId?: string;
            /** Gets or sets the name of the file. */
            FileName?: string;
            /**
             * Gets or sets the size of the file.
             * @format int64
             */
            FileSize?: number;
            /** Gets or sets the file hash. */
            FileHash?: string;
            /**
             * Gets or sets UploadedById.
             * @format uuid
             */
            UploadedById?: string;
        },
        params: RequestParams = {}
    ) =>
        this.request<void, any>({
            path: `/api/v1/depositupload/${uniqueReference}/deleteupload`,
            method: 'DELETE',
            query: query,
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags DepositUpload
     * @name ValidateSourceControlConnection
     * @summary Validate SourceControl Connection.
     * @request POST:/api/v1/depositupload/validatesourcecontrolconnection
     * @secure
     */
    validateSourceControlConnection = (data: SourceControlProvider[], params: RequestParams = {}) =>
        this.request<VerifyRepositoryConfigurationItemResponse, any>({
            path: `/api/v1/depositupload/validatesourcecontrolconnection`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags ScheduledTask
     * @name DownloadSourceControlDeposit
     * @summary Download Source Control Deposit.
     * @request POST:/api/v1/ScheduledTask/downloadsourcecontroldeposit
     * @secure
     */
    downloadSourceControlDeposit = (data: SourceControlDeposit, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/v1/ScheduledTask/downloadsourcecontroldeposit`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * No description
     *
     * @tags ScheduledTask
     * @name V1ScheduledTaskCreatedepositCreate
     * @summary Create a Deposit record in the database.
     * @request POST:/api/v1/ScheduledTask/createdeposit
     * @secure
     */
    v1ScheduledTaskCreatedepositCreate = (data: Deposit, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/v1/ScheduledTask/createdeposit`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * No description
     *
     * @tags ScheduledTask
     * @name ScheduledCloneDeposit
     * @summary Clone Deposit.
     * @request POST:/api/v1/ScheduledTask/clonedeposit
     * @secure
     */
    scheduledCloneDeposit = (data: DepositCloneRequest, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/v1/ScheduledTask/clonedeposit`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * No description
     *
     * @tags ScheduledTask
     * @name ScheduleUpdateDepositStatus
     * @summary UpdateDepositStatus.
     * @request PUT:/api/v1/ScheduledTask/updatedepositstatus
     * @secure
     */
    scheduleUpdateDepositStatus = (data: Deposit, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/v1/ScheduledTask/updatedepositstatus`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * No description
     *
     * @tags ScheduledTask
     * @name ScheduleDeleteDepositFiles
     * @summary Delete the specified deposit files.
     * @request DELETE:/api/v1/ScheduledTask/{uniqueReference}/deletefiles
     * @secure
     */
    scheduleDeleteDepositFiles = (uniqueReference: string, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/v1/ScheduledTask/${uniqueReference}/deletefiles`,
            method: 'DELETE',
            secure: true,
            ...params
        });
}
