import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import './error-panel.scss';

interface ErrorPanelProps {
    code: number;
}

function ErrorPanel({ children, code }: PropsWithChildren<ErrorPanelProps>) {
    const { t } = useTranslation();

    const { message, title } =
        code === 500
            ? { message: t('error.500-message'), title: t('error.500-title') }
            : code === 401
            ? { message: t('error.401-message'), title: t('error.401-title') }
            : code === 403
            ? { message: t('error.403-message'), title: t('error.403-title') }
            : { message: t('error.404-message'), title: t('error.404-title') };

    return (
        <div className="h-full w-full bg-gradient-to-r from-escode-overlay-texture-light to-escode-overlay-texture-dark rounded-2xl">
            <div className="error-panel rounded-xl w-full h-full flex items-center justify-center">
                <div className="flex flex-col gap-2.5 items-center">
                    <h4 className="text-ncc-white text-3xl font-semibold">
                        {code} - {title}
                    </h4>
                    <p className="text-ncc-white text-lg">{message}</p>
                    <div className="mt-4">{children}</div>
                </div>
            </div>
        </div>
    );
}

export default ErrorPanel;
export type { ErrorPanelProps };
