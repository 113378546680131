import { CalendarViewResolution } from 'lib-ui/calendar/calendar';
import { DownloadScheduleItem } from 'core/api/client-portal/autogenerated/data-contracts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ScheduleContext } from '../schedules-context-provider';
import { Widget } from 'lib-ui';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { isUndefined } from 'lodash';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'lib-ui/button/button';
import ScheduleDepositItem from './schedule-deposit-item';
import useSchedulesList from 'core/api/client-portal/hooks/schedules/use-schedules-list';

function ScheduleDepositWidget() {
    const { data: schedules, isLoading } = useSchedulesList();
    const { t } = useTranslation();
    const {
        depositScheduleData,
        isNextDayWithDeposits,
        onUpdateIsNextDayWithDeposits,
        schedulesTitle,
        viewResolutionProvider,
        weekTitleProvider
    } = useContext(ScheduleContext);

    const [schedulesData, setSchedulesData] = useState<
        DownloadScheduleItem[] | null
    >();

    const monthTitle = useCallback(() => {
        // when a day is selected, it is necessary to display  month: t('deposit-today'),
        localStorage.setItem('current-calendar-date', schedulesTitle);

        const monthSelected = `${schedulesTitle.split(' ').at(1)}
             ${schedulesTitle.split(' ').at(2)}`;

        return monthSelected;
    }, [schedulesTitle]);

    const handleTitle = useCallback(
        (newTitle: CalendarViewResolution) => {
            const titleMap: {
                [key: string]: CalendarViewResolution | undefined | string;
            } = {
                day: schedulesTitle.toString().slice(0, -4),
                month: monthTitle(),
                week: weekTitleProvider
            };

            const title = titleMap[newTitle || ''] || undefined;
            return title ? title + ' ' + t('deposit-schedule') : undefined;
        },
        [monthTitle, schedulesTitle, t, weekTitleProvider]
    );

    const handleClick = () => {
        !isNextDayWithDeposits && onUpdateIsNextDayWithDeposits(true);
        return;
    };

    useEffect(() => {
        const calendarTitles = depositScheduleData?.map((item) => item.title);
        const filteredSchedules = schedules?.schedules?.filter((item) => {
            if (item.nextDownloadAt) {
                const nextDownloadDate = new Date(item.nextDownloadAt);

                return (
                    calendarTitles?.includes(item.agreementNumber) &&
                    depositScheduleData?.some(
                        (scheduledData) =>
                            scheduledData.date.getTime() ===
                            nextDownloadDate.getTime()
                    )
                );
            }
            return false;
        });
        setSchedulesData(filteredSchedules);
    }, [depositScheduleData, schedules?.schedules]);

    if (!schedules || isLoading) return null;
    return (
        <Widget
            className="2xl:w-102"
            data-testid="schedule-deposit-widget-container"
        >
            <Widget.Header title={handleTitle(viewResolutionProvider)} />
            <Widget.Body className="w-full px-4 overflow-scroll">
                {!isUndefined(schedulesData) && schedulesData!.length > 0 ? (
                    <>
                        <div className="flex justify-end">
                            <p className=" mt-4 px-6 text-md text-general-grey-grey-70">
                                <span className="font-medium text-black">
                                    {schedulesData?.length}
                                </span>{' '}
                                notifications
                            </p>
                        </div>
                        {schedulesData?.map((deposit, i) => (
                            <ScheduleDepositItem
                                key={deposit.id}
                                data={deposit}
                            />
                        ))}
                        <p className="text-center text-sm font-thin text-brand-escode-neonblue-neonblue-10 pt-5">
                            {t('no-further-deposits')}
                        </p>
                    </>
                ) : (
                    <div className="flex flex-col items-center justify-center mt-8 gap-8">
                        <h2 className="font-medium">
                            {t('no-deposits')} {schedulesTitle.toString()}
                        </h2>
                        <Button
                            variant="secondary"
                            icon={<FontAwesomeIcon icon={faArrowRight} />}
                            iconPosition="right"
                            onClick={handleClick}
                        >
                            {t('next-deposits')}
                        </Button>
                    </div>
                )}
            </Widget.Body>
        </Widget>
    );
}

export default ScheduleDepositWidget;
