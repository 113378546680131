import { FilterBackEventHandler } from '../../data-widget-types';
import { ForwardedRef, forwardRef, useCallback } from 'react';
import { useDropdown } from '@ncc-frontend/core';
import { useTranslation } from 'react-i18next';

import Button from '../../../../button/button';
import FilterStep from '../filter-step/filter-step';
import useDataWidget from '../../use-data-widget';

interface FilterClearStepProps {
    onBack: FilterBackEventHandler;
}

function FilterClearStep(
    { onBack }: FilterClearStepProps,
    ref: ForwardedRef<HTMLDivElement>
) {
    const { t } = useTranslation();
    const { close } = useDropdown();
    const { onApplyFilters, setQuickSearchValue } = useDataWidget();

    const clearFilters = useCallback(() => {
        setQuickSearchValue('');
        onApplyFilters!([]);

        close();
    }, [close, onApplyFilters, setQuickSearchValue]);

    return (
        <FilterStep ref={ref}>
            <FilterStep.Header onBack={onBack}>
                {t('filters.clear-title')}
            </FilterStep.Header>

            <FilterStep.Footer>
                <Button
                    variant="danger"
                    className="flex-1"
                    onClick={clearFilters}
                >
                    {t('filters.clear-confirm')}
                </Button>
                <Button variant="primary" className="flex-1" onClick={onBack}>
                    {t('filters.clear-cancel')}
                </Button>
            </FilterStep.Footer>
        </FilterStep>
    );
}

export default forwardRef(FilterClearStep);
export type { FilterClearStepProps };
