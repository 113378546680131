import { ApiController } from '../../autogenerated/Api';
import { AxiosResponse } from 'axios';
import { MutationOptions, useMutation } from 'react-query';

import { TerminationFormData } from 'ui/termination-form/termination-form-modal';
import { useAuth } from 'react-oidc-context';
import interceptors from '../../../interceptors';
import securityWorker from '../../../security-worker';

type AgreementsInitiateTerminationParams = {
    data: TerminationFormData;
};

function useAgreementsInitiateTermination<TError = unknown, TContext = unknown>(
    options?: Omit<
        MutationOptions<
            AxiosResponse<void>,
            ReactQueryError<TError>,
            AgreementsInitiateTerminationParams,
            TContext
        >,
        'mutationFn'
    >
) {
    const auth = useAuth();

    return useMutation(
        [{ token: auth.user?.access_token, tokenType: auth.user?.token_type }],
        async ({ data }: AgreementsInitiateTerminationParams) => {
            if (process.env.REACT_APP_API_MOCK) {
                console.warn(
                    'Mock is enabled useAgreementsInitiateTermination call ignored.',
                    { data }
                );
                return Promise.resolve({} as AxiosResponse<void>);
            }

            const controller = new ApiController({
                interceptors,
                securityWorker: securityWorker(
                    auth.user?.token_type,
                    auth.user?.access_token
                )
            });

            const telephone = (data.prePhone + data.phone).toString();
            console.log(telephone);

            return controller.initiateTermination(
                Number(data.agreementToTerminate),
                {
                    Comments: data.comments,
                    Company: data.company,
                    CorrespondenceAddress: data.address,
                    DateOfTermination: data.dateOfTermination,
                    EmailAddress: data.email,
                    JobTitle: data.jobTitle,
                    Name: data.fullName,
                    PartyType: data.partyType,
                    ReasonForTheTermination: data.reason,
                    Telephone: telephone
                }
            );
        },
        options
    );
}

export default useAgreementsInitiateTermination;
export type { AgreementsInitiateTerminationParams };
