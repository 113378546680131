import { AgGridReact } from 'ag-grid-react';
import {
    ColDef,
    FILTER_IN_LIST_PARAMS,
    Table,
    TableProps,
    TableWidgetProps
} from 'lib-ui';
import { CommonProps } from '@ncc-frontend/core';
import { DownloadScheduleItem } from 'core/api/client-portal/autogenerated/data-contracts';
import { ForwardedRef, forwardRef, useMemo } from 'react';
import { SchedulesTableContext } from './schedules-table-context';
import { useTranslation } from 'react-i18next';
import getDateFilter from 'core/ag-grid/get-date-filter';

import SchedulesActionsCellRenderer from './schedules-actions-cell-renderer';
import SchedulesAgreementNumberCellRenderer from './schedules-agreement-number-cell-renderer';
import SchedulesDateCellRenderer from './schedules-date-cell-renderer';
import SchedulesFrequencyTypeCellRenderer, {
    schedulesFrequencyTypeValueGetter
} from './schedules-frequency-type-cell-renderer';

interface SchedulesTableProps extends CommonProps {
    context: SchedulesTableContext;
    data: DownloadScheduleItem[] | null | undefined;
    id: string;
    loading?: boolean;
    onGridReady: TableProps['onGridReady'];
    onPaginationChanged: TableProps['onPaginationChanged'];
    onRowDataUpdated: TableProps['onRowDataUpdated'];
}

function SchedulesTable(
    { data, ...restProps }: SchedulesTableProps,
    ref: ForwardedRef<AgGridReact>
) {
    const { i18n, t } = useTranslation();

    const columnDefs = useMemo<ColDef<DownloadScheduleItem>[]>(
        () => [
            {
                cellRenderer: SchedulesAgreementNumberCellRenderer,
                field: 'agreementNumber',
                headerName: t('schedules.header.agreement'),
                onCellClicked: ({ context: { goTo }, data }) => {
                    // BACKEND: backend needs to add the agreement id
                    if (data.agreementId)
                        goTo(`/agreements/${data.agreementId}`);
                }
            },
            {
                field: 'softwarePackageName',
                headerName: t('schedules.header.software-name')
            },
            {
                field: 'versionOrReleaseNumber',
                headerName: t('schedules.header.version')
            },
            {
                cellRenderer: SchedulesFrequencyTypeCellRenderer,
                field: 'frequencyType',
                filter: true,
                filterParams: FILTER_IN_LIST_PARAMS,
                headerName: t('schedules.header.frequency'),
                valueGetter: schedulesFrequencyTypeValueGetter
            },
            {
                ...getDateFilter('nextDownloadAt', i18n.language),
                cellRenderer: SchedulesDateCellRenderer,
                colId: 'nextDownloadAt',
                headerName: t('schedules.header.upcoming-deposit')
            },
            {
                cellRenderer: SchedulesActionsCellRenderer,
                colId: 'actions',
                headerName: t('actions')
            }
        ],
        [i18n.language, t]
    );

    const noRowsOverlayComponentParams = useMemo<
        TableWidgetProps<false>['noRowsOverlayComponentParams']
    >(() => ({ message: t('schedules.no-data') }), [t]);

    return (
        <Table
            {...restProps}
            ref={ref}
            columnDefs={columnDefs}
            noRowsOverlayComponentParams={noRowsOverlayComponentParams}
            rowData={data}
        />
    );
}

export default forwardRef(SchedulesTable);
export type { SchedulesTableProps };
