import { ApiController } from '../../autogenerated/Api';
import { GetIncompleteDepositsResponse } from 'core/api/client-portal/autogenerated/data-contracts';
import { ProblemDetails } from '../../autogenerated/data-contracts';
import { RequestParams } from '../../autogenerated/http-client';
import { UseQueryOptions, useQuery } from 'react-query';
import { useAuth } from 'react-oidc-context';
import interceptors from 'core/api/interceptors';
import securityWorker from 'core/api/security-worker';
import useIncompleteDepositsV2MockData from './use-incomplete-deposits-v2-mock-data';

function useIncompleteDepositsV2<TError = unknown>(
    params?: RequestParams,
    options?: Omit<
        UseQueryOptions<
            GetIncompleteDepositsResponse | ProblemDetails,
            ReactQueryError<TError>,
            GetIncompleteDepositsResponse | ProblemDetails
        >,
        'queryKey' | 'queryFn'
    >
) {
    const { user } = useAuth();

    return useQuery(
        ['use-incomplete-deposit-v2'],
        async () => {
            if (process.env.REACT_APP_API_MOCK) {
                console.warn('Using mock data for useIncompleteDepositsV2');
                return useIncompleteDepositsV2MockData;
            }

            if (
                user?.access_token === undefined ||
                user?.token_type === undefined
            ) {
                return undefined;
            }

            const controller = new ApiController({
                interceptors,
                securityWorker: securityWorker(
                    user?.token_type,
                    user?.access_token
                )
            });

            const response = await controller.v2DepositsIncompleteList(params);
            return response.data;
        },
        options
    );
}

export default useIncompleteDepositsV2;
