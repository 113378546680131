import {
    FilterBackEventHandler,
    FilterConfirmEventHandler,
    FilterData,
    FilterFieldData
} from '../../data-widget-types';
import {
    ForwardedRef,
    ReactElement,
    forwardRef,
    useCallback,
    useMemo
} from 'react';
import { TailwindProps, TailwindStyle } from '@ncc-frontend/core';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';

import DatePickerWizard, {
    DatePickerWizardProps
} from '../../../../form/element/date-picker/date-picker-wizard';
import FilterStep from '../filter-step/filter-step';

function formatDate(date: Date) {
    return `${date.getFullYear().toString().padStart(4, '0')}-${(
        date.getMonth() + 1
    )
        .toString()
        .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
}

interface FilterDateStepProps extends TailwindProps {
    data: FilterFieldData;
    filter: FilterData<'text'> | undefined;
    onBack: FilterBackEventHandler;
    onConfirm?: FilterConfirmEventHandler;
    readonly?: boolean;
}

function FilterDateStep(
    {
        data: { colId, dateConstrain, title },
        filter: currentFilter,
        onBack,
        onConfirm,
        readonly = false,
        ...restProps
    }: FilterDateStepProps,
    ref: ForwardedRef<HTMLDivElement>
): ReactElement | null {
    const { t } = useTranslation();

    const value = useMemo(
        () =>
            !!currentFilter?.model
                ? [currentFilter.model.dateFrom, currentFilter.model.dateTo]
                : undefined,
        [currentFilter?.model]
    );

    const handleSelect = useCallback<DatePickerWizardProps['onSelect']>(
        (dates) => {
            if (readonly) {
                onBack();
                return;
            }

            const filter = cloneDeep(dates as [Date, Date]);

            // AG Grid requires dates in format YYYY-MM-DD
            onConfirm?.({
                colId,
                model: {
                    dateFrom: formatDate(filter[0]),
                    dateTo: formatDate(filter[1]),
                    filterType: 'date',
                    type: 'inRange'
                }
            });
        },
        [colId, onBack, onConfirm, readonly]
    );

    const datePickerTailwindStyle = useMemo<TailwindStyle>(
        () => ({
            border: '',
            padding: ''
        }),
        []
    );

    const translations = useMemo<
        Partial<DatePickerWizardProps['translations']>
    >(
        () => ({
            readonlyConfirm: t('filters.go-back')
        }),
        [t]
    );

    return (
        <FilterStep ref={ref} {...restProps}>
            <FilterStep.Header onBack={onBack} suppressBackIcon={readonly}>
                {title}
            </FilterStep.Header>
            <FilterStep.Body>
                <DatePickerWizard
                    value={value}
                    onSelect={handleSelect}
                    constrain={dateConstrain}
                    range
                    tailwindStyle={datePickerTailwindStyle}
                    readOnly={readonly}
                    translations={translations}
                    suppressAllDates
                />
            </FilterStep.Body>
        </FilterStep>
    );
}

export default forwardRef(FilterDateStep);
export type { FilterDateStepProps };
