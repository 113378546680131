import { Button } from 'lib-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import { faArrowRightToLine } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';

import DashboardWidget, { DashboardWidgetProps } from '../dashboard-widget';
import ScheduleListItem from 'ui/schedules/schedule-list-item';
import useMaxItems from 'common/use-max-items';
import useSchedulesList from 'core/api/client-portal/hooks/schedules/use-schedules-list';

interface UpcomingSchedulesWidgetProps
    extends Omit<DashboardWidgetProps, 'loading'> {}

function UpcomingSchedulesWidget({
    ...restProps
}: UpcomingSchedulesWidgetProps) {
    const { t } = useTranslation();

    const { rows, setContainerRef, setItemRef } = useMaxItems({
        rowGap: 10
    });

    const { data: schedules, isFetching, isLoading } = useSchedulesList();
    const loading = isLoading || isFetching;

    return (
        <DashboardWidget {...restProps}>
            <DashboardWidget.Header
                title={t('dashboard.upcoming-schedules-widget.title', {})}
                loading={loading}
            >
                <NavLink to="/schedules" className="ml-auto">
                    <Button
                        size="s"
                        icon={<FontAwesomeIcon icon={faArrowRightToLine} />}
                        iconPosition="right"
                    >
                        {t('dashboard.upcoming-schedules-widget.see-all')}
                    </Button>
                </NavLink>
            </DashboardWidget.Header>
            <DashboardWidget.Body
                ref={setContainerRef}
                className="flex flex-col gap-2.5"
            >
                {!!loading &&
                    [...new Array(rows)].map((_, index) => (
                        <ScheduleListItem
                            key={index}
                            ref={index === 0 ? setItemRef : undefined}
                            loading
                        />
                    ))}
                {!loading &&
                    schedules?.schedules
                        ?.slice(0, rows)
                        .map((item, index) => (
                            <ScheduleListItem
                                key={item.id}
                                ref={index === 0 ? setItemRef : undefined}
                                data={item}
                            />
                        ))}
            </DashboardWidget.Body>
        </DashboardWidget>
    );
}

export default UpcomingSchedulesWidget;
