const useDepositDetailsV2MockData: Record<number, string> = {
    100: 'A',
    200: 'B',
    300: 'C',
    400: 'D',
    500: 'E',
    600: 'F',
    700: 'G',
    800: 'H',
    900: 'I'
};

export { useDepositDetailsV2MockData };
