import { ReactComponent as AppLogoWhite } from 'logo-white-text.svg';
import { Outlet } from 'react-router-dom';

import '../ui/auth/auth.scss';

function AuthLayout() {
    return (
        <div className="flex h-screen w-screen bg-gradient-to-r from-brand-escode-neonblue-neonblue-100 to-brand-escode-tourmalineblack-tourmalineblack-120">
            <div className="flex items-center justify-center h-full w-[50%] auth-texture">
                <div className="w-[50%]">
                    <AppLogoWhite className="flex items-start"></AppLogoWhite>
                    <h1 className="text-white text-[48px] font-semibold leading-tight">
                        Our secure online depositing portal.
                    </h1>
                </div>
            </div>
            <div className="flex items-center justify-center w-[50%] bg-white">
                <div className="flex flex-col">
                    <Outlet />
                </div>
            </div>
        </div>
    );
}

export default AuthLayout;
