import { SkeletonLine } from 'lib-ui';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { useAuth } from 'react-oidc-context';

const key = 'is-returning-user';
const isReturningUser = localStorage.getItem(key) === 'true';

interface WelcomeMessageProps {}

function WelcomeMessage({}: WelcomeMessageProps) {
    const { user } = useAuth();
    const { t } = useTranslation();

    localStorage.setItem(key, 'true');

    const message = t(
        isReturningUser ? 'dashboard.welcome-back' : 'dashboard.welcome',
        {
            userName: user?.profile.name ?? ''
            // userName: userDetails?.firstName ?? ''
        }
    );

    const loading = !user?.profile.name;

    return (
        <h1
            className={classNames('text-3xl font-semibold', {
                'text-general-grey-grey-90': !loading,
                'text-transparent': loading
            })}
        >
            <span className="relative">
                {loading && (
                    <SkeletonLine
                        className="absolute inset-0"
                        variant="white"
                    />
                )}

                {message}
            </span>
        </h1>
    );
}

export default WelcomeMessage;
export type { WelcomeMessageProps };
