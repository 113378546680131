import { AgreementListResponseViewModel } from '../../autogenerated/data-contracts';
import { UseQueryOptions, useQuery } from 'react-query';
import { isUndefined } from 'lodash';

import { ApiController } from '../../autogenerated/Api';
import { useAuth } from 'react-oidc-context';
import interceptors from '../../../interceptors';
import securityWorker from '../../../security-worker';
import useAgreementsListMockData from './use-agreements-list-mock-data';

type AgreementsListParams = {
    DepositNow?: boolean;
    IncludeLiveOnly?: boolean;
    IncludeRestrictedAgreements?: boolean;
};

function useAgreementsList<TError = unknown>(
    query?: AgreementsListParams,
    options?: Omit<
        UseQueryOptions<
            AgreementListResponseViewModel | undefined,
            ReactQueryError<TError>,
            AgreementListResponseViewModel | undefined
        >,
        'queryKey' | 'queryFn'
    >
) {
    const auth = useAuth();

    return useQuery(
        [
            'agreements',
            'list',
            {
                query,
                token: auth.user?.access_token,
                tokenType: auth.user?.token_type
            }
        ],
        async () => {
            if (process.env.REACT_APP_API_MOCK) {
                console.warn('Using mock data for useAgreementsList');
                return await new Promise<AgreementListResponseViewModel>(
                    (resolve, reject) =>
                        setTimeout(
                            () => resolve(useAgreementsListMockData),
                            1000
                        )
                );
            }

            if (
                isUndefined(auth.user?.access_token) ||
                isUndefined(auth.user?.token_type)
            ) {
                return undefined;
            }

            const controller = new ApiController({
                interceptors,
                securityWorker: securityWorker(
                    auth.user?.token_type,
                    auth.user?.access_token
                )
            });

            const response = await controller.getAgreements(query);
            return response.data;
        },
        options
    );
}

export default useAgreementsList;
