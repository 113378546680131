import * as yup from 'yup';
import { Button, Modal, Stepper, StepperProps } from 'lib-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { useModals } from '@ncc-frontend/core';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import PersonalInformationStep from './steps/personal-information-step';
import React, { useCallback, useMemo, useState } from 'react';
import TerminationDataStep from './steps/termination-data-step';
import TerminationFormInfoModal from './modal/termination-form-info-modal';
import useAgreementsInitiateTermination from 'core/api/client-portal/hooks/agreements/use-agreements-initiate-termination';
import useAgreementsList from 'core/api/client-portal/hooks/agreements/use-agreements-list';

type Step = 'personal-information' | 'termination-data';

type TerminationFormValues = {
    address: string;
    agreementToTerminate: string | number;
    comments: string;
    company: string;
    confirmed: boolean;
    dateOfTermination: string;
    email: string;
    fullName: string;
    jobTitle: string;
    partyType: string;
    phone: number | string;
    prePhone: string;
    reason: string;
};
export type TerminationFormData = {
    address: string;
    agreementToTerminate: string | number;
    comments?: string;
    company: string;
    confirmed: boolean;
    dateOfTermination: string;
    email: string;
    fullName: string;
    jobTitle: string;
    partyType: string;
    phone: string | number;
    prePhone: string;
    reason: string;
};

const TerminationFormModal = () => {
    const { t } = useTranslation();
    const { pop, push } = useModals();
    const [step, setStep] = useState<Step>('personal-information');
    const message = t('feedback-form.field-required');
    const schema = yup.object({
        address: yup.string().required(message),
        agreementToTerminate: yup.string().required(message),
        comments: yup.string(),
        company: yup.string().required(message),
        confirmed: yup
            .boolean()
            .oneOf([true], 'This is a required field')
            .typeError('This is a required field'),
        dateOfTermination: yup.string().required(message),
        email: yup.string().required(message).email(),
        fullName: yup.string().required(message),
        jobTitle: yup.string().required(message),
        partyType: yup.string().required(message),
        phone: yup.string().required(message),
        prePhone: yup.string().required(message),
        reason: yup.string().required(message)
    });
    const methods = useForm<TerminationFormValues>({
        defaultValues: {
            address: '',
            agreementToTerminate: '',
            comments: '',
            company: '',
            confirmed: false,
            dateOfTermination: '',
            email: '',
            fullName: '',
            jobTitle: '',
            partyType: '',
            phone: '',
            prePhone: '+44',
            reason: ''
        },
        mode: 'all',
        resolver: yupResolver(schema)
    });

    const { address, company, email, fullName, jobTitle, phone, prePhone } =
        methods.watch();

    const isPersonalInformationFilled =
        address !== '' &&
        fullName !== '' &&
        jobTitle !== '' &&
        company !== '' &&
        prePhone !== '' &&
        phone !== '' &&
        email !== '';

    const {
        data: agreementsToTerminate,
        isFetching,
        isLoading
    } = useAgreementsList({
        DepositNow: false,
        IncludeLiveOnly: false
    });
    const { isLoading: mutateLoading, mutate: initiateTerminationFn } =
        useAgreementsInitiateTermination({
            onError() {
                push(TerminationFormInfoModal, { isError: true });
            },
            onSuccess() {
                pop();
                push(TerminationFormInfoModal, { isError: false });
            }
        });

    const isLoadingAgreements = isLoading && isFetching;

    const handleOnProceed = useCallback(
        (formData: TerminationFormData) => {
            initiateTerminationFn({
                data: formData
            });
        },
        [initiateTerminationFn]
    );

    const handleStepChange = useCallback((value: Step) => {
        setStep(value);
    }, []);

    const steps = useMemo<StepperProps<Step>['steps']>(
        () => [
            {
                node: t('termination-form.personal-information'),
                step: 'personal-information'
            },
            {
                disabled: !isPersonalInformationFilled,
                node: t('termination-form.step-termination-data'),
                step: 'termination-data'
            }
        ],
        [isPersonalInformationFilled, t]
    );
    const next = () => {
        if (step === 'personal-information') {
            if (!isPersonalInformationFilled) return methods.handleSubmit;
            setStep('termination-data');
            return methods.clearErrors();
        }
    };

    const back = () => {
        if (step === 'termination-data') {
            setStep('personal-information');
        }
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods?.handleSubmit(handleOnProceed)}>
                <Modal size="m">
                    <Modal.Header>{t('termination-form.title')}</Modal.Header>
                    <Modal.Body>
                        <div className="flex flex-col gap-6">
                            <Stepper
                                steps={steps}
                                active={step}
                                onChange={handleStepChange}
                                extra={
                                    <>
                                        {step === 'personal-information' && (
                                            <Button
                                                onClick={next}
                                                type="submit"
                                                variant="tertiary"
                                            >
                                                {t('feedback-form.next-step')}
                                            </Button>
                                        )}
                                        {step === 'termination-data' && (
                                            <>
                                                <Button
                                                    onClick={back}
                                                    variant="tertiary"
                                                >
                                                    {t(
                                                        'feedback-form.previous-step'
                                                    )}
                                                </Button>
                                                <Button
                                                    type="submit"
                                                    loading={mutateLoading}
                                                    variant="secondary"
                                                >
                                                    {t('feedback-form.submit')}
                                                </Button>
                                            </>
                                        )}
                                    </>
                                }
                            />
                            {step === 'personal-information' && (
                                <PersonalInformationStep />
                            )}
                            {step === 'termination-data' &&
                                !isLoadingAgreements && (
                                    <TerminationDataStep
                                        agreementsToTerminate={
                                            agreementsToTerminate
                                        }
                                    />
                                )}
                        </div>
                    </Modal.Body>
                </Modal>
            </form>
        </FormProvider>
    );
};

export default TerminationFormModal;
