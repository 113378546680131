/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import DNStep from '../deposit-now-step/dn-step';
import cn from 'core/utils/cn';
import toastFunctions from 'lib-ui/toast/Toast';
import useAgreementsList from 'core/api/client-portal/hooks/agreements/use-agreements-list';
import useCreateDeposit from 'core/api/client-portal-v2/hooks/deposits/use-create-deposit';

type FormData = {
    relatedAgreements: string[];
};

const AgreementStep = () => {
    const { t } = useTranslation();
    const goTo = useNavigate();
    const queryClient = useQueryClient();
    const [displayUK, setDisplayUK] = useState(true);
    const [displayUS, setDisplayUS] = useState(true);

    const { data, isLoading } = useAgreementsList({
        DepositNow: true
    });

    const { isLoading: creating, mutate: createDeposit } = useCreateDeposit();

    const schema = yup.object().shape({
        relatedAgreements: yup
            .array()
            .min(1, 'Please select at least one agreement.')
            .required('Please select at least one agreement.')
    });

    const methods = useForm<FormData>({
        defaultValues: { relatedAgreements: [] },
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: yupResolver(schema)
    });

    const {
        formState: { errors },
        setValue,
        watch
    } = methods;

    const agreementIdsOptions = useMemo(
        () =>
            data?.agreements?.map((item) => ({
                agreementStatus: item.agreementStatus,
                agreementType: item.agreementType,
                hasOptedOutOfVVStorage: item.hasOptedOutOfVVStorage,
                id: item.agreementId as unknown as string,
                isMultiAgreementType: item.isMultiAgreementType,
                label: `${item.agreementNumber} - ${item.softwarePackageName}`,
                uploaderName: item.uploaderName,
                value: item.agreementId as number,
                viewingPartyCanCreateDeposit: item.viewingPartyCanCreateDeposit
            })),
        [data?.agreements]
    );

    const submit = useCallback(
        (formData: any) => {
            formData = formData.relatedAgreements.map((item: string) =>
                parseInt(item)
            );

            queryClient.invalidateQueries(['use-deposit-details-v2']);
            createDeposit(
                { relatedAgreements: { relatedAgreements: formData } },
                {
                    onError() {
                        toastFunctions.error(t('toast.error'));
                    },
                    onSuccess(res) {
                        toastFunctions.success(t('toast.success'));
                        localStorage.setItem('depositId', res.data.depositId);
                        goTo(
                            `/deposit-now/${res.data.depositId}/contact-details`
                        );
                    }
                }
            );
        },
        [createDeposit, goTo, queryClient, t]
    );

    const agreements = watch('relatedAgreements', []);

    const handleChange = (
        event: {
            target: { checked: boolean; value: any };
        },
        uploaderName: string
    ) => {
        const { checked, value } = event.target;
        if (checked) {
            setValue('relatedAgreements', [...agreements, value]);
            if (uploaderName === 'UK') {
                setDisplayUK(true);
                setDisplayUS(false);
            } else {
                setDisplayUS(true);
                setDisplayUK(false);
            }
        } else {
            setValue(
                'relatedAgreements',
                agreements.filter((item) => item !== value)
            );
            if (agreements.length === 1) {
                setDisplayUK(true);
                setDisplayUS(true);
            }
        }
    };

    // TODO: use below for toggleSelectAll
    // const agreementValues = useMemo(() => {
    //     return agreementIdsOptions.map((item) => item.value as number);
    // }, [agreementIdsOptions]);

    // const toggleSelectAll = () => {
    //     if (!checked) {
    //         setChecked(true);
    //         methods.setValue('relatedAgreements', [...agreementValues]);
    //     } else {
    //         setChecked(false);
    //         methods.setValue('relatedAgreements', []);
    //     }
    // };

    return (
        <FormProvider {...methods}>
            <form className="mt-4" onSubmit={methods.handleSubmit(submit)}>
                <DNStep
                    // actions={
                    //     <Button
                    //         variant="tertiary"
                    //         onClick={toggleSelectAll}
                    //         type="button"
                    //     >
                    //         {t('deposit-now-stage-one.select-all')}
                    //     </Button>
                    // }
                    containerClasses="flex flex-col gap-4 mb-2"
                    description={t('deposit-now-stage-one.description')}
                    displayAgreements={false}
                    disableBack
                    loading={isLoading}
                    submitting={creating}
                    title={t('deposit-now-stage-one.title')}
                >
                    <div className="border border-general-grey-grey-40 rounded-lg max-w-4xl h-[40vh] max-h-[60vh] overflow-y-auto flex flex-col items-start px-4 py-2 gap-2">
                        {agreementIdsOptions?.map(
                            (agreement: {
                                id: string;
                                label: string;
                                uploaderName: string | null | undefined;
                                value: number;
                            }) => {
                                if (!agreement.uploaderName) {
                                    return null;
                                }
                                return (
                                    <div
                                        className={cn(
                                            'cursor-pointer flex items-center gap-2',
                                            agreement.uploaderName === 'UK' &&
                                                !displayUK &&
                                                'hidden',
                                            agreement.uploaderName === 'US' &&
                                                !displayUS &&
                                                'hidden'
                                        )}
                                        key={agreement.id}
                                    >
                                        <input
                                            type="checkbox"
                                            className={cn(
                                                'accent-brand-escode-neonblue-neonblue-100 hover:accent-brand-escode-neonblue-neonblue-100 active:accent-brand-escode-neonblue-neonblue-100 focus:ring-brand-escode-neonblue-neonblue-100 transition-all duration-300 w-4 h-4'
                                            )}
                                            id={agreement.id}
                                            name="agreements"
                                            value={agreement.value}
                                            onChange={(e) =>
                                                handleChange(
                                                    e,
                                                    agreement.uploaderName!
                                                )
                                            }
                                        />
                                        <label
                                            className={cn('cursor-pointer')}
                                            htmlFor={agreement.id}
                                        >
                                            {agreement.label}
                                        </label>
                                    </div>
                                );
                            }
                        )}
                    </div>
                    {errors && (
                        <div className="max-w-4xl text-sm pt-1 text-general-red-red-100">
                            <p>{errors['relatedAgreements']?.message}</p>
                        </div>
                    )}
                </DNStep>
            </form>
        </FormProvider>
    );
};

export default AgreementStep;
