import { MouseEventHandler, ReactElement } from 'react';
import { SkeletonLine } from 'lib-ui';
import { isUndefined } from 'lodash';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

interface InfoSlateProps {
    loading?: boolean;
    onClick?: MouseEventHandler<HTMLDivElement>;
    text: string | undefined;
    title: string;
}

function InfoSlate({
    loading,
    onClick,
    text,
    title
}: InfoSlateProps): ReactElement {
    const { t } = useTranslation();

    const cssClasses = classNames(
        'px-5 py-3.5 bg-ncc-white border border-general-grey-grey-40 rounded-lg',
        { 'cursor-pointer': !isUndefined(onClick) }
    );

    return (
        <div className={cssClasses} onClick={onClick}>
            <div className="text-xs text-general-grey-grey-60 truncate">
                {title}
            </div>
            <div className="relative h-7 text-xl font-medium text-general-grey-grey-90 truncate">
                {loading ? (
                    <SkeletonLine className="absolute h-4 top-1/2 -translate-y-1/2 left-0 right-0" />
                ) : (
                    text?.trim() || t('no-data')
                )}
            </div>
        </div>
    );
}

export default InfoSlate;
export type { InfoSlateProps };
