import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import { useFormContext, useWatch } from 'react-hook-form';
import { useId, useState } from 'react';

import Field, { FieldProps } from './field';
import Input, { InputProps } from '../element/input';

interface InputFieldProps
    extends FieldProps,
        Omit<
            InputProps,
            | 'value'
            | 'name'
            | 'id'
            | 'error'
            | 'wrapperClassName'
            | 'wrapperStyle'
            | 'wrapperTailwindStyle'
        > {
    description?: React.ReactNode;
    suppressReq?: boolean;
}

function InputField({
    className,
    description,
    disabled,
    label,
    name,
    optional,
    orientation,
    readOnly,
    required,
    style,
    suppressReq,
    tailwindStyle,
    tooltip,
    tooltipDisabled,
    tooltipPlacement,
    type = 'text',
    ...restProps
}: InputFieldProps) {
    const {
        formState: { errors },
        register
    } = useFormContext();
    const formValue = useWatch({ name });
    const id = useId();
    const [showPassword, setShowPassword] = useState(false);

    const togglePassword = () => {
        setShowPassword((prev) => !prev);
    };

    // if (type === 'password') {
    //     restProps.type = 'password';
    // }

    return (
        <Field
            as="label"
            name={name}
            label={label}
            optional={optional}
            orientation={orientation}
            readOnly={readOnly}
            disabled={disabled}
            id={id}
            required={required}
            suppressReq={suppressReq}
            error={!!errors[name] ? <>{errors[name]?.message}</> : undefined}
            className={className}
            style={style}
            tailwindStyle={tailwindStyle}
            tooltip={tooltip}
            tooltipDisabled={tooltipDisabled}
            tooltipPlacement={tooltipPlacement}
        >
            <div className="relative">
                <Input
                    {...restProps}
                    id={id}
                    {...register(name)}
                    value={formValue}
                    readOnly={readOnly}
                    disabled={disabled}
                    error={!!errors[name]}
                    type={type === 'password' && showPassword ? 'text' : type}
                />
                {type === 'password' && showPassword && (
                    <FontAwesomeIcon
                        icon={faEye}
                        onClick={togglePassword}
                        className="cursor-pointer absolute text-lg right-2 top-3 z-50"
                    />
                )}
                {type === 'password' && !showPassword && (
                    <FontAwesomeIcon
                        icon={faEyeSlash}
                        onClick={togglePassword}
                        className="cursor-pointer absolute text-lg right-2 top-3 z-50"
                    />
                )}
            </div>
            {!!!errors[name] && description && (
                <div className="mt-1">
                    <p className="text-sm">{description}</p>
                </div>
            )}
        </Field>
    );
}

export default InputField;
export type { InputFieldProps };
