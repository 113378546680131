import { GetSourceCodeProvidersResponse } from '../../autogenerated/data-contracts';

const useGetProvidersMockData: GetSourceCodeProvidersResponse = {
    errors: {},
    providers: [
        {
            authenticationType: 1,
            connectionName: 'Git HTTPS',
            id: 7,
            providerType: 1,
            repositoryAddress:
                'https://github.com/IntegrityGit6580/ViewTest2.git',
            schedules: 17,
            userName: 'IntegrityGit6580'
        },
        {
            authenticationType: 0,
            connectionName: 'Git SSH2',
            id: 10,
            providerType: 1,
            repositoryAddress: 'git@github.com:IntegrityGit6580/ViewTest2.git',
            schedules: 0,
            userName: 'IntegrityGit6580'
        },
        {
            authenticationType: 1,
            connectionName: 'GIT HTTPS 3',
            id: 17,
            providerType: 1,
            repositoryAddress:
                'https://github.com/IntegrityGit6580/ViewTest2.git',
            schedules: 0,
            userName: 'IntegrityGit6580'
        },
        {
            authenticationType: 0,
            connectionName: 'GIT SSH2',
            id: 14,
            providerType: 1,
            repositoryAddress: 'git@github.com:IntegrityGit6580/ViewTest2.git',
            schedules: 0,
            userName: 'IntegrityGit6580'
        },
        {
            authenticationType: 1,
            connectionName: 'httpstest',
            id: 16,
            providerType: 1,
            repositoryAddress:
                'https://github.com/IntegrityGit6580/ViewTest2.git',
            schedules: 0,
            userName: 'IntegrityGit6580'
        },
        {
            authenticationType: 1,
            connectionName: 'HTTPS-5',
            id: 119,
            providerType: 1,
            repositoryAddress:
                'https://github.com/IntegrityGit6580/ViewTest2.git',
            schedules: 2,
            userName: 'IntegrityGit6580'
        },
        {
            authenticationType: 1,
            connectionName: 'HTTPS-6',
            id: 120,
            providerType: 1,
            repositoryAddress:
                'https://github.com/IntegrityGit6580/ViewTest2.git',
            schedules: 2,
            userName: 'IntegrityGit6580'
        },
        {
            authenticationType: 1,
            connectionName: 'HTTPS-7',
            id: 121,
            providerType: 1,
            repositoryAddress:
                'https://github.com/IntegrityGit6580/ViewTest2.git',
            schedules: 2,
            userName: 'IntegrityGit6580'
        },
        {
            authenticationType: 1,
            connectionName: 'HTTPS-8',
            id: 122,
            providerType: 1,
            repositoryAddress:
                'https://github.com/IntegrityGit6580/ViewTest2.git',
            schedules: 2,
            userName: 'IntegrityGit6580'
        },

        {
            authenticationType: 1,
            connectionName: 'HTTPS-10',
            id: 124,
            providerType: 1,
            repositoryAddress:
                'https://github.com/IntegrityGit6580/ViewTest2.git',
            schedules: 2,
            userName: 'IntegrityGit6580'
        }
    ],
    success: true
};

export default useGetProvidersMockData;
