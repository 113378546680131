import { PropsWithChildren } from 'react';
import { Widget } from 'lib-ui';
import { useTranslation } from 'react-i18next';
import AppBody from 'app-layout/bodies/app-body';
import DepositStepper from './deposit-stepper';

type DepositNowStepOptions =
    | 'agreement'
    | 'contact-details'
    | 'deposit-details'
    | 'content'
    | 'review-deposit'
    | 'disclaimer'
    | 'upload';

interface DepositNowStepperProps {
    step: DepositNowStepOptions;
}

const DepositNowContainer = ({
    children,
    step
}: PropsWithChildren<DepositNowStepperProps>) => {
    const { t } = useTranslation();

    return (
        <AppBody>
            <Widget className="h-full">
                <Widget.Header title={t('deposit-now.title')}>
                    <DepositStepper step={step} />
                </Widget.Header>
                <Widget.Body className="p-5 h-auto overflow-auto">
                    {children}
                </Widget.Body>
            </Widget>
        </AppBody>
    );
};

export default DepositNowContainer;
