import {
    ChangeEventHandler,
    ForwardedRef,
    PropsWithChildren,
    forwardRef,
    useCallback
} from 'react';
import {
    RadioProps as CoreRadioProps,
    TailwindStyle,
    tailwindClasses
} from '@ncc-frontend/core';
import classNames from 'classnames';

interface RadioProps extends CoreRadioProps {
    error?: boolean;
    iconClassName?: string;
    iconTailwindStyle?: TailwindStyle;
}

function Radio(
    {
        checked,
        children,
        className,
        disabled,
        error,
        iconClassName,
        iconTailwindStyle,
        onChange,
        readOnly,
        ...restProps
    }: PropsWithChildren<RadioProps>,
    ref: ForwardedRef<HTMLInputElement>
) {
    const iconCssClasses = classNames(
        tailwindClasses({
            background:
                readOnly && checked
                    ? 'bg-ncc-grey-40'
                    : checked && 'bg-brand-escode-neonblue-neonblue-100',
            border: 'border-4',
            borderColor: 'border-ncc-white',
            borderRadius: 'rounded-full',
            height: 'h-4',
            ring: 'ring-1',
            ringColor:
                disabled || readOnly
                    ? 'ring-escode-grey-30'
                    : 'ring-brand-escode-neonblue-neonblue-100',
            width: 'w-4'
        }),
        iconClassName
    );

    const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
        async (event) => {
            if (readOnly) return;
            await onChange?.(event);
        },
        [onChange, readOnly]
    );

    return (
        <>
            <label
                className={classNames('relative py-1 flex gap-2 ml-[0.05rem]', {
                    'text-escode-grey-50': readOnly || disabled
                })}
            >
                <input
                    ref={ref}
                    {...restProps}
                    type="radio"
                    className="absolute top-0 left-0 opacity-0"
                    readOnly={readOnly}
                    checked={checked}
                    onChange={handleChange}
                    disabled={disabled}
                />
                <div className={iconCssClasses} />
                {children}
            </label>
        </>
    );
}

export default forwardRef(Radio);
export type { RadioProps };
