/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    AddAgreementParty,
    AddSshKeyRequestViewModel,
    AgreementDetails,
    AgreementListResponseViewModel,
    ClientContactsResponseViewModel,
    ContactDetails,
    ContactResponseViewModel,
    ContactUsRequest,
    CreateDepositViewModelResponse,
    CreateDownloadScheduleRequest,
    CreateProviderViewModelResponse,
    CreateSourceCodeProviderRequest,
    DataCentreResponse,
    DeclaredDepositFileTypes,
    DeleteDownloadScheduleResponse,
    DeleteProviderViewModelResponse,
    DeleteSshKeyRequestViewModel,
    DeliveryMethod,
    DepositContent,
    DepositDataCentre,
    DepositReportFilter,
    DepositSortColumn,
    DepositSpecs,
    DepositType,
    DepositTypesFilter,
    DisableUserResponseViewModel,
    DocumentMetadataResponseViewModel,
    EditDepositResponse,
    EditDownloadScheduleViewModelRequest,
    EditSourceCodeProviderViewModelRequest,
    EditSourceCodeProviderViewModelResponse,
    FeedbackRequest,
    GetAgreementPartyContactChangesResponseViewModel,
    GetContactAuditResponseViewModel,
    GetDeclaredDepositFileTypesResponse,
    GetDepositFilesResponse,
    GetDepositFileTypesResponse,
    GetDepositHistoryResponse,
    GetDepositResponse,
    GetDepositSourceControlDetails,
    GetDepositsResponse,
    GetDepositTemplateResponse,
    GetDownloadSchedulesResponse,
    GetIncompleteDepositsResponse,
    GetSourceCodeProvidersResponse,
    GetSourceCodeProviderViewModelResponse,
    GetSshKeysResponseViewModel,
    GetSystemConfigurationListResponse,
    GetTooltipsResponseViewModel,
    GetUiTextResponseViewModel,
    InviteContactResponse,
    NotificationsResponseViewModel,
    RegistrationDetails,
    RegistrationResponse,
    RelatedDepositAgreements,
    ReleasesResponse,
    SoftwareDetails,
    SpectreDepositResponse,
    UiCultureResponse,
    UiCultureUpdate,
    UpdateContactRequestViewModel,
    UpdateContactResponseViewModel,
    UpdateRolesOnAgreementPartyContactRequestViewModel,
    UpdateUserDetailsRequestViewModel,
    UploadCompleteRequest,
    UploaderAddFilesToDepositResponse,
    UploaderDepositRequest,
    UploadFailedRequest,
    UploadStartRequest,
    UserResponseViewModel,
    UserSetting,
    VerifyRepositoryConfigurationViewModelRequest
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class ApiController<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags Agreement
     * @name GetAgreements
     * @summary Gets the clients agreements.
     * @request GET:/api/v1/client/agreements
     * @secure
     */
    getAgreements = (
        query?: {
            /** Gets or sets a value indicating whether [include live only]. */
            IncludeLiveOnly?: boolean;
            /** Gets or sets deposit now to indicate whether to include agreements which can be deposited. */
            DepositNow?: boolean;
        },
        params: RequestParams = {}
    ) =>
        this.request<AgreementListResponseViewModel, any>({
            path: `/api/v1/client/agreements`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags Agreement
     * @name GetAgreement
     * @summary Gets the clients agreement details for specific agreement.
     * @request GET:/api/v1/client/agreements/{agreementId}
     * @secure
     */
    getAgreement = (agreementId: number, params: RequestParams = {}) =>
        this.request<AgreementDetails, any>({
            path: `/api/v1/client/agreements/${agreementId}`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags Agreement
     * @name GetAgreementDataCentres
     * @summary Gets the DataCentres for specific agreement.
     * @request GET:/api/v1/client/agreements/{agreementId}/datacentres
     * @secure
     */
    getAgreementDataCentres = (
        agreementId: number,
        query?: {
            /** Gets or sets the Method. */
            Method?: string;
        },
        params: RequestParams = {}
    ) =>
        this.request<DataCentreResponse, any>({
            path: `/api/v1/client/agreements/${agreementId}/datacentres`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags Agreement
     * @name AddAgreementParty
     * @summary Create a new agreement party listRequest.
     * @request POST:/api/v1/client/agreements/{agreementId}/addAgreementParty
     * @secure
     */
    addAgreementParty = (agreementId: number, data: AddAgreementParty, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/v1/client/agreements/${agreementId}/addAgreementParty`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * No description
     *
     * @tags Agreement
     * @name InitiateTermination
     * @summary Initiates the termination.
     * @request POST:/api/v1/client/agreements/{agreementId}/initiate-termination
     * @secure
     */
    initiateTermination = (
        agreementId: number,
        data: {
            /** Gets or sets the name. */
            Name: string;
            /** Gets or sets the job title. */
            JobTitle: string;
            /** Gets or sets the company. */
            Company: string;
            /** Gets or sets the phone. */
            Telephone: string;
            /** Gets or sets the email address. */
            EmailAddress: string;
            /** Gets or sets the correspondence address. */
            CorrespondenceAddress: string;
            /** Gets or sets the date of termination. */
            DateOfTermination: string;
            /** Gets or sets the reason for the termination. */
            ReasonForTheTermination: string;
            /** Gets or sets the comments. */
            Comments?: string;
            /** Gets or sets the type of the party. */
            PartyType: string;
            /**
             * Gets or sets the supporting documentation file.
             * @format binary
             */
            SupportingDocumentation?: File;
        },
        params: RequestParams = {}
    ) =>
        this.request<void, any>({
            path: `/api/v1/client/agreements/${agreementId}/initiate-termination`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.FormData,
            ...params
        });
    /**
     * No description
     *
     * @tags Agreements
     * @name V1ClientAgreementsRequestNovationCreate
     * @summary Requests the novation.
     * @request POST:/api/v1/client/Agreements/request-novation
     * @secure
     */
    v1ClientAgreementsRequestNovationCreate = (data: any, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/v1/client/Agreements/request-novation`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * No description
     *
     * @tags Agreement
     * @name V1ClientAgreementsInitiateReleaseCreate
     * @summary Initiates a release.
     * @request POST:/api/v1/client/agreements/initiate-release
     * @secure
     */
    v1ClientAgreementsInitiateReleaseCreate = (
        data: {
            /** Gets or sets the agreement ids. */
            AgreementIds: number[];
            /** Delivery Method. */
            DeliveryMethod?: DeliveryMethod;
            /**
             * Gets or sets the contact identifier.
             * @format int32
             */
            ReleaseContactId?: number;
            /**
             * Gets or sets the statutory declaration file.
             * @format binary
             */
            StatutoryDeclaration?: File;
            /**
             * Gets or sets the evidence file.
             * @format binary
             */
            EvidenceFile?: File;
            /** Gets or sets the name of the invoice account. */
            InvoiceAccountName?: string;
            /** Gets or sets the invoice account address. */
            InvoiceAccountAddress?: string;
        },
        params: RequestParams = {}
    ) =>
        this.request<void, any>({
            path: `/api/v1/client/agreements/initiate-release`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.FormData,
            ...params
        });
    /**
     * No description
     *
     * @tags AgreementPartyContact
     * @name UpdateAgreementPartyContact
     * @summary Updates the specified agreement identifier.
     * @request POST:/api/v1/agreements/{agreementId}/{agreementPartyId}/agreementpartycontact/update
     * @secure
     */
    updateAgreementPartyContact = (
        agreementId: number,
        agreementPartyId: number,
        data: UpdateRolesOnAgreementPartyContactRequestViewModel[],
        params: RequestParams = {}
    ) =>
        this.request<void, any>({
            path: `/api/v1/agreements/${agreementId}/${agreementPartyId}/agreementpartycontact/update`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * No description
     *
     * @tags Auditing
     * @name GetAgreementPartyContactChanges
     * @summary Gets party contact changes for the specified agreement.
     * @request GET:/api/v1/getagreementpartycontactchanges
     * @secure
     */
    getAgreementPartyContactChanges = (
        query?: {
            /**
             * The agreement identifier.
             * @format int32
             */
            agreementId?: number;
        },
        params: RequestParams = {}
    ) =>
        this.request<GetAgreementPartyContactChangesResponseViewModel, any>({
            path: `/api/v1/getagreementpartycontactchanges`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags Contact
     * @name GetClientContacts
     * @summary Gets the client by reference.
     * @request GET:/api/v1/Contact
     * @secure
     */
    getClientContacts = (params: RequestParams = {}) =>
        this.request<ClientContactsResponseViewModel, any>({
            path: `/api/v1/Contact`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags Contact
     * @name V1ContactDetail
     * @summary Gets the contact by identifier.
     * @request GET:/api/v1/Contact/{id}
     * @secure
     */
    v1ContactDetail = (id: number, params: RequestParams = {}) =>
        this.request<ContactResponseViewModel, any>({
            path: `/api/v1/Contact/${id}`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags Contact
     * @name V1ContactUpdate
     * @summary Updates the contact.
     * @request PUT:/api/v1/Contact/{id}
     * @secure
     */
    v1ContactUpdate = (id: number, data: UpdateContactRequestViewModel, params: RequestParams = {}) =>
        this.request<UpdateContactResponseViewModel, any>({
            path: `/api/v1/Contact/${id}`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags Contact
     * @name GetAuditForContact
     * @summary Gets the audit.
     * @request GET:/api/v1/Contact/Audit
     * @secure
     */
    getAuditForContact = (
        query?: {
            /**
             * The contact identifier.
             * @format int32
             */
            contactId?: number;
        },
        params: RequestParams = {}
    ) =>
        this.request<GetContactAuditResponseViewModel, any>({
            path: `/api/v1/Contact/Audit`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags Contact
     * @name InviteContact
     * @summary A method to invite a contact to become a user.
     * @request POST:/api/v1/Contact/{id}/invite
     * @secure
     */
    inviteContact = (id: number, params: RequestParams = {}) =>
        this.request<InviteContactResponse, any>({
            path: `/api/v1/Contact/${id}/invite`,
            method: 'POST',
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags ContactUs
     * @name ContactUs
     * @summary Contacts the us.
     * @request POST:/api/v1/Contact/contactus
     * @secure
     */
    contactUs = (data: ContactUsRequest, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/v1/Contact/contactus`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * No description
     *
     * @tags ContactUs
     * @name CustomerFeedback
     * @summary Customer feedback on the Escrow View portal.
     * @request POST:/api/v1/Contact/sendfeedback
     * @secure
     */
    customerFeedback = (data: FeedbackRequest, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/v1/Contact/sendfeedback`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * No description
     *
     * @tags Deposit
     * @name CreateSourceCodeProvider
     * @summary Create a new source code provider.
     * @request POST:/api/v1/createprovider
     * @secure
     */
    createSourceCodeProvider = (data: CreateSourceCodeProviderRequest, params: RequestParams = {}) =>
        this.request<CreateProviderViewModelResponse, any>({
            path: `/api/v1/createprovider`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags Deposit
     * @name GetSourceCodeProviders
     * @summary Get the source code providers.
     * @request GET:/api/v1/getproviders
     * @secure
     */
    getSourceCodeProviders = (params: RequestParams = {}) =>
        this.request<GetSourceCodeProvidersResponse, any>({
            path: `/api/v1/getproviders`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags Deposit
     * @name CreateDownloadSchedule
     * @summary Create a new download schedule related to a deposit.
     * @request POST:/api/v1/createschedule
     * @secure
     */
    createDownloadSchedule = (data: CreateDownloadScheduleRequest, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/v1/createschedule`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * @description This function doesn't actually write anything, but needs to be a POST so the parameters can be passed correctly. Also, it doesn't need a write policy requirement.
     *
     * @tags Deposit
     * @name VerifyRepositoryConfiguration
     * @summary Verify the repository configuration is valid.
     * @request POST:/api/v1/verifyrepositoryconfiguration
     * @secure
     */
    verifyRepositoryConfiguration = (data: VerifyRepositoryConfigurationViewModelRequest, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/v1/verifyrepositoryconfiguration`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * No description
     *
     * @tags Deposit
     * @name EditDownloadSchedule
     * @summary Edit an existing download schedule.
     * @request PUT:/api/v1/editschedule
     * @secure
     */
    editDownloadSchedule = (data: EditDownloadScheduleViewModelRequest, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/v1/editschedule`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * No description
     *
     * @tags Deposit
     * @name DeleteDownloadSchedule
     * @summary Delete a source code download schedules related to a deposit.
     * @request DELETE:/api/v1/deleteschedule
     * @secure
     */
    deleteDownloadSchedule = (
        query?: {
            /**
             * The schedule to delete.
             * @format int32
             */
            downloadId?: number;
        },
        params: RequestParams = {}
    ) =>
        this.request<any, DeleteDownloadScheduleResponse>({
            path: `/api/v1/deleteschedule`,
            method: 'DELETE',
            query: query,
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags Deposit
     * @name GetDownloadSchedules
     * @summary Get source code download schedules created by a user.
     * @request GET:/api/v1/getschedules
     * @secure
     */
    getDownloadSchedules = (params: RequestParams = {}) =>
        this.request<GetDownloadSchedulesResponse, any>({
            path: `/api/v1/getschedules`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags Deposit
     * @name DeleteDownloadProvider
     * @summary Delete a source code download provider.
     * @request DELETE:/api/v1/deleteprovider
     * @secure
     */
    deleteDownloadProvider = (
        query?: {
            /**
             * The provider to delete.
             * @format int32
             */
            providerId?: number;
        },
        params: RequestParams = {}
    ) =>
        this.request<any, DeleteProviderViewModelResponse>({
            path: `/api/v1/deleteprovider`,
            method: 'DELETE',
            query: query,
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags Deposit
     * @name EditSourceCodeProvider
     * @summary Edit an existing source code provider.
     * @request PUT:/api/v1/editprovider
     * @secure
     */
    editSourceCodeProvider = (data: EditSourceCodeProviderViewModelRequest, params: RequestParams = {}) =>
        this.request<EditSourceCodeProviderViewModelResponse, any>({
            path: `/api/v1/editprovider`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags Deposit
     * @name ChecksIfProviderHasAtLeastOneDepositLinkedToIt
     * @summary Checks if provider has deposit linked.
     * @request GET:/api/v1/providerhasdepositlinked/{providerId}
     * @secure
     */
    checksIfProviderHasAtLeastOneDepositLinkedToIt = (providerId: number, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/v1/providerhasdepositlinked/${providerId}`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags Deposit
     * @name GetASourceCodeProviderDetails
     * @summary Get an existing source code provider.
     * @request GET:/api/v1/getprovider
     * @secure
     */
    getASourceCodeProviderDetails = (
        query?: {
            /**
             * The provider to get.
             * @format int32
             */
            providerId?: number;
        },
        params: RequestParams = {}
    ) =>
        this.request<GetSourceCodeProviderViewModelResponse, any>({
            path: `/api/v1/getprovider`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags Deposit
     * @name GetDeposits
     * @summary Get deposits.
     * @request GET:/api/v1/agreements/{agreementId}/deposits
     * @secure
     */
    getDeposits = (
        agreementId: number,
        query?: {
            /** Deposit Type. */
            depositType?: DepositTypesFilter;
            /**
             * Page Number.
             * @format int32
             * @default 1
             */
            pageNumber?: number;
            /**
             * Page Size.
             * @format int32
             * @default 10
             */
            pageSize?: number;
            /** Order by column. */
            orderBy?: DepositSortColumn;
            /**
             * Order by descending.
             * @default true
             */
            orderByDescending?: boolean;
        },
        params: RequestParams = {}
    ) =>
        this.request<GetDepositsResponse, any>({
            path: `/api/v1/agreements/${agreementId}/deposits`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags Deposit
     * @name CreateDeposit
     * @summary Creates the deposit.
     * @request POST:/api/v1/agreements/{agreementId}/deposits
     * @secure
     */
    createDeposit = (agreementId: number, data: ContactDetails, params: RequestParams = {}) =>
        this.request<CreateDepositViewModelResponse, any>({
            path: `/api/v1/agreements/${agreementId}/deposits`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags Deposit
     * @name GetDepositDetails
     * @summary Get Deposit.
     * @request GET:/api/v1/agreements/{agreementId}/deposits/{depositId}
     * @secure
     */
    getDepositDetails = (depositId: number, agreementId: string, params: RequestParams = {}) =>
        this.request<GetDepositResponse, any>({
            path: `/api/v1/agreements/${agreementId}/deposits/${depositId}`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags Deposit
     * @name V1AgreementsDepositsDelete
     * @summary Discard the specified deposit.
     * @request DELETE:/api/v1/agreements/{agreementId}/deposits/{depositId}
     * @secure
     */
    v1AgreementsDepositsDelete = (depositId: number, agreementId: string, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/v1/agreements/${agreementId}/deposits/${depositId}`,
            method: 'DELETE',
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags Deposit
     * @name GetAllDeposits
     * @summary Get deposits.
     * @request GET:/api/v1/agreements/deposits
     * @secure
     */
    getAllDeposits = (params: RequestParams = {}) =>
        this.request<GetDepositsResponse, any>({
            path: `/api/v1/agreements/deposits`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags Deposit
     * @name GetDepositTemplate
     * @summary Returns a template deposit for this agreement.
     * @request GET:/api/v1/agreements/{agreementId}/deposit-template
     * @secure
     */
    getDepositTemplate = (agreementId: number, params: RequestParams = {}) =>
        this.request<GetDepositTemplateResponse, any>({
            path: `/api/v1/agreements/${agreementId}/deposit-template`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags Deposit
     * @name V1DepositsIncompleteDepositsList
     * @summary Get incomplete deposits for the current user.
     * @request GET:/api/v1/deposits/IncompleteDeposits
     * @secure
     */
    v1DepositsIncompleteDepositsList = (params: RequestParams = {}) =>
        this.request<GetIncompleteDepositsResponse, any>({
            path: `/api/v1/deposits/IncompleteDeposits`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags Deposit
     * @name GetMediaCheckReport
     * @summary Gets the media check report for a deposit.
     * @request GET:/api/v1/deposits/GetDepositMediaCheckReport/{depositId}/{languageId}
     * @secure
     */
    getMediaCheckReport = (depositId: number, languageId: number, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/v1/deposits/GetDepositMediaCheckReport/${depositId}/${languageId}`,
            method: 'GET',
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags Deposit
     * @name V1AgreementsDepositsContactPartialUpdate
     * @summary Edit Deposit: Old Front-end Step 1 - Contact Info.
     * @request PATCH:/api/v1/agreements/{agreementId}/deposits/{depositId}/contact
     * @secure
     */
    v1AgreementsDepositsContactPartialUpdate = (
        depositId: number,
        agreementId: string,
        data: ContactDetails,
        params: RequestParams = {}
    ) =>
        this.request<EditDepositResponse, any>({
            path: `/api/v1/agreements/${agreementId}/deposits/${depositId}/contact`,
            method: 'PATCH',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags Deposit
     * @name V1AgreementsDepositsRelatedagreementsPartialUpdate
     * @summary Edit Deposit: Old Front-end Step 2 - Related Areements.
     * @request PATCH:/api/v1/agreements/{agreementId}/deposits/{depositId}/relatedagreements
     * @secure
     */
    v1AgreementsDepositsRelatedagreementsPartialUpdate = (
        depositId: number,
        agreementId: string,
        data: RelatedDepositAgreements,
        params: RequestParams = {}
    ) =>
        this.request<EditDepositResponse, any>({
            path: `/api/v1/agreements/${agreementId}/deposits/${depositId}/relatedagreements`,
            method: 'PATCH',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags Deposit
     * @name EditDepositDetailsStep2
     * @summary Edit the deposit details. Step 2.
     * @request PATCH:/api/v1/agreements/{agreementId}/deposits/{depositId}/software
     * @secure
     */
    editDepositDetailsStep2 = (
        depositId: number,
        agreementId: string,
        data: SoftwareDetails,
        params: RequestParams = {}
    ) =>
        this.request<EditDepositResponse, any>({
            path: `/api/v1/agreements/${agreementId}/deposits/${depositId}/software`,
            method: 'PATCH',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags Deposit
     * @name EditDepositDetailsStep3
     * @summary Edit the deposit details. Step 3.
     * @request PATCH:/api/v1/agreements/{agreementId}/deposits/{depositId}/type
     * @secure
     */
    editDepositDetailsStep3 = (depositId: number, agreementId: string, data: DepositType, params: RequestParams = {}) =>
        this.request<EditDepositResponse, any>({
            path: `/api/v1/agreements/${agreementId}/deposits/${depositId}/type`,
            method: 'PATCH',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags Deposit
     * @name EditDepositDetailsStep3SetDeclaredFileTypes
     * @summary Set the customer declared types of source code in the deposit. Step 3.
     * @request PATCH:/api/v1/agreements/{agreementId}/deposits/{depositId}/filetypes
     * @secure
     */
    editDepositDetailsStep3SetDeclaredFileTypes = (
        depositId: number,
        agreementId: string,
        data: DeclaredDepositFileTypes,
        params: RequestParams = {}
    ) =>
        this.request<void, any>({
            path: `/api/v1/agreements/${agreementId}/deposits/${depositId}/filetypes`,
            method: 'PATCH',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * No description
     *
     * @tags Deposit
     * @name EditDepositDetailsStep3GetDeclaredFileTypes
     * @summary Get the customer declared types of source code in the deposit. Step 3.
     * @request GET:/api/v1/agreements/{agreementId}/deposits/{depositId}/filetypes
     * @secure
     */
    editDepositDetailsStep3GetDeclaredFileTypes = (
        depositId: number,
        agreementId: string,
        params: RequestParams = {}
    ) =>
        this.request<GetDeclaredDepositFileTypesResponse, any>({
            path: `/api/v1/agreements/${agreementId}/deposits/${depositId}/filetypes`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags Deposit
     * @name EditDepositDetailsStep4
     * @summary Edit the deposit details. Step 4.
     * @request PATCH:/api/v1/agreements/{agreementId}/deposits/{depositId}/specs
     * @secure
     */
    editDepositDetailsStep4 = (
        depositId: number,
        agreementId: string,
        data: DepositSpecs,
        params: RequestParams = {}
    ) =>
        this.request<EditDepositResponse, any>({
            path: `/api/v1/agreements/${agreementId}/deposits/${depositId}/specs`,
            method: 'PATCH',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags Deposit
     * @name EditDepositDetailsStep5
     * @summary Edit Deposit: Old Front-end Step 6 - Deposit Content.
     * @request PATCH:/api/v1/agreements/{agreementId}/deposits/{depositId}/content
     * @secure
     */
    editDepositDetailsStep5 = (
        depositId: number,
        agreementId: string,
        data: DepositContent,
        params: RequestParams = {}
    ) =>
        this.request<EditDepositResponse, any>({
            path: `/api/v1/agreements/${agreementId}/deposits/${depositId}/content`,
            method: 'PATCH',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags Deposit
     * @name V1AgreementsDepositsDatacentreUpdate
     * @summary Set the deposit datacentre.
     * @request PUT:/api/v1/agreements/{agreementId}/deposits/{depositId}/datacentre
     * @secure
     */
    v1AgreementsDepositsDatacentreUpdate = (
        depositId: number,
        agreementId: string,
        data: DepositDataCentre,
        params: RequestParams = {}
    ) =>
        this.request<EditDepositResponse, any>({
            path: `/api/v1/agreements/${agreementId}/deposits/${depositId}/datacentre`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags Deposit
     * @name ConfirmDeposit
     * @summary Set deposit status to confirmed.
     * @request POST:/api/v1/agreements/{agreementId}/deposits/{depositId}/confirm
     * @secure
     */
    confirmDeposit = (depositId: number, agreementId: string, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/v1/agreements/${agreementId}/deposits/${depositId}/confirm`,
            method: 'POST',
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags Deposit
     * @name StartSftpDeposit
     * @summary Start sftp deposit process.
     * @request POST:/api/v1/deposit/{depositId}/startsftpdeposit
     * @secure
     */
    startSftpDeposit = (depositId: number, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/v1/deposit/${depositId}/startsftpdeposit`,
            method: 'POST',
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags Deposit
     * @name DepositFiles
     * @summary Get files added to the deposit.
     * @request GET:/api/v1/agreements/{agreementId}/deposits/{depositId}/files
     * @secure
     */
    depositFiles = (depositId: number, agreementId: string, params: RequestParams = {}) =>
        this.request<GetDepositFilesResponse, any>({
            path: `/api/v1/agreements/${agreementId}/deposits/${depositId}/files`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags Deposit
     * @name V1DepositsSpectretokenDetail
     * @summary Get a spectre token to add or delete files to the deposit.
     * @request GET:/api/v1/deposits/{depositId}/spectretoken
     * @secure
     */
    v1DepositsSpectretokenDetail = (depositId: number, params: RequestParams = {}) =>
        this.request<SpectreDepositResponse, any>({
            path: `/api/v1/deposits/${depositId}/spectretoken`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags Deposit
     * @name GetDepositFileTypes
     * @summary Get the file types recognised by an Armadillo media check.
     * @request GET:/api/v1/deposits/GetDepositFileTypes/{typeId}
     * @secure
     */
    getDepositFileTypes = (typeId: number, params: RequestParams = {}) =>
        this.request<GetDepositFileTypesResponse, any>({
            path: `/api/v1/deposits/GetDepositFileTypes/${typeId}`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags Deposit
     * @name DepositSourceControlDetails
     * @summary Get deposit source control details.
     * @request GET:/api/v1/{depositId}/sourcecontroldetails
     * @secure
     */
    depositSourceControlDetails = (depositId: number, params: RequestParams = {}) =>
        this.request<GetDepositSourceControlDetails, any>({
            path: `/api/v1/${depositId}/sourcecontroldetails`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags Deposit
     * @name GetDepositHistory
     * @summary Get deposit history for a user's agreements.
     * @request GET:/api/v1/deposits/GetDepositHistory/{months}
     * @secure
     */
    getDepositHistory = (months: number, params: RequestParams = {}) =>
        this.request<GetDepositHistoryResponse, any>({
            path: `/api/v1/deposits/GetDepositHistory/${months}`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags Deposit
     * @name V1DepositsGetDepositHistoryReportCreate
     * @summary Get deposit history for a user's agreements.
     * @request POST:/api/v1/deposits/GetDepositHistoryReport
     * @secure
     */
    v1DepositsGetDepositHistoryReportCreate = (data: DepositReportFilter, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/v1/deposits/GetDepositHistoryReport`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * No description
     *
     * @tags Deposit
     * @name V1DepositsGetDepositHistoryReportDetail
     * @summary Gets a deposit history report.
     * @request GET:/api/v1/deposits/GetDepositHistoryReport/{months}
     * @secure
     */
    v1DepositsGetDepositHistoryReportDetail = (months: number, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/v1/deposits/GetDepositHistoryReport/${months}`,
            method: 'GET',
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags Deposit
     * @name V1EditdepositsourcecontroldetailsCreate
     * @summary Confirm details.
     * @request POST:/api/v1/{depositId}/editdepositsourcecontroldetails
     * @secure
     */
    v1EditdepositsourcecontroldetailsCreate = (
        depositId: number,
        data: GetDepositSourceControlDetails,
        params: RequestParams = {}
    ) =>
        this.request<void, any>({
            path: `/api/v1/${depositId}/editdepositsourcecontroldetails`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * @description Not to be mistaken for confirm deposit this sets the deposit to In Progress.
     *
     * @tags Deposit
     * @name V1ConfirmDepositDetailsCreate
     * @summary Confirm details.
     * @request POST:/api/v1/{depositId}/ConfirmDepositDetails
     * @secure
     */
    v1ConfirmDepositDetailsCreate = (depositId: number, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/v1/${depositId}/ConfirmDepositDetails`,
            method: 'POST',
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags Deposit
     * @name V1DeclineOversizedDepositFeesUpdate
     * @summary Declines the oversized deposit fees.
     * @request PUT:/api/v1/{depositId}/declineOversizedDepositFees
     * @secure
     */
    v1DeclineOversizedDepositFeesUpdate = (depositId: number, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/v1/${depositId}/declineOversizedDepositFees`,
            method: 'PUT',
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags DepositUpload
     * @name StartFileUpload
     * @summary Upload of a file about to start.
     * @request POST:/api/v1/depositupload/{depositId}/startfileupload
     * @secure
     */
    startFileUpload = (depositId: number, data: UploadStartRequest, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/v1/depositupload/${depositId}/startfileupload`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * No description
     *
     * @tags DepositUpload
     * @name FileUploadOperationFailed
     * @summary File Upload operation failed.
     * @request POST:/api/v1/depositupload/{depositId}/uploadfailed
     * @secure
     */
    fileUploadOperationFailed = (depositId: number, data: UploadFailedRequest, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/v1/depositupload/${depositId}/uploadfailed`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * No description
     *
     * @tags DepositUpload
     * @name DeleteUpload
     * @summary Delete of a single file.
     * @request DELETE:/api/v1/depositupload/{depositId}/delete
     * @secure
     */
    deleteUpload = (
        depositId: number,
        query: {
            /**
             * The file identifier.
             * @format uuid
             */
            FileId: string;
        },
        params: RequestParams = {}
    ) =>
        this.request<void, any>({
            path: `/api/v1/depositupload/${depositId}/delete`,
            method: 'DELETE',
            query: query,
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags Document
     * @name GetAgreementContracts
     * @summary Gets the agreement documents meta data.
     * @request GET:/api/v1/client/getagreementcontracts/{agreementId}
     * @secure
     */
    getAgreementContracts = (agreementId: number, params: RequestParams = {}) =>
        this.request<DocumentMetadataResponseViewModel, any>({
            path: `/api/v1/client/getagreementcontracts/${agreementId}`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags Document
     * @name DownloadAgreementContracts
     * @summary Downloads signed agreement or party document.
     * @request GET:/api/v1/client/downloadagreementcontracts/{agreementId}/{index}
     * @secure
     */
    downloadAgreementContracts = (agreementId: number, index: string, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/v1/client/downloadagreementcontracts/${agreementId}/${index}`,
            method: 'GET',
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags Document
     * @name GetTheSingleIntegrityReportForADeposit
     * @summary Gets the integrity report metadata.
     * @request GET:/api/v1/client/getintegrityreport/{depositId}
     * @secure
     */
    getTheSingleIntegrityReportForADeposit = (depositId: number, params: RequestParams = {}) =>
        this.request<DocumentMetadataResponseViewModel, any>({
            path: `/api/v1/client/getintegrityreport/${depositId}`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags Document
     * @name DownloadIntegrityReport
     * @summary Downloads integrity report.
     * @request GET:/api/v1/client/downloadintegrityreport/{depositId}/{index}
     * @secure
     */
    downloadIntegrityReport = (depositId: number, index: string, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/v1/client/downloadintegrityreport/${depositId}/${index}`,
            method: 'GET',
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags Document
     * @name GetTheMultipleVerificationReportsForADepositLinkedToAnAgreement
     * @summary Gets the integrity report metadata.
     * @request GET:/api/v1/client/getverificationreports/{agreementId}/{depositId}
     * @secure
     */
    getTheMultipleVerificationReportsForADepositLinkedToAnAgreement = (
        agreementId: number,
        depositId: number,
        params: RequestParams = {}
    ) =>
        this.request<DocumentMetadataResponseViewModel, any>({
            path: `/api/v1/client/getverificationreports/${agreementId}/${depositId}`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags Document
     * @name DownloadVerificationReports
     * @summary Downloads verification report.
     * @request GET:/api/v1/client/downloadverificationreports/{agreementId}/{depositId}/{index}
     * @secure
     */
    downloadVerificationReports = (agreementId: number, depositId: number, index: string, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/v1/client/downloadverificationreports/${agreementId}/${depositId}/${index}`,
            method: 'GET',
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags Document
     * @name DownloadSftpConfirmationFile
     * @summary Downloads the SFTP XML file (to be uploaded when the SFTP transfer is complete).
     * @request GET:/api/v1/client/downloadsftpconfirmationfile/{depositId}
     * @secure
     */
    downloadSftpConfirmationFile = (
        depositId: string,
        query: {
            /**
             * Gets or sets the deposit identifier.
             * @format int32
             */
            DepositId: number;
        },
        params: RequestParams = {}
    ) =>
        this.request<void, any>({
            path: `/api/v1/client/downloadsftpconfirmationfile/${depositId}`,
            method: 'GET',
            query: query,
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags NewClientRegistration
     * @name V1NewClientRegistrationRegisterCreate
     * @summary Registers the client with the system.
     * @request POST:/api/v1/NewClientRegistration/Register
     * @secure
     */
    v1NewClientRegistrationRegisterCreate = (data: RegistrationDetails, params: RequestParams = {}) =>
        this.request<RegistrationResponse, any>({
            path: `/api/v1/NewClientRegistration/Register`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags Notifications
     * @name V1NotificationsList
     * @summary Gets list of Notifications.
     * @request GET:/api/v1/notifications
     * @secure
     */
    v1NotificationsList = (
        query?: {
            /**
             * Gets or sets the page size.
             * @format int32
             */
            PageSize?: number;
            /**
             * Gets or sets the page number.
             * @format int32
             */
            PageNumber?: number;
        },
        params: RequestParams = {}
    ) =>
        this.request<NotificationsResponseViewModel, any>({
            path: `/api/v1/notifications`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags Notifications
     * @name V1NotificationsReadUpdate
     * @summary Marks notification as read.
     * @request PUT:/api/v1/notifications/{id}/read
     * @secure
     */
    v1NotificationsReadUpdate = (id: number, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/v1/notifications/${id}/read`,
            method: 'PUT',
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags Notifications
     * @name V1NotificationsUnreadUpdate
     * @summary Marks notification as read.
     * @request PUT:/api/v1/notifications/{id}/unread
     * @secure
     */
    v1NotificationsUnreadUpdate = (id: number, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/v1/notifications/${id}/unread`,
            method: 'PUT',
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags Release
     * @name V1ReleasesList
     * @summary Gets list of releases.
     * @request GET:/api/v1/releases
     * @secure
     */
    v1ReleasesList = (params: RequestParams = {}) =>
        this.request<ReleasesResponse, any>({
            path: `/api/v1/releases`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags Release
     * @name V1ReleasesDownloadDetail
     * @summary Downloads the specified identifier.
     * @request GET:/api/v1/releases/{id}/download
     * @secure
     */
    v1ReleasesDownloadDetail = (id: number, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/v1/releases/${id}/download`,
            method: 'GET',
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags Release
     * @name V1ReleasesDownloadedUpdate
     * @summary Downloadeds the specified identifier.
     * @request PUT:/api/v1/releases/{id}/downloaded
     * @secure
     */
    v1ReleasesDownloadedUpdate = (id: number, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/v1/releases/${id}/downloaded`,
            method: 'PUT',
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags SShKeys
     * @name V1SshKeysCreate
     * @summary Adds a ssh key.
     * @request POST:/api/v1/ssh-keys
     * @secure
     */
    v1SshKeysCreate = (data: AddSshKeyRequestViewModel, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/v1/ssh-keys`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * No description
     *
     * @tags SShKeys
     * @name V1SshKeysList
     * @summary Get a users ssh key list.
     * @request GET:/api/v1/ssh-keys
     * @secure
     */
    v1SshKeysList = (params: RequestParams = {}) =>
        this.request<GetSshKeysResponseViewModel, any>({
            path: `/api/v1/ssh-keys`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags SShKeys
     * @name V1SshKeysDelete
     * @summary Deletes a ssh key.
     * @request DELETE:/api/v1/ssh-keys
     * @secure
     */
    v1SshKeysDelete = (data: DeleteSshKeyRequestViewModel, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/v1/ssh-keys`,
            method: 'DELETE',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * No description
     *
     * @tags SystemConfiguration
     * @name GetAllSystemConfigurations
     * @summary Gets the application system configurations.
     * @request GET:/api/v1/SystemConfiguration
     * @secure
     */
    getAllSystemConfigurations = (params: RequestParams = {}) =>
        this.request<GetSystemConfigurationListResponse, any>({
            path: `/api/v1/SystemConfiguration`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags Tooltip
     * @name V1TooltipsGetTooltipsList
     * @summary Gets tooltips.
     * @request GET:/api/v1/Tooltips/GetTooltips
     * @secure
     */
    v1TooltipsGetTooltipsList = (
        query: {
            /** Gets or sets the page. */
            Page: string;
            /** Gets or sets the language. */
            Language: string;
        },
        params: RequestParams = {}
    ) =>
        this.request<GetTooltipsResponseViewModel, any>({
            path: `/api/v1/Tooltips/GetTooltips`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags UiText
     * @name GetAllUiTextForSpecificLanguage
     * @summary Gets the application messages.
     * @request GET:/api/v1/UiText/{Language}
     * @secure
     */
    getAllUiTextForSpecificLanguage = (language: string, params: RequestParams = {}) =>
        this.request<GetUiTextResponseViewModel, any>({
            path: `/api/v1/UiText/${language}`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags Uploader
     * @name V1UploaderAddfilestodepositCreate
     * @summary Adds one or more files to the specified deposit.
     * @request POST:/api/v1/Uploader/addfilestodeposit
     * @secure
     */
    v1UploaderAddfilestodepositCreate = (data: UploaderDepositRequest, params: RequestParams = {}) =>
        this.request<UploaderAddFilesToDepositResponse, any>({
            path: `/api/v1/Uploader/addfilestodeposit`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags Uploader
     * @name UploadComplete
     * @summary Upload of a single file complete.
     * @request POST:/api/v1/Uploader/{depositId}/uploadcomplete
     * @secure
     */
    uploadComplete = (depositId: number, data: UploadCompleteRequest, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/v1/Uploader/${depositId}/uploadcomplete`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * No description
     *
     * @tags User
     * @name V1UserUpdateUiCultureCreate
     * @summary Updates the user ui culture.
     * @request POST:/api/v1/User/UpdateUiCulture
     * @secure
     */
    v1UserUpdateUiCultureCreate = (data: UiCultureUpdate, params: RequestParams = {}) =>
        this.request<UiCultureResponse, any>({
            path: `/api/v1/User/UpdateUiCulture`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags User
     * @name GetUserAccountDetails
     * @summary Retrieves user account details.
     * @request GET:/api/v1/User/details
     * @secure
     */
    getUserAccountDetails = (params: RequestParams = {}) =>
        this.request<UserResponseViewModel, any>({
            path: `/api/v1/User/details`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags User
     * @name UpdateUserAccountDetails
     * @summary Updates a users account details.
     * @request PATCH:/api/v1/User/details
     * @secure
     */
    updateUserAccountDetails = (data: UpdateUserDetailsRequestViewModel, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/v1/User/details`,
            method: 'PATCH',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * No description
     *
     * @tags User
     * @name DisableAUser
     * @summary Disables the user.
     * @request PATCH:/api/v1/User/disable
     * @secure
     */
    disableAUser = (
        query: {
            /**
             * The user to disable.
             * @format uuid
             */
            userId: string;
        },
        params: RequestParams = {}
    ) =>
        this.request<DisableUserResponseViewModel, any>({
            path: `/api/v1/User/disable`,
            method: 'PATCH',
            query: query,
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags User
     * @name GetUserSettings
     * @summary Retrieves user settings.
     * @request GET:/api/v1/User/getsettings
     * @secure
     */
    getUserSettings = (
        query?: {
            /** The System.String settingId. */
            settingId?: string;
        },
        params: RequestParams = {}
    ) =>
        this.request<UserSetting[], any>({
            path: `/api/v1/User/getsettings`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags User
     * @name SaveUserSettings
     * @summary Saves user settings.
     * @request POST:/api/v1/User/savesettings
     * @secure
     */
    saveUserSettings = (data: UserSetting[], params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/v1/User/savesettings`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        });
}
