import { Asterisk, ConfirmationTooltip, Optional, Switch } from 'lib-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Placement, TailwindProps } from '@ncc-frontend/core';
import { PropsWithChildren, ReactNode } from 'react';
import {
    faDiamondExclamation,
    faInfoCircle
} from '@fortawesome/pro-regular-svg-icons';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import cn from 'core/utils/cn';

interface SwitchFieldProps {
    ariaLabel?: string;
    checked?: boolean;
    className?: string;
    dataTestId?: string;
    disabled?: boolean;
    id: string;
    label?: string;
    labelStyles?: string;
    name: string;
    onChange?: (val: boolean) => void;
    optional?: boolean;
    orientation?: 'vertical' | 'horizontal' | 'reversed-horizontal';
    required?: boolean;
    tooltip?: string;
    tooltipDisabled?: boolean;
    tooltipPlacement?: Placement;
}

export function SwitchField({
    className,
    dataTestId,
    disabled = false,
    id,
    label,
    labelStyles,
    name,
    optional,
    orientation = 'reversed-horizontal',
    required,
    tooltip,
    tooltipDisabled,
    tooltipPlacement
}: SwitchFieldProps) {
    const {
        control,
        formState: { errors },
        register
    } = useFormContext();

    return (
        <Field
            className={cn('', className)}
            name={name}
            label={label}
            labelStyles={labelStyles}
            optional={optional}
            orientation={orientation}
            disabled={disabled}
            error={!!errors[name] ? <>{errors[name]?.message}</> : undefined}
            id={id}
            required={required}
            tooltip={tooltip}
            tooltipDisabled={tooltipDisabled}
            tooltipPlacement={tooltipPlacement}
        >
            <div className="flex flex-col justify-center">
                <Switch
                    {...register(name)}
                    control={control}
                    dataTestId={dataTestId}
                    defaultValue={false}
                    disabled={disabled}
                />
            </div>
        </Field>
    );
}

interface FieldProps extends TailwindProps {
    as?: 'label' | 'div' | 'radio';
    className?: string;
    disabled?: boolean;
    error?: ReactNode;
    id?: string;
    label?: ReactNode;
    labelStyles?: string;
    name: string;
    optional?: boolean;
    orientation?: 'vertical' | 'horizontal' | 'reversed-horizontal';
    readOnly?: boolean;
    required?: boolean;
    suppressReq?: boolean;
    tooltip?: string;
    tooltipDisabled?: boolean;
    tooltipPlacement?: Placement;
}

function Field({
    as,
    children,
    className,
    disabled,
    error,
    id,
    label,
    labelStyles,
    name,
    optional = false,
    orientation = 'vertical',
    readOnly,
    required,
    suppressReq = false,
    tooltip,
    tooltipDisabled = false,
    tooltipPlacement = 'right',
    ...restProps
}: PropsWithChildren<FieldProps>) {
    const { t } = useTranslation();
    const cssClasses = cn(
        disabled
            ? 'cursor-not-allowed'
            : readOnly
            ? 'cursor-default'
            : 'cursor-pointer',
        orientation === 'vertical'
            ? 'flex-col'
            : orientation === 'horizontal'
            ? 'flex-row'
            : orientation === 'reversed-horizontal'
            ? 'flex-row-reverse'
            : undefined,
        disabled
            ? 'cursor-not-allowed'
            : readOnly
            ? 'cursor-default'
            : 'cursor-pointer',
        'flex',
        !error ? 'text-general-grey-grey-90' : 'text-general-red-red-100',
        'flex w-fit gap-2',
        className
    );

    return (
        <div>
            <div className={cssClasses} {...restProps}>
                {!!label && (
                    <div
                        className={cn(
                            'flex w-full',
                            as === 'label' && 'items-center'
                        )}
                    >
                        <label
                            htmlFor={id}
                            className={cn(
                                'flex-1 flex items-center gap-1 min-h-full text-general-grey-grey-90 text-base',
                                as === 'label' && 'mb-2',
                                as === 'radio' && 'mb-2'
                            )}
                        >
                            <div className="flex items-center gap-2">
                                <span className="space-x-1">
                                    <span className={labelStyles}>{label}</span>{' '}
                                    {optional && <Optional />}
                                    {required && !suppressReq && <Asterisk />}
                                </span>
                            </div>
                        </label>
                        {tooltip && (
                            <div className="mb-2">
                                <ConfirmationTooltip
                                    placement={tooltipPlacement}
                                    icon={
                                        <FontAwesomeIcon
                                            icon={faDiamondExclamation}
                                        />
                                    }
                                    message={t(tooltip)}
                                    variant="default"
                                    disabled={tooltipDisabled}
                                >
                                    <FontAwesomeIcon
                                        icon={faInfoCircle}
                                        className="text-brand-escode-neonblue-neonblue-100"
                                    />
                                </ConfirmationTooltip>
                            </div>
                        )}
                    </div>
                )}
                {children}
                {error &&
                    orientation !== 'horizontal' &&
                    orientation !== 'reversed-horizontal' && (
                        <div className="text-sm pt-1 text-general-red-red-100">
                            <p>{error}Error</p>
                        </div>
                    )}
            </div>
            {!error && orientation !== 'vertical' && (
                <div className="text-sm pt-1 text-general-red-red-100">
                    <p>{error}</p>
                </div>
            )}
        </div>
    );
}

export default SwitchField;
