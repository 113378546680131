import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';

type OptionalType = {
    className?: string;
};

function Optional({ className }: OptionalType) {
    const { t } = useTranslation();

    return (
        <p
            className={twMerge(
                'text-escode-grey-50 text-sm inline-block',
                className
            )}
            data-testid="optional-tag"
        >
            ({t('deposit-now-static.optional')})
        </p>
    );
}

export default Optional;
