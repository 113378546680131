/* eslint-disable sort-keys-fix/sort-keys-fix */

const useAgreementDetailsV2MockData = [
    {
        agreementId: 7,
        agreementNumber: 35434,
        agreementType: 'Financial Model',
        agreementStatus: 'Live',
        softwarePackageName: 'Test Software',
        softwareOwner: 'BY Education (Lewisham) Limited',
        lastDepositDate: '2024-04-24T14:35:12.853707Z',
        numberOfAssociatedParties: 2,
        viewingPartyCanCreateDeposit: true,
        freeDepositsRemaining: 2,
        isOwner: true,
        isMultiAgreementType: false,
        viewableAgreementParties: [
            {
                agreementPartyId: 126,
                registeredName:
                    'The Mayor and Burgesses of the London Borough of Lewisham',
                partyType: 'Licensee',
                partyStatus: 'Live',
                partyStatusDate: '2020-10-23T09:10:31Z',
                depositor: false,
                billingAccount:
                    'The Mayor and Burgesses of the London Borough of Lewisham',
                agreementPartyContacts: [
                    {
                        id: 38,
                        contactId: 75,
                        roles: ['Main'],
                        name: 'Lieselotte Garrison'
                    }
                ],
                canEditContacts: false
            },
            {
                agreementPartyId: 127,
                registeredName: 'BY Education (Lewisham) Limited',
                partyType: 'Owner',
                partyStatus: 'Live',
                partyStatusDate: '2020-10-23T09:10:31Z',
                depositor: true,
                billingAccount: 'BY Education (Lewisham) Limited',
                agreementPartyContacts: [
                    {
                        id: 24,
                        contactId: 68,
                        roles: ['Main'],
                        name: 'Wadood Ashraf'
                    },
                    {
                        id: 25,
                        contactId: 69,
                        roles: ['Main'],
                        name: 'Bill Grindlay'
                    }
                ],
                canEditContacts: true
            }
        ],
        hasBespokeTestingRequirements: true,
        hasOptedOutOfVVStorage: false
    }
];

export default useAgreementDetailsV2MockData;
